let API = null;

export class Api {
    static Set(api) {
        if (!API && api) {
            API = api;
        }
    }

    static Get() {
        return API;
    }
}
