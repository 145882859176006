import React, { useCallback, useMemo, useRef, useState } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
//import { useSystemState } from '../../context';
import { Console, Optional } from '../../utils';

const NAME = 'DynamicMap.web';

const containerStyle = {
    width: '360px',
    height: '413px',
};

const Center = {
    lat: 20.79,
    lng: -157.53,
    zoom: 6.25,
};

const MAP_CONFIG = {
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCdxCTyCHWbQgHVTWfjIYL2rYA2OtGMR3c',
};


export const DynamicMap = props => {

//    const { pixelDensity } = useSystemState();

    const { isLoaded } = useJsApiLoader(MAP_CONFIG);

    const [map, setMap] = useState(null);
    const setMapRef = useRef(setMap);

    const [lat/*, setLat*/] = useState(Center.lat);
    //const setLatRef = useRef(setLat);

    const [lng/*, setLng*/] = useState(Center.lng);
    //const setLngRef = useRef(setLng);

    const [zoom/*, setZoom*/] = useState(Center.zoom);
    //const setZoomRef = useRef(setZoom);

    const onLoad = useCallback(
        _map => {
            Console.devLog(`${NAME}.onLoad`, { _map });
            // This is just an example of getting and using the map instance!!! don't just blindly copy!
            //const bounds = new window.google.maps.LatLngBounds(Center);
            //_map.fitBounds(bounds);
            setMapRef.current(_map);
        },
        [
            setMapRef,
        ],
    );

    const onUnmount = useCallback(
        _map => {
            Console.devLog(`${NAME}.onUnmount`, { _map });
            setMapRef.current(null);
        },
        [
            setMapRef,
        ],
    );

    Console.devStack(NAME, props, { isLoaded, map, lat, lng, zoom/*, pixelDensity: pixelDensity()*/ });

    return useMemo(
        () => {
            return Optional(isLoaded, (
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={{ lat, lng }}
                    zoom={zoom}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                >
                    <></>
                </GoogleMap>
            ));
        },
        [
            isLoaded,
            lat,
            lng,
            zoom,
            onLoad,
            onUnmount,
        ],
    );
};
