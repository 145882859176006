import React from 'react';
import { ScrollView, View as RNView } from 'react-native';
import { Console, Optional, Validate } from '../utils';

const NAME = 'View';

export const VIEW_VARIANTS = {
    NORMAL: 'normal',
    SCROLL: 'scroll',
};


export const View = props => {

    const {
        value,
        variant,
        children,
        id,
        style,
        pointerEvents,
        width,
        height,
    } = props;

    var _props = {};
    if (Validate.isValidNonEmptyString(id)) {
        _props.id = id;
    } else {
        _props.id = `${value}_${variant}`;
    }
    if (Validate.isValid(style)) {
        _props.style = style;
    }
    if (Validate.isValid(pointerEvents)) {
        _props.pointerEvents = pointerEvents;
    }
    if (Validate.isValid(width)) {
        _props.width = width;
    }
    if (Validate.isValid(height)) {
        _props.height = height;
    }

    Console.stack(`${NAME}[${value}]`);

    return Optional(variant === VIEW_VARIANTS.SCROLL,
        (
            <RNView
                {..._props}
            >
                <ScrollView>
                    {children}
                </ScrollView>
            </RNView>
        ),
        (
            <RNView
                {..._props}
            >
                {children}
            </RNView>
        ),
    );
};
