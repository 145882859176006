// https://reactnativeelements.com/docs/web_usage/
// Edit @font-face in navigation/Route.js
// Edit ttf rule in config-overrides.js

// https://oblador.github.io/react-native-vector-icons/

//import AntDesign from 'react-native-vector-icons/AntDesign';
//import Entypo from 'react-native-vector-icons/Entypo';
//import EvilIcons from 'react-native-vector-icons/EvilIcons';
//import Feather from 'react-native-vector-icons/Feather';
//import FontAwesome from 'react-native-vector-icons/FontAwesome';
//import Fontisto from 'react-native-vector-icons/Fontisto';
//import Foundation from 'react-native-vector-icons/Foundation';
//import Ionicons from 'react-native-vector-icons/Ionicons';
//import MaterialIcons from 'react-native-vector-icons/MaterialIcons';
//import Octicons from 'react-native-vector-icons/Octicons';
//import Zocial from 'react-native-vector-icons/Zocial';
//import SimpleLineIcons from 'react-native-vector-icons/SimpleLineIcons';

// icon array format:
// - array size = 1, { key[0], name[0], outline[0] }
// - array size = 2, { key[0], name[1], outline[1] }
// - array size = 3, { key[0], name[1], outline[2] }
// note: in the key, all '-' must be replaced with '_'

//import * as Signs from './signs';
//export { Signs };

// https://image.online-convert.com/convert-to-svg

export { Icons, Icon2Svg, MATERIAL_COMMUNITY } from './icons';
export { Audios } from './Audios';
export { GetMarkerImage } from './GetMarkerImage';
export { Images } from './Images';
export { asl as Signs, ASL_VARIANTS, ASL_TYPES } from './asl';
export { aslmp4, ASLMP4_URL } from './aslmp4';
export { ipa as Vocal, phonics as Phonics, rPhonics as ReversePhonics } from './ipa';
export { PleasantHolidaysLogo, PleasantHolidaysLogoSize } from './PleasantHolidays';
export { CreativeCommonsLogo, CreativeCommonsLogoSize } from './CreativeCommons';
export { qr as QR } from './qr';
export { IpaAudio/*, IpaWavs*/ } from './IpaAudio';
export { MaterialCommunityIcons, IconFont, MaterialCommunityIconsLoader, IconFontLoader } from './Icon';
export { Three, ThreeLoader } from './Three';
