import React from 'react';
import { KeyboardRegistry } from './utils';
import { Keyboard } from './Keyboard';


export class KeyboardView extends React.Component {
    render() {
        return <Keyboard {...this.props} />;
    }
}

KeyboardRegistry.registerKeyboard('AcquiLinguaKeyboard', () => KeyboardView);
//Console.log('getAllKeyboards', KeyboardRegistry.getAllKeyboards());
//Console.log('getKeyboard', KeyboardRegistry.getAllKeyboards('AcquiLinguaKeyboard'));
