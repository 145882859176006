import React from 'react';


export class KeyboardRegistry {
    static registerKeyboard() {}
    static getAllKeyboards() {}
}

export const KeyboardAccessoryView = () => {
    return (<></>);
};
