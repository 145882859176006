const TILE_SIZE = 256;
const EARTH_RADIUS_IN_METERS = 6371010;
const SCREEN_PIXEL_HEIGHT = 768;
const RADIUS_X_PIXEL_HEIGHT = 27.3611 * EARTH_RADIUS_IN_METERS * SCREEN_PIXEL_HEIGHT;


export const GetAltitude = (zoom, latitude = 0) => {
    return (RADIUS_X_PIXEL_HEIGHT * Math.cos((latitude * Math.PI) / 180)) / (TILE_SIZE * Math.pow(2, zoom));
};

export const GetZoom = (altitude, latitude = 0) => {
    return Math.log2((RADIUS_X_PIXEL_HEIGHT * Math.cos((latitude * Math.PI) / 180)) / (TILE_SIZE * altitude));
};
