import React, { createContext, useContext, useReducer } from 'react';
import { Array2Object, Console } from '../utils';
import { updateUser } from './UserProvider';

const types = [
  'SET_USERS',
  'SET_BOT',
  'UNSET_BOT',
  'SET_SOCKET',
  'UNSET_SOCKET',
];
export const TYPES = Array2Object(types);

export const UNSET_BOT_VALUE = { language: 'en_us' };
export const UNSET_SOCKET_VALUE = { key: null, value: null };

const UsersStateContext = createContext();
const UsersDispatchContext = createContext();

const usersState = {
  users: [],
  bot: UNSET_BOT_VALUE,
  socketUrl: UNSET_SOCKET_VALUE,
};

const updateUsers = users => {
  return users.map((user, index) => updateUser(user, index + 1));
};

const usersReducer = (state, action) => {
  const { type, payload } = action;
  var result;

  switch (type) {

    case TYPES.SET_USERS:
      result = {
        ...state,
        users: updateUsers(payload),
      };
      break;

    case TYPES.SET_BOT:
      result = {
        ...state,
        bot: payload,
      };
      break;

    case TYPES.UNSET_BOT:
      result = {
        ...state,
        bot: UNSET_BOT_VALUE,
      };
      break;

    case TYPES.SET_SOCKET:
      const socketUrl = {
        key: payload.key,
        value: payload.value,
      };
      result = {
        ...state,
        socketUrl,
      };
      break;

    case TYPES.UNSET_SOCKET:
      result = {
        ...state,
        socketUrl: UNSET_SOCKET_VALUE,
      };
      break;

    default:
      throw new Error(`usersReducer: Unhandled action "${type}".`);
  }

  Console.LOG(`usersReducer[${type}] (${JSON.stringify(payload)})`);
  Console.trace('usersReducer', { state, result });

  return result;
};

const NAME = 'UsersProvider';


export const UsersProvider = props => {

  const {
    children,
  } = props;

  const [state, dispatch] = useReducer(usersReducer, usersState);

  Console.stack(NAME, props, { state });

  return (
    <UsersStateContext.Provider value={state}>
      <UsersDispatchContext.Provider value={dispatch}>
        {children}
      </UsersDispatchContext.Provider>
    </UsersStateContext.Provider>
  );
};

export const useUsersState = () => useContext(UsersStateContext);
export const useUsersDispatch = () => useContext(UsersDispatchContext);
