import React from 'react';
import { Pressable } from './Pressable';
import { Icons } from '../media';
import { Colors } from '../styles';
import { Audio, Base64, Console, Validate } from '../utils';

const NAME = 'Speaker';


export const Speaker = props => {

    const {
        data,
        color,
        style,
        disabled,
    } = props;

    const _disabled = Validate.isValid(disabled) ? disabled : !data?.length;
    const _color = Validate.isValid(color) ? color : Colors.colors.green;

    const onPress = () => {
        Console.log(`${NAME}.onPress`, { data });
        Audio.Play(Base64.FromArrayBuffer(data));
    };

    Console.stack(NAME, props, { _color, _disabled });

    return (
        <Pressable
            style={style}
            icon={Icons.VolumeLow}
            onPress={onPress}
            iconColor={_color}
            disabled={_disabled}
        />
    );
};
