import React from 'react';
import { FlatList as RNFlatList, StyleSheet, View } from 'react-native';
import { useAppState } from '../context';
import { Console } from '../utils';
import { Colors } from '../styles';

const NAME = 'FlatList';


export const FlatList = props => {

    const {
        data,
        renderItem,
        style,
    } = props;

    const { dark } = useAppState();

    const colorStyle = { backgroundColor: dark ? Colors.colors.black : Colors.colors.lightgray };

    Console.stack(NAME, props);

    return (
        <View
            style={[styles.view, colorStyle]}
        >
            <RNFlatList
                keyExtractor={item => item?.key ? item.key : item.id}
                style={style}
                data={data}
                renderItem={renderItem}
                scrollEnabled={true}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    view: {
        flex: -1, // MARKMARK -1 ?
        width: '100%',
    },
});
