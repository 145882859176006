import React, { useMemo } from 'react';
import { TouchableOpacity } from 'react-native';
import { Text, TEXT_VARIANTS } from './Text';
import { View } from './View';
import { OnPress } from './utils';
import { useAppState, useSystemState } from '../context';
import { Colors } from '../styles';
import { Console, Optional, Validate } from '../utils';
import { DEV_COLOR } from '../constants';

const NAME = 'Card';


export const Card = props => {

    const {
        //id,
        onPress,
        cardStyle,
        left,
        leftStyle,
        mainStyle,
        title,
        titleStyle,
        subtitle,
        subtitleStyle,
        right,
        rightStyle,
        //backgroundColor,
    } = props;

    const { deviceScale } = useSystemState();
    const { theme, themeUpdate } = useAppState();

    Console.traceStack(NAME, props, { deviceScale, themeUpdate });

    return useMemo(
        () => {
            const { roundness } = theme;
            Console.trace(`${NAME} render`, { deviceScale, roundness, themeUpdate });
            const altColorStyle = {};/*
                backgroundColor: backgroundColor
                    ? backgroundColor
                    : cardStyle?.backgroundColor
                        ? cardStyle.backgroundColor
                        : id % 2
                            ? Colors.colors.gray
                            : Colors.colors.darkgray,
                borderRadius: roundness * deviceScale,
            };*/
            return (
                <TouchableOpacity
                    style={[
                        styles.card,
                        cardStyle,
                        altColorStyle,
                    ]}
                    onPress={() => OnPress(title, onPress)}
                >
                    {Optional(Validate.isValid(left), (
                        <View
                            value={'CardLeft'}
                            style={[styles.left, leftStyle]}
                        >
                            {left}
                        </View>
                    ))}
                    {Optional(Validate.isValidNonEmptyString(title), (
                        <View
                            value={'CardMain'}
                            style={[styles.main, mainStyle]}
                        >
                            <Text
                                style={[
                                    styles.title,
                                    titleStyle,
                                ]}
                                value={title}
                                variant={TEXT_VARIANTS.HEADLINE}
                                noTranslate={true}
                            />
                            {Optional(Validate.isValidNonEmptyString(subtitle), (
                                <Text
                                    style={[
                                        styles.subtitle,
                                        subtitleStyle,
                                    ]}
                                    value={subtitle}
                                    variant={TEXT_VARIANTS.SUBHEADING}
                                    noTranslate={true}
                                />
                            ))}
                        </View>
                    ))}
                    {Optional(Validate.isValid(right), (
                        <View
                            value={'CardMain'}
                            style={[styles.right, rightStyle]}
                        >
                            {right}
                        </View>
                    ))}
                </TouchableOpacity>
            );
        },
        [
            //id,
            deviceScale,
            theme,
            themeUpdate,
            title,
            subtitle,
            left,
            right,
            cardStyle,
            mainStyle,
            titleStyle,
            subtitleStyle,
            leftStyle,
            rightStyle,
            //backgroundColor,
            onPress,
        ],
    );
};

const styles = {
    card: {
        backgroundColor: DEV_COLOR(Colors.colors.red),
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        /*
        marginHorizontal: '0.5%',
        marginTop: '1%',
        paddingHorizontal: '2%',
        paddingVertical: '1%',
        elevation: 5,
        zIndex: 5,
        */
    },
    main: {
        backgroundColor: DEV_COLOR(Colors.colors.magenta),
        flexGrow: 1,
    },
    title: {
        backgroundColor: DEV_COLOR(Colors.colors.blue),
    },
    subtitle: {
        backgroundColor: DEV_COLOR(Colors.colors.green),
        marginLeft: '2%',
    },
    left: {
        backgroundColor: DEV_COLOR(Colors.colors.pink),
        aspectRatio: 1,
        marginRight: '2%',
    },
    right: {
        backgroundColor: DEV_COLOR(Colors.colors.cyan),
        marginLeft: '1%',
    },
};
