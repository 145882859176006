import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { Pressable, View } from '../components';
import { Console, Optional, Validate } from '../utils';
import { LANGUAGES } from '../constants';
import { useAppState, useSystemState } from '../context';
import { useKeyboard } from '../hooks';
import { keyboards } from './keyboards';
import { ANIMATED_USE_NATIVE_DRIVER } from '../constants';
import { Colors } from '../styles';

const NAME = 'Keyboard';

const ANIMATE = true;

const ipa_consonant = {
    en_us: 'kɑnsənənt',
    pt_br: 'kõswɐ̃tɨ',
    pt_pt: 'kõswɐ̃tɨ',
    es_es: 'konsonante',
    es_mx: 'konsonante',
    fr_fr: 'kɔ̃sɔn',
    ro_ro: 'konswanə',
    it_it: 'konsonante',

    de_de: '?',
    en_gb: '?',
    hi_in: '?',
    ja_jp: '?',
    ko_kr: '?',
    ru_ru: '?',
    zh_cn: '?',
    zh_tw: '?',
    ar_sa: '?',
};

const ipa_vowel = {
    en_us: 'vaʊəl',
    pt_br: 'voɡaw',
    pt_pt: 'vuɡaɫ',
    es_es: 'bokal',
    es_mx: 'bokal',
    fr_fr: 'vwajɛl',
    ro_ro: 'vokalə',
    it_it: 'vokale',

    de_de: '?',
    en_gb: '?',
    hi_in: '?',
    ja_jp: '?',
    ko_kr: '?',
    ru_ru: '?',
    zh_cn: '?',
    zh_tw: '?',
    ar_sa: '?',
};

const button_colors = {
    i0: Colors.colors.mediumslateblue,
    i1: Colors.colors.darkblue,
    i2: Colors.colors.steelblue,
    i3: Colors.colors.indigo,
};


export const Keyboard = props => {

    const {
        shiftValue,
        onPress,
    } = props;

    const { deviceScale, height } = useSystemState();
    const { DEFAULT, language } = useAppState();
    const { kbVisible, ipaKeyboard, setKbVisible } = useKeyboard();

    const [shift, setShift] = useState(shiftValue ? shiftValue : 0);
    const setShiftRef = useRef(setShift);
    const [IPA, setIPA] = useState(ipaKeyboard ? ipaKeyboard : 0);
    const setIPARef = useRef(setIPA);

    const [translateY] = useState(new Animated.Value(0));

    useEffect(
        () => {
            if (!ANIMATE) {
                return;
            }
            const { KB_HEIGHT, KB_DURATION_MS, TAB_BAR_HEIGHT } = DEFAULT;
            const _height = (KB_HEIGHT - TAB_BAR_HEIGHT) * deviceScale;
            Console.log(`${NAME} useEffect animation`, { deviceScale, KB_DURATION_MS, KB_HEIGHT, TAB_BAR_HEIGHT, kbVisible, _height });
            Animated.timing(translateY, {
                useNativeDriver: ANIMATED_USE_NATIVE_DRIVER,
                toValue: kbVisible ? -_height : 0,
                KB_DURATION_MS,
            }).start();
        },
        [
            DEFAULT,
            deviceScale,
            translateY,
            kbVisible,
        ],
    );

    const KB = useCallback(
        (c, label, i, j, onPressArg, style, textStyle, color, textColor) => {

            var disabled = false;
            var buttonColor = color;
            var buttonTextColor = textColor;

            var title = label;

            var lastIndex = title.length - 1;
            var firstChar = title.charAt(0);
            var lastChar = title.charAt(lastIndex);

            const removeFirstChar = () => {
                title = title.substring(1);
                c = c.substring(1);
                firstChar = title.charAt(0);
                lastChar = title.charAt(--lastIndex);
            };

            const removeLastChar = () => {
                title = title.substring(0, lastIndex);
                c = c.substring(0, lastIndex);
                lastChar = title.charAt(--lastIndex);
            };

            // ends in numeric code, represents button color
            if (lastChar === '0' ||
                lastChar === '1' ||
                lastChar === '2' ||
                lastChar === '3') {
                buttonColor = button_colors[`i${lastChar}`];
                removeLastChar();
            }
            // ends in '+' means the text is cyan
            if (lastChar === '+') {
                buttonTextColor = Colors.colors.cyan;
                removeLastChar();
            }
            // starts with '-' means disabled, button is gray, button text is grayish
            if (firstChar === '-') {
                disabled = true;
                buttonColor = Colors.colors.gray;
                buttonTextColor = buttonTextColor === Colors.colors.cyan ? Colors.colors.lightgray : Colors.colors.darkgray;
                removeFirstChar();
            }
            // remove nasal vowel tildes
            var send = c
                .replace(/ɛ̃/g, 'ɛ')
                .replace(/œ̃/g, 'œ')
                .replace(/ɔ̃/g, 'ɔ')
                .replace(/ɑ̃/g, 'ɑ')
                ;
            // ...

            const {
                KB_BUTTON_WIDTH,
                KB_BUTTON_HEIGHT,
                KB_BUTTON_RADIUS,
                KB_MARGIN_VT,
                KB_MARGIN_HZ,
            } = DEFAULT;
            const buttonStyle = {
                width: KB_BUTTON_WIDTH * deviceScale,
                height: KB_BUTTON_HEIGHT * deviceScale,
                borderRadius: KB_BUTTON_RADIUS * deviceScale,
                marginVertical: KB_MARGIN_VT * deviceScale,
                marginHorizontal: KB_MARGIN_HZ * deviceScale,
            };

            Console.log(`${NAME}.KB`, { deviceScale, c, send, label, i, j, title, disabled, buttonStyle, buttonColor, buttonTextColor, lastIndex, firstChar, lastChar });
            return (
                <Pressable
                    key={`kb_${i}_${j}`}
                    style={[styles.button, buttonStyle, style]}
                    textStyle={[styles.buttonText, textStyle]}
                    backgroundColor={buttonColor}
                    textColor={buttonTextColor}
                    value={title}
                    disabled={disabled}
                    onPress={() => onPressArg(send)}
                />
            );
        },
        [
            DEFAULT,
            deviceScale,
        ],
    );

    const KBRow = useCallback(
        (row, i, onPressArg) => {
            const {
                KB_ROW_HEIGHT,
                KB_BUTTON_COLOR,
                KB_TEXT_COLOR,
            } = DEFAULT;
            const rowStyle = { height: KB_ROW_HEIGHT * deviceScale};
            Console.log(`${NAME}.KBRow`, { i, deviceScale, language, shift, row, rowStyle });
            return (
                <View
                    key={`KBRow_${i}`}
                    style={[styles.row, rowStyle]}
                >
                    {
                        row.map((c, j) => {
                            var label = c;
                            var style = {};
                            var textStyle = {};
                            var color = KB_BUTTON_COLOR;
                            var textColor = KB_TEXT_COLOR;
                            switch (c) {

                                case 'shift':
                                    style.width = 41 * deviceScale;
                                    style.marginRight = 8 * deviceScale;
                                    switch (shift) {
                                        case 0:
                                            label = '⇧';
                                            color = Colors.colors.black;
                                            textColor = KB_TEXT_COLOR;
                                            break;
                                        case 1:
                                            label = '⇧';
                                            color = Colors.colors.white;
                                            textColor = Colors.colors.black;
                                            break;
                                        case 2:
                                            label = '⇫';
                                            color = Colors.colors.white;
                                            textColor = Colors.colors.black;
                                            break;
                                    }
                                    break;
                                case 'space':
                                    label = LANGUAGES[language].title;
                                    style.width = 170 * deviceScale;
                                    textStyle.fontSize = 8 * deviceScale;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case 'spc':
                                    label = LANGUAGES[language].title;
                                    style.width = 50 * deviceScale;
                                    textStyle.fontSize = 8 * deviceScale;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case 'ipa':
                                    label = 'IPA';
                                    style.width = 46 * deviceScale;
                                    color = Colors.colors.black;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case 'abc':
                                    style.width = 46 * deviceScale;
                                    color = ipaKeyboard ? Colors.colors.gray : Colors.colors.black;
                                    textColor = ipaKeyboard ? Colors.colors.darkgray : Colors.colors.cyan;
                                    break;
                                case 'consonant':
                                    label = ipa_consonant[language];
                                    style.width = 85 * deviceScale;
                                    textStyle.fontSize = 10 * deviceScale;
                                    color = Colors.colors.black;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case 'vowel':
                                    label = ipa_vowel[language];
                                    style.width = 46 * deviceScale;
                                    textStyle.fontSize = 10 * deviceScale;
                                    color = Colors.colors.black;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case 'back':
                                    label = '⌫';
                                    style.width = 41 * deviceScale;
                                    style.marginLeft = 8 * deviceScale;
                                    color = Colors.colors.black;
                                    break;
                                case 'done':
                                    label = '⏎';
                                    style.width = 46 * deviceScale;
                                    color = Colors.colors.black;
                                    textColor = Colors.colors.cyan;
                                    break;
                                case '.':
                                case ',':
                                    style.width = 25 * deviceScale;
                                    break;
                            }
                            return KB(c, label, i, j, onPressArg, style, textStyle, color, textColor);
                        })
                    }
                </View>
            );
        },
        [
            KB,
            deviceScale,
            DEFAULT,
            ipaKeyboard,
            language,
            shift,
        ],
    );

    /* eslint-disable no-fallthrough */
    const _onPress = useCallback(
        (c) => {
            Console.log(`*** ${c} ***`);
            var send = null;
            switch (c) {
                case 'shift':
                    setShiftRef.current((shift + 1) % 3);
                    return;
                case 'abc':
                    setIPARef.current(0);
                    return;
                case 'ipa':
                    setIPARef.current(1);
                    return;
                case 'vowel':
                    setIPARef.current(2);
                    return;
                case 'consonant':
                    setIPARef.current(1);
                    return;
                case 'space':
                case 'spc':
                    send = ' ';
                    break;
                case 'done':
                    setKbVisible(false);
                default:
                    send = c;
                    break;
            }
            if (shift === 1) {
                setShiftRef.current(0);
            }
            if (Validate.isValid(onPress)) {
                onPress(send);
            }
        },
        [
            shift,
            onPress,
            setShiftRef,
            setIPARef,
            setKbVisible,
        ],
    );

    const keyboard = useMemo(
        () => {
            Console.log(`${NAME}.keyboard`, { ipaKeyboard, IPA, language, shift });
            return !ipaKeyboard && IPA === 0
                ? shift ? 'upper' : 'lower'
                : IPA === 2 ? 'vowel' : 'consonant';
        },
        [
            ipaKeyboard,
            language,
            shift,
            IPA,
        ],
    );

    Console.stack(NAME, props, { DEFAULT, deviceScale, height, language, shift, IPA, kbVisible, ipaKeyboard });

    return useMemo(
        () => {
            const { KB_HEIGHT, KB_PADDING_TOP, KB_COLOR_BG, TAB_BAR_HEIGHT } = DEFAULT;
            const keyboardStyle = {
                height: (KB_HEIGHT - TAB_BAR_HEIGHT) * deviceScale,
                paddingTop: KB_PADDING_TOP * deviceScale,
                backgroundColor: KB_COLOR_BG,
            };

            Console.log(`${NAME} render`, { DEFAULT, language, height, keyboard, keyboardStyle, ipaKeyboard, kbVisible });

            // MARKMARK: this optional bit works in terms of not showing kb when dismissed (even with scroll views),
            // however, it dismisses immediately without animation
            return Optional(kbVisible && ipaKeyboard, (
                <Animated.View
                    style={[
                        styles.keyboard,
                        keyboardStyle,
                        { top: height },
                        { transform: [{ translateY }] },
                    ]}
                >
                    {keyboards[language][keyboard].map((row, i) => KBRow(row, i, _onPress))}
                </Animated.View>
            ));
        },
        [
            kbVisible,
            ipaKeyboard,
            DEFAULT,
            deviceScale,
            height,
            language,
            keyboard,
            translateY,
            _onPress,
            KBRow,
        ],
    );
};

const styles = StyleSheet.create({
    keyboard: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    buttonText: {

    },
    row: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
});
