import { StyleSheet } from 'react-native';
import { elements } from './elements';


export const flatten = (style, field) => {
    return StyleSheet.flatten(style)[field];
};

export const invert = inverted => {
    return inverted ? { transform: [{ scaleY: -1 }] } : {};
};

export const styles = StyleSheet.create({
    border: {
        borderWidth: 1,
        borderColor: 'red',
    },
    screen: {

        ...elements.layout.flex,
        ...elements.layout.flexColumn,
        ...elements.layout.fullHeight,
        ...elements.layout.fullWidth,
        ...elements.layout.wrapOff,

        margin: 0,
        padding: 0,
        zIndex: 0,
        overflow: 'hidden',

        ...elements.layout.alignContentCenter,
        ...elements.layout.alignItemsCenter,
        ...elements.layout.alignSelfAuto,
        ...elements.layout.justifyContentBetween,

    },
    screenView: {
        ...elements.layout.flex,
        ...elements.layout.flexColumn,
        ...elements.layout.fullHeight,
        ...elements.layout.fullWidth,
        ...elements.layout.alignItemsCenter,
        ...elements.layout.justifyContentAround,
        borderWidth: 1,
        borderColor: 'black',
    },
    colView: {
        ...elements.layout.flexColumn,
    },
    rowView: {
        ...elements.layout.flexRow,
    },
    underlineText: {
        ...elements.text.underline,
    },
    /*
    gameView: {
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 5,
    },
    gameButtonRow: {
        ...elements.layout.flexRow,
        alignItems: 'center',
        justifyContent: 'space-around',
        padding: 5,
    },
    gameButton: {
        marginHorizontal: 5,
    },
    */
    /*
    keyboard: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: DEFAULT.KB_HEIGHT,
        width: '100%',
        paddingTop: 47,
        backgroundColor: DEFAULT.KB_COLOR_BG,
    },
    keyboardButton: {
        width: DEFAULT.KB_BUTTON_WIDTH,
        height: DEFAULT.KB_BUTTON_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: DEFAULT.KB_BUTTON_RADIUS,
        marginVertical: DEFAULT.KB_MARGIN_VT,
        marginHorizontal: DEFAULT.KB_MARGIN_HZ,
    },
    keyboardButtonText: {
        fontSize: DEFAULT.KB_FONT_SIZE,
    },
    keyboardRow: {
        flexDirection: 'row',
        width: '100%',
        height: DEFAULT.KB_ROW_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
    },
    */
    webView: {
        flex: 1,
        height: '50%',
        width: '50%',
    },
    signLanguageDetection: {
        position: 'absolute',
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
