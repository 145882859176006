import { Icons } from '../media';
import { Colors } from '../styles';
import { MapKeys } from './MapKeys';

const IconColors = [
    Colors.colors.red,
    Colors.colors.darkorange,
    Colors.colors.yellow,
    Colors.colors.lightgreen,
    Colors.colors.lightblue,
    Colors.colors.violet,
    'rgb(188,0,188)',
];


export const MapIcons = new Map([

    // ---- general info

    [MapKeys.WEATHER, { icon: Icons.MapWeatherOn, iconOff: Icons.MapWeatherOff, color: Colors.colors.white }],
    [MapKeys.BUSES, { icon: Icons.MapBuses, color: IconColors[2] }],
    [MapKeys.ATMS, { icon: Icons.MapCash, color: IconColors[3] }],

    [MapKeys.AIRPORTS, { icon: Icons.MapAirports, color: IconColors[0] }],
    [MapKeys.MARINAS, { icon: Icons.MapMarinas, color: IconColors[1] }],
    [MapKeys.EVENTS, { icon: Icons.MapEvents, color: IconColors[2] }],
    [MapKeys.MUSIC, { icon: Icons.MapMusic, color: IconColors[3] }],
    [MapKeys.FAVORITES, { icon: Icons.MapFavorites, color: IconColors[4] }],

    [MapKeys.BIKI, { icon: Icons.MapBiki, color: IconColors[1] }],
    [MapKeys.BUS_STOPS, { icon: Icons.MapBusStops, color: IconColors[2] }],
    [MapKeys.TOILETS, { icon: Icons.MapToilets, color: IconColors[6] }], // 'toilet'

    // attractions

    [MapKeys.ATTRACTIONS, { icon: Icons.MapAttractions, color: IconColors[0] }],
    [MapKeys.BEACHES, { icon: Icons.MapBeaches, color: IconColors[1] }],
    [MapKeys.HIKES, { icon: Icons.MapHikes, color: IconColors[2] }],
    [MapKeys.GOLF, { icon: Icons.MapGolf, color: IconColors[3] }],
    [MapKeys.SURF, { icon: Icons.MapSurf, color: IconColors[4] }],

    [MapKeys.CHURCHES, { icon: Icons.MapChurches, color: IconColors[6] }],

    // commercial

    [MapKeys.GROCERY, { icon: Icons.MapGroceries, color: IconColors[1] }],
    [MapKeys.COFFEE, { icon: Icons.MapCoffee, color: IconColors[2] }],

    [MapKeys.DEPTSTORES, { icon: Icons.MapShopping, color: IconColors[0] }],
    [MapKeys.FARMERS_MARKETS, { icon: Icons.MapFarms, color: IconColors[1] }],
    [MapKeys.RESTAURANTS, { icon: Icons.MapRestaurants, color: IconColors[2] }],
    [MapKeys.BARS, { icon: Icons.MapBars, color: IconColors[3] }],
    [MapKeys.HOTELS, { icon: Icons.MapLodging, color: IconColors[4] }],


]);

/* yelp searches
active, breweries, cafes, hotels, hotelstravel, chinese, japanese, breakfast_brunch,
localflavor, nightlife, movietheaters, museums, parks, religiousorgs, publicservicesgovt,
shopping, restaurants, tours, transport, travelservices, wineries, zoos
*/
