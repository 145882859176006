import { Validate } from '../../utils';

// accept input as either a number or percent ('##%') of total
export const toDimension = (input, denom) => {
    return `${input}`.indexOf('%') !== -1
        ? (+(input.split('%')[0]) / 100) * denom
        : +input;
};

// compute the view heights
// the inputs (header, main, footer) can be given in
// - numeric values (##)
// - percentage of total ('##%')
// - floating (-1)
// - off (0)
// results are calculated for each height
// to maintain as many constraints as possible
export const viewHeights = (total, _header, _main, _footer) => {

    const header = Validate.isValid(_header) ? _header : 0;
    const main = Validate.isValid(_main) ? _main : 0;
    const footer = Validate.isValid(_footer) ? _footer : 0;

    // default all off
    var result = {
        header: { height: 0 },
        main: { height: 0 },
        footer: { height: 0 },
    };

    if (total <= 0) {
        return result;
    }

    // get the numeric value for the inputs
    var h = toDimension(header, total);
    var m = toDimension(main, total);
    var f = toDimension(footer, total);

    // h & m & f
    if (h !== 0 && m !== 0 && f !== 0) {
        if (h < 0) {
            if (f < 0) {

                // everything is floating
                if (m < 0) {
                    result.header.height = total / 3;
                    result.footer.height = result.header.height;
                    result.main.height = total - (result.header.height + result.footer.height);

                // h and f are floating
                } else {
                    result.main.height = m;
                    result.header.height = (total - result.main.height) / 2;
                    result.footer.height = total - (result.header.height + result.main.height);
                }
            } else {
                result.footer.height = f;

                // h and m are floating
                if (m < 0) {
                    result.header.height = (total - result.footer.height) / 2;
                    result.main.height = total - (result.header.height + result.footer.height);

                // h is floating
                } else {
                    result.main.height = m;
                    result.header.height = total - (result.main.height + result.footer.height);
                }
            }

        } else {
            result.header.height = h;

            if (f < 0) {

                // f and m are floating
                if (m < 0) {
                    result.main.height = (total - result.header.height) / 2;
                    result.footer.height = total - (result.header.height + result.main.height);

                // f is floating
                } else {
                    result.main.height = m;
                    result.footer.height = total - (result.header.height + result.main.height);
                }

            // m or nothing is floating
            } else {
                result.footer.height = f;
                result.main.height = total - (result.header.height + result.footer.height);
            }
        }

    // h & m
    } else if (h !== 0 && m !== 0 && f === 0) {
        result.footer.height = 0;
        if (h < 0) {
            if (m < 0) {
                result.header.height = total / 2;
                result.main.height = total - result.header.height;
            } else {
                result.main.height = m;
                result.header.height = total - result.main.height;
            }
        } else {
            result.header.height = h;
            result.main.height = total - result.header.height;
        }

    // m & f
    } else if (h === 0 && m !== 0 && f !== 0) {
        result.header.height = 0;
        if (f < 0) {
            if (m < 0) {
                result.footer.height = total / 2;
                result.main.height = total - result.footer.height;
            } else {
                result.main.height = m;
                result.footer.height = total - result.main.height;
            }
        } else {
            result.footer.height = f;
            result.main.height = total - result.footer.height;
        }

    // m
    } else if (h === 0 && m !== 0 && f === 0) {
        result.header.height = 0;
        result.footer.height = 0;
        result.main.height = total;
    }

    return result;
};
