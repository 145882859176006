import { Howl, Howler } from 'howler';
import { Context } from './Context';
import { Console } from '../Console';
//import { Sleep } from '../Sleep';
import { Validate } from '../Validate';
import { MAX_FILENAME_LEN } from '../../constants';

const NAME = 'Audio.web';

const AUDIO_FORMATS = [ 'wav', 'mp3' ];
const DATA_FORMAT = 'data:audio/wav;base64,';


export class Audio {

    static Play(audio, volume = 1.0) {

        if (!Context.Audio()) {
            Console.log(`${NAME}.Play audio is off`);
            return;
        }

        const _NAME = `${NAME}.Play`;

        if (!Validate.isValidNonEmptyString(audio)) {
            return;
        }

        Console.LOG(`${NAME} audio`, { audio: audio.length < MAX_FILENAME_LEN ? audio : audio.length });

        var sound = new Howl({
            // audioPath is either a path or base64 data
            src: [
                audio.indexOf('http') === 0 || audio.length < MAX_FILENAME_LEN
                    ? audio
                    : `${DATA_FORMAT}${audio}`,
            ],
            format: AUDIO_FORMATS,
            volume,
            onload: function () {
                Console.log(`${_NAME}.onload`);
            },
            onloaderror: function (id, error) {
                Console.error(`${_NAME}.onloaderror`, { id, error });
            },
            onlock: function () {
                Console.log(`${_NAME}.onlock`);
            },
            onend: function (id) {
                Console.log(`${_NAME}.onend`, { id });
            },
            onplay: function(id) {
                Console.log(`${_NAME}.onplay`, { id });
            },
            onplayerror: function(id, error) {
                Console.error(`${_NAME}.onplayerror`, { id, error });
                sound.once(`${_NAME} unlock`, function() {
                    Console.log(`${_NAME} - unlocked...\nplaying...`);
                    sound.play();
                });
            },
        });
        Howler.volume(volume);
        sound.volume(volume);
        const durationSeconds = sound.duration(); // MARKMARK: Doesn't work, returns 0
        Console.log(`${_NAME} play`, { durationSeconds });
        sound.play();

        // MARKMARK
        //Console.LOG(`${_NAME} sleeping...`);
        //Sleep.Millis(durationSeconds * 1000 + 1000);
        //Console.LOG(`${_NAME} ...awake`);
    }
}
