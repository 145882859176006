import { NetworkUtils } from '../../components';
import { GetPos } from './GetPos';
import { GetRelation } from './GetRelation';
import { Console, Validate } from '../../utils';

const NAME = 'VocabularyUtils';


export class VocabularyUtils {

    constructor() {
        this.nodes = new Map();
    }

    node(id, parentNode, linkType, data) {

        // get existing node
        var theNode = this.nodes.get(id)?.node;

        // if not found, create a new node
        if (!theNode) {

            // the data from either a sense or a word node
            const {
                // word
                pos,
                root,
                ipa,
                rank,

                // sense
                type,
                definition,
                examples,
                words,

                // sense +
                emojis,
                translations,

                // sense semantic relationships
                semantic,
            } = data;

            // what kind of node is this? check the id...
            const isSense = /[0-9]{8}%[a-z]/.test(id);

            // the display value is either a lemma or the sense type
            const value = isSense ? `<${type}>` : id.split('%')[0].replace(/_/g, ' ');

            // the type is either the sense type, or for words, either 'Root' or 'Lemma'
            var _type = isSense ? type : root === value ? 'Root' : 'Lemma';
//console.log('MARKMARK BUG', { id, parentNode, linkType, data, _type, isSense, root, value });
            // for cases like of n_communication and v_communication, strip out the pos prefix
            if (_type.indexOf('_') >= 0) {
                _type = _type.split('_')[1];
            }

            // senses don't include pos, but it is encoded in the id
            const _pos = isSense ? GetPos(id.split('%')[1]) : pos;

            // the info string, likewise, depends on the node type
            var info = `${_pos}|`;
            if (isSense) {
                info += `${type.replace(/(a|n|r|v)_/, '')}|${definition}`;
                if (Validate.isValid(examples)) {
                    info += `|${examples.join('|')}`;
                }
            } else {
                if (Validate.isValid(rank)) {
                    info += `${rank}`;
                } else {
                    info += ' ';
                }
                if (Validate.isValid(ipa)) {
                    info += `|/${ipa.join('/|/')}/`;
                }
            }

            // create the new node and add it to the collection
            theNode = {
                id,
                type: _type,
                pos: _pos,
                value,
                info,
            };
            if (Validate.isValid(rank)) {
                theNode.rank = rank;
            }
            if (Validate.isValid(ipa) && ipa.length) {
                theNode.ipa = [...ipa];
            }
            if (Validate.isValidNonEmptyString(definition)) {
                theNode.definition = definition;
            }
            if (Validate.isValid(examples) && examples.length) {
                theNode.examples = [...examples];
            }
            if (Validate.isValid(words)) {
                theNode.words = Object.keys(words).map(v => { return v.split('%')[0]; }).sort();
            }


            if (Validate.isValid(emojis) && emojis.length) {
                theNode.emojis = [...emojis];
            }
            if (Validate.isValid(translations)) {
                theNode.translations = {...translations};
            }

            if (isSense && Validate.isValid(semantic)) {
                theNode.semantic = semantic;
            }

            this.nodes.set(id, {
                node: theNode,
                sourceIds: new Map(),
                targetIds: new Map(),
            });
            Console.log(`${NAME}.node adding[${_type}] ${theNode.id}`, { node: theNode, data });
        }

        // if there is a parent node, add the appropriate link
        if (parentNode) {
            this.link(parentNode, theNode, linkType);
        }

        return theNode;
    }

    link(source, target, type) {
        if (!this.nodes.has(source.id) || !this.nodes.has(target.id)) {
            return;
        }
        if (this.nodes.get(source.id).targetIds.has(target.id) ||
            this.nodes.get(target.id).sourceIds.has(source.id))
        {
            return;
        }
        Console.log(`${NAME}.link adding[${type}] ${source.id}->${target.id}`);
        this.nodes.get(source.id).targetIds.set(target.id, type);
        this.nodes.get(target.id).sourceIds.set(source.id, type);
    }

    graph(height, width, textHeight = null) {
        var result = {
            graph: this.nodes,
            nodes: [],
            links: [],
        };
        this.nodes.forEach(({ node, targetIds }, sourceId) => {
            const networkNode = NetworkUtils.Node({
                    ...node,
                    x: width / 2,
                    y: height / 2,
                },
                textHeight,
            );
            Console.log(`${NAME}.graph`, { node, sourceId, targetIds, networkNode });
            result.nodes.push(networkNode);
            targetIds.forEach((type, targetId) => {
                result.links.push(NetworkUtils.Edge({
                    type,
                    sourceId,
                    targetId,
                }));
            });
        });
        return result;
    }

    static InitResult(resultWord, type, relationKey) {
        if (!resultWord[type]) {
            resultWord[type] = {};
        }
        const relation = GetRelation(relationKey);
        if (!resultWord[type][relation]) {
            resultWord[type][relation] = {};
        }
        return resultWord[type][relation];
    }

    static GetKeys(key, lang, dictionary) {
        const keyTokens = key.split('%');
        const word = keyTokens[0];
        const posIdTokens = keyTokens.length === 2 ? keyTokens[1].split(':') : null;
        const pos = posIdTokens ? posIdTokens[0] : null;
        const id = posIdTokens && posIdTokens.length === 2 ? posIdTokens[1] : null;
        var wordKeys = new Set();
        if (id) {
            wordKeys.add(key);
        } else if (pos) {
            const wordData = dictionary.getWord(word, lang);
            if (!wordData || wordData.w[pos]) {
                Console.log(`${NAME}.#findWord: *** invalid word/pos ${key}`, { lang, word, pos, wordData });
            } else {
                Object.keys(wordData.w[pos]).forEach(_id => wordKeys.add(`${word}%${pos}:${_id}`));
            }
        } else if (word) {
            const wordData = dictionary.getWord(word, lang);
            if (!wordData) {
                Console.log(`${NAME}.#findWord: *** invalid word ${key}`, { lang, word });
            } else {
                Object.keys(wordData.w).forEach(_pos => {
                    Object.keys(wordData.w[_pos]).forEach(_id => wordKeys.add(`${word}%${_pos}:${_id}`));
                });
            }
        }
        return [...wordKeys.keys()].sort();
    }

    static HasSpaceOrHyphenOrCap(s) {
        return s.indexOf('_') >= 0 || s.indexOf('-') >= 0
            ? true
            : /[A-Z]/.test(s);
    }

    static HasRankOrIPA(info, minRank = 0) {
        if (!info?.ipa && !info?.rank) {
            return false;
        }
        if (info?.rank && minRank && info.rank > minRank) {
            return false;
        }
        return true;
    }

    static AddWord(wordKey, wordData, lang, dictionary) {
        const wordKeyTokens = wordKey.split('%');
        const lemma = wordKeyTokens[0];
        wordData.pos = GetPos(wordKeyTokens[1].split(':')[0]);

        const info = dictionary.getWord(lemma, lang).i;
        if (info) {
            const { p, r, l } = info;
            if (p && p.length) {
                wordData.ipa = p;
            }
            if (r) {
                wordData.rank = r;
            }
            if (l) {
                wordData.root = l;
            }
        }
        return wordData;
    }
}
