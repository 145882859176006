
import 'dayjs/locale/en';
import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';
import 'dayjs/locale/es-us'; // MARKMARK: es-us ??? what's the diff?
import 'dayjs/locale/pt';
import 'dayjs/locale/pt-br';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ro';

import 'dayjs/locale/de';
import 'dayjs/locale/hi';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/ru';
import 'dayjs/locale/zh-cn';
import 'dayjs/locale/zh-tw';
import 'dayjs/locale/ar-sa';


export const languages = {
    en_us: {
        flag: 'us',
        title: 'ENGLISH',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    haw_hi: {
        flag: 'hi',
        title: 'HAWAIIAN',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    ja_jp: {
        flag: 'jp',
        title: 'JAPANESE',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    es_es: {
        flag: 'es',
        title: 'SPANISH',
        x: " ~-'¿?¡!",
        uc: 'AÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ',
        lc: 'aábcdeéfghiíjklmnñoópqrstuúüvwxyz',
    },
    fr_fr: { // https://en.wikipedia.org/wiki/French_orthography#Alphabet
        flag: 'fr',
        title: 'FRENCH',
        x: " ~-'?!",
        uc: 'AÀÂBCÇDEÉÈÊËFGHIÎÏJKLMNOÔPQRSTUÙÛÜVWXYŸZ', // Ç added
        lc: 'aàâbcçdeéèêëfghiîïjklmnoôpqrstuùûüvwxyÿz',
    },
    pt_pt: { // https://en.wikipedia.org/wiki/Portuguese_orthography#Alphabet
        flag: 'pt',
        title: 'PORTUGUESE',
        x: " ~-'?!",
        uc: 'AÁÀÂÃBCÇDEÉÊFGHIÍJKLMNOÓÔÕPQRSTUÚVWXYZ',
        lc: 'aáàâãbcçdeéêfghiíjklmnoóôõpqrstuúvwxyz',
    },
    de_de: {
        flag: 'de',
        title: 'GERMAN',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    it_it: { // https://en.wikipedia.org/wiki/Italian_orthography#Alphabet
        flag: 'it',
        title: 'ITALIAN',
        x: " ~-'?!",
        uc: 'AÀBCDEÉÈFGHIÌÍÎJKLMNOÓÒPQRSTUÚÙVWXYZ',
        lc: 'aàbcdeéèfghiìíîjklmnoóòpqrstuúùvwxyz',
    },

    /*
    en_gb: {
        flag: 'gb',
        title: 'ENGLISH',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    zh_cn: {
        flag: 'cn',
        title: 'CHINESE',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },*/
    /**/
    zh_tw: {
        flag: 'tw',
        title: 'CHINESE',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    hi_in: {
        flag: 'in',
        title: 'HINDI',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },

    /*
    es_mx: {
        flag: 'mx',
        title: 'SPANISH',
        x: " ~-'¿?¡!",
        uc: 'AÁBCDEÉFGHIÍJKLMNÑOÓPQRSTUÚÜVWXYZ',
        lc: 'aábcdeéfghiíjklmnñoópqrstuúüvwxyz',
    },
    */
    ar_sa: {
        flag: 'sa',
        title: 'ARABIC',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    ru_ru: {
        flag: 'ru',
        title: 'RUSSIAN',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    /*
    pt_br: { // https://en.wikipedia.org/wiki/Portuguese_orthography#Alphabet
        flag: 'br',
        title: 'PORTUGUESE',
        x: " ~-'?!",
        uc: 'AÁÀÂÃBCÇDEÉÊFGHIÍJKLMNOÓÔÕPQRSTUÚVWXYZ',
        lc: 'aáàâãbcçdeéêfghiíjklmnoóôõpqrstuúvwxyz',
    },
    */
    /**/

    /**/
    ko_kr: {
        flag: 'kr',
        title: 'KOREAN',
        x: "_ ~-'?!",
        uc: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        lc: 'abcdefghijklmnopqrstuvwxyz',
    },
    ro_ro: { // https://en.wikipedia.org/wiki/Romanian_alphabet
        flag: 'ro',
        title: 'ROMANIAN',
        x: " ~-'?!",
        uc: 'AĂÂBCDEFGHIÎJKLMNOPQRSŞȘTŢȚUVWXYZ', // ŞŢ added
        lc: 'aăâbcdefghiîjklmnopqrsşștţțuvwxyz',
    }, // https://en.wikipedia.org/wiki/Romanian_profanity
    /**/
};

export const languageSynonyms = {
    /*
    ar_001: 'ar_sa',
    es_us: 'es_mx',
    es_419: 'es_mx',
    */
};

export const localeLookup = new Map([
    ['en_us', 'en'],
    ['en_gb', 'en-gb'],
    ['es_es', 'es'],
    ['es_mx', 'es-mx'],
    ['pt_pt', 'pt'],
    ['pt_br', 'pt-br'],
    ['fr_fr', 'fr'],
    ['it_it', 'it'],
    ['ro_ro', 'ro'],
    ['de_de', 'de'],
    ['hi_in', 'hi'],
    ['ja_jp', 'ja'],
    ['ko_ko', 'ko'],
    ['ru_ru', 'ru'],
    ['zh_cn', 'zh-cn'],
    ['zh_tw', 'zh-tw'],
    ['ar_sa', 'ar-sa'],
    ['haw_hi', 'en'],
]);
