import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ImageBackground, Keyboard, StyleSheet } from 'react-native'; // MARKMARK: make ImageBackground a component?
import { GiftedChat, Actions, Avatar, Bubble, Composer, Day, InputToolbar, Message, MessageText, Send } from 'react-native-gifted-chat';
import { Flag, Icon, Microphone, Screen, Text, TEXT_VARIANTS, View } from '../components';
import { Icons, Images } from '../media';
import { useAppState, useMessagesDispatch, useMessagesState, useSystemState, useUserState, useUsersState, MESSAGES_TYPES } from '../context';
import { artist, chatbot } from '../ai';
import { ChatStyles } from './styles';
import { NavParams } from './utils';
import { MAX_CONTENT_LEN, USE_DEV_COLOR, DEV_COLOR, LOCALE_LOOKUP } from '../constants';
import { Colors } from '../styles';
import { Console, Numbers, Optional, Speech } from '../utils';

const NAME = 'Chat';

const HUMAN_PROMPT = ' Human:';
const AI_PROMPT = ' AI:';

const EMPTY_ARRAY = [];
const EMPTY_STRING = '';
const SEPERATOR = '|';
const SEND_MESSAGE = 'Send a message';
// const NO_MESSAGES = 'No messages'; // MARKMARK: localization files already have this

const TRANSLATE_COLOR = [
    Colors.colors.green,
    Colors.colors.orange,
    Colors.colors.red,
];

class ChatUtils {
    static updateMessages(messages, user, bot, translate) {

        Console.log('ChatUtils.updateMessages: input', { messages, user, bot, translate });

        const result = messages.map((message, arrIndex, arr) => {

            // create our updated message
            var updatedMessage = {
                ...message,
                _id: arrIndex,
                user: message.senderId === user.username ? { ...user, name: user.username } : bot,
            };

            //delete updatedMessage.content;
            //updateMessage(message, user, bot, arrIndex);

            if (arrIndex && !updatedMessage?.language) { // MARKMARK arrIndex && user === ? bot/user

                // get the next message (in the reversed messages)
                const nextMsg = { ...arr[arrIndex - 1] };

                // update the current (user) message with info from the 'next' (bot)
                if (nextMsg?.language) {

                    // the bot language
                    updatedMessage.language = nextMsg.language;

                    // the user's english translation
                    if (nextMsg?.previous_translation) {
                        updatedMessage.translation = nextMsg.previous_translation;
                    }

                    // the user's mode language translation
                    if (nextMsg?.previous_mode) {
                        updatedMessage.mode = nextMsg.previous_mode;
                    }

                    // the user's bot language ipa
                    if (nextMsg?.previous_ipa) {
                        updatedMessage.message_ipa = nextMsg.previous_ipa;
                    }
                }
            }

            // set the text based on the translate switch
            switch (translate) {
                case 1:
                    if (updatedMessage?.mode?.length) {
                        updatedMessage.text = updatedMessage.mode;
                    }
                    break;
                case 2:
                    if (updatedMessage?.message_ipa?.length) {
                        updatedMessage.text = updatedMessage.message_ipa;
                    }
                    break;
                default:
                    if (updatedMessage?.message_emoji?.length) {
                        updatedMessage.text = updatedMessage.message_emoji;
                    } else if (updatedMessage?.message?.length) {
                        updatedMessage.text = updatedMessage.message;
                    }
                    break;
            }

            Console.log(`ChatUtils.updateMessages: Mapping Message (${arrIndex}) [translate=${translate}]`, { message, updatedMessage });
            return updatedMessage;
        });

        Console.log(`ChatUtils.updateMessages: result [translate=${translate}]`, { result });
        return result;
    }
}

export const Chat = props => {

    const {
        navigation,
        route,
    } = props;

    const messagesDispatch = useMessagesDispatch();
    const messagesDispatchRef = useRef(messagesDispatch);

    const { isWeb, isIOS, deviceScale } = useSystemState();
    const { dark, audio, language, t, aiPrompt, aiEMOJIS, aiIPA, phone, image } = useAppState();
    const { messages, messageCount } = useMessagesState();
    const { user } = useUserState();
    const { bot/*, socketUrl*/ } = useUsersState();

    const [numMessages, setNumMessages] = useState(0);
    const setNumMessagesRef = useRef(setNumMessages);

    const [botMessage, setBotMessage] = useState(EMPTY_STRING);
    const setBotMessageRef = useRef(setBotMessage);

    const [nextMessage, setNextMessage] = useState(EMPTY_STRING);
    const setNextMessageRef = useRef(setNextMessage);

    const [adSearch, setAdSearch] = useState(null);
    const setAdSearchRef = useRef(setAdSearch);

    const [chatMessages, setChatMessages] = useState(EMPTY_ARRAY);
    const setChatMessagesRef = useRef(setChatMessages);

    const [typing, setTyping] = useState(false);
    const setTypingRef = useRef(setTyping);

    const [transcription, setTranscription] = useState(EMPTY_STRING);
    const setTranscriptionRef = useRef(setTranscription);

    const [customText, setCustomText] = useState(EMPTY_STRING);
    const setCustomTextRef = useRef(setCustomText);

    const [chatStyles, setChatStyles] = useState({});
    const setChatStylesRef = useRef(setChatStyles);

    const [styleChanged, setStyleChanged] = useState(0);
    const setStyleChangedRef = useRef(setStyleChanged);

    const [translate, setTranslate] = useState(0);
    const setTranslateRef = useRef(setTranslate);

    const [botVoice, setBotVoice] = useState(null);
    const setBotVoiceRef = useRef(setBotVoice);

    const [modeVoice, setModeVoice] = useState(null);
    const setModeVoiceRef = useRef(setModeVoice);

    useEffect(
        () => {
            const parmsLang = NavParams(route)?.language;
            const parmsTitle = NavParams(route)?.title;
            const title = parmsTitle ? parmsTitle : NAME;
            Console.log(`${NAME} useEffect entry`, { route, parmsTitle, parmsLang, title });
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={TEXT_VARIANTS.TITLE}
                    />
                ),
            });
            /*
            const parmsLang = NavParams(route)?.language;
            if (parmsLang) {
                setLangRef.current(parmsLang);
            }
            */
        },
        [
            route,
            navigation,
            // language,
            // setLangRef,
        ],
    );

    useEffect(
        () => {
            setChatStylesRef.current(ChatStyles(isWeb, dark, deviceScale, bot.language, user.language, styles));
            setStyleChangedRef.current(v => v + 1);
        },
        [
            bot.language,
            user.language,
            isWeb,
            dark,
            deviceScale,
            setChatStylesRef,
            setStyleChangedRef,
        ],
    );

    useEffect(
        () => {
            if (audio && botMessage?.length) {
                const parms = botMessage.split(SEPERATOR);
                Console.LOG(`${NAME} useEffect talk`, { isIOS, audio, botMessage, parms, language, translateShouldBeFalse: translate });
                if (parms?.length === 3) {
                    const _lang = translate === 1 ? language : parms[1];
                    const voice = Speech.Talk(isIOS, parms[0], _lang, botVoice, parms[2]);
                    setBotVoiceRef.current(v => v ? v : voice);
                }
                setBotMessageRef.current(EMPTY_STRING);
            }
        },
        [
            isIOS,
            translate,
            language,
            audio,
            botMessage,
            setBotMessageRef,
            botVoice,
            setBotVoiceRef,
            setAdSearchRef,
        ],
    );

    const addMessage = useCallback(
        payload => {
            Console.log(`${NAME}.addMessage`, { payload });
            messagesDispatchRef.current({ type: MESSAGES_TYPES.ADD_MESSAGE, payload });
        },
        [
            messagesDispatchRef,
        ],
    );

    const onSend = useCallback(
        text => {
            const variables = {
                senderId: user.username,
                recipientId: bot.name,
                text,
            };
            Console.LOG(`${NAME}.onSend user_utterred`, { variables });
            addMessage(variables);

            // once a message is sent, the ui should generally reset as well
            setCustomTextRef.current(EMPTY_ARRAY);
            setNextMessageRef.current(EMPTY_ARRAY);
            setTranscriptionRef.current(EMPTY_STRING);
            setTranslateRef.current(0);
            Keyboard.dismiss();
        },
        [
            user.username,
            bot.name,
            setCustomTextRef,
            setNextMessageRef,
            setTranscriptionRef,
            setTranslateRef,
            addMessage,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect transcription`, { transcription, phone });
            if (phone) {
                if (transcription?.length) {
                    onSend({ text: transcription });
                }
            } else {
                setCustomTextRef.current(transcription);
            }
        },
        [
            phone,
            transcription,
            setCustomTextRef,
            onSend,
        ],
    );

    useEffect(
        () => {
            if (adSearch?.length) {
                const variables = {
                    text: adSearch,
                    system: true,
                };
                Console.log(`${NAME} useEffect ads`);
                addMessage(variables);
                setAdSearchRef.current(null);
            }
        },
        [
            adSearch,
            setAdSearchRef,
            addMessage,
        ],
    );

    useEffect(
        () => {
            var msgs = ChatUtils.updateMessages(messages, user, bot, translate);
            Console.log(`${NAME} useEffect message count [${messageCount}] ${msgs?.length}`, { user, bot, translate, image });

            if (msgs?.length) {
                Console.log(`${NAME} useEffect message`, { message0: msgs[0] });
                const userSentLastMessage = msgs[0].senderId === user.username;
                setTypingRef.current(userSentLastMessage);
                if (userSentLastMessage && numMessages !== messageCount) {
                    //if (SOCKETS[bot.id]) {
                    //    SOCKETS[bot.id].emit(msgs[0].text);
                    //}
                    if (image) {
                        (async () => {
                            const request = { prompt: msgs[0].text };
                            const response = await artist(user.username, request.prompt);
                            const variables = {
                                senderId: bot.name,
                                recipientId: user.username,
                                image: response?.message,
                                system: true,
                            };
                            Console.log(`${NAME} artist`, { request, response, variables });
                            addMessage(variables);
                        })();
                    } else {
                        (async () => {

                            var now = new Date();
                            var nowString = `${now.toDateString()}, ${now.toLocaleTimeString()}`;

                            var request = {
                                language,
                                lang: bot.language,
                                user: user.username,
                                message: msgs[0].text,

                                translation: msgs[0].translation,
                                mode: msgs[0].mode,

                                chitchat: msgs.slice(0).reverse().filter(v => v.senderId === bot.name).map(v =>
                                    `${HUMAN_PROMPT} ${v.previous_translation}|${AI_PROMPT} ${v.translation}`).join(SEPERATOR),

                                options: {
                                    prompt: aiPrompt
                                        .replace(/<DATE>/g, nowString)
                                        .replace(/<NAME>/g, bot.name)
                                        .replace(/<GENDER>/g, bot.gender)
                                        .replace(/<DOB>/g, bot.dob)
                                        .replace(/<LOCATION>/g, bot.location)
                                        .replace(/<FORMAL>/g, bot.formal ? 'formal' : 'friendly'),
                                },
                            };

                            // if the user message matches the next message, pass the translation along
                            if (request.translation?.length) {
                                console.log('\n\n\n\n REQUEST TRANSLATION', request);
                            }
                            const nextMessageTokens = nextMessage?.length ? nextMessage.split(SEPERATOR) : EMPTY_ARRAY;
                            if (nextMessageTokens.length === 2 && request.message === nextMessageTokens[0]) {
                                request.translation = nextMessageTokens[1];
                            }

                            //=============================

                            const response = await chatbot(
                                request.language, // language
                                request.lang, // bot language
                                request.user, // user id
                                request.message, // bot language
                                request.translation, // translated to english
                                request.mode, // translated to <language>
                                request.chitchat, // chat history in english
                                request.options, // our prompt, aiPrompt with substitutions
                                aiEMOJIS,
                                aiIPA,
                            );

                            //=============================

                            var result = {};

                            // s/b language, falls back to the request language
                            result.language = response?.language
                                ? response.language.toLowerCase()
                                : request?.language;

                            // the bot response in the bot language
                            result.message = response?.message;

                            // the bot response in the bot language with emoji
                            result.message_emoji = response?.message_emoji;

                            // the bot response ipa in the bot language
                            result.message_ipa = response?.message_ipa;

                            // the bot response in english
                            result.translation = response?.translation;

                            // the bot response in the mode language
                            result.mode = response?.mode;

                            // the previous user message ipa in the bot language
                            result.previous_ipa = response?.previous_ipa;

                            // the previous user message in english
                            result.previous_translation = response?.previous_translation;

                            // the previous user message in the mode language
                            result.previous_mode = response?.previous_mode;

                            // set the next message
                            if (response?.next_message && response?.next_translation) {
                                setNextMessageRef.current(`${response.next_message}|${response.next_translation}`);
                            }

                            // set the ad search
                            if (response?.search) {
                                setAdSearchRef.current(response?.search);
                            }

                            Console.LOG('\n\nCHATBOT\n', { msgs0: msgs[0], request, response, result });
                            Console.LOG('\n\n');

                            if (result?.message?.length) {
                                const msg = `${result.message}|${bot.language}|${bot.gender}|`;
                                const variables = {
                                    senderId: bot.name,
                                    recipientId: user.username,
                                    text: result.message,

                                    // raw payload
                                    language: result.language,
                                    message: result.message,
                                    message_emoji: result.message_emoji,
                                    message_ipa: result.message_ipa,
                                    translation: result.translation,
                                    mode: result.mode,
                                    previous_ipa: result.previous_ipa,
                                    previous_translation: result.previous_translation,
                                    previous_mode: result.previous_mode,
                                };
                                Console.LOG(`${NAME} useEffect bot_utterred`, { msg, variables });
                                addMessage(variables);
                                setBotMessageRef.current(msg);
                            } else {
                                Console.warn(`${NAME} useEffect bot_uttered bad result`, { result });
                            }
                        })();
                    }
                }
            }
            setChatMessagesRef.current(msgs);
            setNumMessagesRef.current(msgs.length);
        },
        [
            numMessages,
            setNumMessagesRef,
            image,
            language,
            messages,
            messageCount,
            user,
            bot,
            setTypingRef,
            setChatMessagesRef,
            setBotMessageRef,
            translate,
            aiPrompt,
            aiEMOJIS,
            aiIPA,
            nextMessage,
            setNextMessageRef,
            setAdSearchRef,
            addMessage,
        ],
    );

    const onInputTextChanged = useCallback(
        text => {
            Console.trace(`${NAME}.onInputTextChanged`, { text });
            setCustomTextRef.current(text);
        },
        [
            setCustomTextRef,
        ],
    );

    const renderAvatar = useCallback(
        _props => {
            Console.log(`${NAME}.renderAvatar`, { _props });
            return (
                <Avatar
                    {..._props}
                    containerStyle={chatStyles.avatarContainer}
                    imageStyle={chatStyles.avatarImage}
                    renderAvatar={() => (
                        <Flag
                            country={_props.currentMessage.user.language.split('_')[1]}
                        />
                    )}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderBubble = useCallback(
        _props => {

            const onPress = (context, message, _translate) => {
                Console.LOG(`${NAME}.renderBubble.onPress`, { context, message, _translate });
            };

            const onLongPress = (context, message, _translate) => {
                const _text = _translate === 1 // translate to mode
                    ? message?.mode // send the mode translation
                    : message?.message; // send the bot language message
                const _lang = _translate === 1
                    ? language // the mode language
                    : bot.language; // the bot language
                const _inVoice = _translate === 1
                    ? modeVoice // the mode language voice
                    : botVoice; // the bot voice

                const rate = translate === 2 ? 0.85 : 1.0;
                const _outVoice = Speech.Talk(isIOS, _text, _lang, _inVoice, bot.gender, rate);

                Console.LOG(`${NAME}.renderBubble.onLongPress`, { isIOS, context, message, language, rate, _translate, botVoice, modeVoice, botLanguage: bot.language, botGender: bot.gender, _lang, _inVoice, _outVoice });

                if (!_inVoice) {
                    if (translate) {
                        setModeVoiceRef.current(v => v ? v : _outVoice);
                    } else {
                        setBotVoiceRef.current(v => v ? v : _outVoice);
                    }
                }
            };

            Console.log(`${NAME}.renderBubble`, { _props, botLanguage: bot.language, botGender: bot.gender, translate, chatStyles, styleChanged, leftColor: chatStyles.bubbleContent.left.backgroundColor, rightColor: chatStyles.bubbleContent.right.backgroundColor });

            return (
                <Bubble
                    {..._props}
                    contentStyle={chatStyles.bubbleContent}
                    wrapperStyle={chatStyles.bubbleWrapper}
                    textStyle={chatStyles.bubbleText}
                    containerToNextStyle={chatStyles.containerToNext}
                    containerToPreviousStyle={chatStyles.containerToPrevious}
                    tickStyle={chatStyles.tick}
                    onPress={(_context, _message) => onPress(_context, _message, translate)}
                    onLongPress={(_context, _message) => onLongPress(_context, _message, translate)}
                />
            );
        },
        [
            isIOS,
            bot.gender,
            bot.language,
            botVoice,
            modeVoice,
            setBotVoiceRef,
            setModeVoiceRef,
            language,
            translate,
            chatStyles,
            styleChanged,
        ],
    );

    const renderMessage = useCallback(
        _props => {
            Console.log(`${NAME}.renderMessage`, { _props, chatStyles, styleChanged });
            return (
                <Message
                    {..._props}
                    containerStyle={chatStyles.messageContainer}
                />
            );
        },
        [
            chatStyles,
            styleChanged,
        ],
    );

    const renderMessageText = useCallback(
        _props => {
            Console.log(`${NAME}.renderMessageText`, { styleChanged, currentMessage: _props?.currentMessage, leftColor: chatStyles.messageText.left.color, rightColor: chatStyles.messageText.right.color });
            return (
                <MessageText
                    {..._props}
                    containerStyle={chatStyles.messageTextContainer}
                    textStyle={chatStyles.messageText}
                    textProps={{ selectable: false }}
                />
            );
        },
        [
            chatStyles,
            styleChanged,
        ],
    );

    const renderDay = useCallback(
        _props => {
            Console.log(`${NAME}.renderDay`, { _props, chatStyles });
            return (
                <Day
                    {..._props}
                    containerStyle={chatStyles.dayContainer}
                    textStyle={chatStyles.dayText}
                    textProps={{ selectable: false }}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderInputToolbar = useCallback(
        _props => {
            Console.log(`${NAME}.renderInputToolbar`, { _props, chatStyles });
            return (
                <InputToolbar
                    {..._props}
                    containerStyle={chatStyles.inputToolbarContainer}
                    primaryStyle={chatStyles.inputToolbarPrimary}
                    accessoryStyle={chatStyles.inputToolbarAccessory}
                />
            );
        },
        [
            chatStyles,
        ],
    );

    const renderComposer = useCallback(
        _props => {
            Console.log(`${NAME}.renderComposer`, { _props, isWeb, chatStyles });

            // composerHeight={chatStyles.minComposerHeight}
            return (
                <Composer
                    {..._props}
                    multiline={true}
                    textInputStyle={chatStyles.textInput}
                    textInputProps={{
                        ..._props.textInputProps,
                        blurOnSubmit: isWeb,
                        onSubmitEditing: !isWeb ? undefined : () => {
                            if (_props.text && _props.onSend) {
                                Console.LOG(`\n\n\n\n${NAME}.renderComposer onSubmitEditing`, { _props });
                                _props.onSend({ text: _props.text.trim() });
                            }
                        },
                    }}
                />
            );
        },
        [
            isWeb,
            chatStyles,
        ],
    );

    const renderActions = useCallback(
        _props => {
            Console.log(`${NAME}.renderActions`, { _props, customText, nextMessage, translate });
            return (
                <Actions
                    {..._props}
                    containerStyle={chatStyles.actionsContainer}
                    textStyle={chatStyles.actionsText}
                    icon={() => (
                        <View
                            style={styles.row}
                        >
                            <Icon
                                icon={Icons.Translate}
                                color={TRANSLATE_COLOR[translate]}
                                onPress={() => setTranslateRef.current(v => (v + 1) % TRANSLATE_COLOR.length)}
                            />
                            {Optional(customText?.length, (
                                <Icon
                                    icon={Icons.Delete}
                                    onPress={() => setCustomTextRef.current(EMPTY_STRING)}
                                />
                            ), Optional(nextMessage?.length, (
                                <Icon
                                    icon={Icons.SkipNext}
                                    onPress={() => setCustomTextRef.current(nextMessage.split(SEPERATOR)[0])}
                                />
                            )))}
                        </View>
                    )}
                />
            );
        },
        [
            chatStyles,
            nextMessage,
            customText,
            setCustomTextRef,
            translate,
            setTranslateRef,
        ],
    );

    const renderSend = useCallback(
        _props => {
            Console.log(`${NAME}.renderSend`, { _props });
            return (
                <Send
                    {..._props}
                    containerStyle={chatStyles.sendContainer}
                    textStyle={chatStyles.sendText}
                >
                    <View
                        value={'ChatSend'}
                    >
                        {Optional(customText?.length, (
                            <Icon
                                icon={Icons.Send}
                            />
                        ), (
                            <Microphone
                                languageOverride={bot.language}
                                setTranscriptionRef={setTranscriptionRef}
                            />
                        ))}
                    </View>
                </Send>
            );
        },
        [
            bot.language,
            customText,
            setTranscriptionRef,
            chatStyles,
        ],
    );

    Console.devStack(NAME, props, { audio, isWeb, isIOS, phone, image, deviceScale, user, bot, typing, messages/*: messages?.length*/, dark, chatMessages/*: chatMessages?.length, chatStyles,*/, translate });

    return useMemo(
        () => {
            const currencyImages = Images.money[bot.language.split('_')[1]];
            const currencyImageKeys = Object.keys(currencyImages);
            var moneyImages = currencyImageKeys
                .filter(key => key.indexOf(dark ? 'r_' : 'o_') === 0)
                .map(key => currencyImages[key]);
            if (!moneyImages?.length && dark) {
                moneyImages = currencyImageKeys
                    .filter(key => key.indexOf('o_') === 0)
                    .map(key => currencyImages[key]);
            }

            Console.log(`${NAME} render`, { chatMessages, dark, styleChanged, bot, typing });

            /*
                <NewMessageSubscription
                    dispatch={messagesDispatchRef.current}
                    type={MESSAGES_TYPES.ADD_MESSAGE}
                />
                <NewReactionSubscription
                    dispatch={messagesDispatchRef.current}
                    type={MESSAGES_TYPES.ADD_REACTION}
                />

                extraData={dark}
                onPressActionButton={onPressActionButton}
                renderChatEmpty={renderChatEmpty}
            */

            return (
                <Screen
                    {...props}
                    style={styles.full}
                    value={NAME}
                >
                    <ImageBackground
                        style={styles.full}
                        imageStyle={styles.image}
                        source={Numbers.randomItem(moneyImages)}
                    >
                        <GiftedChat
                            messageIdGenerator={() => `${messages ? messages.length : 0}`}
                            locale={LOCALE_LOOKUP.get(bot.language)}
                            text={customText}
                            placeholder={t(SEND_MESSAGE)}
                            user={{
                                _id: user._id,
                                name: user.username,
                                avatar: null,
                                language,
                            }}
                            isTyping={typing}
                            messages={chatMessages}
                            renderAccessory={null}
                            renderActions={renderActions}
                            renderAvatar={renderAvatar}
                            renderBubble={renderBubble}
                            renderChatFooter={null}
                            renderComposer={renderComposer}
                            renderCustomView={null}
                            renderDay={renderDay}
                            renderFooter={null}
                            renderInputToolbar={renderInputToolbar}
                            renderMessage={renderMessage}
                            renderMessageAudio={null}
                            renderMessageImage={null}
                            renderMessageText={renderMessageText}
                            renderMessageVideo={null}
                            renderSend={renderSend}
                            renderTime={() => null}
                            scrollToBottom
                            scrollToBottomComponent={() => (<Icon icon={Icons.ScrollToBottom} />)}
                            onInputTextChanged={onInputTextChanged}
                            onSend={_msgs => _msgs?.length ? onSend(_msgs[0].text) : {}}
                            alwaysShowSend={true}
                            showUserAvatar={true}
                            shouldUpdateMessage={(_props, nextProps) => _props.extraData !== nextProps.extraData}
                            minInputToolbarHeight={chatStyles.minInputToolbarHeight}
                            minComposerHeight={chatStyles.minComposerHeight}
                            maxComposerHeight={chatStyles.maxComposerHeight}
                            maxInputLength={MAX_CONTENT_LEN}
                            onPressAvator={u => Console.LOG('Press Avatar', { u })}
                            onLongPressAvator={u => Console.LOG('Long Press Avatar', { u })}
                        />
                    </ImageBackground>
                </Screen>
            );
        },
        [
            t,
            language,
            props,
            chatStyles,
            styleChanged,
            dark,
            user,
            bot,
            chatMessages,
            customText,
            typing,
            messages,
            onInputTextChanged,
            renderActions,
            renderAvatar,
            renderBubble,
            renderComposer,
            renderInputToolbar,
            renderMessage,
            renderMessageText,
            renderSend,
            renderDay,
            onSend,
        ],
    );
};

const devStyle = {
    borderWidth: USE_DEV_COLOR ? 1 : 0,
    borderColor: Colors.colors.black,
};

const styles = StyleSheet.create({
    full: {
        width: '100%',
        height: '100%',
    },
    image: {
        marginBottom: '70%',
        height: '30%',
        resizeMode: 'contain',
        opacity: 0.3,
        //      transform: [{ rotate: '90deg' }],
    },
    avatarContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lime),
    },
    avatarImage: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    dayContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lime),
    },
    dayText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    bubbleContent: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.purple),
    },
    bubbleWrapper: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    bubbleText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.yellow),
    },
    containerToNext: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.green),
    },
    containerToPrevious: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.red),
    },
    bubbleTick: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.black),
    },
    messageContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.green),
        alignItems: 'center',
        justifyContent: 'center',
    },
    messageTextContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.red),
    },
    messageText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightgreen),
    },
    inputToolbarContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.cyan),
    },
    inputToolbarAccessory: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.magenta),
    },
    inputToolbarPrimary: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.orange),
        alignItems: 'center',
        justifyContent: 'center',
    },
    actionsContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.blue),
        alignItems: 'center',
        justifyContent: 'center',
        width: 100,
    },
    actionsText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    sendContainer: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.blue),
        alignItems: 'center',
        justifyContent: 'center',
    },
    sendText: {
        ...devStyle,
        borderColor: DEV_COLOR(Colors.colors.lightblue),
    },
    row: {
        flexDirection: 'row',
    },
});
