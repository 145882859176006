import React from 'react';
import { G, Circle, Rect, Text } from '../../../svg';
import { Numbers } from '../../../utils';

const BODY_PROPS = {
    x: 0,
    y: 0,
    cx: 0,
    cy: 0,
    opacity: 1,
    fontSize: 50, // MARKMARK deviceSize ???
    textAnchor: 'middle',
};

class SvgUtils {
    static RigidBody({ size, body, color }, translate = true) {
        const { position, angle } = body;
        const width = size[0];
        const height = size[1];
        const offset = { x: width / 2, y: height / 2 };
        const delta = translate ? offset : { x: 0, y: 0 };
        const x = position.x - delta.x;
        const y = position.y - delta.y;
        const fill = color;
        const rotation = Numbers.toDegrees(angle);
        return {
            body: {
                ...BODY_PROPS,
                width,
                height,
                fill,
                r: Math.max(width, height) / 2,
            },
            g: {
                x,
                y,
                rotationx: delta.x,
                rotationy: delta.y,
                rotation,
            },
        };
    }
}

export const SvgRect = props => {
    const { body, g } = SvgUtils.RigidBody(props);
    return <G {...g} ><Rect {...body} /></G>;
};

export const SvgCircle = props => {
    const { body, g } = SvgUtils.RigidBody(props, false);
    return <G {...g} ><Circle {...body} /></G>;
};

export const SvgText = text => {
    return props => {
        const { body, g } = SvgUtils.RigidBody(props, false);
        return <G {...g} ><Text {...body}>{text}</Text></G>;
    };
};
