import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, View } from '../components';
import { useAppState, useSystemState } from '../context';
import { Audio, Console, Viewer3D } from '../utils';
import { Colors } from '../styles';
import { S3_SERVER } from '../constants';

const NAME = 'Speech3D';

const MALE = 'Male';
const FEMALE = 'Female';

const baseXform = { cx: 0, cy: 0, cz: 0, tx: 0, ty: 0, tz: 0, rx: 0, ry: 0, rz: 0, sx: 1, sy: 1, sz: 1 };
const xform = (gender, overrideProps = {}) => {
    // +y is up
    // +z is right
    const genderProps = gender === 'm'
        ? { cx: -160, ty: -40, tz: 20, rx: -90 }
        : { cx: -220, ty: 30, tz: 70, rx: -80 };
    return { ...baseXform, ...genderProps, ...overrideProps };
};

const STL = [
    { stl: 'a_tense', phoneme: 'aː', word: 'bahn', ipa: '/baːn/', xform: { f: { ty: 20, tz: 0 } } },
    { stl: 'a_lax', phoneme: 'a', word: 'bass', ipa: '/bas/' },
    { stl: 'ae_tense', phoneme: 'εː', word: 'lähmung', ipa: '/lεːmʊŋ/' },
    { stl: 'ae_lax', phoneme: 'ε', word: 'bett', ipa: '/bεt/' },
    { stl: 'e_tense', phoneme: 'eː', word: 'beet', ipa: '/beːt/', xform: { f: { ty: -45, tz: 0, rx: -90 } } },
    { stl: 'i_tense', phoneme: 'iː', word: 'tiere', ipa: '/tiːʁə/' },
    { stl: 'i_lax', phoneme: 'I', word: 'mit', ipa: '/mIt/' },
    { stl: 'o_tense', phoneme: 'oː', word: 'boote', ipa: '/boːtə/', xform: { f: { ty: -50, tz: 0, rx: -90 } } },
    { stl: 'o_lax', phoneme: 'ɔ', word: 'offen', ipa: '/ɔfn/' },
    { stl: 'oe_tense', phoneme: 'øː', word: 'höhle', ipa: '/høːlə/', xform: { f: { ty: 40 } } },
    { stl: 'oe_lax', phoneme: 'œ', word: 'götter', ipa: '/gœtɐ/' },
    { stl: 'u_tense', phoneme: 'uː', word: 'bude', ipa: '/buːde/', xform: { f: { ty: 40 } } },
    { stl: 'u_lax', phoneme: 'ʊ', word: 'butter', ipa: '/bʊtɐ/', xform: { f: { ty: 35 } } },
    { stl: 'y_tense', phoneme: 'yː', word: 'güte', ipa: '/gyːtə/', xform: { f: { ty: 40 } } },
    { stl: 'y_lax', phoneme: 'Y', word: 'mütter', ipa: '/mYtɐ/' },
    { stl: 'c', phoneme: 'ç', word: 'ich', ipa: '/Iç/', xform: { f: { ty: -40, tz: 0, rx: -90 } } },
    { stl: 'f', phoneme: 'f', word: 'fahrt', ipa: '/faːʁt/' },
    { stl: 'l', phoneme: 'l', word: 'los', ipa: '/loːs/' },
    { stl: 's', phoneme: 's', word: 'bass', ipa: '/bas/' },
    { stl: 'sh', phoneme: '∫', word: 'schön', ipa: '/∫øːn/' },
    { stl: 'schwa', phoneme: 'ə', word: 'ehe', ipa: '/eːə/' },
    { stl: 'x', phoneme: 'x', word: 'ach', ipa: '/ax/' },
];

export const Speech3D = props => {

    const {
    } = props;

    const { isWeb, adjHeight, width } = useSystemState();
    const { t, stl } = useAppState();

    const [model, setModel] = useState(STL[stl % STL.length]);
    const setModelRef = useRef(setModel);
    const [gender, setGender] = useState('m');
    const setGenderRef = useRef(setGender);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect stl`, { stl });
            setModelRef.current(STL[stl % STL.length]);
        },
        [
            stl,
            setModelRef,
        ],
    );

    // GERMAN (used)
    // Birkholz, Peter; Kürbis, Steffen; Stone, Simon; Häsner, Patrick; Blandin, Rémi; Fleischer, Mario (2020):
    // Printable 3D vocal tract shapes from MRI data and their acoustic and aerodynamic properties.
    // https://doi.org/10.6084/m9.figshare.c.4869732.v1


    // FRENCH
    //     TY    - JOUR
    //     AU    - Birkholz, Peter
    //     AU    - Kürbis, Steffen
    //     AU    - Stone, Simon
    //     AU    - Häsner, Patrick
    //     AU    - Blandin, Rémi
    //     AU    - Fleischer, Mario
    //     PY    - 2020
    //     DA    - 2020/08/05
    //     TI    - Printable 3D vocal tract shapes from MRI data and their acoustic and aerodynamic properties
    //     JO    - Scientific Data
    //     SP    - 255
    //     VL    - 7
    //     IS    - 1
    //     AB    - A detailed understanding of how the acoustic patterns of speech sounds are generated by the complex 3D shapes of the vocal tract is a major goal in speech research. The Dresden Vocal Tract Dataset (DVTD) presented here contains geometric and (aero)acoustic data of the vocal tract of 22 German speech sounds (16 vowels, 5 fricatives, 1 lateral), each from one male and one female speaker. The data include the 3D Magnetic Resonance Imaging data of the vocal tracts, the corresponding 3D-printable and finite-element models, and their simulated and measured acoustic and aerodynamic properties. The dataset was evaluated in terms of the plausibility and the similarity of the resonance frequencies determined by the acoustic simulations and measurements, and in terms of the human identification rate of the vowels and fricatives synthesized by the artificially excited 3D-printed vocal tract models. According to both the acoustic and perceptual metrics, most models are accurate representations of the intended speech sounds and can be readily used for research and education.
    //     SN    - 2052-4463
    //     UR    - https://doi.org/10.1038/s41597-020-00597-w
    //     DO    - 10.1038/s41597-020-00597-w
    //     ID    - Birkholz2020
    //     ER    -
    // https://www.nature.com/articles/s41597-020-00597-w
    // https://www.nature.com/articles/s41597-021-01041-3

    Console.stack(NAME, props, { isWeb, gender, model, stl });

    return useMemo(
        () => {

            // the position of the stl depends on the gender for base options,
            // plus any model-specific overrides
            const options = xform(
                gender,
                model?.xform && model.xform[gender]
                    ? model.xform[gender]
                    : {},
            );

            Console.log(`${NAME} render`, { isWeb, gender, model: model?.stl, options });

            // Audio.Play(`audio/speech3D/${gender}_${model.stl}.${isWeb ? 'wav' : 'mp3'}`)

            // url={`models3d/speech3d/${gender}_${model.stl}.${isWeb ? 'stl' : 'obj'}`}

            return (
                <View
                    value={'Speech3DView'}
                    style={styles.view}
                >
                    <View
                        value={'Speech3DInfo'}
                        style={styles.info}
                    >
                        <Button
                            value={t(MALE)}
                            onPress={() => setGenderRef.current('m')}
                        />
                        <Button
                            value={`${model.phoneme} 🔊`}
                            onPress={() => Audio.Play(`${S3_SERVER}/ipa_3d/${gender}_${model.stl}.mp3`)}
                        />
                        <Button
                            value={t(FEMALE)}
                            onPress={() => setGenderRef.current('f')}
                        />
                    </View>
                    <Viewer3D
                        url={`${S3_SERVER}/ipa_3d/${gender}_${model.stl}.${isWeb ? 'stl' : 'obj'}`}
                        width={width}
                        height={adjHeight}
                        objectColor={gender === 'm' ? Colors.colors.cyan : Colors.colors.hotpink}
                        gridLineColor={Colors.colors.gray}
                        skyboxColor={Colors.colors.gray}
                        groundColor={Colors.colors.white}
                        lightColor={Colors.colors.white}
                        volume={v => Console.log('volume:', { v })}
                        cx={options.cx}
                        cy={options.cy}
                        cz={options.cz}
                        tx={options.tx}
                        ty={options.ty}
                        tz={options.tz}
                        rx={options.rx}
                        ry={options.ry}
                        rz={options.rz}
                        sx={options.sx}
                        sy={options.sy}
                        sz={options.sz}
                    />
                </View>
            );
        },
        [
            isWeb,
            t,
            width,
            adjHeight,
            gender,
            model.stl,
            model.xform,
            model.phoneme,
            setGenderRef,
        ],
    );
};

const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
    },
    info: {
        flexDirection: 'row',
        width: '100%',
        height: '10%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
});
