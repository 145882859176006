import React from 'react';
import { SafeAreaProvider as RNSafeAreaProvider } from 'react-native-safe-area-context';
import { TrueTypeStyle } from '../components/platform';
import { Console } from '../utils';

const NAME = 'SafeAreaProvider';


export const SafeAreaProvider = props => {

    const {
        children,
    } = props;

    Console.stack(NAME, props);

    return (
        <React.Fragment>
            <TrueTypeStyle />
            <RNSafeAreaProvider>
                {children}
            </RNSafeAreaProvider>
        </React.Fragment>
    );
};
