import React from 'react';
import { Text } from 'react-native-svg';
import { G, Path } from '../svg';
import { svgHands } from './svgHands';


// https://svg2jsx.com/
// https://www.signwriting.org/archive/docs2/sw0113-US-ASL-English-Dict.pdf
// https://slevinski.github.io/SuttonSignWriting/characters/symbols.html#?ui=en&set=swu
// https://convertio.co/
// https://wpclipart.com/sign_language/

// Q, Z, 0, 3, 4, 5, 7, 8
// replace 9



// script props
const sProps = {
  x: 5,
  y: 5,
  scale: 1,
};

// number props
const nProps = {
  x: -800,
  y: -3000,
  scalex: 0.055,
  scaley: -0.055,
};

// count props
const cProps = {
  x: -200,
  y: -800,
  scalex: 0.075,
  scaley: -0.075,
};

export const ASL_VARIANTS = {
  TEXT: 'Text',
  HAND: 'Hand',
  SYMBOL: 'Symbol',
};

export const ASL_TYPES = {
  LETTERS: 'Letters',
  NUMBERS: 'Numbers',
  COUNT: 'Count',
};

const _asl = (dark, show) => {

  const back = dark ? '#000' : '#fff';
  const fore = dark ? '#fff' : '#000';
  const gq = !show ? fore : dark ? '#f00' : '#900';
  const ij = !show ? fore : dark ? '#0f0' : '#090';
  const kp = !show ? fore : dark ? '#00f' : '#009';
  const closed = !show ? fore : dark ? '#0ff' : '#099';
  const round = !show ? fore : dark ? '#f0f' : '#909';
  const hand = show ? 'red' : fore;
  const thumb = show ? 'orange' : fore;
  const thumbIn = show ? 'orange' : back;
  const index = show ? 'yellow' : fore;
  const middle = show ? 'green' : fore;
  const ring = show ? 'blue' : fore;
  const pinky = show ? 'purple' : fore;

  const symbolProps = {
    y: -30,
  };

  const handProps = {
    fill: fore,
    scale: 0.25,
  };

  const handOffset = {
    x: 25,
  };

  return {
    __: {
      Text: null,
      Symbol: null,
      Hand: null,
    },
    _A: {
      Text: <G fill={closed}><Text>A</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={thumb} d="M 5.719 20.344 L 9 26.03 V 30 L 4 21.344 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._A.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _B: {
      Text: <G fill={fore}><Text>B</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={hand} d="M 9 15 L 16.5 7.5 24 15 V 30 H 9 z" />
          <Path fill={thumbIn} d="M 9 21 H 18 V 24 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 25}>
          {svgHands._B.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _C: {
      Text: <G fill={fore}><Text>C</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={fore} d="M 17 14 V 10 C 8 10 8 18 8 18 h 2 c 0 -4 7 -4 7 -4 z" />
          <Path fill={thumb} d="M 17 30 v -2 c -8 0 -8.038 -3.928 -8 -4 H 8 c 0 6 9 6 9 6 z" />
          <Path fill={hand} d="M 17 10 V 30 c 8 0 8 -8 8 -8 V 18 c 0 -8 -8 -8 -8 -8 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset}>
          {svgHands._C.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _D: {
      Text: <G fill={round}><Text>D</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={index} d="M 8.5 1 H 10.5 V 22.5 H 8.5 z" />
          <Path fill={hand} d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={round}>
          {svgHands._D.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _E: {
      Text: <G fill={closed}><Text>E</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={thumbIn} d="M 9 21 H 18 V 24 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._E.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _F: {
      Text: <G fill={round}><Text>F</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={pinky} d="M 30 9.5 l 1.406 1.406 -8 8 l -1.406 -1.406 z" />
          <Path fill={ring} d="M 25 3.5 l 1.78 0.908 -5.968 11.719 l -1.78 -0.908 z" />
          <Path fill={middle} d="M 17.5 0 V 15 H 15.5 V 0 z" />
          <Path fill={hand} d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={round}>
          {svgHands._F.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _G: {
      Text: <G fill={gq}><Text>G</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={index} d="M -5 15 V 17 H 9 V 15 z" />
          <Path fill={thumb} d="M -3.031 21.719 l -0.532 1.906 L 9 27 V 24.906 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={back} d="M 11 17 H 22 V 28 H 11 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 75} fill={gq}>
          {svgHands._G.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _H: {
      Text: <G fill={fore}><Text>H</Text></G>,
      Symbol: (
        <G {...symbolProps} rotation={-90} rotationx={16.5} rotationy={22.5}>
          <Path fill={middle} d="M 17 0 H 19 V 15 H 17 z" />
          <Path fill={index} d="M 22 3 H 24 V 15 H 22 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={back} d="M 11 17 H 22 V 28 H 11 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 25}>
          {svgHands._H.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _I: {
      Text: <G fill={ij}><Text>I</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={pinky} d="M 27.594 9.594 L 29 11 l -5 5 V 15 h -1.813 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={ij}>
          {svgHands._I.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _J: {
      Text: <G fill={ij}><Text>J</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <G fill={fore} scale={0.75} x={-5} y={-5}>
            <Path d="M 10 8 H 0 v 10 L 10 8 z" />
            <Path d="M 12.5 20 c -2.623 0 -5 -1.062 -6.719 -2.781 l -2.5 -2.5 L 4 14 l 2.5 2.5 a 8.458 8.458 0 0 0 6 2.5 8.458 8.458 0 0 0 6 -2.5 l 3.813 -3.781 0.687 0.687 -3.781 3.813 A 9.472 9.472 0 0 112.5 20 z" />
            <Path d="M 12.5 16 c -1.519 0 -2.88 -0.63 -3.875 -1.625 l -2.5 -2.5 0.688 -0.688 2.5 2.5 A 4.494 4.494 0 0 0 12.5 15 a 4.494 4.494 0 0 0 3.188 -1.313 l 3.78 -3.812 0.72 0.719 -3.813 3.781 C 15.38 15.37 14.019 16 12.5 16 z" />
            <Path d="M 12 28 V 0 h 1 v 28 h -1 z" />
            <Path fill={back} d="M 20.188 10.594 l 2.125 2.125 L 18.5 16.5 a 8.454 8.454 0 0 1 -4.5 2.344 V 20 h -1 v -1.031 c -0.165 0.01 -0.332 0.031 -0.5 0.031 -0.168 0 -0.335 -0.022 -0.5 -0.031 V 20 h -1 v -1.156 A 8.454 8.454 0 0 16.5 16.5 L 4 14 l 2.125 -2.125 2.5 2.5 A 5.57 5.57 0 0 0 11 15.781 V 14.72 l 1 0.25 v 1 c 0.165 0.015 0.331 0.031 0.5 0.031 0.169 0 0.335 -0.016 0.5 -0.031 v -1 l 1 -0.25 v 1.062 a 5.57 5.57 0 0 0 2.375 -1.406 l 3.813 -3.781 z" />
          </G>
          <G>
            <Path fill={pinky} d="M 27.594 9.594 L 29 11 l -5 5 V 15 h -1.813 z" />
            <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
          </G>
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 50} fill={ij}>
          {svgHands._J.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _K: {
      Text: <G fill={kp}><Text>K</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={middle} d="M 9 15 H -5 V 17 H 9 z" />
          <Path fill={index} d="M 11 15 V 0 H 9 V 15 z" />
          <Path fill={thumb} d="M -1.594 10.563 L -3.03 11.969 9 24 v -2.813 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
       </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={kp}>
          {svgHands._K.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _L: {
      Text: <G fill={fore}><Text>L</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={index} d="M 9 1 H 11 V 15 H 9 z" />
          <Path fill={thumb} d="M 0 21 H 9 V 24 H 0 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 50}>
          {svgHands._L.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _M: {
      Text: <G fill={closed}><Text>M</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={ring} d="M 14.094 5.094 L 12.687 6.5 l 8.5 8.5 H 24 z" />
          <Path fill={middle} d="M  8.094 5.094 L  6.687 6.5 l 8.5 8.5 H 18 z" />
          <Path fill={index} d="M  4.625 7.813 L  3.219 9.219 9 15 h 2.813 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._M.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _N: {
      Text: <G fill={closed}><Text>N</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={middle} d="M  8.094 5.094 L  6.687 6.5 l 8.5 8.5 H 18 z" />
          <Path fill={index} d="M  4.625 7.813 L  3.219 9.219 9 15 h 2.813 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._N.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _O: {
      Text: <G fill={round}><Text>O</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={hand} d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 25} fill={round}>
          {svgHands._O.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _P: {
      Text: <G fill={kp}><Text>P</Text></G>,
      Symbol: (
        <G {...symbolProps} rotation={-45} rotationx={16.5} rotationy={22.5}>
          <Path fill={middle} d="M 9 15 H -5 V 17 H 9 z" />
          <Path fill={index} d="M 11 15 V 0 H 9 V 15 z" />
          <Path fill={thumb} d="M -1.594 10.563 L -3.03 11.969 9 24 v -2.813 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
       </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 10} fill={kp}>
          {svgHands._P.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _Q: {
      Text: <G fill={gq}><Text>Q</Text></G>,
      Symbol: (
        <G {...symbolProps} rotation={-45} rotationx={16.5} rotationy={22.5}>
          <Path fill={index} d="M -5 15 V 17 H 9 V 15 z" />
          <Path fill={thumb} d="M -3.031 21.719 l -0.532 1.906 L 9 27 V 24.906 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x - 10} fill={gq}>
          {svgHands._Q.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _R: {
      Text: <G fill={fore}><Text>R</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={index} d="M 19.594 4.406 L 9 15 h 2.813 l 9.218 -9.188 z" />
          <Path fill={middle} d="M 14 0 H 16 V 15 H 14 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset}>
          {svgHands._R.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _S: {
      Text: <G fill={closed}><Text>S</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._S.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _T: {
      Text: <G fill={closed}><Text>T</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={thumb} d="M 12 11 H 16 V 15 H 12 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={closed}>
          {svgHands._T.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _U: {
      Text: <G fill={fore}><Text>U</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={middle} d="M 14 0 H 16 V 15 H 14 z" />
          <Path fill={index} d="M 9 1 H 11 V 15 H 9 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset}>
          {svgHands._U.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _V: {
      Text: <G fill={fore}><Text>V</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={middle} d="M 21.594 4.406 L 11 15 h 2.813 l 9.218 -9.188 z" />
          <Path fill={index} d="M 9 1 H 11 V 15 H 9 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset}>
          {svgHands._V.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _W: {
      Text: <G fill={round}><Text>W</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={ring} d="M 24.094 6.406 L 15.5 15 h 2.813 L 25.5 7.813 z" />
          <Path fill={middle} d="M 17.5 0 V 15 H 15.5 V 0 z" />
          <Path fill={index} d="M 8.906 6.406 L 7.5 7.813 14.688 15 h 2.813 z" />
          <Path fill={hand} d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} {...handOffset} fill={round}>
          {svgHands._W.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _X: {
      Text: <G fill={fore}><Text>X</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={index} d="M 3 4 V 11 H 5 V 6 H 9 V 15 H 11 V 4 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 25}>
          {svgHands._X.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _Y: {
      Text: <G fill={fore}><Text>Y</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <Path fill={pinky} d="M 27.594 9.594 L 29 11 l -5 5 V 15 h -1.813 z" />
          <Path fill={thumb} d="M 5.719 20.344 L 9 26.03 V 30 L 4 21.344 z" />
          <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 25}>
          {svgHands._Y.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _Z: {
      Text: <G fill={fore}><Text>Z</Text></G>,
      Symbol: (
        <G {...symbolProps}>
          <G>
            <Path fill={index} d="M 9 1 H 11 V 15 H 9 z" />
            <Path fill={hand} d="M 9 15 H 24 V 30 H 9 z" />
          </G>
          <G fill={fore} scalex={-0.6} scaley={0.6} rotation={90} x={35} y={12}>
            <Path d="M 7.5 0 l -7 7 h 14 l -7 -7 z" />
            <Path d="M 4 7 v 24 l 8 -8 v 8 h 2 V 18.187 l -8 8 V 7 H 4 z" />
            <Path d="M 19 6.094 l -8 8 V 7 H 9 v 11.938 l 8 -8 V 31 h 2 V 6.094 z" />
            <Path fill={back} d="M 6 7 v 19.188 l 8 -8 V 31 h 3 V 10.937 l -8 8 V 7 H 6 z" />
          </G>
        </G>
      ),
      Hand: (
        <G {...handProps} x={handOffset.x + 50}>
          {svgHands._Z.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _1: {
      Text: <G fill={fore}><Text>1</Text></G>,
      Symbol: (
        <G fill={fore}>
          <Path d="M 9 1 H 11 V 15 H 9 z" />
          <Path d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={back} d="M 11 17 H 22 V 28 H 11 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._1.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _2: {
      Text: <G fill={fore}><Text>2</Text></G>,
      Symbol: (
        <G fill={fore}>
          <Path d="M 21.594 4.406 L 11 15 h 2.813 l 9.218 -9.188 z" />
          <Path d="M 9 1 H 11 V 15 H 9 z" />
          <Path d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={back} d="M 11 17 H 22 V 28 H 11 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._2.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _3: {
      Text: <G fill={fore}><Text>3</Text></G>,
      Symbol1: (
        <G>
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M2.406 4.406L.97 5.813 10.188 15H13L2.406 4.406z" />
          <Path fill={fore} d="M21.375 15.813L15 22.186V25l7.781-7.781-1.406-1.407z" />
        </G>
      ),
      Symbol: (
        <G fill={fore} scalex={-1} x={-35}>
          <Path d="M 21.594 4.406 L 11 15 h 2.813 l 9.218 -9.188 z" />
          <Path d="M 9 1 H 11 V 15 H 9 z" />
          <Path d="M 2.625 15.813 L 9 22.186 V 25 l -7.781 -7.781 z" />
          <Path d="M 9 15 H 24 V 30 H 9 z" />
          <Path fill={back} d="M 11 17 H 22 V 28 H 11 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._3.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _4: { // MARKMARK TBD
      Text: <G fill={fore}><Text>4</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M14 9l-7 7v15h14V16l-7-7z" />
          <Path fill={back} d="M14 11.813l-5 5.03V29h10V16.844l-5-5.032z" />
          <Path fill={fore} d="M12 21H19V24H12z" />
          <Path fill={fore} d="M19.906 2.844l-2.531 9.531L19 14l2.844-10.625-1.938-.531z" />
          <Path fill={fore} d="M13 0H15V10H13z" />
          <Path fill={fore} d="M8.094 2.844l-1.938.531L9 14l1.625-1.625-2.531-9.531z" />
          <Path fill={fore} d="M1.406 9.594L0 11l7 7v-2l.406-.406-6-6z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._4.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _5: { // MARKMARK TBD
      Text: <G fill={fore}><Text>5</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M7 31V14l5-5 5 5v17H7z" />
          <Path fill={back} d="M12 11.813l-3 3V29h6V14.812l-3-3z" />
          <Path fill={fore} d="M19 4l-4.406 7.594 1.469 1.469L20.718 5 19 4z" />
          <Path fill={fore} d="M11 0v10l1-1 1 1V0h-2z" />
          <Path fill={fore} d="M5 4L3.281 5l4.656 8.063 1.47-1.47L5 4z" />
          <Path fill={fore} d="M1.344 10.5l-1.407 1.438L7 19v-2.813L1.344 10.5z" />
          <Path fill={fore} d="M21.594 17.594L17 22.187V25l6-6-1.406-1.406z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._5.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _6: {
      Text: <G fill={fore}><Text>6</Text></G>,
      Symbol: (
        <G fill={fore}>
          <Path d="M 8.906 6.406 L 7.5 7.813 14.688 15 h 2.813 z" />
          <Path d="M 17.5 0 V 15 H 15.5 V 0 z" />
          <Path d="M 24.094 6.406 L 15.5 15 h 2.813 L 25.5 7.813 z" />
          <Path d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._6.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _7: { // MARKMARK TBD
      Text: <G fill={fore}><Text>7</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 14a8 8 0 110 16 8 8 0 010-16z" />
          <Path d="M2 0v16.719A7.952 7.952 0 000 22V0h2z" />
          <Path d="M11.313 4.688l1.406 1.406-9.5 9.5c-.442.33-.848.716-1.219 1.125V14l9.313-9.313z" />
          <Path d="M18.594 10.594L20 12l-5.438 5.438a8.07 8.07 0 00-1.343-1.5l5.375-5.344z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._7.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _8: { // MARKMARK TBD
      Text: <G fill={fore}><Text>8</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 12a8 8 0 100 16 8 8 0 000-16z" />
          <Path d="M3 0v13.75a8.055 8.055 0 00-2 2.375V0h2z" />
          <Path d="M14 3v11.719a8.035 8.035 0 00-2-1.625V3h2z" />
          <Path d="M20 11l1.406 1.406-5.656 5.656a7.873 7.873 0 00-.813-2L20 11z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._8.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _9: {
      Text: <G fill={fore}><Text>9</Text></G>,
      Symbol: (
        <G fill={fore}>
          <Path d="M 17.5 0 V 15 H 15.5 V 0 z" />
          <Path d="M 25 3.5 l 1.78 0.908 -5.968 11.719 l -1.78 -0.908 z" />
          <Path d="M 30 9.5 l 1.406 1.406 -8 8 l -1.406 -1.406 z" />
          <Path d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._9.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    _0: {
      Text: <G fill={fore}><Text>0</Text></G>,
      Symbol: (
        <G fill={fore}>
          <Path d="M 8.5 22.5 a 8 8 0 1 0 16 0 a 8 8 0 1 0 -16 0 z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={nProps.x} y={nProps.y} scalex={nProps.scalex} scaley={nProps.scaley} >
          {svgHands._0.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x1: { // MARKMARK: Fix hand
      Text: <G fill={fore}><Text>1</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
        </G>
      ),
      Hand: (
        <G fill={'#f00'} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x1.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x2: {
      Text: <G fill={fore}><Text>2</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M2.406 4.406L.97 5.813 10.188 15H13L2.406 4.406z" />
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x2.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x3: {
      Text: <G fill={fore}><Text>3</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M2.406 4.406L.97 5.813 10.188 15H13L2.406 4.406z" />
          <Path fill={fore} d="M21.375 15.813L15 22.186V25l7.781-7.781-1.406-1.407z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x3.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x4: {
      Text: <G fill={fore}><Text>4</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M14 9l-7 7v15h14V16l-7-7z" />
          <Path fill={back} d="M14 11.813l-5 5.03V29h10V16.844l-5-5.032z" />
          <Path fill={fore} d="M12 21H19V24H12z" />
          <Path fill={fore} d="M19.906 2.844l-2.531 9.531L19 14l2.844-10.625-1.938-.531z" />
          <Path fill={fore} d="M13 0H15V10H13z" />
          <Path fill={fore} d="M8.094 2.844l-1.938.531L9 14l1.625-1.625-2.531-9.531z" />
          <Path fill={fore} d="M1.406 9.594L0 11l7 7v-2l.406-.406-6-6z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x4.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x5: {
      Text: <G fill={fore}><Text>5</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M7 31V14l5-5 5 5v17H7z" />
          <Path fill={back} d="M12 11.813l-3 3V29h6V14.812l-3-3z" />
          <Path fill={fore} d="M19 4l-4.406 7.594 1.469 1.469L20.718 5 19 4z" />
          <Path fill={fore} d="M11 0v10l1-1 1 1V0h-2z" />
          <Path fill={fore} d="M5 4L3.281 5l4.656 8.063 1.47-1.47L5 4z" />
          <Path fill={fore} d="M1.344 10.5l-1.407 1.438L7 19v-2.813L1.344 10.5z" />
          <Path fill={fore} d="M21.594 17.594L17 22.187V25l6-6-1.406-1.406z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x5.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x6: {
      Text: <G fill={fore}><Text>6</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M9 14a8 8 0 100 16 8 8 0 000-16z" />
          <Path d="M1.406 6.406L0 7.813 7.188 15H10L1.406 6.406z" />
          <Path d="M8 0H10V15H8z" />
          <Path d="M16.594 6.406L8 15h2.813L18 7.812l-1.406-1.406z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x6.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x7: {
      Text: <G fill={fore}><Text>7</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 14a8 8 0 110 16 8 8 0 010-16z" />
          <Path d="M2 0v16.719A7.952 7.952 0 000 22V0h2z" />
          <Path d="M11.313 4.688l1.406 1.406-9.5 9.5c-.442.33-.848.716-1.219 1.125V14l9.313-9.313z" />
          <Path d="M18.594 10.594L20 12l-5.438 5.438a8.07 8.07 0 00-1.343-1.5l5.375-5.344z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x7.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x8: {
      Text: <G fill={fore}><Text>8</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 12a8 8 0 100 16 8 8 0 000-16z" />
          <Path d="M3 0v13.75a8.055 8.055 0 00-2 2.375V0h2z" />
          <Path d="M14 3v11.719a8.035 8.035 0 00-2-1.625V3h2z" />
          <Path d="M20 11l1.406 1.406-5.656 5.656a7.873 7.873 0 00-.813-2L20 11z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x8.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x9: {
      Text: <G fill={fore}><Text>9</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 14a8 8 0 110 16 8 8 0 010-16z" />
          <Path d="M10 0v14.25A8.054 8.054 0 008 14V0h2z" />
          <Path d="M17 3l1.781.906-5.968 11.719a7.887 7.887 0 00-1.72-1L17 3z" />
          <Path d="M21 10l1.406 1.406-7.187 7.188c-.295-.625-.623-1.227-1.063-1.75L21 10z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x9.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x0: { // MARKMARK: Fix hand
      Text: <G fill={fore}><Text>0</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M16 8A8 8 0 110 8a8 8 0 1116 0z" />
        </G>
      ),
      Hand: (
        <G fill={'#f00'} x={cProps.x} y={cProps.y} scalex={cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x0.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x10: { // MARKMARK: Fix hand
      Text: <G fill={fore}><Text>10</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
        </G>
      ),
      Hand: (
        <G fill={'#f00'} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x10.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x20: {
      Text: <G fill={fore}><Text>20</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M2.406 4.406L.97 5.813 10.188 15H13L2.406 4.406z" />
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x20.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x30: {
      Text: <G fill={fore}><Text>30</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale} strokeMiterlimit="4" strokeWidth="2">
          <Path fill={fore} d="M0 15H15V30H0z" />
          <Path fill={back} d="M2 17H13V28H2z" />
          <Path fill={fore} d="M13 0H15V15H13z" />
          <Path fill={fore} d="M2.406 4.406L.97 5.813 10.188 15H13L2.406 4.406z" />
          <Path fill={fore} d="M21.375 15.813L15 22.186V25l7.781-7.781-1.406-1.407z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x30.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x40: {
      Text: <G fill={fore}><Text>40</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M14 9l-7 7v15h14V16l-7-7z" />
          <Path fill={back} d="M14 11.813l-5 5.03V29h10V16.844l-5-5.032z" />
          <Path fill={fore} d="M12 21H19V24H12z" />
          <Path fill={fore} d="M19.906 2.844l-2.531 9.531L19 14l2.844-10.625-1.938-.531z" />
          <Path fill={fore} d="M13 0H15V10H13z" />
          <Path fill={fore} d="M8.094 2.844l-1.938.531L9 14l1.625-1.625-2.531-9.531z" />
          <Path fill={fore} d="M1.406 9.594L0 11l7 7v-2l.406-.406-6-6z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x40.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x50: {
      Text: <G fill={fore}><Text>50</Text></G>,
      Symbol: (
        <G x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path fill={fore} d="M7 31V14l5-5 5 5v17H7z" />
          <Path fill={back} d="M12 11.813l-3 3V29h6V14.812l-3-3z" />
          <Path fill={fore} d="M19 4l-4.406 7.594 1.469 1.469L20.718 5 19 4z" />
          <Path fill={fore} d="M11 0v10l1-1 1 1V0h-2z" />
          <Path fill={fore} d="M5 4L3.281 5l4.656 8.063 1.47-1.47L5 4z" />
          <Path fill={fore} d="M1.344 10.5l-1.407 1.438L7 19v-2.813L1.344 10.5z" />
          <Path fill={fore} d="M21.594 17.594L17 22.187V25l6-6-1.406-1.406z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x50.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x60: {
      Text: <G fill={fore}><Text>60</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M9 14a8 8 0 100 16 8 8 0 000-16z" />
          <Path d="M1.406 6.406L0 7.813 7.188 15H10L1.406 6.406z" />
          <Path d="M8 0H10V15H8z" />
          <Path d="M16.594 6.406L8 15h2.813L18 7.812l-1.406-1.406z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x60.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x70: {
      Text: <G fill={fore}><Text>70</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 14a8 8 0 110 16 8 8 0 010-16z" />
          <Path d="M2 0v16.719A7.952 7.952 0 000 22V0h2z" />
          <Path d="M11.313 4.688l1.406 1.406-9.5 9.5c-.442.33-.848.716-1.219 1.125V14l9.313-9.313z" />
          <Path d="M18.594 10.594L20 12l-5.438 5.438a8.07 8.07 0 00-1.343-1.5l5.375-5.344z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x70.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x80: {
      Text: <G fill={fore}><Text>80</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 12a8 8 0 100 16 8 8 0 000-16z" />
          <Path d="M3 0v13.75a8.055 8.055 0 00-2 2.375V0h2z" />
          <Path d="M14 3v11.719a8.035 8.035 0 00-2-1.625V3h2z" />
          <Path d="M20 11l1.406 1.406-5.656 5.656a7.873 7.873 0 00-.813-2L20 11z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x80.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x90: {
      Text: <G fill={fore}><Text>90</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M8 14a8 8 0 110 16 8 8 0 010-16z" />
          <Path d="M10 0v14.25A8.054 8.054 0 008 14V0h2z" />
          <Path d="M17 3l1.781.906-5.968 11.719a7.887 7.887 0 00-1.72-1L17 3z" />
          <Path d="M21 10l1.406 1.406-7.187 7.188c-.295-.625-.623-1.227-1.063-1.75L21 10z" />
        </G>
      ),
      Hand: (
        <G fill={fore} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x90.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
    x00: { // MARKMARK: Fix hand
      Text: <G fill={fore}><Text>00</Text></G>,
      Symbol: (
        <G fill={fore} x={sProps.x} y={sProps.y} scale={sProps.scale}>
          <Path d="M16 8A8 8 0 110 8a8 8 0 1116 0z" />
        </G>
      ),
      Hand: (
        <G fill={'#f00'} x={cProps.x} y={cProps.y} scalex={-cProps.scalex} scaley={cProps.scaley} >
          {svgHands.x00.map((path, key) => (<Path key={key} d={path}/>))}
        </G>
      ),
    },
  };
};

export const asl = new Map([
  [true, new Map([[true, _asl(true, true)], [false, _asl(true, false)]])],
  [false, new Map([[true, _asl(false, true)], [false, _asl(false, false)]])],
]);
