import { Platform } from 'react-native';
import { Console, Validate } from '../../../utils';

const NAME = 'Touch';

const isWeb = Platform.OS === 'web';

const Tags = {
    touches: isWeb ? 'input' : 'touches',
    type: isWeb ? 'name' : 'type',
    data: isWeb ? 'payload' : 'event',
    select: isWeb ? 'onMouseDown' : 'start',
    release: isWeb ? 'onMouseUp' : 'end',
    move: isWeb ? 'onMouseMove' : 'move',
};

// https://github.com/liabru/matter-js/blob/master/src/core/Mouse.js
var element = {
    width: 1,
    height: 1,
    clientWidth: 1,
    clientHeight: 1,
    getAttribute: () => { return '1'; }, // 'data-pixel-ratio'
    getBoundingClientRect: () => { return { top: 0, left: 0 }; },
    addEventListener: () => {}, // touch events used instead of matter events
};

export const TouchCallbacks = {
    onSelect: (entities, position) => {
        Console.log(`${NAME} onSelect [${position.x}, ${position.y}]`, { entities });
        return entities;
    },
    onRelease: (entities, position) => {
        Console.log(`${NAME} onRelease [${position.x}, ${position.y}]`, { entities });
        return entities;
    },
    onMove: (entities, position) => {
        Console.trace(`${NAME} onMove [${position.x}, ${position.y}]`, { entities });
        return entities;
    },
    onDrag: (entities, position) => {
        Console.log(`${NAME} onDrag [${position.x}, ${position.y}]`, { entities });
        return entities;
    },
};

var callbacks = {
    ...TouchCallbacks,
};

var dragging = null;

var offset = { dx: 0, dy: 0 };


export class TouchUtils {

    static Callbacks(value = null) {
        if (Validate.isValid(value)) {
            callbacks = { ...callbacks, ...value };
        }
        return callbacks;
    }

    static Dragging(value = null) {
        if (Validate.isValid(value)) {
            dragging = Validate.isValid(value?.x) && Validate.isValid(value?.y)
                ? value
                : null;
        }
        return dragging;
    }

    static Offset(top = null, left = null) {
        if (Validate.isValid(top)) {
            offset.dy = top;
        }
        if (Validate.isValid(left)) {
            offset.dx = left;
        }
        return offset;
    }

    static Element() {
        return element;
    }
}

export const Touch = (entities, parms) => {
    const values = parms[Tags.touches];
    const cb = TouchUtils.Callbacks();
    const { dx, dy } = TouchUtils.Offset();
    const { system } = entities.physics;
    var event = {
        button: 0,
        changedTouches: true,
        detail: 0,
        wheelData: 0,
        preventDefault: () => {},
    };

    values.forEach(v => {
        const { pageX, pageY } = v[Tags.data];
        const position = { x: pageX - dx, y: pageY - dy };
        if (v[Tags.type] === Tags.select) {
            system.mouseEvent({ ...event, position, type: 'mousedown' });
            entities = cb.onSelect(entities, position);
            TouchUtils.Dragging(position);
        } else if (v[Tags.type] === Tags.release) {
            system.mouseEvent({ ...event, position, type: 'mouseup' });
            TouchUtils.Dragging({});
            entities = cb.onDrag(entities, position);
        } else if (v[Tags.type] === Tags.move) {
            if (TouchUtils.Dragging()) {
                system.mouseEvent({ ...event, position, type: 'mousemove' });
                TouchUtils.Dragging(position);
                entities = cb.onDrag(entities, position);
            } else {
                entities = cb.onMove(entities, position);
            }
        }
    });

    return entities;
};
