import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Pressable } from './Pressable';
import { useAppState, useUserState } from '../context';
import { Icons } from '../media';
import { Colors } from '../styles';
import { View } from './View';
import { Console, STT, Validate } from '../utils';
import { MAX_RECORDING_SECONDS } from '../constants';

const NAME = 'Microphone';

const ONE_SECOND_DELAY = 1000;


export const Microphone = props => {

    const {
        languageOverride,
        onStart,
        onStop,
        setTranscriptionRef,
        color,
        hotColor,
        style,
        disabled,
    } = props;

    const { username } = useUserState();
    const { language } = useAppState();
    const lang = Validate.isValidNonEmptyString(languageOverride) ? languageOverride : language;

    const [recording, setRecording] = useState(false);
    const setRecordingRef = useRef(setRecording);
    const [hot, setHot] = useState(false);
    const setHotRef = useRef(setHot);
    const [recordingTimeout, setRecordingTimeout] = useState();
    const setRecordingTimeoutRef = useRef(setRecordingTimeout);
    const [hotTimeout, setHotTimeout] = useState();
    const setHotTimeoutRef = useRef(setHotTimeout);

    const onPress = useCallback(
        _recording => {
            Console.log(`${NAME}.onPress`, { _recording, recordingTimeout, hotTimeout });
            setRecordingRef.current(!_recording);
            if (_recording) {
                if (Validate.isValid(recordingTimeout)) {
                    clearTimeout(recordingTimeout);
                    setRecordingTimeoutRef.current(null);
                }
                if (Validate.isValid(hotTimeout)) {
                    clearTimeout(hotTimeout);
                    setHotTimeoutRef.current(null);
                }
                setHotRef.current(false);
            } else {
                setRecordingTimeoutRef.current(
                    setTimeout(
                        setRecordingRef.current,
                        (MAX_RECORDING_SECONDS * 1000) + ONE_SECOND_DELAY,
                        false,
                    ),
                );
                setHotTimeoutRef.current(
                    setTimeout(
                        setHotRef.current,
                        ONE_SECOND_DELAY,
                        true,
                    ),
                );
            }
        },
        [
            recordingTimeout,
            hotTimeout,
            setRecordingRef,
            setHotRef,
            setRecordingTimeoutRef,
            setHotTimeoutRef,
        ],
    );

    Console.stack(NAME, props, { MAX_RECORDING_SECONDS, lang, username, recording, hot });

    return useMemo(
        () => {
            const iconColor = recording
                ? !hot
                    ? Colors.colors.yellow
                    : hotColor ? hotColor : Colors.colors.red
                : color ? color : Colors.colors.green;
            Console.log(`${NAME} render`, { lang, username, recording, hot, iconColor });
            return (
                <View
                    value={'Microphone'}
                >
                    <STT
                        language={lang}
                        username={username}
                        recording={recording}
                        onStart={onStart}
                        onStop={onStop}
                        setTranscriptionRef={setTranscriptionRef}
                    />
                    <Pressable
                        style={style}
                        icon={Icons.Microphone}
                        onPress={() => onPress(recording)}
                        iconColor={iconColor}
                        disabled={disabled}
                    />
                </View>
            );
        },
        [
            lang,
            username,
            recording,
            hot,
            onStart,
            onStop,
            setTranscriptionRef,
            style,
            color,
            hotColor,
            disabled,
            onPress,
        ],
    );
};
