import React from 'react';
import { StyleSheet } from 'react-native';
import { ProgressBar as RNPProgressBar } from 'react-native-paper';
import { Text } from './Text';
import { View } from './View';
import { Console, Numbers, Validate } from '../utils';
import { Colors } from '../styles';

const NAME = 'ProgressBar';


export const ProgressBar = props => {

    const {
        style,
        color,
        progress,
        visible,
        infoText,
        //        indeterminate
    } = props;

    const percentText = Numbers.percentage(progress);

    Console.stack(NAME, props, { percentText });

    return (
        <View
            value={'ProgressBarView'}
            style={style}
        >
            <RNPProgressBar
                style={styles.progressBar}
                progress={progress}
                color={color}
                visible={Validate.isValid(visible) ? visible : true}
            />
            <Text
                style={styles.text}
                value={infoText ? `${percentText} (${infoText})` : percentText}
                color={Colors.colors.black}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    progressBar: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
    text: {
        position: 'absolute',
        top: 0,//-30,
        left: 0,
        width: '100%',
        height: '100%',
        textAlign: 'center',
        alignItems: 'center',
        opacity: 0.5,
        //borderColor: Colors.colors.red,
        //borderWidth: 1,
    },
});
