import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, SearchBar, Screen, Text, TEXT_VARIANTS, View } from '../components';
import { SearchResults } from './SearchResults';
import { APP_TYPES, useAppDispatch, useAppState } from '../context';
import { useVocabulary } from '../hooks';
import { Vocabulary, SEMANTIC, SENSE_KEY } from '../vocabulary';
import { Console, Numbers, Optional } from '../utils';
import { Navigate } from './utils';

const NAME = 'Search';

const DETAIL = 'Detail';
const GRAPH = 'Graph';
const WORDS = 'Words';

const LOADING = 'Loading';


export const Search = props => {

    const {
        navigation,
    } = props;

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { detail, language, t, search } = useAppState();

    const { vocabularyLoaded, vocabularyProgress } = useVocabulary();

    const [data, setData] = useState([]);
    const setDataRef = useRef(setData);

    const [lang] = useState(language.split('_')[0]);

    useEffect(
        () => {
            if (search === '_TRANSLATED_ACQUIRE_') {
                const payload = t('acquire');
                Console.log(`${NAME} useEffect search`, { search, payload });
                appDispatchRef.current({ type: APP_TYPES.SET_SEARCH, payload });
            }
        },
        [
            search,
            appDispatchRef,
            t,
        ],
    );

    useEffect(
        () => {
            if (!vocabularyLoaded) {
                return;
            }

            const listData = Vocabulary.List(lang, search);

            var itemsArray = [];
            const listDataKeys = [...listData.keys()].sort();
            listDataKeys.forEach((key, id) => {

                // MARKMARK: THIS IS WHERE MULT-WORDS ARE FILTERED OUT OF SEARCH...should this be somewhere else? maybe eliminated from raw data
                if (key.indexOf(' ') >= 0 || key.indexOf('_') >= 0) {
                    return;
                }

                const value = listData.get(key);

                var item = {
                    key,
                    id,
                    pos: Object.keys(value.w).map(v => Vocabulary.GetPos(v)),
                };

                var emojiSet = new Set();
                const posKeys = Object.keys(value.w);
                posKeys.forEach(posKey => {
                    const posData = value.w[posKey];
                    const relationKeys = Object.keys(posData);
                    relationKeys.forEach(relationKey => {
                        const relationData = posData[relationKey];
                        if (relationData[SEMANTIC][SENSE_KEY]) {
                            const semanticKeys = Object.keys(relationData[SEMANTIC][SENSE_KEY]);
                            semanticKeys.forEach(semanticKey => {
                                const senseData = relationData[SEMANTIC][SENSE_KEY][semanticKey];
                                if (senseData?.e) {
                                    senseData.e.forEach(senseEmoji => {
                                        emojiSet.add(senseEmoji);
                                    });
                                }
                            });
                        }
                    });
                });
                if (emojiSet.size) {
                    item.emoji = Numbers.randomItem(Array.from(emojiSet));
                }

                if (value?.i) {
                    if (value.i?.p) {
                        item.ipa = value.i.p;
                    }
                    if (value.i?.r) {
                        item.rank = value.i.r;
                    }
                    if (value.i?.l) {
                        item.root = value.i.l;
                    }
                    if (value.i?.c) {
                        item.cognates = value.i.c.split('|');
                    }
                }
                itemsArray.push(item);
            });

            Console.log(`${NAME} useEffect list`, { lang, search, listData, itemsArray });

            setDataRef.current(itemsArray);
        },
        [
            vocabularyLoaded,
            lang,
            search,
            setDataRef,
        ],
    );

    const onPress = useCallback(
        value => {
            const payload = value.split('%')[0];
            Console.devLog(`${NAME}.onPress`, { value, payload });
            appDispatchRef.current({ type: APP_TYPES.SET_SEARCH, payload });
            Navigate(navigation, detail ? t(DETAIL) : t(GRAPH), { title: payload });
        },
        [
            detail,
            appDispatchRef,
            navigation,
            t,
        ],
    );

    Console.stack(NAME, props, { detail, language, lang, search, data, vocabularyLoaded, vocabularyProgress });

    return useMemo(
        () => {
            return (
                <Screen {...props} value={NAME}>
                    {Optional(vocabularyLoaded, (
                        <>
                            <View
                                value={'SeachView'}
                                style={styles.controls}
                            >
                                <Text
                                    value={`${search === '' ? Vocabulary.Size(lang) : data.length} ${t(WORDS)}`}
                                    variant={TEXT_VARIANTS.HEADLINE}
                                />
                            </View>
                            <SearchResults
                                data={data}
                                onPress={onPress}
                            />
                            <SearchBar
                                style={styles.searchBar}
                            />
                        </>
                    ), (
                        <View
                            style={styles.view}
                        >
                            <ActivityIndicator />
                            <Text
                                value={`${t(LOADING)} ${Numbers.percentage(vocabularyProgress, 0)}`}
                            />
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            t,
            props,
            lang,
            search,
            data,
            onPress,
            vocabularyLoaded,
            vocabularyProgress,
        ],
    );
};


const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingVertical: '33%',
    },
    controls: {
        flexDirection: 'row',
        width: '100%',
        height: '10.5%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    searchBar: {
        width: '100%',
        height: '100%',
    },
});
