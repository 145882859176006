import React from 'react';
import { Pressable as RNPressable, StyleSheet } from 'react-native';
import { Icon } from './Icon';
import { Text } from './Text';
import { OnPress } from './utils';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';

const NAME = 'Pressable';


export const Pressable = props => {

    const {
        children,
        onPress,
        disabled,
        icon,
        value,
        backgroundColor,
        iconSize,
        iconColor,
        textColor,
        style,
        textStyle,
    } = props;

    const title = Validate.isValidNonEmptyString(value) ? value : icon?.title;
    const buttonColorStyle = Validate.isValid(backgroundColor) ? { backgroundColor } : {};

    Console.stack(`${NAME}[${title}]`, props);

    return (
        <RNPressable
            style={[styles.pressable, style, buttonColorStyle]}
            disabled={disabled}
            onPress={() => OnPress(value, onPress)}
        >
            <>
                {
                    Optional(Validate.isValid(icon), (
                        <Icon
                            size={iconSize}
                            icon={icon}
                            color={disabled ? Colors.colors.lightgray : iconColor}
                        />
                    ))
                }
                {
                    Optional(Validate.isValid(value), (
                        <Text
                            value={value}
                            style={textStyle}
                            color={disabled ? Colors.colors.darkgray : textColor ? textColor : Colors.colors.black }
                        />
                    ))
                }
                {children}
            </>
        </RNPressable>
    );
};

const styles = StyleSheet.create({
    pressable: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});
