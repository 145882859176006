import { S3_SERVER } from '../constants';
import { Console } from '../utils';

const NAME = 'Audio';


var audios = {
    general: {},
};

[
    'da_app_hawaii.wav',
].forEach(audioFile => {
    audios.general[audioFile.split('.')[0]] = `${S3_SERVER}/general/${audioFile}`;
});

export const Audios = audios;
Console.log(`${NAME} Audios`, Audios);
