import React from 'react';
import { Emoji } from '../Emoji';
import { View } from '../View';
import { Console } from '../../utils';
import { Colors } from '../../styles';

const NAME = 'SmartCamera.web';


export const SmartCamera = props => {

    const {
        type,
        capture,
    } = props;

    const viewStyle = {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.colors.lightgray,
    };

    Console.stack(NAME, props, { type, capture });

    return (
        <View
            value={NAME}
            style={viewStyle}
        >
            <Emoji
                value={'parrot'}
                size={315}
            />
        </View>
    );
};
