const subscribeUnsubscribe = { subscribe: () => ({ unsubscribe: () => {} }) };

export const Sensors = {
    accelerometer: subscribeUnsubscribe,
    barometer: subscribeUnsubscribe,
    gravity: subscribeUnsubscribe,
    gyroscope: subscribeUnsubscribe,
    magnetometer: subscribeUnsubscribe,
    orientation: subscribeUnsubscribe,
    setUpdateIntervalForType: () => {},
    SensorTypes: {
        accelerometer: 'accelerometer',
        barometer: 'barometer',
        gravity: 'gravity',
        gyroscope: 'gyroscope',
        magnetometer: 'magnetometer',
        orientation: 'orientation',
    },
};
