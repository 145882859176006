import { Console } from '../../utils';


export const Navigate = (navigation, routeName, params) => {
    Console.log('Navigate', { routeName, params });
    navigation.navigate(routeName, params);
};

export const NavParams = route => {
    if (!route?.params) {
        Console.warn('NavParams: bad route params', { route });
        return {};
    }
    return route.params;
};
