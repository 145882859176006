export const MapKeys = {
    AIRPORTS: 'airports',
    ATMS: 'atms',
    ATTRACTIONS: 'attractions',
    BEACHES: 'beaches',
    BIKI: 'biki',
    BUSES: 'buses',
    BUS_STOPS: 'busStops',
    CHURCHES: 'churches',
    CONDOS: 'condos',
    EVENTS: 'events',
    FARMERS_MARKETS: 'farmersMarkets',
    FAVORITES: 'favorites',
    GOLF: 'golf',
    HIKES: 'hikes',
    HOTELS: 'hotels',
    LOCATIONS: 'locations',
    MARINAS: 'marinas',
    MUSIC: 'music',
    PEOPLE: 'people',
    SATELLITES: 'satellites',
    SURF: 'surf',
    TOILETS: 'toilets',
    WEATHER: 'weather',

    BARS: 'bars',
    COFFEE: 'coffee',
    DEPTSTORES: 'deptstores',
    GROCERY: 'grocery',
    HAWAIIAN: 'hawaiian',
    RESTAURANTS: 'restaurants',
};
