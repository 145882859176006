//import React from 'react';
import { Console } from '../utils';

const NAME = 'Ads.web';

export const BANNER_AD_WIDTH = 320;
export const BANNER_AD_HEIGHT = 0;//50;

export const AD_TYPES = {
    OPEN: 'open',
    INTERSTITIAL: 'interstitial',
    REWARDED: 'rewarded',
    REWARDED_INTERSTITIAL: 'rewardedInterstitial',
    BANNER: 'banner',
    GAM_BANNER: 'gamBanner',
};

class Ads {

    constructor(keywords,
                openAdUnitId = null,
                interstitialAdUnitId = null,
                rewardedAdUnitId = null,
                rewardedInterstitialAdUnitId = null,
                bannerAdUnitId = null,
                gamBannerAdUnitId = null,
    ) {
        Console.log(`${NAME} Ads.ctor`, { keywords });
    }

    subscribe = (type, setLoadedRef) => {
        Console.log(`${NAME} Ads.subscribe`, { type, setLoadedRef });
        return () => {};
    };

    show = (display = null, loaded = null, type = null) => {
        Console.log(`${NAME} Ads.show`, { display, loaded, type });
        return null;
    };

    static Banner(unitId, requestOptions, size = null) {
        Console.log(`${NAME} Ads.Banner`, { unitId, requestOptions, size });
        return {
            show: () => {},
        };
    }

    static GAMBanner(unitId, requestOptions, size = null) {
        Console.log(`${NAME} Ads.GAMBanner`, { unitId, requestOptions, size });
        return {
            show: () => {},
        };
    }
}

export class GoogleAds {
    static Get(keywords) {
        Console.log(`${NAME} GoogleAds.Get`, { keywords });
        return new Ads(keywords);
    }
}
