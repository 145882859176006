import React, { useEffect } from 'react';
import { Modal, StyleSheet } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { BackKeyUtils } from './platform';
import { Button } from './Button';
import { Text, TEXT_VARIANTS } from './Text';
import { View } from './View';
import { useSystemState } from '../context';
import { Console } from '../utils';
import { Colors } from '../styles';

const NAME = 'BackKey';

const PROMPT = 'Do you want to leave?';
const STAY = 'No, Stay';
const LEAVE = 'Yes, Leave';


export const BackKey = props => {

    const {
        visible,
        setVisible,
        goBack,
    } = props;

    const { isWeb } = useSystemState();
    const navigation = useNavigation();

    useEffect(
        () => {
            if (!isWeb) {
                return;
            }

            const onBack = () => {
                Console.log(`${NAME}.onBack`);
                if (goBack) {
                    navigation.goBack();
                } else {
                    setVisible(true);
                }
                return true;
            };

            BackKeyUtils.addEventListener('hardwareBackPress', onBack);
            return () => BackKeyUtils.removeEventListener('hardwareBackPress', onBack);
        },
        [
            isWeb,
            navigation,
            setVisible,
            goBack,
        ],
    );

    Console.stack(NAME, props, { isWeb });

    return (
        <Modal
            transparent={true}
            visible={visible}
            onRequestClose={() => setVisible(false)}
        >
            <View
                value={'BackKey'}
                style={styles.modal}
            >
                <Text
                    value={PROMPT}
                    variant={TEXT_VARIANTS.TITLE}
                    style={styles.text}
                />
                <View
                    value={'BackKeyButtons'}
                    style={styles.buttons}
                >
                    <Button
                        value={STAY}
                        textColor={Colors.colors.green}
                        onPress={() => setVisible(false)}
                    />
                    <Button
                        value={LEAVE}
                        textColor={Colors.colors.red}
                        onPress={() => BackKeyUtils.exitApp()}
                    />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modal: {
        backgroundColor: Colors.colors.yellow,
        borderColor: Colors.colors.red,
        borderWidth: 2,
        top: '25%',
        width: '75%',
        height: '25%',
        alignSelf: 'center',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    buttons: {
        width: '100%',
        flexDirection: 'row',
        alignSelf: 'auto',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    text: {
        color: Colors.colors.red,
        fontWeight: 'bold',
    },
});
