import React from 'react';
import { MaterialCommunityIcons } from '../media';
import { useAppState, useSystemState } from '../context';
import { OnPress } from './utils';
import { Console } from '../utils';

const NAME = 'Icon';


export const Icon = props => {

    const {
        onPress,
        icon,
        iconName,
        focused,
        color,
        size,
    } = props;

    const { deviceScale } = useSystemState();
    const { dark, theme, DEFAULT } = useAppState();

    const name = iconName ? iconName : icon.name(dark);
    const Icons = MaterialCommunityIcons.Get();

    var _color = color;
    if (!_color) {
        const { colors } = theme;
        const { text } = colors;
        _color = text;
    }

    Console.stack(`${NAME}[${name}]`, props, { deviceScale, dark, _color, name });

    return (
        <Icons
            name={name}
            focused={focused}
            color={_color}
            size={deviceScale * (size ? size : DEFAULT.ICON_SIZE)}
            onPress={onPress ? () => OnPress(name, onPress) : null}
        />
    );
};
