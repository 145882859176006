export class Toggles {

    constructor() {
        this.toggles = new Map();
    }

    get(type) {
        return this.toggles.has(type)
            ? this.toggles.get(type)
            : false;
    }

    clear() {
        this.toggles.forEach((_, k, m) => m.set(k, false));
    }

    toggle(type, radio = true) {
        const newValue = this.toggles.has(type)
            ? !this.toggles.get(type)
            : true;
        if (radio && newValue) {
            this.toggles.forEach((_, k, m) => m.set(k, false));
        }
        this.toggles.set(type, newValue);
    }

    enabled = () => {
        var result = [];
        this.toggles.forEach((v, k) => {
            if (v) {
                result.push(k);
            }
        });
        return result;
    }
}
