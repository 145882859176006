import React, { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Text, TEXT_VARIANTS, Screen } from '../components';
import { Console } from '../utils';
import { Colors } from '../styles';
import { NavParams } from './utils';

const NAME = 'ConfirmRegister';

const CONFIRM_REGISTER_MESSAGE = 'Confirm Register message';


export const ConfirmRegister = props => {

    const {
        route,
        navigation,
    } = props;

    useEffect(
        () => {
            const parmsTitle = NavParams(route)?.title;
            const title = parmsTitle ? parmsTitle : NAME;
            Console.log(`${NAME} useEffect entry`, { title });
            navigation.setOptions({ headerTitle: ({ tintColor }) => <Text value={title} color={tintColor} variant={TEXT_VARIANTS.TITLE} /> });
        },
        [
            route,
            navigation,
        ],
    );
    /*
        useEffect(
            () => {
                Console.log(`${NAME} useEffect`, { kbVisible });
                if (!kbVisible) {
                    appDispatchRef.current({ type: APP_TYPES.SET_AUTH, payload: NAME });
                }
            },
            [
                appDispatchRef,
                kbVisible,
            ],
        );
    */
    Console.stack(NAME, props/*, { kbVisible }*/);

    return useMemo(
        () => {
            Console.log(`${NAME} render`);
            return (
                <Screen
                    {...props}
                    value={NAME}
                    headerHeight={'20%'}
                    headerStyle={styles.header}
                    headerView={(
                        <Text
                            value={CONFIRM_REGISTER_MESSAGE}
                        />
                    )}
                    mainHeight={'40%'}
                    mainStyle={styles.main}
                    mainView={(
                        <></>
                    )}
                    footerHeight={'40%'}
                    footerStyle={styles.footer}
                    footerView={(
                        <></>
                    )}
                />
            );
        },
        [
            props,
        ],
    );
};

const styles = StyleSheet.create({
    header: {
        width: '80%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    main: {
        width: '80%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    footer: {
        width: '80%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    button: {
        backgroundColor: Colors.colors.lightgreen,
    },
});
