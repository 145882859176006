import React from 'react';
import { StyleSheet } from 'react-native';
import {
    Grid,
    Path,
    Chart as RNWChart,
    XAxis,
    YAxis,
    useLayout,
    useChart,
    useLine,
} from 'react-native-web-svg-charts';
import { View } from './View';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
//import * as scale from "d3-scale";
//import * as shape from "d3-shape";

import {
    Circle,
    G,
    Line,
    Rect,
    Text as TextSVG,
} from 'react-native-svg';


const NAME = 'Chart';

const AXIS_FONT_SIZE = 15;
const AXIS_FONT_WEIGHT = 'normal';

const Y_AXIS_WIDTH = 40;
const X_AXIS_HEIGHT = Math.round(AXIS_FONT_SIZE * 1.5);

const MAX_X_TICKS = 10;

const contentInset = {
    top: AXIS_FONT_SIZE,
    bottom: AXIS_FONT_SIZE,
    //    left: 50,
    //    right: 50,
};

class ChartUtils {

    static GetVerticalLimits(data, smallFraction = 0.05) {
        const yMin = data.reduce((ymin, v) => Math.min(v.y, ymin), Number.POSITIVE_INFINITY);
        const yMax = data.reduce((ymax, v) => Math.max(v.y, ymax), Number.NEGATIVE_INFINITY);
        const small = 0;//smallFraction * (yMax - yMin);
        return {
            yMin: yMin - small,
            yMax: yMax + small,
        };
    }

    static GetXNumTicks(count, langIndex) {
        var i = 0;
        const steps = [1, 3, 6, 12, 24];
        while (i < steps.length && (count / steps[i]) >= (langIndex ? MAX_X_TICKS / 2 : MAX_X_TICKS)) {
            i++;
        }
        const result = Math.round(count / steps[i]);
        return result;
    }

    //<HorizontalLine {...{ y }} />
    static HorizontalLine(_props/*{ y }*/) {
        return (
            <Line
                key={'zero-yaxis'}
                x1={'0%'}
                x2={'100%'}
                y1={_props.y(50)}
                y2={_props.y(50)}
                stroke={Colors.colors.gray}
                strokeDasharray={[4, 8]}
                strokeWidth={2}
            />
        );
    }

    //<VerticalLine {...{ x }} />
    static VerticalLine(_props/*{ x }*/) {
        return (
            <Line
                key={'zero-xaxis'}
                x1={_props.x(50)}
                x2={_props.x(50)}
                y1={'0%'}
                y2={'100%'}
                stroke={Colors.colors.gray}
                strokeDasharray={[4, 8]}
                strokeWidth={2}
            />
        );
    }

    //<Decorator {...{ x, y, data }} />
    static Decorator(_props/*{ x, y, data }*/) {
        return _props.data.map((value, index) => (
            <Circle
                key={`circle${index}`}
                cx={_props.x(index)}
                cy={_props.y(value)}
                r={4}
                stroke={'rgb(134, 65, 244)'}
                fill={Colors.colors.white}
            />
        ));
    }

    //<Tooltip {...{ x, y, data }} />
    static Tooltip(_props/*{ x, y, data }*/) {
        return (
            <G
                x={_props.x(5) - 75 / 2}
                key={'tooltip'}
                onPress={() => console.log('tooltip clicked')}
            >
                <G y={50}>
                    <Rect
                        height={40}
                        width={75}
                        stroke={Colors.colors.gray}
                        fill={Colors.colors.white}
                        ry={10}
                        rx={10}
                    />
                    <TextSVG
                        x={75 / 2}
                        dy={20}
                        alignmentBaseline={'middle'}
                        textAnchor={'middle'}
                        stroke={'rgb(134, 65, 244)'}
                    >
                        {`${_props.data[5]}ºC`}
                    </TextSVG>
                </G>
                <G x={75 / 2}>
                    <Line
                        y1={50 + 40}
                        y2={_props.y(_props.data[5])}
                        stroke={Colors.colors.gray}
                        strokeWidth={2}
                    />
                    <Circle
                        cy={_props.y(_props.data[5])}
                        r={6}
                        stroke={'rgb(134, 65, 244)'}
                        strokeWidth={2}
                        fill={Colors.colors.white}
                    />
                </G>
            </G>
        );
    }

    //<CustomGrid {...{ x, y, data, ticks }} />
    static CustomGrid(_props/*{ x, y, data, ticks }*/) {
        return (
            <G>
                {// Horizontal grid
                    _props.ticks.map(tick => (
                        <Line
                            key={tick}
                            x1={'0%'}
                            x2={'100%'}
                            y1={_props.y(tick)}
                            y2={_props.y(tick)}
                            stroke={'rgba(0,0,0,0.2)'}
                        />
                    ))}
                {// Vertical grid
                    _props.data.map((_, index) => (
                        <Line
                            key={index}
                            y1={'0%'}
                            y2={'100%'}
                            x1={_props.x(index)}
                            x2={_props.x(index)}
                            stroke={'rgba(0,0,0,0.2)'}
                        />
                    ))}
            </G>
        );
    }
}


export const Chart = props => {

    const {
        style,
        color,
        lineColor,
        data,
        yFormat,
        xFormat1,
        xFormat2,
        dynamic,
        langIndex,
        viewStyle,
    } = props;

    const { yMin, yMax } = ChartUtils.GetVerticalLimits(data);

    const {
        width,
        height,
        onLayout,
    } = useLayout();

    const {
        x,
        y,
        ticks,
        mappedData,
    } = useChart({
        width,
        height,
        data,
        yMin,
        yMax,
        yAccessor: ({ item }) => item.y,
        xAccessor: ({ item }) => item.x,
        contentInset,
        //xScale: scale.scaleTime,
        //curve: shape.curveLinear,
    });

    const { line } = useLine({
        mappedData,
        x,
        y,
    });

    var xHeight = 0;
    if (xFormat1) {
        xHeight += X_AXIS_HEIGHT;
    }
    if (xFormat2) {
        xHeight += X_AXIS_HEIGHT;
    }
    const yAxisStyle = {
        width: Y_AXIS_WIDTH,
        height: viewStyle.height - xHeight,
    };
    const xAxisStyle = {
        width: viewStyle.width - Y_AXIS_WIDTH,
        height: X_AXIS_HEIGHT,
        marginLeft: Y_AXIS_WIDTH,
        marginTop: AXIS_FONT_SIZE * 0.25,
    };
    const chartStyle = {
        width: xAxisStyle.width,
        height: yAxisStyle.height,
    };

    const _color = color ? color : Colors.colors.gray;
    const _lineColor = lineColor ? lineColor : _color;

    Console.stack(NAME, props, { width, height, ticks, line, data, mappedData });

    return (
        <View
            style={viewStyle}
        >
            <View
                style={styles.row}
            >
                <YAxis
                    style={yAxisStyle}
                    data={data}
                    contentInset={contentInset}
                    fill={_color}
                    fontSize={AXIS_FONT_SIZE}
                    fontWeight={AXIS_FONT_WEIGHT}
                    numberOfTicks={ticks?.length}
                    yAccessor={({ item }) => item.y}
                    formatLabel={yFormat}
                />
                <View
                    style={[style, chartStyle]}
                >
                    <RNWChart
                        style={styles.chart}
                        {...{ width, height, onLayout }}
                    >
                        <Grid
                            y={y}
                            ticks={ticks}
                            contentInset={contentInset}
                        />
                        <Path
                            d={line}
                            fill={'none'}
                            stroke={_lineColor}
                            strokeWidth={3}
                            animate={dynamic}
                            animationDuration={dynamic}
                        />
                        <Path
                            d={`M0,0L0,${height}`}
                            fill={'none'}
                            stroke={_color}
                            strokeWidth={2}
                        />
                        <Path
                            d={`M0,${height}L${width},${height}`}
                            fill={'none'}
                            stroke={_color}
                            strokeWidth={2}
                        />
                    </RNWChart>
                </View>
            </View>
            {Optional(xFormat1, (
                <XAxis
                    style={xAxisStyle}
                    data={data}
                    contentInset={contentInset}
                    fill={_color}
                    fontSize={AXIS_FONT_SIZE}
                    fontWeight={AXIS_FONT_WEIGHT}
                    numberOfTicks={ChartUtils.GetXNumTicks(data.length, langIndex)}
                    xAccessor={({ item }) => item.x}
                    formatLabel={xFormat1}
                />
            ))}
            {Optional(xFormat2, (
                <XAxis
                    style={xAxisStyle}
                    data={data}
                    contentInset={contentInset}
                    fill={_color}
                    fontSize={AXIS_FONT_SIZE}
                    fontWeight={AXIS_FONT_WEIGHT}
                    numberOfTicks={ChartUtils.GetXNumTicks(data.length, langIndex)}
                    xAccessor={({ item }) => item.x}
                    formatLabel={xFormat2}
                />
            ))}
        </View>
    );
    /*
                    formatLabel={v => ChartUtils.XDateLabel(v)}

            <XAxis
                data={data}
                xAccessor={({ item }) => item.x}
                numberOfTicks={6}
                fill={color}
                fontSize={AXIS_FONT_SIZE}
                fontWeight={AXIS_FONT_WEIGHT}
                style={{ marginHorizontal: -15, height: 20 }}
                contentInset={CONTENT_INSET}
                rotation={20}
                originY={30}
                y={5}
                formatLabel={ChartUtils.XDateLabel}
            />
                    contentInset={contentInset}

                style={{ marginHorizontal: -15, height: 20 }}
                contentInset={{ left: 10, right: 25 }}
                rotation={20}
                originY={30}
                y={5}
    */
};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
    },
    chart: {
        flex: 1,
    },
});
