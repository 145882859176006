import React from 'react';
import { Button as RNPButton } from 'react-native-paper';
import { OnPress } from './utils';
import { useAppState, useSystemState } from '../context';
import { Console, Numbers, Validate } from '../utils';
import { TextStyles } from '../styles';

const NAME = 'Button';

export const BUTTON_VARIANTS = {
    TEXT: 'text',
    UNDERLINED: 'underlined',
    OUTLINED: 'outlined',
    CONTAINED: 'contained',
};

const DEFAULT_VARIANT = BUTTON_VARIANTS.OUTLINED;


export const Button = props => {

    const {
        value,
        onPress,
        style,
        disabled,
        variant,
        noTranslate,
        icon,
        backgroundColor,
        iconColor,
        iconSize,
        textColor,
        labelStyle,
    } = props;

    const { deviceScale, fontScale } = useSystemState();
    const { t, dark } = useAppState();

    const iconName = icon ? icon.name(dark) : null;
    const mode = !Validate.isValidNonEmptyString(variant)
        ? DEFAULT_VARIANT
        : BUTTON_VARIANTS.UNDERLINED === variant
            ? BUTTON_VARIANTS.TEXT
            : variant;
    const xValue = Validate.isValidNonEmptyString(value)
        ? noTranslate ? value : t(value)
        : null;

    var buttonLabelStyle = labelStyle
        ? { ...TextStyles.buttonLabel, ...labelStyle }
        : { ...TextStyles.buttonLabel };
    if (variant === BUTTON_VARIANTS.UNDERLINED) {
        buttonLabelStyle.textDecorationLine = 'underline';
    }
    if (textColor) {
        buttonLabelStyle.color = textColor;
    } else if (iconColor) {
        buttonLabelStyle.color = iconColor;
    }
    buttonLabelStyle = Numbers.ReScaleStyle(buttonLabelStyle, deviceScale / fontScale);

    Console.stack(`${NAME}[${xValue}]`, props, { deviceScale, fontScale, dark, iconName, iconSize, mode, xValue, buttonLabelStyle, noTranslate });

    return (
        <RNPButton
            style={style}
            disabled={disabled}
            color={backgroundColor}
            icon={iconName}
            mode={mode}
            labelStyle={buttonLabelStyle}
            uppercase={false}
            onPress={() => OnPress(value, onPress)}
        >
            {xValue}
        </RNPButton>
    );
};
