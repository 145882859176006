import React, { createContext, useContext, useReducer } from 'react';
import { Array2Object, Console, /*GeoGrid,*/ GetSunInfo, Numbers, Toggles, Validate } from '../utils';
import {
  CreateBuses,
  CreateWeather,
  DecodeBus,
  DecodeWeather,
  IsHawaii,
  IsKauai,
  IsMaui,
  IsOahu,
  NUM_COMPASS_MODES,
  NUM_MAP_MODES,
  NUM_MENU_MODES,
  NUM_TRACKING_MODES,
  NUM_TRANSIT_MODES,
} from '../map';

const NAME = 'OahuProvider';

const types = [
  'CLEAR_TOGGLES',
  'SET_CAMERA',
  'SET_COMPASS_MODE',
  'SET_MENU_MODE',
  'SET_MAP_MODE',
  'SET_TOGGLE',
  'SET_TRACKING_MODE',
  'SET_TRANSIT_MODE',
  'UPDATE_BUSES',
  'UPDATE_SATELLITES',
  'UPDATE_LOCATIONS',
  'UPDATE_SURF',
  'UPDATE_WEATHER',
  'UPDATE_YELP',
];
export const TYPES = Array2Object(types);

const OahuStateContext = createContext();
const OahuDispatchContext = createContext();

let TOGGLES = new Toggles();

var oahuState = {

  compassModeIndex: 0,
  mapModeIndex: 0,
  menuModeIndex: 0,
  trackingModeIndex: NUM_TRACKING_MODES,
  transitModeIndex: NUM_TRANSIT_MODES,

  minZoom: 6.0,
  maxZoom: 21.0,

  latitude: 0,
  longitude: 0,
  heading: 0,
  pitch: 0,
  zoom: 0,

  minLatitude: Number.POSITIVE_INFINITY,
  maxLatitude: Number.NEGATIVE_INFINITY,
  minLongitude: Number.POSITIVE_INFINITY,
  maxLongitude: Number.NEGATIVE_INFINITY,

  getToggle: type => TOGGLES.get(type),
  enabledToggles: () => TOGGLES.enabled(),
  toggleUpdate: 2,

  buses: new Map(),
  busesUpdate: 2,

  satellites: [],
  satellitesUpdate: 2,

  locations: {},
  locationsUpdate: 2,

  surf: [],
  surfUpdate: 2,

  weather: {
    lookup: new Map(),
    get: step => [],
  },
  weatherUpdate: 2,

  yelp: [],
  yelpUpdate: 2,

  sunInfo: (lat, lng) => {
    const now = new Date();
    var result = {
      today: GetSunInfo(lat, lng, now),
      tomorrow: GetSunInfo(lat, lng, new Date(now.getTime() + (24 * 60 * 60 * 1000))),
    };
    result.upcoming = (currentTime = null) => {
      var time = currentTime ? currentTime : new Date();
      const mins = time.getMinutes() + 60 * time.getHours();
      const pending = mins <= result.today[0]
        ? [result.today[0], result.today[1]] // before sunrise today
        : mins <= result.today[1]
          ? [result.today[1], result.tomorrow[0]] // before sunset today
          : [result.tomorrow[0], result.tomorrow[1]]; // after sunset today
      return pending;
    };
    return result;
  },
};

const oahuReducer = (state, action) => {

  const { type, payload } = action;
  var result;
  var records;

  switch (type) {

    case TYPES.SET_CAMERA:
      result = {
        ...state,
      };
      if (payload?.center) {
        if (Validate.isValid(payload.center?.latitude)) {
          result.latitude = payload.center.latitude;
        }
        if (Validate.isValid(payload.center?.longitude)) {
          result.longitude = payload.center.longitude;
        }
      }
      if (Validate.isValid(payload?.heading)) {
        result.heading = payload.heading;
      }
      if (Validate.isValid(payload?.pitch)) {
        result.pitch = payload.pitch;
      }
      if (Validate.isValid(payload?.zoom)) {
        result.zoom = Numbers.clamp(payload.zoom, state.minZoom, state.maxZoom);
      }

      if (payload?.region) {
        if (Validate.isValid(payload.region?.minLatitude)) {
          result.minLatitude = payload.region.minLatitude;
        }
        if (Validate.isValid(payload.region?.maxLatitude)) {
          result.maxLatitude = payload.region.maxLatitude;
        }
        if (Validate.isValid(payload.region?.minLongitude)) {
          result.minLongitude = payload.region.minLongitude;
        }
        if (Validate.isValid(payload.region?.maxLongitude)) {
          result.maxLongitude = payload.region.maxLongitude;
        }
      }
      break;

    case TYPES.SET_COMPASS_MODE:
      result = {
        ...state,
        compassModeIndex: payload % NUM_COMPASS_MODES,
      };
      break;

    case TYPES.SET_MAP_MODE:
      result = {
        ...state,
        mapModeIndex: payload % NUM_MAP_MODES,
      };
      break;

    case TYPES.SET_MENU_MODE:
      result = {
        ...state,
        menuModeIndex: payload % NUM_MENU_MODES,
      };
      break;

    case TYPES.SET_TRACKING_MODE:
      result = {
        ...state,
        trackingModeIndex: payload % NUM_TRACKING_MODES,
      };
      break;

    case TYPES.SET_TRANSIT_MODE:
      result = {
        ...state,
        transitModeIndex: payload % NUM_TRANSIT_MODES,
      };
      break;

    case TYPES.SET_TOGGLE: // sets the toggleUpdate to a positive
      TOGGLES.toggle(payload);
      result = {
        ...state,
        toggleUpdate: Math.abs(state.toggleUpdate) + 1,
      };
      Console.devLog(`${NAME} [${type}](${result.toggleUpdate})`, { payload });
      break;

    case TYPES.CLEAR_TOGGLES: // sets the toggleUpdate to a negative
    TOGGLES.clear();
      result = {
        ...state,
        toggleUpdate: -(Math.abs(state.toggleUpdate) + 1),
      };
      Console.devLog(`${NAME} [${type}](${result.toggleUpdate})`, { payload });
      break;

    case TYPES.UPDATE_BUSES:
      records = payload.map(item => DecodeBus(item));
      result = {
        ...state,
        buses: CreateBuses(records),
        busesUpdate: state.busesUpdate + 1,
      };
      break;

    case TYPES.UPDATE_SATELLITES:
      result = {
        ...state,
        satellites: payload,
        satellitesUpdate: state.satellitesUpdate + 1,
      };
      break;

    case TYPES.UPDATE_LOCATIONS:
      /**/
      if (payload.attractions) {
        payload.attractions
          .filter(v => v?.images)
          .map(v => ({ key: v.key, images: v.images.map(w => ({ age: w.age, uri: w.source.uri })) }))
          .forEach(v => console.log(v.key, v.images));
        //console.log('\n\n\n', payload.attractions.filter(v => v?.images).map(v => ({ key: v.key, images: v.images.length })));
      }
      /**/
      result = {
        ...state,
        locationsUpdate: state.locationsUpdate + 1,
      };
      if (!result.locations) {
        result.locations = {};
      }
      Object.keys(payload).forEach(key => {
        result.locations[key] = /*new GeoGrid(*/payload[key].map(v => {
          var retval = v?.coord ?
            {
              ...v,
              coordinate: {
                latitude: v.coord[0],
                longitude: v.coord[1],
              },
            }
            :
            {
              ...v,
            };
          if (!retval?.contact) {
            retval.contact = {};
          }
          if (IsHawaii(retval)) {
            retval.contact.island = 'hawaii';
          }
          if (IsKauai(retval)) {
            retval.contact.island = 'kauai';
          }
          if (IsOahu(retval)) {
            retval.contact.island = 'oahu';
          }
          if (IsMaui(retval)) {
            retval.contact.island = 'maui';
          }
          return retval;
        });
      });
      break;

    case TYPES.UPDATE_SURF:
      result = {
        ...state,
        surf: payload,
        surfUpdate: state.surfUpdate + 1,
      };
      break;

    case TYPES.UPDATE_WEATHER:
      records = payload.map(item => DecodeWeather(item, state.sunInfo));
      if (records?.length) {
        result = {
          ...state,
          weather: CreateWeather(records),
          weatherUpdate: state.weatherUpdate + 1,
        };
      }
      break;

    case TYPES.UPDATE_YELP:
      result = {
        ...state,
        yelp: [...state.yelp, ...payload],
        yelpUpdate: state.yelpUpdate + 1,
      };
      break;

    default:
      throw new Error(`oahuReducer: Unhandled action '${type}'.`);
  }

  Console.LOG(`oahuReducer[${type}] payload.length (${payload?.length})`);// (${/*MARKMARK*/false && payload?.length ? JSON.stringify(payload[0]) : Object.keys(payload)})`);
  //Console.LOG(`oahuReducer[${type}] (${JSON.stringify(payload)})`);
  Console.trace('oahuReducer', { state, result });

  return result;
};


export const OahuProvider = props => {

  const {
    children,
  } = props;

  const [state, dispatch] = useReducer(oahuReducer, oahuState);

  Console.stack(NAME, props, { state });

  return (
    <OahuStateContext.Provider value={state}>
      <OahuDispatchContext.Provider value={dispatch}>
        {children}
      </OahuDispatchContext.Provider>
    </OahuStateContext.Provider>
  );
};

export const useOahuState = () => useContext(OahuStateContext);
export const useOahuDispatch = () => useContext(OahuDispatchContext);
