export const ipaWavLookup = {
    'm̪̥': { 'cv': 'cv_1_1', 'vc': 'vc_1_1', 'vcv': 'vcv_1_2' },
    'm̪': { 'cv': 'cv_1_2', 'vc': 'vc_1_2', 'vcv': 'vcv_1_3' },
    'n̼̥': { 'cv': 'cv_1_3', 'vc': 'vc_1_3', 'vcv': 'vcv_1_4' },
    'n̼': { 'cv': 'cv_1_4', 'vc': 'vc_1_4', 'vcv': 'vcv_1_5' },
    'n̪̥': { 'cv': 'cv_1_5', 'vc': 'vc_1_5', 'vcv': 'vcv_1_6' },
    'n̪': { 'cv': 'cv_1_6', 'vc': 'vc_1_6', 'vcv': 'vcv_1_7' },
    'n̥': { 'cv': 'cv_1_7', 'vc': 'vc_1_7', 'vcv': 'vcv_1_8' },
    'n': { 'cv': 'cv_1_8', 'vc': 'vc_1_8', 'vcv': 'vcv_1_9' },
    'n̠̥': { 'cv': 'cv_1_9', 'vc': 'vc_1_9', 'vcv': 'vcv_1_10' },
    'n̠': { 'cv': 'cv_1_10', 'vc': 'vc_1_10', 'vcv': 'vcv_1_11' },
    'ɳ̥': { 'cv': 'cv_1_11', 'vc': 'vc_1_11', 'vcv': 'vcv_1_12' },
    'ɳ': { 'cv': 'cv_1_12', 'vc': 'vc_1_12', 'vcv': 'vcv_1_13' },
    'ȵ̥': { 'cv': 'cv_1_13', 'vc': 'vc_1_13', 'vcv': 'vcv_1_14' },
    'ȵ': { 'cv': 'cv_1_14', 'vc': 'vc_1_14', 'vcv': 'vcv_1_15' },
    'p': { 'cv': 'cv_1_15', 'vc': 'vc_1_15', 'vcv': 'vcv_1_16' },
    'b': { 'cv': 'cv_1_16', 'vc': 'vc_1_16', 'vcv': 'vcv_1_17' },
    'p̪': { 'cv': 'cv_1_17', 'vc': 'vc_1_17', 'vcv': 'vcv_1_18' },
    'b̪': { 'cv': 'cv_1_18', 'vc': 'vc_1_18', 'vcv': 'vcv_5_74' },
    't̼': { 'cv': 'cv_1_19', 'vc': 'vc_1_19', 'vcv': 'vcv_5_75' },
    'd̼': { 'cv': 'cv_1_20', 'vc': 'vc_1_20', 'vcv': 'vcv_5_76' },
    't̪': { 'cv': 'cv_1_21', 'vc': 'vc_1_21', 'vcv': 'vcv_5_77' },
    'd̪': { 'cv': 'cv_1_22', 'vc': 'vc_1_22', 'vcv': 'vcv_5_78' },
    't': { 'cv': 'cv_1_23', 'vc': 'vc_1_23', 'vcv': 'vcv_5_79' },
    'd': { 'cv': 'cv_1_24', 'vc': 'vc_1_24', 'vcv': 'vcv_5_80' },
    'θ̼': { 'cv': 'cv_2_25', 'vc': 'vc_a_210', 'vcv': 'vcv_2_22' },
    'ð̼': { 'cv': 'cv_2_26', 'vc': 'vc_2_25', 'vcv': 'vcv_2_23' },
    'θ': { 'cv': 'cv_2_27', 'vc': 'vc_2_26', 'vcv': 'vcv_2_24' },
    'ð': { 'cv': 'cv_2_28', 'vc': 'vc_2_27', 'vcv': 'vcv_2_25' },
    's': { 'cv': 'cv_2_29', 'vc': 'vc_2_28', 'vcv': 'vcv_2_26' },
    'z': { 'cv': 'cv_2_30', 'vc': 'vc_2_29', 'vcv': 'vcv_2_27' },
    'ʃ': { 'cv': 'cv_2_31', 'vc': 'vc_2_30', 'vcv': 'vcv_2_28' },
    'ʒ': { 'cv': 'cv_2_32', 'vc': 'vc_2_31', 'vcv': 'vcv_2_29' },
    'ʂ': { 'cv': 'cv_2_33', 'vc': 'vc_2_32', 'vcv': 'vcv_2_30' },
    'ʐ': { 'cv': 'cv_2_34', 'vc': 'vc_2_33', 'vcv': 'vcv_2_31' },
    'ɕ': { 'cv': 'cv_2_35', 'vc': 'vc_2_34', 'vcv': 'vcv_2_32' },
    'ʑ': { 'cv': 'cv_2_36', 'vc': 'vc_2_35', 'vcv': 'vcv_2_33' },
    'φ̞': { 'cv': 'cv_2_37', 'vc': 'vc_2_36', 'vcv': 'vcv_2_34' },
    'β̞': { 'cv': 'cv_2_38', 'vc': 'vc_2_37', 'vcv': 'vcv_2_35' },
    'ʋ̥': { 'cv': 'cv_2_39', 'vc': 'vc_2_38', 'vcv': 'vcv_2_36' },
    'ʋ': { 'cv': 'cv_2_40', 'vc': 'vc_2_39', 'vcv': 'vcv_7_109' },
    'θ̼̞': { 'cv': 'cv_2_41', 'vc': 'vc_2_40', 'vcv': 'vcv_6_92' },
    'ð̼̞': { 'cv': 'cv_2_42', 'vc': 'vc_2_41', 'vcv': 'vcv_6_93' },
    'θ̞': { 'cv': 'cv_2_43', 'vc': 'vc_2_42', 'vcv': 'vcv_6_94' },
    'ð̞': { 'cv': 'cv_2_44', 'vc': 'vc_2_43', 'vcv': 'vcv_6_95' },
    'ɹ̥': { 'cv': 'cv_2_45', 'vc': 'vc_2_44', 'vcv': 'vcv_6_96' },
    'ɹ': { 'cv': 'cv_2_46', 'vc': 'vc_2_45', 'vcv': 'vcv_6_97' },
    'r̪̥': { 'cv': 'cv_3_47', 'vc': 'vc_3_48', 'vcv': 'vcv_3_42' },
    'r̪': { 'cv': 'cv_3_48', 'vc': 'vc_3_49', 'vcv': 'vcv_3_43' },
    'r̥': { 'cv': 'cv_3_49', 'vc': 'vc_3_50', 'vcv': 'vcv_3_44' },
    'r': { 'cv': 'cv_3_50', 'vc': 'vc_3_51', 'vcv': 'vcv_3_45' },
    'r̠̥': { 'cv': 'cv_3_51', 'vc': 'vc_3_52', 'vcv': 'vcv_3_46' },
    'r̠': { 'cv': 'cv_3_52', 'vc': 'vc_3_53', 'vcv': 'vcv_3_47' },
    'r̥<sup>ɽ</sup>': { 'cv': 'cv_3_53', 'vc': 'vc_3_54', 'vcv': 'vcv_3_48' },
    'r<sup>ɽ</sup>': { 'cv': 'cv_3_54', 'vc': 'vc_3_55', 'vcv': 'vcv_3_49' },
    'r̥<sup>j</sup>': { 'cv': 'cv_3_55', 'vc': 'vc_3_56', 'vcv': 'vcv_3_50' },
    'r<sup>j</sup>': { 'cv': 'cv_3_56', 'vc': 'vc_3_57', 'vcv': 'vcv_3_51' },
    'ɲ̥': { 'cv': 'cv_3_57', 'vc': 'vc_3_58', 'vcv': 'vcv_3_52' },
    'ɲ': { 'cv': 'cv_3_58', 'vc': 'vc_3_59', 'vcv': 'vcv_3_53' },
    'ŋ̥': { 'cv': 'cv_3_59', 'vc': 'vc_3_60', 'vcv': 'vcv_3_54' },
    'ŋ': { 'cv': 'cv_3_60', 'vc': 'vc_3_61', 'vcv': 'vcv_7_110' },
    'ŋ̥ʷ': { 'cv': 'cv_3_61', 'vc': 'vc_3_62', 'vcv': 'vcv_7_111' },
    'ŋʷ': { 'cv': 'cv_3_62', 'vc': 'vc_3_63', 'vcv': 'vcv_7_112' },
    'ɴ̥': { 'cv': 'cv_3_63', 'vc': 'vc_3_64', 'vcv': 'vcv_7_113' },
    'ɴ': { 'cv': 'cv_3_64', 'vc': 'vc_3_65', 'vcv': 'vcv_7_114' },
    'ɴ̥<sup>ʌ</sup>': { 'cv': 'cv_3_65', 'vc': 'vc_3_66', 'vcv': 'vcv_7_115' },
    'ɴ<sup>ʌ</sup>': { 'cv': 'cv_3_66', 'vc': 'vc_3_67', 'vcv': 'vcv_7_116' },
    'ʌ̙': { 'cv': 'cv_4_67', 'vc': 'vc_9_188', 'vcv': 'vcv_8_138' },
    'ɣ': { 'cv': 'cv_4_68', 'vc': 'vc_4_70', 'vcv': 'vcv_4_59' },
    'xʷ': { 'cv': 'cv_4_69', 'vc': 'vc_4_71', 'vcv': 'vcv_4_60' },
    'ɣʷ': { 'cv': 'cv_4_70', 'vc': 'vc_4_72', 'vcv': 'vcv_4_61' },
    'χ': { 'cv': 'cv_4_71', 'vc': 'vc_4_73', 'vcv': 'vcv_4_62' },
    'ʁ': { 'cv': 'cv_4_72', 'vc': 'vc_4_74', 'vcv': 'vcv_4_63' },
    'χ<sup>ʌ</sup>': { 'cv': 'cv_4_73', 'vc': 'vc_4_75', 'vcv': 'vcv_4_64' },
    'ʁ<sup>ʌ</sup>': { 'cv': 'cv_4_74', 'vc': 'vc_4_76', 'vcv': 'vcv_4_65' },
    'ħ': { 'cv': 'cv_4_75', 'vc': 'vc_4_77', 'vcv': 'vcv_4_66' },
    'ʕ': { 'cv': 'cv_4_76', 'vc': 'vc_4_78', 'vcv': 'vcv_4_67' },
    'ʜ͜ħ': { 'cv': 'cv_4_77', 'vc': 'vc_4_79', 'vcv': 'vcv_4_68' },
    'ʢ͜ʕ': { 'cv': 'cv_4_78', 'vc': 'vc_4_80', 'vcv': 'vcv_4_69' },
    'ʜ<sup>ħ</sup>': { 'cv': 'cv_4_79', 'vc': 'vc_4_81', 'vcv': 'vcv_4_70' },
    'ʢ<sup>ʕ</sup>': { 'cv': 'cv_4_80', 'vc': 'vc_4_82', 'vcv': 'vcv_4_71' },
    'h': { 'cv': 'cv_4_81', 'vc': 'vc_4_83', 'vcv': 'vcv_9_146' },
    'ɦ': { 'cv': 'cv_4_82', 'vc': 'vc_4_84', 'vcv': 'vcv_8_128' },
    'j̥': { 'cv': 'cv_4_83', 'vc': 'vc_4_85', 'vcv': 'vcv_8_129' },
    'j': { 'cv': 'cv_4_84', 'vc': 'vc_4_86', 'vcv': 'vcv_8_130' },
    'ɰ̥': { 'cv': 'cv_4_85', 'vc': 'vc_4_87', 'vcv': 'vcv_8_131' },
    'ɰ': { 'cv': 'cv_4_86', 'vc': 'vc_4_88', 'vcv': 'vcv_8_132' },
    'ʍ': { 'cv': 'cv_4_87', 'vc': 'vc_4_89', 'vcv': 'vcv_8_133' },
    'ħ̘': { 'cv': 'cv_5_88', 'vc': 'vc_9_189', 'vcv': 'vcv_8_139' },
    'ʕ̘': { 'cv': 'cv_5_89', 'vc': 'vc_9_190', 'vcv': 'vcv_8_140' },
    'ʜ̘͜ħ̘': { 'cv': 'cv_5_90', 'vc': 'vc_9_191', 'vcv': 'vcv_8_141' },
    'ʢ̘͜ʕ̘': { 'cv': 'cv_5_91', 'vc': 'vc_9_192', 'vcv': 'vcv_8_142' },
    'ʜ̘': { 'cv': 'cv_5_92', 'vc': 'vc_9_193', 'vcv': 'vcv_8_143' },
    'ʢ̘': { 'cv': 'cv_5_93', 'vc': 'vc_9_194', 'vcv': 'vcv_8_144' },
    'ʎ̥': { 'cv': 'cv_5_94', 'vc': 'vc_9_195', 'vcv': 'vcv_c_202' },
    'ʎ': { 'cv': 'cv_5_95', 'vc': 'vc_9_196', 'vcv': 'vcv_c_203' },
    'ʟ̥': { 'cv': 'cv_5_96', 'vc': 'vc_9_197', 'vcv': 'vcv_c_204' },
    'ʟ': { 'cv': 'cv_5_97', 'vc': 'vc_9_198', 'vcv': 'vcv_c_205' },
    'ʟ̥ʷ': { 'cv': 'cv_5_98', 'vc': 'vc_9_199', 'vcv': 'vcv_c_206' },
    'ʟʷ': { 'cv': 'cv_5_99', 'vc': 'vc_9_200', 'vcv': 'vcv_c_207' },
    'ʟ̠̥': { 'cv': 'cv_5_100', 'vc': 'vc_9_201', 'vcv': 'vcv_c_208' },
    'ʟ̠': { 'cv': 'cv_5_101', 'vc': 'vc_9_202', 'vcv': 'vcv_c_209' },
    'ʟ̠̥<sup>ʌ</sup>': { 'cv': 'cv_5_102', 'vc': 'vc_9_203', 'vcv': 'vcv_c_210' },
    'ʟ̠<sup>ʌ</sup>': { 'cv': 'cv_5_103', 'vc': 'vc_9_204', 'vcv': 'vcv_c_211' },
    'c̆': { 'cv': 'cv_5_104', 'vc': 'vc_9_205', 'vcv': 'vcv_c_212' },
    'ɟ̆': { 'cv': 'cv_5_105', 'vc': 'vc_9_206', 'vcv': 'vcv_c_213' },
    'k̆': { 'cv': 'cv_5_106', 'vc': 'vc_9_207', 'vcv': 'vcv_c_214' },
    'ğ': { 'cv': 'cv_5_107', 'vc': 'vc_9_208', 'vcv': 'vcv_c_215' },
    'k̆ʷ': { 'cv': 'cv_5_108', 'vc': 'vc_9_209', 'vcv': 'vcv_c_216' },
    'r̼': { 'cv': 'cv_6_109', 'vc': 'vc_3_47', 'vcv': 'vcv_3_41' },
    'q̆<sup>ʌ</sup>': { 'cv': 'cv_6_110', 'vc': 'vc_5_104', 'vcv': 'vcv_e_248' },
    'ɢ̆<sup>ʌ</sup>': { 'cv': 'cv_6_111', 'vc': 'vc_5_105', 'vcv': 'vcv_e_249' },
    'ʡ̆': { 'cv': 'cv_6_112', 'vc': 'vc_5_106', 'vcv': 'vcv_e_250' },
    'ʡ̬̆': { 'cv': 'cv_6_113', 'vc': 'vc_5_107', 'vcv': 'vcv_e_251' },
    'ʀ̥': { 'cv': 'cv_6_114', 'vc': 'vc_5_108', 'vcv': 'vcv_e_252' },
    'ʀ': { 'cv': 'cv_6_115', 'vc': 'vc_5_109', 'vcv': 'vcv_e_253' },
    'ʀ̥<sup>ʌ</sup>': { 'cv': 'cv_6_116', 'vc': 'vc_5_110', 'vcv': 'vcv_e_254' },
    'ʀ<sup>ʌ</sup>': { 'cv': 'cv_6_117', 'vc': 'vc_5_111', 'vcv': 'vcv_e_255' },
    'ʜ': { 'cv': 'cv_6_118', 'vc': 'vc_5_112', 'vcv': 'vcv_e_256' },
    'ʢ': { 'cv': 'cv_6_119', 'vc': 'vc_5_113', 'vcv': 'vcv_e_257' },
    'ʡʰ': { 'cv': 'cv_6_120', 'vc': 'vc_b_240', 'vcv': 'vcv_b_198' },
    'cç': { 'cv': 'cv_6_121', 'vc': 'vc_b_241', 'vcv': 'vcv_b_199' },
    'ɟʝ': { 'cv': 'cv_6_122', 'vc': 'vc_7_138', 'vcv': 'vcv_b_200' },
    'kx': { 'cv': 'cv_6_123', 'vc': 'vc_7_139', 'vcv': 'vcv_d_231' },
    'gɣ': { 'cv': 'cv_6_124', 'vc': 'vc_7_140', 'vcv': 'vcv_d_232' },
    'kxʷ': { 'cv': 'cv_6_125', 'vc': 'vc_7_141', 'vcv': 'vcv_d_233' },
    'gɣʷ': { 'cv': 'cv_6_126', 'vc': 'vc_7_142', 'vcv': 'vcv_d_234' },
    'qχ': { 'cv': 'cv_6_127', 'vc': 'vc_7_143', 'vcv': 'vcv_d_235' },
    'ɢʁ': { 'cv': 'cv_6_128', 'vc': 'vc_7_144', 'vcv': 'vcv_d_236' },
    'qχ<sup>ʌ</sup>': { 'cv': 'cv_6_129', 'vc': 'vc_7_145', 'vcv': 'vcv_d_237' },
    'ɢʁ<sup>ʌ</sup>': { 'cv': 'cv_6_130', 'vc': 'vc_7_146', 'vcv': 'vcv_d_238' },
    '<small>Q</small>ħ': { 'cv': 'cv_6_131', 'vc': 'vc_7_147', 'vcv': 'vcv_b_201' },
    'v': { 'cv': 'cv_7_132', 'vc': 'vc_8_161', 'vcv': 'vcv_2_21' },
    'm̥': { 'cv': 'cv_7_133', 'vc': 'vc_b_242', 'vcv': 'vcv_9_145' },
    'ʌ̙̥': { 'cv': 'cv_7_134', 'vc': 'vc_9_187', 'vcv': 'vcv_8_137' },
    'k': { 'cv': 'cv_7_135', 'vc': 'vc_8_163', 'vcv': 'vcv_7_119' },
    'g': { 'cv': 'cv_7_136', 'vc': 'vc_8_164', 'vcv': 'vcv_7_120' },
    'kʷ': { 'cv': 'cv_7_137', 'vc': 'vc_8_165', 'vcv': 'vcv_7_121' },
    'gʷ': { 'cv': 'cv_7_138', 'vc': 'vc_8_166', 'vcv': 'vcv_7_122' },
    'q': { 'cv': 'cv_7_139', 'vc': 'vc_8_167', 'vcv': 'vcv_7_123' },
    'ɢ': { 'cv': 'cv_7_140', 'vc': 'vc_8_168', 'vcv': 'vcv_7_124' },
    'q<sup>ʌ</sup>': { 'cv': 'cv_7_141', 'vc': 'vc_8_169', 'vcv': 'vcv_7_125' },
    'ɢ<sup>ʌ</sup>': { 'cv': 'cv_7_142', 'vc': 'vc_8_170', 'vcv': 'vcv_7_126' },
    '<small>Q</small>': { 'cv': 'cv_7_143', 'vc': 'vc_8_171', 'vcv': 'vcv_7_127' },
    'ʡ͜<small>Q</small>': { 'cv': 'cv_7_144', 'vc': 'vc_8_172', 'vcv': 'vcv_b_183' },
    'ʡ': { 'cv': 'cv_7_145', 'vc': 'vc_8_173', 'vcv': 'vcv_b_184' },
    'Ɂ': { 'cv': 'cv_7_146', 'vc': 'vc_8_174', 'vcv': 'vcv_b_185' },
    'cʰ': { 'cv': 'cv_7_147', 'vc': 'vc_8_175', 'vcv': 'vcv_b_186' },
    'ɟʱ': { 'cv': 'cv_7_148', 'vc': 'vc_8_176', 'vcv': 'vcv_b_187' },
    'kʰ': { 'cv': 'cv_7_149', 'vc': 'vc_8_177', 'vcv': 'vcv_b_188' },
    'gʱ': { 'cv': 'cv_7_150', 'vc': 'vc_8_178', 'vcv': 'vcv_b_189' },
    'k<sup>ʍ</sup>': { 'cv': 'cv_7_151', 'vc': 'vc_8_179', 'vcv': 'vcv_b_190' },
    'g<sup>w̤</sup>': { 'cv': 'cv_7_152', 'vc': 'vc_8_180', 'vcv': 'vcv_b_191' },
    'qʰ': { 'cv': 'cv_7_153', 'vc': 'vc_8_181', 'vcv': 'vcv_b_192' },
    'ɢʱ': { 'cv': 'cv_7_154', 'vc': 'vc_8_182', 'vcv': 'vcv_b_193' },
    'q<sup>ʌ</sup>ʰ': { 'cv': 'cv_7_155', 'vc': 'vc_8_183', 'vcv': 'vcv_b_194' },
    'ɢ<sup>ʌ</sup>ʱ': { 'cv': 'cv_7_156', 'vc': 'vc_8_184', 'vcv': 'vcv_b_195' },
    '<small>Q</small>ʰ': { 'cv': 'cv_7_157', 'vc': 'vc_8_185', 'vcv': 'vcv_b_196' },
    'ʡ͜<small>Q</small>ʰ': { 'cv': 'cv_7_158', 'vc': 'vc_8_186', 'vcv': 'vcv_b_197' },
    'ɻ̥': { 'cv': 'cv_8_159', 'vc': 'vc_7_137', 'vcv': 'vcv_6_100' },
    'ɻ': { 'cv': 'cv_8_160', 'vc': 'vc_6_114', 'vcv': 'vcv_6_101' },
    'ɹ̥<sup>j</sup>': { 'cv': 'cv_8_161', 'vc': 'vc_6_115', 'vcv': 'vcv_6_102' },
    'ɹ<sup>j</sup>': { 'cv': 'cv_8_162', 'vc': 'vc_6_116', 'vcv': 'vcv_6_103' },
    'φ̞<sup>l</sup>': { 'cv': 'cv_8_163', 'vc': 'vc_6_117', 'vcv': 'vcv_6_104' },
    'β̞<sup>l</sup>': { 'cv': 'cv_8_164', 'vc': 'vc_6_118', 'vcv': 'vcv_6_105' },
    'ʋ̥<sup>l</sup>': { 'cv': 'cv_8_165', 'vc': 'vc_6_119', 'vcv': 'vcv_6_106' },
    'ʋ<sup>l</sup>': { 'cv': 'cv_8_166', 'vc': 'vc_6_120', 'vcv': 'vcv_6_107' },
    'l̼̥': { 'cv': 'cv_8_167', 'vc': 'vc_6_121', 'vcv': 'vcv_6_108' },
    'l̼': { 'cv': 'cv_8_168', 'vc': 'vc_6_122', 'vcv': 'vcv_a_165' },
    'l̪̥': { 'cv': 'cv_8_169', 'vc': 'vc_6_123', 'vcv': 'vcv_a_166' },
    'l̪': { 'cv': 'cv_8_170', 'vc': 'vc_6_124', 'vcv': 'vcv_a_167' },
    'l̥': { 'cv': 'cv_8_171', 'vc': 'vc_6_125', 'vcv': 'vcv_a_168' },
    'l': { 'cv': 'cv_8_172', 'vc': 'vc_6_126', 'vcv': 'vcv_a_169' },
    'l̠̥': { 'cv': 'cv_8_173', 'vc': 'vc_6_127', 'vcv': 'vcv_a_170' },
    'l̠': { 'cv': 'cv_8_174', 'vc': 'vc_6_128', 'vcv': 'vcv_a_171' },
    'ɭ̥': { 'cv': 'cv_8_175', 'vc': 'vc_6_129', 'vcv': 'vcv_a_172' },
    'ɭ': { 'cv': 'cv_8_176', 'vc': 'vc_6_130', 'vcv': 'vcv_a_173' },
    'ȴ̥': { 'cv': 'cv_8_177', 'vc': 'vc_6_131', 'vcv': 'vcv_a_174' },
    'ȴ': { 'cv': 'cv_8_178', 'vc': 'vc_6_132', 'vcv': 'vcv_a_175' },
    'ⱱ̟̥': { 'cv': 'cv_8_179', 'vc': 'vc_6_133', 'vcv': 'vcv_a_176' },
    'ⱱ̟': { 'cv': 'cv_8_180', 'vc': 'vc_6_134', 'vcv': 'vcv_a_177' },
    'ⱱ̥': { 'cv': 'cv_8_181', 'vc': 'vc_6_135', 'vcv': 'vcv_a_178' },
    'ⱱ': { 'cv': 'cv_8_182', 'vc': 'vc_6_136', 'vcv': 'vcv_a_179' },
    'x': { 'cv': 'cv_9_183', 'vc': 'vc_4_69', 'vcv': 'vcv_4_58' },
    'ʝ': { 'cv': 'cv_9_184', 'vc': 'vc_4_68', 'vcv': 'vcv_4_57' },
    'ʈ': { 'cv': 'cv_9_185', 'vc': 'vc_a_212', 'vcv': 'vcv_5_83' },
    'ɖ': { 'cv': 'cv_9_186', 'vc': 'vc_a_213', 'vcv': 'vcv_5_84' },
    'ȶ': { 'cv': 'cv_9_187', 'vc': 'vc_a_214', 'vcv': 'vcv_5_85' },
    'ȡ': { 'cv': 'cv_9_188', 'vc': 'vc_a_215', 'vcv': 'vcv_5_86' },
    'pʰ': { 'cv': 'cv_9_189', 'vc': 'vc_a_216', 'vcv': 'vcv_5_87' },
    'bʱ': { 'cv': 'cv_9_190', 'vc': 'vc_a_217', 'vcv': 'vcv_5_88' },
    'p̪ʰ': { 'cv': 'cv_9_191', 'vc': 'vc_a_218', 'vcv': 'vcv_5_89' },
    'b̪ʱ': { 'cv': 'cv_9_192', 'vc': 'vc_a_219', 'vcv': 'vcv_5_90' },
    't̼ʰ': { 'cv': 'cv_9_193', 'vc': 'vc_a_220', 'vcv': 'vcv_5_91' },
    'd̼ʱ': { 'cv': 'cv_9_194', 'vc': 'vc_a_221', 'vcv': 'vcv_9_147' },
    't̪ʰ': { 'cv': 'cv_9_195', 'vc': 'vc_a_222', 'vcv': 'vcv_9_148' },
    'd̪ʱ': { 'cv': 'cv_9_196', 'vc': 'vc_a_223', 'vcv': 'vcv_9_149' },
    'tʰ': { 'cv': 'cv_9_197', 'vc': 'vc_a_224', 'vcv': 'vcv_9_150' },
    'dʱ': { 'cv': 'cv_9_198', 'vc': 'vc_a_225', 'vcv': 'vcv_9_151' },
    't̠ʰ': { 'cv': 'cv_9_199', 'vc': 'vc_a_226', 'vcv': 'vcv_9_152' },
    'd̠ʱ': { 'cv': 'cv_9_200', 'vc': 'vc_a_227', 'vcv': 'vcv_9_153' },
    'ʈʰ': { 'cv': 'cv_9_201', 'vc': 'vc_a_228', 'vcv': 'vcv_9_154' },
    'ɖʱ': { 'cv': 'cv_9_202', 'vc': 'vc_a_229', 'vcv': 'vcv_9_155' },
    'ȶʰ': { 'cv': 'cv_9_203', 'vc': 'vc_a_230', 'vcv': 'vcv_9_156' },
    'ȡʱ': { 'cv': 'cv_9_204', 'vc': 'vc_a_231', 'vcv': 'vcv_9_157' },
    'pφ': { 'cv': 'cv_9_205', 'vc': 'vc_a_232', 'vcv': 'vcv_9_158' },
    'bβ': { 'cv': 'cv_9_206', 'vc': 'vc_a_233', 'vcv': 'vcv_9_159' },
    'p̪f': { 'cv': 'cv_9_207', 'vc': 'vc_a_234', 'vcv': 'vcv_9_160' },
    'b̪v': { 'cv': 'cv_9_208', 'vc': 'vc_a_235', 'vcv': 'vcv_9_161' },
    'ç': { 'cv': 'cv_a_209', 'vc': 'vc_5_90', 'vcv': 'vcv_4_56' },
    'r̼̥': { 'cv': 'cv_a_210', 'vc': 'vc_3_46', 'vcv': 'vcv_3_40' },
    'ʙ̪': { 'cv': 'cv_a_211', 'vc': 'vc_8_160', 'vcv': 'vcv_3_39' },
    'ɾ̪̥': { 'cv': 'cv_a_212', 'vc': 'vc_5_91', 'vcv': 'vcv_a_182' },
    'ɾ̪': { 'cv': 'cv_a_213', 'vc': 'vc_5_92', 'vcv': 'vcv_e_239' },
    'ɾ̥': { 'cv': 'cv_a_214', 'vc': 'vc_5_93', 'vcv': 'vcv_e_240' },
    'ɾ': { 'cv': 'cv_a_215', 'vc': 'vc_5_94', 'vcv': 'vcv_e_241' },
    'ɾ̠̥': { 'cv': 'cv_a_216', 'vc': 'vc_5_95', 'vcv': 'vcv_e_242' },
    'ɾ̠': { 'cv': 'cv_a_217', 'vc': 'vc_5_96', 'vcv': 'vcv_e_243' },
    'ɽ̥': { 'cv': 'cv_a_218', 'vc': 'vc_5_97', 'vcv': 'vcv_e_244' },
    'ɽ': { 'cv': 'cv_a_219', 'vc': 'vc_5_98', 'vcv': 'vcv_e_245' },
    'ɾ̥<sup>j</sup>': { 'cv': 'cv_a_220', 'vc': 'vc_5_99', 'vcv': 'vcv_e_246' },
    'ɾ<sup>j</sup>': { 'cv': 'cv_a_221', 'vc': 'vc_5_100', 'vcv': 'vcv_e_247' },
    't̼θ̼': { 'cv': 'cv_a_222', 'vc': 'vc_7_148', 'vcv': 'vcv_9_162' },
    'd̼ð̼': { 'cv': 'cv_a_223', 'vc': 'vc_7_149', 'vcv': 'vcv_9_163' },
    't̪θ': { 'cv': 'cv_a_224', 'vc': 'vc_7_150', 'vcv': 'vcv_9_164' },
    'd̪ð': { 'cv': 'cv_a_225', 'vc': 'vc_7_151', 'vcv': 'vcv_d_222' },
    'ts': { 'cv': 'cv_a_226', 'vc': 'vc_7_152', 'vcv': 'vcv_d_223' },
    'dz': { 'cv': 'cv_a_227', 'vc': 'vc_7_153', 'vcv': 'vcv_d_224' },
    'tʃ': { 'cv': 'cv_a_228', 'vc': 'vc_7_154', 'vcv': 'vcv_d_225' },
    'dʒ': { 'cv': 'cv_a_229', 'vc': 'vc_7_155', 'vcv': 'vcv_d_226' },
    'ʈʂ': { 'cv': 'cv_a_230', 'vc': 'vc_7_156', 'vcv': 'vcv_d_227' },
    'ɖʐ': { 'cv': 'cv_a_231', 'vc': 'vc_7_157', 'vcv': 'vcv_d_228' },
    'ȶɕ': { 'cv': 'cv_a_232', 'vc': 'vc_7_158', 'vcv': 'vcv_d_229' },
    'ȡʑ': { 'cv': 'cv_a_233', 'vc': 'vc_7_159', 'vcv': 'vcv_d_230' },
    'm': { 'cv': 'cv_b_234', 'vc': 'vc_b_243', 'vcv': 'vcv_1_1' },
    'c': { 'cv': 'cv_b_235', 'vc': 'vc_b_236', 'vcv': 'vcv_7_117' },
    'ɟ': { 'cv': 'cv_b_236', 'vc': 'vc_8_162', 'vcv': 'vcv_7_118' },
    'ɹ̠̥': { 'cv': 'cv_b_237', 'vc': 'vc_b_244', 'vcv': 'vcv_6_98' },
    'ɹ̠': { 'cv': 'cv_b_238', 'vc': 'vc_b_245', 'vcv': 'vcv_6_99' },
    't̠': { 'cv': 'cv_b_239', 'vc': 'vc_b_237', 'vcv': 'vcv_5_81' },
    'd̠': { 'cv': 'cv_b_240', 'vc': 'vc_a_211', 'vcv': 'vcv_5_82' },
    'ɾ̼̥': { 'cv': 'cv_b_241', 'vc': 'vc_b_238', 'vcv': 'vcv_a_180' },
    'ɾ̼': { 'cv': 'cv_b_242', 'vc': 'vc_b_239', 'vcv': 'vcv_a_181' },
    'φ': { 'cv': 'cv_b_243', 'vc': 'vc_b_252', 'vcv': 'vcv_d_220' },
    'β': { 'cv': 'cv_b_244', 'vc': 'vc_b_253', 'vcv': 'vcv_2_19' },
    'f': { 'cv': 'cv_b_245', 'vc': 'vc_b_254', 'vcv': 'vcv_2_20' },
    'ʙ̥': { 'cv': 'cv_b_246', 'vc': 'vc_b_249', 'vcv': 'vcv_d_221' },
    'ʙ': { 'cv': 'cv_b_247', 'vc': 'vc_b_250', 'vcv': 'vcv_3_37' },
    'ʙ̪̥': { 'cv': 'cv_b_248', 'vc': 'vc_b_251', 'vcv': 'vcv_3_38' },
    'ʡ͜<small>Q</small>ʜ͜ħ': { 'cv': 'cv_b_249', 'vc': 'vc_b_255', 'vcv': 'vcv_5_73' },
    'ʡʜ': { 'cv': 'cv_b_250', 'vc': 'vc_b_256', 'vcv': 'vcv_5_72' },
    'ʔh': { 'cv': 'cv_b_251', 'vc': 'vc_b_257', 'vcv': 'vcv_4_55' },
    'w': { 'cv': 'cv_b_252', 'vc': 'vc_b_246', 'vcv': 'vcv_8_134' },
    'χ̞': { 'cv': 'cv_b_253', 'vc': 'vc_b_247', 'vcv': 'vcv_8_135' },
    'ʁ̞': { 'cv': 'cv_b_254', 'vc': 'vc_b_248', 'vcv': 'vcv_8_136' },
    'ğʷ': { 'cv': 'cv_b_255', 'vc': 'vc_5_101', 'vcv': 'vcv_c_217' },
    'q̆': { 'cv': 'cv_b_256', 'vc': 'vc_5_102', 'vcv': 'vcv_c_218' },
    'ɢ̆': { 'cv': 'cv_b_257', 'vc': 'vc_5_103', 'vcv': 'vcv_c_219' },
}
    ;

/*
'n':{'cv':'cv_1_8','vc':'vc_1_8','vcv':'vcv_1_9'},
'ɳ':{'cv':'cv_1_12','vc':'vc_1_12','vcv':'vcv_1_13'},
'p':{'cv':'cv_1_15','vc':'vc_1_15','vcv':'vcv_1_16'},
'b':{'cv':'cv_1_16','vc':'vc_1_16','vcv':'vcv_1_17'},
't':{'cv':'cv_1_23','vc':'vc_1_23','vcv':'vcv_5_79'},
'd':{'cv':'cv_1_24','vc':'vc_1_24','vcv':'vcv_5_80'},
'θ':{'cv':'cv_2_27','vc':'vc_2_26','vcv':'vcv_2_24'},
'ð':{'cv':'cv_2_28','vc':'vc_2_27','vcv':'vcv_2_25'},
's':{'cv':'cv_2_29','vc':'vc_2_28','vcv':'vcv_2_26'},
'z':{'cv':'cv_2_30','vc':'vc_2_29','vcv':'vcv_2_27'},
'ʃ':{'cv':'cv_2_31','vc':'vc_2_30','vcv':'vcv_2_28'},
'ʒ':{'cv':'cv_2_32','vc':'vc_2_31','vcv':'vcv_2_29'},
'ʋ':{'cv':'cv_2_40','vc':'vc_2_39','vcv':'vcv_7_109'},
'ɹ':{'cv':'cv_2_46','vc':'vc_2_45','vcv':'vcv_6_97'},
'r':{'cv':'cv_3_50','vc':'vc_3_51','vcv':'vcv_3_45'},
'ɲ':{'cv':'cv_3_58','vc':'vc_3_59','vcv':'vcv_3_53'},
'ŋ':{'cv':'cv_3_60','vc':'vc_3_61','vcv':'vcv_7_110'},
'ɴ':{'cv':'cv_3_64','vc':'vc_3_65','vcv':'vcv_7_114'},
'ɣ':{'cv':'cv_4_68','vc':'vc_4_70','vcv':'vcv_4_59'},
'χ':{'cv':'cv_4_71','vc':'vc_4_73','vcv':'vcv_4_62'},
'ʁ':{'cv':'cv_4_72','vc':'vc_4_74','vcv':'vcv_4_63'},
'h':{'cv':'cv_4_81','vc':'vc_4_83','vcv':'vcv_9_146'},
'ɦ':{'cv':'cv_4_82','vc':'vc_4_84','vcv':'vcv_8_128'},
'j':{'cv':'cv_4_84','vc':'vc_4_86','vcv':'vcv_8_130'},
'ɰ':{'cv':'cv_4_86','vc':'vc_4_88','vcv':'vcv_8_132'},
'ʍ':{'cv':'cv_4_87','vc':'vc_4_89','vcv':'vcv_8_133'},
'ʎ':{'cv':'cv_5_95','vc':'vc_9_196','vcv':'vcv_c_203'},
'ʟ':{'cv':'cv_5_97','vc':'vc_9_198','vcv':'vcv_c_205'},
'ʀ':{'cv':'cv_6_115','vc':'vc_5_109','vcv':'vcv_e_253'},
'ʜ':{'cv':'cv_6_118','vc':'vc_5_112','vcv':'vcv_e_256'},
'v':{'cv':'cv_7_132','vc':'vc_8_161','vcv':'vcv_2_21'},
'k':{'cv':'cv_7_135','vc':'vc_8_163','vcv':'vcv_7_119'},
'g':{'cv':'cv_7_136','vc':'vc_8_164','vcv':'vcv_7_120'},
'q':{'cv':'cv_7_139','vc':'vc_8_167','vcv':'vcv_7_123'},
'ɢ':{'cv':'cv_7_140','vc':'vc_8_168','vcv':'vcv_7_124'},
'l':{'cv':'cv_8_172','vc':'vc_6_126','vcv':'vcv_a_169'},
'ⱱ':{'cv':'cv_8_182','vc':'vc_6_136','vcv':'vcv_a_179'},
'x':{'cv':'cv_9_183','vc':'vc_4_69','vcv':'vcv_4_58'},
'ʝ':{'cv':'cv_9_184','vc':'vc_4_68','vcv':'vcv_4_57'},
'ɾ':{'cv':'cv_a_215','vc':'vc_5_94','vcv':'vcv_e_241'},
'ɽ':{'cv':'cv_a_219','vc':'vc_5_98','vcv':'vcv_e_245'},
'ts':{'cv':'cv_a_226','vc':'vc_7_152','vcv':'vcv_d_223'},
'dz':{'cv':'cv_a_227','vc':'vc_7_153','vcv':'vcv_d_224'},
'tʃ':{'cv':'cv_a_228','vc':'vc_7_154','vcv':'vcv_d_225'},
'dʒ':{'cv':'cv_a_229','vc':'vc_7_155','vcv':'vcv_d_226'},
'm':{'cv':'cv_b_234','vc':'vc_b_243','vcv':'vcv_1_1'},
'c':{'cv':'cv_b_235','vc':'vc_b_236','vcv':'vcv_7_117'},
'φ':{'cv':'cv_b_243','vc':'vc_b_252','vcv':'vcv_d_220'},
'β':{'cv':'cv_b_244','vc':'vc_b_253','vcv':'vcv_2_19'},
'f':{'cv':'cv_b_245','vc':'vc_b_254','vcv':'vcv_2_20'},
'ʙ':{'cv':'cv_b_247','vc':'vc_b_250','vcv':'vcv_3_37'},
'w':{'cv':'cv_b_252','vc':'vc_b_246','vcv':'vcv_8_134'},
'χ̞':{'cv':'cv_b_253','vc':'vc_b_247','vcv':'vcv_8_135'},
*/
