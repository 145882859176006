//import { AsyncStorage } from 'react-native'; // MARKMARK : TODO deprecated
//import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { Console } from './Console';

const isWeb = Platform.OS === 'web';

class AsyncStorage {
    static setItem = (key, value) => { };
    static removeItem = key => { };
    static getItem = key => { return null; };
}

export class Storage {

    static setItem(key, value) {
        (async () => {
            try {
                isWeb
                    ? localStorage.setItem(key, value)
                    : await AsyncStorage.setItem(key, value);
            } catch (error) {
                Console.error(`StoraStorage.setItem(${isWeb}, ${key}, ${JSON.stringify(value)})`, { error });
            }
        })();
    }

    static delItem(key) {
        (async () => {
            try {
                isWeb
                    ? localStorage.removeItem(key)
                    : await AsyncStorage.removeItem(key);
            } catch (error) {
                Console.error(`Storage.delItem(${isWeb}, ${key}})`, { error });
            }
        })();
    }

    static getItem(key) {
        (async () => {
            try {
                const value = isWeb
                    ? localStorage.getItem(key)
                    : await AsyncStorage.getItem(key);
                return value;
            } catch (error) {
                Console.error(`Storage.getItem(${isWeb}, ${key}})`, { error });
            }
        })();
    }

    static setObj(key, value) {
        Storage.setItem(key, JSON.stringify(value));
    }

    static delObj(key) {
        return JSON.parse(Storage.delItem(key));
    }

    static getObj(key) {
        return JSON.parse(Storage.getItem(key));
    }
}
