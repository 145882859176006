import React from 'react';
import Svg, { Circle, Rect, Path } from '../svg';
import { Text } from '../components';


export const PleasantHolidaysLogoSize = {
    width: 209,
    height: 24,
};

const BOOKING_TO_BE_ANNOUNCED = true;
export const PleasantHolidaysLogo = dark => {

    return BOOKING_TO_BE_ANNOUNCED
        ? <Text value={'To be announced'} />
        : (
        <Svg
            xmlns={'http://www.w3.org/2000/svg'}
            width={`${PleasantHolidaysLogoSize.width}`}
            height={`${PleasantHolidaysLogoSize.height}`}
            viewBox={'0 0 209 24'}
            fill={dark ? '#fff' : '#000'}
        >
            <Path d={'M18.11,.31h-3.68V20.26h4.91V1.53c0-.68-.55-1.23-1.23-1.23Z'} />
            <Path d={'M11.05,.31H0V20.26H4.91v-6.14h6.14c.68,0,1.23-.55,1.23-1.23V1.53c0-.68-.55-1.23-1.23-1.23Zm-3.71,11.97c0,.34-.27,.61-.61,.61h-1.84V2.15c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61V12.28Z'} />
            <Path d={'M46.65,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v2.46h4.91v-1.84c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v4.3h-6.14c-.68,0-1.23,.55-1.23,1.23v4.91c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23V7.98c0-.68-.55-1.23-1.23-1.23Zm-3.68,11.66c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-3.68c0-.34,.27-.61,.61-.61h1.84v4.3Z'} />
            <Path d={'M32.53,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v11.05c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23v-2.46h-4.91v1.84c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-4.3h6.14c.68,0,1.23-.55,1.23-1.23V7.98c0-.68-.55-1.23-1.23-1.23Zm-3.68,5.52c0,.34-.27,.61-.61,.61h-1.84v-4.3c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v3.68Z'} />
            <Path d={'M60.77,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v3.2c0,1.03,.64,1.95,1.6,2.3l4.96,1.84c.48,.18,.8,.64,.8,1.15v1.94c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-1.84h-4.91v2.46c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23v-3.2c0-1.03-.64-1.95-1.6-2.3l-4.96-1.84c-.48-.18-.8-.64-.8-1.15v-1.94c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v1.84h4.91v-2.46c0-.68-.55-1.23-1.23-1.23Z'} />
            <Path d={'M90.54,17.8V7.98c0-.68-.55-1.23-1.23-1.23h-11.05v13.5h4.91s0-12.28,0-12.28h1.84c.34,0,.61,.27,.61,.61v10.43c0,.68,.55,1.23,1.23,1.23h3.68v-2.46Z'} />
            <Path d={'M94.22,20.26h6.14v-1.23h-1.23c-.68,0-1.23-.55-1.23-1.23V7.98h2.46v-1.23h-2.46V1.53c0-.68-.55-1.23-1.23-1.23h-3.68V5.52c0,.68-.55,1.23-1.23,1.23v1.23h1.23v11.05c0,.68,.55,1.23,1.23,1.23Z'} />
            <Path d={'M74.88,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v2.46h4.91v-1.84c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v4.3h-6.14c-.68,0-1.23,.55-1.23,1.23v4.91c0,.68,.55,1.23,1.23,1.23,0,0,6.94,0,9.82,0,.68,0,1.23-.55,1.23-1.23V7.98c0-.68-.55-1.23-1.23-1.23Zm-3.68,11.66c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-3.68c0-.34,.27-.61,.61-.61h1.84v4.3Z'} />
            <Path d={'M175.24,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v2.46h4.91v-1.84c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v4.3h-6.14c-.68,0-1.23,.55-1.23,1.23v4.91c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23V7.98c0-.68-.55-1.23-1.23-1.23Zm-3.68,11.66c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-3.68c0-.34,.27-.61,.61-.61h1.84v4.3Z'} />
            <Path d={'M139.33,.31h-3.68V20.26h4.91V1.53c0-.68-.55-1.23-1.23-1.23Z'} />
            <Rect
                x={'143.02'}
                y={'6.75'}
                width={'4.91'}
                height={'13.5'}
            />
            <Path d={'M161.12,.31h-3.68V6.75h-6.14c-.68,0-1.23,.55-1.23,1.23v11.05c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23V1.53c0-.68-.55-1.23-1.23-1.23Zm-3.68,18.11c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61V8.59c0-.34,.27-.61,.61-.61h1.84v10.43Z'} />
            <Path d={'M132.27,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v11.05c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23V7.98c0-.68-.55-1.23-1.23-1.23Zm-3.68,11.66c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61V8.59c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v9.82Z'} />
            <Path d={'M189.67,6.75h-3.68v11.66c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61V6.75h-4.91v12.28c0,.68,.55,1.23,1.23,1.23h6.14v2.46c0,.68,.55,1.23,1.23,1.23h3.68V7.98c0-.68-.55-1.23-1.23-1.23Z'} />
            <Path d={'M203.78,6.75h-9.82c-.68,0-1.23,.55-1.23,1.23v3.2c0,1.03,.64,1.95,1.6,2.3l4.96,1.84c.48,.18,.8,.64,.8,1.15v1.94c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61v-1.84h-4.91v2.46c0,.68,.55,1.23,1.23,1.23h9.82c.68,0,1.23-.55,1.23-1.23v-3.2c0-1.03-.64-1.95-1.6-2.3l-4.96-1.84c-.48-.18-.8-.64-.8-1.15v-1.94c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v1.84h4.91v-2.46c0-.68-.55-1.23-1.23-1.23Z'} />
            <Path d={'M117.85,.31h-3.68V12.28c0,.34-.27,.61-.61,.61h-1.23c-.34,0-.61-.27-.61-.61V.31h-4.91V20.26h4.91v-5.52c0-.34,.27-.61,.61-.61h1.23c.34,0,.61,.27,.61,.61v5.52h4.91V1.53c0-.68-.55-1.23-1.23-1.23Z'} />
            <Path d={'M208.64,18.16c-.24-.24-.53-.36-.87-.36s-.6,.11-.84,.33c-.26,.24-.38,.54-.38,.89s.12,.64,.35,.87,.53,.35,.87,.35,.62-.12,.86-.35c.25-.24,.37-.53,.37-.88s-.12-.63-.36-.87Zm-.15,1.59c-.2,.2-.44,.3-.71,.3s-.52-.1-.72-.3c-.2-.2-.3-.44-.3-.72s.1-.51,.29-.71c.2-.2,.44-.31,.72-.31s.52,.1,.72,.31c.2,.2,.29,.44,.29,.71s-.1,.52-.3,.72Z'} />
            <Path d={'M208.33,19.63s0-.03,0-.04v-.12c0-.14-.04-.24-.12-.3-.04-.03-.11-.06-.19-.08,.09-.01,.17-.04,.24-.09,.06-.05,.1-.13,.1-.25,0-.15-.06-.26-.18-.32-.07-.04-.16-.06-.27-.07-.02,0-.11,0-.28,0-.17,0-.29,0-.38,0v1.36h.36v-.48h.15c.05,0,.09,.02,.12,.03,.05,.02,.08,.05,.1,.1,.01,.03,.02,.08,.02,.17,0,.08,.01,.14,.02,.19h.34v-.04s-.02-.03-.02-.04Zm-.4-.7s-.11,.05-.19,.05h-.14v-.39h.09c.09,0,.16,0,.21,.03,.07,.03,.11,.08,.11,.15s-.02,.13-.07,.16Z'} />
            <Circle
                cx={'145.47'}
                cy={'2.76'}
                r={'2.76'}
            />
        </Svg>
    );
};
