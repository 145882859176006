import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Animated, Easing, StyleSheet } from 'react-native';
import { Auth } from '../aws';
import { Button, Icon, Image, Pressable, Text, TEXT_VARIANTS, Screen, Video, View } from '../components';
import { useAppState, useSystemState/*, useAppDispatch, APP_TYPES*/ } from '../context';
import { Icons, Images } from '../media';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { DEV_COLOR, ANIMATED_USE_NATIVE_DRIVER } from '../constants';
import { Navigate } from './utils';

const NAME = 'Splash';

const ENTER_APP = 'Enter App';

const LOGIN = 'Login';

const DA_APP_HAWAII_VIDEO = 'G6InHYb6Tsg';

const HEADER_SECONDS = 7;
const HEADERS = [
    ['_Info', Icons.Info],
    ['_Help', Icons.Help],
    ['_Theme', Icons.Theme],
    ['_Translate', Icons.Translate],
];

const START_HERE = 'Start here';


export const Splash = props => {

    const {
        navigation,
    } = props;

    const { isWeb, deviceScale, headerHeight, width, adjHeight } = useSystemState();
    const { auth, /*dark,*/ t, theme, themeUpdate } = useAppState();

    //const appDispatch = useAppDispatch();
    //const appDispatchRef = useRef(appDispatch)

    const [animatedHeader] = useState(new Animated.Value(0));
    const [headerTicker, setHeaderTicker] = useState(0);
    const setHeaderTickerRef = useRef(setHeaderTicker);
    const [headerIndex, setHeaderIndex] = useState(0);
    const setHeaderIndexRef = useRef(setHeaderIndex);

    const onEnter = useCallback(
        async () => {
            const currentUser = await Auth.currentAuthenticatedUser();
            Navigate(navigation, t(LOGIN), { title: t(LOGIN), currentUser: currentUser ? JSON.stringify(currentUser) : null });
        },
        [
            t,
            navigation,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect animate header`);
            Animated.timing(animatedHeader, {
                useNativeDriver: ANIMATED_USE_NATIVE_DRIVER,
                toValue: Number.MAX_SAFE_INTEGER / 1000,
                duration: Number.MAX_SAFE_INTEGER,
                easing: Easing.linear, // inOut, bounce, linear, elastic(0.7), bezier(0.75, -0.25, 0.25, 1.25)
                delay: 0,
            }).start();
            animatedHeader.addListener(({ value }) => {
                setHeaderTickerRef.current(Math.floor(value / HEADER_SECONDS));
            });
            return () => {
                Console.log(`${NAME} useEffect remove animate header`);
                animatedHeader.removeAllListeners();
            };
        },
        [
            animatedHeader,
            setHeaderTickerRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect header`, { headerTicker });
            setHeaderIndexRef.current(v => (v + 1) % HEADERS.length);
        },
        [
            headerTicker,
            setHeaderIndexRef,
        ],
    );

    Console.stack(NAME, props, { auth, width, adjHeight, headerHeight });

    return useMemo(
        () => {
            //const idealAspect = 0.75;
            const fontSize = {
                fontSize: 12 * deviceScale,
            };
            const headerPercent = 0.8;
            const marginAdjust = 0.95;
            const resizeMode = 'contain';
            var w = width * marginAdjust / deviceScale;
            var h = adjHeight * headerPercent * marginAdjust / deviceScale;

            const getImageInfo = (viewWidth, viewHeight, heightScale, imageWidth, imageHeight, borderColor) => {
                const imageAspect = imageHeight / imageWidth;
                var scaledHeight = heightScale * viewHeight;
                var scaledWidth = scaledHeight / imageAspect;
                if (scaledWidth > viewWidth) {
                    scaledWidth = viewWidth;
                    scaledHeight = scaledWidth * imageAspect;
                }
                const style = {
                    position: 'absolute',
                    borderWidth: 0,
                    borderColor,
                };
                return [scaledWidth, scaledHeight, style];
            };
            var [widthTitle, heightTitle, logoTitle] = getImageInfo(w, h, 0.3, 566, 399, 'red');
            var [widthMotto, heightMotto, logoMotto] = getImageInfo(w, h, 0.1, 2010, 168, 'blue');
            var [widthIslands, heightIslands, logoIslands] = getImageInfo(w, h, 0.5, 2164, 1401, 'black');
            var [widthHonu, heightHonu, logoHonu] = getImageInfo(w, h, 0.45, 1650, 1864, 'green');

            logoIslands.top = 0;
            logoTitle.bottom = '50%';//h * 0.15;
            logoMotto.bottom = 0;
            logoMotto.right = `${100 * (0.5 * (w - widthMotto) / w)}%`;
            logoHonu.bottom = `${100 * (1 - ((h - heightMotto) / h))}%`;
            logoHonu.right = `${5 + 100 * (0.5 * (w - widthMotto) / w)}%`;
            logoIslands.right = logoMotto.right;
            logoTitle.left = logoMotto.right;

            const backgroundColor = {
            };
            const backgroundColor2 = {
                backgroundColor: 'rgb(125,125,125)',//theme?.colors?.primary,//dark ? Colors.colors.red : Colors.colors.yellow,
            };
            Console.log(`${NAME} render`, { themeUpdate, fontSize });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    screenStyle={backgroundColor2}
                    renderHelp={(
                        <View
                            value={'SplashHelp'}
                            style={styles.helpView}
                        >
                            <View
                                value={'SplashHelpHeader'}
                                style={styles.helpHeader}
                            >
                                <Text
                                    value={'Your virtual concierge!'}
                                />
                                <Text
                                    value={'For know where to go in Hawaiʻi.'}
                                />
                            </View>
                            <View
                                value={'SplashHelpMain'}
                                style={styles.helpMain}
                            >
                                <Video
                                    source={DA_APP_HAWAII_VIDEO}
                                    youtube={true}
                                />
                            </View>
                            <View
                                value={'SplashHelpFooter'}
                                style={styles.helpFooter}
                            >
                                <Text
                                    value={'www.youtube.com/@braddahhonu'}
                                />
                            </View>
                        </View>
                    )}
                    helpBackgroundOpacity={1.0}
                    headerHeight={`${headerPercent * 100.0}%`}
                    headerStyle={[styles.header, backgroundColor]}
                    headerView={(
                        <>
                            <View
                                value={'SplashScreenHeaderLeft'}
                                style={styles.headerLeft}
                            >
                                <Image
                                    containerStyle={logoIslands}
                                    source={Images.miscl.da_app_islands}
                                    width={widthIslands}
                                    height={heightIslands}
                                    resizeMode={resizeMode}
                                />
                                <Image
                                    containerStyle={logoTitle}
                                    source={Images.miscl.da_app_title}
                                    width={widthTitle}
                                    height={heightTitle}
                                    resizeMode={resizeMode}
                                />
                                <Image
                                    containerStyle={logoMotto}
                                    source={Images.miscl.da_app_motto}
                                    width={widthMotto}
                                    height={heightMotto}
                                    resizeMode={resizeMode}
                                />
                                <Image
                                    containerStyle={logoHonu}
                                    source={Images.miscl.da_app_honu}
                                    width={widthHonu}
                                    height={heightHonu}
                                    resizeMode={resizeMode}
                                />
                            </View>
                            <View
                                value={'SplashScreenRight'}
                                style={styles.headerRight}
                            >
                                <View
                                    value={'SplashHeaderScroll'}
                                    style={styles.headerScroll}
                                >
                                    <View
                                        value={'SplashHeader'}
                                        style={styles.headerIcons}
                                    >
                                        <Icon icon={Icons.HandUp} color={theme?.colors?.text} />
                                        <Icon icon={HEADERS[headerIndex][1]} color={theme?.colors?.text} />
                                    </View>
                                    <Text
                                        style={[styles.headerIconsText, fontSize]}
                                        value={HEADERS[headerIndex][0]}
                                        color={theme?.colors?.text}
                                    />
                                </View>
                            </View>
                        </>
                    )}
                    mainHeight={-1}
                    mainStyle={[styles.main, backgroundColor]}
                    mainView={(
                        <View
                            style={styles.entry}
                        >
                            <Button
                                value={ENTER_APP}
                                style={styles.buttonEnter}
                                textColor={Colors.colors.blue}
                                onPress={onEnter}
                            />
                            <Icon
                                icon={Icons.HandLeft}
                                color={Colors.colors.red}
                            />
                            <Text
                                style={styles.textEnter}
                                value={START_HERE}
                                variant={TEXT_VARIANTS.SUBHEADING}
                            />
                        </View>
                    )}
                    footerHeight={'10%'}
                    footerStyle={[styles.footer, backgroundColor]}
                    footerView={(
                        <View
                            style={styles.links}
                        >
                            <Pressable
                                disabled={true}
                                style={styles.link}
                                onPress={() => console.log('APPLE')}
                            >
                                <Image
                                    source={Images.miscl.store_link_app_store}
                                    width={100}
                                    height={50}
                                    resizeMode={'contain'}
                                />
                            </Pressable>
                            <Pressable
                                disabled={true}
                                style={styles.link}
                                onPress={() => console.log('GOOGLE')}
                            >
                                <Image
                                    source={Images.miscl.store_link_google_play}
                                    width={100}
                                    height={50}
                                    resizeMode={'contain'}
                                />
                            </Pressable>
                            {Optional(isWeb, (
                                <a href={'mailto:sean@da-app-hawaii.com&subject=East-Meets-West'}>Contact us</a>
                            ))}
                        </View>
                    )}
                />
            );
        },
        // onPress={() => appDispatchRef.current({ type: APP_TYPES.NEXT_HEADER_STYLE_INDEX })}
        [
            //appDispatchRef,
            isWeb,
            props,
            //dark,
            width,
            adjHeight,
            deviceScale,
            theme,
            themeUpdate,
            headerIndex,
            onEnter,
        ],
    );
};

const styles = StyleSheet.create({
    header: {
        //padding: '2%',
        //flexDirection: 'row',
        width: '100%',
        //justifyContent: 'space-between',
        //backgroundColor: 'lightblue',//DEV_COLOR(Colors.colors.orange),
    },
    headerLeft: {
        position: 'absolute',
        top:'2.5%',
        left: '2.5%',
        width: '95%',
        height: '95%',
        borderWidth: 0,
        borderColor: 'purple',
        backgroundColor: DEV_COLOR(Colors.colors.purple),
    },
    headerRight: {
        position: 'absolute',
        top: '0%',
        right: '0%',
        justifyContent: 'space-between',
        alignItems: 'space-between',
        backgroundColor: DEV_COLOR(Colors.colors.magenta),
    },
    main: {
        alignContent: 'center',
        justifyContent: 'center',
        //backgroundColor: 'lightblue',
    },
    footer: {
        paddingHorizontal: '1.5%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        //backgroundColor: 'lightblue',//DEV_COLOR(Colors.colors.lightblue),
    },
    headerIcons: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginTop: '3%',
        backgroundColor: DEV_COLOR(Colors.colors.purple),
    },
    headerIconsText: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        textAlign: 'center',
        backgroundColor: DEV_COLOR(Colors.colors.red),
    },
    headerScroll: {
        alignSelf: 'flex-end',
        marginRight: 6,
        backgroundColor: DEV_COLOR(Colors.colors.green),
    },
    entry: {
        flexDirection: 'row',
        alignContent: 'center',
        justifyContent: 'center',
    },
    buttonEnter: {
        marginRight: 5,
        elevation: 5,
        zIndex: 5,
        backgroundColor: Colors.colors.lightgreen,
    },
    textEnter: {
        marginLeft: 5,
        color: Colors.colors.red,
    },
    helpView: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    helpHeader: {
        width: '100%',
        height: '25%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    helpMain: {
        width: '100%',
        height: '50%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    helpFooter: {
        width: '100%',
        height: '25%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    logo: {
        position: 'absolute',
        //top: '15%',
        //left: '67%',
        backgroundColor: DEV_COLOR(Colors.colors.yellow),
    },
    links: {
        flexDirection: 'row',
        width: '100%',
        alignContent: 'center',
        justifyContent: 'space-evenly',
        backgroundColor: DEV_COLOR(Colors.colors.white),
    },
    link: {
        justifyContent: 'center',
        //width: '50%',
        backgroundColor: DEV_COLOR(Colors.colors.maroon),
    },
});
