const LexFNum = {
    '00': 'a_all',
    '01': 'pert',
    '02': 'r_all',
    '03': 'Tops',
    '04': 'act',
    '05': 'animal',
    '06': 'artifact',
    '07': 'attribute',
    '08': 'n_body',
    '09': 'n_cognition',
    '10': 'n_communication',
    '11': 'event',
    '12': 'feeling',
    '13': 'food',
    '14': 'group',
    '15': 'location',
    '16': 'motive',
    '17': 'object',
    '18': 'person',
    '19': 'phenomenon',
    '20': 'plant',
    '21': 'n_possession',
    '22': 'process',
    '23': 'quantity',
    '24': 'relation',
    '25': 'shape',
    '26': 'state',
    '27': 'substance',
    '28': 'time',
    '29': 'v_body',
    '30': 'change',
    '31': 'v_cognition',
    '32': 'v_communication',
    '33': 'competition',
    '34': 'consumption',
    '35': 'contact',
    '36': 'creation',
    '37': 'emotion',
    '38': 'motion',
    '39': 'perception',
    '40': 'v_possession',
    '41': 'social',
    '42': 'stative',
    '43': 'weather',
    '44': 'ppl',
    '45': 'root',
    '46': 'lemma',
};

export const GetLex = type => {
    return LexFNum[type];
};
