import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import { Image, View, Screen } from '../components';
import { useAppState } from '../context';
import { Images } from '../media';
import { Console } from '../utils';
import { NavParams } from './utils';

const NAME = 'MapWebDetail';


export const MapWebDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { DEFAULT } = useAppState();

    const [source] = useState(NavParams(route)?.data);

    useEffect(
        () => {
            navigation.setOptions({
                headerTitle: () => (
                    <Image
                        source={Images.miscl.da_app_title_header}
                        width={DEFAULT.HEADER_HEIGHT * 0.9 * 3}
                        height={DEFAULT.HEADER_HEIGHT * 0.9}
                        resizeMode={'contain'}
                    />
                ),
            });
        },
        [
            navigation,
            DEFAULT,
        ],
    );

    Console.stack(NAME, props, { source });

    return useMemo(
        () => {
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        value={NAME}
                        style={styles.view}
                    >
                        <WebView
                            source={source}
                        />
                    </View>
                </Screen>
            );
        },
        [
            props,
            source,
        ],
    );
};

const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
    },
});
