import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { Card, Drawer, DynamicMap, FlatList, Screen, Text, View } from '../components';
import { useAppDispatch, useAppState, useOahuState, useOahuDispatch, useUserState, useSystemState, OAHU_TYPES, APP_TYPES } from '../context';
import { GetZoom, MapIcons, MapKeys, MapViews } from '../map';
import { Icons, MATERIAL_COMMUNITY } from '../media';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';
import { HoloCard, SurfBaseUrl, SurfFeedHtml, SURF_FEED_HEIGHT, SURF_FEED_WIDTH /*,getOahuSurf*/ } from '../thirdparty';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { API } from '../aws';
import { Navigate } from './utils';

const NAME = 'MapView';

const MAP_LOCATION_DETAIL = 'MapLocationDetail';
const MAP_SURF_DETAIL = 'MapSurfDetail';
const MAP_USER_DETAIL = 'MapUserDetail';
const MAP_DIRECTIONS_DETAIL = 'MapDirectionsDetail';
const MAP_WEATHER_DETAIL = 'MapWeatherDetail';

const MARKMARK_BUS_DELAY_FACTOR = 100;

const DISABLED_ICON_COLOR = Colors.colors.lightgray;

const DUMMY = 'dummy';

// https://github.com/swrobel/meta-surf-forecast#new-api-v2


export const MapView = props => {

    const {
        navigation,
    } = props;

    const appDisatch = useAppDispatch();
    const appDispatchRef = useRef(appDisatch);

    const { adjHeight, width } = useSystemState();
    const { holoCardNumber, holoSecurityCode } = useUserState();
    const { t, dark, drawer, showAds } = useAppState();

    const {
        buses, busesUpdate,
        satellites, satellitesUpdate,
        locations, locationsUpdate,
        surf, surfUpdate,
        weather, weatherUpdate,
        sunInfo,
    } = useOahuState();

    const oahuDispatch = useOahuDispatch();
    const oahuDispatchRef = useRef(oahuDispatch);

    const [showDrawer, setShowDrawer] = useState(false);
    const setShowDrawerRef = useRef(setShowDrawer);

    const [holo, setHolo] = useState(0);
    const setHoloRef = useRef(setHolo);

    const [bikis, setBikis] = useState([]);
    const setBikisRef = useRef(setBikis);

    const [cards, setCards] = useState([]);
    const setCardsRef = useRef(setCards);
    const [cardsUpdate, setCardsUpdate] = useState(2);
    const setCardsUpdateRef = useRef(setCardsUpdate);

    const [enabled, setEnabled] = useState(new Set());
    const setEnabledRef = useRef(setEnabled);
    const [enabledUpdate, setEnabledUpdate] = useState(2);
    const setEnabledUpdateRef = useRef(setEnabledUpdate);

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect camera`);
            oahuDispatchRef.current({ type: OAHU_TYPES.SET_CAMERA, payload: MapViews[0][0] });
        },
        [],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect holo`, { holoCardNumber, holoSecurityCode });
            (async () => {
                const _holo = await HoloCard(holoCardNumber, holoSecurityCode);
                Console.devLog(`${NAME} useEffect holo ASYNC`, { _holo });
                setHoloRef.current(_holo?.balance);
            })();
        },
        [
            holoCardNumber,
            holoSecurityCode,
            setHoloRef,
        ],
    );

    useEffect(
        () => {
            const _biki = locations?.biki; // MARKMARKGRID
            Console.devLog(`${NAME} useEffect biki`, { locationsUpdate, _biki: _biki?.length });
            setBikisRef.current(_biki?.length ? _biki : []);
        },
        [
            locations,
            locationsUpdate,
            setBikisRef,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect hidata`);

            // keep setInterval ids
            var intervalIds = [];

            [
                // MARKMARK: fix this so that multiple categories are called at once
                {
                    key: MapKeys.LOCATIONS,
                    categories: [
                        MapKeys.AIRPORTS,
                        MapKeys.ATTRACTIONS,
                        MapKeys.BEACHES,
                        MapKeys.BIKI,
                        MapKeys.GOLF,
                        MapKeys.HIKES,
                        MapKeys.HOTELS,
                        MapKeys.MARINAS,
                        MapKeys.SURF,
                        MapKeys.RESTAURANTS,

                        MapKeys.EVENTS,
                        MapKeys.PEOPLE,
                    ],
                    delay: 3600000,
                },
                {
                    key: MapKeys.BUSES,
                    categories: [DUMMY],
                    delay: MARKMARK_BUS_DELAY_FACTOR * 60000 / 8 * 2, // 7.5s
                },
                {
                    key: MapKeys.WEATHER,
                    categories: [DUMMY],
                    delay: 3600000 / 4, // 15m
                },
                {
                    key: MapKeys.SATELLITES,
                    categories: [DUMMY],
                    delay: 86400000 / 4, // 6h
                },
            ].forEach(search => {
                const { key, categories, delay } = search;
                const type = OAHU_TYPES[`UPDATE_${key.toUpperCase()}`];
                Console.devLog(`${NAME} useEffect hidata fetching`, { search, type });
                categories.forEach(category => {
                    const func = async () => {
                        var body = {
                            command: key,
                        };
                        if (key === MapKeys.SATELLITES) {
                            body.lat = 21.82;
                            body.lng = -157.84;
                        }
                        if (category !== DUMMY) {
                            body.categories = [category];
                        }
                        try {
                            const payload = await API.hidata(body);
                            Console.log(`${NAME} hidata result`, { key, category, body, payload });
                            if (payload) {
                                oahuDispatchRef.current({ type, payload });
                            }
                        } catch (error) {
                            Console.error(`${NAME} hidata error [${key},${category}]`, error);
                        }
                    };
                    intervalIds.push(setInterval(func, delay));
                    func();
                });
            });

            // cancel all of the setIntervals
            return () => {
                Console.devLog(`${NAME} useEffect hidata clearing`, { intervalIds });
                intervalIds.forEach(v => clearInterval(v));
            };
        },
        [
            oahuDispatchRef,
        ],
    );
/*
const GET_SURF = true;

    useEffect(
        () => {
            var surfIntervalId = null; // MARKMARKGRID
            if (GET_SURF && !surf?.length && locations[MapKeys.SURF] && locations[MapKeys.SURF]?.length) {
                const surfLocations = locations[MapKeys.SURF];//.filter(v => v?.type === SURF);
                var surfReports = new Map();

                if (surfLocations?.length) {
                    Console.log(`${NAME} useEffect getOahuSurf`, { surfLocations: surfLocations.length });
                    async function getSurf() {
                        const surflineIds = surfLocations.filter(v => v?.sl).map(v => v.sl);
                        Console.log(`${NAME} useEffect getOahuSurf fetching`, { surflineIds });

                        const surfData = await getOahuSurf(surflineIds);
                        for (let i = 0; i < surfData.length; i++) {
                            const report = surfData[i];
                            const id = report?.id;
                            if (surfReports.has(id)) {
                                surfReports.set(id, { ...surfReports.get(id), ...report });
                            } else {
                                surfReports.set(id, report);
                            }
                        }
                        var payload = [];
                        surfLocations.forEach(loc => {
                            var report = surfReports.get(loc?.sl);
                            var temp = { ...loc };
                            if (temp?.sl) {
                                delete temp.sl;
                            }
                            if (report) {
                                delete report.id;
                                payload.push({ ...temp, report });
                            } else {
                                payload.push(temp);
                            }
                        });
                        oahuDispatchRef.current({ type: OAHU_TYPES.UPDATE_SURF, payload });
                    }
                    getSurf();
                    setInterval(getSurf, 3600000); // 1 hr
                }
            }
            return () => {
                if (surfIntervalId) {
                    Console.log(`${NAME} useEffect getOahuSurf clearing`, { surfIntervalId });
                    clearInterval(surfIntervalId);
                }
            };
        },
        [
            locations,
            locationsUpdate,
            surf,
            oahuDispatchRef,
        ],
    );
*/
    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect toggle drawer`, { drawer });
            setShowDrawerRef.current(v => !v);
        },
        [
            drawer,
            setShowDrawerRef,
        ],
    );

    const setEnabledTypes = useCallback(
        types => {
            Console.devLog(`${NAME}.setEnabledTypes`, { types });
            setEnabledRef.current(new Set(types?.length ? types : []));
            setEnabledUpdateRef.current(v => v + 1);
        },
        [
            setEnabledRef,
            setEnabledUpdateRef,
        ],
    );

    const renderList = useCallback(
        data => {
            const renderItem = item => {
                Console.log(`${NAME}.renderList.renderItem`, { index: item?.index, item: item?.item });
                const cardStyle = { backgroundColor: item?.item?.color ? item.item.color : Colors.colors.lightgray };
                return (
                    <Card
                        id={item?.index}
                        titleStyle={styles.black}
                        cardStyle={cardStyle}
                        title={item?.item?.title ? item.item.title : item?.item ? item.item : 'unknown title'}
                        onPress={value => onCardPress(item, value)}
                    />
                );
            };

            const blackList = new Set([
                MapKeys.ATMS,
                MapKeys.BIKI,
                MapKeys.BUS_STOPS,
                MapKeys.BUSES,
                MapKeys.SURF,
                MapKeys.TOILETS,
                MapKeys.WEATHER,
            ]);

            var locationData = {};
            Object.keys(locations) // MARKMARKGRID
                .filter(key => !blackList.has(key) && enabled.has(key))
                .forEach(key => {
                    locationData[key] = locations[key];
                });

            const hasData = data?.length ? true : false;
            const hasSurf = enabled.has(MapKeys.SURF) && surf?.length ? true : false;
            const hasLocations = Object.keys(locationData).length ? true : false;
            var _locations = [];
            if (!hasData && hasSurf) {
                _locations = [..._locations, ...surf];
            }
            if (!hasData && hasLocations) {
                Object.keys(locationData).forEach(key => {
                    _locations = [..._locations, ...locationData[key]];
                });
            }

            Console.devLog(`${NAME}.renderList`, { enabledUpdate, hasData, hasSurf, hasLocations,
                _locations: _locations?.length ? _locations[0] : null,
            });

            if (!hasData && !hasSurf && !hasLocations) {
                return null;
            }

            return (
                <View
                    style={styles.info}
                >
                    {Optional(hasData, (
                        <FlatList
                            data={data}
                            renderItem={renderItem}
                        />
                    ), (
                        <FlatList
                            data={_locations.sort()}
                            renderItem={renderItem}
                        />
                    ))}
                </View>
            );
        },
        [
            locations,
            surf,
            onCardPress,
            enabled,
            enabledUpdate,
        ],
    );

    const onMarkerPress = useCallback(
        event => {
            Console.devLog(`${NAME}.onMarkerPress [${event?.data?.type}]`, { event });
        },
        [
        ],
    );

    const onCalloutPress = useCallback(
        event => {
            Console.devLog(`${NAME}.onCalloutPress [${event?.data?.type}]`, { event });

            if (event?.data?.type) {
                switch (event.data.type) {
                    case 'weather':
                        Console.devLog(`${NAME}.onCalloutPress ${event.data.type}`, { event });
                        Navigate(navigation, t(MAP_WEATHER_DETAIL), event);
                        break;
                    case 'surf':
                        Console.devLog(`\n\n\n${NAME}.onCalloutPress ${event.data.type}`, { event, data: event?.data });
                        Navigate(navigation, t(MAP_SURF_DETAIL), event);
                        break;
                    case 'user':
                    case 'busStop':
                        Console.devLog(`${NAME}.onCalloutPress ${event.data.type}`, { event });
                        Navigate(navigation, t(MAP_USER_DETAIL), event);
                        break;
                    case 'directions':
                        Console.devLog(`${NAME}.onCalloutPress ${event.data.type}`, { event });
                        Navigate(navigation, t(MAP_DIRECTIONS_DETAIL), event);
                        break;
                    default:
                        Console.devLog(`${NAME}.onCalloutPress location`, { event });
                        Navigate(navigation, t(MAP_LOCATION_DETAIL), event);
                        break;
                }
            }
        },
        [
            navigation,
            t,
        ],
    );

    const onCardPress = useCallback(
        (_item, _value) => {
            var camera = { pitch: 0, heading: 0, zoom: 15 };
            if (_item?.item?.lookat) {
                camera.center = {
                    latitude: _item.item.lookat.latitude,
                    longitude: _item.item.lookat.longitude,
                };
                camera.heading = _item.item.lookat.heading;
                camera.pitch = _item.item.lookat.tilt;
                camera.zoom = GetZoom(_item.item.lookat.altitude, _item.item.lookat.latitude);
            } else if (_item?.item?.coordinate) {
                camera.center = _item.item.coordinate;
            } else if (_item?.item?.coordinates?.length >= 2) {
                camera.center = {};
                camera.center.latitude = _item.item.coordinates[0];
                camera.center.longitude = _item.item.coordinates[1];
            }
            Console.devLog(`${NAME}.onCardPress`, { _item, _value, camera });
            oahuDispatchRef.current({ type: OAHU_TYPES.SET_CAMERA, payload: camera });
            appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER });
        },
        [
            appDispatchRef,
            oahuDispatchRef,
        ],
    );

    const onDirections = useCallback(
        directions => {
            Console.devLog(`${NAME}.onDirections`, { directions/*: JSON.stringify(directions, null, ' ')*/ });
            return directions;
        },
        [
        ],
    );

    const setListData = useCallback(
        data => {
            Console.devLog(`${NAME}.setListData`, { data: data?.length });
            setCardsRef.current(data);
            setCardsUpdateRef(v => v + 1);
        },
        [
            setCardsRef,
            setCardsUpdateRef,
        ],
    );

    const controls = useMemo(
        () => {
            Console.devLog(`${NAME}.controls`, { enabledUpdate, weatherUpdate, weather: weather?.lookup?.size, locationsUpdate, locations: locations ? Object.keys(locations) : null/*, surfUpdate, surfLength: surf?.length, geocodeTimestamp*/ });

            const renderIcon = (type, active = null) => {
                const isActive = Validate.isValid(active)
                    ? active
                    : locations[type]?.length ? true : false;
                const icon = MapIcons.get(type);
                const isEnabled = enabled.has(type);
                Console.log(`${NAME}.controls.renderIcon`, { type, isEnabled, active, isActive/*, icon*/ });
                const MARKMARK_ALWAYS_ON = true;
                return (
                    <Icon
                        key={type}
                        type={MATERIAL_COMMUNITY}
                        name={!isEnabled && icon?.iconOff ? icon.iconOff.name(dark) : icon.icon.name(dark)}
                        color={MARKMARK_ALWAYS_ON ? isEnabled ? Colors.colors.white : icon.color : !isActive ? DISABLED_ICON_COLOR : isEnabled ? Colors.colors.white : icon.color}
                        iconStyle={styles.iconStyle}
                        onPress={isActive
                            ? () => {
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_TOGGLE,
                                    payload: type,
                                });
                            }
                            : () => { }
                        }
                    />
                );
            };

            return [
                [
                    renderIcon(MapKeys.AIRPORTS),
                    renderIcon(MapKeys.MARINAS),
                    renderIcon(MapKeys.EVENTS),
                    renderIcon(MapKeys.MUSIC),
                    renderIcon(MapKeys.FAVORITES),
                ],
                [
                    renderIcon(MapKeys.ATTRACTIONS),
                    renderIcon(MapKeys.BEACHES),
                    renderIcon(MapKeys.HIKES),
                    renderIcon(MapKeys.GOLF),
                    renderIcon(MapKeys.SURF),
                ],
                [
                    renderIcon(MapKeys.DEPTSTORES),
                    renderIcon(MapKeys.FARMERS_MARKETS),
                    renderIcon(MapKeys.RESTAURANTS),
                    renderIcon(MapKeys.BARS),
                    renderIcon(MapKeys.HOTELS),
                ],
                [
                    renderIcon(MapKeys.WEATHER, weather?.lookup?.size ? true : false),
                ],
            ];
        },
        [
            weather,
            weatherUpdate,
            locations,
            locationsUpdate,
            //surf,
            //surfUpdate,
            dark,
            enabled,
            enabledUpdate,
            oahuDispatchRef,
        ],
    );

    const renderContent = useCallback(
        (
            _busesUpdate,
            _satellitesUpdate,
            _locationsUpdate,
            _surfUpdate,
            _weatherUpdate,
        ) => {

            var locationData = [];
            Object.keys(locations) // MARKMARKGRID
                .filter(key => enabled.has(key))
                .forEach(key => {
                    locationData = [
                        ...locationData,
                        ...locations[key],
                    ];
                });

            const mapStyle = {
                width,
                height: showAds
                    ? adjHeight - BANNER_AD_HEIGHT
                    : adjHeight,
            };

            const showSatellites = enabled.has(MapKeys.SATELLITES);
            const showSurf = enabled.has(MapKeys.SURF);
            const showWeather = enabled.has(MapKeys.WEATHER);

            Console.devLog(`${NAME}.renderContent`, {
                enabledUpdate,
                showAds,
                showSatellites,
                showSurf,
                showWeather,
                weather: weather?.lookup?.size,
                buses: buses?.size,
                locationData: locationData?.length ? locationData[0] : null,
                adjHeight,
                mapStyle,
                _busesUpdate,
                _satellitesUpdate,
                _locationsUpdate,
                _surfUpdate,
                _weatherUpdate,
                holo,
            });

            return (
                <View
                    style={mapStyle}
                >
                    <DynamicMap
                        mapStyle={mapStyle}
                        locations={locationData}
                        locationsUpdate={locationData?.length ? _locationsUpdate : -_locationsUpdate}
                        bikis={bikis}
                        bikisUpdate={bikis?.length}
                        buses={buses}
                        busesUpdate={_busesUpdate}
                        weather={showWeather ? weather : null}
                        weatherUpdate={showWeather ? _weatherUpdate : -_weatherUpdate}
                        surf={showSurf ? surf : []}
                        surfUpdate={showSurf ? _surfUpdate : -_surfUpdate}
                        satellites={showSatellites ? satellites : []}
                        satellitesUpdate={showSatellites ? _satellitesUpdate : -_satellitesUpdate}
                        onCalloutPress={onCalloutPress}
                        onDirections={onDirections}
                        onMarkerPress={onMarkerPress}
                        mapViews={MapViews}
                        controls={controls}
                        holo={holo}
                        sunInfo={sunInfo}
                        setListData={setListData}
                        setEnabledTypes={setEnabledTypes}
                        surfSource={{
                            baseUrl: SurfBaseUrl,
                            html: SurfFeedHtml,
                        }}
                        surfDimensions={{
                            width: SURF_FEED_WIDTH,
                            height: SURF_FEED_HEIGHT,
                        }}
                    />
                </View>
            );
        },
        [
            showAds,
            adjHeight,
            width,
            controls,
            onCalloutPress,
            onDirections,
            onMarkerPress,
            //dark,
            bikis,
            buses,
            satellites,
            locations,
            surf,
            weather,
            holo,
            sunInfo,
            enabled,
            enabledUpdate,
            setListData,
            setEnabledTypes,
        ],
    );

    Console.stack(NAME, props, { locations, dark, drawer, showDrawer, showAds, cards: cards?.length, cardsUpdate });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { cards: cards?.length, cardsUpdate, busesUpdate, satellitesUpdate, locationsUpdate, surfUpdate, weatherUpdate, showDrawer });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    renderHelp={(
                        <View
                            value={'MapViewHelp'}
                            style={styles.helpView}
                        >
                            {
                                [
                                    { key: 'compass', onIcon: 'MapCompassOn', onColor: 'red', offIcon: 'MapCompassOff', offColor: 'darkred' },
                                    { key: 'location', onIcon: 'MapPositionGPS', onColor: 'orange', offIcon: 'MapPosition', offColor: 'darkorange' },
                                    { key: 'navigation', onIcon: 'MapDirectionsOn', onColor: 'yellow', offIcon: 'MapDirectionsOff', offColor: 'lightyellow' },
                                    { key: 'satellite', onIcon: 'MapStandardView', onColor: 'lime', offIcon: 'MapSatelliteView', offColor: 'green' },
                                    { key: 'view', onIcon: 'MapIsland', onColor: 'lightblue' },
                                    { key: 'weather', onIcon: 'MapWeatherOn', onColor: 'white', offIcon: 'MapWeatherOff', offColor: 'gray' },
                                ].map(v => (
                                    <View
                                        key={v.key}
                                        value={v.key}
                                        style={styles.helpRow}
                                    >
                                        {
                                            Optional(Validate.isValid(v?.offIcon) && Validate.isValid(v?.offColor), (
                                                <Icon
                                                    type={MATERIAL_COMMUNITY}
                                                    name={Icons[v?.offIcon]?.name(dark)}
                                                    color={Colors.colors[v?.offColor]}
                                                    iconStyle={styles.iconStyle}
                                                />
                                            ))
                                        }
                                        {
                                            Optional(Validate.isValid(v?.onIcon) && Validate.isValid(v?.onColor), (
                                                <Icon
                                                    type={MATERIAL_COMMUNITY}
                                                    name={Icons[v?.onIcon]?.name(dark)}
                                                    color={Colors.colors[v?.onColor]}
                                                    iconStyle={styles.iconStyle}
                                                />
                                            ))
                                        }
                                        <Text value={v.key} />
                                    </View>
                                ))
                            }
                            {
                                [
                                    [
                                        { key: MapKeys.AIRPORTS, onIcon: 'MapAirports', onColor: 'red' },
                                        { key: MapKeys.MARINAS, onIcon: 'MapMarinas', onColor: 'orange' },
                                        { key: MapKeys.EVENTS, onIcon: 'MapEvents', onColor: 'yellow' },
                                        { key: MapKeys.MUSIC, onIcon: 'MapMusic', onColor: 'lime' },
                                        { key: MapKeys.FAVORITES, onIcon: 'MapFavorites', onColor: 'lightblue' },
                                    ],
                                    [
                                        { key: MapKeys.ATTRACTIONS, onIcon: 'MapAttractions', onColor: 'red' },
                                        { key: MapKeys.BEACHES, onIcon: 'MapBeaches', onColor: 'orange' },
                                        { key: MapKeys.HIKES, onIcon: 'MapHikes', onColor: 'yellow' },
                                        { key: MapKeys.GOLF, onIcon: 'MapGolf', onColor: 'lime' },
                                        { key: MapKeys.SURF, onIcon: 'MapSurf', onColor: 'lightblue' },
                                    ],
                                    [
                                        { key: MapKeys.DEPTSTORES, onIcon: 'MapShopping', onColor: 'red' },
                                        { key: MapKeys.FARMERS_MARKETS, onIcon: 'MapFarms', onColor: 'orange' },
                                        { key: MapKeys.RESTAURANTS, onIcon: 'MapRestaurants', onColor: 'yellow' },
                                        { key: MapKeys.BARS, onIcon: 'MapBars', onColor: 'lime' },
                                        { key: MapKeys.HOTELS, onIcon: 'MapLodging', onColor: 'lightblue' },
                                    ],
                                ].map((v, vidx) => (
                                    <View
                                        key={`v${vidx}`}
                                        value={`v${vidx}`}
                                        style={styles.helpRow}
                                    >
                                        {
                                            v.map((w, widx) => (
                                                Optional(Validate.isValid(w?.onIcon) && Validate.isValid(w?.onColor), (
                                                    <Icon
                                                        key={`w${widx}`}
                                                        type={MATERIAL_COMMUNITY}
                                                        name={Icons[w?.onIcon]?.name(dark)}
                                                        color={Colors.colors[w?.onColor]}
                                                        iconStyle={styles.iconStyle}
                                                    />
                                                ))
                                            ))
                                        }
                                    </View>
                                ))
                            }
                        </View>
                    )}
                >
                    <Drawer
                        show={showDrawer}
                        toggleShow={() => showDrawer ? appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER }) : null}
                        percentage={85}
                        content={renderList(cards)}
                    >
                        {renderContent(busesUpdate, satellitesUpdate, locationsUpdate, surfUpdate, weatherUpdate)}
                    </Drawer>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            dark,
            showAds,
            showDrawer,
            cards,
            cardsUpdate,
            busesUpdate,
            satellitesUpdate,
            locationsUpdate,
            surfUpdate,
            weatherUpdate,
            renderContent,
            renderList,
            appDispatchRef,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    info: {
        height: '85%',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    black: {
        color: Colors.colors.black,
    },
    helpView: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    helpRow: {
        width: '90%',
        flexDirection: 'row',
    },
    iconStyle: {
        padding: 5,
        fontSize: 38,
    },
});
