import { WILDCARD } from './DictionaryConstants';
import { Loader } from './utils';
import { Console } from '../utils';

const NAME = 'Dictionary';


export class Dictionary {

    constructor(cb) {
        this.cb = cb;
        this.words = new Map();
        this.senses = new Map();
        Loader(this.onRead, this.onLoad);
    }

    onRead = percent => {
        this.cb(percent);
    };

    onLoad = result => {
        this.words = result.words;
        this.senses = result.senses;
        this.onRead(1);
    };

    wordCount = (lang = null) => {
        if (lang) {
            const langWords = this.words.get(lang);
            if (!langWords) {
                Console.warn(`${NAME}.wordCount bad lang`, { lang });
            }
            return langWords ? langWords.size : 0;
        }
        var count = 0;
        this.words.forEach((words, _) => {
            count += words.size;
        });
        return count;
    };

    senseCount = () => {
        return this.senses.size;
    };

    getWord = (lemma, lang = 'en') => {
        const langWords = this.words.get(lang);
        if (!langWords) {
            Console.warn(`${NAME}.getWord bad lang`, { lang });
        }
        return langWords ? langWords.get(lemma) : null;
    };

    getSense = senseKey => {
        return this.senses.get(senseKey);
    };

    wordList = (fragment, lang = 'en') => {
        var result = new Map();
        if (fragment.length) {
            const cleanFragment = fragment.replace(/ /g, '_');
            const langWords = this.words.get(lang);
            if (!langWords) {
                Console.warn(`${NAME}.wordList bad lang`, { lang });
            } else {
                langWords.forEach((data, lemma) => {
                    if (fragment === WILDCARD || lemma.startsWith(cleanFragment)) {
                        result.set(lemma, data);
                    }
                });
            }
        }
        return result;
    };
}
