// https://oblador.github.io/react-native-vector-icons/

const platformIcons = [
  ['Android'],
  ['Apple'],
  ['Web'],
  ['Unknown', 'help'],
];

const socialMediaIcons = [
  ['Android'],
  ['Apple'],
  ['AWS'],
  ['Facebook'],
  ['GitHub'],
  ['Google'],
  ['Instagram'],
  ['LinkedIn'],
  ['Twitter'],
  ['YouTube'],
  ['QRCode'],
  ['Email'],
];

const generalIcons = [
  ['Enter', 'exit-to-app'],
  ['Login'],
  ['Logout'],
  ['Microphone'],
  ['VolumeMute', 'volume-mute'],
  ['VolumeOff', 'volume-off'],
  ['VolumeOn', 'volume-high'],
  ['VolumeLow', 'volume-low'],
  ['Send', 'send'],
  ['Delete', 'delete-forever', 'delete-forever-outline'],
  ['ScrollToBottom', 'chevron-double-down'],
  ['Male', 'human-male'],
  ['Female', 'human-female'],
  ['Dimensions2D', 'video-3d-off'],
  ['Dimensions3D', 'video-3d'],
  ['Detail', 'card-bulleted', 'card-bulleted-outline'],
  ['Graph', 'graph', 'graph-outlined'],
  ['HandDown', 'hand-pointing-down'],
  ['HandUp', 'hand-pointing-up'],
  ['HandLeft', 'hand-pointing-left'],
  ['HandRight', 'hand-pointing-right'],
  ['Text', 'format-font'],
  ['Colors', 'palette', 'palette-outline'],
  ['Menu', 'menu'],
];

const headerIcons = [
  ['Back', 'arrow-left'],
  ['BackIOS', 'chevron-left'],
  ['FrontCamera', 'camera-front-variant'],
  ['BackCamera', 'camera-rear-variant'],
  ['Phone', 'phone', 'phone-outline'],
  ['AutoSendOn', 'text-to-speech'],
  ['AutoSendOff', 'text-to-speech-off'],
  ['Image', 'image-edit', 'image-edit-outline'],
  ['Theme', 'white-balance-sunny', 'moon-waning-crescent'],
  ['Translate', 'translate'],
  ['Help', 'help-circle', 'help-circle-outline'],
];

const controlIcons = [
  ['Play', 'play-circle', 'play-circle-outline'],
  ['Pause', 'pause-circle', 'pause-circle-outline'],
  ['SkipPrevious', 'skip-previous-circle', 'skip-previous-circle-outline'],
  ['SkipNext', 'skip-next-circle', 'skip-next-circle-outline'],
];

const applicationIcons = [
  ['Awards', 'trophy', 'trophy-outline'],
  ['Auth', 'logout'],
  ['Bots', 'account-multiple', 'account-multiple-outline'],
  ['Camera', 'camera-enhance', 'camera-enhance-outline'],
  ['Charts', 'equalizer', 'equalizer-outline'],
  ['Education', 'school', 'school-outline'],
  ['Games', 'puzzle', 'puzzle-outline'],
  ['Graph', 'graph', 'graph-outline'],
  ['Hand', 'hand-front-right', 'hand-front-right-outline'],
  ['Home', 'home', 'home-outline'],
  ['Info', 'information', 'information-outline'],
  ['Keyboard', 'keyboard', 'keyboard-outline'],
  ['KeyboardOff', 'keyboard-off', 'keyboard-off-outline'],
  ['Schedule', 'calendar-month', 'calendar-month-outline'],
  ['Search', 'card-search', 'card-search-outline'],
  ['Settings', 'cog', 'cog-outline'],
  ['Speech', 'account-tie-voice', 'account-tie-voice-outline'],
  ['School', 'school', 'school-outline'],
  ['Tools', 'wrench', 'wrench-outline'],
  ['User', 'account', 'account-outline'],
  ['Users', 'chat', 'chat-outline'],
  ['Video', 'video', 'video-outline'],
  ['Grid', 'grid'],
  ['Sound', 'sine-wave'],
];

const oahuIcons = [
  ['Oahu', 'island'],
  ['MapView', 'island'],
  ['Coupons', 'cash'],
  ['Calendar', 'calendar', 'calendar-outline'],
];

// https://pictogrammers.com/library/mdi/
const oahuMapControlIcons = [
  ['MapCamera', 'camera', 'camera-outline'],
  ['MapClusterOn', 'group'],
  ['MapClusterOff', 'ungroup'],
  ['MapCompassOn', 'compass', 'compass-outline'],
  ['MapCompassOff', 'compass-off', 'compass-off-outline'],
  ['MapDirectionsOn', 'sign-direction'],
  ['MapDirectionsOff', 'sign-direction-remove'],
  ['MapGallery', 'view-gallery', 'view-gallery-outline'],
  ['MapMarker', 'map-marker', 'map-marker-outline'],
  ['MapMenuDown', 'menu-down', 'menu-down-outline'],
  ['MapMenuLeft', 'menu-left', 'menu-left-outline'],
  ['MapMenuRight', 'menu-right', 'menu-right-outline'],
  ['MapMenuUp', 'menu-up', 'menu-up-outline'],
  ['MapOverlayOn', 'map-plus'],
  ['MapOverlayOff', 'map-minus'],
  ['MapNavigationWalk', 'walk'],
  ['MapNavigationBike', 'bicycle'],
  ['MapNavigationBus', 'bus'],
  ['MapNavigationCar', 'car-side'],
  ['MapPositionMan', 'human-greeting'],
  ['MapPosition', 'crosshairs'],
  ['MapPositionGPS', 'crosshairs-gps'],
  ['MapSatelliteView', 'earth'],
  ['MapStandardView', 'web'/*'earth-off'*/],
  ['MapViewAll', 'magnify-expand'],
  ['MapWeatherOn', 'cloud'],
  ['MapWeatherOff', 'cloud-outline'],
];

const oahuMapIcons = [
  ['MapAirports', 'airplane'],
  ['MapAttractions', 'binoculars'],
  ['MapBars', 'glass-cocktail'],
  ['MapBeaches', 'umbrella-beach', 'umbrella-beach-outline'],
  ['MapBiki', 'bicycle'],
  ['MapBuses', 'bus'],
  ['MapBusStops', 'bus-stop'],
  ['MapCamping', 'tent'],
  ['MapCash', 'cash'],
  ['MapChurches', 'church'],
  ['MapCoffee', 'coffee', 'coffee-outline'],
  ['MapDinosaurs', 'google-downasaur'],
  ['MapEvents', 'clock', 'clock-outline'],
  ['MapFarms', 'tractor-variant'/*'home-silo', 'home-silo-outline'*/],
  ['MapFavorites', 'star', 'star-outline'],
  ['MapGolf', 'golf'],
  ['MapGroceries', 'cart', 'cart-outline'],
  ['MapHappyHours', 'glass-mug-variant'],
  ['MapHelicopters', 'helicopter'],
  ['MapHikes', 'hiking'],
  ['MapHorses', 'horse-human'],
  ['MapIsland', 'island'],
  ['MapJellyfish', 'jellyfish'],
  ['MapLighthouses', 'lighthouse'],
  ['MapLodging', 'bed'],
  ['MapMarinas', 'sail-boat'],
  ['MapMoonNew', 'moon-new'],
  ['MapMoonWaxingCrescent', 'moon-waxing-crescent'],
  ['MapMoonFirstQuarter', 'moon-first-quarter'],
  ['MapMoonWaxingGibbous', 'moon-waxing-gibbous'],
  ['MapMoonFull', 'moon-full'],
  ['MapMoonWaningGibbous', 'moon-waning-gibbous'],
  ['MapMoonLastQuarter', 'moon-last-quarter'],
  ['MapMoonWaningCrescent', 'moon-waning-crescent'],
  ['MapMusic', 'music-note'],
  ['MapParachutes', 'parachute', 'parachute-outline'],
  ['MapParking', 'parking'],
  ['MapRestaurants', 'silverware-fork-knife'], // MARKMARK: more options with food icons, also food specializations
  ['MapSatellites', 'satellite-variant'],
  ['MapScuba', 'diving-scuba-flag'],
  ['MapShopping', 'shopping', 'shopping-outline'],
  ['MapSnorkling', 'diving-snorkel'],
  ['MapSpaceStation', 'space-station'],
  ['MapSubmarines', 'submarine'],
  ['MapSunrise', 'weather-sunset-up'],
  ['MapSunset', 'weather-sunset-down'],
  ['MapSurf', 'surfing'],
  ['MapSwim', 'swim'],
  ['MapTelescopes', 'telescope'],
  ['MapTrees', 'tree', 'tree-outline'],
  ['MapToilets', 'human-male-female'],
  ['MapTurtles', 'turtle'],
  ['MapTourBuses', 'bus-double-decker'],
  ['MapScenicViews', 'camera'],
  ['MapWaterfalls', 'waterfall'],
  ['MapZoos', 'paw', 'paw-outline'],
];

/*
        ['favorites', { name: 'star', color: IconColors[6] }],
        ['events', { name: 'clock', color: IconColors[6] }],

        ['turtle', { name: 'waterfall', color: IconColors[0] }],
        ['horse', { name: 'horse-human', color: IconColors[0] }],
        ['dinosaur', { name: 'google-downasaur', color: IconColors[0] }],
        ['tour', { name: 'bus-double-decker', color: IconColors[0] }],
        ['helicopter', { name: 'helicopter', color: IconColors[0] }],
        ['swim', { name: 'swim', color: IconColors[0] }],

        // https://materialdesignicons.com/
        // bus, bus-stop, umbrella-beach(-outline), telescope, swim, food-fork-drink, qrcode, tower-beach
        // store, pig-variant, diving-scuba-flag, bed, coffee, palm-tree, pine-tree, plane-car, pistol,
        // fish(-off), jellyfish, satellite-variant, weather-sunset-up, weather-sunset-down, phone-classic,
        // penguin, koala, kitesurfing, parachute, paragliding, noodles, pizza, lighthouse, kayaking, ice-cream
        // hook (fishing), hamburger, glass-wine, flower, cigar, cannabis
*/

const iconName = icon => {
  const title = icon[0];
  const key = title.replace(/-/g, '_');
  const fallback = title.toLowerCase();
  const solid = icon.length > 1 ? icon[1] : fallback;
  const outline = icon.length === 3 ? icon[2] : icon.length === 2 ? icon[1] : fallback;
  return { key, title, solid, outline };
};

var icons = {};
[
  ...platformIcons,
  ...socialMediaIcons,
  ...generalIcons,
  ...headerIcons,
  ...controlIcons,
  ...applicationIcons,
  ...oahuIcons,
  ...oahuMapControlIcons,
  ...oahuMapIcons,
].forEach(icon => {
  const { key, title, solid, outline } = iconName(icon);
  const name = (dark = false) => dark ? solid : outline;
  icons[key] = { key, title, name };
});

export const Icons = icons;

export const Icon2Svg = new Map([
  ['camera', ['M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z']],
  ['camera-outline', 'M20,4H16.83L15,2H9L7.17,4H4A2,2 0 0,0 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6A2,2 0 0,0 20,4M20,18H4V6H8.05L9.88,4H14.12L15.95,6H20V18M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15Z'],
  ['group', 'M1,1V5H2V19H1V23H5V22H19V23H23V19H22V5H23V1H19V2H5V1M5,4H19V5H20V19H19V20H5V19H4V5H5M6,6V14H9V18H18V9H14V6M8,8H12V12H8M14,11H16V16H11V14H14'],
  ['ungroup', 'M2,2H6V3H13V2H17V6H16V9H18V8H22V12H21V18H22V22H18V21H12V22H8V18H9V16H6V17H2V13H3V6H2V2M18,12V11H16V13H17V17H13V16H11V18H12V19H18V18H19V12H18M13,6V5H6V6H5V13H6V14H9V12H8V8H12V9H14V6H13M12,12H11V14H13V13H14V11H12V12Z'],
  ['compass', ''],
  ['compass-outline', ''],
  ['compass-off', ''],
  ['compass-off-outline', ''],
  ['sign-direction', ''],
  ['sign-direction-remove', ''],
  ['view-gallery', ''],
  ['view-gallery-outline', ''],
  ['map-marker', ''],
  ['map-marker-outline', ''],
  ['menu-down', ''],
  ['menu-left', ''],
  ['menu-right', ''],
  ['menu-up', ''],
  ['map-plus', ''],
  ['map-minus', ''],
  ['walk', ''],
  ['bicycle', ''],
  ['bus', 'M18,11H6V6H18M16.5,17A1.5,1.5 0 0,1 15,15.5A1.5,1.5 0 0,1 16.5,14A1.5,1.5 0 0,1 18,15.5A1.5,1.5 0 0,1 16.5,17M7.5,17A1.5,1.5 0 0,1 6,15.5A1.5,1.5 0 0,1 7.5,14A1.5,1.5 0 0,1 9,15.5A1.5,1.5 0 0,1 7.5,17M4,16C4,16.88 4.39,17.67 5,18.22V20A1,1 0 0,0 6,21H7A1,1 0 0,0 8,20V19H16V20A1,1 0 0,0 17,21H18A1,1 0 0,0 19,20V18.22C19.61,17.67 20,16.88 20,16V6C20,2.5 16.42,2 12,2C7.58,2 4,2.5 4,6V16Z'],
  ['car-side', ''],
  ['human-greeting', 'M12 2C13.1 2 14 2.9 14 4S13.1 6 12 6 10 5.1 10 4 10.9 2 12 2M15.9 8.1C15.5 7.7 14.8 7 13.5 7H11C8.2 7 6 4.8 6 2H4C4 5.2 6.1 7.8 9 8.7V22H11V16H13V22H15V10.1L19 14L20.4 12.6L15.9 8.1Z'],
  ['crosshairs', ''],
  ['crosshairs-gps', ''],
  ['earth', ''],
  ['web', ''],
  ['magnify-expand', ''],
  ['cloud', ''],
  ['cloud-outline', ''],
  ['cloud-off', ''],
  ['cloud-off-outline', ''],
  ['airplane', 'M20.56 3.91C21.15 4.5 21.15 5.45 20.56 6.03L16.67 9.92L18.79 19.11L17.38 20.53L13.5 13.1L9.6 17L9.96 19.47L8.89 20.53L7.13 17.35L3.94 15.58L5 14.5L7.5 14.87L11.37 11L3.94 7.09L5.36 5.68L14.55 7.8L18.44 3.91C19 3.33 20 3.33 20.56 3.91Z'],
  ['binoculars', 'M11,6H13V13H11V6M9,20A1,1 0 0,1 8,21H5A1,1 0 0,1 4,20V15L6,6H10V13A1,1 0 0,1 9,14V20M10,5H7V3H10V5M15,20V14A1,1 0 0,1 14,13V6H18L20,15V20A1,1 0 0,1 19,21H16A1,1 0 0,1 15,20M14,5V3H17V5H14Z'],
  ['glass-cocktail', ''],
  ['umbrella-beach', 'M13.13 14.56L14.56 13.13L21 19.57L19.57 21L13.13 14.56M17.42 8.83L20.28 5.97C16.33 2 9.93 2 6 5.95C9.91 4.65 14.29 5.7 17.42 8.83M5.95 6C2 9.93 2 16.33 5.97 20.28L8.83 17.42C5.7 14.29 4.65 9.91 5.95 6M5.97 5.96L5.96 5.97C5.58 9 7.13 12.85 10.26 16L16 10.26C12.86 7.13 9 5.58 5.97 5.96Z'],
  ['umbrella-beach-outline', 'M21 19.57L19.57 21L13.13 14.56L14.56 13.13L21 19.57M13.12 3C10.54 3 7.96 4 6 5.95L5.97 5.96C2 9.91 2 16.32 5.97 20.27L20.27 5.96C18.3 4 15.71 3 13.12 3M6.14 17.27C5.4 16.03 5 14.61 5 13.12C5 12.19 5.16 11.3 5.46 10.45C5.65 12.36 6.35 14.24 7.53 15.89L6.14 17.27M9 14.43C7.63 12.38 7.12 9.93 7.6 7.6C8.18 7.5 8.76 7.42 9.35 7.42C11.15 7.42 12.9 7.97 14.43 9L9 14.43M10.45 5.46C11.3 5.16 12.19 5 13.12 5C14.61 5 16.03 5.4 17.27 6.14L15.88 7.53C14.23 6.35 12.36 5.65 10.45 5.46Z'],
  ['bicycle', ''],
  ['bus-stop', ''],
  ['tent', ''],
  ['cash', ''],
  ['church', ''],
  ['church-outline', ''],
  ['coffee', ''],
  ['coffee-outline', ''],
  ['google-downasaur', ''],
  ['clock', ''],
  ['clock-outline', ''],
  ['home-silo', ''],
  ['home-silo-outline', ''],
  ['star', ''],
  ['star-outline', ''],
  ['golf', 'M19.5,18A1.5,1.5 0 0,1 21,19.5A1.5,1.5 0 0,1 19.5,21A1.5,1.5 0 0,1 18,19.5A1.5,1.5 0 0,1 19.5,18M17,5.92L11,9V18.03C13.84,18.19 16,19 16,20C16,21.1 13.31,22 10,22C6.69,22 4,21.1 4,20C4,19.26 5.21,18.62 7,18.27V20H9V2L17,5.92Z'],
  ['cart', ''],
  ['cart-outline', ''],
  ['glass-mug-variant', ''],
  ['helicopter', ''],
  ['hiking', 'M17.47 8.67H19V23H17.47V12.6C16.67 12.44 15.92 12.14 15.21 11.71S13.9 10.78 13.39 10.2L12.77 13.27L15 15.47V23H13V17L10.76 14.8L8.89 23H6.73C6.73 23 9.86 7.22 9.89 7.09C10 6.61 10.22 6.24 10.59 6C10.96 5.73 11.33 5.6 11.71 5.6C12.1 5.6 12.46 5.69 12.79 5.87C13.13 6.04 13.39 6.29 13.58 6.61L14.64 8.24C14.93 8.78 15.32 9.25 15.81 9.63S16.86 10.3 17.47 10.5V8.67M8.55 5.89L7.4 5.65C6.83 5.5 6.31 5.62 5.84 5.94C5.38 6.26 5.1 6.7 5 7.28L4.19 11.26C4.16 11.55 4.22 11.81 4.38 12.05C4.54 12.29 4.75 12.42 5 12.46L7.21 12.89L8.55 5.89M13 1C11.9 1 11 1.9 11 3S11.9 5 13 5 15 4.11 15 3 14.11 1 13 1Z'],
  ['horse-human', ''],
  ['jellyfish', ''],
  ['lighthouse', ''],
  ['bed', ''],
  ['sail-boat', ''],
  ['moon-new', ''],
  ['moon-waxing-crescent', ''],
  ['moon-first-quarter', ''],
  ['moon-waxing-gibbous', ''],
  ['moon-full', ''],
  ['moon-waning-gibbous', ''],
  ['moon-last-quarter', ''],
  ['moon-waning-crescent', ''],
  ['music-note', ''],
  ['parachute', ''],
  ['parachute-outline', ''],
  ['parking', ''],
  ['silverware-fork-knife', ['M11,9H9V2H7V9H5V2H3V9C3,11.12 4.66,12.84 6.75,12.97V22H9.25V12.97C11.34,12.84 13,11.12 13,9V2H11V9M16,6V14H18.5V22H21V2C18.24,2 16,4.24 16,6Z']],
  ['satellite-variant', ''],
  ['diving-scuba-flag', ''],
  ['shopping', ''],
  ['shopping-outline', ''],
  ['diving-snorkel', ''],
  ['submarine', ''],
  ['weather-sunset-up', ''],
  ['weather-sunset-down', ''],
  ['surf', ''],
  ['swim', ''],
  ['telescope', ''],
  ['tree', ''],
  ['tree-outline', ''],
  ['human-male-female', ''],
  ['turtle', ['M8.47,5.95C8.95,5.67 9.47,5.44 10,5.28V4C10,2.9 10.87,2 11.97,1.97C13.13,2 14,2.9 14,4V5.28C14.53,5.45 15.05,5.67 15.53,5.95L13.93,8.07H10.07L8.47,5.95M19,12C19,12.5 18.95,12.95 18.86,13.4L16.33,12.62L15.14,8.96L16.74,6.85C17.17,7.25 17.55,7.7 17.88,8.2C18.67,8.13 19.43,8.25 20.11,8.59C21.14,9.12 21.84,10.13 22,11.28L19,11.64C19,11.76 19,11.88 19,12M5,12C5,11.88 5,11.76 5,11.65L2,11.28C2.16,10.13 2.86,9.12 3.89,8.59C4.57,8.25 5.34,8.13 6.08,8.26C6.41,7.75 6.79,7.28 7.24,6.87L8.86,8.95L7.67,12.62L5.14,13.4C5.05,12.95 5,12.5 5,12M10.24,9.57H13.76L14.85,12.93L12,15L9.15,12.93L10.24,9.57M8.13,14.05L11.25,16.31V18.96C10.68,18.9 10.13,18.77 9.62,18.58L8.39,21.34C7.33,20.87 6.57,19.9 6.37,18.76C6.23,18 6.35,17.24 6.69,16.56C6.24,16.04 5.87,15.46 5.59,14.82L8.13,14.05M15.87,14.05L18.41,14.82C18.13,15.46 17.76,16.04 17.31,16.56C17.65,17.24 17.77,18 17.64,18.76C17.43,19.9 16.67,20.87 15.61,21.34L14.39,18.58C13.86,18.77 13.33,18.94 12.75,19V16.31L15.87,14.05Z']],
  ['bus-double-decker', ''],
  ['waterfall', ''],
  ['paw', ''],
  ['paw-outline', ''],
]);

export const MATERIAL_COMMUNITY = 'material-community';
