import React, { PureComponent } from 'react';
import { StyleSheet } from 'react-native';
import { GameEngine } from './engine/platform';
import { Finger } from './engine/renderers';
import { Touch } from './engine/systems';
import { Console } from '../utils';

const NAME = 'FingersGame';


export class FingersGame extends PureComponent {
    constructor() {
        super();
    }

    render() {

        Console.log(`${NAME} render`);

        return (
            <GameEngine
                style={styles.container}
                systems={[Touch]}
                entities={{
                    0: { x:  40, y: 200, renderer: <Finger /> }, //-- Notice that each entity has a unique id (required)
                    1: { x: 100, y: 200, renderer: <Finger /> }, //-- and a renderer property (optional). If no renderer
                    2: { x: 160, y: 200, renderer: <Finger /> }, //-- is supplied with the entity - it won't get displayed.
                    3: { x: 220, y: 200, renderer: <Finger /> },
                    4: { x: 280, y: 200, renderer: <Finger /> },
                }}
            />
        );
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#FFF',
    },
});
