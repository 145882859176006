import { AudioRecord } from './AudioRecord';
import { Context } from './Context';
import { Console } from '../Console';
import { Validate } from '../Validate';

const NAME = 'Recorder.web';

const INACTIVE = 'inactive';
const PAUSED = 'paused';
const SUSPENDED = 'suspended';

navigator.getUserMedia = (
    navigator.getUserMedia ||
    navigator.webkitGetUserMedia ||
    navigator.mozGetUserMedia ||
    navigator.msGetUserMedia
);

Console.trace('MediaDevices supported constraints', navigator.mediaDevices.getSupportedConstraints());


export class Recorder {

    constructor(options, onStart, onStop, onInfo) {

        Console.log(`${NAME}.constructor`, { options });

        const {
            echoCancellation,
            autoGainControl,
            noiseSuppression,
            channelCount,
            sampleRate,
            // bitsPerSample (16) MARKMARK ???
        } = options;

        const recordOptions = {
            mimeType: 'audio/wav',
            audioBitsPerSecond: sampleRate,
        };
        this.constraints = {
            audio: {
                echoCancellation,
                autoGainControl,
                noiseSuppression,
                channelCount,
                sampleRate,
            },
            video: false,
        };

        this.onInfo = onInfo;

        this.audioRecord = new AudioRecord(recordOptions, onStart, onStop, onInfo);
    }

    start = async () => {

        const audioContext = Context.Context();
        const mediaRecorder = this.audioRecord.recorder();
        const mediaStream = this.audioRecord.stream();

        Console.log(`${NAME}.start`, { audioContext, mediaRecorder, mediaStream });

        if (Validate.isValid(audioContext) && Validate.isValid(mediaRecorder)) {

            Console.log(`${NAME}.start audio recording audio=[${audioContext.state}] media=[${mediaRecorder.state}]`, { audioContext, mediaRecorder });

            if (audioContext.state === SUSPENDED) {
                audioContext.resume();
            }

            if (mediaRecorder.state === PAUSED) {
                mediaRecorder.resume();
                return;
            }

            if (audioContext && mediaStream && mediaRecorder.state === INACTIVE) {
                mediaRecorder.start();
                audioContext.createMediaStreamSource(mediaStream).connect(Context.Analyzer());
            }

        } else if (navigator.mediaDevices) {

            Console.log(`${NAME}.start audio recording supported`);

            var devices = await navigator.mediaDevices.enumerateDevices();
            if (Validate.isValid(this.onInfo) && Validate.isValid(devices?.length)) {
                this.onInfo(devices.map(item => `${item.kind}: ${item.deviceId.substring(0, 16)}`));
            }
            devices = devices.filter((d) => d.kind === 'audioinput');

            const stream = await navigator.mediaDevices.getUserMedia({ ...this.constraints, deviceId: devices[0].deviceId });
            Console.log(`${NAME}.start navigator.mediaDevices.getUserMedia`, { devices, stream });

            this.audioRecord.create(stream);

            Context.Context()
                .resume()
                .then(() => {
                    const _mediaRecorder = this.audioRecord.recorder();
                    const _mediaStream = this.audioRecord.stream();
                    Console.log(`${NAME}.start audioContext.resumed`, { _mediaRecorder, _mediaStream });
                    if (Validate.isValid(_mediaRecorder) && Validate.isValid(_mediaStream)) {
                        _mediaRecorder.start();
                        Context.Context()
                            .createMediaStreamSource(_mediaStream)
                            .connect(Context.Analyzer());
                    }
                });
        } else {
            Console.log(`${NAME}.start audio recording not supported`);
        }
    };

    stop = () => {

        const mediaRecorder = this.audioRecord.recorder();
        const mediaStream = this.audioRecord.stream();

        Console.log(`${NAME}.stop [${mediaRecorder?.state}]`, { mediaRecorder, mediaStream });

        if (Validate.isValid(mediaStream) &&
            Validate.isValid(mediaRecorder?.state) &&
            mediaRecorder.state !== INACTIVE)
        {

            mediaRecorder.stop();
            mediaStream.getAudioTracks().forEach(track => {
                Console.log(`${NAME}.stop stopping track`, { track });
                track.stop();
            });

            this.audioRecord.reset();
            Context.Reset();

        } else {
            Console.log(`${NAME}.stop null or inactive media recorder`);
        }
    };
}
