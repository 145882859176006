import React, { useState } from 'react';
import { IconFont } from '../../media';
import { Console } from '../../utils';

const NAME = 'TrueTypeStyle';


export const TrueTypeStyle = props => {

    const [iconFont] = useState(IconFont.Get());

    Console.stack(NAME, props, { iconFont });

    return (
        <style type={'text/css'} >
            { `@font-face { font-family: 'MaterialCommunityIcons'; src: url(${iconFont}); format('truetype'); }` }
        </style>
    );
};
