import Svg, {
    Circle as RNCircle,
    Image as RNImage,
    Path as RNPath,
    Rect as RNRect,
    Text as RNText,
} from 'react-native-svg';

export { G } from './G';

export default Svg;

export const Circle = RNCircle;
export const Image = RNImage;
export const Path = RNPath;
export const Rect = RNRect;
export const Text = RNText;
