import axios from 'axios';
import { Console, Numbers } from '../utils';

// MARKMARK: Move to constants
// v2 https://services.surfline.com/kbyg/spots/forecasts/wave?days=1&intervalHours=3&maxHeights=false&sds=true&spotId=590927576a2e4300134fbed8
const SERVER_PRE = 'https://services.surfline.com/kbyg/spots/forecasts/';
const SERVER_POST = '?days=1&intervalHours=3&maxHeights=false&sds=true&spotId=';

// temp: https://services.surfline.com/kbyg/spots/forecasts/weather?days=1&intervalHours=3&maxHeights=false&sds=true&spotId=60428c564d813d3a2bb40d2c
// weatherimage: https://wa.cdn-surfline.com/quiver/0.21.2/weathericons/NIGHT_CLEAR.svg
const MIN_SWELL_HEIGHT = 1.0;

function getTimeIndex(arr) {
  const now = Date.now();
  var i = 0;
  for (; i < arr.length; i++) {
    const ts = +arr[i].timestamp * 1000.0;
    if (ts > now) {
      i--;
      break;
    }
  }
  return (i < 0 || i >= arr.length) ? 0 : i;
}

const SwellNumber = ['1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣'];

function getData(type, id) {

  Console.log(`getOahuSurf.getData ${type} ${id} start`);

  const result = axios.create({
    baseURL: SERVER_PRE + type,
    method: 'get',
    transformResponse: (data) => {

      const obj = JSON.parse(data);
      const info = obj?.data;
      var retval = {};
      if (!info) {
        return retval;
      }

      Console.log(`getOahuSurf.getData.transformResponse ${type} ${id} start`, { info });

      if (info?.wave && info.wave.length) {

        const index = getTimeIndex(info.wave);

        const { surf, swells } = info.wave[index];
        const { raw, humanRelation } = surf;

        const waveMin = Numbers.format(raw?.min, 1);
        const waveMax = Numbers.format(raw?.max, 1);

        var temp = {
          description: `🏄‍♂️ ${humanRelation.toLowerCase()}`,
          wave: `🌊 ${waveMin}-${waveMax} ft`,
        };

        if (swells) {
          var swellIndex = 0;
          for (let s = 0; s < swells.length; s++) {
            const { height, period, direction, directionMin } = swells[s];
            if (height >= MIN_SWELL_HEIGHT) {
              // MARKMARK: format values separated by comma or |, then do this formatting within Oahu.js
              const ft = Numbers.format(height, 1);
              const deg = Numbers.format(Numbers.average(direction, directionMin), 0);
              const compass = Numbers.degreesToCompass(deg);
              temp[`swell${s + 1}`] = `${SwellNumber[swellIndex++]} ${ft} ft ${compass}, ${period} secs`;
            }
          }
        }

        retval = { ...retval, ...temp };
      }

      if (info?.wind && info.wind.length) {

        const index = getTimeIndex(info.wind);
        const { speed, direction, directionType, gust } = info.wind[index];

        const min = Numbers.format(speed, 0);
        const max = Numbers.format(gust, 0);
        const deg = Numbers.format(direction, 0);
        const compass = Numbers.degreesToCompass(deg);
        const _temp = {
          wind: min === max ? `🌬️ ${directionType.toLowerCase()} wind ${min} kts ${compass}` : `🌬️ ${directionType.toLowerCase()} wind ${min}-${max} kts ${compass}`,
        };

        retval = { ...retval, ..._temp };
      }

      if (info?.tides && info.tides.length) {
        const index = getTimeIndex(info.tides);

        const ft = Numbers.format(info.tides[index].height, 1);
        const _temp = {
          tide: `🌙 ${info.tides[index].type.toLowerCase()} tide ${ft} ft`,
        };

        retval = { ...retval, ..._temp };
      }

      if (info?.weather && info.weather.length) {

        const index = getTimeIndex(info.weather);
        const { temperature, condition } = info.weather[index];

        const conditionLC = condition.toLowerCase().replace(/_/g, ' ');
        var emoji = conditionLC.includes('clear')
          ? '🌞 '// MARKMARK NIGHT '🌜 '
          : conditionLC.includes('rain') || conditionLC.includes('shower')
            ? '🌧️ '
            : conditionLC.includes('cloud') || conditionLC.includes('overcast')
              ? '☁️ '
              : '';

        const t = Numbers.format(temperature, 0);

        const _temp = {
          weather: `${emoji}${t}°F ${conditionLC}`,
        };

        retval = { ...retval, ..._temp };
      }

      retval = { id, ...retval };
      Console.log(`getOahuSurf.getData.transformResponse ${type} ${id} end`, { info, temp, retval });
      return retval;
    },
  });

  Console.log(`getOahuSurf.getData ${type} ${id} end`, { result });
  return result;
}

async function getSurf(type, id) {
  var result = null;
  const query = `${SERVER_POST}${id}`;
  try {
    result = await getData(type, id).get(query);
  } catch (error) {
    Console.error(`getOahuSurf.getSurf(${type}, ${id}) [${query}]`, error);
  }
  return result;
}

export async function getOahuSurf(surfIds) {
  Console.log('getOahuSurf start', { surfIds });
  var payload = [];
  try {

    const wave = await axios.all(surfIds.map(id => getSurf('wave', id)));
    const wind = await axios.all(surfIds.map(id => getSurf('wind', id)));
    const tides = await axios.all(surfIds.map(id => getSurf('tides', id)));
    const weather = await axios.all(surfIds.map(id => getSurf('weather', id)));

    if (weather) {
      payload = [...payload, ...weather.map(v => v.data)];
    }
    if (wind) {
      payload = [...payload, ...wind.map(v => v.data)];
    }
    if (wave) {
      payload = [...payload, ...wave.map(v => v.data)];
    }
    if (tides) {
      payload = [...payload, ...tides.map(v => v.data)];
    }
  }
  catch (error) {
    Console.error('getOahuSurf', error);
  }

  Console.log('getOahuSurf end', { payload: payload.length, p0: payload[0] });
  return payload;
}
