import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Screen, View } from '../components';
import Svg, { G } from '../svg';
import { Signs, ASL_VARIANTS, ASL_TYPES } from '../media';
import { useAppState, useSystemState } from '../context';
import { Console } from '../utils';

const NAME = 'Signing';

const controlPercent = 0.1;

const HIDE = 'Hide';
const SHOW = 'Show';
const TOGETHER = 'Together';

const { LETTERS/*, NUMBERS, COUNT*/ } = ASL_TYPES;
const { HAND, SYMBOL, TEXT } = ASL_VARIANTS;

var scale = {};
scale[LETTERS] = {};
scale[LETTERS][HAND] = 0.325;
scale[LETTERS][SYMBOL] = 1;
scale[LETTERS][TEXT] = 1.5;
/*
scale[NUMBERS] = {};
scale[NUMBERS][HAND] = 0.65;
scale[NUMBERS][SYMBOL] = 1;
scale[NUMBERS][TEXT] = 2;
scale[COUNT] = {};
scale[COUNT][HAND] = 1;
scale[COUNT][SYMBOL] = 1;
scale[COUNT][TEXT] = 1.5;
*/

var Layouts = {};
Layouts[LETTERS] = [
    ['_A', '_B', '_C', '_D'],
    ['_E', '_F', '_G', '_H'],
    ['_I', '_J', '_K', '_L'],
    ['_M', '_N', '_O', '_P'],
    ['_Q', '_R', '_S', '_T'],
    ['_U', '_V', '_W', '_X'],
    ['__', '_Y', '_Z', '__'],
];
/*
Layouts[NUMBERS] = [
    ['_1', '_2', '_3'],
    ['_4', '_5', '_6'],
    ['_7', '_8', '_9'],
    ['__', '_0', '__'],
];
Layouts[COUNT] = [
    ['__', 'x00', 'x50', '__', 'x0', 'x5', '__'],
    ['__', 'x10', 'x60', '__', 'x1', 'x6', '__'],
    ['__', 'x20', 'x70', '__', 'x2', 'x7', '__'],
    ['__', 'x30', 'x80', '__', 'x3', 'x8', '__'],
    ['__', 'x40', 'x90', '__', 'x4', 'x9', '__'],
];
*/

export const Signing = props => {

    const { deviceScale, width, adjHeight } = useSystemState();
    const { t, dark } = useAppState();

    const [layout/*, setLayout*/] = useState(LETTERS);
    //const setLayoutRef = useRef(setLayout);
    const [mode, setMode] = useState(HAND);
    const setModeRef = useRef(setMode);
    const [show, setShow] = useState(false);
    const setShowRef = useRef(setShow);

    const renderTable = useCallback(
        (_layout, _mode, _show) => {

            const nCols = Layouts[_layout][0].length;
            const nRows = Layouts[_layout].length;

            const tableHeight = adjHeight * (1.0 - controlPercent);
            const tableSize = { height: tableHeight };

            const dx = (width * 0.95) / nCols;
            const dy = tableHeight / nRows;

            const signs = Signs.get(dark).get(_show);

            Console.log(`${NAME}.renderTable`, { deviceScale, width, adjHeight, dark, _layout, _mode, _show, nRows, nCols, tableHeight, dx, dy, signs });

            var firstMode = _mode;
            var secondMode = TEXT;

            if (_mode === TEXT) {
                firstMode = HAND;
                secondMode = SYMBOL;
            }

            return (
                <View
                    value={'SigningTable'}
                    style={[styles.table, tableSize]}
                >
                    <Svg
                        width={width}
                        height={tableHeight}
                    >
                        {Layouts[_layout].map((row, r) => row.map((cell, c) => (
                            <G
                                key={`${r}_${c}`}
                                x={(width * 0.05) + (c * dx)}
                                y={(-dy * 0.25) + ((1 + r) * dy)}
                            >
                                <G
                                    scale={scale[_layout][firstMode] * deviceScale}
                                >
                                    {signs[cell][firstMode]}
                                </G>
                                <G
                                    x={dx * 0.5}
                                    scale={scale[_layout][secondMode] * deviceScale}
                                >
                                    {signs[cell][secondMode]}
                                </G>
                            </G>
                        )))}
                    </Svg>
                </View>
            );
        },
        [
            dark,
            deviceScale,
            width,
            adjHeight,
        ],
    );

    Console.stack(NAME, props, { dark, deviceScale, adjHeight, width, layout, mode, show });

    return useMemo(
        () => {

            const controlSize = { height: adjHeight * controlPercent };

            Console.log(`${NAME} render`, { adjHeight, layout, mode, show, controlSize });

            /* MARKMARK: numbers/letters switch
                        <Button
                            labelStyle={styles.buttonLabel}
                            value={layout}
                            onPress={() => { } /*() => setLayoutRef.current(layout === LETTERS ? NUMBERS : layout === NUMBERS ? COUNT : LETTERS)* /}
                        />
            */
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    {renderTable(layout, mode, show)}
                    <View
                        value={'SigningControls'}
                        style={[styles.controls, controlSize]}
                    >
                        <Button
                            labelStyle={styles.buttonLabel}
                            value={mode === TEXT ? TOGETHER : mode}
                            onPress={() => setModeRef.current(mode === HAND ? SYMBOL : mode === SYMBOL ? TEXT : HAND)}
                        />
                        <Button
                            labelStyle={styles.buttonLabel}
                            value={`🎨 ${t(show ? HIDE : SHOW)}`}
                            noTranslate={true}
                            onPress={() => setShowRef.current(!show)}
                        />
                    </View>
                </Screen>
            );
        },
        [
            t,
            props,
            adjHeight,
            layout,
            mode,
            show,
            //setLayoutRef,
            setModeRef,
            setShowRef,
            renderTable,
        ],
    );
};

const styles = StyleSheet.create({
    controls: {
        flexDirection: 'row',
        width: '95%',
        height: '10%',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    table: {
        flexDirection: 'column',
        width: '100%',
        height: '90%',
    },
    row: {
        flexDirection: 'row',
    },
    buttonLabel: {
        fontSize: 13,
    },
});
