import { Validate } from './Validate';
import { DEV_MODE, VERBOSE } from '../constants';

let _VERBOSE_ = VERBOSE.ENABLED;
let _STACK_ = VERBOSE.STACK;
let _TRACE_ = VERBOSE.TRACE;

const MIN_ELAPSE = 0;//1000.0;
let LogTime = Date.now();
let LogCount = 0;

// TODO: use __DEV__ override to kill all logging in production

// custom logger
// - format
//   - timestamp
//   - calling function
//   - message
//   - [data object]
// - verbose
//   - info: logging is subject to runtime verbose setting
//   - warning/error: always logged
// - modes
//   - stack: logs component renderings
//   - trace: logs low-level / wordy messages
//
// usage
//  Console.stack('SomeComponent', props)
//  Console.trace('debug message', { wordy_log_data })
//  Console.log('info message', { value1, value2 })
//  Console.warn('warning message', { value1, value2 })
//  ...catch(err) { Console.error('error message', err) }
export class Console {

    // get/set the runtime verbose flag
    static Verbose = (value = null) => {
        if (!Validate.isNull(value)) {
            _VERBOSE_ = value;
        }
        return _VERBOSE_;
    };

    static SetStack = (value = null) => {
        if (!Validate.isNull(value)) {
            _STACK_ = value;
        }
        return _STACK_;
    };

    static SetTrace = (value = null) => {
        if (!Validate.isNull(value)) {
            _TRACE_ = value;
        }
        return _TRACE_;
    };

    // all messages flow through here
    // logs timestamp, function name, message, and optional value object
    // warnings and errors are always logged
    // info logs are only logged if verbose is enabled, or if the force flag is set
    // note, the optional value must be an object
    // example:
    //  Console.log('some message', { value1, value2 })
    static #print = (func, message, value = null, force = false) => {
        if (!Validate.isNull(value) && !Validate.isObject(value)) {
            console.error(`(${Date.now()}) *** LOG VALUE NOT NULL AND NOT OBJECT ***`, { message, value, force });
        }
        if (!force && !Console.Verbose()) {
            return;
        }

        const timestamp = Date.now();
        const elapse = timestamp - LogTime;

        LogTime = timestamp;
        LogCount++;
        const prefix = `(${LogTime}) ${LogCount}:`;

        if (MIN_ELAPSE && elapse > MIN_ELAPSE) {
            func(`${prefix} *** ${elapse / 1000.0} ***`);
        }

        if (value) {
            var obj = {...value};
            Object.setPrototypeOf(obj, null);
            func(`${prefix} ${message}`, obj);
        } else {
            func(`${prefix} ${message}`);
        }
    };

    // use for component stack trace including props and any extra values
    // style, children, and navigation, are removed from the logged props
    static stack = (component, props, values = null, force = false) => {
        if (_STACK_ || force) {
            const name = `STACK: ${component}`;
            if (!Validate.isNull(props) && Validate.isObject(props)) {
                var {children, navigation, style, renderHelp, ...value} = props;
                if (!Validate.isNull(values) && Validate.isObject(values)) {
                    Object.setPrototypeOf(value, null);
                    Object.setPrototypeOf(values, null);
                    Console.#print(console.log, name, { props: value, extra: values }, force);
                } else {
                    Console.#print(console.log, name, value, force);
                }
            } else {
                Console.#print(console.log, name, value, force);
            }
        }
    };

    // same as Console.stack but forces output in dev mode
    static devStack = (message, props, values = null) => {
        Console.stack(message, props, values, DEV_MODE);
    };

    // same as Console.stack for wordy stacks
    static traceStack = (message, props, values = null) => {
        if (_TRACE_) {
            Console.stack(message, props, values);
        }
    };

    // use for low-level wordy messages
    static trace = (message, value = null, force = false) => {
        if (_TRACE_) {
            Console.#print(console.log, `TRACE: ${message}`, value, force);
        }
    };

    // same as Console.trace but forces output in dev mode
    static devTrace = (message, props, values = null) => {
        Console.trace(message, props, values, DEV_MODE);
    };

    // basic log info, only applies if verbose is enabled
    static log = (message, value = null, force = false) => {
        Console.#print(console.log, message, value, force);
    };

    // same as Console.log but forces output in dev mode
    static devLog = (message, value = null) => {
        Console.log(message, value, DEV_MODE);
    };

    // hack to force logging when verbose is disabled
    // *** only used for debugging, do not push code is LOG statements !!! ***
    static LOG = (message, value = null) => {
        Console.log(message, value, true);
    };

    // basic log warn, overrides verbose
    static warn = (message, value = null) => {
        Console.#print(console.warn, message, value, true);
    };

    // basis log error, overrides verbose
    // if err object is provided, its message is used
    // - no need for Console.error('error message', err.error.message)
    static error = (message, err = null) => {
        const value = err && err?.error && err.error?.message ? { message: err.error.message } : err;
        Console.#print(console.error, message, value, true);
    };

    // MARKMARK: May not work on android
    static timer = message => {
        if (DEV_MODE) {
            const msg = `\n⏱️⏱️⏱️ ${message} ⏱️⏱️⏱️\n`;
            console.time(msg);
            return () => console.timeEnd(msg);
        } else {
            return () => {};
        }
    }
}
