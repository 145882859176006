import LinearGradient from 'react-native-linear-gradient';

export const Gradient = LinearGradient;

export const HeaderGradients = [
    ['gold1', {
        colors: ['#fea', '#dc8', '#a95', '#dc8'],
        locations: [0, 0.49, 0.51, 1],
    }],
    ['gold2', {
        colors: ['#a95', '#fea', '#ffffff', '#dc8', '#fea', '#a95'],
        locations: [0, 0.25, 0.38, 0.63, 0.87, 1],
    }],
    ['gold3', {
        colors: ['#a95', '#fea'],
        locations: [0, 1],
    }],
    ['gold4', {
        colors: ['#a95', '#fea', '#dc8', '#fea'],
        locations: [0, 0.47, 0.53, 1],
    }],

    ['silver1', {
        colors: ['#e6e6e6', 'rgba(0,0,0,0.25)', 'rgba(38,38,38,0.6)', 'rgba(0,0,0,0.25)'],
        locations: [0, 0.49, 0.51, 1],
    }],
    ['silver2', {
        colors: ['rgba(38,38,38,0.5)', '#e6e6e6', '#ffffff', 'rgba(0,0,0,0.25)', '#e6e6e6', 'rgba(38,38,38,0.4)'],
        locations: [0, 0.25, 0.38, 0.63, 0.87, 1],
    }],
    ['silver3', {
        colors: ['rgba(0,0,0,0.25)','#e6e6e6'],
        locations: [0, 1],
    }],
    ['silver4', {
        colors: ['hsl(0,0%,78%)', 'hsl(0,0%,90%)', 'hsl(0,0%,78%)', 'hsl(0,0%,70%)'],
        locations: [0, 0.47, 0.53, 1],
    }],

    ['rose1', {
        colors: ['#FFE6E9', '#DDA6AE', '#B76E79', '#DDA6AE'],
        locations: [0, 0.49, 0.51, 1],
    }],
    ['rose2', {
        colors: ['#DDA6AE', '#FFE6E9', '#ffefef', '#D9A3A9', '#FFE6E9', '#DDA6AE'],
        locations: [0, 0.25, 0.38, 0.63, 0.87, 1],
    }],
    ['rose3', {
        colors: ['#D9A3A9', '#FFE6E9'],
        locations: [0, 1],
    }],
    ['rose4', {
        colors: ['#D9A3A9', 'rgba(255,230,233,1)', '#D9A3A9', 'rgba(255,230,233,0.7)'],
        locations: [0, 0.47, 0.53, 1],
    }],

    ['black1', {
        colors: ['#555', '#222', '#000', '#222'],
        locations: [0, 0.49, 0.51, 1],
    }],
    ['black2', {
        colors: ['#000', '#222', '#333', '#000', '#2f2f2f', '#000'],
        locations: [0, 0.15, 0.28, 0.63, 0.87, 1],
    }],
    ['black3', {
        colors: ['#000', '#333'],
        locations: [0, 1],
    }],
    ['black4', {
        colors: ['hsl(0,0%,0%)', 'hsl(0,0%,10%)', 'hsl(0,0%,0%)', 'hsl(0,0%,10%)'],
        locations: [0, 0.47, 0.53, 1],
    }],
];
