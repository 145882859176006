import React, { PureComponent } from 'react';
import { StyleSheet, View } from 'react-native';

const RADIUS = 20;

export class Finger extends PureComponent {
    render() {
        const x = this.props.x - RADIUS / 2;
        const y = this.props.y - RADIUS / 2;
        return (
            <View
                style={[styles.finger, { left: x, top: y }]}
            />
        );
    }
}

const styles = StyleSheet.create({
    finger: {
        borderColor: '#CCC',
        borderWidth: 4,
        borderRadius: RADIUS * 2,
        width: RADIUS * 2,
        height: RADIUS * 2,
        backgroundColor: 'pink',
        position: 'absolute',
    },
});
