import { API } from '../aws';
import { Console } from '../utils';
import { models } from './models';

const NAME = 'chatbot';


export const chatbot = async (language, lang, user, message, translation, mode, chitchat, chatOptions, addEmojis, addIPA) => {
    if (!message?.length && !translation?.length) {
        Console.warn(`${NAME} invalid input`, { message, translation });
        return null;
    }
    try {
        Console.log(`${NAME} enter`, { language, lang, user, message, translation, mode, chitchat, chatOptions, addEmojis, addIPA });

        const body = {
            command: 'bot',
            models,
            target: language,
            lang,
            user,
            chitchat,
            chatOptions,
            prompt: message,
            promptTranslation: translation,
            promptMode: mode,
            addEmojis,
            addIPA,
        };

        const result = await API.chat(body);
        Console.LOG(`${NAME} result`, { lang, user, message, chitchat, chatOptions, result });

        return result;

    } catch (error) {
        Console.error(`${NAME} error`, error);
    }
    return null;
};
