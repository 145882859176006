import { Icons } from '../media';
import { Colors } from '../styles';


export const MODES = {
    OFF: 'off',
    ON: 'on',
};

export const Mode = [
    {
        mode: MODES.OFF,
        color: Colors.colors.indianred,
        icon: Icons.MapCompassOff,
        enabled: false,
    },
    {
        mode: MODES.ON,
        color: Colors.colors.red,
        icon: Icons.MapCompassOn,
        enabled: true,
    },
];

export const NUM_MODES = Mode.length;

var modeMap = new Map();
Mode.forEach(v => modeMap.set(v.mode, v));

export const ModeMap = modeMap;
