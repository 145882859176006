import { Console } from './Console';

//const delay = _ms => new Promise(resolve => setTimeout(resolve, _ms));

export class Sleep {

    static Millis(millis) {
        var now = new Date();
        const waitTill = new Date(now.getTime() + millis);
        Console.LOG(`waiting ${millis} ms...`, { now: now.getTime(), waitTill: waitTill.getTime() });
        do {
            now = new Date();
        } while (now < waitTill);
        Console.LOG('done waiting', { now: now.getTime(), waitTill: waitTill.getTime() });
    }
}
