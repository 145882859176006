import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Input, INPUT_VARIANTS, Text, TEXT_VARIANTS, Screen, View } from '../components';
import { Auth } from '../aws';
import { useAppState, USER_TYPES, useUserDispatch, useUserState } from '../context';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';
import { Navigate, NavParams } from './utils';

const NAME = 'Login';

const USERNAME = 'Name';
const PASSWORD = 'Password';
const SIGN_IN_MESSAGE = 'Sign in to your account';
const SIGN_IN = 'Login';
const REGISTER = 'Register';
//const FORGOT_PASSWORD = 'ForgotPassword';

const PLACEHOLDER_NAME = 'Enter your name';
const PLACEHOLDER_PASSWORD = 'Enter your password';

const EMPTY_USERNAME = 'Username cannot be empty';
const EMPTY_PASSWORD = 'Custom auth lambda trigger is not configured for the user pool.';
const INVALID_USERNAME = 'User does not exist.';
const INVALID_PASSWORD = 'Incorrect username or password.';

const __EMPTY_USERNAME__ = 'EMPTY_USERNAME';
const __EMPTY_PASSWORD__ = 'EMPTY_PASSWORD';
const __INVALID_USERNAME__ = 'INVALID_USERNAME';
const __INVALID_PASSWORD__ = 'INVALID_PASSWORD';


export const Login = props => {

    const {
        navigation,
        route,
    } = props;

    //const appDispatch = useAppDispatch();
    //const appDispatchRef = useRef(appDispatch);

    const userDispatch = useUserDispatch();
    const userDispatchRef = useRef(userDispatch);

    const { t } = useAppState();

    const { user } = useUserState();

    //const { kbVisible } = useKeyboard();

    const [username, setUsername] = useState(NavParams(route)?.username ? NavParams(route)?.username : '');
    const setUsernameRef = useRef(setUsername);

    const [password, setPassword] = useState(NavParams(route)?.password ? NavParams(route)?.password : '');
    const setPasswordRef = useRef(setPassword);

    const [error, setError] = useState({});
    const setErrorRef = useRef(setError);

    const [active, setActive] = useState(false);
    const setActiveRef = useRef(setActive);

    /*
    const [setUser] = useSetUserLazyQuery({ dispatch: userDispatch, type: USER_TYPES.SET_USER, setError });
    const [getUser] = useGetUserLazyQuery({ dispatch: userDispatch, type: USER_TYPES.SET_USER });

    const { token } = Token.token();

    if (token) {
        const variables = { token };
        Console.log(`${NAME} getUser`, { variables });
        getUser({ variables });
    }
    */
    /*
        useEffect(
            () => {
                Console.log(`${NAME} useEffect`, { kbVisible });
                if (!kbVisible) {
                    //appDispatchRef.current({ type: APP_TYPES.SET_AUTH, payload: NAME });
                }
            },
            [
                //appDispatchRef,
                kbVisible,
            ],
        );
    */

    useEffect(
        () => {
            const navParams = NavParams(route);
            const parmsTitle = navParams?.title;
            if (navParams?.username) {
                setUsernameRef.current(navParams.username);
            }
            if (navParams?.password) {
                setPasswordRef.current(navParams.password);
            }

            if (navParams?.currentUser) {
                const payload = JSON.parse(navParams.currentUser);
                if (payload?.username) {
                    //setActiveRef.current(true);
                    userDispatchRef.current({ type: USER_TYPES.SET_USER, payload });
                    setUsernameRef.current(payload?.username);
                    setActiveRef.current(true);
                    //userDispatchRef.current({ type: USER_TYPES.SET_USERNAME, payload: payload.username });
                }
            }

            const title = parmsTitle ? parmsTitle : NAME;
            Console.devLog(`${NAME} useEffect entry`, { title, navParams });
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            route?.params?.params?.username,
            route?.params?.params?.password,
            route?.params?.params?.currentUser,
            route,
            navigation,
            setUsernameRef,
            setPasswordRef,
            userDispatchRef,
            setActiveRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect user`, { user, username });
            if (Validate.isValid(user) && Validate.isValidNonEmptyString(username)) { // MARKMARK user.isAuthDev &&
                userDispatchRef.current({
                    type: USER_TYPES.SET_USERNAME,
                    payload: username,
                });
            }
        },
        [
            user,
            username,
            userDispatchRef,
        ],
    );

    const onLogin = useCallback(
        async (_username, _password) => {

            Console.log(`${NAME}.onLogin`, { _username });
            const result = await Auth.login(_username, _password);

            if (Validate.isValid(result)) {
                if (Validate.isValid(result?.error) && Validate.isValidNonEmptyString(result.error?.message)) {
                    Console.LOG(`${NAME}.onLogin error`, { result });
                    var _error = {};
                    switch (result.error.message) {
                        case EMPTY_USERNAME:
                            _error = { ..._error, username: __EMPTY_USERNAME__ };
                            break;
                        case EMPTY_PASSWORD:
                            _error = { ..._error, password: __EMPTY_PASSWORD__ };
                            break;
                        case INVALID_USERNAME:
                            _error = { ..._error, username: __INVALID_USERNAME__ };
                            break;
                        case INVALID_PASSWORD:
                            _error = { ..._error, password: __INVALID_PASSWORD__ };
                            break;
                        default:
                            break;
                    }
                    if (Object.keys(_error).length) {
                        setErrorRef.current(_error);
                    }
                } else {
                    const payload = await Auth.currentAuthenticatedUser();
                    Console.log(`${NAME}.onLogin currentAuthenticatedUser`, { payload });
                    if (Validate.isValid(payload) && Validate.isValid(payload?.username)) {
                        userDispatchRef.current({ type: USER_TYPES.SET_USER, payload });
                    }
                }
            }
        },
        [
            setErrorRef,
        ],
    );

    Console.stack(NAME, props, { /*kbVisible,*/ username, error, user });

    return useMemo(
        () => {
            const allowLogin = username?.length && password?.length;
            const buttonStyle = { backgroundColor: Colors.colors.lightgreen };
            Console.log(`${NAME} render`, { username, error });
            return Optional(!active, (
                <Screen
                    {...props}
                    value={NAME}
                    headerHeight={'20%'}
                    headerStyle={styles.header}
                    headerView={(
                        <>
                            <Text
                                value={SIGN_IN_MESSAGE}
                            />
                            <Text
                                value={error?.network}
                                variant={TEXT_VARIANTS.ERROR}
                            />
                        </>
                    )}
                    mainHeight={'40%'}
                    mainStyle={styles.main}
                    mainView={(
                        <View
                            value={`${NAME}Main`}
                            style={styles.input}
                        >
                            <Input
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={USERNAME}
                                placeholder={PLACEHOLDER_NAME}
                                value={username}
                                onChangeText={setUsernameRef.current}
                                errorMessage={error?.username}
                            />
                            <Input
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={PASSWORD}
                                placeholder={PLACEHOLDER_PASSWORD}
                                value={password}
                                onChangeText={setPasswordRef.current}
                                errorMessage={error?.password}
                                password={true}
                            />
                        </View>
                    )}
                    footerHeight={'40%'}
                    footerStyle={styles.footer}
                    footerView={(
                        <>
                            <Button
                                disabled={!allowLogin}
                                style={[allowLogin ? buttonStyle : null]}
                                value={SIGN_IN}
                                onPress={() => onLogin(username, password)}
                            />
                            <View
                                style={styles.buttonRow}
                            >
                                <Button
                                    disabled={true}
                                    value={REGISTER}
                                    onPress={() => Navigate(navigation, t(REGISTER), { title: t(REGISTER) })}
                                />
                            </View>
                        </>
                    )}
                />
            ));
        },
        [
            active,
            t,
            navigation,
            props,
            error,
            password,
            username,
            setPasswordRef,
            setUsernameRef,
            onLogin,
        ],
    );
};

/*
                                <Button
                                    disabled={true}
                                    value={FORGOT_PASSWORD}
                                    onPress={() => Navigate(navigation, t(FORGOT_PASSWORD), { title: t(FORGOT_PASSWORD) })}
                                />
*/

const styles = StyleSheet.create({
    header: {
        justifyContent: 'center',
    },
    main: {
        justifyContent: 'center',
    },
    footer: {
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    input: {
        width: '80%',
    },
    labelContainer: {
    },
    buttonRow: {
        width: '80%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
});
