import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
    GoogleMap,
    DirectionsRenderer,
    DirectionsService,
    GroundOverlay, // Overlay
    Marker,
    MarkerClusterer,
//    OverlayView, // Callout
    Polyline,
    useJsApiLoader,
} from '@react-google-maps/api';
import { Console, Optional } from '../../utils';
import { CustomMapStyle, TRANSIT_MODES } from '../../map';
import { GoogleMapViewActions, HandleGoogleMapViewEvent } from './GoogleMapViewUtils';

const NAME = 'GoogleMapView.web';

const MARKMARK_IMAGE_PATH = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';

const MAP_CONFIG = {
    id: 'google-map-script',
    // MARKMARK: SECURITY !!! googleMapsApiKey
    googleMapsApiKey: 'AIzaSyCdxCTyCHWbQgHVTWfjIYL2rYA2OtGMR3c',
};

class _GoogleMapViewUtils {

    static GetMarker(marker, onCalloutPress, onPress, onDrag = null) {
        return {
            ...marker,

            // load/unload events

            onLoad: _marker => {
                Console.log(`${NAME}.onMarkerLoad`, { marker: _marker });
            },
            onUnmount: _marker => {
                Console.log(`${NAME}.onMarkerUnmount`, { marker: _marker });
            },

            // action events

            onClick: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_CLICK,
                    onPress,
                    `${NAME}.Marker.onClick`,
                );
            },
            onDblClick: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_DOUBLE_CLICK,
                    onPress,
                    `${NAME}.Marker.onDblClick`,
                );
            },
            onRightClick: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_RIGHT_CLICK,
                    onPress,
                    `${NAME}.Marker.onRightClick`,
                );
            },

            // drag termination events

            onDragStart: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_DRAG_START,
                    onDrag,
                    `${NAME}.Marker.onDragStart`,
                );
            },
            onDragEnd: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_DRAG_END,
                    onDrag,
                    `${NAME}.Marker.onDragEnd`,
                );
            },

            // mouse events

            onMouseDown: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_MOUSE_DOWN,
                    null,
                    `${NAME}.Marker.onMouseDown`,
                    'trace',
                );
            },
            onMouseUp: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_MOUSE_UP,
                    null,
                    `${NAME}.Marker.onMouseUp`,
                    'trace',
                );
            },

            // dynamic events

            onDrag: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_DRAG,
                    null,
                    `${NAME}.Marker.onDrag`,
                    'trace',
                );
            },
            onMouseOut: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_MOUSE_OUT,
                    null,
                    `${NAME}.Marker.onMouseOut`,
                    'trace',
                );
            },
            onMouseOver: event => {
                HandleGoogleMapViewEvent(
                    event,
                    marker,
                    GoogleMapViewActions.MARKER_MOUSE_OVER,
                    null,
                    `${NAME}.Marker.onMouseOver`,
                    'trace',
                );
            },

            // change notifications

            onAnimationChanged: () => {
                Console.trace(`${NAME}.onMarkerAnimationChanged`, { id: marker?.id });
            },
            onClickableChanged: () => {
                Console.trace(`${NAME}.onMarkerClickableChanged`, { id: marker?.id });
            },
            onCursorChanged: () => {
                Console.trace(`${NAME}.onMarkerCursorChanged`, { id: marker?.id });
            },
            onDraggableChanged: () => {
                Console.trace(`${NAME}.onMarkerDraggableChanged`, { id: marker?.id });
            },
            onFlatChanged: () => {
                Console.trace(`${NAME}.onMarkerFlatChanged`, { id: marker?.id });
            },
            onIconChanged: () => {
                Console.trace(`${NAME}.onMarkerIconChanged`, { id: marker?.id });
            },
            onPositionChanged: () => {
                Console.trace(`${NAME}.onMarkerPositionChanged`, { id: marker?.id });
            },
            onShapeChanged: () => {
                Console.trace(`${NAME}.onMarkerShapeChanged`, { id: marker?.id });
            },
            onTitleChanged: () => {
                Console.trace(`${NAME}.onMarkerTitleChanged`, { id: marker?.id });
            },
            onVisibleChanged: () => {
                Console.trace(`${NAME}.onMarkerVisibleChanged`, { id: marker?.id });
            },
            onZindexChanged: () => {
                Console.trace(`${NAME}.onMarkerZindexChanged`, { id: marker?.id });
            },
        };
    }
}


export const GoogleMapView = props => {

    const {
        mapStyle,
        mapType,
        lat,
        lng,
        heading,
        pitch,
        zoom,
        minZoom,
        maxZoom,
        dark,
        busMarkers,
        busUpdate,
        weatherMarkers,
        weatherUpdate,
        markers,
        markersUpdate,
        overlays,
        overlaysUpdate,
        polylines,
        polylinesUpdate,
        clustering,
        // compass, // ignored in web
        origin,
        destination,
        transitMode,
        onCalloutPress,
        onDirections,
        onMapPress,
        onMarkerPress,
        onViewChanged,
    } = props;

    const { isLoaded } = useJsApiLoader(MAP_CONFIG);

    const [map, setMap] = useState(null);
    const setMapRef = useRef(setMap);

    const [directions, setDirections] = useState(null);
    const setDirectionsRef = useRef(setDirections);

    const onLoad = useCallback(
        _map => {
            Console.devLog(`${NAME}.onLoad`, { _map });
            setMapRef.current(_map);
        },
        [
            setMapRef,
        ],
    );

    const onUnmount = useCallback(
        _map => {
            Console.devLog(`${NAME}.onUnmount`, { _map });
            setMapRef.current(null);
        },
        [
            setMapRef,
        ],
    );

    const handleDirections = useCallback(
        (_directions, _transitMode) => {
            const event = { directions: _directions, transitMode: _transitMode };
            Console.devLog(`\n\n${NAME}.handleDirections`, { event });
            if (_directions?.status !== 200) {
                setDirectionsRef.current(null);
            } else {
                setDirectionsRef.current(_directions);
                if (onDirections) {
                    onDirections(event);
                }
            }
        },
        [
            onDirections,
            setDirectionsRef,
        ],
    );

    const onIdle = useCallback(
        () => {
            if (!map || !onViewChanged) {
                return;
            }

            const mapCenter = map.getCenter();
            const mapBounds = map.getBounds();

            const camera = {
                center: {
                    latitude: mapCenter.lat(),
                    longitude: mapCenter.lng(),
                },
                heading: map.getHeading(),
                pitch: map.getTilt(),
                zoom: map.getZoom(),
                region: {
                    minLatitude: mapBounds.Ua.lo,
                    minLongitude: mapBounds.Ha.lo,
                    maxLatitude: mapBounds.Ua.hi,
                    maxLongitude: mapBounds.Ha.hi,
                },
            };

            Console.log(`${NAME}.onIdle`, { camera });
            onViewChanged(camera);
        },
        [
            map,
            onViewChanged,
        ],
    );

    Console.stack(NAME, props, { isLoaded, map, directions });

    return useMemo(
        () => {
            const showDirections = (origin && origin !== '' && destination && destination !== '') ? true : false;
            const _mapStyle = {
                width: `${mapStyle.width}px`,
                height: `${mapStyle.height}px`,
            };

            Console.log(`${NAME} render`, { isLoaded, _mapStyle, mapType, lat, lng, zoom, pitch, heading, clustering, origin, destination, transitMode, directions, minZoom, maxZoom, showDirections });
            Console[busMarkers?.length ? 'devLog' : 'log'](`${NAME} render buses`, { busMarkers: busMarkers?.length, busUpdate, busMarker: busMarkers?.length ? busMarkers[0] : null });
            Console[weatherMarkers?.length ? 'devLog' : 'log'](`${NAME} render weather`, { weatherMarkers: weatherMarkers?.length, weatherUpdate, weatherMarker: weatherMarkers?.length ? weatherMarkers[0] : null });
            Console[markers?.length ? 'devLog' : 'log'](`${NAME} render markers`, { markers: markers?.length, markersUpdate, marker: markers?.length ? markers[0] : null });
            Console[overlays?.length ? 'devLog' : 'log'](`${NAME} render overlays`, { overlays: overlays?.length, overlaysUpdate, overlay: overlays?.length ? overlays[0] : null });
            Console[polylines?.length ? 'devLog' : 'log'](`${NAME} render polylines`, { polylines: polylines?.length, polylinesUpdate, polyline: polylines?.length ? polylines[0] : null });

            return Optional(isLoaded, (
                <GoogleMap
                    mapTypeId={mapType === 'standard' ? 'roadmap' : mapType}
                    mapContainerStyle={_mapStyle}
                    options={{
                        styles: CustomMapStyle(dark, /*show road labels*/ true, /* show roads */ true),
                        fullscreenControl: false,
                        keyboardShortcuts: false,
                        mapTypeControl: false,
                        streetViewControl: false,
                        zoomControl: false,
                    }}
                    center={{ lat, lng }}
                    heading={heading}
                    tilt={pitch}
                    zoom={zoom}
                    onClick={_event => {
                        HandleGoogleMapViewEvent(
                            _event,
                            null,
                            GoogleMapViewActions.MAP_CLICK,
                            onMapPress,
                            `${NAME}.Map.onClick`,
                        );
                    }}
                    onDblClick={_event => {
                        HandleGoogleMapViewEvent(
                            _event,
                            null,
                            GoogleMapViewActions.MAP_DOUBLE_CLICK,
                            onMapPress,
                            `${NAME}.Map.onDblClick`,
                        );
                    }}
                    onRightClick={_event => {
                        HandleGoogleMapViewEvent(
                            _event,
                            null,
                            GoogleMapViewActions.MAP_RIGHT_CLICK,
                            onMapPress,
                            `${NAME}.Map.onRightClick`,
                        );
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onIdle={onIdle}
                >
                    {
                        [
                            { id: 'overlay', data: overlays, Comp: GroundOverlay },
                            { id: 'polyline', data: polylines, Comp: Polyline },
                        ].map(({ id, data, Comp }, index) => data.map(_props => <Comp key={`${id}${index}`} {..._props} />))
                    }
                    {Optional(clustering, (
                        <MarkerClusterer
                            options={{
                                averageCenter: true,
                                imagePath: MARKMARK_IMAGE_PATH,
                                minZoom,
                                maxZoom,
                            }}
                        >
                            {clusterer => {
                                return [
                                    busMarkers,
                                    weatherMarkers,
                                    markers,
                                ].map(data => {
                                    return data
                                        .map((marker, index) => {
                                            const _props = _GoogleMapViewUtils.GetMarker(marker, onCalloutPress, onMarkerPress);
                                            return (
                                                <Marker
                                                    {..._props}
                                                    key={`cluster_marker_${index}`}
                                                    clusterer={clusterer}
                                                />
                                            );
                                        });
                                });
                            }}
                        </MarkerClusterer>
                    ), (
                        <>
                            {
                                [
                                    busMarkers,
                                    weatherMarkers,
                                    markers,
                                ].map(data => {
                                    return data
                                        .map((marker, index) => {
                                            const _props = _GoogleMapViewUtils.GetMarker(marker, onCalloutPress, onMarkerPress);
                                            return (
                                                <Marker
                                                    {..._props}
                                                    key={`marker_${index}`}
                                                />
                                            );
                                        });
                                })
                            }
                        </>
                    ))}
                    {Optional(showDirections && transitMode !== TRANSIT_MODES.NONE && transitMode !== TRANSIT_MODES.OFF, (
                        <DirectionsService
                            options={{
                                origin,
                                destination,
                                travelMode: transitMode,
                            }}
                            callback={_directions => handleDirections(_directions, transitMode)}
                        />
                    ))}
                    {Optional(directions, (
                        <DirectionsRenderer
                            options={{
                                directions,
                                strokeColor: 'red',
                                strokeWeight: 1,
                            }}
                        />
                    ))}
                </GoogleMap>
            ));
        },
        [
            isLoaded,
            mapStyle,
            mapType,
            lat,
            lng,
            heading,
            pitch,
            zoom,
            minZoom,
            maxZoom,
            dark,
            busMarkers,
            busUpdate,
            weatherMarkers,
            weatherUpdate,
            markers,
            markersUpdate,
            overlays,
            overlaysUpdate,
            polylines,
            polylinesUpdate,
            clustering,
            origin,
            destination,
            transitMode,
            directions,
            handleDirections,
            onCalloutPress,
            onMapPress,
            onMarkerPress,
            onLoad,
            onUnmount,
            onIdle,
        ],
    );
};
