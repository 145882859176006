import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PanResponder, View } from 'react-native';
import { useSystemState } from '../../context';
import { Console, Validate } from '../../utils';

const NAME = 'DynamicView.web';


export const DynamicView = props => {

    const {
        children,
        style,
        onGesture,
        top,
    } = props;

    const { headerHeight } = useSystemState();

    const [offset, setOffset] = useState(top + headerHeight);
    const setOffsetRef = useRef(setOffset);

    const [xPosition, setXPosition] = useState(0);
    const setXPositionRef = useRef(setXPosition);

    const [yPosition, setYPosition] = useState(0);
    const setYPositionRef = useRef(setYPosition);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect offset`, { top, headerHeight });
            setOffsetRef.current(headerHeight + (top ? top : 0));
        },
        [
            headerHeight,
            top,
        ],
    );

    const _onGestureState = useCallback(
        (event, state) => {
            if (Validate.isValid(onGesture)) {
                Console.trace(`${NAME}._onGestureState { ${event.x0}, ${event.y0} } [${state}] offset=${offset}`, { ...event });
                if (state === 5 && !event.x0 && !event?.y0 && !event.moveX && !event.moveY) { // MARKMARK: MAGIC NUMBER
                    onGesture({
                        panState: {
                            x: xPosition,
                            y: yPosition,
                            state,
                        },
                    });
                } else {
                    onGesture({
                        panState: {
                            x: event.x0,
                            y: event.y0 - offset,
                            state,
                        },
                    });
                }
            }
        },
        [
            offset,
            onGesture,
            xPosition,
            yPosition,
        ],
    );

    const _onGestureEvent = useCallback(
        event => {
            Console.trace(`${NAME}._onGestureEvent { ${event.moveX}, ${event.moveY} } offset=${offset}`, { ...event });
            if (Validate.isValid(onGesture)) {
                const x = event.moveX;
                const y = event.moveY - offset;
                onGesture({
                    panEvent: {
                        x,
                        y,
                    },
                });
                setXPositionRef.current(x);
                setYPositionRef.current(y);
            }
        },
        [
            offset,
            onGesture,
            setXPositionRef,
            setYPositionRef,
        ],
    );

    const panResponder = useMemo(
        () => {
            return PanResponder.create({

                onStartShouldSetPanResponder: (evt, gestureState) => true,
                onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
                onMoveShouldSetPanResponder: (evt, gestureState) => true,
                onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
                onPanResponderTerminationRequest: (evt, gestureState) => false,// true

                onPanResponderGrant: (evt, gestureState) => {
                    _onGestureState(gestureState, 4);
                },

                onPanResponderMove: (evt, gestureState) => {
                    _onGestureEvent(gestureState);
                },

                onPanResponderRelease: (evt, gestureState) => {
                    _onGestureState(gestureState, 5);
                },

                onPanResponderTerminate: (evt, gestureState) => {
                    _onGestureState(gestureState, 5);
                },

                onShouldBlockNativeResponder: (evt, gestureState) => {
                    return true;
                },
            });
        },
        [
            _onGestureEvent,
            _onGestureState,
        ],
    );

    Console.stack(NAME, props, { headerHeight });

    return useMemo(
        () => {
            Console.log(`${NAME} render`);
            return (
                <View
                    {...panResponder.panHandlers}
                    style={style}
                >
                    {children}
                </View>
            );
        },
        [
            children,
            style,
            panResponder,
        ],
    );
};
