export class Validate {

    // all type validations flow through here
    static #_isType(value, type) {
        return typeof value === type;
    }

    static isDefined(value) {
        return !Validate.#_isType(value, 'undefined'); // invert return for 'isUndefined'
    }

    static isBoolean(value) {
        return Validate.#_isType(value, 'boolean');
    }

    static isBigInt(value) {
        return Validate.#_isType(value, 'bigint');
    }

    static isFunction(value) {
        return Validate.#_isType(value, 'function');
    }

    static isNumber(value) {
        return Validate.#_isType(value, 'number');
    }

    static isObject(value) {
        return Validate.#_isType(value, 'object');
    }

    static isString(value) {
        return Validate.#_isType(value, 'string');
    }

    static isSymbol(value) {
        return Validate.#_isType(value, 'symbol');
    }

    static isNull(value) {
        return value === null;
    }

    static isValid(value) {
        return Validate.isDefined(value) &&
               !Validate.isNull(value);
    }

    static isValidNonEmptyString(value) {
        return Validate.isValid(value) &&
               Validate.isString(value) &&
               value.length > 0;
    }

    static isValidField(obj, field) {
        return Validate.isValid(obj) &&
               Validate.isObject(obj) &&
               Validate.isValidNonEmptyString(field) &&
               Validate.isValid(obj[field]);
    }
}
