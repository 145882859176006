import { Console, Validate } from '../../utils';


// invokes and logs callback(value)
// - errors on no callback
export const onPress = (value, callback = null) => {

    if (Validate.isValid(callback)) {
        if (Validate.isValidNonEmptyString(value)) {
            Console.log(`*** onPress(${value}) ***`);
        }
        callback(value);
    } else {
        Console.error(`onPress(${value}): callback is undefined`);
    }
};
