import axios from 'axios';
//import { File, FILE_ENCODING } from '../../storage';
import { S3_SERVER } from '../../constants';
//import { Console } from '../../utils';
import { DictionaryFiles } from '../dictionary/DictionaryFiles';

//const NAME = 'Loader';

const server = axios.create({ baseURL: S3_SERVER });

const SENSES = 'Senses_';
const WORDS = 'Words_';


export const Loader = (onRead, onLoad) => {

    var result = {
        words: new Map(),
        senses: new Map(),
    };

    var fnames = new Set(DictionaryFiles);
    DictionaryFiles.forEach(async fname => {
        var json = null;

        /*
        const exists = await File.Exists(File.Path(fname));
        if (exists) {
            Console.LOG(`${NAME} reading file`, { fname });
            json = JSON.parse(await File.Read(fname, FILE_ENCODING.UTF8));
        } else {
            json = await server.get(`/dictionary/${fname}`);
            Console.LOG(`${NAME} writing file`, { fname });
            await File.Write(fname, JSON.stringify(json), FILE_ENCODING.UTF8);
        }
        */
        json = await server.get(`/dictionary/${fname}`);

        const jsonRecords = json?.data ? json.data : [];
        if (fname.startsWith(WORDS)) {
            const lang = fname.split('_')[1];
            if (!result.words.has(lang)) {
                result.words.set(lang, new Map());
            }
            jsonRecords.forEach(record => {
                result.words.get(lang).set(record[0], record[1]);
            });
        } else if (fname.startsWith(SENSES)) {
            jsonRecords.forEach(record => {
                result.senses.set(record[0], record[1]);
            });
        }
        fnames.delete(fname);
        if (fnames.size) {
            onRead(1.0 - (fnames.size / DictionaryFiles.length));
        } else {
            onLoad(result);
        }
    });
};
