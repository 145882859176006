export const Array2Object = (array, cb = (object, key) => { object[key] = key; }) => {
    var result = {};
    array.forEach(key => { cb(result, key); });
    return result;
};

export const IdArray2Map = array => {
    var result = new Map();
    array.forEach(entry => {
        result.set(entry.id, entry);
    });
    return result;
};

export const Map2Object = map => Object.fromEntries(map);

export const Object2Map = obj => new Map(Object.entries(obj));
