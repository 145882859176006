import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import { Button, Icon, Image, Pressable, Screen, Text, View, TEXT_VARIANTS } from '../components';
import { Storage } from '../aws';
import { useAppState, useUserState, useSystemState } from '../context';
import { Icons, Images } from '../media';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';
import { LINKS } from '../constants';

const NAME = 'Resources';

const QR_CODE = 'QRCode';
const YOUTUBE = 'YouTube';

const ICON_SIZE = 40;

const SOCIAL_MEDIA = 'Visit us on Social Media';
const DOWNLOAD_APP = 'Download Application';
/*
const RESOURCES = 'You are here';
const WORD5 = 'Search five letter words';
const SPEECH = 'Learn about pronunciation';
const SIGNING = 'Practice sign language';
*/
const SocialMediaWebStyle = {
    textDecoration: 'none',
};


export const Resources = props => {

    const { isWeb } = useSystemState();
    const { user } = useUserState();
    const { dev } = useAppState();

    const [code, setCode] = useState(false);
    const setCodeRef = useRef(setCode);

    const [uri, setUri] = useState(null);
    const setUriRef = useRef(setUri);

    const [androidSent, setAndroidSent] = useState(false);
    const setAndroidSentRef = useRef(setAndroidSent);

    const [iosSent, setIosSent] = useState(false);
    const setIosSentRef = useRef(setIosSent);

    const onPress = useCallback(
        value => {
            if (value === QR_CODE) {
                setCodeRef.current(v => !v);
                setUriRef.current(null);
            } else if (value === YOUTUBE) {
                setCodeRef.current(false);
                setUriRef.current(LINKS[value].split('|')[isWeb ? 0 : 1]);
            } else {
                setCodeRef.current(false);
                setUriRef.current(LINKS[value]);
            }
        },
        [
            isWeb,
            setCodeRef,
            setUriRef,
        ],
    );
    /*
        const renderTab = useCallback(
            value => {
                return (
                    <View
                        value={'ResourcesTab'}
                        style={styles.footerTab}
                    >
                        <View
                            value={'ResourcesTabText'}
                            style={styles.footerTabText}
                        >
                            <Text
                                style={styles.tabText}
                                value={value}
                            />
                        </View>
                        <Icon icon={Icons.HandDown} />
                    </View>
                );
            },
            [
            ],
        );
    */
    Console.stack(NAME, props, { isWeb, user, dev, code, uri });

    return useMemo(
        () => {
            Console.stack(`${NAME} render`, { isWeb, dev, code, uri });
            /*
                    <View
                        value={'ResourcesFooter'}
                        style={styles.footer}
                    >
                        {renderTab(RESOURCES)}
                        {renderTab(WORD5)}
                        {renderTab(SPEECH)}
                        {renderTab(SIGNING)}
                    </View>
            */
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        value={'ResourcesHeader'}
                        style={styles.header}
                    >
                        <Text
                            value={SOCIAL_MEDIA}
                            variant={TEXT_VARIANTS.SUBHEADING}
                        />
                        <Text
                            value={'www.da-app-hawaii.com'}
                            color={Colors.colors.red}
                            variant={TEXT_VARIANTS.TEXT}
                        />
                        {Optional(isWeb, (
                            <View
                                value={'ResourcesSocialWeb'}
                                style={styles.buttons}
                            >
                                <a href={LINKS.Facebook} target={'_blank'} style={SocialMediaWebStyle}><Icon icon={Icons.Facebook} /></a>
                                <a href={LINKS.Instagram} target={'_blank'} style={SocialMediaWebStyle}><Icon icon={Icons.Instagram} /></a>
                                <a href={LINKS.Twitter} target={'_blank'} style={SocialMediaWebStyle}><Icon icon={Icons.Twitter} /></a>
                                <a href={LINKS.YouTube} target={'_blank'} style={SocialMediaWebStyle}><Icon icon={Icons.YouTube} /></a>
                            </View>
                        ), (
                            <View
                                value={'ResourcesSocialNative'}
                            >
                                <View
                                    value={'ResourcesSocialButtons'}
                                    style={styles.buttons}
                                >
                                    <Pressable iconSize={ICON_SIZE} icon={Icons.Facebook} onPress={() => onPress('Facebook')} />
                                    <Pressable iconSize={ICON_SIZE} icon={Icons.Instagram} onPress={() => onPress('Instagram')} />
                                    <Pressable iconSize={ICON_SIZE} icon={Icons.Twitter} onPress={() => onPress('Twitter')} />
                                    <Pressable iconSize={ICON_SIZE} icon={Icons.YouTube} onPress={() => onPress('YouTube')} />
                                    <Pressable iconSize={ICON_SIZE} icon={Icons.QRCode} onPress={() => onPress('QRCode')} />
                                </View>
                            </View>
                        ))}
                    </View>
                    {Optional(dev && !isWeb, (
                        <View
                            value={'ResourcesDevelopment'}
                            style={styles.development}
                        >
                            <Pressable iconSize={ICON_SIZE} icon={Icons.GitHub} onPress={() => onPress('GitHub')} />
                            <Pressable iconSize={ICON_SIZE} icon={Icons.AWS} onPress={() => onPress('AWS')} />
                            <Pressable iconSize={ICON_SIZE} icon={Icons.Google} onPress={() => onPress('Google')} />
                            <Pressable iconSize={ICON_SIZE} icon={Icons.LinkedIn} onPress={() => onPress('LinkedIn')} />
                            <Pressable iconSize={ICON_SIZE} icon={Icons.Email} onPress={() => onPress('Email')} />
                        </View>
                    ))}
                    <View
                        value={'ResourcesBody'}
                        style={styles.body}
                    >
                        {Optional(code && !Validate.isValid(uri), (
                            <View
                                value={'ResourcesQRCode'}
                                style={styles.whiteBackground}
                            >
                                <Image
                                    source={Images.miscl.qr_hanahou}
                                    width={300}
                                    height={300}
                                    resizeMode={'contain'}
                                />
                            </View>
                        ))}
                        {Optional(!code && Validate.isValid(uri), (
                            <View
                                value={'ResourcesWebView'}
                                style={styles.webView}
                            >
                                <WebView
                                    source={{ uri }}
                                />
                            </View>
                        ))}
                        {Optional(!code && isWeb, (
                            <View
                                value={'ResourcesDownload'}
                                style={styles.download}
                            >
                                <Text
                                    value={DOWNLOAD_APP}
                                    variant={TEXT_VARIANTS.SUBHEADING}
                                />
                                <View
                                    value={'ResourcesDownloadButtons'}
                                    style={styles.buttons}
                                >
                                    <Button
                                        disabled={!user?.username || androidSent}
                                        icon={Icons.Android}
                                        value={'Android'}
                                        onPress={() => {
                                            setAndroidSentRef.current(true);
                                            Storage.download('da_app_hawaii.apk');
                                        }}
                                    />
                                    <Button
                                        disabled={!user?.username || iosSent}
                                        icon={Icons.Apple}
                                        value={'IOS'}
                                        onPress={() => {
                                            setIosSentRef.current(true);
                                            Storage.download('da_app_hawaii_ios_coming_soon.txt');
                                        }}
                                    />
                                </View>
                            </View>
                        ))}
                    </View>
                </Screen>
            );
        },
        [
            props,
            isWeb,
            user,
            dev,
            code,
            uri,
            //renderTab,
            onPress,
            androidSent,
            iosSent,
            setAndroidSentRef,
            setIosSentRef,
        ],
    );
};

const styles = StyleSheet.create({
    buttons: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
    },
    download: {
        width: '60%',
        height: '20%',
        alignSelf: 'center',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    webView: {
        width: '100%',
        flexGrow: 1,
    },
    header: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '20%',
    },
    body: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexGrow: 1,
    },
    footer: {
        width: '100%',
        height: '23%',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    footerTab: {
        width: '20%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: '2%',
    },
    footerTabText: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabText: {
        textAlign: 'center',
        fontStyle: 'italic',
    },
    development: {
        width: '100%',
        alignSelf: 'center',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        flexDirection: 'row',
        height: '10%',
    },
    whiteBackground: {
        backgroundColor: Colors.colors.white,
    },
    helpView: {
        backgroundColor: Colors.colors.green,
    },
});
