// https://flagcolor.com
const flagColors = {

    white: 'rgb(255, 255, 255)',
    black: 'rgb(0, 0, 0)',
    lightgray: 'rgb(211,211,211)',

    usa_red: 'rgb(191,13,62)',
    usa_blue: 'rgb(10,49,97)',

    uk_red: 'rgb(200, 16, 46)',
    uk_blue: 'rgb(1, 33, 105)',

    spain_red: 'rgb(239,51,64)',
    spain_yellow: 'rgb(255,209,0)',

    mexico_green: 'rgb(0,99,65)',
    mexico_red: 'rgb(200,16,46)',

    portugal_green: 'rgb(4,106,56)',
    portugal_red: 'rgb(218,41,28)',

    brazil_green: 'rgb(0,150,57)',
    brazil_yellow: 'rgb(254,221,0)',
    brazil_blue: 'rgb(0,39,118)',

    france_red: 'rgb(239,65,53)',
    france_blue: 'rgb(0,85,164)',

    italy_red: 'rgb(203,51,59)',
    italy_green: 'rgb(0,122,51)',

    romania_blue: 'rgb(1,33,105)',
    romania_yellow: 'rgb(255,205,0)',
    romania_red: 'rgb(200,16,46)',

    china_red: 'rgb(200,16,46)',
    china_yellow: 'rgb(252,227,0)',

    japan_red: 'rgb(239,51,64)',

    korea_red: 'rgb(200,16,46)',
    korea_blue: 'rgb(0,47,108)',

    taiwan_red: 'rgb(254,0,0)',
    taiwan_blue: 'rgb(0,0,149)',

    russia_red: 'rgb(239,51,64)',
    russia_blue: 'rgb(0,114,206)',

    india_orange: 'rgb(255,143,28)',
    india_blue: 'rgb(37,14,98)',
    india_green: 'rgb(80,158,47)',

    germany_red: 'rgb(218,41,28)',
    germany_yellow: 'rgb(255,205,0)',

    saudi_green: 'rgb(0,150,57)',
};

// https://flagcolor.com
export var languageColors = {
    en_us: {
        light: {
            backgroundColor: flagColors.usa_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.usa_red,
            color: flagColors.white,
        },
    },
    en_gb: {
        light: {
            backgroundColor: flagColors.uk_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.uk_red,
            color: flagColors.white,
        },
    },
    es_es: {
        light: {
            backgroundColor: flagColors.spain_yellow,
            color: flagColors.spain_red,
        },
        dark: {
            backgroundColor: flagColors.spain_red,
            color: flagColors.spain_yellow,
        },
    },
    fr_fr: {
        light: {
            backgroundColor: flagColors.france_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.france_red,
            color: flagColors.white,
        },
    },
    it_it: {
        light: {
            backgroundColor: flagColors.italy_green,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.italy_red,
            color: flagColors.white,
        },
    },
    pt_pt: {
        light: {
            backgroundColor: flagColors.portugal_green,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.portugal_red,
            color: flagColors.white,
        },
    },
    ro_ro: {
        light: {
            backgroundColor: flagColors.romania_blue,
            color: flagColors.romania_yellow,
        },
        dark: {
            backgroundColor: flagColors.romania_red,
            color: flagColors.romania_yellow,
        },
    },
    es_mx: {
        light: {
            backgroundColor: flagColors.mexico_green,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.mexico_red,
            color: flagColors.white,
        },
    },
    pt_br: {
        light: {
            backgroundColor: flagColors.brazil_blue,
            color: flagColors.brazil_yellow,
        },
        dark: {
            backgroundColor: flagColors.brazil_green,
            color: flagColors.brazil_yellow,
        },
    },
    zh_cn: {
        light: {
            backgroundColor: flagColors.china_yellow,
            color: flagColors.china_red,
        },
        dark: {
            backgroundColor: flagColors.china_red,
            color: flagColors.black,
        },
    },
    ja_jp: {
        light: {
            backgroundColor:  flagColors.lightgray,
            color: flagColors.japan_red,
        },
        dark: {
            backgroundColor: flagColors.japan_red,
            color: flagColors.black,
        },
    },
    ko_kr: {
        light: {
            backgroundColor: flagColors.korea_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.korea_red,
            color: flagColors.white,
        },
    },
    zh_tw: {
        light: {
            backgroundColor: flagColors.taiwan_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.taiwan_red,
            color: flagColors.white,
        },
    },
    hi_in: {
        light: {
            backgroundColor: flagColors.india_blue,
            color: flagColors.india_orange,
        },
        dark: {
            backgroundColor: flagColors.india_green,
            color: flagColors.india_orange,
        },
    },
    ru_ru: {
        light: {
            backgroundColor: flagColors.russia_blue,
            color: flagColors.white,
        },
        dark: {
            backgroundColor: flagColors.russia_red,
            color: flagColors.white,
        },
    },
    de_de: {
        light: {
            backgroundColor: flagColors.germany_yellow,
            color: flagColors.germany_red,
        },
        dark: {
            backgroundColor: flagColors.germany_red,
            color: flagColors.black,
        },
    },
    ar_sa: {
        light: {
            backgroundColor:  flagColors.lightgray,
            color: flagColors.saudi_green,
        },
        dark: {
            backgroundColor: flagColors.saudi_green,
            color: flagColors.white,
        },
    },
};

languageColors.haw_hi = languageColors.en_us;

languageColors.es_419 = languageColors.es_mx;
languageColors.es_us = languageColors.es_mx;
