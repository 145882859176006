import { Console } from '../Console';
import { Voices } from './Voices';
import { LANGUAGES, LANGUAGE_SYNONYMS } from '../../constants';

// this will be our master voice map
let VOICES = null;

var BlackListLangNames = new Set([
    'en-US|Albert',
    'en-US|Bad News',
    'en-US|Bahh',
    'en-US|Bells',
    'en-US|Boing',
    'en-US|Bubbles',
    'en-US|Cellos',
    'en-US|Fred',
    'en-US|Good News',
    'en-US|Jester',
    'en-US|Junior',
    'en-US|Kathy',
    'en-US|Organ',
    'en-US|Superstar',
    'en-US|Ralph',
    'en-US|Trinoids',
    'en-US|Whisper',
    'en-US|Wobble',
    'en-US|Zarvox',
]);
['Flo', 'Eddy', 'Flo', 'Grandma', 'Grandpa', 'Reed', 'Rocko', 'Sandy', 'Shelley'].forEach(name => {
    [
        ['en-US', 'English', 'US'],
        ['en-GB', 'English', 'UK'],
        ['es-ES', 'Spanish', 'Spain'],
        ['es-MX', 'Spanish', 'Mexico'],
        ['fr-FR', 'French', 'France'],
        ['pt-BR', 'Portuguese', 'Brazil'],
        ['de-DE', 'German', 'Germany'],
        ['it-IT', 'Italian', 'Italy'],
    ].forEach(linfo => {
        BlackListLangNames.add(`${linfo[0]}|${name} (${linfo[1]} (${linfo[2]}))`);
    });
});

// make everything look like 'en-US'
const fixLang = lang => {
    if (!lang) {
        return null;
    }
    if (lang.indexOf('_') >= 0) {
        const t = lang.split('_');
        if (t?.length === 2) {
            return `${t[0]}-${t[1].toUpperCase()}`;
        }
    }
    return lang;
};

// lookup to map edge cases like ar_001, es_419, es_us, etc
var LanguageSynonyms = {};
Object.keys(LANGUAGE_SYNONYMS).forEach(lang => {
    const key = fixLang(lang);
    LanguageSynonyms[key] = fixLang(LANGUAGE_SYNONYMS[lang]);
});
Object.keys(LANGUAGES).forEach(lang => {
    const key = fixLang(lang);
    LanguageSynonyms[key] = key;
});
// this is a special special case...
LanguageSynonyms['haw-HI'] = 'en-US';

// remap any language entry into 'en-US', whilst handling edge case synonyms
export const getLanguageKey = lang => {
    var key = fixLang(lang);
    if (key) {
        key = LanguageSynonyms[key];
    }
    return key;
};

// get the voices that match the input language and gender
// optionally initialize the singleton voice map
export const getVoices = (language = null, gender = null, installedVoices = null) => {

    Console.log('getVoices entry', { VOICES, language, gender, installedVoices });

    // if we don't have a voice map, and we have installed voices...
    if (!VOICES && installedVoices?.length) {

        Console.log('getVoices init');

        // initialize the voice map
        VOICES = new Map();

        // make a key for each supported language
        // where we will be store an array of corresponding installed voice entries
        Object.keys(LANGUAGES).forEach(lang => VOICES.set(fixLang(lang), []));

        // iterate the installed voices and store them into the voice map
        installedVoices.forEach(v => {

            // get the language from the installed voice,
            const lang = v?.language ? v.language : v?.lang ? v.lang : null;
            // then convert it to a consistent language key
            const key = getLanguageKey(lang);

            // if we support this language key, add the voice to the map
            if (VOICES.has(key)) {
                if (BlackListLangNames.has(`${key}|${v?.name}`) ||
                    (v?.id && v.id.indexOf('synthesis') >= 0) ||
                    (v?.voiceURI && v.voiceURI.indexOf('synthesis') >= 0) ||
                    (v?.voiceURI && v.voiceURI.indexOf('eloquence') >= 0)) {
                    // skip

                    // MARKMARK: get best quality / get gender

                } else {
                    VOICES.get(key).push(v);
                }
            }
        });

        Console.log('getVoices done', { Voices, installedVoices, VOICES });
    }

    // if the voice map is not initialized, return null
    // otherwise, return array of installed voices that support the language
    const key = getLanguageKey(language);
    const result = VOICES
        ? key && VOICES.has(key) ? VOICES.get(key) : []
        : null;
    Console.log('getVoices result', { language, key, result });
    return result;
};
