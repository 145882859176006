import React, { useCallback, useEffect, useRef, useState } from 'react';
import { stt } from '../ai';
import { Recorder } from './platform';
import { Console } from './Console';
import { Validate } from './Validate';

const NAME = 'STT';

const OPTIONS = {
    channelCount: 1,
    sampleRate: 16000,
    bitsPerSample: 16,
    echoCancellation: false,
    autoGainControl: false,
    noiseSuppression: false,
};


export const STT = props => {

    const {
        language,
        username,
        recording,
        onStart,
        onStop,
        setTranscriptionRef,
        onInfo,
    } = props;

    const [recorder, setRecorder] = useState();
    const setRecorderRef = useRef(setRecorder);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect recorder`);
            setRecorderRef.current(new Recorder(OPTIONS, _onStart, _onStop, _onInfo));
        },
        [
            setRecorderRef,
            _onStart,
            _onStop,
            _onInfo,
        ],
    );

    const _onStart = useCallback(
        () => {
            Console.log(`${NAME}._onStart`);
            if (Validate.isValid(onStart)) {
                onStart();
            }
        },
        [
            onStart,
        ],
    );

    const _onStop = useCallback(
        async payload => {
            const data = payload?.data;
            Console.log(`${NAME}._onStop`, { data: data?.length });
            if (data?.length) {
                var doTranscription = true;
                if (Validate.isValid(onStop)) {
                    doTranscription = onStop(payload);
                }
                if (doTranscription && Validate.isValid(setTranscriptionRef?.current)) {
                    const transcription = await stt(language, username, payload.data);
                    Console.log(`${NAME}._onStop set transcription`, { transcription });
                    setTranscriptionRef.current(transcription);
                }
            }
        },
        [
            language,
            username,
            onStop,
            setTranscriptionRef,
        ],
    );

    const _onInfo = useCallback(
        info => {
            Console.log(`${NAME}._onInfo`, { info });
            if (Validate.isValid(onInfo)) {
                onInfo(info);
            }
        },
        [
            onInfo,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect recording=[${recording}]`);
            if (recorder) {
                if (recording) {
                    recorder.start();
                } else {
                    recorder.stop();
                }
            }
        },
        [
            recorder,
            recording,
        ],
    );

    Console.stack(NAME, props, { recorder, recording, language });

    return (<></>);
};
