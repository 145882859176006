import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Alert, StyleSheet } from 'react-native';
import { Image, Input, Pressable, Text, View, Screen, INPUT_VARIANTS, TEXT_VARIANTS } from '../components';
import { Images } from '../media';
import { Console, Optional } from '../utils';
import { useAppState, useUserDispatch, useUserState, useSystemState, USER_TYPES } from '../context';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { HoloCard, HoloUrl } from '../thirdparty';
import { Navigate, NavParams } from './utils';

const NAME = 'MapUserDetail';

const MAP_WEB_DETAIL = 'MapWebDetail';

const HOLO_LOGO_WIDTH = 140;
const HOLO_LOGO_HEIGHT = 64;

const HOLO_CARD_WIDTH = 380;
const HOLO_CARD_HEIGHT = 239;

const HOLO_CARD_NUMBER = '_HOLO_CARD_NUMBER_';
const HOLO_SECURITY_CODE = '_HOLO_SECURITY_CODE_';

const PLACEHOLDER_HOLO_CARD_NUMBER = '_PLACEHOLDER_HOLO_CARD_NUMBER_';
const PLACEHOLDER_HOLO_SECURITY_CODE = '_PLACEHOLDER_HOLO_SECURITY_CODE_';

export const MapUserDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight, width } = useSystemState();
    const { /*username, MARKMARK*/ holoCardNumber, holoSecurityCode } = useUserState();
    const { t, dark, language, showAds } = useAppState();
    const userDispatch = useUserDispatch();
    const userDispatchRef = useRef(userDispatch);

    const [username] = useState('Braddah Honu');
    const [data] = useState(NavParams(route)?.data);

    useEffect(
        () => {
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={username}
                        color={tintColor}
                        variant={username?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            username,
            navigation,
        ],
    );

    Console.devStack(NAME, props, { adjHeight, width, dark, language, data, showAds, username, holoCardNumber, holoSecurityCode });

    return useMemo(
        () => {
            const _height = showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight;
            const titleStyle = {
                width,
                height: _height * 0.1,
                //flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            };
            const holoStyle = {
                width,
                height: _height - titleStyle.height,
                alignItems: 'center',
                justifyContent: 'space-evenly',
            };
            const imageStyle = {
                alignItems: 'center',
                justifyContent: 'space-evenly',
            };
            const inputStyle = {
                width: width * 0.93,
            };
            Console.devLog(`${NAME} render`, { dark, language, data, showAds, username, holoCardNumber, holoSecurityCode });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={[styles.header, titleStyle]}
                    >
                        <Text
                            value={'Current amount: $7.50'}
                            variant={TEXT_VARIANTS.TITLE}
                        />
                    </View>
                    <View
                        style={[styles.holo, holoStyle]}
                    >
                        <Pressable
                            style={imageStyle}
                            onPress={async () => {
                                const _holo = await HoloCard(holoCardNumber, holoSecurityCode);
                                if (_holo?.messages?.length) {
                                    Alert.alert(null, t(_holo.messages[0]), [{ text: 'OK' }]);
                                } else {
                                    const holoBalance = `HOLO: $${_holo.balance.toFixed(2)}`;
                                    Alert.alert(null, holoBalance, [{ text: 'OK' }]);
                                }
                            }}
                        >
                            <Image
                                source={Images.miscl.holo_card}
                                width={HOLO_CARD_WIDTH / 2}
                                height={HOLO_CARD_HEIGHT / 2}
                                resizeMode={'contain'}
                            />
                        </Pressable>
                        <View
                            style={inputStyle}
                        >
                            <Input
                                labelContainerStyle={styles.labelContainer}
                                variant={INPUT_VARIANTS.OUTLINED}
                                title={HOLO_CARD_NUMBER}
                                placeholder={PLACEHOLDER_HOLO_CARD_NUMBER}
                                value={holoCardNumber ? '* * * * * * * * * * * * * * 6008' : holoCardNumber}
                                onChangeText={value => userDispatchRef.current({ type: USER_TYPES.SET_HOLO_CARD_NUMBER, payload: value })}
                                keyboardType={'phone-pad'}
                            />
                            <View
                                style={styles.row}
                            >
                                <View
                                    style={styles.securityCode}
                                >
                                    <Input
                                        labelContainerStyle={styles.labelContainer}
                                        variant={INPUT_VARIANTS.OUTLINED}
                                        title={HOLO_SECURITY_CODE}
                                        placeholder={PLACEHOLDER_HOLO_SECURITY_CODE}
                                        value={holoSecurityCode}
                                        onChangeText={value => userDispatchRef.current({ type: USER_TYPES.SET_HOLO_SECURITY_CODE, payload: value })}
                                        keyboardType={'phone-pad'}
                                    />
                                </View>
                                <Pressable
                                    style={styles.holoLink}
                                    onPress={() => {
                                        Navigate(navigation, t(MAP_WEB_DETAIL), {
                                            title: MAP_WEB_DETAIL,
                                            data: { uri: HoloUrl(language) },
                                        });
                                    }}
                                >
                                    <Image
                                        source={Images.miscl.HOLO_logo}
                                        width={HOLO_LOGO_WIDTH * 0.65}
                                        height={HOLO_LOGO_HEIGHT * 0.65}
                                        resizeMode={'contain'}
                                    />
                                </Pressable>
                            </View>
                        </View>
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            t,
            props,
            navigation,
            dark,
            language,
            adjHeight,
            width,
            showAds,
            data,
            username,
            holoCardNumber,
            holoSecurityCode,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    header: {
        //width: '100%'
    },
    holo: {
        //width: '60%',
    },
    labelContainer: {
        //width: '60%',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    securityCode: {
        width: '70%',
    },
    holoLink: {
        marginBottom: '6%',
    },
});
