export { GetAltitude, GetZoom } from './AltitudeZoom';
export { BusColor, BusKey, CreateBuses, DecodeBus } from './BusUtils';
export { DecodeDirections } from './DirectionUtils';
export { CreateWeather, DecodeWeather } from './WeatherUtils';
export { CustomMapStyle } from './CustomMapStyle';

export { Mode as CompassMode, ModeMap as CompassModeMap, MODES as COMPASS_MODES, NUM_MODES as NUM_COMPASS_MODES } from './CompassMode';
export { Mode as MapMode, ModeMap as MapModeMap, MODES as MAP_MODES, NUM_MODES as NUM_MAP_MODES } from './MapMode';
export { Mode as MenuMode, ModeMap as MenuModeMap, MODES as MENU_MODES, NUM_MODES as NUM_MENU_MODES } from './MenuMode';
export { Mode as TrackingMode, ModeMap as TrackingModeMap, MODES as TRACKING_MODES, NUM_MODES as NUM_TRACKING_MODES } from './TrackingMode';
export { Mode as TransitMode, ModeMap as TransitModeMap, MODES as TRANSIT_MODES, NUM_MODES as NUM_TRANSIT_MODES } from './TransitMode';

export { MapIcons } from './MapIcons';
export { MapKeys } from './MapKeys';
export { MapViews } from './MapViews';

export { WeatherAttributes, WeatherAttributeMap } from './WeatherAttributes';

const OAHU_WEST = -158.8;
const OAHU_EAST = -157.5;
const HAWAII_NORTH = 20.4;

export const IsKauai = item => {
    return item?.coordinate?.longitude && item.coordinate.longitude < OAHU_WEST ? true : false;
};

export const IsOahu = item => {
    return IsKauai(item) || (item?.coordinate?.longitude && item.coordinate.longitude > OAHU_EAST) ? false : true;
};

export const IsHawaii = item => {
    return item?.coordinate?.latitude && item.coordinate.latitude < HAWAII_NORTH ? true : false;
};

export const IsMaui = item => {
    return IsKauai(item) || IsOahu(item) || IsHawaii(item) ? false : (item?.coordinate?.latitude && item?.coordinate?.longitude) ? true : false;
};


/*
static Transform_MARKMARK(payload, type) {
    var transform = payload.map(item => {
      var result = { ...item };
      if (!item?.title) {
        result.title = result.id;
      }

      if (!result?.coordinate) {
        if (result?.lat && result?.lng) {
          result.coordinate = { latitude: result.lat, longitude: result.lng };
          delete result.lat;
          delete result.lng;
        } else if (result?.coord?.length === 2) {
          result.coordinate = { latitude: result.coord[0], longitude: result.coord[1] };
          delete result.coord;
        } else if (result?.c && result.c?.l && result.c?.g) {
          result.coordinate = { latitude: result.c.l, longitude: result.c.g };
          delete result.c;
        }
      }
      return result;
    });
    return transform.map(item => {
      var result = { ...item };
      [
        { key: 't', value: 'title' },
        { key: 'name', value: 'title' },

        { key: 'i', value: 'icon' },
        //{ key: 'r', value: 'iconColor' },
        { key: 'dif', value: 'difference' },
        { key: 'a', value: 'address' },
        { key: 'f', value: 'phone' },
        { key: 'p', value: 'timestamp' },
        { key: 'prc', value: 'price' },

        { key: 'descr', value: 'description' },
        { key: 'url', value: 'image_url' },

      ].forEach(entry => {
        if (!result[entry.value] && result[entry.key]) {
          result[entry.value] = result[entry.key];
          delete result[entry.key];
        }
      });
      if (result?.s) {
        result.coordinates = result.s.map(_v => ({ latitude: _v?.l, longitude: _v?.g/*, elevation: _v?.z* / }));
        result.coordinate = result.coordinates.length ? result.coordinates[0] : null;
        delete result.s;
      }
      if (result?.img) {
        const tokens = result.img.split('|');
        if (tokens.length === 2) {
          result.image_url = `https://s3-media${tokens[0]}.fl.yelpcdn.com/bphoto/${tokens[1]}/l.jpg`;
        } else {
          result.image_url = result.img;
        }
        delete result.img;
      }
      return result;
    });
  }
*/
