export const CustomMapStyle = (dark, showRoadLabels, showRoads) => {
    return [
        {
            featureType: 'administrative',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: showRoadLabels ? 'on' : 'off',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#FFCD00',//'#ffffff',
                },
            ],
        },
        {
            featureType: 'administrative',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#000000',
                },
            ],
        },
        {
            featureType: 'landscape.man_made',
            stylers: [
                {
                    color: dark ? '#3f3f3f' : '#7f7f7f',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            stylers: [
                {
                    color: '#007f00',
                },
            ],
        },
        {
            featureType: 'landscape.natural.landcover',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: /*dark ? '#2E433C' : */'#577B47',//'#00ff00' : '#00af00',
                },
            ],
        },
        {
            featureType: 'poi',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.icon',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'labels.text',
            stylers: [
                {
                    visibility: showRoadLabels ? 'on' : 'off',
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#007f00',
                    weight: 2,
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#00ff00',
                },
            ],
        },
        {
            featureType: 'road.highway.controlled_access',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#007f00',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#3f7f00',
                    weight: 2,
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#7fff00',
                },
            ],
        },
        {
            featureType: 'road.highway',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#3f7f00',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#7f3f00',
                    weight: 1,
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#ff7f00',
                },
            ],
        },
        {
            featureType: 'road.arterial',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#7f3f00',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: '#7f0000',
                    weight: 1,
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#ff0000',
                },
            ],
        },
        {
            featureType: 'road.local',
            elementType: 'labels.text.stroke',
            stylers: [
                {
                    color: '#7f0000',
                },
            ],
        },
        {
            featureType: 'transit',
            stylers: [
                {
                    visibility: 'off',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'geometry.fill',
            stylers: [
                {
                    color: dark ? '#000030' : '#20306A',
                },
            ],
        },
        {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [
                {
                    color: '#FFCD00',//'#ffffff',
                },
            ],
        },
        {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [
                {
                    visibility: showRoads ? 'on' : 'off',
                },
            ],
        },
    ];
};
