import { Platform } from 'react-native';
import { Splash, Login, Register, ConfirmRegister, ForgotPassword } from '../../screens';
import { Icons } from '../../media';
import { APP_NAME } from '../../constants';


export const Routes = {

    Icon: Icons.Home,

    TabArray: ['Splash'],

    Tabs: {
        Splash: {
            title: APP_NAME,
            Component: Splash,
            Icon: Platform.OS === 'web' ? Icons.Web : Platform.OS === 'android' ? Icons.Android : Platform.OS === 'ios' ? Icons.Apple : Icons.Unknown,
            children: new Set(['Login', 'Register', 'ConfirmRegister', 'ForgotPassword']),
        },
    },

    Stacks: {
        Login: {
            Component: Login,
        },
        Register: {
            Component: Register,
        },
        ConfirmRegister: {
            Component: ConfirmRegister,
        },
        ForgotPassword: {
            Component: ForgotPassword,
        },
    },

};
