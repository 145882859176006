export class Statistics {

    static GetAvailableMemory() {
        return '';
    }

    static Report(id) {
        // no-op
    }
}
