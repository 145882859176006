import { Resources } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Info,

    TabArray: ['Resources'],

    Tabs: {
        Resources: {
            Component: Resources,
            Icon: Icons.Info,
        },
    },

    Stacks: {
    },

};
