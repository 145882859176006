import React, { createContext, useContext, useReducer } from 'react';
import { Keyboard, PixelRatio, Platform, StatusBar, Vibration } from 'react-native';
import { Array2Object, Compass, Console, Geocode, Sensors } from '../utils';
import { RNW, STD_HEIGHT, STD_ASPECT } from '../constants';

const types = [
    'SET_DIMENSIONS',
    'SET_HEIGHTS',
    'SET_GEOLOCATION',
];
export const TYPES = Array2Object(types);

const SystemStateContext = createContext();
const SystemDispatchContext = createContext();

const os = Platform.OS;

const IS_ANDROID = os === RNW.ANDROID ? true : false;
const IS_IOS = os === RNW.IOS ? true : false;
const IS_WEB = os === RNW.WEB ? true : false;

const geocodeDefaults = {
    geocodeLatitude: 21.2851,//21.332493, // HNL
    geocodeLongitude: -157.8358,//-157.919771, // HNL
    geocodeAccuracy: -1,
    geocodeAltitude: null,
    geocodeAltitudeAccuracy: null,
    geocodeHeading: null,
    geocodeSpeed: null,
    geocodeTimestamp: true,//false,
};

const geocodeOptionsDefaults = {
    timeout: 30000, // 30 secs (default 10 mins)
    maximumAge: 15000, // 15 secs (default infinity: always use cache)
    enableHighAccuracy: true,
};

var systemState = {
    ...geocodeDefaults,
    os,
    isAndroid: IS_ANDROID,
    isIOS: IS_IOS,
    isWeb: IS_WEB,
    isIphoneX: IS_IOS ? null : false,
    height: 0,
    width: 0,
    scale: 1,
    fontScale: 1,
    deviceScale: 1,
    headerHeight: 0,
    tabBarHeight: 0,
    advertHeight: 0,
    pixelDensity: PixelRatio.get(),
    getPixelSize: dp => PixelRatio.getPixelSizeForLayoutSize(dp),
    getNearestPixel: dp => PixelRatio.roundToNearestPixel(dp),
    normalize: (dp, scale) => Math.round(PixelRatio.roundToNearestPixel(dp * scale)) - (IS_IOS ? 0 : 2),
    isKeyboardVisible: () => Keyboard.isVisible(),
    dismissKeyboard: () => Keyboard.dismiss(),
    scheduleKeyboardLayoutAnimation: event => Keyboard.scheduleLayoutAnimation(event),
    startVibrate: (pattern = 400, repeat = false) => Vibration.vibrate(pattern, repeat),
    stopVibrate: () => Vibration.cancel(),
    getGeocode: cb => Geocode.getCurrentPosition(
        payload => {
            Console.devLog('Geocode.getCurrentPosition', payload);
            cb(payload);
        },
        err => {
            Console.devLog('Geocode.getCurrentPosition', { err });
            cb(null, err);
        },
        geocodeOptionsDefaults,
    ),
    startGeocode: cb => Geocode.watchPosition(
        payload => {
            Console.devLog('Geocode.watchPosition', payload);
            cb(payload);
        },
        err => {
            Console.devLog('Geocode.watchPosition', { err });
            cb(null, err);
        },
        {
            ...geocodeOptionsDefaults,
            distanceFilter: 20, // 20m, approx dist walked in 15 secs
        },
    ),
    stopGeocode: id => id !== null ? Geocode.clearWatch(id) : {},
    startCompass: (degrees, cb) => Compass.start(degrees, cb),
    stopCompass: () => Compass.stop(),
    accelerometer: Sensors.accelerometer,
    gyroscope: Sensors.gyroscope,
    magnetometer: Sensors.magnetometer,
    barometer: Sensors.barometer,
    orientation: Sensors.orientation,
    gravity: Sensors.gravity,
    setUpdateIntervalForType: Sensors.setUpdateIntervalForType,
    sensorTypes: Sensors.SensorTypes,
};


// recalculations that have to be done when some display sizing/orienting in done
const updateComputedState = state => {

    const { isAndroid, isIOS, width, height, headerHeight, tabBarHeight } = state;

    state.aspect = height / width;

    state.navHeight = headerHeight + tabBarHeight;
    state.adjHeight = height - state.navHeight;

    state.isPortrait = width < height ? true : false;
    state.isLandscape = !state.isPortrait;
    state.statusBarHeight = isAndroid ? StatusBar.currentHeight : 0;

    const IPHONE_SAFE_GAP = 78;
    state.safeHeight = state.isLandscape ? 0 : isIOS ? IPHONE_SAFE_GAP : state.statusBarHeight;

    state.deviceHeight = Math.max(width, height) - state.safeHeight;

    if (state.width && state.height) {
        if (state.isIphoneX === null) {
            // https://github.com/heyman333/react-native-responsive-fontsize
            // https://github.com/ptelad/react-native-iphone-x-helper
            state.isIphoneX = false;
            if (isIOS && !Platform.isPad && !Platform.isTV) {
                [780, 812, 844, 896, 926].forEach(sz => {
                    if (state.width === sz || state.height === sz) {
                        state.isIphoneX = true;
                    }
                });
            }
        }

        // get the scale based on the "standard" device
        const getDeviceScale = (h, w) => {
            const aspect = h / w;
            var _w = w;
            var _h = h;

            if (aspect >= STD_ASPECT) {
                _h = _w * STD_ASPECT;
            } else {
                _w = _h / STD_ASPECT;
            }

            return {
                deviceWidth: _w,
                deviceHeight: _h,
                deviceScale: _h / STD_HEIGHT,
                deviceHPad: (w - _w) / 2,
            };
        };

        const {
            deviceScale,
            deviceHeight,
            deviceWidth,
            deviceHPad,
        } = getDeviceScale(state.height, state.width);

        state.deviceScale = deviceScale;
        state.deviceHeight = deviceHeight;
        state.deviceWidth = deviceWidth;
        state.deviceHPad = deviceHPad;
    }
};

// call this initially to make sure everything has a 'valid' value
updateComputedState(systemState);

const systemReducer = (state, action) => {
    const { type, payload } = action;
    var result;
    var changed = true;

    switch (type) {

        case TYPES.SET_DIMENSIONS:
            changed = state?.height !== payload?.height ||
                state?.width !== payload?.width ||
                state?.scale !== payload?.scale ||
                state?.fontScale !== payload?.fontScale
                ? true : false;
            result = {
                ...state,
                height: payload?.height,
                width: payload?.width,
                scale: payload?.scale,
                fontScale: payload?.fontScale,
            };
            break;

        case TYPES.SET_HEIGHTS:
            changed = state?.headerHeight !== payload?.headerHeight ||
                state?.tabBarHeight !== payload?.tabBarHeight
                ? true : false;
            result = {
                ...state,
                headerHeight: payload.headerHeight,
                tabBarHeight: payload.tabBarHeight,
            };
            break;

        case TYPES.SET_GEOLOCATION:
            if (payload?.coords) {
                const {
                    latitude,
                    longitude,
                    accuracy,
                    altitude,
                    altitudeAccuracy,
                    heading,
                    speed,
                } = payload.coords;
                result = {
                    ...state,
                    geocodeLatitude: latitude,
                    geocodeLongitude: longitude,
                    geocodeAccuracy: accuracy,
                    geocodeAltitude: altitude,
                    geocodeAltitudeAccuracy: altitudeAccuracy,
                    geocodeHeading: heading,
                    geocodeSpeed: speed,
                    geocodeTimestamp: payload?.timestamp,
                };
            } else {
                result = {
                    ...state,
                    ...geocodeDefaults,
                };
            }
            break;

        default:
            throw new Error(`systemReducer: Unhandled action '${type}'.`);
    }

    updateComputedState(result);

    Console[changed ? 'LOG' : 'log'](`systemReducer[${type}] (${JSON.stringify(payload)})`);
    Console.trace('systemReducer', { state, result });

    return result;
};

const NAME = 'SystemProvider';


export const SystemProvider = props => {

    const {
        children,
    } = props;

    const [state, dispatch] = useReducer(systemReducer, systemState);

    Console.stack(NAME, props, { state });

    return (
        <SystemStateContext.Provider value={state}>
            <SystemDispatchContext.Provider value={dispatch}>
                {children}
            </SystemDispatchContext.Provider>
        </SystemStateContext.Provider>
    );
};

export const useSystemState = () => useContext(SystemStateContext);
export const useSystemDispatch = () => useContext(SystemDispatchContext);
