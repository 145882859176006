import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import de from './de'; // german
import en from './en'; // english
import es from './es'; // spanish
import fr from './fr'; // french
import haw from './haw'; // hawaiian
import hi from './hi'; // hindi
import it from './it'; // italian
import ja from './ja'; // japanese
import ko from './ko'; // korean
import pt from './pt'; // portuguese
import ro from './ro'; // romanian
import ru from './ru'; // russian
import zh from './zh'; // chinese
import ar from './ar'; // arabic

// https://www.w3.org/International/articles/language-tags/
// https://translate.i18next.com/

// MARKMARK: Should get this from the device
import { LANGUAGE } from '../constants';
const lng = LANGUAGE.split('_')[0];

i18n
  .use(initReactI18next)
  .init({
    resources: {
      de,
      en,
      es,
      fr,
      haw,
      hi,
      it,
      ja,
      ko,
      pt,
      ro,
      ru,
      zh,
      ar,
    },
    lng,
    fallbackLng: lng,
    debug: false,
    keySeparator: false,
    interpolation: { escapeValue: false },
    compatibilityJSON: 'v3',
  });

export default i18n;
