const WIDGET_HTML = '<!DOCTYPE html><html><head></head><body><link href="//www.surf-forecast.com/stylesheets/widget.css" media="screen" rel="stylesheet" type="text/css" /><div class="wf-width-cont surf-fc-widget"><div class="widget-container"><div class="external-cont"><iframe class="surf-fc-i" allowtransparency="true" src="//www.surf-forecast.com/breaks/<SURF>/forecasts/widget/<UNITS>" scrolling="no" frameborder="0" marginwidth="0" marginheight="0"></iframe><div class="footer"><a class="logo" href="//www.surf-forecast.com/"><img src="//www.surf-forecast.com/images/widget.png" width="1" height="1" /></a><div class="about" id="cmt">More <a href="//www.surf-forecast.com/breaks/<SURF>">Detailed Surf Conditions &amp; Webcams for&nbsp;<NAME></a> <nobr>at&nbsp;<a href="//www.surf-forecast.com/">surf-forecast.com</a></nobr>.</div></div></div></div></div></body></html>';
const FEED_HTML = '<!DOCTYPE html><html><head></head><body><link href="//www.surf-forecast.com/stylesheets/feed.css" media="screen" rel="stylesheet" type="text/css" /><div id="wf-weatherfeed"><iframe style="overflow:hidden;border:none;" allowtransparency="true" height="272" width="469" src="//www.surf-forecast.com/breaks/<SURF>/forecasts/feed/<UNITS>" scrolling="no" frameborder="0" marginwidth="0" marginheight="0"><p>Your browser does not support iframes.</p></iframe><div id="wf-link"><a href="https://www.surf-forecast.com/"><img alt="Surf Forecast" src="//www.surf-forecast.com/images/feed/surflogo-150.jpg"/></a><p id="cmt">More <a href="//www.surf-forecast.com/breaks/<SURF>">Detailed Surf Conditions &amp; Webcams for&nbsp;<NAME></a> <nobr>at&nbsp;<a href="//www.surf-forecast.com/">surf-forecast.com</a></nobr>.</p><div style="clear: both;"></div></div></div></body></html>';

const isMetricLanguage = language => {
    return language === 'en_us' || language === 'haw_hi' ? false : true;
};

const getHtml = (html, id, name, language) => {
    return html
        .replace(/<SURF>/g, id)
        .replace(/<NAME>/g, name)
        .replace(/<UNITS>/g, isMetricLanguage(language) ? 'm' : 'i');
};

export const SURF_FEED_HEIGHT = 272 * 1.1;//272 + 11;
export const SURF_FEED_WIDTH = 469 * 1.5;

export const MAX_SURF_WIDGET_WIDTH = 753 + 80;

export const SurfBaseUrl = () => {
    return 'https://www.surf-forecast.com';
};

export const SurfBreaksUrl = id => {
    return `${SurfBaseUrl()}/breaks/${id}`;
};

export const SurfWidgetHtml = (id, name, language) => {
    return getHtml(WIDGET_HTML, id, name, language);
};

export const SurfFeedHtml = (id, name, language) => {
    return getHtml(FEED_HTML, id, name, language);
};
