// Used to convert between matter.js speed and meters per second (m/s).
const PARTICLE_SPEED = 0.05;

const OPTIONS = {
    friction: 0,
    frictionAir: 0,
    frictionStatic: 0,
    restitution: 1,
};

class ParticleEngineUtils {

    // https://github.com/scipy/scipy/blob/4833a293e7790dd244b2530b74d1a6718cf385d0/scipy/stats/_continuous_distns.py#L5305
    static MaxwellPDF(x, mass, temp) {
        const m = mass / 1000;
        const k = 8.61733262145 * (10 ** (-5));
        const T = temp;
        const a = Math.sqrt(k * T / m);

        return Math.sqrt(2 / Math.PI) * (
            (
                (x ** 2) * Math.exp(-(x ** 2) / (2 * (a ** 2)))
            ) / (a ** 3)
        );
    }

    static IsAboveEscapeSpeed(particle, escapeSpeed) {
        // Convert matter.js speed back to the meters per second (m/s)
        // unit we're using in the graph.
        let molecularSpeed = particle.speed / PARTICLE_SPEED;

        // If the particle's current speed is 0, that means it hasn't
        // started moving yet. In this case, just use the molecularSpeed
        // we've assigned it on creation.
        if (particle.speed === 0) {
            molecularSpeed = particle.molecularSpeed;
        }

        return molecularSpeed >= escapeSpeed;
    }

    /**
     * Scan the given particles and let the appropriate ones escape.
     */
    static LetParticlesEscape(particles, escapeSpeed) {
        particles.forEach(gasParticles => {
            gasParticles.forEach(p => {
                if (ParticleEngineUtils.IsAboveEscapeSpeed(p, escapeSpeed)) {
                    p.collisionFilter.category = 0;
                } else {
                    p.collisionFilter.category = 1;
                }
            });
        });
    }

    /**
     * Adjust the velocity of a particle based on the initial speed we
     * assigned it, to make sure it doesn't lose energy.
     *
     * Based on:
     *   https://jsfiddle.net/xaLtoc2g/
     */
    static AdjustE(engine, p) {
        const baseSpeed = p.molecularSpeed * PARTICLE_SPEED;
        if (p.speed !== 0) {
            let speedMultiplier = baseSpeed / p.speed;
            engine.setVelocity(p, {
                x: p.velocity.x * speedMultiplier,
                y: p.velocity.y * speedMultiplier,
            });
        }
    }

    static UpdateParticleSpeed(engine, p, molecularSpeed) {
        p.molecularSpeed = molecularSpeed;
        const baseSpeed = p.molecularSpeed * PARTICLE_SPEED;
        let speedMultiplier = baseSpeed / p.speed;
        engine.setVelocity(p, {
            x: p.velocity.x * speedMultiplier,
            y: p.velocity.y * speedMultiplier,
        });
    }
}


export class ParticleEngine {

    constructor(engine, activeGases = [], gasProportions = [], allowEscape = false, escapeSpeed = 1500, temperature = 300) {

        this.engine = engine;

        this.activeGases = activeGases;
        this.gasProportions = gasProportions;
        this.allowEscape = allowEscape;
        this.escapeSpeed = escapeSpeed;
        this.temperature = temperature;

        this.particles = null;

        const { width, height } = this.engine.dimensions();
        this.width = width;
        this.height = height;

        //console.log('particles.ctor', { engine, activeGases, gasProportions });
    }

    initialize() {

        this.engine.gravity(0, 0);
        this.engine.timeScale(2);

        /* eslint-disable consistent-this */
        const me = this;

        let counter0 = 0;
        this.engine.subBeforeUpdate(event => {
            if (event.timestamp >= counter0 + 50) {//500
                me.particles.forEach(gasParticles => {
                    gasParticles.forEach(p => {
                        ParticleEngineUtils.AdjustE(me.engine, p);
                    });
                });
                counter0 = event.timestamp;
            }
        });

        this.#refresh();

        let counter1 = 0;
        this.engine.subAfterUpdate(event => {
            if (event.timestamp >= counter1 + 20) {//200
                if (me.allowEscape) {
                    me.removeEscapedParticles();
                    ParticleEngineUtils.LetParticlesEscape(me.particles, me.escapeSpeed);
                }
                me.updateParticleSpeedDistribution();
                counter1 = event.timestamp;
            }
        });

        //console.log('particles.init', { me });
    }

    isOutOfBounds(pos) {
        return (pos.x < 0 || pos.y < 0 || pos.x > this.width || pos.y > this.height);
    }

    removeEscapedParticles() {
        /* eslint-disable consistent-this */
        const me = this;

        // Record the current particle counts in this callback,
        // to make it easy to determine whether any have escaped,
        // based on the criteria below.
        const currentParticleCounts = new Map();
        this.particles.forEach((gasParticles, idx) => {
            currentParticleCounts.set(idx, gasParticles.length);
        });

        this.particles.forEach(gasParticles => {
            gasParticles.forEach((p, idx, array) => {
                if (p.collisionFilter.category === 0 && me.isOutOfBounds(p.position)) {
                    // If this particle is set to leave the scene,
                    // and it's already left the scene,
                    // remove it from the world and this array.
                    me.engine.remove(p);
                    array.splice(idx, 1);
                }
            });
        });

        this.particles.forEach((gasParticles, idx) => {
            // If no particles escaped, we don't need to call onParticleCountUpdated.
            if (currentParticleCounts.get(idx) !== gasParticles.length) {
                const proportion = gasParticles.length / me.initialParticleCounts[idx];
                me.onParticleCountUpdated(idx, proportion * 100);
            }
        });
    }

    /**
     * Update particle speeds based on the new proportion,
     * and the original distribution bucket.
     */
    updateParticleSpeeds(particles, distributionBucket, proportion) {
        let pIdx = 0;
        distributionBucket.forEach(bucket => {
            const particlesAtThisSpeed = Math.round(bucket.particleCount * (proportion / 100));

            // If there are some particles set to this speed bucket,
            // update the particles array.
            if (particlesAtThisSpeed > 0) {
                for (let i = 0; i < particlesAtThisSpeed; i++) {
                    const idx = pIdx + i;
                    if (idx > particles.length) {
                        continue;
                    }
                    const p = particles[pIdx + i];
                    if (p) {
                        ParticleEngineUtils.UpdateParticleSpeed(this.engine, p, bucket.speed);
                    }
                }
                pIdx += particlesAtThisSpeed;
            }
        });

        return particles;
    }

    /**
     * Adjust each particle's speed
     * to keep the distributions even as they escape.
     */
    updateParticleSpeedDistribution() {
        /* eslint-disable consistent-this */
        const me = this;
        this.particles.forEach((gasParticles, idx) => {
            const gasParticleCount = gasParticles.length;
            const initialCount = me.initialParticleCounts[idx];
            if (initialCount !== gasParticleCount) {
                me.updateParticleSpeeds(
                    gasParticles,
                    me.distributionBuckets[idx],
                    me.gasProportions[idx]);
            }
        });
    }

    makeParticle(gas, molecularSpeed, colorOverride = null) {
        const p = this.engine.addCircle(null, -1, -1, gas.particleSize, colorOverride ? colorOverride : gas.color, OPTIONS);
        const { body } = p.body;

        this.engine.setInertia(p.body, Infinity);
        body.molecularSpeed = molecularSpeed;
        body.collisionFilter.category =
            this.allowEscape && ParticleEngineUtils.IsAboveEscapeSpeed(body, this.escapeSpeed)
                ? 0 : 1;

        const direction = Math.random() * Math.PI * 2;
        body.direction = direction;

        this.engine.setVelocity(body, {
            x: Math.sin(direction) * (PARTICLE_SPEED * molecularSpeed),
            y: Math.cos(direction) * (PARTICLE_SPEED * -molecularSpeed),
        });

        return body;
    }

    /**
     * Generate Maxwell PDF distribution buckets for the given gas type.
     *
     * Returns an array of the numbers of particles
     * we want to create at each speed interval.
     */
    generateBuckets(gas) {
        const distributionBuckets = [];
        for (let i = 0; i < 2100; i += 20) {
            let particleCount = ParticleEngineUtils.MaxwellPDF(i / (460 / 1.5), gas.mass, this.temperature);
            particleCount *= 2;
            particleCount = Math.round(particleCount);
            distributionBuckets.push({ speed: i, particleCount: particleCount });
        }
        return distributionBuckets;
    }

    #refresh() {
        const me = this;

        if (this.particles) {
            this.particles.forEach(gasParticles => {
                me.engine.remove(gasParticles);
            });
        }

        this.distributionBuckets = [];
        const initialParticleCounts = [];
        this.activeGases.forEach(gas => {
            const buckets = me.generateBuckets(gas);
            const totalParticles = buckets.reduce((prev, cur) => { return prev + cur.particleCount; }, 0);
            initialParticleCounts.push(totalParticles);
            me.distributionBuckets.push(buckets);
        });

        this.initialParticleCounts = initialParticleCounts;
        this.particles = this.#drawParticles(
            this.activeGases,
            this.gasProportions,
            this.distributionBuckets);

        this.particles.forEach(gasParticles => { me.engine.add(gasParticles); });
    }

    #drawParticles(activeGases = [], gasProportions = [], distributionBuckets) {
        /* eslint-disable consistent-this */
        const me = this;
        const particles = [];

        activeGases.forEach((gas, idx) => {
            const proportion = gasProportions[idx];
            const buckets = distributionBuckets[idx];

            const p = [];

            buckets.forEach(bucket => {
                // The number of particles to create for a given
                // bucket depends on the pre-calculated distribution
                // bucket as well as this gas's proportion state.
                const particleCount = bucket.particleCount * (proportion / 100);
                for (let i = 0; i < particleCount; i++) {
                    p.push(me.makeParticle(gas, bucket.speed, i === 0 ? null : 'yellow'));
                }
            });

            particles[idx] = p;
        });

        //console.log('particles', particles);
        return particles;
    }
}
