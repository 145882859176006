import FileSaver from 'file-saver';
import { FILE_ENCODING } from './FileEncoding';

const NAME = 'File.web';


// const type = 'text/plain;charset=utf-8';
// const type = 'application/vnd.android.package-archive';


export class File {
    static SaveAs(blob, filename) {
        console.log(`${NAME}.SaveAs`, { filename });
        FileSaver.saveAs(blob, filename);
    }

    static Path(_) {
        return null;
    }

    static /*async*/ Exists(path) {
        //console.log(`${NAME}.Exists`, { path });
        return false;
    }

    static /*async*/ ListAssets(path) {
        //console.log(`${NAME}.ListAssets`, { path });
        return null;
    }

    static /*async*/ ReadAsset(filename, encoding = FILE_ENCODING.UTF8) {
        //console.log(`${NAME}.ReadAsset`, { filename, encoding });
        try {
            return require(`../../public/${filename}`);
        } catch (e) {
            console.error(`File.ReadAsset(${filename})`, e.message);
            return null;
        }
    }

    static /*async*/ ReadPath(path, encoding = FILE_ENCODING.BASE64) {
        console.error(`${NAME}.ReadPath`, { path, encoding });
        return null;
    }

    static /*async*/ Read(filename, encoding = FILE_ENCODING.BASE64) {
        console.error(`${NAME}.Read`, { filename, encoding });
        return null;
    }

    static /*async*/ Write(filename, data, encoding = FILE_ENCODING.BASE64) {
        console.error(`${NAME}.Write`, { filename, encoding });
    }

    static /*async*/ Delete(path) {
        console.error(`${NAME}.Delete`, { path });
    }
}
