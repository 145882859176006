// https://developers.google.com/web/updates/2016/01/mediarecorder

import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { Console } from '../Console';
import { Validate } from '../Validate';

const NAME = 'AudioRecord';

(async () => await register(await connect()))();


export class AudioRecord {

    constructor(options, onStart, onStop, onInfo) {
        Console.log(`${NAME}.constructor`);

        this.options = options;
        this.onStart = onStart;
        this.onStop = onStop;

        this.mediaStream = null;
        this.mediaRecorder = null;
        this.chunks = [];

        if (!MediaRecorder.isTypeSupported('audio/wav')) {
            const msg = `${NAME} mimeType "audio/wav" not supported`;
            Console.error(msg);
            if (onInfo) {
                onInfo([msg]);
            }
        }
    }

    reset() {
        Console.log(`${NAME}.reset`);
        this.mediaStream = null;
        this.mediaRecorder = null;
        this.chunks = [];
    }

    recorder() {
        if (!Validate.isValid(this.mediaRecorder)) {
            Console.log(`${NAME}.recorder (null value)`);
        }
        return this.mediaRecorder;
    }

    stream() {
        if (!Validate.isValid(this.mediaStream)) {
            Console.log(`${NAME}.stream (null value)`);
        }
        return this.mediaStream;
    }

    create(stream) {

        if (!Validate.isValid(stream)) {
            Console.error(`${NAME}.create invalid stream`);
        }
        Console.log(`${NAME}.create`);

        this.mediaStream = stream;
        this.mediaRecorder = new MediaRecorder(this.mediaStream, this.options);

        this.mediaRecorder.ondataavailable = event => {
            // simulate onstart, as it is not supported by the extended lib
            if (Validate.isValid(this.onStart) && !this.chunks.length) {
                Console.log(`${NAME}.onstart`);
                this.onStart();
            }
            Console.log(`${NAME}.ondataavailable`, { event });
            this.chunks.push(event.data);
        };
        this.mediaRecorder.onstop = async () => {
            Console.log(`${NAME}.onstop`);
            if (Validate.isValid(this.onStop)) {
                const buffer = await this.chunks[0].arrayBuffer();
                if (buffer) {
                    const data = new Uint8Array(buffer);
                    this.onStop({ data });
                }
            }
            this.chunks = [];
        };
        this.mediaRecorder.onpause = () => Console.log(`${NAME}.onpause`);
        this.mediaRecorder.onresume = () => Console.log(`${NAME}.onresume`);
        this.mediaRecorder.onerror = error => Console.error(`${NAME}.onerror`, { error });
        this.mediaRecorder.onwarning = warning => Console.warn(`${NAME}.onwarning`, { warning });

        return this.stream();
    }
}
