import React from 'react';
import { Text, View } from 'react-native';
import { Console } from './Console';


export class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo,
        });
        Console.error(errorInfo, error);
    }

    render() {
        if (this.state.errorInfo) {
            return (
                <View>
                    <Text>
                        {this.state.error && this.state.error.toString()}
                    </Text>
                </View>
            );
        }
        return this.props.children;
    }
}
