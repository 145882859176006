import React, { /*useCallback,*/ useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, /*SmartCamera,*/ Screen, Text, TEXT_VARIANTS, View } from '../components';
import Svg, { G } from '../svg';
import { Signs } from '../media';
import { useAppState, useSystemState } from '../context';
import { Console, Optional, Validate } from '../utils';
import { DEV_MODE } from '../constants';

const NAME = 'Camera';

const EMPTY_PREDICTION = ' ';

const symbolKeys = Object.keys(Signs.get(true).get(true));
var symbols = symbolKeys.filter(key => key.charAt(0) === '_');
// MARKMARK: take off the last '0', which the nn isn't trained for yet
symbols.splice(36);
/*
[
    '_', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H',
    'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q',
    'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
    '1', '2', '3', '4', '5', '6', '7', '8', '9',
];
*/
const SHOW_SIGN_COLORS = false;

class CameraUtils {
    static IsFront(cameraType, frontCamera) {
        var isFrontCamera = frontCamera;
        if (cameraType === 'front') {
            isFrontCamera = true;
        } else if (cameraType === 'back') {
            isFrontCamera = false;
        }
        return isFrontCamera;
    }
}


export const Camera = props => {

    const {
        cameraType,
    } = props;

    const { deviceScale } = useSystemState();
    const { DEFAULT, dark, frontCamera } = useAppState();
    const { CAMERA_WIDTH, CAMERA_HEIGHT, SIGN_SVG_SIZE } = DEFAULT;

    const [train, setTrain] = useState(false);
    const setTrainRef = useRef(setTrain);

    const [index, setIndex] = useState(symbols.length);
    const setIndexRef = useRef(setIndex);

    const [record, setRecord] = useState(false);
    const setRecordRef = useRef(setRecord);

    const [prediction, setPrediction] = useState(EMPTY_PREDICTION);
    const setPredictionRef = useRef(setPrediction);

    useEffect(
        () => {
            if (index < symbols.length) {
                const _prediction = symbols[index];
                Console.LOG(`${NAME} useEffect prediction`, { index, _prediction });
                setPredictionRef.current(_prediction);
            }
        },
        [
            index,
            setPredictionRef,
        ],
    );
/*
    const onRecognize = useCallback(
        event => {
            const _prediction = Validate.isValidNonEmptyString(event?.classification)
                ? event.classification
                : EMPTY_PREDICTION;
            Console.log(`${NAME}.onRecognize`, { event, _prediction });
            setPredictionRef.current(_prediction);
        },
        [
            setPredictionRef,
        ],
    );
*/
    const renderCamera = useMemo(
        () => {
            const isFront = CameraUtils.IsFront(cameraType, frontCamera);
            Console.devLog(`${NAME}.renderCamera`, { cameraType, frontCamera, isFront, record, index });
            return null;/** MARKMARK REMOVE SMARTCAMERA (
                <SmartCamera
                    type={isFront ? 0 : 1}
                    capture={(isFront && record) ? symbols[index] : EMPTY_PREDICTION}
                    onRecognize={onRecognize}
                />
            );*/
        },
        [
            cameraType,
            frontCamera,
            index,
            record,
            //onRecognize,
        ],
    );

    const renderTraining = useMemo(
        () => {
            Console.log(`${NAME}.renderTraining`);
            return (
                <>
                    {
                        Optional(DEV_MODE, (
                            <Button
                                value={'Predict'}
                                onPress={() => setTrainRef.current(false)}
                            />
                        ))
                    }
                    <Button
                        value={symbols[index]}
                        onPress={() => setIndexRef.current((index + 1) % symbols.length)}
                    />
                    <Button
                        value={record ? 'Stop' : 'Start'}
                        onPress={() => setRecordRef.current(!record)}
                    />
                </>
            );
        },
        [
            index,
            record,
            setIndexRef,
            setRecordRef,
            setTrainRef,
        ],
    );

    const renderPrediction = useMemo(
        () => {
            const sign = Signs.get(dark).get(SHOW_SIGN_COLORS)[prediction === EMPTY_PREDICTION || prediction === '__' ? '__' : `_${prediction}`];
            const validSign = Validate.isValid(sign);
            const validHand = validSign && Validate.isValid(sign?.Hand);
            const validText = validSign && Validate.isValid(sign?.Text);
            const validSymbol = validSign && Validate.isValid(sign?.Symbol);
            Console.log(`${NAME}.renderPrediction`, { prediction, sign, validSign, validHand, validText, validSymbol });

            const svgSize = SIGN_SVG_SIZE * deviceScale;

            const textWidth = {
                //width: Validate.isValid(sign?.Symbol) ? svgSize : '100%',
                height: svgSize,
                //borderColor: Colors.colors.magenta,
                //borderWidth: 1,
            };

            const svgStyle = {
                width: svgSize,
                height: svgSize,
                //borderColor: Colors.colors.cyan,
                //borderWidth: 1,
            };
            const handSvgStyle = {
                width: 1.5 * svgSize,
                height: 1.5 * svgSize,
                //borderColor: Colors.colors.yellow,
                //borderWidth: 1,
            };

            return (
                <>
                    {
                        Optional(false && DEV_MODE && frontCamera, (
                            <Button
                                value={'Train'}
                                onPress={() => setTrainRef.current(true)}
                            />
                        ))
                    }
                    {
                        Optional(validText, (
                            <View
                                value={'CameraText'}
                                style={svgStyle}
                            >
                                <Svg
                                    height={'100%'}
                                    width={'100%'}
                                >
                                    <G
                                        scale={2.5 * deviceScale}
                                        x={12.5}
                                        y={35}
                                    >
                                        {sign?.Text}
                                    </G>
                                </Svg>
                            </View>
                        ),
                            (
                                <View
                                    value={'CameraPrediction'}
                                    style={[styles.text, textWidth]}
                                >
                                    <Text
                                        variant={TEXT_VARIANTS.TITLE}
                                        value={prediction}
                                        textAlign={'center'}
                                    />
                                </View>
                            ))
                    }
                    {
                        Optional(validSymbol, (
                            <View
                                value={'CameraSymbol'}
                                style={svgStyle}
                            >
                                <Svg
                                    height={'100%'}
                                    width={'100%'}
                                >
                                    <G
                                        scale={1.25 * deviceScale}
                                        x={5}
                                        y={40}
                                    >
                                        {sign?.Symbol}
                                    </G>
                                </Svg>
                            </View>
                        ))
                    }
                    {
                        Optional(validHand, (
                            <View
                                value={'CameraHand'}
                                style={handSvgStyle}
                            >
                                <Svg
                                    height={'100%'}
                                    width={'100%'}
                                >
                                    <G
                                        scale={0.475 * deviceScale}
                                        x={7.5}
                                        y={62.5}
                                    >
                                        {sign?.Hand}
                                    </G>
                                </Svg>
                            </View>
                        ))
                    }
                </>
            );
        },
        [
            SIGN_SVG_SIZE,
            dark,
            deviceScale,
            frontCamera,
            prediction,
            setTrainRef,
        ],
    );

    Console.stack(NAME, props, { CAMERA_WIDTH, CAMERA_HEIGHT, SIGN_SVG_SIZE, deviceScale, dark, frontCamera, train, index, record, prediction });

    return useMemo(
        () => {
            const cameraStyle = {
                width: CAMERA_WIDTH * deviceScale,
                height: CAMERA_HEIGHT * deviceScale,
            };
            Console.devLog(`${NAME} render`, { deviceScale, CAMERA_HEIGHT, CAMERA_WIDTH, cameraStyle });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    mainHeight={CAMERA_HEIGHT * deviceScale}
                    mainStyle={cameraStyle}
                    mainView={renderCamera}
                    footerHeight={-1}
                    footerStyle={styles.controls}
                    footerView={Optional(frontCamera && train, renderTraining, renderPrediction)}
                />
            );
        },
        [
            props,
            CAMERA_HEIGHT,
            CAMERA_WIDTH,
            deviceScale,
            frontCamera,
            train,
            renderCamera,
            renderTraining,
            renderPrediction,
        ],
    );
};

const styles = StyleSheet.create({
    controls: {
        flexDirection: 'row',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    hand: {
        width: '100%',
        height: '100%',
    },
    text: {
        alignItems: 'center',
        justifyContent: 'center',
    },
});

export const SignCamera = props => {
    return Camera({ ...props, cameraType: 'front' });
};

export const LookCamera = props => {
    return Camera({ ...props, cameraType: 'back' });
};
