import { API } from '../aws';
import { Console } from '../utils';

const NAME = 'artist';


export const artist = async (user, prompt, size = 'SMALL', n = 1) => {

    if (!prompt?.length) {
        Console.warn(`${NAME} bad input`, { prompt });
        return null;
    }

    try {
        Console.log(`${NAME} enter`, { prompt, size, user });

        var body = {
            command: 'pic',
            prompt,
            size,
            n,
            user,
        };

        const result = await API.chat(body);
        Console.LOG(`${NAME} result`, { prompt, size, n, result });

        return result;

    } catch (error) {
        Console.error(`${NAME} error`, error);
        return null;
    }
};
