let AMPLIFY = null;

export class Amplify {
    static Set(amplify) {
        if (!AMPLIFY && amplify) {
            AMPLIFY = amplify;
        }
    }

    static Get() {
        return AMPLIFY;
    }
}
