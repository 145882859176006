import React from 'react';
import { StyleSheet } from 'react-native';
import { Slider as RNESlider } from 'react-native-elements';
import { Text } from './Text';
import { View } from './View';
import { Console, Optional, Validate } from '../utils';

const NAME = 'Slider';


export const Slider = props => {

    const {
        label,
        textVariant,
        sliderStyle,
        trackStyle,
        thumbStyle,
        textStyle,
        viewStyle,
        enabled,
        onValueChange,
        minimumValue,
        maximumValue,
        step,
    } = props;

    const disabled = Validate.isValid(enabled) ? !enabled : false;

    Console.stack(`${NAME}[${label}]`, props, { disabled });

    return (
        <View
            value={`${label}Slider`}
            style={[styles.view, viewStyle]}
        >
            {Optional(Validate.isValidNonEmptyString(label), (
                <Text
                    value={label}
                    variant={textVariant}
                    style={[styles.text, textStyle]}
                />
            ))}
            <RNESlider
                onValueChange={onValueChange}
                minimumValue={minimumValue}
                maximumValue={maximumValue}
                step={step}
                style={[styles.slider, sliderStyle]}
                trackStyle={[styles.track, trackStyle]}
                thumbStyle={[styles.thumb, thumbStyle]}
                disabled={disabled}
                debugTouchArea={false}
                allowTouchTrack={true}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    view: {
        alignItems: 'stretch',
        justifyContent: 'center',
        width: '100%',
    },
    text: {
        marginHorizontal: 10,
    },
    slider: {
    },
    track: {
    },
    thumb: {
    },
});
