import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { /*Alert,*/ StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { WebView } from 'react-native-webview';
import { GoogleMapView, LaunchCamera, LaunchGallery } from './platform';
import { BusColor, BusKey, CompassMode, DecodeDirections, MapIcons, MapKeys, MapMode, MenuMode, TrackingMode, TransitMode, TRACKING_MODES, TRANSIT_MODES, NUM_TRACKING_MODES, NUM_TRANSIT_MODES } from '../map';
import { Gradient } from './Gradient';
import { Image } from './Image';
import { Pressable } from './Pressable';
import { Text } from './Text';
import { View } from './View';
import { useAppState, useOahuState, useOahuDispatch, useSystemState, useSystemDispatch, OAHU_TYPES, SYSTEM_TYPES } from '../context';
import { Icons, Icon2Svg, MATERIAL_COMMUNITY } from '../media';
import { Colors } from '../styles';
import { Console, Numbers, Optional, Validate } from '../utils';

const NAME = 'DynamicMap';

const YOU_ARE_HERE = 'You are here';
const CENTER_ZOOM = 14.5;

const MAP_ZOOM_THRESHOLD = 11.0;
const MAP_VIEW_THRESHOLD = 10.0;
const MAP_ZONE_THRESHOLD = 8.0;

const LOCATIONS_CLUSTER_THRESHOLD = 200;

const EmojiNumbers = ['0️⃣', '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣'];

const NonDestinationTypes = new Set(['biki', 'busStop', 'surf', 'user', 'weather']);

class DynamicMapUtils {

    static SetValue(target, source, key, value = null) {
        if (Validate.isValid(source) && Validate.isValid(source[key])) {
            target[key] = source[key];
        } else if (Validate.isValid(value)) {
            target[key] = value;
        }
    }

    static InitializeMarker(input, isWeb, icon = null, color = null, callout_props = null) {

        // NATIVE: https://github.com/react-native-maps/react-native-maps/blob/master/docs/marker.md
        // WEB:    https://react-google-maps-api-docs.netlify.app/#marker

        const { id, title, coordinate, contact } = input;
        const { latitude, longitude } = coordinate;

        var result = {
            data: input,
            title,
        };

        // common to web and native
        DynamicMapUtils.SetValue(result, input, 'draggable', false);
        DynamicMapUtils.SetValue(result, input, 'opacity', 1.0);
        DynamicMapUtils.SetValue(result, input, 'zIndex', 0.0);

        if (isWeb) {
            result.id = id;
            result.position = { lat: latitude, lng: longitude };
            if (input?.image?.uri) {
                result.icon = {
                    url: input.image.uri,
                    anchor: { x: 25, y: 25 },
                };
            }
            if (icon) {
                result.icon = {
                    path: Icon2Svg.get(icon),
                    fillOpacity: 1.0,
                    scale: 1.0,
                    strokeColor: 'black',
                    strokeWeight: 0,
                    anchor: { x: 0.5, y: 0.5 },
                };
                if (Validate.isValid(color)) {
                    result.icon.fillColor = color;
                }
            }
            /** /
            animation, // Animation
            clusterer, // Clusterer
            cursor, // string
            label: 'LABEL', // string|MarkerLabel
            noClustererRedraw, // boolean
            options,
            shape,
            /**/
        } else {
            result.id = id; // MARKMARK: This is not req'd for native, but added for generic referencing like web
            result.identifier = id;
            result.coordinate = coordinate;
            if (Validate.isValid(input?.image)) {
                const image_props = {
                    source: input.image,
                    width: 50,
                    height: 50,
                    resizeMode: 'contain',
                };
                result.children = <Image {...image_props} />;
            }
            if (Validate.isValid(contact?.icon) || Validate.isValid(icon)) {
                var icon_props = {
                    type: MATERIAL_COMMUNITY,
                    name: contact?.icon ? contact.icon : icon,
                    /*
                    iconStyle: {
                        fontSize: 0.05,
                        opacity: 1.0,
                    },
                    */
                };
                if (Validate.isValid(contact?.color) || Validate.isValid(color)) {
                    icon_props.color = contact?.color ? contact.color : color;
                }
                result.children = <Icon {...icon_props} />;
            }
            //result.anchor = { x: 25, y: 25 };
            /*
            anchor: marker?.anchor ? marker.anchor : { x: 0.5, y: 0.5 },
            calloutAnchor: marker?.calloutAnchor ? marker.calloutAnchor : { x: 0.5, y: 0 },
            calloutOffset: marker?.calloutOffset ? marker.calloutOffset : { x: 0, y: 0 },
            centerOffset: marker?.centerOffset ? marker.centerOffset : { x: 0, y: 0 },
            // description: 'DESCRIPTION',
            flat: marker?.flat ? true : false,
            // icon, // NOTE: conflict with web marker.icon
            isPreselected: marker?.isPreselected ? true : false,
            pinColor: marker?.pinColor ? marker.pinColor : marker?.color ? marker.color : '#FF0000',
            rotation: marker?.rotation ? marker.rotation : 0,
            stopPropagation: marker?.stopPropagation ? true : false,
            tappable: marker?.tappable === false ? false : true,
            tracksInfoWindowChanges: marker?.tracksInfoWindowChanges ? true : false,
            tracksViewChanges: marker?.tracksViewChanges === false ? false : true,
            */
        }

        if (callout_props) {
            result.callout_props = callout_props;
        }

        return result;
    }

    static InitializeCallout(details, calloutStyle, backgroundColor) {
        return !details?.length ? <></> : {
            tooltip: true,
            children: (
                <View
                    style={[calloutStyle, { backgroundColor }]}
                >
                    {details.map((detail, detailIndex) => <Text key={`detail_${detailIndex}`} {...detail} />)}
                </View>
            ),
        };
    }

    static InitializeSurfCallout(key, title, language, source, dimensions) {
        const surfLogoStyle = dimens => ({
            position: 'absolute',
            bottom: dimens.height * 0.185,
            left: dimens.width * 0.02,
            width: dimens.width * 0.16,
            height: dimens.height * 0.1,
            //backgroundColor: 'blue',
            //opacity: 0.2,
        });
        const surfBreaksStyle = (dimens, short) => ({
            position: 'absolute',
            bottom: dimens.height * (short ? 0.16 : 0.10),
            left: dimens.width * 0.19,
            width: dimens.width * 0.28,
            height: dimens.height * (short ? 0.1 : 0.17),
            //backgroundColor: 'red',
            //opacity: 0.5,
        });
        const surfUrlStyle = (dimens, short) => ({
            position: 'absolute',
            bottom: dimens.height * (short ? 0.1 : 0.04),
            left: dimens.width * 0.21,
            width: dimens.width * 0.15,
            height: dimens.height * 0.05,
            //backgroundColor: 'green',
            //opacity: 0.5,
        });
        const short = title?.length <= 18 ? true : false;
        return !key?.length || !title?.length || !language?.length ? <></> : {
            tooltip: true,
            children: (
                <View
                    style={dimensions}
                >
                    <WebView
                        source={{
                            baseUrl: source.baseUrl(),
                            html: source.html(key, title, language),
                        }}
                        originWhiteList={['*']}
                    />
                    <Pressable
                        style={surfLogoStyle(dimensions)}
                        onPress={() => {
                            console.log('surfBaseUrl');
                            /*
                            Navigate(navigation, t(MAP_WEB_DETAIL), {
                                title: MAP_WEB_DETAIL,
                                data: { uri: SurfBaseUrl() },
                            });
                            */
                        }}
                    />
                    <Pressable
                        style={surfBreaksStyle(dimensions, short)}
                        onPress={() => {
                            console.log('SurfBreaksUrl');
                            /*
                            Navigate(navigation, t(MAP_WEB_DETAIL), {
                                title: MAP_WEB_DETAIL,
                                data: { uri: SurfBreaksUrl(surf_forecast_key) },
                            });
                            */
                        }}
                    />
                    <Pressable
                        style={surfUrlStyle(dimensions, short)}
                        onPress={() => {
                            console.log('SurfBaseUrl');
                            /*
                            Navigate(navigation, t(MAP_WEB_DETAIL), {
                                title: MAP_WEB_DETAIL,
                                data: { uri: SurfBaseUrl() },
                            });
                            */
                        }}
                    />
                </View>
            ),
        };
    }

    static GetWeatherStep(zoom) {
        var step = 1;
        if (zoom < 8.50) {
            step = 8;
        } else if (zoom < 12.00) {
            step = 4;
        } else if (zoom < 13.00) {
            step = 2;
        }
        Console.log('DynamicMapUtils.GetWeatherStep', { zoom, step });
        return step;
    }

    static IsInside(marker, minLat, maxLat, minLng, maxLng) {
        const coord = marker?.position?.lat && marker.position?.lng
            ?
            {
                latitude: marker.position.lat,
                longitude: marker.position.lng,
            }
            : marker?.coordinate?.latitude && marker?.coordinate?.longitude
                ?
                {
                    latitude: marker.coordinate.latitude,
                    longitude: marker.coordinate.longitude,
                }
                : null;
        if (!coord) {
            return true;
        }
        const retval = (
            (!minLat || coord.latitude >= minLat) &&
            (!maxLat || coord.latitude <= maxLat) &&
            (!minLng || coord.longitude >= minLng) &&
            (!maxLng || coord.longitude <= maxLng)
        ) ? true : false;
        Console.trace('DynamicMapUtils.IsInside', { id: marker?.id, coord, minLat, maxLat, minLng, maxLng, retval });
        return retval;
    }

    static InflateRegion(minLat, maxLat, minLng, maxLng) {
        if (minLat && maxLat && minLng && maxLng &&
            maxLat > minLat && maxLng > minLng) {
            const dLat = maxLat - minLat;
            const dLng = maxLng - minLng;
            return {
                minLat: minLat - dLat,
                maxLat: maxLat + dLat,
                minLng: minLng - dLng,
                maxLng: maxLng + dLng,
            };
        }
        return { minLat, maxLat, minLng, maxLng };
    }

    static GetMapIndices(lat, lng, views, zoneSearch = false) {
        var minDist = Number.POSITIVE_INFINITY;
        var viewIndex = -1;
        var zoneIndex = -1;
        views.forEach((subViews, vi) => {
            subViews.forEach((subView, zi) => {
                if (!vi) {
                    // always skip first view (state)
                    return;
                }
                if (zoneSearch && !zi) {
                    // looking for zone? skip first view (island)
                    return;
                }
                if (!zoneSearch && zi) {
                    // looking for island? skip all but first view
                    return;
                }
                const dLat = lat - subView.center.latitude;
                const dLng = lng - subView.center.longitude;
                const dist = Numbers.distance(dLat, dLng);
                if (dist < minDist) {
                    minDist = dist;
                    viewIndex = vi;
                    zoneIndex = zi;
                }
            });
        });
        Console.devLog('DynamicMapUtils.GetMapIndices', { lat, lng, zoneSearch, viewIndex, zoneIndex, camera: views[viewIndex][zoneIndex] });
        return { viewIndex, zoneIndex };
    }
}

export const DynamicMap = props => {

    const {
        mapStyle,
        locations,
        locationsUpdate,
        bikis,
        bikisUpdate,
        buses,
        busesUpdate,
        weather,
        weatherUpdate,
        //surf,
        //surfUpdate,
        //satellites,
        //satellitesUpdate,
        onCalloutPress,
        onDirections,
        onMarkerPress,
        mapViews,
        controls,
        holo,
        //sunInfo, // MARKMARK TODO
        //setListData, // MARKMARK TODO
        setEnabledTypes,
        surfSource,
        surfDimensions,
    } = props;

    const { isWeb, startGeocode, stopGeocode, geocodeTimestamp, geocodeLatitude, geocodeLongitude } = useSystemState();
    const { t, dark, language, theme, themeUpdate } = useAppState();
    const {
        enabledToggles,
        toggleUpdate,
        latitude,
        longitude,
        heading,
        pitch,
        zoom,
        minZoom,
        maxZoom,
        minLatitude,
        maxLatitude,
        minLongitude,
        maxLongitude,
        compassModeIndex,
        mapModeIndex,
        menuModeIndex,
        trackingModeIndex,
        transitModeIndex,
    } = useOahuState();

    const oahuDispatch = useOahuDispatch();
    const oahuDispatchRef = useRef(oahuDispatch);
    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    const [weatherStep, setWeatherStep] = useState(1);
    const setWeatherStepRef = useRef(setWeatherStep);

    const [uri, setUri] = useState(null);
    const setUriRef = useRef(setUri);

    const [clustering, setClustering] = useState(false);
    const setClusteringRef = useRef(setClustering);

    const [geocodeId, setGeocodeId] = useState(null);
    const setGeocodeIdRef = useRef(setGeocodeId);

    const [timestamp, setTimestamp] = useState(0);
    const setTimestampRef = useRef(setTimestamp);

    const [markerPressed, setMarkerPressed] = useState(false);
    const setMarkerPressedRef = useRef(setMarkerPressed);

    const [weatherMarkers, setWeatherMarkers] = useState([]);
    const setWeatherMarkersRef = useRef(setWeatherMarkers);

    const [weatherMarkersLOD, setWeatherMarkersLOD] = useState([]);
    const setWeatherMarkersLODRef = useRef(setWeatherMarkersLOD);

    const [busMarkers, setBusMarkers] = useState([]);
    const setBusMarkersRef = useRef(setBusMarkers);
    const [busMarkersUpdate, setBusMarkersUpdate] = useState(2);
    const setBusMarkersUpdateRef = useRef(setBusMarkersUpdate);

    const [directionMarkers, setDirectionMarkers] = useState([]);
    const setDirectionMarkersRef = useRef(setDirectionMarkers);
    const [directionMarkersUpdate, setDirectionMarkersUpdate] = useState(2);
    const setDirectionMarkersUpdateRef = useRef(setDirectionMarkersUpdate);

    const [directionPolylines, setDirectionPolylines] = useState([]);
    const setDirectionPolylinesRef = useRef(setDirectionPolylines);
    const [directionPolylinesUpdate, setDirectionPolylinesUpdate] = useState(2);
    const setDirectionPolylinesUpdateRef = useRef(setDirectionPolylinesUpdate);

    const [markers, setMarkers] = useState([]);
    const setMarkersRef = useRef(setMarkers);
    const [markersUpdate, setMarkersUpdate] = useState(2);
    const setMarkersUpdateRef = useRef(setMarkersUpdate);

    const [overlays/*, setOverlays*/] = useState([]);
    //const setOverlaysRef = useRef(setOverlays);
    const [overlaysUpdate/*, setOverlaysUpdate*/] = useState(2);
    //const setOverlaysUpdateRef = useRef(setOverlaysUpdate);

    const [polylines, setPolylines] = useState([]);
    const setPolylinesRef = useRef(setPolylines);
    const [polylinesUpdate, setPolylinesUpdate] = useState(2);
    const setPolylinesUpdateRef = useRef(setPolylinesUpdate);

    const [origin, setOrigin] = useState(null);
    const setOriginRef = useRef(setOrigin);

    const [destination, setDestination] = useState(null);
    const setDestinationRef = useRef(setDestination);

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect tracking/transit modes`, { geocodeTimestamp, transitModeIndex });
            // trackingModeIndex and transitModeIndex are inactive, and initialized to NUM_XXX_MODES
            // when geocodeTimestamp changes, these modes are activated
            if (geocodeTimestamp) {
                if (trackingModeIndex === NUM_TRACKING_MODES) {
                    oahuDispatchRef.current({
                        type: OAHU_TYPES.SET_TRACKING_MODE,
                        payload: 0,
                    });
                }
                if (transitModeIndex === NUM_TRANSIT_MODES) {
                    oahuDispatchRef.current({
                        type: OAHU_TYPES.SET_TRANSIT_MODE,
                        payload: 0,
                    });
                }
            }
        },
        [
            geocodeTimestamp,
            trackingModeIndex,
            transitModeIndex,
            oahuDispatchRef,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect geocode`, { geocodeId });
            if (geocodeId !== null) {
                return () => {
                    Console.devLog(`${NAME} geocode stop`, { geocodeId });
                    stopGeocode(geocodeId);
                };
            }
        },
        [
            geocodeId,
            stopGeocode,
        ],
    );

    const initializeGeocode = useCallback(
        () => {
            const id = startGeocode(payload => systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_GEOLOCATION,
                payload,
            }));
            if (id !== null) {
                Console.devLog(`${NAME} geocode started`, { id });
                setGeocodeIdRef.current(id);
            } else {
                Console.devLog(`${NAME} geocode not started`, { id });
            }
        },
        [
            startGeocode,
            setGeocodeIdRef,
            systemDispatchRef,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect initialize geocode`);
            initializeGeocode();
        },
        [
            initializeGeocode,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect timestamp`);

            // keep setInterval ids
            var intervalIds = [];
            const func = () => {
                const now = new Date();
                setTimestampRef.current(now.getTime());
                Console.devLog(`\nMARKMARK TIMESTAMP MAYBE TO UPDATE SUNRISE/SET???\n${NAME} useEffect timestamp`, { now });
            };
            intervalIds.push(setInterval(func, 1000 * 60 * 15)); // 15 mins
            func();

            // cancel all of the setIntervals
            return () => {
                Console.devLog(`${NAME} useEffect timestamp clearing`, { intervalIds });
                intervalIds.forEach(v => clearInterval(v));
            };
        },
        [
            setTimestampRef,
        ],
    );

    useEffect(
        () => {
            const _step = DynamicMapUtils.GetWeatherStep(zoom);
            Console.devLog(`${NAME} useEffect weather step`, { zoom, _step });
            setWeatherStepRef.current(_step);
        },
        [
            zoom,
            setWeatherStepRef,
        ],
    );

    useEffect(
        () => {
            var _markers = [];
            locations?.length && locations.forEach(location => {
                const mapIcon = MapIcons.get(location?.type);
                const _marker = DynamicMapUtils.InitializeMarker(
                    location,
                    isWeb,
                    location?.icon ? location.icon : mapIcon ? mapIcon.icon.name(dark) : null,
                    location?.color ? location.color : mapIcon ? mapIcon.color : null,
                );
                if (location?.type === MapKeys.SURF && location?.info?.surf_forecast?.length && location?.title?.length) {
                    _marker.callout_props = DynamicMapUtils.InitializeSurfCallout(location.info.surf_forecast, location.title, language, surfSource, surfDimensions);
                    _markers.push(_marker);
                } else if (location?.type !== MapKeys.SURF) {
                    _markers.push(_marker);
                }
            });

            const inflated = DynamicMapUtils.InflateRegion(minLatitude, maxLatitude, minLongitude, maxLongitude);
            const filtered = _markers.filter(m => DynamicMapUtils.IsInside(m, inflated.minLat, inflated.maxLat, inflated.minLng, inflated.maxLng));
            setMarkersRef.current(filtered);
            setMarkersUpdateRef.current(v => v + 1);
            setClusteringRef.current(filtered?.length > LOCATIONS_CLUSTER_THRESHOLD ? true : false);
            Console.devLog(`\n\n\n${NAME} useEffect locations`, { dark, language, locationsUpdate, locations: locations?.length, markers: _markers?.length ? _markers[0] : null, /*polylines: trails.length,*/ inflated, filtered: filtered?.length, minLatitude, maxLatitude, minLongitude, maxLongitude });
        },
        [
            isWeb,
            dark,
            language,
            minLatitude,
            maxLatitude,
            minLongitude,
            maxLongitude,
            locations,
            locationsUpdate,
            setMarkersRef,
            setMarkersUpdateRef,
            setClusteringRef,
            surfSource,
            surfDimensions,
        ],
    );

    useEffect(
        () => {
            if (!weather?.lookup?.size) {
                Console.devLog(`${NAME} useEffect weather level of detail clearing`);
                setWeatherMarkersLODRef.current([]);
            } else {
                const levelOfDetail = weather.get(weatherStep);
                setWeatherMarkersLODRef.current(levelOfDetail);
                Console.devLog(`${NAME} useEffect weather level of detail`, { weatherUpdate, weatherStep, levelOfDetail: levelOfDetail?.length });
            }
        },
        [
            weather,
            weatherUpdate,
            weatherStep,
            setWeatherMarkersLODRef,
        ],
    );

    useEffect(
        () => {
            const langIndex = language === 'en_us' || language === 'haw_hi' ? 0 : 1;
            var _markers = [];
            weatherMarkersLOD.forEach(station => {
                var _marker = DynamicMapUtils.InitializeMarker(
                    station,
                    isWeb,
                );

                _marker.title = station.title.split('|')[langIndex];
                // MARKMARK = `${station.coordinate.latitude},${station.coordinate.longitude}`;
                _marker.opacity = 0.5;

                if (station?.conditions?.length && station.conditions[0]?.report) {
                    const {
                        temperature,
                        temperatureColor,
                        clouds,
                        cloudsColor,
                        rain,
                        rainColor,
                        humidity,
                        humidityColor,
                        windSpeed,
                        windSpeedColor,
                        waveHeight,
                        waveHeightColor,
                    } = station.conditions[0].report;
                    const _temperature = Validate.isValid(temperature) && temperature?.length === 2 ? temperature[langIndex] : null;
                    const _waveHeight = Validate.isValid(waveHeight) && waveHeight?.length === 2 ? waveHeight[langIndex] : null;

                    _marker.callout_props = DynamicMapUtils.InitializeCallout(
                        [
                            { value: _temperature, color: temperatureColor },
                            { value: clouds, color: cloudsColor },
                            { value: rain, color: rainColor },
                            { value: windSpeed, color: windSpeedColor },
                            { value: humidity, color: humidityColor },
                            { value: _waveHeight, color: waveHeightColor },
                        ],
                        styles.weatherCallout,
                        Colors.colors.dimgray,
                    );
                }

                _markers.push(_marker);
            });

            const inflated = DynamicMapUtils.InflateRegion(minLatitude, maxLatitude, minLongitude, maxLongitude);
            const filtered = _markers.filter(m => DynamicMapUtils.IsInside(m, inflated.minLat, inflated.maxLat, inflated.minLng, inflated.maxLng));
            setWeatherMarkersRef.current(filtered);
            Console.devLog(`\n\n\n${NAME} useEffect weather markers`, {
                language, weatherUpdate, inflated, minLatitude, maxLatitude, minLongitude, maxLongitude,
                weatherMarkersLOD: weatherMarkersLOD?.length,
                markers: _markers?.length ? _markers[0] : null,
                filtered: filtered?.length,
            });
        },
        [
            isWeb,
            minLatitude,
            maxLatitude,
            minLongitude,
            maxLongitude,
            language,
            weatherMarkersLOD,
            weatherUpdate,
            setWeatherMarkersRef,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect autocenter`, { latitude, longitude, trackingModeIndex, trackingMode: TrackingMode[trackingModeIndex] });
            if (TrackingMode[trackingModeIndex].mode === TRACKING_MODES.TRACKING) {
                center();
            }
        },
        [
            trackingModeIndex,
            latitude,
            longitude,
            center,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect origin`, { geocodeLatitude, geocodeLongitude, geocodeTimestamp, trackingModeIndex, trackingMode: TrackingMode[trackingModeIndex] });
            setOriginRef.current({ latitude: geocodeLatitude, longitude: geocodeLongitude });
            if (geocodeTimestamp && TrackingMode[trackingModeIndex].mode === TRACKING_MODES.TRACKING) {
                center();
            }
        },
        [
            geocodeLatitude,
            geocodeLongitude,
            geocodeTimestamp,
            trackingModeIndex,
            center,
            setOriginRef,
        ],
    );

    const center = useCallback(
        () => {
            Console.devLog(`${NAME}.center`, { geocodeLatitude, geocodeLongitude, zoom });
            oahuDispatchRef.current({
                type: OAHU_TYPES.SET_CAMERA, payload: {
                    center: {
                        latitude: geocodeLatitude,
                        longitude: geocodeLongitude,
                    },
                    zoom: Math.max(zoom, CENTER_ZOOM),
                },
            });
        },
        [
            geocodeLatitude,
            geocodeLongitude,
            zoom,
            oahuDispatchRef,
        ],
    );

    const _onCalloutPress = useCallback(
        event => {
            if (onCalloutPress) {
                Console.devLog(`${NAME}._onCalloutPress`, { event });
                onCalloutPress(event);
            }
        },
        [
            onCalloutPress,
        ],
    );

    const setDirectionsBling = useCallback(
        (_busMarkers = [], _directionMarkers = [], _directionPolylines = []) => {
            Console.devLog(`${NAME}.setDirectionsBling`, { _busMarkers: _busMarkers?.length, _directionMarkers: _directionMarkers?.length, _directionPolylines: _directionPolylines?.length });

            setBusMarkersRef.current(_busMarkers);
            setBusMarkersUpdateRef.current(v => v + 1);

            setDirectionMarkersRef.current(_directionMarkers);
            setDirectionMarkersUpdateRef.current(v => v + 1);

            setDirectionPolylinesRef.current(_directionPolylines);
            setDirectionPolylinesUpdateRef.current(v => v + 1);

            if (_busMarkers?.length || _directionMarkers?.length || _directionPolylines?.length) {
                setDestinationRef.current(null);
                oahuDispatchRef.current({ type: OAHU_TYPES.CLEAR_TOGGLES });
            }
        },
        [
            setBusMarkersRef,
            setBusMarkersUpdateRef,
            setDirectionMarkersRef,
            setDirectionMarkersUpdateRef,
            setDirectionPolylinesRef,
            setDirectionPolylinesUpdateRef,
            oahuDispatchRef,
            setDestinationRef,
        ],
    );

    const deselect = useCallback(
        () => {
            Console.devLog(`${NAME}deselect`, {});
            setPolylinesRef.current([]);
            setPolylinesUpdateRef.current(v => v + 1);
        },
        [
            setPolylinesRef,
            setPolylinesUpdateRef,
        ],
    );

    useEffect(
        () => {
            Console.devLog(`${NAME} useEffect toggle`, { toggleUpdate });
            if (setEnabledTypes) {
                setEnabledTypes(enabledToggles());
            }
            if (toggleUpdate > 0) {
                setDirectionsBling();
            }
            deselect();
        },
        [
            toggleUpdate,
            enabledToggles,
            setEnabledTypes,
            setDirectionsBling,
            deselect,
        ],
    );

    const _onDirections = useCallback(
        event => {

            const dir = DecodeDirections(event.transitMode, event.directions, language, holo);
            Console.devLog(`\n\n\n\nMARKMARK\n\n\n\n${NAME}._onDirections\n\n\n`, {
                event,
                bikis: bikis?.length, bikisUpdate,
                buses: buses?.size, busesUpdate,
                // eventDirections:JSON.stringify(event.directions, null, ' '),
            });

            const directions = onDirections
                ? onDirections(dir)
                : dir;

            if (!directions?.legs?.length) {
                return;
            }

            var _directionBuses = [];
            var _directionMarkers = [];
            var _directionPolylines = [];

            var busStopIndex = 0;

            // for bicycling, get the nearest biki stations to the route endpoints
            if (directions.legs[0].transitMode.mode === TRANSIT_MODES.BICYCLING) {
                const start = directions.legs[0]?.start?.location;
                const end = directions.legs[directions.legs.length - 1]?.end?.location;
                const delta = 0.004; // approx 0.25 mi
                [start, end].forEach(latlng => {
                    const bikiMinLat = latlng.latitude - delta;
                    const bikiMaxLat = latlng.latitude + delta;
                    const bikiMinLng = latlng.longitude - delta;
                    const bikiMaxLng = latlng.longitude + delta;
                    bikis
                        .filter(biki => DynamicMapUtils.IsInside(biki, bikiMinLat, bikiMaxLat, bikiMinLng, bikiMaxLng))
                        .map(biki => {
                            const _dLat = biki.coordinate.latitude - latlng.latitude;
                            const _dLng = biki.coordinate.longitude - latlng.longitude;
                            return {
                                ...biki,
                                distance: Numbers.distance(_dLat, _dLng),
                            };
                        })
                        .sort((a, b) => a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0)
                        .slice(0, 2)
                        .forEach(biki => {
                            _directionMarkers.push(DynamicMapUtils.InitializeMarker(
                                biki,
                                isWeb,
                                directions.legs[0].transitMode.icon.name(dark),
                                directions.legs[0].transitMode.color,
                            ));
                        });
                });
            }

            // get the direction path
            var transitLegCounter = 0;
            directions.legs.forEach((leg, legIndex, legArray) => {

                var legColor = leg.transitMode.color;

                // for transit, get the buses and bus stops
                if (leg.transitMode.mode === TRANSIT_MODES.TRANSIT) {

                    const busKey = BusKey(`${leg?.route}: ${leg.headsign}`);
                    const matchingBuses = transitLegCounter
                        ? null
                        : buses.get(busKey);

                    Console.devLog(`${NAME}._onDirections matching buses`, { matchingBuses, busKey });

                    if (matchingBuses) {

                        matchingBuses.forEach(bus => {
                            const directionBus = DynamicMapUtils.InitializeMarker(
                                bus,
                                isWeb,
                                Icons.MapBuses.name(dark),
                                BusColor(bus?.adherence ? bus.adherence : 0),
                            );

                            directionBus.label = bus?.route;
                            //directionBus.opacity = 0.5;

                            _directionBuses.push(directionBus);
                        });
                    }

                    if (transitLegCounter++ % 2 !== 0) {
                        legColor = Colors.colors.lightyellow;
                    }

                    [
                        {
                            icon: Icons.MapBusStops,
                            props: {
                                title: leg?.departureStop,
                                coordinate: leg?.start?.location,
                                color: Colors.colors.lime,
                                opacity: 0.67,
                                type: 'busStop',
                            },
                            callout_props: DynamicMapUtils.InitializeCallout(
                                leg.details.map((value, idx) => ({
                                    value: idx ? value : `${EmojiNumbers[++busStopIndex % EmojiNumbers.length]} ${value}`,
                                    color: Colors.colors.white,
                                })),
                                styles.directionsCallout,
                                Colors.colors.blue,
                            ),
                        },
                        {
                            icon: Icons.MapBusStops,
                            props: {
                                title: leg?.arrivalStop,
                                coordinate: leg?.end?.location,
                                color: Colors.colors.red,
                                opacity: 0.67,
                                type: 'busStop',
                            },
                            callout_props: (legIndex + 1) >= legArray.length ? null : DynamicMapUtils.InitializeCallout(
                                [
                                    {
                                        value: `${EmojiNumbers[++busStopIndex % EmojiNumbers.length]} ${leg.arrivalStop}`,
                                        color: Colors.colors.white,
                                    },
                                    ...legArray[legIndex + 1].details.map(value => ({
                                        value,
                                        color: Colors.colors.white,
                                    })),
                                ],
                                styles.directionsCallout,
                                Colors.colors.blue,
                            ),
                        },
                    ].forEach(_marker => {
                        _directionMarkers.push(DynamicMapUtils.InitializeMarker(
                            _marker.props,
                            isWeb,
                            _marker.icon.name(dark),
                            _marker.props?.color,
                            _marker?.callout_props,
                        ));
                    });
                }

                _directionPolylines.push({
                    coordinates: leg.path,
                    strokeWidth: leg.transitMode.width,
                    strokeColor: legColor,
                    tappable: true,
                    onPress: () => {
                        //Alert.alert(null, leg.details.join('\n'), [{ text: 'OK' }]);
                        //Alert.alert(null, directions.details.join('\n'), [{ text: 'OK' }]);
                    },
                });
            });

            [
                {
                    icon: Icons.MapPositionMan,
                    props: {
                        title: directions.departureAddress,
                        coordinate: directions.legs[0]?.start?.location,
                        color: Colors.colors.lime,
                        type: 'busStop',
                    },
                    callout_props: DynamicMapUtils.InitializeCallout(
                        directions.departureDetails.map(value => ({
                            value,
                            color: Colors.colors.white,
                        })),
                        styles.directionsCallout,
                        Colors.colors.green,
                    ),
                },
                {
                    icon: Icons.MapFavorites,
                    props: {
                        title: directions.arrivalAddress,
                        coordinate: directions.legs[directions.legs.length - 1]?.end?.location,
                        color: Colors.colors.red,
                        type: 'busStop',
                    },
                    callout_props: DynamicMapUtils.InitializeCallout(
                        directions.arrivalDetails.map(value => ({
                            value,
                            color: Colors.colors.white,
                        })),
                        styles.directionsCallout,
                        Colors.colors.darkred,
                    ),
                },
            ].forEach(_marker => {
                _directionMarkers.push(DynamicMapUtils.InitializeMarker(
                    _marker.props,
                    isWeb,
                    _marker.icon.name(dark),
                    _marker.props?.color,
                    _marker?.callout_props,
                ));
            });

            setDirectionsBling(_directionBuses, _directionMarkers, _directionPolylines);
        },
        [
            isWeb,
            dark,
            language,
            holo,
            bikis,
            bikisUpdate,
            buses,
            busesUpdate,
            onDirections,
            setDirectionsBling,
        ],
    );

    const onMapPress = useCallback(
        event => {
            setMarkerPressedRef.current(false);
            deselect();
            if (markerPressed) {
                return;
            }
            const mapIndices = DynamicMapUtils.GetMapIndices(
                event?.coordinate?.latitude,
                event?.coordinate?.longitude,
                mapViews,
                zoom > MAP_ZONE_THRESHOLD);
            const payload = mapViews[mapIndices.viewIndex][mapIndices.zoneIndex];//zoom <= MAP_ZONE_THRESHOLD ? 0 : mapIndices.zoneIndex];
            Console.devLog(`${NAME}.onMapPress`, { zoom, event, mapIndices });
            oahuDispatchRef.current({ type: OAHU_TYPES.SET_CAMERA, payload });
        },
        [
            markerPressed,
            mapViews,
            zoom,
            oahuDispatchRef,
            setMarkerPressedRef,
            deselect,
        ],
    );

    const _onMarkerPress = useCallback(
        event => {
            setMarkerPressedRef.current(true);
            Console.devLog(`${NAME}._onMarkerPress`, { event, trackingModeIndex, transitModeIndex });
            if (TrackingMode[trackingModeIndex].enabled && TransitMode[transitModeIndex].enabled) {
                if (!NonDestinationTypes.has(event?.data?.type)) {
                    setDestinationRef.current(event?.coordinate);
                }
                //} else if (onCalloutPress) {
                //    Console.devLog(`${NAME}._onMarkerPress callout`, { event });
                //    onCalloutPress(event);
            }

            const _polylines = event?.data?.trail?.length > 1
                ?
                [
                    {
                        coordinates: event.data.trail.map(v => ({ latitude: v[0], longitude: v[1] })),
                        strokeWidth: 2,
                        strokeColor: Colors.colors.lime,
                        tappable: true,
                        onPress: () => { console.log('PRESS'); },
                    },
                ]
                : [];
            setPolylinesRef.current(_polylines);
            setPolylinesUpdateRef.current(v => v + 1);

            if (onMarkerPress) {
                onMarkerPress(event);
            }
        },
        [
            trackingModeIndex,
            transitModeIndex,
            setDestinationRef,
            onMarkerPress,
            //onCalloutPress,
            setPolylinesRef,
            setPolylinesUpdateRef,
            setMarkerPressedRef,
        ],
    );

    const onViewChanged = useCallback(
        payload => {
            Console.devLog(`${NAME}.onViewChanged`, { payload });
            oahuDispatchRef.current({ type: OAHU_TYPES.SET_CAMERA, payload });
        },
        [
            oahuDispatchRef,
        ],
    );

    Console.stack(NAME, props, {
        isWeb, holo,
        compassModeIndex, mapModeIndex, menuModeIndex, trackingModeIndex, transitModeIndex,
        uri, latitude, longitude, heading, pitch, zoom, clustering,
        origin, destination,
        timestamp,
        geocodeTimestamp, geocodeLatitude, geocodeLongitude,
        busMarkers: busMarkers?.length, busMarkersUpdate,
        directionMarkers: directionMarkers?.length, directionMarkersUpdate,
        directionPolylines: directionPolylines?.length, directionPolylinesUpdate,
        markers: markers?.length, locationsUpdate,
        overlays: overlays?.length, overlaysUpdate,
        polylines: polylines?.length, polylinesUpdate,
    });

    return useMemo(
        () => {
            const compassMode = CompassMode[compassModeIndex];
            const mapMode = MapMode[mapModeIndex];
            const menuMode = MenuMode[menuModeIndex];
            const trackingMode = TrackingMode[trackingModeIndex];
            const transitMode = TransitMode[trackingMode.mode === TRACKING_MODES.OFF ? 0 : transitModeIndex];

            const primary = theme?.colors?.primary ? theme.colors.primary : Colors.colors.gray;
            const primary_0_10 = primary.replace(/rgb\(/g, 'rgba(').replace(/\)/g, ',0.1)');
            const primary_0_80 = primary.replace(/rgb\(/g, 'rgba(').replace(/\)/g, ',0.8)');
            const primary_1_00 = primary.replace(/rgb\(/g, 'rgba(').replace(/\)/g, ',1)');

            const topGradientColors = dark
                ? [primary_1_00, primary_0_10, 'rgba(0,0,48,0)']
                : [primary_1_00, primary_0_10, 'rgba(32,48,106,0)'];
            const bottomGradientColors = dark
                ? ['rgba(0,0,48,0)', primary_0_10, primary_1_00]
                : ['rgba(32,48,106,0)', primary_0_10, primary_1_00];
            const bottomLeftGradientColors = dark
                ? ['rgba(0,0,48,0)', primary_0_10, primary_0_80]
                : ['rgba(32,48,106,0)', primary_0_10, primary_0_80];

            Console.log(`${NAME} render`, {
                latitude, longitude, heading, pitch, zoom,
                /**/
                clustering, minZoom, maxZoom, themeUpdate,
                origin, destination,
                compassMode, mapMode, trackingMode, transitMode,
                busMarkers: busMarkers?.length, busMarkersUpdate,
                directionMarkers: directionMarkers?.length, directionMarkersUpdate,
                directionPolylines: directionPolylines?.length, directionPolylinesUpdate,
                markers: markers?.length, markersUpdate, locationsUpdate,
                polylines: polylines?.length, polylinesUpdate,
                overlays: overlays?.length, overlaysUpdate,
                weatherMarkers: weatherMarkers?.length, weatherUpdate,
                /**/
            });

            return (
                <>
                    <GoogleMapView
                        mapStyle={mapStyle}
                        mapType={mapMode.mode}
                        lat={latitude}
                        lng={longitude}
                        heading={heading}
                        pitch={pitch}
                        zoom={zoom}
                        minZoom={minZoom}
                        maxZoom={maxZoom}
                        clustering={clustering}
                        compass={compassMode.enabled}
                        dark={dark}
                        busMarkers={busMarkers}
                        busUpdate={busMarkersUpdate}
                        weatherMarkers={weatherMarkers}
                        weatherUpdate={weatherUpdate}
                        positionMarker={{
                            ...DynamicMapUtils.InitializeMarker(
                                {
                                    id: 'position',
                                    type: 'user',
                                    title: t(YOU_ARE_HERE),
                                    coordinate: {
                                        latitude: geocodeLatitude,
                                        longitude: geocodeLongitude,
                                    },
                                },
                                isWeb,
                                Icons.MapPositionMan.name(dark),
                                trackingMode.color,
                            ),
                            description: `${geocodeLatitude.toFixed(3)} N, ${(-geocodeLongitude).toFixed(3)} W`,
                        }}
                        positionUpdate={trackingMode.enabled && !directionMarkers?.length ? geocodeTimestamp : null}
                        markers={[...directionMarkers, ...markers]}
                        markersUpdate={directionMarkersUpdate * markersUpdate * locationsUpdate}
                        overlays={overlays}
                        overlaysUpdate={overlaysUpdate}
                        polylines={[...directionPolylines, ...polylines]}
                        polylinesUpdate={directionPolylinesUpdate * polylinesUpdate}
                        origin={origin}
                        destination={destination}
                        transitMode={transitMode.mode}
                        onCalloutPress={_onCalloutPress}
                        onDirections={_onDirections}
                        onMapPress={onMapPress}
                        onMarkerPress={_onMarkerPress}
                        onViewChanged={onViewChanged}
                    />
                    <Gradient
                        style={styles.topControls}
                        colors={topGradientColors}
                        pointerEvents={'box-none'}
                    >
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={compassMode.icon.name(dark)}
                            color={compassMode.color}
                            iconStyle={styles.iconStyle}
                            onPress={() => {
                                if (isWeb) {
                                    return;
                                }
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_COMPASS_MODE,
                                    payload: compassModeIndex + 1,
                                });
                            }}
                        />
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={trackingMode.icon.name(dark)}
                            color={trackingMode.color}
                            iconStyle={styles.iconStyle}
                            onPress={() => {
                                if (trackingMode.mode === TRACKING_MODES.NONE) {
                                    initializeGeocode();
                                    return;
                                }
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_TRACKING_MODE,
                                    payload: trackingModeIndex + 1,
                                });
                                // MARKMARK: some technical debt here...is there a better way?
                                if (TrackingMode[(trackingModeIndex + 1) % NUM_TRACKING_MODES].enabled) {
                                    center();
                                }
                            }}
                        />
                        {Optional(false, (
                            <Icon
                                type={MATERIAL_COMMUNITY}
                                name={clustering ? Icons.MapClusterOff.name(dark) : Icons.MapClusterOn.name(dark)}
                                color={Colors.colors.lightblue}
                                iconStyle={styles.iconStyle}
                                onPress={() => {
                                    //setClusteringRef.current(v => !v);
                                }}
                            />
                        ))}
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={transitMode.icon.name(dark)}
                            color={transitMode.color}
                            iconStyle={styles.iconStyle}
                            onPress={() => {
                                if (!trackingMode.enabled) {
                                    return;
                                }
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_TRANSIT_MODE,
                                    payload: transitModeIndex + 1,
                                });
                            }}
                        />
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={mapMode.icon.name(dark)}
                            color={mapMode.color}
                            iconStyle={styles.iconStyle}
                            onPress={() => {
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_MAP_MODE,
                                    payload: mapModeIndex + 1,
                                });
                            }}
                        />
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={Icons.MapIsland.name(dark)}
                            color={zoom <= MAP_VIEW_THRESHOLD
                                ? Colors.colors.lightblue
                                : Colors.colors.cyan}
                            iconStyle={styles.iconStyle}
                            onPress={() => {
                                var payload = { zoom: MAP_ZOOM_THRESHOLD };
                                if (zoom <= MAP_ZOOM_THRESHOLD) {
                                    const mapIndices = zoom <= MAP_VIEW_THRESHOLD
                                        ? { viewIndex: 0 }
                                        : DynamicMapUtils.GetMapIndices(latitude, longitude, mapViews);
                                    payload = mapViews[mapIndices.viewIndex][0];
                                    Console.devLog(`${NAME} MapIsland.onPress`, { latitude, longitude, zoom, mapIndices, payload });
                                }
                                oahuDispatchRef.current({
                                    type: OAHU_TYPES.SET_CAMERA,
                                    payload,
                                });
                            }}
                        />
                        {controls[controls.length - 1]}
                    </Gradient>
                    <View
                        style={styles.row}
                    >
                        <Gradient
                            style={styles.bottomControlsLeft}
                            colors={bottomLeftGradientColors}
                        >
                            <Icon
                                type={MATERIAL_COMMUNITY}
                                name={'camera'}
                                color={Colors.colors.white}
                                iconStyle={styles.iconStyle}
                                onPress={async () => {
                                    const _uri = await LaunchCamera();
                                    if (_uri) {
                                        setUriRef.current(_uri);
                                    }
                                }}
                            />
                        </Gradient>
                        <Gradient
                            style={styles.bottomControls}
                            colors={bottomGradientColors}
                        >
                            {controls[menuModeIndex]}
                            <Icon
                                type={MATERIAL_COMMUNITY}
                                name={menuMode.icon.name(dark)}
                                color={menuMode.color}
                                iconStyle={styles.iconStyle}
                                onPress={() => {
                                    oahuDispatchRef.current({
                                        type: OAHU_TYPES.SET_MENU_MODE,
                                        payload: menuModeIndex + 1,
                                    });
                                }}
                            />
                            {Optional(false, (
                                <Icon
                                    type={MATERIAL_COMMUNITY}
                                    name={'map'}
                                    color={Colors.colors.pink /*overlay ? Colors.colors.white : Colors.colors.lightblue*/}
                                    iconStyle={styles.iconStyle}
                                    onPress={() => {
                                        Console.devLog('setOverlay');
                                        //setOverlayRef.current(v => !v);
                                    }}
                                />
                            ))}
                            {Optional(false && !isWeb, (
                                <>
                                    <Icon
                                        type={MATERIAL_COMMUNITY}
                                        name={'view-gallery'}
                                        color={Colors.colors.lightblue}
                                        iconStyle={styles.iconStyle}
                                        onPress={async () => {
                                            const _uri = await LaunchGallery();
                                            if (_uri) {
                                                setUriRef.current(_uri);
                                            }
                                        }}
                                    />
                                </>
                            ))}
                        </Gradient>
                    </View>
                </>
            );
        },
        [
            t,
            isWeb,
            dark,
            mapStyle,
            mapViews,
            minZoom,
            maxZoom,
            latitude,
            longitude,
            heading,
            pitch,
            zoom,
            clustering,
            controls,
            busMarkers,
            busMarkersUpdate,
            weatherMarkers,
            weatherUpdate,
            directionMarkers,
            directionMarkersUpdate,
            directionPolylines,
            directionPolylinesUpdate,
            markers,
            markersUpdate,
            locationsUpdate,
            overlays,
            overlaysUpdate,
            polylines,
            polylinesUpdate,
            origin,
            destination,
            compassModeIndex,
            mapModeIndex,
            menuModeIndex,
            trackingModeIndex,
            transitModeIndex,
            _onCalloutPress,
            _onDirections,
            _onMarkerPress,
            onMapPress,
            onViewChanged,
            //setClusteringRef,
            initializeGeocode,
            oahuDispatchRef,
            setUriRef,
            geocodeLatitude,
            geocodeLongitude,
            geocodeTimestamp,
            center,
            theme,
            themeUpdate,
        ],
    );
};

const baseControlStyle = {
    position: 'absolute',
    flexDirection: 'row',
    elevation: 5,
    zIndex: 5,
    backgroundColor: Colors.colors.transparent,
};

const baseCalloutStyle = {
    padding: 5,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.colors.white,
    backgroundColor: Colors.colors.black,
};

const styles = StyleSheet.create({
    topControls: {
        ...baseControlStyle,
        top: 0,
        paddingTop: 6,
        left: 0,
        paddingLeft: 55,
        right: 0,
        //backgroundColor: 'magenta',
    },
    bottomControls: {
        ...baseControlStyle,
        bottom: 0,
        paddingBottom: 10,
        right: 0,
        paddingRight: 6,
        left: 70,
        justifyContent: 'flex-end',
        //backgroundColor: 'lime',
    },
    bottomControlsLeft: {
        ...baseControlStyle,
        bottom: 0,
        paddingBottom: 20,
        right: 70,
        left: 0,
        width: 70,
        justifyContent: 'center',
    },
    iconStyle: { // MARKMARK See MapView
        padding: 5,
        fontSize: 38,
    },
    row: {
        flexDirection: 'row',
    },
    weatherCallout: {
        ...baseCalloutStyle,
        width: 135,
    },
    directionsCallout: {
        ...baseCalloutStyle,
        width: 280,
    },
});
