const Pos = {
    'n': 'noun',
    'v': 'verb',
    'a': 'adjective',
    'r': 'adverb',
    'u': 'unknown',
};

export const GetPos = pos => {
    return Pos[pos];
};
