import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Card, ColorInput, Drawer, FlatList, Icon, Input, INPUT_VARIANTS, Picker, Pressable, Screen, Switch, Text, View, VIEW_VARIANTS, TEXT_VARIANTS } from '../components';
import { APP_TYPES, useAppDispatch, useAppState, useSystemState } from '../context';
import { Icons } from '../media';
import { Console, Numbers, Optional } from '../utils';
import { Colors } from '../styles';

const NAME = 'Settings';

const INFORMATION = 'Information';
const TEXT = 'Text';
const COLORS = 'Colors';
const GAMES = 'Games';
const TUTORS = 'Tutors';

const RESET = 'Reset';
const PROMPT = 'Prompt';

const VIBRATE = 'Vibrate';

const GRAVITY_SCALE = 'Gravity scale';
const GRAVITY_MAGNITUDE = 'Gravity magnitude';
const TIME_SCALE = 'Time scale';

const TOGGLE_AI_EMOJIS = 'Emojis';
const TOGGLE_AI_IPA = 'IPA';

const TEXT_STYLE_PROPS = [
    'fontFamily',
    //'fontSize',
    'fontStyle',
    'fontWeight',
];

const TEXT_STYLE_ENUMS = new Map([
    ['fontStyle', ['normal', 'italic']],
    ['fontWeight', ['normal', 'bold', '100', '200', '300', '400', '500', '600', '700', '800', '900']],
]);

const COLOR_TEXT_VIEW = 'colorTextView';
const COLOR_REGEX = /^#[0-9A-Fa-f]{6}([0-9A-Fa-f]{0,2})$/;

// MARKMARK: get this dynamically, or from the provider's exports...
// also, what about APP_STATE ???

const SYSTEM_STATE = [
    'deviceScale',
    'deviceHeight',
    'deviceWidth',
    'deviceHPad',
    'aspect',
    'height',
    'width',
    'scale',
    'fontScale',
    'headerHeight',
    'tabBarHeight',
    'navHeight',
    'adjHeight',
    'isPortrait',
    'isLandscape',
    'statusBarHeight',
    'safeHeight',
    'deviceHeight',
    'isIphoneX',
];

const CONTENT = [
    { id: 0, title: INFORMATION, iconName: 'Info', render: <Text key={0} value={INFORMATION} /> },
    { id: 1, title: COLORS, iconName: 'Colors', render: <Text key={1} value={COLORS} /> },
    { id: 2, title: TEXT, iconName: 'Text', render: <Text key={2} value={TEXT} /> },
    { id: 3, title: GAMES, iconName: 'Games', render: <Text key={3} value={GAMES} /> },
    { id: 4, title: TUTORS, iconName: 'Education', render: <Text key={4} value={TUTORS} /> },
];

export const Settings = props => {

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const systemState = useSystemState();
    const { adjHeight, isWeb } = systemState;
    const { dark, customTheme, customThemeUpdate, theme, themeUpdate, drawer, tScale, gScale, gMagnitude, aiPrompt, aiEMOJIS, aiIPA } = useAppState();

    const [color, setColor] = useState(Colors.colors.lightgray);
    const setColorRef = useRef(setColor);

    const [colorView, setColorView] = useState(Colors.colors.lightgray);
    const setColorViewRef = useRef(setColorView);

    const [colorTarget, setColorTarget] = useState(COLOR_TEXT_VIEW);
    const setColorTargetRef = useRef(setColorTarget);

    const [show, setShow] = useState(true);
    const setShowRef = useRef(setShow);

    const [mode, setMode] = useState(TUTORS);
    const setModeRef = useRef(setMode);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect toggle drawer`);
            setShowRef.current(v => !v);
        },
        [
            drawer,
            setShowRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect`, { color, colorTarget, themeUpdate });
            if (colorTarget === COLOR_TEXT_VIEW) {
                setColorViewRef.current(color);
            } else {
                var payload = { ...theme };
                payload.colors[colorTarget] = color;
                appDispatchRef.current({ type: APP_TYPES.SET_CUSTOM_THEME, payload });
            }
        },
        [
            theme,
            themeUpdate,
            color,
            colorTarget,
            setColorViewRef,
            appDispatchRef,
        ],
    );

    const setTime = useCallback(
        payload => {
            appDispatchRef.current({ type: APP_TYPES.SET_TIME, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const setGravity = useCallback(
        payload => {
            appDispatchRef.current({ type: APP_TYPES.SET_GRAVITY, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const setMagnitude = useCallback(
        payload => {
            appDispatchRef.current({ type: APP_TYPES.SET_GRAVITY_MAGNITUDE, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const setText = useCallback(
        (type, field, value) => {
            Console.log(`${NAME}.setText`, { type, field, value });
        },
        [
        ],
    );

    Console.stack(NAME, props, { adjHeight, isWeb, dark, gScale, gMagnitude, tScale, color, colorTarget, themeUpdate, customThemeUpdate });

    return useMemo(
        () => {
            const { fonts, colors } = theme;
            const backgroundColorStyle = { backgroundColor: colorView };
            const cardStyle = { backgroundColor: Colors.colors.lightgray };
            const heightStyle = { height: adjHeight };

            Console.log(`${NAME} render`, { adjHeight, isWeb, fonts, colors, dark, gScale, gMagnitude, tScale, color, colorTarget, colorView, themeUpdate, customThemeUpdate });

            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <Drawer
                        show={show}
                        toggleShow={() => show ? appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER }) : null}
                        percentage={67}
                        content={(
                            <View
                                style={styles.drawer}
                            >
                                <FlatList
                                    data={CONTENT}
                                    renderItem={item => (
                                        <Card
                                            id={item?.id}
                                            titleStyle={styles.black}
                                            cardStyle={cardStyle}
                                            title={item?.item?.title ? item.item.title : item?.item ? item.item : 'unknown title'}
                                            left={item?.item?.iconName && Icons[item.item.iconName] ? <Icon iconName={Icons[item.item.iconName].name(dark)} /> : null}
                                            onPress={value => {
                                                setModeRef.current(value);
                                                appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER });
                                            }}
                                        />
                                    )}
                                />
                            </View>
                        )}
                    >
                        {Optional(mode === INFORMATION, (
                            <View
                                value={'SettingsInfo'}
                                style={[styles.subView, heightStyle]}
                            >
                                {Optional(!isWeb, (
                                    <Button
                                        value={VIBRATE}
                                        style={styles.button}
                                        onPress={() => systemState.startVibrate()}
                                    />
                                ))}
                                {SYSTEM_STATE.map((item, iid) => (
                                    <View
                                        key={`iid_${iid}`}
                                        style={styles.infoRow}
                                    >
                                        <Text
                                            style={styles.infoKey}
                                            value={`${item} = `}
                                        />
                                        <Text
                                            style={styles.infoValue}
                                            value={Numbers.format(systemState[item], 3)}
                                        />
                                    </View>
                                ))}
                            </View>
                        ))}
                        {Optional(mode === COLORS, (
                            <View
                                value={'SettingsColor'}
                                style={[styles.subView, heightStyle]}
                            >
                                <View
                                    value={'SettingsColorInput'}
                                    style={styles.colorInput}
                                >
                                    <ColorInput
                                        dark={dark}
                                        color={color}
                                        onChangeColor={setColorRef.current}
                                    />
                                </View>
                                <View
                                    value={'SettingsColorTextView'}
                                    style={styles.colorTextView}
                                >
                                    <Pressable
                                        style={[styles.pressable, backgroundColorStyle]}
                                        onPress={() => setColorTargetRef.current(COLOR_TEXT_VIEW)}
                                    />
                                    <View
                                        value={'SettingsColorText'}
                                        style={[styles.colorTextSubView, backgroundColorStyle]}
                                    >
                                        {Object.keys(colors).map((item, cid) => (
                                            <Text
                                                key={`cid_${cid}`}
                                                value={item}
                                                color={item === colorTarget ? color : colors[item]}
                                                style={styles.colorText}
                                                onPress={() => {
                                                    if (COLOR_REGEX.test(colors[item])) {
                                                        setColorRef.current(colors[item].substring(0, 7));
                                                    }
                                                    setColorTargetRef.current(item);
                                                }}
                                            />
                                        ))}
                                    </View>
                                    <Pressable
                                        style={[styles.pressable, backgroundColorStyle]}
                                        onPress={() => setColorTargetRef.current(COLOR_TEXT_VIEW)}
                                    />
                                </View>
                                <Button
                                    value={RESET}
                                    onPress={() => {
                                        const { darkTheme, lightTheme } = customTheme;
                                        const payload = dark
                                            ? { ...darkTheme, colors: Colors.darkTheme }
                                            : { ...lightTheme, colors: Colors.lightTheme };
                                        appDispatchRef.current({ type: APP_TYPES.SET_CUSTOM_THEME, payload });
                                    }}
                                />
                            </View>
                        ))}
                        {Optional(mode === TEXT, (
                            <View
                                value={'SettingsText'}
                                style={[styles.subView, heightStyle]}
                                variant={VIEW_VARIANTS.SCROLL}
                            >
                                {Object.keys(fonts).map((item, tid) => (
                                    <View
                                        key={`tid_${tid}`}
                                        style={styles.fontView}
                                    >
                                        <Text
                                            value={item}
                                            variant={TEXT_VARIANTS.HEADLINE}
                                        />
                                        {
                                            TEXT_STYLE_PROPS.map((textProp, pid) => Optional(TEXT_STYLE_ENUMS.has(textProp), (
                                                <View
                                                    key={`pid_${pid}`}
                                                    value={'SettingsTextView'}
                                                    style={styles.pickerView}
                                                >
                                                    <Text
                                                        value={`${textProp} = `}
                                                        style={styles.pickerKey}
                                                    />
                                                    <Picker
                                                        viewStyle={styles.pickerValue}
                                                        selected={fonts[item][textProp] ? fonts[item][textProp] : 0}
                                                        items={TEXT_STYLE_ENUMS.get(textProp)}
                                                        onChange={value => setText(item, textProp, value)}
                                                    />
                                                </View>
                                            ), (
                                                <Input
                                                    key={textProp}
                                                    title={textProp}
                                                    value={fonts[item][textProp] ? `${fonts[item][textProp]}` : ''}
                                                    onChangeText={value => setText(item, textProp, value)}
                                                    variant={INPUT_VARIANTS.OUTLINED}
                                                />
                                            )))
                                        }
                                    </View>
                                ))}
                            </View>
                        ))}
                        {Optional(mode === GAMES, (
                            <View
                                value={'SettingsGravity'}
                                style={[styles.subView, heightStyle]}
                            >
                                <Input
                                    title={GRAVITY_SCALE}
                                    value={`${gScale}`}
                                    onChangeText={setGravity}
                                    variant={INPUT_VARIANTS.OUTLINED}
                                />
                                <Input
                                    title={GRAVITY_MAGNITUDE}
                                    value={`${gMagnitude}`}
                                    onChangeText={setMagnitude}
                                    variant={INPUT_VARIANTS.OUTLINED}
                                />
                                <Input
                                    title={TIME_SCALE}
                                    value={`${tScale}`}
                                    onChangeText={setTime}
                                    variant={INPUT_VARIANTS.OUTLINED}
                                />
                            </View>
                        ))}
                        {Optional(mode === TUTORS, (
                            <View
                                value={'SettingsTutors'}
                                style={[styles.subView, heightStyle]}
                            >
                                <Button
                                    value={RESET}
                                    onPress={() => appDispatchRef.current({ type: APP_TYPES.RESET_AI })}
                                />
                                <Input
                                    containerStyle={styles.text}
                                    title={PROMPT}
                                    value={aiPrompt}
                                    onChangeText={payload => appDispatchRef.current({ type: APP_TYPES.SET_AI_PROMPT, payload })}
                                    multiline={true}
                                />
                                <Switch
                                    leftLabel={TOGGLE_AI_EMOJIS}
                                    textVariant={TEXT_VARIANTS.TITLE}
                                    value={aiEMOJIS}
                                    onValueChange={() => appDispatchRef.current({ type: APP_TYPES.TOGGLE_AI_EMOJIS })}
                                />
                                <Switch
                                    leftLabel={TOGGLE_AI_IPA}
                                    textVariant={TEXT_VARIANTS.TITLE}
                                    value={aiIPA}
                                    onValueChange={() => appDispatchRef.current({ type: APP_TYPES.TOGGLE_AI_IPA })}
                                />
                            </View>
                        ))}
                    </Drawer>
                </Screen >
            );
        },
        [
            props,
            aiPrompt,
            aiEMOJIS,
            aiIPA,
            adjHeight,
            isWeb,
            show,
            mode,
            appDispatchRef,
            setModeRef,
            systemState,
            theme,
            themeUpdate,
            customTheme,
            customThemeUpdate,
            dark,
            tScale,
            gScale,
            gMagnitude,
            color,
            colorTarget,
            colorView,
            setTime,
            setGravity,
            setMagnitude,
            setText,
            setColorRef,
            setColorTargetRef,
        ],
    );
};

const styles = StyleSheet.create({
    subView: {
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-around',
//      paddingVertical: '2%',
        borderColor: Colors.colors.red,
        borderWidth: 2,
    },
    infoRow: {
        width: '100%',
        flexDirection: 'row',
//      marginTop: '2%',
    },
    infoKey: {
        width: '50%',
        textAlign: 'right',
    },
    infoValue: {
        width: '50%',
        textAlign: 'left',
    },
    fontView: {
//      width: '100%',
        borderColor: Colors.colors.black,
        borderWidth: 1,
//      padding: '2%',
        justifyContent: 'space-around',
    },
    pickerView: {
        flexDirection: 'row',
        flex: -1,
    },
    pickerKey: {
        width: '30%',
    },
    pickerValue: {
        width: '100%',
    },
    colorTextView: {
        //    marginTop: '5%',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    pressable: {
        width: '30%',
        height: '100%',
    },
    colorInput: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    colorTextSubView: {
        width: '40%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    colorText: {
//      marginVertical: 2,
    },
    drawer: {
        width: '100%',
        height: '38%',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },
    button: {
        backgroundColor: Colors.colors.lightgreen,
    },
    black: {
        color: Colors.colors.black,
    },
    text: {
        height: '25%',
        width: '100%',
    },
});
