import { Console } from '../../utils';


export const GoogleMapViewActions = {
    NULL_ACTION: 'null action', // neither
    MAP_CLICK: 'map click',
    MAP_DOUBLE_CLICK: 'map double click',
    MAP_RIGHT_CLICK: 'map right click',
    MARKER_CLICK: 'marker click',
    MARKER_CALLOUT_CLICK: 'marker callout click', // native
    MARKER_DOUBLE_CLICK: 'marker double click', // web
    MARKER_RIGHT_CLICK: 'marker right click', // web
    MARKER_DRAG_START: 'marker drag start',
    MARKER_DRAG: 'marker drag',
    MARKER_DRAG_END: 'marker drag end',
    MARKER_MOUSE_OVER: 'marker mouse over', // web
    MARKER_MOUSE_OUT: 'marker mouse out', // web
    MARKER_MOUSE_UP: 'marker mouse up', // web
    MARKER_MOUSE_DOWN: 'marker mouse down', // web
    MARKER_SELECT: 'marker select', // native
    MARKER_DESELECT: 'marker deselect', // native
};

export const DecodeGoogleMapViewEvent = (event, marker = null, action = null) => {
    var _event = { };

    // carry the marker's raw data with the event
    if (marker?.data) {
        _event.data = marker.data;
    }

    // set the id, priority goes to arg, but fallback is derived from the input event
    if (marker?.id) {
        _event.id = marker.id;
    } else if (event?.id) {
        _event.id = event.id;
    }

    // encode the action, if any
    if (action) {
        _event.action = action;
    } else {
        _event.action = GoogleMapViewActions.NULL_ACTION;
    }

    // get the coordinate and position for native events
    // the goodies are in event.nativeEvent
    if (event?.nativeEvent?.coordinate) {
        _event.coordinate = { ...event.nativeEvent?.coordinate };
    }
    if (event?.nativeEvent?.position) {
        _event.position = { ...event.nativeEvent.position };
    }

    // get the coordinate and position for web events
    // look for event.latLng and event.pixel
    if (event?.latLng) {
        _event.coordinate = {
            latitude: event.latLng.lat(),
            longitude: event.latLng.lng(),
        };
    }
    if (event?.pixel) {
        _event.position = {
            x: event.pixel?.x,
            y: event.pixel?.y,
        };
    }

    // lastly (so they have priority)
    // get/update the coordinate and position directly from the event they exist
    if (event?.coordinate) {
        _event.coordinate = { ...event.coordinate };
    }
    if (event?.position) {
        _event.position = { ...event.position };
    }

    return _event;
};

export const HandleGoogleMapViewEvent = (event, marker, action, cb = null, srcPath = null, logLevel = null) => {
    if (cb) {
        // if there is a callback, invoke it
        cb(DecodeGoogleMapViewEvent(event, marker, action));
    } else {
        // if there wasn't a callback, optionally log the event
        if (srcPath) {
            Console[logLevel ? logLevel : 'devLog'](srcPath, { event: DecodeGoogleMapViewEvent(event, marker, action) });
        }
    }
};
