const THIN = 1;
const THICK = 3;

const atomic = {
    layout: {
        absolute: {
            position: 'absolute',
        },
        relative: {
            position: 'relative',
        },
        flex: {
            flex: 1,
        },
        flexColumn: {
            flexDirection: 'column',
        },
        flexColumnReverse: {
            flexDirection: 'column-reverse',
        },
        flexRow: {
            flexDirection: 'row',
        },
        flexRowReverse: {
            flexDirection: 'row-reverse',
        },
        fullWidth: {
            width: '100%',
        },
        fullHeight: {
            height: '100%',
        },
        resizeModeCenter: {
            resizeMode: 'center',
        },
        resizeModeContain: {
            resizeMode: 'contain',
        },
        resizeModeCover: {
            resizeMode: 'cover',
        },
        resizeModeRepeat: {
            resizeMode: 'repeat',
        },
        resizeModeStretch: {
            resizeMode: 'stretch',
        },
        wrapOff: {
            flexWrap: 'nowrap',
        },
        wrapOn: {
            flexWrap: 'wrap',
        },
        wrapReverse: {
            flexWrap: 'wrap-reverse',
        },
        alignContentCenter: {
            alignContent: 'center',
        },
        alignItemsCenter: {
            alignItems: 'center',
        },
        alignSelfAuto: {
            alignSelf: 'auto',
        },
        justifyContentBetween: {
            justifyContent: 'space-between',
        },
        justifyContentAround: {
            justifyContent: 'space-around',
        },
        borderThin: {
            borderWidth: THIN,
        },
        borderThick: {
            borderWidth: THICK,
        },
    },
    text: {
        italic: {
            fontStyle: 'italic',
        },
        bold: {
            fontWeight: 'bold',
        },
        underline: {
            textDecorationLine: 'underline',
        },
        lineThrough: {
            textDecorationLine: 'line-through',
        },
        underlineLineThrough: {
            textDecorationLine: 'underline line-through',
        },
    },
    view: {
        borderSolid: {
            boderStyle: 'solid',
        },
        borderDotted: {
            borderStyle: 'dotted',
        },
        borderDashed: {
            borderStyle: 'dashed',
        },
    },
};

export const elements = {
    ...atomic,
    style: {
        flexCenterView: {
            ...atomic.layout.relative,
            ...atomic.layout.flex,
//            ...atomic.layout.alignContentCenter,
//            ...atomic.layout.alignItemsCenter,
            ...atomic.layout.alignSelfAuto,
            ...atomic.layout.justifyContentAround,
//            ...atomic.layout.borderThin,
        },
    },
};
