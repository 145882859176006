import React from 'react';
import { G as Group } from 'react-native-svg';
import { Console, Validate } from '../utils';

const NAME = 'G';

class GUtils {

    static #Rotate(a = 0, rx, ry) {
        const angle = a % 360.0;
        const xy = {
            x: Validate.isValid(rx) ? rx : 0,
            y: Validate.isValid(ry) ? ry : 0,
        };
        return a ? `translate(${xy.x} ${xy.y}) rotate(${angle}) translate(${-xy.x} ${-xy.y})` : null;
    }

    static #Scale(s, sx, sy) {
        const xy = Validate.isValid(s)
            ? { x: s, y: s }
            :
            {
                x: Validate.isValid(sx) ? sx : 1,
                y: Validate.isValid(sy) ? sy : 1,
            };
        return (xy.x !== 1 || xy.y !== 1) ? `scale(${xy.x} ${xy.y})` : null;
    }

    static #Translate(tx, ty) {
        const xy = {
            x: Validate.isValid(tx) ? tx : 0,
            y: Validate.isValid(ty) ? ty : 0,
        };
        return (xy.x || xy.y) ? `translate(${xy.x} ${xy.y})` : null;
    }

    static Transform(props) {

        const {
            x, // translateX,
            y, // translateY,
            scale,
            scalex,
            scaley,
            rotation,
            rotationx,
            rotationy,
        } = props;

        const svgProps = {
            ...props,
            x: undefined,
            y: undefined,
            scale: undefined,
            scalex: undefined,
            scaley: undefined,
            rotation: undefined,
            rotationx: undefined,
            rotationy: undefined,
        };

        var xforms = [];
        [
            GUtils.#Translate(x, y),
            GUtils.#Rotate(rotation, rotationx, rotationy),
            GUtils.#Scale(scale, scalex, scaley),
        ].forEach(xform => {
            if (Validate.isValid(xform)) {
                xforms.push(xform);
            }
        });

        if (!xforms.length) {
            return { svgProps };
        }

        var result = ' ';
        xforms.forEach(xform => { result += `${xform} `; });

        return {
            svgProps,
            transform: result.trim(),
        };
    }
}


export const G = props => {
    const { svgProps, transform } = GUtils.Transform(props);
    Console.stack(NAME, props, { svgProps, transform });
    return (<Group {...svgProps} transform={transform} />);
};
