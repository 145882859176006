import { Console } from '../Console';
import { Validate } from '../Validate';

let analyzer = null;
let context = null;
let audio = null;


export class Context {

    static DEFAULT_FFT_SIZE = 1024;

    static Reset() {
        analyzer = Context.Context().createAnalyser();
        analyzer.fftSize = Context.DEFAULT_FFT_SIZE;
    }

    static Context() {
        if (!context) {
            context = new (window.AudioContext || window.webkitAudioContext)();
        }
        return context;
    }

    static Analyzer(fftSize = 0) { // MARKMARK enforce valid range
        if (!analyzer) {
            Context.Reset();
        }
        if (fftSize) {
            analyzer.fftSize = Math.pow(2, fftSize);
        }
        return analyzer;
    }

    static Audio(value = null) {
        if (Validate.isValid(value)) {
            Console.log('Context.Audio', { value });
            audio = value;
        }
        return audio;
    }
}
