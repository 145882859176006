import { Detail, Graph, Search } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Search,

    TabArray: ['Search'],

    Tabs: {
        Search: {
            Component: Search,
            Icon: Icons.Search,
            children: new Set(['Graph', 'Detail']),
        },
    },

    Stacks: {
        Graph: {
            Component: Graph,
        },
        Detail: {
            Component: Detail,
        },
    },

};
