let THREE = null;

export class Three {
    static Set(three) {
        if (!THREE && three) {
            THREE = three;
        }
    }

    static Get() {
        return THREE;
    }
}
