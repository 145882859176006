import React, { useEffect, useRef } from 'react';
import { useBottomTabBarHeight } from '@react-navigation/bottom-tabs';
import { useHeaderHeight } from '@react-navigation/elements';
import { SYSTEM_TYPES, useSystemDispatch } from '../../context';
import { Console } from '../../utils';

const NAME = 'ScreenSettings';


export const ScreenSettings = props => {

    const {
        hasHeader,
        hasTab,
    } = props;

    Console.stack(NAME, props);

    if (hasHeader && hasTab) {
        return (<HeaderAndTabSettings />);
    } else if (hasHeader) {
        return (<HeaderSettings />);
    } else if (hasTab) {
        return (<TabSettings />);
    } else {
        return (<NoHeaderOrTabSettings />);
    }
};

const HeaderSettings = () => {

    const headerHeight = useHeaderHeight();
    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    useEffect(
        () => {
            Console.log('HeaderSettings useEffect', { headerHeight });
            systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_HEIGHTS,
                payload: {
                    headerHeight,
                    tabBarHeight: 0,
                },
            });
        },
        [
            headerHeight,
            systemDispatchRef,
        ],
    );

    Console.stack('HeaderSettings', {}, { headerHeight });
    return (<></>);
};

const TabSettings = () => {

    const tabBarHeight = useBottomTabBarHeight();
    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    useEffect(
        () => {
            Console.log('TabSettings useEffect', { tabBarHeight });
            systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_HEIGHTS,
                payload: {
                    headerHeight: 0,
                    tabBarHeight,
                },
            });
        },
        [
            tabBarHeight,
            systemDispatchRef,
        ],
    );

    Console.stack('TabSettings', {}, { tabBarHeight });
    return (<></>);
};

const HeaderAndTabSettings = () => {

    const headerHeight = useHeaderHeight();
    const tabBarHeight = useBottomTabBarHeight();
    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    useEffect(
        () => {
            Console.log('HeaderAndTabSettings useEffect', { headerHeight, tabBarHeight });
            systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_HEIGHTS,
                payload: {
                    headerHeight,
                    tabBarHeight,
                },
            });
        },
        [
            headerHeight,
            tabBarHeight,
            systemDispatchRef,
        ],
    );

    Console.stack('HeaderAndTabSettings', { headerHeight, tabBarHeight });
    return (<></>);
};

const NoHeaderOrTabSettings = () => {

    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    useEffect(
        () => {
            Console.log('NoHeaderOrTabSettings useEffect');
            systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_HEIGHTS,
                payload: {
                    headerHeight: 0,
                    tabBarHeight: 0,
                },
            });
        },
        [
            systemDispatchRef,
        ],
    );

    Console.stack('NoHeaderOrTabSettings');
    return (<></>);
};
