import React from 'react';
import { StyleSheet } from 'react-native';
import { Picker as RNPicker } from '@react-native-picker/picker';
import { View } from './View';
//import { toDimension } from './utils'; // MARKMARK
import { useAppState, useSystemState } from '../context';
import { Console, Validate } from '../utils';
import { Colors } from '../styles';

const NAME = 'Picker';

export const PICKER_VARIANTS = {
    DIALOG: 'dialog',
    DROPDOWN: 'dropdown',
};

const DEFAULT_VARIANT = PICKER_VARIANTS.DIALOG;

const MAGIC_HEIGHT = 65;
const DEFAULT_HEIGHT = 65;
const DEFAULT_WIDTH = 150;

const WHITE = Colors.colors.white;
const GRAY = Colors.colors.darkgray;
const BORDER = { width: 1, color: Colors.colors.gray };

class PickerUtils {
    static ScaleStyle(_width, height, borderWidth) {
        const scale = height <= MAGIC_HEIGHT
            ? height / MAGIC_HEIGHT
            : 1;
        const width = (_width / scale) - 2 * borderWidth;
        const origin = {
            x: width * 0.5,
            y: (height / scale) * 0.5,
        };
        return {
            width,
            height,
            transform: [
                { translateX: -origin.x },
                { translateY: -origin.y },
                { scale: scale },
                { translateX: origin.x },
                { translateY: origin.y },
            ],
        };
    }
}


export const Picker = React.forwardRef((props, ref) => {

    const {
        selected,
        items,
        variant,
        onChange,
        style,
        viewStyle,
        width,
        height,
    } = props;

    const { isWeb } = useSystemState();
    const { dark } = useAppState();

    const backgroundColorStyle = {
        backgroundColor: dark ? GRAY : WHITE,
    };
    const scaleStyle = PickerUtils.ScaleStyle(
        Validate.isValid(width) ? width : DEFAULT_WIDTH,
        Validate.isValid(height) ? height : DEFAULT_HEIGHT,
        isWeb ? 0 : BORDER.width);

    Console.stack(NAME, props, { isWeb, dark, backgroundColorStyle, scaleStyle });

    return (
        <View
            value={'PickerView'}
            style={[styles.view, isWeb ? {} : styles.border, viewStyle]}
        >
            <RNPicker
                ref={ref}
                style={[styles.picker, scaleStyle, backgroundColorStyle, style]}
                selectedValue={selected}
                onValueChange={onChange}
                mode={variant ? variant : DEFAULT_VARIANT}
                dropdownIconColor={dark ? WHITE : GRAY}
            >
                {items.map((item, index) => (
                    <RNPicker.Item
                        key={index}
                        label={item}
                        value={item}
                    />
                ))}
            </RNPicker>
        </View>
    );
});

const styles = StyleSheet.create({
    view: {
    },
    picker: {
    },
    border: {
        borderColor: BORDER.color,
        borderWidth: BORDER.width,
    },
});
