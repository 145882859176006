export class Time {

    static getFileTimetamp() {
        const ts = (new Date()).toISOString();
        return ts.replace('-', '_')
            .replace('-', '_')
            .replace(':', '_')
            .replace(':', '_')
            .replace('T', '_') // TODO MARKMARK
            .substring(0, 19);
    }
}
