import { Icons } from '../media';
import { Colors } from '../styles';
import { Console, Numbers } from '../utils';
import { ModeMap as TransitModeMap, MODES as TRANSIT_MODES } from './TransitMode';


const FindNearestCoordinateIndex = (coordinate, coordinates) => {
    var distance = Number.POSITIVE_INFINITY;
    var index = -1;
    if (coordinates?.length && coordinate?.latitude && coordinate?.longitude) {
        coordinates.forEach((c, i) => {
            if (c?.latitude && c?.longitude) {
                const dLat = Math.abs(c.latitude - coordinate.latitude);
                const dLng = Math.abs(c.longitude - coordinate.longitude);
                const dist = Numbers.distance(dLat, dLng);
                if (dist < distance) {
                    distance = dist;
                    index = i;
                }
            }
        });
    }
    return { index, distance, coordinate, path: coordinates[index] };
};

const TimeDelta = (future, now) => {
    const secs = future - now;
    const mins = Math.round(secs / 60.0);
    const hrs = Math.floor(mins / 60.0);
    const newMins = mins - hrs * 60;
    return `${hrs}:${Numbers.padLeft(newMins, 2)}`;
};

const DecodeDirectionsStep = (navTransitMode, step, coordinates, ja, now, longDestination, shortDestination) => {
    const {
        distance,
        duration,
        end_location,
        html_instructions,
        start_location,
        travel_mode,
    } = step;
    var stepData = {
        distance: distance?.text,
        duration: duration?.text,
        start: { location: { latitude: start_location?.lat, longitude: start_location?.lng } },
        end: { location: { latitude: end_location?.lat, longitude: end_location?.lng } },
        transitMode: TransitModeMap.get(travel_mode),
        details: [],
    };
    if (ja && distance?.value) {
        stepData.distance = distance.value >= 1000
            ? `${distance.value / 1000} km`
            : `${distance.value} m`;
    }
    if (html_instructions) {
        stepData.description = html_instructions
            .replace(/<b>/g, '')
            .replace(/<\/b>/g, '')
            .replace(/<wbr\/>/g, '')
            .replace(/<div.*?>/g, ' (')
            .replace(/<\/div>/g, ')');
        const indexOfLongDestination = stepData.description.indexOf(longDestination);
        if (indexOfLongDestination >= 0) {
            stepData.description = `${stepData.description.substring(0, indexOfLongDestination)}${shortDestination}${stepData.description.substring(indexOfLongDestination + longDestination.length)}`;
        }
    }
    if (step?.transit_details) {
        const {
            arrival_stop,
            arrival_time,
            departure_stop,
            departure_time,
            headsign,
            line,
            num_stops,
        } = step.transit_details;
        stepData.departureStop = departure_stop?.name;
        stepData.departureTime = departure_time?.text;
        stepData.arrivalStop = arrival_stop?.name;
        stepData.arrivalTime = arrival_time?.text;
        stepData.route = line?.short_name;
        stepData.headsign = headsign;
        stepData.numStops = num_stops;
        stepData.start.icon = Icons.MapBusStops;
        stepData.start.color = Colors.colors.lightgreen;
        stepData.end.icon = Icons.MapBusStops;
        stepData.end.color = Colors.colors.red;

        stepData.details.push(`🚏 ${stepData.numStops}: ${stepData.arrivalStop}`);
        stepData.details.push(`📏 ${stepData.distance}, ⏳ ${stepData.duration}`);

        if (stepData.departureTime && departure_time?.value) {
            stepData.departureTime.replace(' ', ' ');
            stepData.details.push(`⌚️ ${stepData.departureTime}, ⏱ ${TimeDelta(departure_time?.value, now)}`);
        }
        if (stepData.arrivalTime) {
            stepData.arrivalTime.replace(' ', ' ');
        }
    } else if (navTransitMode === TRANSIT_MODES.TRANSIT) {
        stepData.details.push(`📏 ${stepData.distance}, ⏳ ${stepData.duration}`);
    }

    const _departureStop = stepData?.departureStop ? stepData.departureStop : '';
    const _routeDescr = stepData?.route && stepData?.headsign
        ? `${stepData.transitMode.emoji} ${stepData.route}: ${stepData.headsign}`
        : navTransitMode === TRANSIT_MODES.TRANSIT
            ? `${stepData.transitMode.emoji} ${stepData.description}`
            : '';

    stepData.details = [
        _departureStop,
        _routeDescr,
        ...stepData.details,
    ];

    const start_index = FindNearestCoordinateIndex(stepData.start.location, coordinates);
    const end_index = FindNearestCoordinateIndex(stepData.end.location, coordinates);

    stepData.startIndex = start_index.index;
    stepData.endIndex = end_index.index;

    return stepData;
};
/*
const TestIndices = (data, len) => {
    var result = true;
    data.legs.forEach(x => {
        if (x.startIndex < 0 || x.endIndex < 0) {
            Console.LOG('\n\nMARKMARK DecodeDirections MISSING INDEX');
            result = false;
        }
    });
    if (data.legs[0].startIndex !== 0 ||
        data.legs[data.legs.length - 1].endIndex !== len - 1) {
        Console.LOG('\n\nMARKMARK DecodeDirections BAD END INDEX', { len });
        result = false;
    }
    for (let x = 1; x < data.legs.length; x++) {
        if (Math.abs(data.legs[x].startIndex - data.legs[x - 1].endIndex) > 1) {
            Console.LOG('\n\nMARKMARK DecodeDirections BAD MIDDLE INDEX');
            result = false;
        }
    }
    return result;
};
*/
export const DecodeDirections = (transitMode, directions, language, holo) => {
    const { coordinates, distance, duration, fares, legs } = directions;
    const ja = language.substring(0, 2) === 'ja';
    var now = new Date();
    now = Math.round(now.getTime() / 1000.0);
    if (fares?.length !== 1) {
        Console.LOG('\n\nMARKMARK DecodeDirections bad fares', { directions, fares });
    }
    if (legs?.length !== 1) {
        Console.LOG('\n\nMARKMARK DecodeDirections bad legs', { directions, legs });
    }
    var data = {
        meters: Math.round(distance * 1000),
        seconds: Math.round(duration * 60),
        distance: legs[0]?.distance?.text,
        duration: legs[0]?.duration?.text,
        arrivalAddress: legs[0]?.end_address.split(',')[0],
        departureAddress: legs[0]?.start_address.split(',')[0],
        coordinates,
    };
    if (ja) {
        data.distance = distance >= 1
            ? `${distance} km`
            : `${data.meters} m`;
    }
    data.departureDetails = [
        `🧍‍♂️ ${data.departureAddress}`,
    ];
    data.arrivalDetails = [
        `🏁 ${data.arrivalAddress}`,
    ];
    if (legs[0]?.departure_time?.text) {
        data.departureTime = legs[0].departure_time.text;
        data.departureDetails.push(`⏰ ${data.departureTime}, ⏱️ ${TimeDelta(legs[0].departure_time.value, now)}`);
    }
    if (legs[0]?.arrival_time?.text) {
        data.arrivalTime = legs[0].arrival_time.text;
        data.arrivalDetails.push(`⏰ ${data.arrivalTime}`);
    }
    data.arrivalDetails.push(`📏 ${data.distance}, ⏳ ${data.duration}`);
    if (fares[0]?.text) {
        data.fare = fares[0]?.text;
        if (holo) {
            data.departureDetails.push(`💵 ${data.fare}, Holo: $${holo.toFixed(2)}`);
        } else {
            data.departureDetails.push(`💵 ${data.fare}`);
        }
    }
    if (legs[0]?.steps?.length) {
        data.legs = [];
        legs[0].steps.forEach(step => {
            data.legs.push(DecodeDirectionsStep(transitMode, step, coordinates, ja, now, legs[0]?.end_address, data.arrivalAddress));
        });
    }

    data.departureDetails = [
        ...data.departureDetails.slice(0, 1),
        ...data.legs[0].details,
        ...data.departureDetails.slice(1),
    ];

    //TestIndices(data, coordinates?.length);

    data.legs.forEach(leg => {
        leg.path = coordinates.slice(leg.startIndex, leg.endIndex + 1);
    });

    return data;
};
