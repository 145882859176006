// https://dzone.com/articles/exploring-html5-web-audio
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Visualizations_with_Web_Audio_API
// http://stackoverflow.com/questions/22312841/waveshaper-node-in-webaudio-how-to-emulate-distortion

import { Context } from './Context';
import { Console } from '../Console';

const NAME = 'Graphics';


export class Graphics {

    static SineWave(canvas, canvasContext, canvasStyle, fftSize, scale) {

        Console.trace(`${NAME}.SineWave`, { canvas, canvasContext, canvasStyle, fftSize, scale });

        const { width, height, backgroundColor, strokeColor, strokeWidth } = canvasStyle;

        let analyzer = Context.Analyzer(fftSize);
        const bufferLength = analyzer.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const sliceWidth = width * 1.0 / bufferLength;
        const yOffset = height / 2;
        const denom = 128.0;

        if (canvasContext) {
            canvasContext.clearRect(0, 0, width, height);
        }

        const draw = () => {

            requestAnimationFrame(draw);
            Context.Analyzer(fftSize).getByteTimeDomainData(dataArray);

            canvasContext.strokeStyle = strokeColor;
            canvasContext.fillStyle = backgroundColor;
            canvasContext.lineWidth = strokeWidth;
            canvasContext.fillRect(0, 0, width, height);

            canvasContext.beginPath();

            let x = 0;
            for (let i = 0; i < bufferLength; i++) {

                const y = yOffset * (dataArray[i] / denom);

                if (i === 0) {
                    canvasContext.moveTo(x, y);
                } else {
                    canvasContext.lineTo(x, y);
                }

                x += sliceWidth;
            }

            canvasContext.lineTo(width, yOffset);
            canvasContext.stroke();
        };

        draw();
    }
}
