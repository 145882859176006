// http://soundfile.sapp.org/doc/WaveFormat/
import { Platform } from 'react-native';
import { Console } from './Console';
import { Numbers } from './Numbers';
import { MIN_RECORDING_SECONDS, MAX_RECORDING_SECONDS } from '../constants';


const NAME = 'Wav';

const MAGIC_SAMPLE_LIMIT = 5 * 9196570; // maximum number of samples for 2-channels using base64 (1M max payload)

const BIT = 1;
const CHAR = BIT * 2;
const WORD = CHAR * 2;
const BYTE = WORD * 2;

const SLICE = Platform.OS === 'web' ? MAGIC_SAMPLE_LIMIT : 8;

const HEADER_LEN = 44; // PCM
const HEADER = {
    ChunkID: 'RIFF',
    Format: 'WAVE',
    SubChunk1ID: 'fmt ',
    Subchunk1Size: 16, // PCM
    AudioFormat: 1, // PCM
    Subchunk2ID: 'data',
};


export class Wav {
    constructor(uint8s) {
        this.uint8s = uint8s;

        // decode and store the header
        this.header = Wav.decodeHeader(uint8s.slice(0, HEADER_LEN));
        const { NumChannels, BitsPerSample } = this.header;

        // decode and store the audio data
        this.samples = Wav.decodeSamples(uint8s.slice(HEADER_LEN), NumChannels, BitsPerSample);
    }

    getWav() {
        //const { NumChannels, BitsPerSample } = this.header;
        //return Wav.createWav(Wav.encodeHeader(this.header), Wav.encodeSamples(BitsPerSample / NumChannels, this.samples));
        return this.uint8s;
    }

    static createSinWave(numChannels, bitsPerSample, sampleRate, frequency, amplitude, seconds) {

        const numSamples = seconds * sampleRate;
        const samplesPerCycle = [
            Math.round(sampleRate / frequency[0]),
            Math.round(sampleRate / frequency[1]),
        ];

        // create the new header
        const newHeader = Wav.createHeader(
            +sampleRate,
            +numChannels,
            +bitsPerSample,
            +numSamples,
        );

        const PI2 = 2 * Math.PI;
        var newSamples = [];
        for (let i = 0; i < numSamples; i++) {
            var channels = [];
            for (let j = 0; j < numChannels; j++) {
                channels.push(amplitude[j] * Math.sin(i * PI2 / samplesPerCycle[j]));
            }
            newSamples.push(channels);
        }

        const encodedHeader = Wav.encodeHeader(newHeader);
        const encodedSamples = Wav.encodeSamples(bitsPerSample / numChannels, newSamples);

        // create the new wav array buffer
        return Wav.createWav(encodedHeader, encodedSamples);
    }

    static analyze(data, numChannels, bitsPerSample, sampleRate) {

        var result = [];

        // voice criteria...sustained high-frequency amplitude variation
        var minAmplitude = 0.25; // % of dynamic range
        const duration = 4; // 3 consecutive windows sizes
        const windowSize = 100; // number of slices per second
        const window = sampleRate / windowSize;

        // the result array is a represents amplitude ranges aggregated over a period of time (window)
        var maxRange = 0;
        var minValue = 0;
        var maxValue = 0;
        for (let i = 0; i < data.length - window; i += window) {
            var channels = [];
            for (let c = 0; c < numChannels; c++) {
                var min = 0;
                var max = 0;
                for (let j = 0; j < window; j++) {
                    const value = data[i + j][c];
                    if (value < min) {
                        min = value;
                        if (value < minValue) {
                            minValue = value;
                        }
                    }
                    if (value > max) {
                        max = value;
                        if (value > maxValue) {
                            maxValue = value;
                        }
                    }
                }
                const range = max - min;
                if (range > maxRange) {
                    maxRange = range;
                }

                channels.push(range);
            }
            result.push(channels);
        }

        minAmplitude *= maxRange;

        var count = 0;
        var first = -1;
        for (let s = 1; s < result.length && first < 0; s++) {
            var cmax = 0;
            for (let c = 0; c < result[s].length; c++) {
                const value = Math.abs(result[s][c]);
                if (value > cmax) {
                    cmax = value;
                }
            }
            if (cmax > minAmplitude) {
                count++;
                if (count > duration) {
                    first = s - duration - 1;
                }
            } else {
                count = 0;
            }
        }

        count = 0;
        var last = -1;
        for (let s = result.length - 2; s >= 0 && last < 0; s--) {
            var cmax = 0;
            for (let c = 0; c < result[s].length; c++) {
                const value = Math.abs(result[s][c]);
                if (value > cmax) {
                    cmax = value;
                }
            }
            if (cmax > minAmplitude) {
                count++;
                if (count > duration) {
                    last = s + duration + 2;
                }
            } else {
                count = 0;
            }
        }

        if (last <= first) {
            first = 0;
            last = result.length;
        }

        first *= window;
        last *= window;

        Console.log(`${NAME}.analyze exit`, { data: data.slice(0, SLICE), numChannels, bitsPerSample, sampleRate, duration, windowSize, window, minAmplitude, minValue, maxValue, maxRange, first, last });
        return { first, last };
    }

    getHeader() {
        return { ...this.header };
    }

    getChannel(n) {
        if (n === 0) {
            return this.samples.map(s => s[0]);
        }
        if (n === 1 && this.header.NumChannels === 2) {
            return this.samples.map(s => s[1]);
        }
        return [];
    }

    getSample(index) {
        if (index < 0 || index > this.samples.length) {
            Console.error(`${NAME}.getSample index ${index} out of range ${this.samples.length}`);
            return null;
        }
        return [...this.samples[index]];
    }

    convert(minSampleRate, numChannels, minBitsPerChannel, trim = false) {

        // validates the channels
        if (numChannels < 1 || numChannels > 2) {
            Console.error(`${NAME}.convert bad channels ${numChannels}`);
            return null;
        }

        // compute the sample rate
        var sampleRate = this.header.SampleRate;
        if (sampleRate > minSampleRate && !(sampleRate % minSampleRate)) {
            sampleRate = minSampleRate;
        }

        // compute the bits per channel
        const bitsPerChannel = minBitsPerChannel;
        /*
        var bitsPerChannel = this.header.BitsPerSample / this.header.NumChannels;
        while (bitsPerChannel / 2 >= minBitsPerChannel) {
            bitsPerChannel /= 2;
        }
        */

        // compute the bits per sample
        const bitsPerSample = bitsPerChannel * numChannels;
        if (bitsPerSample % BYTE) {
            Console.error(`${NAME}.convert bad bits per sample ${bitsPerSample} (${numChannels}, ${bitsPerChannel})`);
            return null;
        }

        // do analysis lazily
        if (!this.analysis) {
            const { NumChannels, BitsPerSample, SampleRate } = this.header;
            this.analysis = Wav.analyze(this.samples, NumChannels, BitsPerSample, SampleRate);
            Console.log(`${NAME}.convert analysis`, { analysis: this.analysis });
        }

        // compute the sample range based on time constraints, etc
        const minTimeSamples = MIN_RECORDING_SECONDS * this.header.SampleRate;
        const maxTimeSamples = MAX_RECORDING_SECONDS * this.header.SampleRate;
        var maxSamples = maxTimeSamples > MAGIC_SAMPLE_LIMIT
            ? MAGIC_SAMPLE_LIMIT
            : maxTimeSamples;
        if (maxSamples > this.samples.length) {
            maxSamples = this.samples.length;
        }
        const minSamples = this.samples.length < minTimeSamples
            ? this.samples.length
            : minTimeSamples;

        // set the sample range based on the threshold analysis
        var first, last;
        if (trim) {
            first = this.analysis.first;
            last = this.analysis.last;
        } else {
            first = 0;
            last = this.samples.length;
        }

        // adjust the last index based on allowed sample range
        var numSamples = last - first;
        if (numSamples > maxSamples) {
            last = maxSamples - first;
        }
        if (numSamples < minSamples) {
            last = first + minSamples;
            if (last > this.samples.length) {
                last = this.samples.length;
                first = last - minSamples;
                if (first < 0) {
                    first = 0;
                }
            }
        }
        numSamples = last - first;

        // create the new header
        const newHeader = Wav.createHeader(
            sampleRate,
            numChannels,
            bitsPerSample,
            numSamples,
        );

        // convert the sample data
        const newSamples = Wav.convertSamples(
            numChannels,
            sampleRate,
            this.header.SampleRate,
            this.samples.slice(first, last),
        );

        // encode (and test) the header
        const encodedHeader = Wav.encodeHeader(newHeader);
        Wav.testHeaderEncoding(encodedHeader, newHeader);

        // encode the samples
        const { NumChannels, BitsPerSample } = newHeader;
        const encodedSamples = Wav.encodeSamples(BitsPerSample / NumChannels, newSamples);
        Wav.testSamplesEncoding(encodedSamples, newSamples, NumChannels, BitsPerSample);

        // create the new wav array buffer
        const result = Wav.createWav(encodedHeader, encodedSamples);
        Console.log(`${NAME}.convert exit`, { sampleRate, numChannels, bitsPerChannel, bitsPerSample, minSampleRate, SampleRate: this.header.SampleRate, NumChannels: this.header.NumChannels, minBitsPerChannel, BitsPerSample: this.header.BitsPerSample, minTimeSamples, maxTimeSamples, minSamples, maxSamples, first, last, numSamples, samples: this.samples.slice(first, first + SLICE), newHeader, newSamples: newSamples.slice(0, SLICE) });
        return result;
    }

    static createHeader(SampleRate, NumChannels, BitsPerSample, numSamples) {

        const BlockAlign = NumChannels * BitsPerSample / BYTE;
        const Subchunk2Size = numSamples * BlockAlign;

        const result = {
            ...HEADER,
            ChunkSize: WORD + (BYTE + HEADER.Subchunk1Size) + (BYTE + Subchunk2Size),
            NumChannels,
            SampleRate,
            ByteRate: SampleRate * BlockAlign,
            BlockAlign,
            BitsPerSample,
            Subchunk2Size,
        };

        Console.log(`${NAME}.createHeader`, { SampleRate, NumChannels, BitsPerSample, numSamples, Subchunk2Size, result });
        return result;
    }

    static convertSamples(numChannels, sampleRate, originalSampleRate, originalSamples) {

        // validate sample rate
        if (sampleRate > originalSampleRate || originalSampleRate % sampleRate) {
            Console.error(`${NAME}.convertSamples base sample rate ${sampleRate} (${originalSampleRate})`);
            return null;
        }

        // compute some useful values
        const originalNumChannels = originalSamples[0].length;
        const sampleRateRatio = originalSampleRate / sampleRate;
        const numSamples = originalSamples.length / sampleRateRatio;

        Console.log(`${NAME}.convertSamples enter`, { numChannels, sampleRate, originalSampleRate, originalSamples: originalSamples.slice(0, SLICE), originalNumChannels, sampleRateRatio, numSamples });

        // convert the samples
        var result = [];
        for (let s = 0; s < numSamples; s++) {
            var channels = [];

            // skip samples as necessary
            const originalSample = originalSamples[s * sampleRateRatio];

            // now things depend on the channel ratio...
            if (originalNumChannels > numChannels) {

                // stereo -> mono, average the stereo channels

                //var average = 0;
                //originalSample.forEach(c => { average += c; });
                //channels.push(average / originalSample.length);

                const sum = originalSample[0] + originalSample[1];
                channels.push(sum);

            } else if (originalNumChannels < numChannels) {

                // mono -> stereo, copy the mono signal to both stereo channels

                for (let c = 0; c < numChannels; c++) {
                    channels.push(originalSample[0]);
                }

            } else {

                // same channel counts, simple copy

                originalSample.forEach(c => { channels.push(c); });
            }

            result.push(channels);
        }

        Console.log(`${NAME}.convertSamples exit`, { result: result.slice(0, SLICE) });
        return result;
    }

    static createWav(encodedHeader, encodedSamples) {

        // create array buffer and data view
        const result = new ArrayBuffer(encodedHeader.length + encodedSamples.length);
        const view = new Uint8Array(result);

        // populate the array buffer with the encoded header and samples
        view.set(encodedHeader);
        view.set(encodedSamples, HEADER_LEN);

        return result;
    }

    static downSample(arr, maxLength) {
        var result = [];
        if (arr.length <= maxLength) {
            result = [...arr];
        } else {
            const combine = Math.ceil(arr.length / maxLength);
            const iMax = Math.floor(arr.length / combine);
            var result = [];
            for (let i = 0; i < iMax; i++) {
                const iIndex = i * combine;
                var max = -1.0;
                var min = 1.0;
                for (let j = 0; j < combine; j++) {
                    const value = arr[iIndex + j];
                    if (value > max) {
                        max = value;
                    }
                    if (value < min) {
                        min = value;
                    }
                }
                result.push(max);
                result.push(min);
            }
        }
        Console.log(`${NAME}.downSample`, { arr, maxLength, result });
        return result;
    }

    static encodeHeader(header) {

        const buffer = new ArrayBuffer(HEADER_LEN);
        const result = new Uint8Array(buffer);

        let i = 0;
        result.set(Wav.t2d(header.ChunkID), i); i += WORD;
        result.set(Wav.i2d(header.ChunkSize, WORD), i); i += WORD;
        result.set(Wav.t2d(header.Format), i); i += WORD;
        result.set(Wav.t2d(header.SubChunk1ID), i); i += WORD;
        result.set(Wav.i2d(header.Subchunk1Size, WORD), i); i += WORD;
        result.set(Wav.i2d(header.AudioFormat, CHAR), i); i += CHAR;
        result.set(Wav.i2d(header.NumChannels, CHAR), i); i += CHAR;
        result.set(Wav.i2d(header.SampleRate, WORD), i); i += WORD;
        result.set(Wav.i2d(header.ByteRate, WORD), i); i += WORD;
        result.set(Wav.i2d(header.BlockAlign, CHAR), i); i += CHAR;
        result.set(Wav.i2d(header.BitsPerSample, CHAR), i); i += CHAR;
        result.set(Wav.t2d(header.Subchunk2ID), i); i += WORD;
        result.set(Wav.i2d(header.Subchunk2Size, WORD), i); i += WORD;

        return result;
    }

    static decodeHeader(arr) {

        var result = {};

        let i = 0;
        result.ChunkID = Wav.d2t(arr.slice(i, i += WORD));
        result.ChunkSize = Wav.d2i(arr.slice(i, i += WORD));
        result.Format = Wav.d2t(arr.slice(i, i += WORD));
        result.SubChunk1ID = Wav.d2t(arr.slice(i, i += WORD));
        result.Subchunk1Size = Wav.d2i(arr.slice(i, i += WORD));
        result.AudioFormat = Wav.d2i(arr.slice(i, i += CHAR));
        result.NumChannels = Wav.d2i(arr.slice(i, i += CHAR));
        result.SampleRate = Wav.d2i(arr.slice(i, i += WORD));
        result.ByteRate = Wav.d2i(arr.slice(i, i += WORD));
        result.BlockAlign = Wav.d2i(arr.slice(i, i += CHAR));
        result.BitsPerSample = Wav.d2i(arr.slice(i, i += CHAR));
        result.Subchunk2ID = Wav.d2t(arr.slice(i, i += WORD));
        result.Subchunk2Size = Wav.d2i(arr.slice(i, i += WORD));

        return result;
    }

    static encodeSamples(bitsPerChannel, samples) {

        if (!samples) {
            Console.error(`${NAME}.encodeSamples null samples`);
            return null;
        }

        const numSamples = samples.length;
        const numChannels = samples[0].length;
        const numValues = numSamples * numChannels;
        const channelStep = bitsPerChannel / BYTE;
        const sampleStep = channelStep * numChannels;
        const range = Numbers.signedRange(bitsPerChannel);

        const buffer = new ArrayBuffer(numValues * channelStep);
        const result = new Uint8Array(buffer);

        for (let s = 0; s < numSamples; s++) {
            const sIndex = s * sampleStep;
            for (let c = 0; c < numChannels; c++) {
                const index = sIndex + c * channelStep;
                const normalized = samples[s][c];
                const value = Math.round(normalized * range);
                const dec = Wav.i2d(value, channelStep);
                result.set(dec, index);
            }
        }

        Console.log(`${NAME}.encodeSamples`, { bitsPerChannel, samples: samples.slice(0, SLICE), numSamples, numChannels, numValues, sampleStep, channelStep, range, result: result.slice(0, SLICE) });
        return result;
    }

    static decodeSamples(d, numChannels, bitsPerSample) {

        const bitsPerChannel = bitsPerSample / numChannels;
        const channelStep = bitsPerChannel / BYTE;
        const sampleStep = channelStep * numChannels;
        const numSamples = d.length / sampleStep;
        const range = Numbers.signedRange(bitsPerChannel);
        const rangeInv = 1.0 / range;

        var result = [];
        var values = [];
        for (let s = 0; s < numSamples; s++) {
            const sIndex = s * sampleStep;
            var channels = [];
            var vchannels = [];
            for (let c = 0; c < numChannels; c++) {
                const index = sIndex + c * channelStep;
                const value = Wav.d2i(d.slice(index, index + channelStep));
                const normalized = value * rangeInv;
                if (normalized > 1) {
                    Console.log('ERROR', { bitsPerChannel, range, value, normalized });
                }
                channels.push(normalized);
                vchannels.push(value);
            }
            result.push(channels);
            values.push(vchannels);
        }

        Console.log(`${NAME}.decodeSamples`, { d: d.slice(0, SLICE), numChannels, bitsPerSample, numSamples, bitsPerChannel, sampleStep, channelStep, range, result: result.slice(0, SLICE), values: values.slice(0, SLICE) });
        return result;
    }

    // convert decimal to string, big endian
    static d2t(d) {
        var t = '';
        d.forEach(c => { t += String.fromCharCode(c); });
        return t;
    }

    // convert string to decimal, big endian
    static t2d(t) {
        var d = [];
        for (let i = 0; i < t.length; i++) {
            d.push(t.charCodeAt(i));
        }
        return d;
    }

    // convert integer to decimal, little endian
    static i2d(i, n) {
        const b = Wav.i2b(i, n);
        const d = Wav.b2d(b);
        //Console.log(`${NAME}.i2d`, { i, n, b, d });
        return d;
    }

    // convert decimal to integer, little endian
    static d2i(d) {
        var b = Wav.d2b(d);
        var m = 1;
        if (b[0] === '1') {
            b = Wav.bFrom2sComplement(b);
            m = -1;
        }
        var i = m * Wav.b2i(b);
        //Console.log(`${NAME}.d2i`, { d, b, i });
        return i;
    }

    // convert decimal to binary, little endian
    static d2b(d) {
        var b = '';
        [...d].reverse().forEach(u => { b += u.toString(2).padStart(BYTE, '0'); });
        //Console.log(`${NAME}.d2b`, { d, b });
        return b;
    }

    // convert binary to decimal
    static b2d(barr) {
        const result = barr.map(b => Wav.b2i(b));
        //Console.log(`${NAME}.b2d`, { barr, result });
        return result;
    }

    // convert binary to integer
    static b2i(b) {
        const i = parseInt(b, 2);
        //Console.log(`${NAME}.b2i`, { b, i });
        return i;
    }

    // convert integer to binary, little endian
    static i2b(i, n) {

        var b = Wav.bFromInteger(i, n);

        var result = [];
        for (let j = 0; j < n; j++) {
            const index = j * BYTE;
            result.push(b.substring(index, index + BYTE));
        }
        result.reverse();
        //Console.log(`${NAME}.i2b`, { i, n, b, result });
        return result;
    }

    static s2u(s) {
        return (new Uint8Array([s]))[0];
    }

    static u2s(u) {
        return (new Int8Array([u]))[0];
    }

    static bInvert(b) {
        var inv = '';
        for (let i = 0; i < b.length; i++) {
            inv += b[i] === '1' ? '0' : '1';
        }
        //Console.log(`${NAME}.bInvert`, { b, inv });
        return inv;
    }

    static bAdd1(b) {
        return Wav.bMod1(b, '0');
    }

    static bSub1(b) {
        return Wav.bMod1(b, '1');
    }

    static bMod1(b, value) {
        var last = b.lastIndexOf(value);
        if (last === -1) {
            Console.error(`${NAME}.bMod1(${value}) overflow ${b}`);
            return null;
        }
        const mod1 = b.slice(0, last) + Wav.bInvert(b.slice(last));
        //Console.log(`${NAME}.bMod1(${value})`, { b, last, mod1 });
        return mod1;
    }

    static bTo2sComplement(b) {
        return Wav.bAdd1(Wav.bInvert(b));
    }

    static bFrom2sComplement(b) {
        return Wav.bInvert(Wav.bSub1(b));
    }

    static bFromInteger(i, n) {
        var b = Math.abs(i).toString(2).padStart(n * BYTE, '0');
        return i < 0 ? Wav.bTo2sComplement(b) : b;
    }

    // https://exstrom.com/journal/sigproc/dsigproc.html
    static butterworth(order, minFrequency, maxFrequency, sampleRate, data) {
        const parms = Wav.butterworthBandPassParams(order, minFrequency, maxFrequency, sampleRate);
        return Wav.butterworthBandPass(parms, data);
    }

    static butterworthBandPass(parms, data) {
        const { A, d } = parms;
        const numChannels = data[0].length;
        var w = [
            [ [], [], [], [], [] ],
            [ [], [], [], [], [] ],
        ];
        var result = [];
        for (let i = 0; i < data.length; i++) {
            var channels = [];
            for (let j = 0; j < numChannels; j++) {
                const value = data[i][j];
                var dwSum = 0;
                for (let k = 0; k < 4; k++) {
                    dwSum += d[k][i] * w[j][k][i];
                }
                w[j][4][i] = value + dwSum;
                channels.push(A[i] * (w[j][4][i] - 2.0 * w[j][1][i] + w[j][3][i]));
                w[j][3][i] = w[j][2][i];
                w[j][2][i] = w[j][1][i];
                w[j][1][i] = w[j][0][i];
                w[j][0][i] = w[j][4][i];
            }
            result.push(channels);
        }
        return result;
    }

    static butterworthBandPassParams(order, minFrequency, maxFrequency, sampleRate) {

        const fsum = maxFrequency + minFrequency;
        const fdif = maxFrequency - minFrequency;

        const t0 = Math.PI / sampleRate;
        const t1 = t0 * fsum;
        const t2 = t0 * fdif;

        const a = Math.cos(t1) / Math.cos(t2);
        const b = Math.tan(t2);
        const a2 = a * a;
        const b2 = b * b;


        var A = [];
        var d = [ [], [], [], [] ];
        for (let i = 0; i < order / 4; i++) {

            const r = Math.sin(Math.PI * (2.0 * i + 1.0)) / order;

            const br = b * r;
            const br2 = 2.0 * br;
            const a22 = 2.0 * a2;
            const s = b2 + br2 + 1.0;
            const x = b2 - a22 - 1.0;
            const y = b2 - br2 + 1.0;
            const fourAPerS = 4.0 * a / s;

            A.push(b2 / s);
            d[0].push(fourAPerS * (1.0 + br));
            d[1].push(2.0 * x / s);
            d[2].push(fourAPerS * (1.0 - br));
            d[3].push(-1.0 * y / s);
        }

        return { A, d };
    }

    static testHeaderEncoding(encodedHeader, header) {
        const confirmHeader = Wav.decodeHeader(new Uint8Array(encodedHeader));
        if (Object.keys(header).length !== Object.keys(confirmHeader).length) {
            Console.error(`${NAME}.testHeaderEncoding1`, { encodedHeader, header, confirmHeader });
            return false;
        }
        Object.keys(header).forEach(key => {
            if (header[key] !== confirmHeader[key]) {
                Console.error(`${NAME}.testHeaderEncoding2`, { encodedHeader, header, confirmHeader });
                return false;
            }
        });
        return true;
    }

    static testSamplesEncoding(encodedSamples, samples, numChannels, bitsPerSample) {
        const confirmSamples = Wav.decodeSamples(encodedSamples, numChannels, bitsPerSample);
        if (!samples?.length || (samples.length !== confirmSamples?.length || samples[0].length !== confirmSamples[0].length)) {
            Console.error(`${NAME}.testSamplesEncoding1`, { encodedSamples: encodedSamples.slice(0, SLICE), samples: samples.slice(0, SLICE), numChannels, bitsPerSample, confirmSamples: confirmSamples.slice(0, SLICE) });
            return false;
        }
        for (let s = 0; s < samples.length; s++) {
            for (let c = 0; c < samples[s].length; c++) {
                if (samples[s][c] !== confirmSamples[s][c]) {
                    Console.error(`${NAME}.testSamplesEncoding2`, { encodedSamples: encodedSamples.slice(0, SLICE), samples: samples.slice(0, SLICE), numChannels, bitsPerSample, confirmSamples: confirmSamples.slice(0, SLICE) });
                    return false;
                }
            }
        }
        return true;
    }
}
