import { Word5 } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Grid,

    TabArray: ['Word5'],

    Tabs: {
        Word5: {
            Component: Word5,
            Icon: Icons.Grid,
        },
    },

    Stacks: {
    },

};
