import React, { useMemo, useRef, useState } from 'react';
import { Modal, StatusBar, StyleSheet } from 'react-native';
import { useAppDispatch, useAppState, useSystemState, APP_TYPES } from '../context';
import { Button } from './Button';
import { Pressable } from './Pressable';
import { Text, TEXT_VARIANTS } from './Text';
import { Video } from './Video';
import { View } from './View';
import { Console, Optional, Validate } from '../utils';
import { Colors } from '../styles';

const NAME = 'Help';

const HELP = 'HELP_';


export const Help = props => {

    const {
        screen,
        render,
        backgroundOpacity,
    } = props;

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { t, dark, help } = useAppState();
    const { headerHeight, adjHeight, width } = useSystemState();

    const [video, setVideo] = useState(false);
    const setVideoRef = useRef(setVideo);

    Console.stack(NAME, props, { video, dark, help, screen, headerHeight, adjHeight, width });

    return useMemo(
        () => {
            const headerStyle = {
                width,
                height: headerHeight,
            };
            var modalStyle = {
                width,
                height: adjHeight,
                backgroundColor: dark ? Colors.colors.black : Colors.colors.white,
                borderColor: dark ? Colors.colors.white : Colors.colors.black,
            };
            if (backgroundOpacity) {
                modalStyle.opacity = backgroundOpacity;
            }
            const paragraphs = t(`${HELP}${screen}`).split('|');
            const videoSource = `${HELP}${screen}_Video`;
            const xVideoSource = t(videoSource);

            Console.log(`${NAME} render`, { backgroundOpacity, modalStyle, dark, help, screen, videoSource, xVideoSource, paragraphs });
            //animationType={'slide|fade|none'}

            return (
                <Modal
                    transparent={true}
                    visible={help}
                    animationType={'slide'}
                    onRequestClose={() => appDispatchRef.current({ type: APP_TYPES.SET_HELP, payload: false })}
                >
                    <StatusBar
                        hidden={true}
                    />
                    <View
                        value={'HelpHeader'}
                        style={[styles.header, headerStyle]}
                    >
                        <Pressable
                            style={styles.helpIcon}
                            onPress={() => appDispatchRef.current({ type: APP_TYPES.SET_HELP, payload: false })}
                        />
                    </View>
                    <View
                        value={'HelpBody'}
                        style={[styles.modal, modalStyle]}
                    >
                        {Optional(Validate.isValid(render), (
                            <>{render}</>
                        ), (
                            <View
                                value={'HelpBodyInfo'}
                                style={styles.info}
                            >
                                {paragraphs.map((value, index) => (
                                    <Text
                                        key={`help_${index}`}
                                        value={value}
                                        noTranslate={true}
                                        variant={!index ? TEXT_VARIANTS.TITLE : null}
                                        style={!index ? styles.text : null}
                                    />
                                ))}
                            </View>
                        ))}
                        {Optional(xVideoSource !== videoSource, (
                            <>
                                <View
                                    value={'HelpVideoButton'}
                                    style={styles.videoButton}
                                >
                                    <Button
                                        value={'📽️'}
                                        onPress={() => setVideoRef.current(v => !v)}
                                    />
                                </View>
                                {Optional(video, (
                                    <View
                                        value={'HelpVideo'}
                                        style={styles.popupVideo}
                                    >
                                        <Video
                                            source={xVideoSource}
                                            youtube={true}
                                            muted={false}
                                            onEnded={() => setVideoRef.current(false)}
                                        />
                                    </View>
                                ))}
                            </>
                        ))}
                    </View>
                </Modal>
            );
        },
        [
            t,
            video,
            dark,
            headerHeight,
            adjHeight,
            width,
            help,
            screen,
            appDispatchRef,
            render,
            setVideoRef,
            backgroundOpacity,
        ],
    );
};

const styles = StyleSheet.create({
    header: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    helpIcon: {
        width: '100%',
        height: '100%',
    },
    modal: {
        opacity: 0.85,
        //borderWidth: 2,
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    info: {
        opacity: 1.0,
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    text: {
        fontWeight: 'bold',
        paddingVertical: '3%',
    },
    popupVideo: {
        position: 'absolute',
        top: '8%',
        //        left: '0%',
    },
    videoButton: {
        position: 'absolute',
        top: '1%',
        left: '80.5%',
        width: '10%',
        height: '10%',
    },
});
