import { GravityGame, ParticlesGame } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Games,

    TabArray: ['GravityGame', 'ParticlesGame'],

    Tabs: {
        GravityGame: {
            Component: GravityGame,
            Icon: Icons.Games,
        },
        ParticlesGame: {
            Component: ParticlesGame,
            Icon: Icons.Games,
        },
    },

    Stacks: {
    },

};
