import React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Emoji, Flag, FlatList, Text, View } from '../components';
import { Console, Optional, Validate } from '../utils';
import { Words } from '../styles';

const NAME = 'SearchResults';


export const SearchResults = props => {

    const {
        data,
        onPress,
    } = props;

    const renderCognates = (item, rank) => {
        const { cognates } = item;
        if (!cognates?.length) {
            return <></>;
        }

        const containerStyle = {
            left: `${(rank !== ' ' ? -5 : 0) + ((cognates.length - 1) * -3)}%`,
        };

        Console.log(`${NAME}.renderCognates`, { cognates, rank, containerStyle });

        return (
            <View
                value={'SearchResultCognates'}
                style={[styles.cognates, containerStyle]}
            >
                {cognates.map(lang => (
                    <Flag
                        key={`cognate_${lang}`}
                        containerStyle={styles.flags}
                        country={lang}
                        scale={0.5}
                    />
                ))}
            </View>
        );
    };

    const renderItem = ({ item }) => {
        const filteredPos = item.pos.filter(v => v !== 'unknown');
        const pos = filteredPos.map(v => Words.Pos.get(v).info).join(', ');
        const ipa = item.ipa ? `/${item.ipa.join('/, /')}/` : ' ';
        const rank = item.rank ? `${item.rank}` : ' ';
        const root = item.root
            ? item.root === item.key
                ? '⭐'
                : `[${item.root}]`
            : ' ';
        const titleStyle = filteredPos.length === 1
            ? { color: Words.Pos.get(item.pos[0])?.color }
            : {};

        Console.log(`${NAME}.renderItem`, { item, pos, ipa, rank, root, titleStyle });

        return (
            <Card
                id={item.id}
                title={item.key}
                titleStyle={titleStyle}
                subtitle={ipa}
                left={Optional(Validate.isValid(item?.emoji), (
                    <Emoji
                        emoji={item.emoji}
                    />
                ))}
                right={
                    <View
                        value={'SearchResultRight'}
                        style={[]}
                    >
                        <View
                            value={'SearchResultCognateRank'}
                            style={styles.cognatesRank}
                        >
                            {renderCognates(item, rank)}
                            {Optional(rank !== ' ', (
                                <Text
                                    style={styles.rightText}
                                    noTranslate={true}
                                    value={rank}
                                />
                            ))}
                        </View>
                        <Text
                            style={styles.rightText}
                            noTranslate={true}
                            value={pos}
                        />
                        <Text
                            style={styles.rightText}
                            noTranslate={true}
                            value={root}
                        />
                    </View>
                }
                onPress={value => {
                    if (Validate.isValid(onPress)) {
                        onPress(value);
                    }
                }}
            />
        );
    };

    return (
        <FlatList
            data={data}
            renderItem={renderItem}
        />
    );
};

const styles = StyleSheet.create({
    cognates: {
        flexDirection: 'row',
        justifyContent: 'center',
    },
    flags: {
        marginRight: '3%',
    },
    cognatesRank: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    rightText: {
        textAlign: 'right',
    },
});
