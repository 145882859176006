import React, { useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Chart, Pressable, Text, View, Screen, TEXT_VARIANTS } from '../components';
import { useAppState, useSystemState } from '../context';
import { WeatherAttributes } from '../map';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { NavParams } from './utils';

const NAME = 'MapWeatherDetail';

const HOUR_MILLIS = 1000 * 60 * 60;

const DAY_OF_WEEK = [
    '_SUNDAY_',
    '_MONDAY_',
    '_TUESDAY_',
    '_WEDNESDAY_',
    '_THURSDAY_',
    '_FRIDAY_',
    '_SATURDAY_',
];

const BUTTON_SIZE = 50;


export const MapWeatherDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight, width } = useSystemState();
    const { t, dark, language, showAds } = useAppState();

    const [data] = useState(NavParams(route)?.data);
    const [title] = useState(NavParams(route)?.data?.sun?.text);

    const [buttonIndex, setButtonIndex] = useState(0);
    const setButtonIndexRef = useRef(setButtonIndex);

    useEffect(
        () => {
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={title?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            title,
            navigation,
        ],
    );

    Console.devStack(NAME, props, { adjHeight, width, dark, showAds, data });

    return useMemo(
        () => {
            const screenStyle = dark
                ? { backgroundColor: Colors.colors.black }
                : { backgroundColor: Colors.colors.gray };
            const screenContrast = dark ? Colors.colors.gray : Colors.colors.black;
            const color = dark ? Colors.colors.white : Colors.colors.black;

            const _height = showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight;
            const tenPercent = Math.round(_height * 0.1);
            const titleStyle =  {
                width,
                height: tenPercent,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: screenContrast,
            };
            const buttonStyle = {
                width,
                height: tenPercent,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: screenContrast,
            };
            const viewStyle = {
                width: width * 0.95,
                height: _height - titleStyle.height - buttonStyle.height,
                //backgroundColor: Colors.colors.transparent,//dark ? Colors.colors.gray : Colors.colors.lightgray,
            };

            const ts = new Date(data.ts.replace(/ /g, 'T')).getTime();
            const langIndex = language === 'en_us' || language === 'haw_hi' ? 0 : 1;
            const attributes = WeatherAttributes[buttonIndex];
            Console.devLog(`${NAME} render`, { data, viewStyle, showAds, buttonIndex, langIndex, ts });
            return (
                <Screen
                    {...props}
                    screenStyle={screenStyle}
                    value={NAME}
                >
                    <View
                        style={titleStyle}
                    >
                        <Text
                            value={`${attributes.emoji} ${t(attributes.title)}`}
                            variant={TEXT_VARIANTS.TITLE}
                            color={attributes.color}
                            noTranslate={true}
                        />
                    </View>
                    <Chart
                        viewStyle={viewStyle}
                        color={color}
                        lineColor={attributes.color}
                        data={data.conditions.map((v, i) => ({
                            y: attributes.y(langIndex)(v),
                            x: new Date(ts + i * HOUR_MILLIS),
                        }))}
                        yFormat={attributes.yFormat(langIndex)}
                        xFormat1={_x => {
                            const _ts = new Date(_x);
                            var hrs = _ts.getHours();
                            const ampm = hrs < 13 ? 'a' : 'p';
                            hrs %= 12;
                            if (!hrs) {
                                hrs = 12;
                            }
                            return `${hrs}${ampm}`;
                        }}
                        xFormat2={_x => {
                            const _ts = new Date(_x);
                            const day = _ts.getDay();
                            return t(DAY_OF_WEEK[day]);
                        }}
                        dynamic={500}
                        langIndex={langIndex}
                    />
                    <View
                        style={buttonStyle}
                    >
                        {
                            WeatherAttributes
                                .filter(v => !data?.conditions?.length || data.conditions[0][v.key] === null ? false : true)
                                .map((_props, idx) => {
                                    const selected = idx === buttonIndex
                                        ?
                                        {
                                            borderColor: attributes.color,
                                            borderWidth: 2,
                                            borderRadius: BUTTON_SIZE / 2,
                                            backgroundColor: screenStyle.backgroundColor,
                                        }
                                        : {};
                                    return (
                                        <Pressable
                                            key={`button${idx}`}
                                            style={[styles.buttonStyle, selected]}
                                            onPress={() => setButtonIndexRef.current(idx)}
                                        >
                                            <Text
                                                style={styles.textStyle}
                                                noTranslate={true}
                                                value={_props.emoji}
                                            />
                                        </Pressable>
                                    );
                                },
                            )
                        }
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            t,
            props,
            dark,
            language,
            adjHeight,
            width,
            showAds,
            data,
            buttonIndex,
            setButtonIndexRef,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    buttonStyle: {
        justifyContent: 'center',
        width: BUTTON_SIZE,
        height: BUTTON_SIZE,
        //width: '50%',
        //backgroundColor: Colors.colors.green,
    },
    textStyle: {
        textAlign: 'center',
        fontSize: 17,
    },
});
