import { Icons } from '../media';
import { Colors } from '../styles';


export const MODES = {
    OFF: 'OFF',
    ON: 'ON',
    TRACKING: 'TRACKING',
    NONE: 'NONE',
};

export const Mode = [
    {
        mode: MODES.OFF,
        color: Colors.colors.darkorange,
        icon: Icons.MapPosition,
        enabled: false,
    },
    {
        mode: MODES.ON,
        color: Colors.colors.orange,
        icon: Icons.MapPositionGPS,
        enabled: true,
    },
    {
        mode: MODES.TRACKING,
        color: Colors.colors.orangered,
        icon: Icons.MapPositionGPS,
        enabled: true,
    },
    {
        mode: MODES.NONE,
        color: Colors.colors.lightgray,
        icon: Icons.MapPosition,
        enabled: false,
    },
];

export const NUM_MODES = Mode.length - 1;

var modeMap = new Map();
Mode.forEach(v => modeMap.set(v.mode, v));

export const ModeMap = modeMap;
