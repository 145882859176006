import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import TimeTable from '@mikezzb/react-native-timetable';
import { Screen, View } from '../components';
import { useAppState, useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { Navigate } from './utils';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';


const NAME = 'Calendar';

const SCRAPBOOK = 'Scrapbook';

const HEADER_HEIGHT = 30;
/*
const eventGroups = [
    {
        courseId: 'AIST3020',
        title: 'Intro to Computer Systems',
        sections: {
            '- - LEC': {
                days: [2, 3],
                startTimes: ['11:30', '16:30'],
                endTimes: ['12:15', '18:15'],
                locations: ['Online Teaching', 'Online Teaching'],
            },
            '-L01 - LAB': {
                days: [2],
                startTimes: ['16:30'],
                endTimes: ['17:15'],
                locations: ['Online Teaching'],
            },
        },
    },
    {
        courseId: 'CSCI2100',
        title: 'Data Structures',
        sections: {
            'A - LEC': {
                days: [1, 3],
                startTimes: ['16:30', '14:30'],
                endTimes: ['17:15', '16:15'],
                locations: ['Online Teaching', 'Online Teaching'],
            },
            'AT02 - TUT': {
                days: [4],
                startTimes: ['17:30'],
                endTimes: ['18:15'],
                locations: ['Online Teaching'],
            },
        },
    },
    {
        courseId: 'ELTU2014',
        title: 'English for ERG Stds I',
        sections: {
            'BEC1 - CLW': {
                days: [2, 4],
                startTimes: ['10:30', '8:30'],
                endTimes: ['11:15', '10:15'],
                locations: ['Online Teaching', 'Online Teaching'],
            },
        },
    },
    {
        courseId: 'ENGG2780',
        title: 'Statistics for Engineers',
        sections: {
            'B - LEC': {
                days: [1],
                startTimes: ['12:30'],
                endTimes: ['14:15'],
                locations: ['Online Teaching'],
            },
            'BT01 - TUT': {
                days: [3],
                startTimes: ['12:30'],
                endTimes: ['14:15'],
                locations: ['Online Teaching'],
            },
        },
    },
    {
        courseId: 'GESC1000',
        title: 'College Assembly',
        sections: {
            '-A01 - ASB': {
                days: [5],
                startTimes: ['11:30'],
                endTimes: ['13:15'],
                locations: ['Online Teaching'],
            },
        },
    },
    {
        courseId: 'UGEB1492',
        title: 'Data Expl - Stat in Daily Life',
        sections: {
            '- - LEC': {
                days: [4],
                startTimes: ['14:30'],
                endTimes: ['17:15'],
                locations: ['Lady Shaw Bldg LT5'],
            },
        },
    },
    {
        courseId: 'UGEC1685',
        title: 'Drugs and Culture',
        sections: {
            '- - LEC': {
                days: [4],
                startTimes: ['11:30'],
                endTimes: ['13:15'],
                locations: ['Lee Shau Kee Building LT5'],
            },
        },
    },
    {
        courseId: 'Eat!',
        title: 'No work on SUNDAY!',
        sections: {
            '': {
                days: [7],
                startTimes: ['12:30'],
                endTimes: ['13:15'],
                locations: ['Home'],
            },
        },
    },
    {
        courseId: 'Manga!',
        title: '',
        sections: {
            '': {
                days: [6],
                startTimes: ['16:30'],
                endTimes: ['19:15'],
                locations: ['Home'],
            },
        },
    },
];
*/
const events = [
    {
        courseId: 'Duke\'s',
        day: 1,
        startTime: '7:00',
        endTime: '8:30',
        location: 'Breakfast',
        color: 'rgba(255,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 1,
        startTime: '9:00',
        endTime: '10:00',
        location: 'to Pearl Harbor',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Pearl Harbor',
        day: 1,
        startTime: '10:00',
        endTime: '12:30',
        location: 'Sites',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 1,
        startTime: '12:30',
        endTime: '13:30',
        location: 'to Dole Plantation',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Dole Plantation',
        day: 1,
        startTime: '13:30',
        endTime: '15:30',
        location: 'Sites',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 1,
        startTime: '15:30',
        endTime: '16:30',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Top of Waikiki',
        day: 1,
        startTime: '17:00',
        endTime: '18:30',
        location: 'Dinner',
        color: 'rgba(255,0,255,1)',
    },

    {
        courseId: 'Transit',
        day: 2,
        startTime: '6:15',
        endTime: '6:30',
        location: 'to Diamond Head',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Diamond Head',
        day: 2,
        startTime: '6:30',
        endTime: '8:00',
        location: 'Hike',
        color: 'rgba(255,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 2,
        startTime: '8:00',
        endTime: '8:15',
        location: 'to Leonard\'s',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Leonards',
        day: 2,
        startTime: '8:15',
        endTime: '8:45',
        location: 'Malasadas',
        color: 'rgba(255,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 2,
        startTime: '8:45',
        endTime: '9:00',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Waikiki Resort Spa',
        day: 2,
        startTime: '10:00',
        endTime: '11:00',
        location: 'Massage',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Cheesecake Factory',
        day: 2,
        startTime: '12:00',
        endTime: '13:30',
        location: 'Lunch',
        color: 'rgba(255,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 2,
        startTime: '14:45',
        endTime: '15:00',
        location: 'to Bishop Museum',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Bishop Museum',
        day: 2,
        startTime: '15:00',
        endTime: '17:30',
        location: 'Site',
        color: 'rgba(255,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 2,
        startTime: '17:30',
        endTime: '17:45',
        location: 'to Waikik',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Maui Brewing Company',
        day: 2,
        startTime: '18:00',
        endTime: '19:30',
        location: 'Dinner',
        color: 'rgba(127,0,0,1)',
    },

    {
        courseId: 'Transit',
        day: 3,
        startTime: '7:00',
        endTime: '8:00',
        location: 'to Turtle Bay',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Roys',
        day: 3,
        startTime: '8:00',
        endTime: '9:30',
        location: 'Breakfast',
        color: 'rgba(255,0,255,1)',
    },
    {
        courseId: 'Turtle Bay',
        day: 3,
        startTime: '9:30',
        endTime: '11:00',
        location: 'Beach/Swim',
        color: 'rgba(0,127,255,1)',
    },
    {
        courseId: 'Transit',
        day: 3,
        startTime: '11:00',
        endTime: '12:00',
        location: 'to Aji Limo',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Aji Limo',
        day: 3,
        startTime: '12:00',
        endTime: '13:00',
        location: 'Poke',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 3,
        startTime: '13:00',
        endTime: '13:45',
        location: 'to Waimea Beach',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Waimea Beach',
        day: 3,
        startTime: '13:45',
        endTime: '16:30',
        location: 'Beach',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 3,
        startTime: '16:30',
        endTime: '17:00',
        location: 'to Haleiwa',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Haleiwa Joe\'s',
        day: 3,
        startTime: '17:00',
        endTime: '18:30',
        location: 'Dinner',
        color: 'rgba(255,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 3,
        startTime: '18:30',
        endTime: '19:30',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },

    {
        courseId: 'Cinnamons',
        day: 4,
        startTime: '7:00',
        endTime: '8:30',
        location: 'Breakfast',
        color: 'rgba(255,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 4,
        startTime: '9:15',
        endTime: '10:00',
        location: 'to Lulumahu Falls',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Lulumahu Falls',
        day: 4,
        startTime: '10:00',
        endTime: '13:15',
        location: 'Hike',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 4,
        startTime: '13:15',
        endTime: '14:00',
        location: 'to Kailua',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Kailua Town Pub and Grill',
        day: 4,
        startTime: '14:00',
        endTime: '15:00',
        location: 'Burgers',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 4,
        startTime: '15:00',
        endTime: '16:00',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'In Between',
        day: 4,
        startTime: '17:00',
        endTime: '19:30',
        location: 'Karaoke',
        color: 'rgba(255,0,255,1)',
    },

    {
        courseId: 'Transit',
        day: 5,
        startTime: '7:15',
        endTime: '7:30',
        location: 'to Rainbow Drive-in',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Rainbow Drive-in',
        day: 5,
        startTime: '7:30',
        endTime: '8:30',
        location: 'Breakfast',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 5,
        startTime: '8:30',
        endTime: '8:45',
        location: 'to Zoo',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Zoo',
        day: 5,
        startTime: '8:45',
        endTime: '10:45',
        location: 'Sites',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 5,
        startTime: '10:45',
        endTime: '11:00',
        location: 'to Aloha Stadium',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Swap Meet',
        day: 5,
        startTime: '11:30',
        endTime: '13:30',
        location: 'Shopping',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 5,
        startTime: '13:30',
        endTime: '14:00',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Hard Rock Cafe',
        day: 5,
        startTime: '14:30',
        endTime: '16:00',
        location: 'Happy Hour',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Charthouse',
        day: 5,
        startTime: '17:15',
        endTime: '18:45',
        location: 'Dinner',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Fireworks',
        day: 5,
        startTime: '18:45',
        endTime: '19:30',
        location: 'Event',
        color: 'rgba(255,0,127,1)',
    },

    {
        courseId: 'Lilihua Bakery',
        day: 6,
        startTime: '7:00',
        endTime: '8:30',
        location: 'Breakfast',
        color: 'rgba(0,0,127,1)',
    },
    {
        courseId: 'Transit',
        day: 6,
        startTime: '9:00',
        endTime: '9:45',
        location: 'to Hanauma Bay',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Hanauma Bay',
        day: 6,
        startTime: '9:45',
        endTime: '13:00',
        location: 'Snorkling',
        color: 'rgba(255,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 6,
        startTime: '13:00',
        endTime: '13:30',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Transit',
        day: 6,
        startTime: '14:30',
        endTime: '15:00',
        location: 'to Ala Moana Mall',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Ala Moana Mall',
        day: 6,
        startTime: '15:00',
        endTime: '17:30',
        location: 'Shopping',
        color: 'rgba(0,127,0,1)',
    },
    {
        courseId: 'Transit',
        day: 6,
        startTime: '17:30',
        endTime: '18:00',
        location: 'to Nico\'s',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Nico\'s',
        day: 6,
        startTime: '18:00',
        endTime: '19:30',
        location: 'Dinner',
        color: 'rgba(255,0,255,1)',
    },

    {
        courseId: 'Hula Grill',
        day: 7,
        startTime: '7:00',
        endTime: '8:30',
        location: 'Breakfast',
        color: 'rgba(127,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 7,
        startTime: '9:00',
        endTime: '9:45',
        location: 'to Koko Head',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Koko Head',
        day: 7,
        startTime: '9:45',
        endTime: '11:00',
        location: 'Hike',
        color: 'rgba(0,0,255,1)',
    },
    {
        courseId: 'Transit',
        day: 7,
        startTime: '11:30',
        endTime: '12:15',
        location: 'to Waikiki',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Marugame Udon',
        day: 7,
        startTime: '12:30',
        endTime: '13:15',
        location: 'Lunch',
        color: 'rgba(255,0,0,1)',
    },
    {
        courseId: 'Transit',
        day: 7,
        startTime: '14:00',
        endTime: '15:00',
        location: 'to Polynesian Cultural Center',
        color: 'rgba(127,127,127,1)',
    },
    {
        courseId: 'Polynesian Cultural Center',
        day: 7,
        startTime: '15:00',
        endTime: '19:30',
        location: 'Luau',
        color: 'rgba(0,0,127,1)',
    },
];

const configs = {
    startHour: 6,
    endHour: 19.5,
    numOfDaysPerPage: 3,
};

export const Calendar = props => {

    const {
        navigation,
    } = props;

    const { adjHeight } = useSystemState();

    const { t, language, showAds } = useAppState();

    const onEventSelect = useCallback(
        event => {
            Console.log(`${NAME}.onEventSelect`, { event });
            Navigate(navigation, t(SCRAPBOOK), { title: event.courseId,
                event: {
                    ...event,
                    image_urls: [
                        'https://upload.wikimedia.org/wikipedia/commons/c/c9/Female_Green_Sea_Turtle.jpg',
                        'https://upload.wikimedia.org/wikipedia/commons/f/f9/Green_Sea_Turtles%2C_Chelonia_mydas_is_getting_back_to_the_ocean_leaving_a_track.jpg',
                        'https://upload.wikimedia.org/wikipedia/commons/8/89/Green_sea_turtle_portrait.JPG',
                    ],
                },
            });

        },
        [
            t,
            navigation,
        ],
    );

    Console.stack(NAME, props, { adjHeight, language, showAds });

    return useMemo(
        () => {
            const headerHeight = { height: HEADER_HEIGHT };
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT - headerHeight.height : adjHeight };
            Console.log(`${NAME} render`, { adjHeight, language, showAds });
            // events={events}
            // eventGroups={eventGroups}
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={[styles.container, adsHeight]}
                    >
                        <TimeTable
                            headerStyle={headerHeight}
                            configs={configs}
                            events={events}
                            eventOnPress={onEventSelect}
                            theme={{ primary: 'rgb(36,98,195)' }}
                        />
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            adjHeight,
            showAds,
            language,
            onEventSelect,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    container: {
        width: '100%',
        paddingHorizontal: '1%',
        alignContent: 'center',
        backgroundColor: 'rgb(36,98,195)',
    },
});
