import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Graphics } from './Graphics';
import { Console } from '../Console';

const NAME = 'Visualizer.web';

const CANVAS_DIMENSION = '2d';


export const Visualizer = props => {

    const {
        width,
        height,
        backgroundColor,
        strokeColor,
        strokeWidth,
        fftSize,
        scale,
        refresh,
    } = props;

    const visualizerRef = useRef();

    const [canvas, setCanvas] = useState();
    const setCanvasRef = useRef(setCanvas);

    const [canvasContext, setCanvasContext] = useState();
    const setCanvasContextRef = useRef(setCanvasContext);

    const visualize = useCallback(
        (_canvas, _canvasContext) => {
            const canvasStyle = { width, height, backgroundColor, strokeColor, strokeWidth };
            Console.log(`${NAME}.visualize`, { canvasStyle });
            Graphics.SineWave(_canvas, _canvasContext, canvasStyle, fftSize, scale);
        },
        [
            width,
            height,
            backgroundColor,
            strokeColor,
            strokeWidth,
            fftSize,
            scale,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect refresh`, { refresh });
            if (refresh && canvasContext) {
                canvasContext.clearRect(0, 0, width, height);
            }
        },
        [
            width,
            height,
            canvasContext,
            refresh,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect visualizer`);
            const visualizer = visualizerRef.current;
            const _canvas = visualizer;
            const _canvasContext = _canvas.getContext(CANVAS_DIMENSION);
            setCanvasRef.current(_canvas);
            setCanvasContextRef.current(_canvasContext);
            visualize(_canvas, _canvasContext);
        },
        [
            setCanvasRef,
            setCanvasContextRef,
            visualizerRef,
            visualize,
        ],
    );

    Console.stack(NAME, props, { canvas, canvasContext });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { width, height });
            return (
                <canvas
                    ref={visualizerRef}
                    height={height}
                    width={width}
                    className={'visualizer'}
                />
            );
        },
        [
            width,
            height,
            visualizerRef,
        ],
    );
};
