import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StatusBar, StyleSheet } from 'react-native';
import { ScreenSettings } from './helpers';
import { DynamicView } from './platform';
import { viewHeights } from './utils';
import { BackKey } from './BackKey';
import { Help } from './Help';
import { View, VIEW_VARIANTS } from './View';
import { APP_TYPES, useAppDispatch, useAppState, useSystemState } from '../context';
import { Console, Optional, Statistics, Validate } from '../utils';
import { Colors } from '../styles';
import { USE_DEV_COLOR, DEV_COLOR } from '../constants';

const NAME = 'Screen';

const BOTTOM_TAB = 'BottomTab';
const DRAWER = 'Drawer';

const SELECT = 'select';
const RELEASE = 'release';

const FOCUS = 'focus';
const BLUR = 'blur';


export const Screen = props => {

    const {
        navigation,
        route,
        value,
        screenStyle,
        headerHeight,
        headerStyle,
        headerView,
        mainHeight,
        mainStyle,
        mainView,
        footerHeight,
        footerStyle,
        footerView,
        children,
        dynamic,
        selectDistance,
        onHeightChange,
        topDragColor,
        bottomDragColor,
        renderHelp,
        variant,
        helpBackgroundOpacity,
    } = props;

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { adjHeight, width } = useSystemState();
    const { DEFAULT, screen, selection, navigator } = useAppState();

    const [backVisible, setBackVisible] = useState(false);
    const setBackVisibleRef = useRef(setBackVisible);

    const [focus, setFocus] = useState(false);
    const setFocusRef = useRef(setFocus);

    const [hasHeader, setHasHeader] = useState(false);
    const setHasHeaderRef = useRef(setHasHeader);

    const [hasTab, setHasTab] = useState(false);
    const setHasTabRef = useRef(setHasTab);

    const [header, setHeader] = useState();
    const setHeaderRef = useRef(setHeader);

    const [main, setMain] = useState();
    const setMainRef = useRef(setMain);

    const [footer, setFooter] = useState();
    const setFooterRef = useRef(setFooter);

    const [heights, setHeights] = useState(viewHeights(adjHeight, headerHeight, mainHeight, footerHeight));
    const setHeightsRef = useRef(setHeights);

    const [top, setTop] = useState(null);
    const setTopRef = useRef(setTop);

    const [bottom, setBottom] = useState(null);
    const setBottomRef = useRef(setBottom);

    const [routeTitle] = useState(route?.params?.params?.title);

    const onGesture = useCallback(
        event => {

            const isTop = Validate.isValid(top);
            const isBottom = Validate.isValid(bottom);
            var newHeights = {
                header: { height: 0 },
                footer: { height: 0 },
            };

            if ((selection || isTop || isBottom) && Validate.isValid(event?.panEvent)) {

                const { x, y } = event.panEvent;

                Console.trace(`${NAME}[${value}].onGesture move event { ${x}, ${y} }, selection=${selection}, top=${top}, bottom=${bottom}`);
                appDispatchRef.current({
                    type: APP_TYPES.SET_GESTURE,
                    payload: { x, y, state: null },
                });

                if (isTop) {
                    setTopRef.current(y);
                    newHeights.header.height = heights.header.height + y;
                    onHeightChange(newHeights, true);
                } else if (isBottom) {
                    setBottomRef.current(y);
                    newHeights.footer.height = heights.footer.height - (y - heights.main.height);
                    onHeightChange(newHeights, true);
                }
            }

            if (Validate.isValid(event?.panState)) {

                const { x, y, state } = event.panState;

                if (selectDistance && state !== 5) { // MARKMARK: MAGIC NUMBER!

                    if (Validate.isValid(topDragColor) && y < selectDistance) {

                        Console.log(`${NAME}[${value}].onGesture select top { ${x}, ${y} } [${state}]`);
                        setTopRef.current(y);

                    } else if (Validate.isValid(bottomDragColor) && y > heights.main.height - selectDistance) {

                        Console.log(`${NAME}[${value}].onGesture select bottom { ${x}, ${y} } [${state}]`);
                        setBottomRef.current(y);

                    } else {

                        Console.log(`${NAME}[${value}].onGesture select event { ${x}, ${y} } [${state}]`);
                        appDispatchRef.current({
                            type: APP_TYPES.SET_GESTURE,
                            payload: { x, y, state: SELECT },
                        });
                    }

                } else {

                    Console.log(`${NAME}[${value}].onGesture release event { ${x}, ${y} }, selection=${selection}, top=${top}, bottom=${bottom}`);

                    if (selection) {
                        appDispatchRef.current({
                            type: APP_TYPES.SET_GESTURE,
                            payload: { x, y, state: RELEASE },
                        });
                    }
                    if (Validate.isValid(onHeightChange)) {
                        if (isTop) {
                            newHeights.header.height = heights.header.height + top;
                            onHeightChange(newHeights, false);
                            setTopRef.current(null);
                        }
                        if (isBottom) {
                            newHeights.footer.height = heights.footer.height - (bottom - heights.main.height);
                            onHeightChange(newHeights, false);
                            setBottomRef.current(null);
                        }
                    }
                }
            }
        },
        [
            value,
            selection,
            heights,
            selectDistance,
            top,
            bottom,
            topDragColor,
            bottomDragColor,
            appDispatchRef,
            setTopRef,
            setBottomRef,
            onHeightChange,
        ],
    );

    useEffect(
        () => {
            const newHeights = viewHeights(adjHeight, headerHeight, mainHeight, footerHeight);
            Console.log(`${NAME}[${value}] useEffect viewHeights`, { adjHeight, headerHeight, mainHeight, footerHeight, newHeights });
            setHeightsRef.current(newHeights);
            if (Validate.isValid(onHeightChange)) {
                onHeightChange(newHeights, null);
            }
        },
        [
            value,
            adjHeight,
            headerHeight,
            mainHeight,
            footerHeight,
            setHeightsRef,
            onHeightChange,
        ],
    );

    useEffect(
        () => {
            const _hasHeader = navigator === BOTTOM_TAB || navigator === DRAWER;
            const _hasTab = Validate.isValid(route) && !Validate.isValid(route?.params) && navigator === BOTTOM_TAB;
            Console.devLog(`${NAME}[${value}] useEffect update`, { navigator, route, hasHeader: _hasHeader, hasTab: _hasTab });
            setHasHeaderRef.current(_hasHeader);
            setHasTabRef.current(_hasTab);
        },
        [
            value,
            route,
            navigator,
            setHasHeaderRef,
            setHasTabRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME}[${value}] useEffect add listeners`);
            const unsubscribeFocus = navigation.addListener(FOCUS, () => { _onFocus(true); });
            const unsubscribeBlur = navigation.addListener(BLUR, () => { _onFocus(false); });
            return () => {
                Console.log(`${NAME}[${value}] useEffect remove listeners`);
                unsubscribeFocus();
                unsubscribeBlur();
            };
        },
    );

    useEffect(
        () => {
            Console.trace(`${NAME}[${value}] useEffect header`, { headerHeight: heights?.header?.height });
            setHeaderRef.current(Optional(heights?.header?.height && headerView, (
                <View
                    value={`${value}Header`}
                    style={[styles.header, headerStyle, heights.header]}
                >
                    {headerView}
                </View>
            )));
        },
        [
            value,
            heights,
            headerStyle,
            headerView,
            setHeaderRef,
        ],
    );

    useEffect(
        () => {
            Console.trace(`${NAME}[${value}] useEffect main`, { mainHeight: heights?.main?.height });
            setMainRef.current(
                Optional(heights?.main?.height && mainView, Optional(dynamic,
                    (
                        <DynamicView
                            name={`${value}Main`}
                            style={[styles.main, mainStyle, heights.main]}
                            width={width}
                            height={heights.main.height}
                            top={heights.header.height}
                            onGesture={onGesture}
                        >
                            {mainView}
                        </DynamicView>
                    ),
                    (
                        <View
                            value={`${value}Main`}
                            style={[styles.main, mainStyle, heights.main]}
                            width={width}
                            height={heights.main.height}
                        >
                            {mainView}
                        </View>
                    ),
                )));
        },
        [
            value,
            width,
            heights,
            mainStyle,
            mainView,
            setMainRef,
            dynamic,
            onGesture,
        ],
    );

    useEffect(
        () => {
            Console.trace(`${NAME}[${value}] useEffect footer`, { footerHeight: heights?.footer?.height });
            setFooterRef.current(Optional(heights?.footer?.height && footerView, (
                <View
                    value={`${value}Footer`}
                    style={[styles.footer, footerStyle, heights.footer]}
                >
                    {footerView}
                </View>
            )));
        },
        [
            value,
            heights,
            footerStyle,
            footerView,
            setFooterRef,
        ],
    );

    const _onFocus = useCallback(
        hasFocus => {
            Console.log(`${NAME}[${value}](${screen})._onFocus`, { hasFocus });
            setFocusRef.current(hasFocus);
            if (hasFocus) {
                appDispatchRef.current({ type: APP_TYPES.SET_SCREEN, payload: value });
            }
        },
        [
            screen,
            value,
            setFocusRef,
            appDispatchRef,
        ],
    );

    const renderAdornments = useMemo(
        () => {
            Console.log(`${NAME}[${value}].renderAdornments`, { routeTitle, backVisible, hasHeader, hasTab });
            return (
                <>
                    <BackKey
                        visible={backVisible}
                        setVisible={setBackVisibleRef.current}
                        goBack={routeTitle}
                    />
                    <StatusBar
                        hidden={true}
                    />
                    <ScreenSettings
                        hasHeader={hasHeader}
                        hasTab={hasTab}
                    />
                </>
            );
        },
        [
            value,
            routeTitle,
            backVisible,
            setBackVisibleRef,
            hasHeader,
            hasTab,
        ],
    );

    Console.stack(`${NAME}[${value}](${screen}) focus=${focus}`, props, { /*deviceHPad,*/ /*DEFAULT,*/ backVisible, navigator/*, focus*/, hasHeader, hasTab, width, adjHeight, heights });

    return useMemo(
        () => {

            if (focus) {
                Statistics.Report(`${value} render`);
            }

            const { DRAG_BAR_HEIGHT } = DEFAULT;
            const isTop = Validate.isValid(top);
            const isBottom = Validate.isValid(bottom);
            const halfDragBar = DRAG_BAR_HEIGHT / 2;
            const topDragStyle = {
                top: heights.header.height + (isTop ? top - halfDragBar : 0),
                height: isTop ? DRAG_BAR_HEIGHT : halfDragBar,
                backgroundColor: isTop ? Colors.colors.green : topDragColor,
            };
            const bottomDragStyle = {
                top: heights.header.height - halfDragBar + (isBottom ? bottom : heights.main.height),
                height: isBottom ? DRAG_BAR_HEIGHT : halfDragBar,
                backgroundColor: isBottom ? Colors.colors.green : bottomDragColor,
            };

            Console.log(`${NAME}[${value}] render`, { focus, isTop, isBottom, halfDragBar, topDragStyle, bottomDragStyle });

            return Optional(focus, (
                <View
                    value={`Screen[${value}]`}
                    style={[styles.screen, screenStyle]}
                >
                    {renderAdornments}
                    <Help
                        screen={value}
                        render={renderHelp}
                        backgroundOpacity={helpBackgroundOpacity}
                    />
                    <View
                        value={value}
                        style={[styles.view, /*deviceStyle,*/ variant === VIEW_VARIANTS.SCROLL ? {} : styles.viewNonScroll]}
                        variant={variant}
                    >
                        {header}
                        {main}
                        {footer}
                        {children}
                        {Optional(Validate.isValid(topDragColor) && heights.header.height, (
                            <View
                                value={`${value}TopDrag]`}
                                style={[styles.dragStyle, topDragStyle]}
                            />
                        ))}
                        {Optional(Validate.isValid(bottomDragColor) && heights.footer.height, (
                            <View
                                value={`${value}BottomDrag]`}
                                style={[styles.dragStyle, bottomDragStyle]}
                            />
                        ))}
                    </View>
                </View>
            ));
        },
        [
            DEFAULT,
            variant,
            value,
            screenStyle,
            heights,
            topDragColor,
            bottomDragColor,
            top,
            bottom,
            //backVisible,
            //setBackVisibleRef,
            //route,
            focus,
            //hasHeader,
            //hasTab,
            header,
            main,
            footer,
            children,
            renderAdornments,
            renderHelp,
            helpBackgroundOpacity,
        ],
    );
};

const baseStyle = {
    width: '100%',
    borderWidth: USE_DEV_COLOR ? 1 : 0,
    alignItems: 'center',
};

const styles = StyleSheet.create({
    screen: {
        ...baseStyle,
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        flexWrap: 'nowrap',
        margin: 0,
        padding: 0,
        elevation: 0,
        zIndex: 0,
        overflow: 'hidden',
        alignContent: 'center',
        alignSelf: 'auto',
        justifyContent: 'space-between',
        borderColor: DEV_COLOR(Colors.colors.white),
        backgroundColor: DEV_COLOR(Colors.colors.lightgray),
    },
    view: {
        ...baseStyle, // MARKMARK: check that align:center is ok, wasn't in this style before this line
        flex: 1,
        flexDirection: 'column',
        height: '100%',
        borderColor: DEV_COLOR(Colors.colors.black),
        backgroundColor: DEV_COLOR(Colors.colors.darkgray),
    },
    viewNonScroll: {
        //alignItems: 'center',
        justifyContent: 'space-between',
        borderColor: DEV_COLOR(Colors.colors.orange),
        backgroundColor: DEV_COLOR(Colors.colors.yellow),
    },
    header: {
        ...baseStyle,
        borderColor: DEV_COLOR(Colors.colors.red),
        backgroundColor: DEV_COLOR(Colors.colors.pink),
    },
    main: {
        ...baseStyle,
        borderColor: DEV_COLOR(Colors.colors.blue),
        backgroundColor: DEV_COLOR(Colors.colors.lightblue),
    },
    footer: {
        ...baseStyle,
        borderColor: DEV_COLOR(Colors.colors.green),
        backgroundColor: DEV_COLOR(Colors.colors.lightgreen),
    },
    dragStyle: {
        position: 'absolute',
        left: 0,
        width: '100%',
    },
});
