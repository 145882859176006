import React, { useEffect, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Image, Screen, Text, View, TEXT_VARIANTS, VIEW_VARIANTS } from '../components';
import { useAppState, useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { S3_SERVER } from '../constants';
import { Colors } from '../styles';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { NavParams } from './utils';

const NAME = 'Scrapbook';


export const Scrapbook = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight } = useSystemState();
    const { showAds } = useAppState();
    /*
        const [ads, setAds] = useState(false);
        const setAdsRef = useRef(setAds);

        useEffect(
            () => {
                Console.log(`${NAME} useEffect GoogleAds`);
                return GoogleAds.Get().subscribe(AD_TYPES.BANNER, setAdsRef);
            },
            [
                setAdsRef,
            ],
        );
    */
    useEffect(
        () => {
            const event = NavParams(route)?.event;
            Console.log(`${NAME} useEffect entry`, { event });
            navigation.setOptions({ headerTitle: ({ tintColor }) => <Text value={event?.courseId} color={tintColor} variant={TEXT_VARIANTS.TITLE} /> });
        },
        [
            route,
            navigation,
        ],
    );

    Console.stack(NAME, props, { adjHeight, showAds });

    return useMemo(
        () => {
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight };
            Console.log(`${NAME} render`, { adjHeight, showAds });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={[styles.scrollView, adsHeight]}
                        variant={VIEW_VARIANTS.SCROLL}
                    >
                        <Image
                            containerStyle={styles.image}
                            source={{ uri: 'https://mediaim.expedia.com/destination/2/c74194b69b6a6d9fff5f1d9cba939f6d.jpg' }}
                            width={340}
                            height={340}
                            resizeMode={'contain'}
                        />
                        <Image
                            containerStyle={styles.image}
                            source={{ uri: `${S3_SERVER}/da_app_hawaii/dole_plantation.png` }}
                            width={340}
                            height={340}
                            resizeMode={'contain'}
                        />
                        <Image
                            containerStyle={styles.image}
                            source={{ uri: `${S3_SERVER}/da_app_hawaii/diamondhead.png` }}
                            width={340}
                            height={340}
                            resizeMode={'contain'}
                        />
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            adjHeight,
            showAds,
        ],
    );
};

/*
            {Optional(NavParams(route)?.event?.image_urls?.length, (
                <>
                    {NavParams(route)?.event?.image_urls.map(uri => (
                        <View
                            style={styles.image}
                        >
                            <Image
                                source={{ uri }}
                                width={340}
                                height={340}
                                resizeMode={'contain'}
                            />
                        </View>
                    ))}
                </>
            ))}
*/

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
        //backgroundColor: Colors.colors.red,
    },
    scrollView: {
        alignItems: 'center',
        backgroundColor: Colors.colors.white,
    },
    image: {
        width: 340,
        height: 340,
        marginVertical: 10,
    },
});
