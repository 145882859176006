import React from 'react';
import { ActivityIndicator } from './ActivityIndicator';
import { Text, TEXT_VARIANTS } from './Text';
import { View } from './View';
import { Styles } from '../styles';
import { Console, Optional, Validate } from '../utils';

const NAME = 'InfoMessage';

const LOADING = 'Loading';


export const InfoMessage = props => {

    const {
        value,
        inverted,
    } = props;

    const message = Validate.isValidNonEmptyString(value) ? value : LOADING;

    const infoMessageContainer = {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
    };

    const invertedStyle = Styles.invert(inverted);

    Console.stack(NAME, props, { message, value, inverted });

    return (
        <View
            value={'InfoMessageView'}
            style={[infoMessageContainer, invertedStyle]}
        >
            {Optional(message === LOADING, <ActivityIndicator />)}
            <Text
                value={message}
                variant={TEXT_VARIANTS.INFO}
            />
        </View>
    );
};
