import { Amplify } from './amplify';
import { File } from '../storage';

const NAME = 'Storage';

const checkFilename = value => {
    return typeof value === 'string' && value?.length ? true : false;
};

export class Storage {

    static async downloadText(filename) {
        var text = null;
        try {
            const result = checkFilename(filename)
                ? await Amplify.Get().Storage.get(filename, { download: true })
                : null;
            text = await (new Response(result.Body)).text();
            //console.log(`${NAME}.downloadText`, { filename, text });
        } catch (err) {
            console.error(`${NAME}.downloadText "${filename}"`, err);
        }
        return text;
    }

    static async download(filename) {
        try {
            const result = checkFilename(filename)
                ? await Amplify.Get().Storage.get(filename, { download: true })
                : null;
            //console.log(`${NAME}.download`, { filename, result });
            File.SaveAs(result.Body, filename);
        } catch (err) {
            console.error(`${NAME}.download "${filename}"`, err);
        }
    }

    static async upload(filename, text) {
        try {
            const result = checkFilename(filename)
                ? await Amplify.Get().Storage.put(filename, text)
                : null;
            //console.log(`${NAME}.upload`, { filename, text, result });
            return result;
        } catch (err) {
            console.error(`${NAME}.upload`, err);
        }
    }
}
