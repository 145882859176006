import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { WebView } from 'react-native-webview';
import { Text, Pressable, View, Screen, TEXT_VARIANTS } from '../components';
import { useAppState, useSystemState } from '../context';
import { Colors } from '../styles';
import { Console } from '../utils';
import { MAX_SURF_WIDGET_WIDTH, SurfBaseUrl, SurfBreaksUrl, SurfWidgetHtml } from '../thirdparty';
import { Navigate, NavParams } from './utils';

const NAME = 'MapSurfDetail';

const MAP_WEB_DETAIL = 'MapWebDetail';


export const MapSurfDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { t, language } = useAppState();
    const { width } = useSystemState();

    const [title] = useState(NavParams(route)?.data?.title);
    const [surf_forecast_key] = useState(NavParams(route)?.data?.info?.surf_forecast);

    useEffect(
        () => {
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={title?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            title,
            navigation,
        ],
    );

    Console.devStack(NAME, props, { width, language });

    return useMemo(
        () => {
            const dimensions = {
                width: MAX_SURF_WIDGET_WIDTH,
                height: width,
            };

            const translateX = -dimensions.width / 2;
            const translateY = -dimensions.height / 2;
            const rotate = '90deg';
            const transform = {
                transform: [
                    { translateX },
                    { translateY },
                    { rotate },
                    { translateX: -translateX },
                    { translateY: -(width - dimensions.height / 2) },
                ],
            };

            Console.devLog(`${NAME} render`, { language, title, surf_forecast_key, width, dimensions });

            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        value={NAME}
                        style={[styles.view, dimensions, transform]}
                    >
                        <WebView
                            source={{
                                baseUrl: SurfBaseUrl(),
                                html: SurfWidgetHtml(surf_forecast_key, title, language),
                            }}
                            originWhiteList={['*']}
                        />
                        <View
                            style={[styles.view, dimensions, styles.border]}
                            backgroundColor={Colors.colors.transparent}
                        />
                        <Pressable
                            style={styles.logo}
                            onPress={() => {
                                Navigate(navigation, t(MAP_WEB_DETAIL), {
                                    title: MAP_WEB_DETAIL,
                                    data: { uri: SurfBaseUrl() },
                                });
                            }}
                        />
                        <Pressable
                            style={title?.length < 8 ? styles.breaksShort : styles.breaksLong}
                            onPress={() => {
                                Navigate(navigation, t(MAP_WEB_DETAIL), {
                                    title: MAP_WEB_DETAIL,
                                    data: { uri: SurfBreaksUrl(surf_forecast_key) },
                                });
                            }}
                        />
                        <Pressable
                            style={title?.length < 8 ? styles.urlShort : styles.urlLong}
                            onPress={() => {
                                Navigate(navigation, t(MAP_WEB_DETAIL), {
                                    title: MAP_WEB_DETAIL,
                                    data: { uri: SurfBaseUrl() },
                                });
                            }}
                        />
                    </View>
                </Screen>
            );
        },
        [
            t,
            navigation,
            props,
            language,
            title,
            surf_forecast_key,
            width,
        ],
    );
};

const pressableBaseStyle = {
    position: 'absolute',
    opacity: 0.5,
    //backgroundColor: 'red',
};

const styles = StyleSheet.create({
    view: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    border: {
        borderWidth: 0,
        borderColor: Colors.colors.red,
    },
    logo: {
        ...pressableBaseStyle,
        bottom: '5%',
        left: '2%',
        width: '10.6%',
        height: '7%',
    },
    breaksShort: {
        ...pressableBaseStyle,
        bottom: '7%',
        left: '18.5%',
        width: '38.5%',
        height: '4%',
    },
    breaksLong: {
        ...pressableBaseStyle,
        bottom: '7%',
        left: '18.5%',
        width: '58%',
        height: '4%',
    },
    urlShort: {
        ...pressableBaseStyle,
        bottom: '7%',
        right: '25%',
        width: '15%',
        height: '4%',
    },
    urlLong: {
        ...pressableBaseStyle,
        bottom: '1%',
        left: '15.5%',
        width: '15%',
        height: '4%',
    },
});
