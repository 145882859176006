import React from 'react';
import { StatusBar, View } from 'react-native';
import { PhysicsEngine, Renderer, TouchCallbacks } from './engine';
import { GameEngine } from './engine/platform';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { MrPotatoHeadData} from './MrPotatoHeadData';
//import { MrPotatoHeadDemo as MrPotatoHeadData } from './MrPotatoHeadDemo';

const NAME = 'MrPotatoHeadGame';

const mph = new MrPotatoHeadData();


export class MrPotatoHeadGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            accelerometer: null,
            engine: null,
            entities: {},
            systems: [],
        };

        Console.log(`${NAME}.constructor`, { props, state: this.state });
    }

    componentDidMount() {

        // MARKMARK: is this required? just to get useTouch?
        const callbacks = {
            onSelect: (entities, position) => {
                TouchCallbacks.onSelect(entities, position);
                Console.log(`${NAME}.onSelect`, { entities, position });
                return entities;
            },
        };

        const { top, left, width, height, tScale, gScale } = this.props;
        const events = mph.get(height);
        this.state.engine = new PhysicsEngine(top, left, width, height, callbacks, events, tScale, gScale);
        mph.populate(this.state.engine);

        this.state.entities = this.state.engine.entities();
        this.state.systems = this.state.engine.systems();

        ///* eslint-disable react/no-did-mount-set-state */
        // I think this is required to force an update
        this.setState({ initialized: true });

        Console.log(`${NAME}.componentDidMount`, { state: this.state });
    }

    componentDidUpdate(prevProps) {
        Console.log(`${NAME}.componentDidUpdate`, { state: this.state, props: this.props, prevProps });
        this.state.engine.setDimensions(this.props.width, this.props.height);
    }

    componentWillUnmount() {
        Console.log(`${NAME}.componentWillUnmount`, { state: this.state });
        if (this.state.accelerometer) {
            this.state.accelerometer.stop();
        }
    }

    render() {
        const { top, left, width, height, running, children } = this.props;
        const { initialized, systems, entities } = this.state;
        const gameStyle = {
            position: 'absolute',
            top,
            left,
            width,
            height,
            backgroundColor: Colors.colors.lightgray,
            //borderColor: Colors.colors.red,
            //borderWidth: 1,
        };
        Console.log(`${NAME}.render`, { top, left, width, height, running, initialized, systems, entities });
        return Optional(initialized,
            (
                <GameEngine
                    style={gameStyle}
                    systems={systems}
                    entities={entities}
                    running={running}
                    renderer={Renderer}
                >
                    <StatusBar hidden={true} />
                    {children}
                </GameEngine>
            ),
            <View
                style={gameStyle}
            />,
        );
    }
}
