let ICONS = null;

export class MaterialCommunityIcons {
    static Set(icons) {
        if (!ICONS && icons) {
            ICONS = icons;
        }
    }

    static Get() {
        return ICONS;
    }
}
