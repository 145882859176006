import React from 'react';
import { Screen } from '../components';
import { Speech2D as Component2D } from './Speech2D';
import { Speech3D as Component3D } from './Speech3D';
import { useAppState } from '../context';
import { Console, Optional } from '../utils';

const NAME = 'Speech';


export const Speech = props => {

  const { threeD } = useAppState();

  Console.stack(NAME, props, { threeD });

  return (
    <Screen
      {...props}
      value={NAME}
    >
      {Optional(threeD, <Component3D />, <Component2D />)}
    </Screen>
  );
};

export const Speech2D = props => {

  Console.stack(`${NAME}2D`, props);

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <Component2D />
    </Screen>
  );
};

export const Speech3D = props => {

  Console.stack(`${NAME}3D`, props);

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <Component3D />
    </Screen>
  );
};
