import React from 'react';
import { StyleSheet } from 'react-native';
import { Divider as RNEDivider } from 'react-native-elements';
import { Console } from '../utils';
import { Colors } from '../styles';

const NAME = 'Divider';

export const Divider = props => {

    const {
        style,
        width,
        orientation,
        color,
    } = props;

    const colorStyle = { backgroundColor: color ? color : Colors.colors.darkgray };

    Console.stack(NAME, props);

    return (
        <RNEDivider
            style={[styles.divider, colorStyle, style]}
            width={width}
            orientation={orientation}
            color={color}
        />
    );
};

const styles = StyleSheet.create({
    divider: {
        alignSelf: 'stretch',
    },
});
