const Relation = {
    '00': 'Sense',
    '01': 'Antonym',
    '02': 'Hypernym',
    '03': 'Hyponym',
    '04': 'Meronym',
    '05': 'Domain',
    '06': 'Domain_member',
    '07': 'Attribute',
    '08': 'Entailment',
    '09': 'Cause',
    '10': 'Verb_participle',
    '11': 'Also_see',
    '12': 'Verb_group',
    '13': 'Derivation',
    '14': 'Similar',
    '15': 'Topic',
    '16': 'Region',
    '17': 'Usage',
    '18': 'Instance',
    '19': 'Member',
    '20': 'Substance',
    '21': 'Part',
    '22': 'Adj_pertainym',
    '23': 'Adv_derivation',
    '24': 'Lemma_root',
    '25': 'Lemma_member',
};

export const GetRelation = relation => {
    const relationTokens = relation.split('_');
    const result = relationTokens.map(v => Relation[v]);
    return result.join('_');
};
