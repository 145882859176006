import { useEffect, useRef, useState } from 'react';
import { Vocabulary } from '../vocabulary';
import { Console } from '../utils';

const NAME = 'useVocabulary';

const VOCABULARY_PROGRESS_MAX = 0.999;


export const useVocabulary = () => {

    const [vocabularyLoaded, setVocabularyLoaded] = useState(false);
    const setVocabularyLoadedRef = useRef(setVocabularyLoaded);

    const [vocabularyProgress, setVocabularyProgress] = useState(Vocabulary.IsLoaded() ? 1.0 : 0.0);
    const setVocabularyProgressRef = useRef(setVocabularyProgress);

    useEffect(
        () => {
            if (vocabularyProgress < (1.0 - VOCABULARY_PROGRESS_MAX)) {
                Vocabulary.Get(setVocabularyProgressRef.current);
            } else if (vocabularyProgress >= VOCABULARY_PROGRESS_MAX) {
                setVocabularyLoadedRef.current(true);
            }
        },
        [
            vocabularyProgress,
            setVocabularyProgressRef,
            setVocabularyLoadedRef,
        ],
    );

    Console.log(NAME, { vocabularyLoaded, vocabularyProgress });

    return {
        vocabularyLoaded,
        vocabularyProgress,
    };
};
