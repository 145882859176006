import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createMaterialBottomTabNavigator } from '@react-navigation/material-bottom-tabs';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { createStackNavigator } from '@react-navigation/stack';

export const TabNavigators = {
    BottomTab: createBottomTabNavigator(),
    Drawer: createDrawerNavigator(),
    MaterialBottomTab: createMaterialBottomTabNavigator(),
    MaterialTopTab: createMaterialTopTabNavigator(),
};

export const Navigators = {
    ...TabNavigators,
    Stack: createStackNavigator(),
    Default: createBottomTabNavigator(),
};
