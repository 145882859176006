import {
    Coupons,
    Calendar,
    MapCreativeCommonsDetail,
    MapDirectionsDetail,
    MapLocationDetail,
    MapSurfDetail,
    MapUserDetail,
    MapView,
    MapWeatherDetail,
    MapWebDetail,
    Scrapbook,
} from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Oahu,

    TabArray: ['MapView'/*, 'Coupons', 'Calendar'*/],

    Tabs: {
        MapView: {
            Component: MapView,
            Icon: Icons.MapView,
            children: new Set(['MapCreativeCommonsDetail', 'MapDirectionsDetail', 'MapLocationDetail', 'MapSurfDetail', 'MapUserDetail', 'MapWeatherDetail', 'MapWebDetail']),
            menu: true,
        },
        Coupons: {
            Component: Coupons,
            Icon: Icons.Coupons,
        },
        Calendar: {
            Component: Calendar,
            Icon: Icons.Calendar,
            children: new Set(['Scrapbook']),
        },
    },

    Stacks: {
        MapCreativeCommonsDetail: {
            Component: MapCreativeCommonsDetail,
        },
        MapDirectionsDetail: {
            Component: MapDirectionsDetail,
        },
        MapLocationDetail: {
            Component: MapLocationDetail,
        },
        MapSurfDetail: {
            Component: MapSurfDetail,
        },
        MapUserDetail: {
            Component: MapUserDetail,
        },
        MapWeatherDetail: {
            Component: MapWeatherDetail,
        },
        MapWebDetail: {
            Component: MapWebDetail,
        },
        Scrapbook: {
            Component: Scrapbook,
        },
    },

};
