import React from 'react';
import { StyleSheet, TouchableOpacity } from 'react-native';
import MenuDrawer from 'react-native-side-drawer';
import { useSystemState } from '../context';
import { Console } from '../utils';
//import { Colors } from '../styles';

const NAME = 'Drawer';

// MARKMARK: add openDrawer to AppState, set that in Navigator headerLeft
// in Settings (move menu flag to Settings), add this Drawer with contents for color, etc...

export const Drawer = props => {

    const {
        show,
        toggleShow,
        content,
        percentage,
        children,
    } = props;

    const { navHeight } = useSystemState();
    const contentStyle = { paddingBottom: navHeight };

    Console.stack(NAME, props, { navHeight });

    return (
        <MenuDrawer
            open={show}
            position={'left'}
            drawerContent={(
                <TouchableOpacity
                    onPress={toggleShow}
                    style={[styles.animatedBox, contentStyle]}
                    activeOpacity={1}
                >
                    {content}
                </TouchableOpacity>
            )}
            drawerPercentage={percentage}
            animationTime={250}
            overlay={true}
            opacity={1}
        >
            <TouchableOpacity
                onPress={toggleShow}
                style={styles.body}
                activeOpacity={1}
            >
                {children}
            </TouchableOpacity>
        </MenuDrawer>
    );
};

const styles = StyleSheet.create({
    animatedBox: {
        flex: 1,
        //backroundColor: Colors.colors.red,
        //padding: 0,
        //margin: 0,
    },
    body: {
        //flex: 1,
        //backroundColor: Colors.colors.yellow,
        //padding: 0,
        //margin: 0,
    },
});
