import { Icons } from '../media';
import { Colors } from '../styles';


export const MODES = {
    OFF: 'OFF',
    WALKING: 'WALKING',
    BICYCLING: 'BICYCLING',
    DRIVING: 'DRIVING',
    TRANSIT: 'TRANSIT',
    NONE: 'NONE',
};

export const Mode = [
    {
        mode: MODES.OFF,
        color: Colors.colors.lightyellow,
        icon: Icons.MapDirectionsOn,
        enabled: false,
        emoji: null,
        width: 0,
    },
    {
        mode: MODES.WALKING,
        color: Colors.colors.white,
        icon: Icons.MapNavigationWalk,
        enabled: true,
        emoji: '🚶',
        width: 3,
    },
    {
        mode: MODES.BICYCLING,
        color: Colors.colors.orange,
        icon: Icons.MapNavigationBike,
        enabled: true,
        emoji: '🚴',
        width: 4,
    },
    {
        mode: MODES.DRIVING,
        color: Colors.colors.lime,
        icon: Icons.MapNavigationCar,
        enabled: true,
        emoji: '🚗',
        width: 5,
    },
    {
        mode: MODES.TRANSIT,
        color: Colors.colors.yellow,
        icon: Icons.MapNavigationBus,
        enabled: true,
        emoji: '🚌',
        width: 5,
    },
    {
        mode: MODES.NONE,
        color: Colors.colors.lightgray,
        icon: Icons.MapDirectionsOff,
        enabled: false,
        emoji: null,
        width: 0,
    },
];

export const NUM_MODES = Mode.length - 1;

var modeMap = new Map();
Mode.forEach(v => modeMap.set(v.mode, v));

export const ModeMap = modeMap;
