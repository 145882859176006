import { Colors } from '../styles';

const TRANSPARENCY = 1;


export const WeatherAttributes = [
    {
        key: 'temperature',
        title: '_TEMPERATURE_',
        emoji: '🌡️',
        color: Colors.transparency(Colors.colors.red, TRANSPARENCY),
        y: langIndex => item => item?.temperature?.length === 2 ? item.temperature[langIndex] : null,
        yFormat: langIndex => value => `${value}°${langIndex ? 'C' : 'F'}`,
    },
    {
        key: 'clouds',
        title: '_CLOUDS_',
        emoji: '☁️',
        color: Colors.transparency(Colors.colors.darkorange, TRANSPARENCY),
        y: () => item => item?.clouds,
        yFormat: () => value => `${value}%`,
    },
    {
        key: 'rain',
        title: '_RAIN_',
        emoji: '🌧️',
        color: Colors.transparency(Colors.colors.yellow, TRANSPARENCY),
        y: () => item => item?.rain,
        yFormat: () => value => `${value}%`,
    },
    {
        key: 'windSpeed',
        title: '_WIND_',
        emoji: '🌬️',
        color: Colors.transparency(Colors.colors.lightgreen, TRANSPARENCY),
        y: () => item => item?.windSpeed,
        yFormat: () => value => `${value}kts`,
    },
    {
        key: 'humidity',
        title: '_HUMIDITY_',
        emoji: '💦',
        color: Colors.transparency(Colors.colors.lightblue, TRANSPARENCY),
        y: () => item => item?.humidity,
        yFormat: () => value => `${value}%`,
    },
    {
        key: 'waveHeight',
        title: '_WAVES_',
        emoji: '🌊',
        color: Colors.transparency(Colors.colors.violet, TRANSPARENCY),
        y: langIndex => item => item?.waveHeight?.length === 2 ? item.waveHeight[langIndex] : null,
        yFormat: langIndex => value => `${value}${langIndex ? 'm' : 'ft'}`,
    },
];


var attributeMap = new Map();
WeatherAttributes.forEach(v => attributeMap.set(v.key, v));

export const WeatherAttributeMap = attributeMap;
