import { Console, Validate } from '../../../utils';

var elapse = {
    current: 0,
    previous: 0,
};

var events = [
    [0.0, entities => Console.log('start', entities)],
];

export class TimeUtils {
    static Elapse(value = null) {
        if (Validate.isValid(value)) {
            elapse = { current: value, previous: value };
        }
        return elapse;
    }

    static Increment(increment) {
        elapse.previous = elapse.current;
        elapse.current += increment;
        return elapse;
    }

    static Events(value = null) {
        if (Validate.isValid(value)) {
            events = [...value].sort((a, b) => a[0] < b[0]);
        }
        return events;
    }
}

export const Time = (entities, parms) => {
    const time = TimeUtils.Increment(parms.time.delta);
    TimeUtils.Events()
        .filter(e => e[0] > time.previous && e[0] <= time.current)
        .forEach(e => e[1](entities));
    return entities;
};
