export const ASLMP4_URL = 'https://s3-us-west-1.amazonaws.com/assets.startasl.com/videos/';

export const aslmp4 = new Set([
    'ac',
    'accountant',
    'across-2',
    'across',
    'actor',
    'africa',
    'after',
    'afternoon',
    'again',
    'airforce',
    'airplane',
    'algebra',
    'all-gone',
    'all',
    'always',
    'angry',
    'animal',
    'anniversary',
    'annoying',
    'annoying2',
    'antarctica',
    'apartment',
    'apple-2',
    'apple',
    'appointment',
    'architect-2',
    'architect',
    'are-you-a-student-2',
    'are-you-deaf',
    'are-you-hungry',
    'are-you-married-2',
    'argue',
    'army',
    'arrive',
    'arrogant',
    'art',
    'artist',
    'asia',
    'ask-you',
    'asl',
    'aspirin',
    'assistant',
    'attic',
    'aunt',
    'australia',
    'austria',
    'average',
    'awful',
    'baby',
    'bacon',
    'bad',
    'bald',
    'ball',
    'banana',
    'band',
    'bar',
    'bark',
    'baseball-player',
    'baseball',
    'basement',
    'basketball',
    'bass',
    'bath',
    'bathroom',
    'bathtub',
    'bear',
    'beard',
    'beautiful',
    'bed-rest',
    'bed',
    'bedroom',
    'bedspread',
    'before',
    'belt',
    'best',
    'bible',
    'bicycle',
    'biology',
    'bird',
    'birthday',
    'bite',
    'bitter',
    'black',
    'blanket',
    'blinds',
    'blog',
    'blow-money',
    'blue',
    'bluetooth',
    'boat',
    'book',
    'bookstore',
    'born',
    'boss',
    'both',
    'bowling',
    'boy',
    'boyfriend',
    'bracelet',
    'bread',
    'breakfast',
    'brick',
    'bright',
    'bring',
    'broke-in',
    'broke-up',
    'broke',
    'broken',
    'broom',
    'brother',
    'brown',
    'brush-teeth',
    'brush',
    'buddhist',
    'building',
    'bus-2',
    'bus',
    'busy',
    'butter',
    'buy',
    'cabinet',
    'cafeteria',
    'calendar',
    'camera',
    'camping',
    'can-you-fingerspell-that-again',
    'can-you-please-sign-slower',
    'can-you-please-sign-that-again',
    'can',
    'canada',
    'cancel',
    'candle',
    'candy',
    'car',
    'cards',
    'carnival',
    'carpenter',
    'carrot',
    'cat',
    'ceiling-fan',
    'ceiling2',
    'cell-phone',
    'cellphone-app',
    'chair',
    'change',
    'change2',
    'change3',
    'cheap',
    'cheese',
    'chemistry',
    'cherry',
    'chicken',
    'children',
    'china',
    'choir',
    'christian',
    'christmas',
    'church',
    'class',
    'classroom',
    'clean',
    'clock',
    'close',
    'closet',
    'clothes',
    'coffee-table',
    'coffee',
    'cold',
    'college',
    'color',
    'colors',
    'competition',
    'complain',
    'computer-2',
    'computer',
    'confident',
    'continue',
    'cook',
    'cookie',
    'corrections',
    'cough',
    'country',
    'cousin-female',
    'cousin-male',
    'cow',
    'cramp',
    'cry',
    'curtains',
    'cute',
    'dance',
    'darn',
    'date',
    'daughter',
    'day',
    'dead',
    'deaf',
    'decide',
    'delicious',
    'dentist',
    'dependent',
    'depressed',
    'desert',
    'desk',
    'diaper',
    'diarrhea',
    'dictionary',
    'different',
    'dining-room',
    'dinner',
    'dirty',
    'disagree',
    'discard',
    'dishwasher',
    'disorganized',
    'disorganized2',
    'diversity',
    'diversity2',
    'divorce',
    'dizzy',
    'do-you-have-children',
    'do-you-read-lips',
    'doctor-2',
    'doctor',
    'doesnt-matter',
    'dog-2',
    'dog',
    'donate',
    'dont-care',
    'dont-know',
    'dont-like',
    'dont-want',
    'door',
    'dorm',
    'down',
    'drama',
    'draw',
    'drawer',
    'drawing',
    'dress-2',
    'dresser',
    'drink',
    'drive',
    'drop',
    'drums',
    'dvd',
    'early',
    'earthquake',
    'east',
    'easter',
    'eat',
    'eggs',
    'egypt',
    'electricity-out',
    'elementary-school',
    'elephant',
    'elevator',
    'emergency',
    'empty',
    'engaged',
    'engine',
    'england',
    'english',
    'enjoy',
    'enter',
    'envelope',
    'environment',
    'eraser',
    'escape',
    'europe',
    'evening',
    'examine',
    'examine2',
    'excited',
    'excuse-me',
    'exercise',
    'expensive',
    'fabric',
    'fall-down',
    'fall-in-love',
    'fall',
    'fall2',
    'family-room',
    'family',
    'famous',
    'far',
    'farm',
    'fast',
    'fat-2',
    'fat',
    'father',
    'fence',
    'few',
    'fewer',
    'fight',
    'file',
    'finally',
    'find',
    'fine',
    'finish',
    'finland',
    'fired-2',
    'fireman',
    'fireplace',
    'first',
    'fish',
    'fishing',
    'fixing',
    'flood',
    'floor',
    'flower',
    'fog',
    'folders',
    'food',
    'football',
    'forest',
    'forgot2',
    'france',
    'freckles',
    'freeway',
    'freezer',
    'friday',
    'friend',
    'friendly',
    'front-door',
    'front',
    'fruit',
    'full',
    'furniture',
    'future',
    'gain-weight',
    'game',
    'garage',
    'gardening',
    'gas',
    'germany',
    'get-along',
    'get-up',
    'gift',
    'giraffe',
    'girl',
    'girlfriend',
    'give-blood',
    'give-you',
    'glass',
    'glasses-2',
    'glasses-3',
    'glasses',
    'global-warming',
    'glue',
    'go-ahead',
    'go-enter',
    'go',
    'god',
    'golf',
    'gone',
    'good-afternoon',
    'good-morning',
    'good',
    'goodbye',
    'goodnight',
    'gossip',
    'government',
    'graduate',
    'grand-daughter-2',
    'grand-daughter',
    'grand-son-2',
    'grand-son',
    'grandfather',
    'grandmother',
    'grapes',
    'gray',
    'greece',
    'green-beans',
    'green',
    'gross',
    'grow-up-2',
    'guitar',
    'gymnastics',
    'hair-long',
    'hair-short',
    'hair',
    'halloween',
    'halloween2',
    'hallway',
    'hamburger',
    'hammer',
    'handsome',
    'hannukah',
    'happy',
    'hard',
    'hat',
    'have',
    'headache',
    'headphones',
    'health',
    'hearing',
    'height',
    'hello',
    'help',
    'here',
    'highschool',
    'hiking',
    'history',
    'hockey',
    'home',
    'homework',
    'horse',
    'hospital',
    'hot',
    'hotdog',
    'hour',
    'house',
    'how-2',
    'how-are-you-2',
    'how-are-you',
    'how-did-you-get-here',
    'how-many-siblings-do-you-have',
    'how-much-does-it-cost',
    'how-old-are-you',
    'how-was-your-weekend',
    'how',
    'hug',
    'humble',
    'hungry',
    'hunting',
    'hurry',
    'hurt',
    'husband',
    'i-am-20-years-old',
    'i-am-not-married',
    'i-dont-understand',
    'i-feel-ok',
    'i-feel-tired',
    'i-feel-wonderful',
    'i-have-3-children',
    'i-have-a-question',
    'i-have-to-go-home',
    'ice-cream',
    'ice-skating',
    'ill-be-right-back',
    'ime',
    'important',
    'improve',
    'in',
    'income',
    'independent',
    'india',
    'indian',
    'infantry',
    'inform',
    'inheritance',
    'interest',
    'interesting',
    'internet',
    'intersection',
    'invent-imagine',
    'invest',
    'iran',
    'iraq',
    'ireland',
    'iron',
    'is-that-right',
    'is-that-true',
    'israel-jewish',
    'italy',
    'itchy',
    'jacket',
    'jail-prison',
    'jam',
    'janitor',
    'japan',
    'jealous',
    'job',
    'junior-high-school',
    'keep-paying',
    'ketchup',
    'key',
    'keyboard',
    'killed',
    'king',
    'kiss',
    'kitchen',
    'know',
    'korea',
    'laid-off',
    'lamp',
    'landlord',
    'laptop',
    'large',
    'last-past',
    'late',
    'laundry',
    'law',
    'lawyer',
    'lazy',
    'leader',
    'leak',
    'learn',
    'leave',
    'left-direction',
    'lemon',
    'lettuce',
    'librarian',
    'library',
    'lie',
    'light',
    'like',
    'limit',
    'lion',
    'listen',
    'living-room',
    'lock',
    'longer-than',
    'lose-weight',
    'lose',
    'lost',
    'loud',
    'lousy',
    'love',
    'lunch',
    'magazine',
    'mailman',
    'major',
    'man-2',
    'man',
    'manager',
    'manufacturer',
    'many',
    'married',
    'marry',
    'martial-arts',
    'matches',
    'math',
    'mean',
    'meat',
    'mechanic-2',
    'mechanic',
    'medicine-ear-drops',
    'medicine-ointment',
    'medicine-pills',
    'medicine-syrup',
    'medicine',
    'medium',
    'meeting',
    'messy',
    'metal',
    'mexican',
    'mexico',
    'microwave',
    'middle-school',
    'midnight',
    'milk',
    'minute',
    'mirror',
    'monday',
    'monkey',
    'month',
    'moon',
    'more-than',
    'more',
    'morning',
    'most',
    'mother',
    'motorcycle',
    'mountains',
    'move',
    'movie',
    'mug',
    'muscular',
    'mushroom',
    'music',
    'muslim',
    'must',
    'mustache',
    'my',
    'navy',
    'near',
    'necklace-2',
    'necklace',
    'need',
    'neighbor',
    'neighborhood',
    'nephew',
    'nervous',
    'never',
    'new-years',
    'nice-to-meet-you',
    'nice',
    'niece',
    'no-2',
    'no',
    'none',
    'noon',
    'north-america',
    'north-pole',
    'north',
    'nosy',
    'not-allowed',
    'not-allowed2',
    'not-fine',
    'not-yet',
    'not',
    'now',
    'numbers-1-10',
    'numbers-100',
    'numbers-11-20',
    'numbers-21-30',
    'nurse',
    'nuts',
    'ocean',
    'off',
    'office',
    'often',
    'ok',
    'ok2',
    'ok3',
    'old',
    'on',
    'once-a-month',
    'once-a-week',
    'onion',
    'open',
    'oppression',
    'orange',
    'ordinal-numbers',
    'organized',
    'out',
    'outside',
    'oven',
    'oven2',
    'owe',
    'painting',
    'pants',
    'paper',
    'parade',
    'park',
    'parking-lot',
    'party',
    'pass',
    'patient',
    'pay',
    'pay2',
    'paycheck',
    'peach',
    'pension',
    'pepper',
    'pet',
    'photo-album',
    'photographer',
    'photography',
    'physics',
    'piano',
    'pickup',
    'picture-frame',
    'picture',
    'pillow',
    'pineapple',
    'pineapple2',
    'pineapple3',
    'pink',
    'pizza-2',
    'pizza',
    'plains',
    'plant',
    'plastic',
    'play-2',
    'play',
    'playground',
    'please',
    'poetry',
    'point',
    'policeman',
    'porch2',
    'potato',
    'powerful',
    'practice',
    'pregnant',
    'president',
    'prevent',
    'program',
    'promotion',
    'psychology',
    'puerto-rico',
    'purple',
    'purse',
    'put-on-makeup',
    'queen',
    'quiet',
    'radio',
    'rain',
    'rainforest',
    'raking',
    'rash',
    'rat',
    're-arrange',
    'read',
    'recover',
    'red',
    'refrigerator',
    'refrigerator2',
    'relieved',
    'religion',
    'renovating',
    'replace',
    'restaurant',
    'retire',
    'rich',
    'right-direction',
    'right',
    'ring',
    'rocking-chair',
    'rocking-chair2',
    'roof',
    'room',
    'roommate',
    'rubber2',
    'rude',
    'rug',
    'run',
    'russia',
    'sad',
    'salt',
    'sandwich',
    'saturday',
    'savings',
    'scared',
    'school',
    'science',
    'scientist',
    'scissors',
    'scotland',
    'screwdriver',
    'searching',
    'season',
    'second',
    'secretary',
    'see-you-later',
    'sentence',
    'service',
    'sewing',
    'sewing2',
    'shave',
    'sheets',
    'shelves',
    'shirt-plaid',
    'shirt-v-neck',
    'shirt',
    'shoes',
    'shopping',
    'short',
    'shorts',
    'show',
    'shower',
    'shy',
    'siblings',
    'sick',
    'sign-language',
    'sign',
    'silly',
    'sing',
    'single-alone',
    'sink',
    'sister',
    'sit',
    'skiing',
    'skirt',
    'slavery',
    'sleep',
    'slow',
    'small',
    'smart',
    'smoking',
    'snake',
    'sniffles',
    'snow',
    'so-so',
    'soap',
    'socks',
    'soda-machine',
    'soda-pop',
    'sofa',
    'soft-hearted',
    'some',
    'sometimes',
    'son',
    'song',
    'sore-throat',
    'sore-throat2',
    'sorry-2',
    'sorry',
    'south-america',
    'south',
    'spackling',
    'spain',
    'spat',
    'spat2',
    'spinning',
    'spit',
    'sports',
    'spring',
    'stadium',
    'stairs',
    'stamp',
    'stand',
    'statue',
    'steal',
    'stingy',
    'stone',
    'stones2',
    'store',
    'strange',
    'strawberry',
    'street',
    'strict',
    'strong',
    'stubborn',
    'student-2',
    'student',
    'study',
    'subtract',
    'sugar',
    'summer',
    'sun-tanning',
    'sun',
    'sunday',
    'sunglasses',
    'support',
    'surgeon',
    'surgery',
    'swamp',
    'sweater',
    'sweden',
    'sweet',
    'sweetheart',
    'swimming-pool',
    'swimming',
    'swollen',
    'table',
    'take-care-of-yourself',
    'take-it-easy3',
    'take-pills',
    'take-turns',
    'take',
    'tall',
    'tape',
    'tea',
    'teacher',
    'team',
    'telephone',
    'television',
    'tend-to',
    'tennis',
    'thank-you-2',
    'thank-you',
    'thanksgiving',
    'thanksgiving2',
    'that',
    'thin-2',
    'thin',
    'think-about',
    'thirsty',
    'throw-up',
    'thursday',
    'ticket',
    'tie',
    'tiger',
    'time',
    'tired',
    'tissue',
    'today',
    'toilet',
    'tomato',
    'tomorrow',
    'tornado',
    'towel',
    'toy',
    'track-and-field',
    'traffic-2',
    'traffic',
    'train',
    'transfer',
    'trash2',
    'tree',
    'trouble',
    'tuesday',
    'turkey',
    'two-lane-highway',
    'ugly',
    'umbrella',
    'uncle',
    'united-states',
    'university',
    'until',
    'upset-stomach',
    'upset',
    'use-atm',
    'use',
    'vacation',
    'vacuum',
    'valentines-day',
    'vegetables',
    'vietnam',
    'vietnam2',
    'visit',
    'vlog',
    'volleyball',
    'vomit',
    'wait',
    'waiter',
    'wake-up',
    'walk',
    'walking',
    'wall',
    'want-2',
    'want',
    'war',
    'warm',
    'washer-dryer',
    'washing',
    'watch-tv',
    'watch',
    'watching-the-game',
    'water',
    'watermelon',
    'weak',
    'wealthy',
    'weather',
    'website',
    'wedding',
    'wednesday',
    'week',
    'weight',
    'welder',
    'west',
    'what-did-youthey-say',
    'what-do-you-think',
    'what-do',
    'what-does-that-mean',
    'what-is-your-name',
    'what-is-your-phone-number',
    'what-is-your-teachers-name',
    'what-time',
    'what',
    'when',
    'where-do-you-live',
    'where-do-you-rank-among-your-siblings',
    'where-do-you-work',
    'where-is-the-bathroom',
    'where',
    'white',
    'who-do-you-live-with',
    'who-is-your-teacher',
    'who',
    'why',
    'wife',
    'win',
    'wind',
    'window',
    'winter',
    'with',
    'without',
    'woman-2',
    'woman',
    'wonderful',
    'wood',
    'work',
    'workshop',
    'wow',
    'write-it-please',
    'write',
    'writing',
    'wrong',
    'yard',
    'year',
    'yell',
    'yellow',
    'yes-please',
    'yes-thank-you',
    'yes',
    'yesterday',
    'yesterday2',
    'yoga',
    'you',
    'young',
    'your',
    'youre-welcome',
]);
