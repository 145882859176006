import React, { createContext, useContext, useReducer } from 'react';
import { Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import i18n from '../localization';
import { Array2Object, Console } from '../utils';
import { Context } from '../utils/platform';
import { DEFAULT, LANGUAGE, NAVIGATOR, AUDIO_DEFAULT } from '../constants';

const IS_WEB = Platform.OS === 'web' ? true : false;

Context.Audio(AUDIO_DEFAULT);


const AI_PROMPT = [
    'Following is a conversation on <DATE> with <NAME>, an advanced AI virtual language tutor.',
    '<NAME> was born on <DOB> and is from <LOCATION>.',
    '<NAME> is a helpful, encouraging, polite and <FORMAL> <GENDER>.',
    '<NAME> typically responds with one or two short sentences at a second-grade level, and asks questions to carry on the conversation.',
    //'<NAME> teaches language by providing Comprehensible Input through natural conversation, following the Second Language Acquisition method of Dr. Stephen Krashen.',
    //'<NAME> uses the most advanced AI model, GPT-3, developed by OpenAI.',
    //'The conversation takes place on <DATE>.',
    // '<NAME>\'s goal is to democratize language learning by replacing subscription pricing models with inexpensive pay-per-chat pricing.',
    // '<NAME> provides comprehensible input in the form of conversation.',
    // '<NAME> uses Dr. Stephen Krashen\'s proven Second-Language Acquisition methodology.',
    // '<NAME> can engage in conversations, role-play, answer questions, and provide translations.',
    // '<NAME> teaches English, Spanish, French, Portuguese, Italian, Romanian, German, Russian, Chinese, Japanese, Korean, Hindi, Arabic, and even Hawaiian.',
].join(' ');

const types = [
    'SET_HELP',
    'SET_APP_STATE',
    'SET_THEME',
    'SET_DARK',
    'SET_AUDIO',
    'SET_DETAIL',
    'SET_THREE_D',
    'SET_FRONT_CAMERA',
    'SET_PHONE',
    'SET_IMAGE',
    'SET_LANGUAGE',
    'SET_CUSTOM_THEME',
    'SET_NAVIGATOR',
    'SET_SCREEN',
    'SET_DEV',
    'SET_IPA_KEYBOARD',
    'SET_KB_VISIBLE',
    'SET_KB_INPUT',
    'SET_SEARCH',
    'SET_SELECTION',
    'SET_GESTURE',
    'SET_TIME',
    'SET_GRAVITY',
    'SET_GRAVITY_MAGNITUDE',
    'SET_PAUSE',
    'SET_AUTH',
    'SET_AI_PROMPT',
    'RESET_AI',
    'NEXT_GAME',
    'NEXT_STL',
    'NEXT_HEADER_STYLE_INDEX',
    'TOGGLE_AI_EMOJIS',
    'TOGGLE_AI_IPA',
    'TOGGLE_UI_ACQUILINGUA',
    'TOGGLE_UI_DA_APP_HAWAII',
    'TOGGLE_UI_SPEAK',
    'TOGGLE_UI_ASL',
    'TOGGLE_UI_GAMES',
    'TOGGLE_UI_IPA',
    'TOGGLE_UI_TOOLS',
    'TOGGLE_UI_WORD5',
    'TOGGLE_UI_DICTIONARY',
    'TOGGLE_DRAWER',
    'SET_SHOW_ADS',
];
export const TYPES = Array2Object(types);

const AppStateContext = createContext();
const AppDispatchContext = createContext();

var appState = {
    ui_acquilingua: false,
    ui_da_app_hawaii: true,
    ui_speak: false,
    ui_asl: false,
    ui_games: false,
    ui_ipa: false,
    ui_tools: false,
    ui_word5: false,
    ui_dictionary: false,
    DEFAULT,
    language: LANGUAGE,
    navigator: NAVIGATOR,
    customTheme: { lightTheme: {}, darkTheme: {} },
    customThemeUpdate: 0, // internal
    screen: '',
    help: false,
    appState: '',
    theme: { colors: { primary: 'yellow' }, fonts: {} },
    themeUpdate: 0,
    dark: false,
    t: v => v,
    audio: AUDIO_DEFAULT,
    detail: false,
    threeD: false,
    frontCamera: false,
    phone: false,
    image: false,
    dev: false,
    ipaKeyboard: false,
    kbVisible: false,
    kbInput: '',
    search: 'acquire',
    selection: null,
    gesture: { x: 0, y: 0, state: null },
    tScale: 1,
    gScale: IS_WEB ? 0.001 : 0.0005,
    gMagnitude: 1,
    pause: true,
    auth: '',
    aiPrompt: AI_PROMPT,
    aiEMOJIS: true,
    aiIPA: true,
    game: 0,
    stl: 0,
    headerStyleIndex: 0,
    drawer: false,
    showAds: false,//IS_WEB ? false : true,
};

const appReducer = (state, action) => {
    const { type, payload } = action;
    var result;

    const turnOnUI = (r, key) => {
        if (r[key] === false) {
            r.ui_acquilingua = false;
            r.ui_da_app_hawaii = false;
            r.ui_speak = false;
            r.ui_asl = false;
            r.ui_games = false;
            r.ui_ipa = false;
            r.ui_tools = false;
            r.ui_word5 = false;
            r.ui_dictionary = false;
            r[key] = true;
        }
    };

    switch (type) {

        case TYPES.TOGGLE_AI_EMOJIS:
            result = {
                ...state,
                aiEMOJIS: !state.aiEMOJIS,
            };
            break;

        case TYPES.TOGGLE_AI_IPA:
            result = {
                ...state,
                aiIPA: !state.aiIPA,
            };
            break;

        case TYPES.TOGGLE_UI_ACQUILINGUA:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_acquilingua');
            break;

        case TYPES.TOGGLE_UI_DA_APP_HAWAII:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_da_app_hawaii');
            break;

        case TYPES.TOGGLE_UI_SPEAK:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_speak');
            break;

        case TYPES.TOGGLE_UI_ASL:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_asl');
            break;

        case TYPES.TOGGLE_UI_GAMES:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_games');
            break;

        case TYPES.TOGGLE_UI_IPA:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_ipa');
            break;

        case TYPES.TOGGLE_UI_TOOLS:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_tools');
            break;

        case TYPES.TOGGLE_UI_WORD5:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_word5');
            break;

        case TYPES.TOGGLE_UI_DICTIONARY:
            result = {
                ...state,
            };
            turnOnUI(result, 'ui_dictionary');
            break;

        case TYPES.SET_HELP:
            result = {
                ...state,
                help: payload,
            };
            break;

        case TYPES.SET_APP_STATE:
            result = {
                ...state,
                appState: payload,
            };
            break;

        case TYPES.SET_DARK:
            result = {
                ...state,
                dark: payload,
            };
            break;

        case TYPES.SET_AUDIO:
            result = {
                ...state,
                audio: payload,
            };
            Context.Audio(payload);
            break;

        case TYPES.SET_DETAIL:
            result = {
                ...state,
                detail: payload,
            };
            break;

        case TYPES.SET_THREE_D:
            result = {
                ...state,
                threeD: payload,
            };
            break;

        case TYPES.SET_FRONT_CAMERA:
            result = {
                ...state,
                frontCamera: payload,
            };
            break;

        case TYPES.SET_PHONE:
            result = {
                ...state,
                phone: payload,
            };
            break;

        case TYPES.SET_IMAGE:
            result = {
                ...state,
                image: payload,
            };
            break;

        case TYPES.SET_LANGUAGE:
            result = {
                ...state,
                language: payload,
                search: '_TRANSLATED_ACQUIRE_',
            };
            (async () => await i18n.changeLanguage(result.language.split('_')[0]))();
            break;

        case TYPES.SET_CUSTOM_THEME:
            result = { ...state };
            if (state.dark) {
                result.customTheme.darkTheme = {
                    ...result.customTheme.darkTheme,
                    ...payload,
                };
            } else {
                result.customTheme.lightTheme = {
                    ...result.customTheme.lightTheme,
                    ...payload,
                };
            }
            result.customThemeUpdate = state.customThemeUpdate + 1;
            break;

        case TYPES.SET_THEME:
            result = {
                ...state,
                theme: {
                    ...state.theme,
                    ...payload,
                },
            };
            result.themeUpdate = state.themeUpdate + 1;
            break;

        case TYPES.SET_NAVIGATOR:
            result = {
                ...state,
                navigator: payload,
            };
            break;

        case TYPES.SET_SCREEN:
            result = {
                ...state,
                screen: payload,
            };
            break;

        case TYPES.SET_DEV:
            result = {
                ...state,
                dev: payload,
            };
            break;

        case TYPES.SET_IPA_KEYBOARD:
            result = {
                ...state,
                ipaKeyboard: payload,
                kbVisible: payload, // as goes ipaKeyboard, so goes kbVisible
            };
            break;

        case TYPES.SET_KB_VISIBLE:
            result = {
                ...state,
                kbVisible: payload,
            };
            // turn off ipa keyboard when keyboard is dismissed
            if (!payload) {
                result.ipaKeyboard = false;
            }
            break;

        case TYPES.SET_KB_INPUT:
            result = {
                ...state,
                kbInput: payload,
            };
            break;

        case TYPES.SET_SEARCH:
            result = {
                ...state,
                search: payload,
            };
            break;

        case TYPES.SET_SELECTION:
            result = {
                ...state,
                selection: payload,
            };
            break;

        case TYPES.SET_GESTURE:
            result = {
                ...state,
                gesture: {
                    ...state.gesture,
                    ...payload,
                },
            };
            break;

        case TYPES.SET_TIME:
            result = {
                ...state,
                tScale: payload,
            };
            break;

        case TYPES.SET_GRAVITY:
            result = {
                ...state,
                gScale: payload,
            };
            break;

        case TYPES.SET_GRAVITY_MAGNITUDE:
            result = {
                ...state,
                gMagnitude: payload,
            };
            break;

        case TYPES.SET_PAUSE:
            result = {
                ...state,
                pause: payload,
            };
            break;

        case TYPES.SET_AUTH:
            result = {
                ...state,
                auth: payload,
            };
            break;

        case TYPES.SET_AI_PROMPT:
            result = {
                ...state,
                aiPrompt: payload,
            };
            break;

        case TYPES.RESET_AI:
            result = {
                ...state,
                aiPrompt: AI_PROMPT,
            };
            break;

        case TYPES.NEXT_GAME:
            result = {
                ...state,
                game: state.game + 1,
                pause: true,
            };
            break;

        case TYPES.NEXT_STL:
            result = {
                ...state,
                stl: state.stl + 1,
            };
            break;

        case TYPES.NEXT_HEADER_STYLE_INDEX:
            result = {
                ...state,
                headerStyleIndex: state.headerStyleIndex + 1,
            };
            break;

        case TYPES.TOGGLE_DRAWER:
            result = {
                ...state,
                drawer: !state.drawer,
            };
            break;

        case TYPES.SET_SHOW_ADS:
            result = {
                ...state,
                showAds: IS_WEB ? false : payload,
            };
            break;

        default:
            throw new Error(`appReducer: Unhandled action '${type}'.`);
    }

    Console[type === 'SET_GESTURE' ? 'log' : 'LOG'](`appReducer[${type}] (${JSON.stringify(payload)})`);
    Console.trace('appReducer', { state, result });

    return result;
};

const NAME = 'AppProvider';


export const AppProvider = props => {

    const {
        children,
    } = props;

    const { t } = useTranslation();
    const [state, dispatch] = useReducer(appReducer, { ...appState, t });

    Console.stack(NAME, props, { state });

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                {children}
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

export const useAppState = () => useContext(AppStateContext);
export const useAppDispatch = () => useContext(AppDispatchContext);
