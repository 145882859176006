import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Alert, StyleSheet } from 'react-native';
import { Button, Card, FlatList, Icon, Image, Pressable, Text, View, Screen, BUTTON_VARIANTS, TEXT_VARIANTS } from '../components';
import { useAppState, useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { MATERIAL_COMMUNITY, CreativeCommonsLogo, PleasantHolidaysLogo } from '../media';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { PleasantHolidaysUrl } from '../thirdparty';
import { Navigate, NavParams } from './utils';

const NAME = 'MapLocationDetail';

const MAP_CREATIVE_COMMONS_DETAIL = 'MapCreativeCommonsDetail';
const MAP_WEB_DETAIL = 'MapWebDetail';

const BOOKING = 'BOOKING';

const IMAGE_WIDTH = 360;
const IMAGE_HEIGHT = 240;

const IconColors = [
    Colors.colors.red,
    Colors.colors.darkorange,
    Colors.colors.yellow,
    Colors.colors.lightgreen,
    Colors.colors.lightblue,
    Colors.colors.violet,
    'rgb(188,0,188)',
];

export const MapLocationDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight } = useSystemState();
    const { t, language, dark, showAds } = useAppState();

    const [item, setItem] = useState({});
    const setItemRef = useRef(setItem);

    const [info, setInfo] = useState([]);
    const setInfoRef = useRef(setInfo);

    const [icons, setIcons] = useState([]);
    const setIconsRef = useRef(setIcons);

    const [description, setDescription] = useState(null);
    const setDescriptionRef = useRef(setDescription);

    const [showHP, setShowHP] = useState(true);
    const setShowHPRef = useRef(setShowHP);

    const [imageIndex, setImageIndex] = useState(0);
    const setImageIndexRef = useRef(setImageIndex);

    const [data] = useState(NavParams(route)?.data);
    const [title] = useState(NavParams(route)?.data?.title);

    useEffect(
        () => {

            var tempInfo = [];
            //tempInfo.push('<');
            if (data?.contact?.phone) {
                tempInfo.push(`☎️${data.contact.phone}`);
            }
            if (data?.contact?.address) {
                tempInfo.push(`✉️${data.contact.address.split('|')[0].split(',')[0]}`);
            }
            if (data?.contact?.url) {
                tempInfo.push(`❤️${data.contact.url}`);
            }
            //tempInfo.push('>');

            var tempDescription = null;
            if (showHP && language.startsWith('en') && data?.description?.hp) {
                tempDescription = `🤙🏽  ${data.description.hp}`;
            } else if (data?.description) {
                const languageTokens = language.toLowerCase().split('_');
                if (data.description[languageTokens[0]]) {
                    tempDescription = `ℹ️  ${data.description[languageTokens[0]]}`;
                } else if (languageTokens.length > 1 && data.description[languageTokens[1]]) {
                    tempDescription = `ℹ️  ${data.description[languageTokens[1]]}`;
                }
            }

            Console.devLog(`${NAME} useEffect entry`, { title, data, tempInfo, tempDescription, language, showHP });

            setItemRef.current({ ...data });
            setInfoRef.current(tempInfo);
            setIconsRef.current(data?.icons?.length ? data.icons : []);
            setDescriptionRef.current(tempDescription);

            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={title?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            data,
            showHP,
            language,
            title,
            navigation,
            setItemRef,
            setInfoRef,
            setIconsRef,
            setDescriptionRef,
        ],
    );

    const renderItem = useCallback(
        _item => {
            Console.log(`${NAME}.renderItem`, { _item, showHP, dark });
            const cardColor = { backgroundColor: dark ? Colors.colors.black : Colors.colors.lightgray };
            const blackColorStyle = { color: dark ? Colors.colors.white : Colors.colors.black };
            return (
                <Card
                    cardStyle={[styles.card, cardColor]}
                    titleStyle={[blackColorStyle, cardColor]}
                    title={_item.item.value}
                    onPress={() => setShowHPRef.current(v => !v)}
                />
            );
        },
        [
            dark,
            showHP,
            setShowHPRef,
        ],
    );

    Console.stack(NAME, props, { adjHeight, item, showAds, data, info, icons, description });

    return useMemo(
        () => {
            const URIS = [
                { source: { uri: 'https://upload.wikimedia.org/wikipedia/commons/c/c6/Waikiki_%283%29.jpg' } },
                { source: { uri: 'https://upload.wikimedia.org/wikipedia/commons/7/74/Waikiki_Beach%2C_Honolulu.JPG' } },
            ];
            const viewColor = { backgroundColor: dark ? Colors.colors.black : Colors.colors.lightgray };
            const adsHeight = { height: showAds ? adjHeight - BANNER_AD_HEIGHT : '100%' };
            var images = data?.images?.length ? data.images : URIS;
            if (data?.image?.length) {
                images = data.image
                    .map(v => {
                        return {
                            source: {
                                uri: v.replace(/s3:\/\/acquilingua-apk173646-demo\/daapppics\//, 'https://acquilingua-apk173646-demo.s3.us-east-2.amazonaws.com/daapppics/'),
                            },
                        };
                    });
            }
            const index = imageIndex % images.length;
            const image = images[index];
            const source = image.source;
            const attribution = image.attribution;
            const license = image.license;
            Console.devLog(`${NAME} render`, { index, images: images.length, info, adjHeight, adsHeight, showAds, data, source });
            const lightGreenColorStyle = { backgroundColor: Colors.colors.lightgreen };
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={[styles.view, adsHeight, viewColor]}
                    >
                        <Image
                            source={source}
                            containerStyle={styles.logo}
                            width={IMAGE_WIDTH}
                            height={IMAGE_HEIGHT}
                            resizeMode={'cover'}
                        />
                        <Pressable
                            style={styles.logo}
                            onPress={() => setImageIndexRef.current(v => v + 1)}
                        />
                        {Optional(info.length, (
                            <View
                                style={styles.contactGroup}
                            >
                                {info.map((v, idx) => (
                                    <Button
                                        key={`button_${idx}`}
                                        value={v.charAt(0)}
                                        textColor={'white'}
                                        labelStyle={styles.contactGroupLabel}
                                        variant={BUTTON_VARIANTS.TEXT}
                                        onPress={() => Alert.alert(null, v.substring(2), [{ text: 'OK' }])}
                                    />
                                ))}
                            </View>
                        ))}
                        {Optional(attribution && license, (
                            <Pressable
                                style={styles.creativeCommons}
                                onPress={() => {
                                    Navigate(navigation, t(MAP_CREATIVE_COMMONS_DETAIL), {
                                        title: MAP_CREATIVE_COMMONS_DETAIL,
                                        data: { attribution, license },
                                    });
                                }}
                            >
                                {CreativeCommonsLogo(dark)}
                            </Pressable>
                        ))}
                        {Optional(icons.length, (
                            <View
                                style={styles.iconGroup}
                            >
                                {icons.map((iconName, idx) => (
                                    <Icon
                                        key={`icon_${idx}`}
                                        type={MATERIAL_COMMUNITY}
                                        iconName={iconName}
                                        iconStyle={styles.iconStyle}
                                        color={IconColors[idx % IconColors.length]}
                                    />
                                ))}
                            </View>
                        ))}
                        {Optional(description?.length, (
                            <View
                                style={styles.list}
                            >
                                <FlatList
                                    data={[{ key: 'description', value: description?.replace(/\|/g, '\n\n') }]}
                                    renderItem={renderItem}
                                />
                            </View>
                        ))}
                        <View
                            style={styles.buttonGroup}
                        >
                            <Button
                                value={data?.type === 'hikes' ? 'Reservation' : 'Coupons'}
                                variant={BUTTON_VARIANTS.CONTAINED}
                                style={[styles.button, lightGreenColorStyle]}
                                backgroundColor={Colors.colors.green}
                                labelStyle={styles.buttonLabel}
                                onPress={() => Console.devLog('Coupon')}
                            />
                            <Pressable
                                onPress={() => {
                                    Navigate(navigation, t(MAP_WEB_DETAIL), {
                                        title: MAP_WEB_DETAIL,
                                        data: { uri: PleasantHolidaysUrl() },
                                    });
                                }}
                            >
                                <Text value={BOOKING} />
                                {PleasantHolidaysLogo(dark)}
                            </Pressable>
                        </View>
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            t,
            navigation,
            props,
            dark,
            adjHeight,
            showAds,
            data,
            info,
            icons,
            description,
            renderItem,
            imageIndex,
            setImageIndexRef,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
    view: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logo: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: IMAGE_HEIGHT,
        width: IMAGE_WIDTH,
    },
    list: {
        //flex: -1,
        position: 'absolute',
        top: IMAGE_HEIGHT + 50,
        left: '1%',
        width: '98%',
        height: 325,
        //borderColor: 'yellow',
        //borderWidth: 1,
        //marginVertical: '1%',
    },
    contactGroup: {
        position: 'absolute',
        top: IMAGE_HEIGHT,
        left: 0,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
        alignContent: 'center',
        //marginTop: '2%',
        //position: 'absolute',
        //left: 0,
        //bottom: 50,
        height: 50,
        backgroundColor: 'rgba(200,200,200,0.3)',//Colors.colors.transparent,

        /*
        position: 'absolute',
        width: '10%',
        height: IMAGE_HEIGHT,
        left: '85%',
        top: '2%',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.colors.transparent,
        */
    },
    contactGroupLabel: {
        fontSize: 30,
        //fontWeight: 'bold',
        //color: Colors.colors.black,
    },
    creativeCommons: {
        position: 'absolute',
        top: 5,
        right: 5,
        width: 32,
        height: 32,
        //backgroundColor: Colors.colors.transparent,
    },
    scrollView: {
        width: '100%',
        height: '79%',
        alignItems: 'center',
    },
    card: {
        //paddingVertical: 2,
        paddingHorizontal: 2,
    },
    button: {
        marginVertical: 5,
    },
    buttonLabel: {
        fontSize: 20,
        fontWeight: 'bold',
        fontStyle: 'italic',
        color: Colors.colors.black,
    },
    buttonGroup: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        marginVertical: '2%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-evenly',
    },
    menu: {
        width: '100%',
        marginTop: '15%',
    },
    menuItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    iconGroup: {
        position: 'absolute',
        top: IMAGE_HEIGHT,
        left: 0,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        paddingTop: '2.5%',
        backgroundColor: 'rgb(80,80,80)',
        height: 50,
    },
    iconStyle: {
        padding: 5,
        fontSize: 38,
    },
});
