import seedrandom from 'seedrandom';
import { Console } from './Console';

var randomNumber = seedrandom();
const RAD_PER_DEG = Math.PI / 180;
const DEG_PER_RAD = 180 / Math.PI;


export class Numbers {

    static floatEqual(a, b) {
        if (a === b) {
            return true;
        }
        const diff = Math.abs(a - b);
        if (diff < Number.EPSILON) {
            return true;
        }
        return diff <= Number.EPSILON * Math.min(Math.abs(a), Math.abs(b));
    }

    static percentage = (n, digits = 1) => {
        return `${(Math.round(n * 1000.0) / 10.0).toFixed(digits)}%`;
    }

    static format = (value, digits = 1) => {
        if (value === null) {
            Console.warn('Numbers.format null input');
            return 0;
        }
        var temp = value;
        var factor = 1.0;
        for (let i = 0; i < digits; i++) {
            factor *= 10.0;
        }
        var temp = Math.round(value * factor) / factor;
        return temp.toFixed(digits);
    }

    static padLeft = (value, digits = 2) => {
        return String(value).padStart(digits, '0');
    };

    static average = (value1, value2) => {
        return (value1 + value2) * 0.5;
    }

    static compareArrays = (a, b) => {
        if (a.length !== b.length) {
            return false;
        }
        for (let i = 0; i < a.length; i++) {
            const A = a[i];
            var match = false;
            for (let j = 0; j < b.length && !match; j++) {
                const B = b[j];
                if (A === B) {
                    match = true;
                }
            }
            if (!match) {
                return false;
            }
        }
        return true;
    };

    static isSameArray = (a, b) => {
        return Numbers.compareArrays(a, b) && Numbers.compareArrays(b, a);
    }

    static randomSeed(seed) {
        randomNumber = seedrandom(seed);
    }

    static randomFloat(seed = null) {
        if (seed !== null) {
            Numbers.randomSeed(seed);
        }
        return randomNumber();
    }

    static randomBool(seed = null) {
        if (seed !== null) {
            Numbers.randomSeed(seed);
        }
        return randomNumber() < 0.5 ? true : false;
    }

    static random(max, seed = null) {
        if (Math.floor(max) < 1) {
            Console.log(`random(${max}): invalid args, max < 1`);
            return 0;
        }
        if (seed !== null) {
            Numbers.randomSeed(seed);
        }
        return Math.floor(randomNumber() * Math.floor(max));
    }

    static randomIntegers(max, num, seed = null) {
        if (max < num) {
            Console.log(`randomIntegers(${max}, ${num}): invalid args, max < num`);
            return [];
        }
        if (seed !== null) {
            Numbers.randomSeed(seed);
        }
        var result = new Set();
        while (result.size < num) {
            result.add(Numbers.random(max));
        }
        return [...result];
    }

    static randomIndex(length, prev = -1, seed = null) {
        if (length < 1) {
            return 0;
        }
        const index = Numbers.random(length - (prev >= 0 ? 1 : 0), seed);
        return index === prev ? index + 1 : index;
    }

    static randomItem(arr, seed = null) {
        return !arr ? null : arr[Numbers.randomIndex(arr.length, -1, seed)];
    }

    static toNumber(value) {
        return value * 1;
    }

    static sortNumbers(arr) {
        try {
            return arr.sort((a, b) => (a * 1) - (b * 1));
        } catch (error) {
            Console.log(`sortNumbers: cannot numerically sort ${arr}`, error.message);
            return arr.sort();
        }
    }

    static unsignedRange(bits) {
        return Math.pow(2, bits);
    }

    static signedRange(bits) {
        return Math.pow(2, bits - 1);
    }

    static distance(dx, dy) {
        return Math.sqrt(dx * dx + dy * dy);
    }

    static toRadians(degrees) {
        return degrees * RAD_PER_DEG;
    }

    static toDegrees(radians) {
        return radians * DEG_PER_RAD;
    }

    static degreesToCompass(deg) {
        var compass = 'N';
        if (deg >= 12) {
            compass = 'NNE';
        }
        if (deg >= 34) {
            compass = 'NE';
        }
        if (deg >= 57) {
            compass = 'ENE';
        }
        if (deg >= 79) {
            compass = 'E';
        }
        if (deg >= 102) {
            compass = 'ESE';
        }
        if (deg >= 124) {
            compass = 'SE';
        }
        if (deg >= 147) {
            compass = 'SSE';
        }
        if (deg >= 169) {
            compass = 'S';
        }
        if (deg >= 192) {
            compass = 'SSW';
        }
        if (deg >= 214) {
            compass = 'SW';
        }
        if (deg >= 237) {
            compass = 'WSW';
        }
        if (deg >= 259) {
            compass = 'W';
        }
        if (deg >= 282) {
            compass = 'WNW';
        }
        if (deg >= 304) {
            compass = 'NW';
        }
        if (deg >= 327) {
            compass = 'NNW';
        }
        if (deg >= 349) {
            compass = 'N';
        }
        return compass;
    }

    static clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }

    static celsiusToFahrenheit(tempC) {
        return (tempC * 9.0 / 5.0) + 32.0;
    }

    static metersToFeet(distM) {
        return distM * 3.280839895;
    }

    static minsToTime(mins) {
        const min = Numbers.padLeft(mins % 60, 2);
        var hr = Math.floor(mins / 60);
        var meridiem = 'AM';
        if (hr > 12) {
            hr -= 12;
            meridiem = 'PM';
        }
        return `${hr}:${min} ${meridiem}`;
    }

    static boundingBox(coordinates) {
        var result = {
            xMin: Number.MAX_VALUE,
            xMax: -Number.MAX_VALUE,
            yMin: Number.MAX_VALUE,
            yMax: -Number.MAX_VALUE,
        };
        coordinates.forEach(c => {
            result.xMin = Math.min(c.x, result.xMin);
            result.xMax = Math.max(c.x, result.xMax);
            result.yMin = Math.min(c.y, result.yMin);
            result.yMax = Math.max(c.y, result.yMax);
        });
        result.w = result.xMax - result.xMin;
        result.h = result.yMax - result.yMin;
        return result;
    }

    static ReScaleStyle(style, scale) {
        var result = { ...style };
        Object.keys(style).forEach(field => {
            var value = style[field];
            if (typeof value === 'number') {
                result[field] = value * scale;
            }
        });
        return result;
    }

    static fix(value, factor = 1) {
        const scale = Math.pow(10.0, factor);
        return Math.round(value * scale) / scale;
    }

    static getOrder(value) {
        const v = Math.abs(value);
        var magnitude = 1.0;
        var factor = 1;
        if (v < 1.0) {
            factor = 0;
            while ((v / magnitude) < 10.0) {
                magnitude *= 0.1;
                factor--;
            }
            magnitude = Numbers.fix(magnitude, -factor);
        } else {
            while ((v / magnitude) > 10.0) {
                magnitude *= 10.0;
                factor++;
            }
            magnitude = Numbers.fix(magnitude, factor);
        }
        return magnitude;
    }

    static getTicks(hi, lo, minTicks = 10, maxTicks = 50) {
        const diff = hi - lo;
        const order = Numbers.getOrder(hi);
        var ticks = [];
        [1, 2, 5, 10, 20, 50, 100].forEach(x => ticks.push(order / x));
        var index = 0;
        while (index < ticks.length && Math.round(diff / ticks[index]) <= minTicks) {
            index++;
        }
        if (index < ticks.length - 1 && Math.round(diff / ticks[index + 1] <= maxTicks)) {
            index++;
        }
        const increment = ticks[index];
        const start = lo - lo % increment;
        const end = hi + (ticks[index] - hi % increment);
        return {
            start,
            end,
            increment,
            count: Math.round((end - start) / increment),
        };
    }
}
