import { CrosswordGame, Speech2D, Speech3D } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Speech,

    TabArray: ['Speech2D', 'Speech3D', 'CrosswordGame'],

    Tabs: {
        Speech2D: {
            Component: Speech2D,
            Icon: Icons.Speech,
        },
        Speech3D: {
            Component: Speech3D,
            Icon: Icons.Dimensions3D,
        },
        CrosswordGame: {
            Component: CrosswordGame,
            Icon: Icons.Games,
        },
    },

    Stacks: {
    },

};
