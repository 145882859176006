import { SignCamera, Signing } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Hand,

    TabArray: ['Signing', 'Camera'],

    Tabs: {
        Signing: {
            Component: Signing,
            Icon: Icons.Hand,
        },
        Camera: {
            Component: SignCamera,
            Icon: Icons.Camera,
        },
    },

    Stacks: {
    },

};
