const MILLIS_PER_DAY = 24 * 60 * 60 * 1000;

const MIN_LAT = 18.5;
const MAX_LAT = 22.5;
const MIN_LNG = -160.0;
const MAX_LNG = -154.5;

const DLAT = MAX_LAT - MIN_LAT;
const DLNG = MAX_LNG - MIN_LNG;

// 29.53058771 days [29.53059 (wiki)]
// http://individual.utoronto.ca/kalendis/lunar/#FALC
const MoonPeriod = {
    d: 29,
    h: 12,
    m: 44,
    s: 2 + (7 / 8),
};

const MoonPeriodMillis = 1000 /* ms/sec */ *
    (MoonPeriod.s +
    60 /* sec/min */ * (MoonPeriod.m +
    60 /* min/hr */  * (MoonPeriod.h +
    24 /* hr/day */  * MoonPeriod.d)));

const MoonPhases = [
    'moon-new',
    'moon-waxing-crescent',
    'moon-first-quarter',
    'moon-waxing-gibbous',
    'moon-full',
    'moon-waning-gibbous',
    'moon-last-quarter',
    'moon-waning-crescent',
];
const MoonPhaseMillis = MoonPeriodMillis / MoonPhases.length;

// recent new moon
// https://www.timeanddate.com/moon/phases/
const NewMoonBaseline = new Date('May 19 2023 15:53:00 GMT'); /* 5:53 am HST */

// shift baseline back half a phase cycle, so 'new moon' started halfway between preceding waning crescent and new moon
const HalfPhaseShiftedBaseline = new Date(NewMoonBaseline.getTime() - (MoonPhaseMillis * 0.5));
const HalfPhaseShiftedBaselineMillis = HalfPhaseShiftedBaseline.getTime();

const StartDateUTC = new Date('Jan 1 2023 00:00:00 GMT');

// https://aa.usno.navy.mil/calculated/rstt/year?ID=AA&year=2027&task=0&lat=18.5&lon=-160&label=&tz=10.00&tz_sign=-1&submit=Get+Data
// data from 2023-2026, inclusive
const SunHawaiiData = [
{SW:[432,1095],NE:[418,1065],SE:[410,1073],NW:[440,1087]},
{SW:[433,1096],NE:[418,1066],SE:[411,1074],NW:[440,1088]},
{SW:[433,1096],NE:[419,1067],SE:[411,1074],NW:[441,1089]},
{SW:[433,1097],NE:[419,1067],SE:[411,1075],NW:[441,1089]},
{SW:[434,1097],NE:[419,1068],SE:[412,1075],NW:[441,1090]},
{SW:[434,1098],NE:[419,1069],SE:[412,1076],NW:[441,1091]},
{SW:[434,1099],NE:[419,1069],SE:[412,1077],NW:[441,1091]},
{SW:[434,1099],NE:[420,1070],SE:[412,1077],NW:[442,1092]},
{SW:[434,1100],NE:[420,1071],SE:[412,1078],NW:[442,1093]},
{SW:[435,1101],NE:[420,1071],SE:[413,1079],NW:[442,1093]},
{SW:[435,1101],NE:[420,1072],SE:[413,1079],NW:[442,1094]},
{SW:[435,1102],NE:[420,1073],SE:[413,1080],NW:[442,1095]},
{SW:[435,1103],NE:[420,1073],SE:[413,1081],NW:[442,1095]},
{SW:[435,1103],NE:[420,1074],SE:[413,1081],NW:[442,1096]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1104],NE:[420,1076],SE:[413,1082],NW:[442,1098]},
{SW:[435,1105],NE:[420,1076],SE:[413,1083],NW:[442,1098]},
{SW:[435,1106],NE:[420,1077],SE:[413,1084],NW:[442,1099]},
{SW:[435,1106],NE:[420,1078],SE:[413,1084],NW:[442,1100]},
{SW:[435,1107],NE:[420,1078],SE:[413,1085],NW:[442,1100]},
{SW:[435,1108],NE:[420,1079],SE:[413,1086],NW:[442,1101]},
{SW:[435,1108],NE:[420,1080],SE:[413,1086],NW:[442,1102]},
{SW:[435,1109],NE:[419,1080],SE:[413,1087],NW:[441,1102]},
{SW:[435,1109],NE:[419,1081],SE:[413,1087],NW:[441,1103]},
{SW:[435,1110],NE:[419,1082],SE:[413,1088],NW:[441,1104]},
{SW:[435,1111],NE:[419,1082],SE:[413,1089],NW:[441,1105]},
{SW:[435,1111],NE:[419,1083],SE:[413,1089],NW:[441,1105]},
{SW:[434,1112],NE:[418,1084],SE:[412,1090],NW:[440,1106]},
{SW:[434,1112],NE:[418,1085],SE:[412,1090],NW:[440,1107]},
{SW:[434,1113],NE:[418,1085],SE:[412,1091],NW:[440,1107]},
{SW:[434,1113],NE:[417,1086],SE:[412,1091],NW:[439,1108]},
{SW:[433,1114],NE:[417,1086],SE:[411,1092],NW:[439,1108]},
{SW:[433,1114],NE:[417,1087],SE:[411,1092],NW:[438,1109]},
{SW:[433,1115],NE:[416,1088],SE:[411,1093],NW:[438,1110]},
{SW:[432,1116],NE:[416,1088],SE:[410,1094],NW:[438,1110]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[432,1117],NE:[415,1090],SE:[410,1095],NW:[437,1112]},
{SW:[431,1117],NE:[414,1090],SE:[409,1095],NW:[436,1112]},
{SW:[431,1118],NE:[414,1091],SE:[409,1096],NW:[436,1113]},
{SW:[430,1118],NE:[413,1091],SE:[409,1096],NW:[435,1113]},
{SW:[430,1119],NE:[413,1092],SE:[408,1096],NW:[435,1114]},
{SW:[430,1119],NE:[412,1093],SE:[408,1097],NW:[434,1115]},
{SW:[429,1119],NE:[411,1093],SE:[407,1097],NW:[433,1115]},
{SW:[429,1120],NE:[411,1094],SE:[407,1098],NW:[433,1116]},
{SW:[428,1120],NE:[410,1094],SE:[406,1098],NW:[432,1116]},
{SW:[428,1121],NE:[410,1095],SE:[406,1099],NW:[432,1117]},
{SW:[427,1121],NE:[409,1095],SE:[405,1099],NW:[431,1117]},
{SW:[427,1122],NE:[408,1096],SE:[405,1100],NW:[430,1118]},
{SW:[426,1122],NE:[408,1096],SE:[404,1100],NW:[430,1118]},
{SW:[425,1122],NE:[407,1097],SE:[403,1100],NW:[429,1119]},
{SW:[425,1123],NE:[406,1097],SE:[403,1101],NW:[428,1119]},
{SW:[424,1123],NE:[405,1098],SE:[402,1101],NW:[427,1120]},
{SW:[424,1124],NE:[405,1098],SE:[402,1102],NW:[427,1120]},
{SW:[423,1124],NE:[404,1099],SE:[401,1102],NW:[426,1121]},
{SW:[422,1124],NE:[403,1099],SE:[400,1102],NW:[425,1121]},
{SW:[422,1125],NE:[402,1100],SE:[400,1103],NW:[424,1122]},
{SW:[421,1125],NE:[402,1100],SE:[399,1103],NW:[424,1122]},
{SW:[420,1125],NE:[401,1101],SE:[398,1103],NW:[423,1123]},
{SW:[420,1126],NE:[400,1101],SE:[398,1104],NW:[422,1123]},
{SW:[419,1126],NE:[399,1102],SE:[397,1104],NW:[421,1124]},
{SW:[418,1126],NE:[398,1102],SE:[396,1104],NW:[420,1124]},
{SW:[417,1127],NE:[398,1103],SE:[395,1105],NW:[419,1125]},
{SW:[417,1127],NE:[397,1103],SE:[395,1105],NW:[419,1125]},
{SW:[416,1127],NE:[396,1103],SE:[394,1105],NW:[418,1125]},
{SW:[415,1127],NE:[395,1104],SE:[393,1105],NW:[417,1126]},
{SW:[414,1128],NE:[394,1104],SE:[392,1106],NW:[416,1126]},
{SW:[414,1128],NE:[393,1105],SE:[392,1106],NW:[415,1127]},
{SW:[413,1128],NE:[392,1105],SE:[391,1106],NW:[414,1127]},
{SW:[412,1129],NE:[391,1105],SE:[390,1107],NW:[413,1127]},
{SW:[411,1129],NE:[390,1106],SE:[389,1107],NW:[412,1128]},
{SW:[411,1129],NE:[389,1106],SE:[389,1107],NW:[411,1128]},
{SW:[410,1129],NE:[389,1107],SE:[388,1107],NW:[411,1129]},
{SW:[409,1130],NE:[388,1107],SE:[387,1108],NW:[410,1129]},
{SW:[408,1130],NE:[387,1107],SE:[386,1108],NW:[409,1129]},
{SW:[407,1130],NE:[386,1108],SE:[385,1108],NW:[408,1130]},
{SW:[407,1130],NE:[385,1108],SE:[385,1108],NW:[407,1130]},
{SW:[406,1131],NE:[384,1108],SE:[384,1109],NW:[406,1130]},
{SW:[405,1131],NE:[383,1109],SE:[383,1109],NW:[405,1131]},
{SW:[404,1131],NE:[382,1109],SE:[382,1109],NW:[404,1131]},
{SW:[403,1131],NE:[381,1109],SE:[381,1109],NW:[403,1131]},
{SW:[402,1131],NE:[380,1110],SE:[380,1109],NW:[402,1132]},
{SW:[402,1132],NE:[379,1110],SE:[380,1110],NW:[401,1132]},
{SW:[401,1132],NE:[378,1111],SE:[379,1110],NW:[400,1133]},
{SW:[400,1132],NE:[377,1111],SE:[378,1110],NW:[399,1133]},
{SW:[399,1132],NE:[376,1111],SE:[377,1110],NW:[398,1133]},
{SW:[398,1133],NE:[375,1112],SE:[376,1111],NW:[397,1134]},
{SW:[397,1133],NE:[374,1112],SE:[375,1111],NW:[396,1134]},
{SW:[397,1133],NE:[373,1112],SE:[375,1111],NW:[395,1134]},
{SW:[396,1133],NE:[372,1113],SE:[374,1111],NW:[394,1135]},
{SW:[395,1133],NE:[372,1113],SE:[373,1111],NW:[394,1135]},
{SW:[394,1134],NE:[371,1113],SE:[372,1112],NW:[393,1135]},
{SW:[393,1134],NE:[370,1114],SE:[371,1112],NW:[392,1136]},
{SW:[393,1134],NE:[369,1114],SE:[371,1112],NW:[391,1136]},
{SW:[392,1134],NE:[368,1114],SE:[370,1112],NW:[390,1136]},
{SW:[391,1135],NE:[367,1115],SE:[369,1113],NW:[389,1137]},
{SW:[390,1135],NE:[366,1115],SE:[368,1113],NW:[388,1137]},
{SW:[389,1135],NE:[365,1115],SE:[367,1113],NW:[387,1137]},
{SW:[389,1135],NE:[364,1116],SE:[367,1113],NW:[386,1138]},
{SW:[388,1136],NE:[363,1116],SE:[366,1114],NW:[385,1138]},
{SW:[387,1136],NE:[362,1117],SE:[365,1114],NW:[384,1139]},
{SW:[386,1136],NE:[361,1117],SE:[364,1114],NW:[383,1139]},
{SW:[385,1136],NE:[361,1117],SE:[363,1114],NW:[383,1139]},
{SW:[385,1137],NE:[360,1118],SE:[363,1115],NW:[382,1140]},
{SW:[384,1137],NE:[359,1118],SE:[362,1115],NW:[381,1140]},
{SW:[383,1137],NE:[358,1118],SE:[361,1115],NW:[380,1140]},
{SW:[382,1137],NE:[357,1119],SE:[360,1115],NW:[379,1141]},
{SW:[382,1138],NE:[356,1119],SE:[360,1116],NW:[378,1141]},
{SW:[381,1138],NE:[355,1120],SE:[359,1116],NW:[377,1142]},
{SW:[380,1138],NE:[355,1120],SE:[358,1116],NW:[377,1142]},
{SW:[380,1138],NE:[354,1120],SE:[358,1116],NW:[376,1142]},
{SW:[379,1139],NE:[353,1121],SE:[357,1117],NW:[375,1143]},
{SW:[378,1139],NE:[352,1121],SE:[356,1117],NW:[374,1143]},
{SW:[378,1139],NE:[351,1121],SE:[356,1117],NW:[373,1143]},
{SW:[377,1140],NE:[351,1122],SE:[355,1118],NW:[373,1144]},
{SW:[376,1140],NE:[350,1122],SE:[354,1118],NW:[372,1144]},
{SW:[376,1140],NE:[349,1123],SE:[354,1118],NW:[371,1145]},
{SW:[375,1140],NE:[348,1123],SE:[353,1118],NW:[370,1145]},
{SW:[374,1141],NE:[348,1124],SE:[352,1119],NW:[370,1146]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[373,1141],NE:[346,1124],SE:[351,1119],NW:[368,1146]},
{SW:[373,1142],NE:[346,1125],SE:[351,1120],NW:[368,1147]},
{SW:[372,1142],NE:[345,1125],SE:[350,1120],NW:[367,1147]},
{SW:[372,1142],NE:[344,1126],SE:[350,1120],NW:[366,1148]},
{SW:[371,1143],NE:[344,1126],SE:[349,1121],NW:[366,1148]},
{SW:[371,1143],NE:[343,1127],SE:[349,1121],NW:[365,1149]},
{SW:[370,1143],NE:[343,1127],SE:[348,1121],NW:[364,1149]},
{SW:[370,1144],NE:[342,1127],SE:[348,1122],NW:[364,1149]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[367,1145],NE:[339,1130],SE:[345,1123],NW:[361,1152]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[367,1146],NE:[338,1131],SE:[345,1124],NW:[360,1153]},
{SW:[366,1147],NE:[338,1131],SE:[344,1125],NW:[360,1153]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[365,1148],NE:[337,1132],SE:[343,1126],NW:[359,1154]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1149],NE:[336,1133],SE:[343,1127],NW:[358,1155]},
{SW:[365,1149],NE:[336,1134],SE:[343,1127],NW:[358,1156]},
{SW:[364,1149],NE:[335,1134],SE:[342,1127],NW:[357,1156]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1136],SE:[342,1128],NW:[357,1158]},
{SW:[364,1151],NE:[334,1136],SE:[342,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1137],SE:[341,1129],NW:[356,1159]},
{SW:[363,1152],NE:[334,1137],SE:[341,1130],NW:[356,1159]},
{SW:[363,1152],NE:[334,1137],SE:[341,1130],NW:[356,1159]},
{SW:[363,1152],NE:[334,1138],SE:[341,1130],NW:[356,1160]},
{SW:[363,1153],NE:[333,1138],SE:[341,1131],NW:[355,1160]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1156],NE:[333,1141],SE:[341,1134],NW:[355,1163]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1158],NE:[333,1144],SE:[341,1136],NW:[355,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1145],SE:[342,1136],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[365,1159],NE:[334,1145],SE:[343,1137],NW:[356,1167]},
{SW:[365,1159],NE:[335,1145],SE:[343,1137],NW:[357,1167]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[366,1160],NE:[335,1146],SE:[344,1138],NW:[357,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1168]},
{SW:[367,1160],NE:[337,1147],SE:[345,1138],NW:[359,1169]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1146],SE:[347,1139],NW:[361,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[341,1146],SE:[348,1139],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[342,1146],SE:[349,1138],NW:[364,1168]},
{SW:[372,1160],NE:[342,1146],SE:[350,1138],NW:[364,1168]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[373,1160],NE:[343,1145],SE:[351,1138],NW:[365,1167]},
{SW:[373,1159],NE:[344,1145],SE:[351,1137],NW:[366,1167]},
{SW:[373,1159],NE:[344,1144],SE:[351,1137],NW:[366,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1158],NE:[346,1143],SE:[352,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1157],NE:[347,1142],SE:[353,1136],NW:[369,1164]},
{SW:[376,1157],NE:[347,1142],SE:[354,1135],NW:[369,1164]},
{SW:[376,1157],NE:[348,1141],SE:[354,1135],NW:[370,1163]},
{SW:[376,1156],NE:[348,1141],SE:[354,1134],NW:[370,1163]},
{SW:[377,1156],NE:[348,1140],SE:[355,1134],NW:[370,1162]},
{SW:[377,1156],NE:[349,1140],SE:[355,1134],NW:[371,1162]},
{SW:[377,1155],NE:[349,1139],SE:[355,1133],NW:[371,1161]},
{SW:[378,1155],NE:[350,1139],SE:[356,1133],NW:[372,1161]},
{SW:[378,1154],NE:[350,1138],SE:[356,1132],NW:[372,1160]},
{SW:[378,1154],NE:[351,1138],SE:[356,1132],NW:[373,1160]},
{SW:[379,1153],NE:[351,1137],SE:[357,1131],NW:[373,1159]},
{SW:[379,1153],NE:[351,1136],SE:[357,1131],NW:[373,1158]},
{SW:[379,1152],NE:[352,1136],SE:[357,1130],NW:[374,1158]},
{SW:[380,1152],NE:[352,1135],SE:[358,1130],NW:[374,1157]},
{SW:[380,1151],NE:[352,1135],SE:[358,1129],NW:[374,1156]},
{SW:[380,1151],NE:[353,1134],SE:[358,1129],NW:[375,1156]},
{SW:[380,1150],NE:[353,1133],SE:[358,1128],NW:[375,1155]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1148],NE:[354,1131],SE:[359,1126],NW:[376,1153]},
{SW:[382,1148],NE:[355,1130],SE:[360,1126],NW:[377,1152]},
{SW:[382,1147],NE:[355,1130],SE:[360,1125],NW:[377,1151]},
{SW:[382,1146],NE:[355,1129],SE:[360,1124],NW:[377,1151]},
{SW:[382,1146],NE:[356,1128],SE:[360,1124],NW:[378,1150]},
{SW:[383,1145],NE:[356,1127],SE:[361,1123],NW:[378,1149]},
{SW:[383,1144],NE:[357,1126],SE:[361,1122],NW:[379,1148]},
{SW:[383,1143],NE:[357,1126],SE:[361,1121],NW:[379,1148]},
{SW:[383,1143],NE:[357,1125],SE:[361,1121],NW:[379,1147]},
{SW:[383,1142],NE:[358,1124],SE:[361,1120],NW:[380,1146]},
{SW:[384,1141],NE:[358,1123],SE:[362,1119],NW:[380,1145]},
{SW:[384,1141],NE:[358,1122],SE:[362,1119],NW:[380,1144]},
{SW:[384,1140],NE:[359,1121],SE:[362,1118],NW:[381,1143]},
{SW:[384,1139],NE:[359,1120],SE:[362,1117],NW:[381,1142]},
{SW:[384,1138],NE:[359,1119],SE:[362,1116],NW:[381,1141]},
{SW:[385,1137],NE:[359,1119],SE:[363,1115],NW:[381,1141]},
{SW:[385,1137],NE:[360,1118],SE:[363,1115],NW:[382,1140]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[385,1135],NE:[360,1116],SE:[363,1113],NW:[382,1138]},
{SW:[385,1134],NE:[361,1115],SE:[363,1112],NW:[383,1137]},
{SW:[386,1133],NE:[361,1114],SE:[364,1111],NW:[383,1136]},
{SW:[386,1133],NE:[361,1113],SE:[364,1111],NW:[383,1135]},
{SW:[386,1132],NE:[362,1112],SE:[364,1110],NW:[384,1134]},
{SW:[386,1131],NE:[362,1111],SE:[364,1109],NW:[384,1133]},
{SW:[386,1130],NE:[362,1110],SE:[364,1108],NW:[384,1132]},
{SW:[386,1129],NE:[362,1109],SE:[364,1107],NW:[384,1131]},
{SW:[387,1128],NE:[363,1108],SE:[365,1106],NW:[385,1130]},
{SW:[387,1127],NE:[363,1107],SE:[365,1105],NW:[385,1129]},
{SW:[387,1127],NE:[363,1106],SE:[365,1105],NW:[385,1128]},
{SW:[387,1126],NE:[364,1105],SE:[365,1104],NW:[386,1127]},
{SW:[387,1125],NE:[364,1104],SE:[365,1103],NW:[386,1126]},
{SW:[387,1124],NE:[364,1103],SE:[365,1102],NW:[386,1125]},
{SW:[388,1123],NE:[364,1102],SE:[366,1101],NW:[386,1124]},
{SW:[388,1122],NE:[365,1101],SE:[366,1100],NW:[387,1123]},
{SW:[388,1121],NE:[365,1100],SE:[366,1099],NW:[387,1122]},
{SW:[388,1120],NE:[365,1099],SE:[366,1098],NW:[387,1121]},
{SW:[388,1120],NE:[366,1098],SE:[366,1098],NW:[388,1120]},
{SW:[388,1119],NE:[366,1097],SE:[366,1097],NW:[388,1119]},
{SW:[389,1118],NE:[366,1096],SE:[367,1096],NW:[388,1118]},
{SW:[389,1117],NE:[366,1095],SE:[367,1095],NW:[388,1117]},
{SW:[389,1116],NE:[367,1094],SE:[367,1094],NW:[389,1116]},
{SW:[389,1115],NE:[367,1093],SE:[367,1093],NW:[389,1115]},
{SW:[389,1114],NE:[367,1092],SE:[367,1092],NW:[389,1114]},
{SW:[389,1114],NE:[368,1091],SE:[367,1092],NW:[390,1113]},
{SW:[390,1113],NE:[368,1090],SE:[368,1091],NW:[390,1112]},
{SW:[390,1112],NE:[368,1089],SE:[368,1090],NW:[390,1111]},
{SW:[390,1111],NE:[369,1088],SE:[368,1089],NW:[391,1110]},
{SW:[390,1110],NE:[369,1087],SE:[368,1088],NW:[391,1109]},
{SW:[390,1109],NE:[369,1086],SE:[368,1087],NW:[391,1108]},
{SW:[391,1108],NE:[369,1085],SE:[369,1086],NW:[391,1107]},
{SW:[391,1108],NE:[370,1084],SE:[369,1086],NW:[392,1106]},
{SW:[391,1107],NE:[370,1083],SE:[369,1085],NW:[392,1105]},
{SW:[391,1106],NE:[370,1083],SE:[369,1084],NW:[392,1105]},
{SW:[391,1105],NE:[371,1082],SE:[369,1083],NW:[393,1104]},
{SW:[392,1104],NE:[371,1081],SE:[370,1082],NW:[393,1103]},
{SW:[392,1103],NE:[371,1080],SE:[370,1081],NW:[393,1102]},
{SW:[392,1103],NE:[372,1079],SE:[370,1081],NW:[394,1101]},
{SW:[392,1102],NE:[372,1078],SE:[370,1080],NW:[394,1100]},
{SW:[393,1101],NE:[373,1077],SE:[371,1079],NW:[395,1099]},
{SW:[393,1100],NE:[373,1076],SE:[371,1078],NW:[395,1098]},
{SW:[393,1100],NE:[373,1075],SE:[371,1078],NW:[395,1097]},
{SW:[393,1099],NE:[374,1074],SE:[371,1077],NW:[396,1096]},
{SW:[394,1098],NE:[374,1074],SE:[372,1076],NW:[396,1096]},
{SW:[394,1097],NE:[374,1073],SE:[372,1075],NW:[396,1095]},
{SW:[394,1097],NE:[375,1072],SE:[372,1075],NW:[397,1094]},
{SW:[394,1096],NE:[375,1071],SE:[372,1074],NW:[397,1093]},
{SW:[395,1095],NE:[376,1070],SE:[373,1073],NW:[398,1092]},
{SW:[395,1095],NE:[376,1069],SE:[373,1073],NW:[398,1091]},
{SW:[395,1094],NE:[377,1069],SE:[373,1072],NW:[399,1091]},
{SW:[396,1093],NE:[377,1068],SE:[374,1071],NW:[399,1090]},
{SW:[396,1093],NE:[377,1067],SE:[374,1071],NW:[399,1089]},
{SW:[396,1092],NE:[378,1066],SE:[374,1070],NW:[400,1088]},
{SW:[397,1091],NE:[378,1066],SE:[375,1069],NW:[400,1088]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[397,1090],NE:[379,1064],SE:[375,1068],NW:[401,1086]},
{SW:[398,1090],NE:[380,1064],SE:[376,1068],NW:[402,1086]},
{SW:[398,1089],NE:[380,1063],SE:[376,1067],NW:[402,1085]},
{SW:[399,1089],NE:[381,1062],SE:[377,1067],NW:[403,1084]},
{SW:[399,1088],NE:[381,1062],SE:[377,1066],NW:[403,1084]},
{SW:[399,1088],NE:[382,1061],SE:[377,1066],NW:[404,1083]},
{SW:[400,1087],NE:[382,1060],SE:[378,1065],NW:[404,1082]},
{SW:[400,1087],NE:[383,1060],SE:[378,1065],NW:[405,1082]},
{SW:[401,1086],NE:[383,1059],SE:[379,1064],NW:[406,1081]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[403,1085],NE:[386,1057],SE:[380,1063],NW:[408,1079]},
{SW:[403,1084],NE:[386,1057],SE:[381,1062],NW:[408,1079]},
{SW:[403,1084],NE:[387,1056],SE:[381,1062],NW:[409,1078]},
{SW:[404,1084],NE:[388,1056],SE:[382,1062],NW:[410,1078]},
{SW:[404,1083],NE:[388,1056],SE:[382,1061],NW:[410,1078]},
{SW:[405,1083],NE:[389,1055],SE:[383,1061],NW:[411,1077]},
{SW:[406,1083],NE:[389,1055],SE:[384,1061],NW:[411,1077]},
{SW:[406,1083],NE:[390,1055],SE:[384,1061],NW:[412,1077]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[408,1082],NE:[392,1054],SE:[386,1060],NW:[414,1076]},
{SW:[408,1082],NE:[393,1054],SE:[386,1060],NW:[415,1076]},
{SW:[409,1082],NE:[393,1053],SE:[387,1060],NW:[415,1075]},
{SW:[409,1082],NE:[394,1053],SE:[387,1060],NW:[416,1075]},
{SW:[410,1082],NE:[395,1053],SE:[388,1060],NW:[417,1075]},
{SW:[411,1082],NE:[395,1053],SE:[389,1060],NW:[417,1075]},
{SW:[411,1082],NE:[396,1053],SE:[389,1060],NW:[418,1075]},
{SW:[412,1082],NE:[397,1053],SE:[390,1060],NW:[419,1075]},
{SW:[412,1082],NE:[397,1053],SE:[390,1060],NW:[419,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[415,1082],NE:[400,1053],SE:[393,1060],NW:[422,1075]},
{SW:[415,1082],NE:[401,1053],SE:[393,1060],NW:[423,1075]},
{SW:[416,1082],NE:[401,1053],SE:[394,1060],NW:[423,1075]},
{SW:[417,1082],NE:[402,1053],SE:[395,1060],NW:[424,1075]},
{SW:[417,1082],NE:[403,1053],SE:[395,1060],NW:[425,1075]},
{SW:[418,1083],NE:[403,1053],SE:[396,1061],NW:[425,1075]},
{SW:[418,1083],NE:[404,1053],SE:[396,1061],NW:[426,1075]},
{SW:[419,1083],NE:[405,1053],SE:[397,1061],NW:[427,1075]},
{SW:[420,1083],NE:[405,1054],SE:[398,1061],NW:[427,1076]},
{SW:[420,1084],NE:[406,1054],SE:[398,1062],NW:[428,1076]},
{SW:[421,1084],NE:[406,1054],SE:[399,1062],NW:[428,1076]},
{SW:[421,1084],NE:[407,1054],SE:[399,1062],NW:[429,1076]},
{SW:[422,1085],NE:[408,1055],SE:[400,1063],NW:[430,1077]},
{SW:[423,1085],NE:[408,1055],SE:[401,1063],NW:[430,1077]},
{SW:[423,1085],NE:[409,1055],SE:[401,1063],NW:[431,1077]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[425,1086],NE:[411,1057],SE:[403,1064],NW:[433,1079]},
{SW:[425,1087],NE:[411,1057],SE:[403,1065],NW:[433,1079]},
{SW:[426,1087],NE:[412,1057],SE:[404,1065],NW:[434,1079]},
{SW:[426,1088],NE:[412,1058],SE:[405,1066],NW:[434,1080]},
{SW:[427,1088],NE:[413,1058],SE:[405,1066],NW:[435,1080]},
{SW:[428,1089],NE:[413,1059],SE:[406,1067],NW:[435,1081]},
{SW:[428,1089],NE:[414,1059],SE:[406,1067],NW:[436,1081]},
{SW:[428,1090],NE:[414,1060],SE:[407,1068],NW:[436,1082]},
{SW:[429,1090],NE:[415,1060],SE:[407,1068],NW:[437,1082]},
{SW:[429,1091],NE:[415,1061],SE:[407,1069],NW:[437,1083]},
{SW:[430,1091],NE:[416,1062],SE:[408,1069],NW:[438,1084]},
{SW:[430,1092],NE:[416,1062],SE:[408,1070],NW:[438,1084]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[431,1094],NE:[417,1064],SE:[410,1072],NW:[439,1086]},
{SW:[432,1094],NE:[418,1065],SE:[410,1072],NW:[440,1087]},
{SW:[432,1095],NE:[418,1065],SE:[410,1073],NW:[440,1087]},
{SW:[433,1095],NE:[418,1066],SE:[411,1073],NW:[440,1088]},
{SW:[433,1096],NE:[419,1066],SE:[411,1074],NW:[441,1088]},
{SW:[433,1097],NE:[419,1067],SE:[411,1075],NW:[441,1089]},
{SW:[433,1097],NE:[419,1068],SE:[411,1075],NW:[441,1090]},
{SW:[434,1098],NE:[419,1068],SE:[412,1076],NW:[441,1090]},
{SW:[434,1099],NE:[419,1069],SE:[412,1077],NW:[441,1091]},
{SW:[434,1099],NE:[420,1070],SE:[412,1077],NW:[442,1092]},
{SW:[434,1100],NE:[420,1071],SE:[412,1078],NW:[442,1093]},
{SW:[435,1101],NE:[420,1071],SE:[413,1079],NW:[442,1093]},
{SW:[435,1101],NE:[420,1072],SE:[413,1079],NW:[442,1094]},
{SW:[435,1102],NE:[420,1073],SE:[413,1080],NW:[442,1095]},
{SW:[435,1102],NE:[420,1073],SE:[413,1080],NW:[442,1095]},
{SW:[435,1103],NE:[420,1074],SE:[413,1081],NW:[442,1096]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1105],NE:[420,1076],SE:[413,1083],NW:[442,1098]},
{SW:[435,1106],NE:[420,1077],SE:[413,1084],NW:[442,1099]},
{SW:[435,1106],NE:[420,1078],SE:[413,1084],NW:[442,1100]},
{SW:[435,1107],NE:[420,1078],SE:[413,1085],NW:[442,1100]},
{SW:[435,1107],NE:[420,1079],SE:[413,1085],NW:[442,1101]},
{SW:[435,1108],NE:[420,1080],SE:[413,1086],NW:[442,1102]},
{SW:[435,1109],NE:[420,1080],SE:[413,1087],NW:[442,1102]},
{SW:[435,1109],NE:[419,1081],SE:[413,1087],NW:[441,1103]},
{SW:[435,1110],NE:[419,1082],SE:[413,1088],NW:[441,1104]},
{SW:[435,1110],NE:[419,1082],SE:[413,1088],NW:[441,1104]},
{SW:[435,1111],NE:[419,1083],SE:[413,1089],NW:[441,1105]},
{SW:[434,1112],NE:[418,1084],SE:[412,1090],NW:[440,1106]},
{SW:[434,1112],NE:[418,1084],SE:[412,1090],NW:[440,1106]},
{SW:[434,1113],NE:[418,1085],SE:[412,1091],NW:[440,1107]},
{SW:[434,1113],NE:[417,1086],SE:[412,1091],NW:[439,1108]},
{SW:[433,1114],NE:[417,1086],SE:[411,1092],NW:[439,1108]},
{SW:[433,1114],NE:[417,1087],SE:[411,1092],NW:[439,1109]},
{SW:[433,1115],NE:[416,1088],SE:[411,1093],NW:[438,1110]},
{SW:[433,1115],NE:[416,1088],SE:[411,1093],NW:[438,1110]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[431,1117],NE:[414,1090],SE:[409,1095],NW:[436,1112]},
{SW:[431,1117],NE:[414,1091],SE:[409,1095],NW:[436,1113]},
{SW:[431,1118],NE:[413,1091],SE:[409,1096],NW:[435,1113]},
{SW:[430,1118],NE:[413,1092],SE:[408,1096],NW:[435,1114]},
{SW:[430,1119],NE:[412,1092],SE:[408,1097],NW:[434,1114]},
{SW:[429,1119],NE:[412,1093],SE:[407,1097],NW:[434,1115]},
{SW:[429,1120],NE:[411,1094],SE:[407,1098],NW:[433,1116]},
{SW:[428,1120],NE:[410,1094],SE:[406,1098],NW:[432,1116]},
{SW:[428,1121],NE:[410,1095],SE:[406,1099],NW:[432,1117]},
{SW:[427,1121],NE:[409,1095],SE:[405,1099],NW:[431,1117]},
{SW:[427,1122],NE:[408,1096],SE:[405,1099],NW:[430,1118]},
{SW:[426,1122],NE:[408,1096],SE:[404,1100],NW:[430,1118]},
{SW:[426,1122],NE:[407,1097],SE:[404,1100],NW:[429,1119]},
{SW:[425,1123],NE:[406,1097],SE:[403,1101],NW:[428,1119]},
{SW:[424,1123],NE:[406,1098],SE:[402,1101],NW:[428,1120]},
{SW:[424,1123],NE:[405,1098],SE:[402,1101],NW:[427,1120]},
{SW:[423,1124],NE:[404,1099],SE:[401,1102],NW:[426,1121]},
{SW:[422,1124],NE:[403,1099],SE:[400,1102],NW:[425,1121]},
{SW:[422,1125],NE:[403,1100],SE:[400,1103],NW:[425,1122]},
{SW:[421,1125],NE:[402,1100],SE:[399,1103],NW:[424,1122]},
{SW:[420,1125],NE:[401,1101],SE:[398,1103],NW:[423,1123]},
{SW:[420,1126],NE:[400,1101],SE:[398,1104],NW:[422,1123]},
{SW:[419,1126],NE:[399,1102],SE:[397,1104],NW:[421,1124]},
{SW:[418,1126],NE:[399,1102],SE:[396,1104],NW:[421,1124]},
{SW:[418,1126],NE:[398,1102],SE:[396,1104],NW:[420,1124]},
{SW:[417,1127],NE:[397,1103],SE:[395,1105],NW:[419,1125]},
{SW:[416,1127],NE:[396,1103],SE:[394,1105],NW:[418,1125]},
{SW:[415,1127],NE:[395,1104],SE:[393,1105],NW:[417,1126]},
{SW:[415,1128],NE:[394,1104],SE:[393,1106],NW:[416,1126]},
{SW:[414,1128],NE:[393,1104],SE:[392,1106],NW:[415,1127]},
{SW:[413,1128],NE:[392,1105],SE:[391,1106],NW:[414,1127]},
{SW:[412,1128],NE:[392,1105],SE:[390,1106],NW:[414,1127]},
{SW:[412,1129],NE:[391,1106],SE:[390,1107],NW:[413,1128]},
{SW:[411,1129],NE:[390,1106],SE:[389,1107],NW:[412,1128]},
{SW:[410,1129],NE:[389,1106],SE:[388,1107],NW:[411,1128]},
{SW:[409,1130],NE:[388,1107],SE:[387,1107],NW:[410,1129]},
{SW:[408,1130],NE:[387,1107],SE:[386,1108],NW:[409,1129]},
{SW:[408,1130],NE:[386,1108],SE:[386,1108],NW:[408,1130]},
{SW:[407,1130],NE:[385,1108],SE:[385,1108],NW:[407,1130]},
{SW:[406,1130],NE:[384,1108],SE:[384,1108],NW:[406,1130]},
{SW:[405,1131],NE:[383,1109],SE:[383,1109],NW:[405,1131]},
{SW:[404,1131],NE:[382,1109],SE:[382,1109],NW:[404,1131]},
{SW:[403,1131],NE:[381,1109],SE:[381,1109],NW:[403,1131]},
{SW:[403,1131],NE:[380,1110],SE:[381,1109],NW:[402,1132]},
{SW:[402,1132],NE:[379,1110],SE:[380,1110],NW:[401,1132]},
{SW:[401,1132],NE:[378,1110],SE:[379,1110],NW:[400,1132]},
{SW:[400,1132],NE:[377,1111],SE:[378,1110],NW:[399,1133]},
{SW:[399,1132],NE:[377,1111],SE:[377,1110],NW:[399,1133]},
{SW:[398,1133],NE:[376,1112],SE:[376,1111],NW:[398,1134]},
{SW:[398,1133],NE:[375,1112],SE:[376,1111],NW:[397,1134]},
{SW:[397,1133],NE:[374,1112],SE:[375,1111],NW:[396,1134]},
{SW:[396,1133],NE:[373,1113],SE:[374,1111],NW:[395,1135]},
{SW:[395,1133],NE:[372,1113],SE:[373,1111],NW:[394,1135]},
{SW:[394,1134],NE:[371,1113],SE:[372,1112],NW:[393,1135]},
{SW:[394,1134],NE:[370,1114],SE:[372,1112],NW:[392,1136]},
{SW:[393,1134],NE:[369,1114],SE:[371,1112],NW:[391,1136]},
{SW:[392,1134],NE:[368,1114],SE:[370,1112],NW:[390,1136]},
{SW:[391,1135],NE:[367,1115],SE:[369,1113],NW:[389,1137]},
{SW:[390,1135],NE:[366,1115],SE:[368,1113],NW:[388,1137]},
{SW:[390,1135],NE:[365,1115],SE:[368,1113],NW:[387,1137]},
{SW:[389,1135],NE:[364,1116],SE:[367,1113],NW:[386,1138]},
{SW:[388,1136],NE:[363,1116],SE:[366,1113],NW:[385,1138]},
{SW:[387,1136],NE:[363,1116],SE:[365,1114],NW:[385,1138]},
{SW:[386,1136],NE:[362,1117],SE:[364,1114],NW:[384,1139]},
{SW:[386,1136],NE:[361,1117],SE:[364,1114],NW:[383,1139]},
{SW:[385,1136],NE:[360,1118],SE:[363,1114],NW:[382,1140]},
{SW:[384,1137],NE:[359,1118],SE:[362,1115],NW:[381,1140]},
{SW:[383,1137],NE:[358,1118],SE:[361,1115],NW:[380,1140]},
{SW:[383,1137],NE:[357,1119],SE:[361,1115],NW:[379,1141]},
{SW:[382,1138],NE:[356,1119],SE:[360,1116],NW:[378,1141]},
{SW:[381,1138],NE:[356,1119],SE:[359,1116],NW:[378,1141]},
{SW:[380,1138],NE:[355,1120],SE:[358,1116],NW:[377,1142]},
{SW:[380,1138],NE:[354,1120],SE:[358,1116],NW:[376,1142]},
{SW:[379,1139],NE:[353,1121],SE:[357,1117],NW:[375,1143]},
{SW:[378,1139],NE:[352,1121],SE:[356,1117],NW:[374,1143]},
{SW:[378,1139],NE:[352,1121],SE:[356,1117],NW:[374,1143]},
{SW:[377,1139],NE:[351,1122],SE:[355,1117],NW:[373,1144]},
{SW:[376,1140],NE:[350,1122],SE:[354,1118],NW:[372,1144]},
{SW:[376,1140],NE:[349,1123],SE:[354,1118],NW:[371,1145]},
{SW:[375,1140],NE:[349,1123],SE:[353,1118],NW:[371,1145]},
{SW:[375,1141],NE:[348,1123],SE:[353,1119],NW:[370,1145]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[373,1141],NE:[346,1124],SE:[351,1119],NW:[368,1146]},
{SW:[373,1142],NE:[346,1125],SE:[351,1120],NW:[368,1147]},
{SW:[372,1142],NE:[345,1125],SE:[350,1120],NW:[367,1147]},
{SW:[372,1142],NE:[344,1126],SE:[350,1120],NW:[366,1148]},
{SW:[371,1143],NE:[344,1126],SE:[349,1121],NW:[366,1148]},
{SW:[371,1143],NE:[343,1126],SE:[349,1121],NW:[365,1148]},
{SW:[370,1143],NE:[343,1127],SE:[348,1121],NW:[365,1149]},
{SW:[370,1144],NE:[342,1127],SE:[348,1122],NW:[364,1149]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[368,1145],NE:[339,1130],SE:[346,1123],NW:[361,1152]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[367,1146],NE:[338,1131],SE:[345,1124],NW:[360,1153]},
{SW:[366,1147],NE:[338,1131],SE:[344,1125],NW:[360,1153]},
{SW:[366,1147],NE:[338,1131],SE:[344,1125],NW:[360,1153]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[365,1148],NE:[337,1132],SE:[343,1126],NW:[359,1154]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1149],NE:[336,1134],SE:[343,1127],NW:[358,1156]},
{SW:[364,1149],NE:[335,1134],SE:[342,1127],NW:[357,1156]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1136],SE:[342,1128],NW:[357,1158]},
{SW:[364,1151],NE:[334,1136],SE:[342,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1136],SE:[341,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1137],SE:[341,1129],NW:[356,1159]},
{SW:[363,1152],NE:[334,1137],SE:[341,1130],NW:[356,1159]},
{SW:[363,1152],NE:[334,1138],SE:[341,1130],NW:[356,1160]},
{SW:[363,1153],NE:[333,1138],SE:[341,1131],NW:[355,1160]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1154],NE:[333,1139],SE:[341,1132],NW:[355,1161]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1158],NE:[333,1144],SE:[341,1136],NW:[355,1166]},
{SW:[364,1158],NE:[333,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1145],SE:[342,1136],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[365,1159],NE:[334,1145],SE:[343,1137],NW:[356,1167]},
{SW:[365,1159],NE:[335,1145],SE:[343,1137],NW:[357,1167]},
{SW:[365,1159],NE:[335,1146],SE:[343,1137],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1168]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[339,1147],SE:[346,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1146],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[340,1146],SE:[347,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[341,1146],SE:[348,1139],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[342,1146],SE:[349,1138],NW:[364,1168]},
{SW:[372,1160],NE:[342,1146],SE:[350,1138],NW:[364,1168]},
{SW:[372,1160],NE:[342,1145],SE:[350,1138],NW:[364,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[373,1160],NE:[343,1145],SE:[351,1138],NW:[365,1167]},
{SW:[373,1159],NE:[344,1145],SE:[351,1137],NW:[366,1167]},
{SW:[373,1159],NE:[344,1144],SE:[351,1137],NW:[366,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1158],NE:[345,1143],SE:[352,1136],NW:[367,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[347,1142],SE:[353,1136],NW:[369,1164]},
{SW:[376,1157],NE:[347,1142],SE:[354,1135],NW:[369,1164]},
{SW:[376,1157],NE:[348,1141],SE:[354,1135],NW:[369,1163]},
{SW:[376,1156],NE:[348,1141],SE:[354,1134],NW:[370,1163]},
{SW:[377,1156],NE:[348,1140],SE:[355,1134],NW:[370,1162]},
{SW:[377,1156],NE:[349,1140],SE:[355,1134],NW:[371,1162]},
{SW:[377,1155],NE:[349,1139],SE:[355,1133],NW:[371,1161]},
{SW:[378,1155],NE:[350,1139],SE:[356,1133],NW:[372,1161]},
{SW:[378,1154],NE:[350,1138],SE:[356,1132],NW:[372,1160]},
{SW:[378,1154],NE:[350,1138],SE:[356,1132],NW:[372,1160]},
{SW:[379,1153],NE:[351,1137],SE:[357,1131],NW:[373,1159]},
{SW:[379,1153],NE:[351,1137],SE:[357,1131],NW:[373,1159]},
{SW:[379,1152],NE:[352,1136],SE:[357,1130],NW:[374,1158]},
{SW:[380,1152],NE:[352,1135],SE:[358,1130],NW:[374,1157]},
{SW:[380,1151],NE:[352,1135],SE:[358,1129],NW:[374,1157]},
{SW:[380,1151],NE:[353,1134],SE:[358,1129],NW:[375,1156]},
{SW:[380,1150],NE:[353,1133],SE:[358,1128],NW:[375,1155]},
{SW:[381,1150],NE:[354,1133],SE:[359,1128],NW:[376,1155]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1148],NE:[354,1131],SE:[359,1126],NW:[376,1153]},
{SW:[381,1148],NE:[355,1130],SE:[359,1126],NW:[377,1152]},
{SW:[382,1147],NE:[355,1130],SE:[360,1125],NW:[377,1152]},
{SW:[382,1146],NE:[355,1129],SE:[360,1124],NW:[377,1151]},
{SW:[382,1146],NE:[356,1128],SE:[360,1124],NW:[378,1150]},
{SW:[382,1145],NE:[356,1127],SE:[360,1123],NW:[378,1149]},
{SW:[383,1144],NE:[356,1127],SE:[361,1122],NW:[378,1149]},
{SW:[383,1144],NE:[357,1126],SE:[361,1122],NW:[379,1148]},
{SW:[383,1143],NE:[357,1125],SE:[361,1121],NW:[379,1147]},
{SW:[383,1142],NE:[357,1124],SE:[361,1120],NW:[379,1146]},
{SW:[384,1141],NE:[358,1123],SE:[362,1119],NW:[380,1145]},
{SW:[384,1141],NE:[358,1122],SE:[362,1119],NW:[380,1144]},
{SW:[384,1140],NE:[358,1121],SE:[362,1118],NW:[380,1143]},
{SW:[384,1139],NE:[359,1121],SE:[362,1117],NW:[381,1143]},
{SW:[384,1138],NE:[359,1120],SE:[362,1116],NW:[381,1142]},
{SW:[385,1138],NE:[359,1119],SE:[363,1116],NW:[381,1141]},
{SW:[385,1137],NE:[360,1118],SE:[363,1115],NW:[382,1140]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[385,1135],NE:[360,1116],SE:[363,1113],NW:[382,1138]},
{SW:[385,1134],NE:[361,1115],SE:[363,1112],NW:[383,1137]},
{SW:[386,1134],NE:[361,1114],SE:[364,1112],NW:[383,1136]},
{SW:[386,1133],NE:[361,1113],SE:[364,1111],NW:[383,1135]},
{SW:[386,1132],NE:[362,1112],SE:[364,1110],NW:[384,1134]},
{SW:[386,1131],NE:[362,1111],SE:[364,1109],NW:[384,1133]},
{SW:[386,1130],NE:[362,1110],SE:[364,1108],NW:[384,1132]},
{SW:[386,1129],NE:[362,1109],SE:[364,1107],NW:[384,1131]},
{SW:[387,1129],NE:[363,1108],SE:[365,1107],NW:[385,1130]},
{SW:[387,1128],NE:[363,1107],SE:[365,1106],NW:[385,1129]},
{SW:[387,1127],NE:[363,1106],SE:[365,1105],NW:[385,1128]},
{SW:[387,1126],NE:[364,1105],SE:[365,1104],NW:[386,1127]},
{SW:[387,1125],NE:[364,1104],SE:[365,1103],NW:[386,1126]},
{SW:[387,1124],NE:[364,1103],SE:[365,1102],NW:[386,1125]},
{SW:[388,1123],NE:[364,1102],SE:[366,1101],NW:[386,1124]},
{SW:[388,1122],NE:[365,1101],SE:[366,1100],NW:[387,1123]},
{SW:[388,1122],NE:[365,1100],SE:[366,1100],NW:[387,1122]},
{SW:[388,1121],NE:[365,1099],SE:[366,1099],NW:[387,1121]},
{SW:[388,1120],NE:[366,1098],SE:[366,1098],NW:[388,1120]},
{SW:[388,1119],NE:[366,1097],SE:[366,1097],NW:[388,1119]},
{SW:[389,1118],NE:[366,1096],SE:[367,1096],NW:[388,1118]},
{SW:[389,1117],NE:[366,1095],SE:[367,1095],NW:[388,1117]},
{SW:[389,1116],NE:[367,1094],SE:[367,1094],NW:[389,1116]},
{SW:[389,1115],NE:[367,1093],SE:[367,1093],NW:[389,1115]},
{SW:[389,1115],NE:[367,1092],SE:[367,1093],NW:[389,1114]},
{SW:[389,1114],NE:[368,1091],SE:[367,1092],NW:[390,1113]},
{SW:[390,1113],NE:[368,1090],SE:[368,1091],NW:[390,1112]},
{SW:[390,1112],NE:[368,1090],SE:[368,1090],NW:[390,1111]},
{SW:[390,1111],NE:[368,1089],SE:[368,1089],NW:[390,1111]},
{SW:[390,1110],NE:[369,1088],SE:[368,1088],NW:[391,1110]},
{SW:[390,1109],NE:[369,1087],SE:[368,1087],NW:[391,1109]},
{SW:[390,1109],NE:[369,1086],SE:[368,1087],NW:[391,1108]},
{SW:[391,1108],NE:[370,1085],SE:[369,1086],NW:[392,1107]},
{SW:[391,1107],NE:[370,1084],SE:[369,1085],NW:[392,1106]},
{SW:[391,1106],NE:[370,1083],SE:[369,1084],NW:[392,1105]},
{SW:[391,1105],NE:[371,1082],SE:[369,1083],NW:[393,1104]},
{SW:[392,1104],NE:[371,1081],SE:[370,1082],NW:[393,1103]},
{SW:[392,1104],NE:[371,1080],SE:[370,1082],NW:[393,1102]},
{SW:[392,1103],NE:[372,1079],SE:[370,1081],NW:[394,1101]},
{SW:[392,1102],NE:[372,1078],SE:[370,1080],NW:[394,1100]},
{SW:[392,1101],NE:[372,1077],SE:[370,1079],NW:[394,1099]},
{SW:[393,1101],NE:[373,1076],SE:[371,1079],NW:[395,1098]},
{SW:[393,1100],NE:[373,1075],SE:[371,1078],NW:[395,1097]},
{SW:[393,1099],NE:[374,1075],SE:[371,1077],NW:[396,1097]},
{SW:[393,1098],NE:[374,1074],SE:[371,1076],NW:[396,1096]},
{SW:[394,1098],NE:[374,1073],SE:[372,1076],NW:[396,1095]},
{SW:[394,1097],NE:[375,1072],SE:[372,1075],NW:[397,1094]},
{SW:[394,1096],NE:[375,1071],SE:[372,1074],NW:[397,1093]},
{SW:[395,1095],NE:[376,1070],SE:[373,1073],NW:[398,1092]},
{SW:[395,1095],NE:[376,1070],SE:[373,1073],NW:[398,1092]},
{SW:[395,1094],NE:[376,1069],SE:[373,1072],NW:[398,1091]},
{SW:[396,1093],NE:[377,1068],SE:[374,1071],NW:[399,1090]},
{SW:[396,1093],NE:[377,1067],SE:[374,1071],NW:[399,1089]},
{SW:[396,1092],NE:[378,1067],SE:[374,1070],NW:[400,1089]},
{SW:[397,1092],NE:[378,1066],SE:[375,1070],NW:[400,1088]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[397,1090],NE:[379,1064],SE:[375,1068],NW:[401,1086]},
{SW:[398,1090],NE:[380,1064],SE:[376,1068],NW:[402,1086]},
{SW:[398,1089],NE:[380,1063],SE:[376,1067],NW:[402,1085]},
{SW:[398,1089],NE:[381,1062],SE:[376,1067],NW:[403,1084]},
{SW:[399,1088],NE:[381,1062],SE:[377,1066],NW:[403,1084]},
{SW:[399,1088],NE:[382,1061],SE:[377,1066],NW:[404,1083]},
{SW:[400,1087],NE:[382,1061],SE:[378,1065],NW:[404,1083]},
{SW:[400,1087],NE:[383,1060],SE:[378,1065],NW:[405,1082]},
{SW:[401,1086],NE:[383,1059],SE:[379,1064],NW:[405,1081]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[401,1086],NE:[384,1058],SE:[379,1064],NW:[406,1080]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[402,1085],NE:[386,1057],SE:[380,1063],NW:[408,1079]},
{SW:[403,1084],NE:[386,1057],SE:[381,1062],NW:[408,1079]},
{SW:[403,1084],NE:[387,1057],SE:[381,1062],NW:[409,1079]},
{SW:[404,1084],NE:[387,1056],SE:[382,1062],NW:[409,1078]},
{SW:[404,1083],NE:[388,1056],SE:[382,1061],NW:[410,1078]},
{SW:[405,1083],NE:[389,1055],SE:[383,1061],NW:[411,1077]},
{SW:[405,1083],NE:[389,1055],SE:[383,1061],NW:[411,1077]},
{SW:[406,1083],NE:[390,1055],SE:[384,1061],NW:[412,1077]},
{SW:[406,1082],NE:[390,1054],SE:[384,1060],NW:[413,1076]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[408,1082],NE:[392,1054],SE:[386,1060],NW:[414,1076]},
{SW:[408,1082],NE:[392,1054],SE:[386,1060],NW:[414,1076]},
{SW:[409,1082],NE:[393,1053],SE:[387,1060],NW:[415,1075]},
{SW:[409,1082],NE:[394,1053],SE:[387,1060],NW:[416,1075]},
{SW:[410,1082],NE:[394,1053],SE:[388,1060],NW:[416,1075]},
{SW:[410,1082],NE:[395,1053],SE:[388,1060],NW:[417,1075]},
{SW:[411,1082],NE:[396,1053],SE:[389,1060],NW:[418,1075]},
{SW:[412,1082],NE:[396,1053],SE:[390,1060],NW:[418,1075]},
{SW:[412,1082],NE:[397,1053],SE:[390,1060],NW:[419,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[415,1082],NE:[400,1053],SE:[393,1060],NW:[422,1075]},
{SW:[415,1082],NE:[400,1053],SE:[393,1060],NW:[422,1075]},
{SW:[416,1082],NE:[401,1053],SE:[394,1060],NW:[423,1075]},
{SW:[416,1082],NE:[402,1053],SE:[394,1060],NW:[424,1075]},
{SW:[417,1082],NE:[402,1053],SE:[395,1060],NW:[424,1075]},
{SW:[418,1083],NE:[403,1053],SE:[396,1061],NW:[425,1075]},
{SW:[418,1083],NE:[404,1053],SE:[396,1061],NW:[426,1075]},
{SW:[419,1083],NE:[404,1053],SE:[397,1061],NW:[426,1075]},
{SW:[419,1083],NE:[405,1054],SE:[397,1061],NW:[427,1076]},
{SW:[420,1084],NE:[406,1054],SE:[398,1062],NW:[428,1076]},
{SW:[421,1084],NE:[406,1054],SE:[399,1062],NW:[428,1076]},
{SW:[421,1084],NE:[407,1054],SE:[399,1062],NW:[429,1076]},
{SW:[422,1084],NE:[408,1055],SE:[400,1062],NW:[430,1077]},
{SW:[422,1085],NE:[408,1055],SE:[400,1063],NW:[430,1077]},
{SW:[423,1085],NE:[409,1055],SE:[401,1063],NW:[431,1077]},
{SW:[424,1086],NE:[409,1056],SE:[402,1064],NW:[431,1078]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[425,1086],NE:[411,1056],SE:[403,1064],NW:[433,1078]},
{SW:[425,1087],NE:[411,1057],SE:[403,1065],NW:[433,1079]},
{SW:[426,1087],NE:[412,1057],SE:[404,1065],NW:[434,1079]},
{SW:[426,1088],NE:[412,1058],SE:[404,1066],NW:[434,1080]},
{SW:[427,1088],NE:[413,1058],SE:[405,1066],NW:[435,1080]},
{SW:[427,1089],NE:[413,1059],SE:[405,1067],NW:[435,1081]},
{SW:[428,1089],NE:[414,1059],SE:[406,1067],NW:[436,1081]},
{SW:[428,1090],NE:[414,1060],SE:[406,1068],NW:[436,1082]},
{SW:[429,1090],NE:[415,1060],SE:[407,1068],NW:[437,1082]},
{SW:[429,1091],NE:[415,1061],SE:[407,1069],NW:[437,1083]},
{SW:[430,1091],NE:[416,1061],SE:[408,1069],NW:[438,1083]},
{SW:[430,1092],NE:[416,1062],SE:[408,1070],NW:[438,1084]},
{SW:[431,1092],NE:[416,1063],SE:[409,1070],NW:[438,1085]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[431,1094],NE:[417,1064],SE:[409,1072],NW:[439,1086]},
{SW:[432,1094],NE:[418,1064],SE:[410,1072],NW:[440,1086]},
{SW:[432,1095],NE:[418,1065],SE:[410,1073],NW:[440,1087]},
{SW:[432,1095],NE:[418,1066],SE:[410,1073],NW:[440,1088]},
{SW:[433,1096],NE:[418,1066],SE:[411,1074],NW:[440,1088]},
{SW:[433,1097],NE:[419,1067],SE:[411,1075],NW:[441,1089]},
{SW:[433,1097],NE:[419,1068],SE:[411,1075],NW:[441,1090]},
{SW:[434,1098],NE:[419,1068],SE:[412,1076],NW:[441,1090]},
{SW:[434,1098],NE:[419,1069],SE:[412,1076],NW:[441,1091]},
{SW:[434,1099],NE:[420,1070],SE:[412,1077],NW:[442,1092]},
{SW:[434,1100],NE:[420,1070],SE:[412,1078],NW:[442,1092]},
{SW:[435,1100],NE:[420,1071],SE:[413,1078],NW:[442,1093]},
{SW:[435,1101],NE:[420,1072],SE:[413,1079],NW:[442,1094]},
{SW:[435,1102],NE:[420,1072],SE:[413,1080],NW:[442,1094]},
{SW:[435,1102],NE:[420,1073],SE:[413,1080],NW:[442,1095]},
{SW:[435,1103],NE:[420,1074],SE:[413,1081],NW:[442,1096]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1105],NE:[420,1076],SE:[413,1083],NW:[442,1098]},
{SW:[435,1105],NE:[420,1077],SE:[413,1083],NW:[442,1099]},
{SW:[435,1106],NE:[420,1077],SE:[413,1084],NW:[442,1099]},
{SW:[435,1107],NE:[420,1078],SE:[413,1085],NW:[442,1100]},
{SW:[435,1107],NE:[420,1079],SE:[413,1085],NW:[442,1101]},
{SW:[435,1108],NE:[420,1079],SE:[413,1086],NW:[442,1101]},
{SW:[435,1108],NE:[420,1080],SE:[413,1086],NW:[442,1102]},
{SW:[435,1109],NE:[419,1081],SE:[413,1087],NW:[441,1103]},
{SW:[435,1110],NE:[419,1081],SE:[413,1088],NW:[441,1103]},
{SW:[435,1110],NE:[419,1082],SE:[413,1088],NW:[441,1104]},
{SW:[435,1111],NE:[419,1083],SE:[413,1089],NW:[441,1105]},
{SW:[434,1111],NE:[418,1084],SE:[412,1089],NW:[440,1106]},
{SW:[434,1112],NE:[418,1084],SE:[412,1090],NW:[440,1106]},
{SW:[434,1113],NE:[418,1085],SE:[412,1091],NW:[440,1107]},
{SW:[434,1113],NE:[417,1086],SE:[412,1091],NW:[439,1108]},
{SW:[434,1114],NE:[417,1086],SE:[412,1092],NW:[439,1108]},
{SW:[433,1114],NE:[417,1087],SE:[411,1092],NW:[439,1109]},
{SW:[433,1115],NE:[416,1087],SE:[411,1093],NW:[438,1109]},
{SW:[433,1115],NE:[416,1088],SE:[411,1093],NW:[438,1110]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[432,1117],NE:[414,1090],SE:[410,1095],NW:[436,1112]},
{SW:[431,1117],NE:[414,1091],SE:[409,1095],NW:[436,1113]},
{SW:[431,1118],NE:[413,1091],SE:[409,1096],NW:[435,1113]},
{SW:[430,1118],NE:[413,1092],SE:[408,1096],NW:[435,1114]},
{SW:[430,1119],NE:[412,1092],SE:[408,1097],NW:[434,1114]},
{SW:[429,1119],NE:[412,1093],SE:[407,1097],NW:[434,1115]},
{SW:[429,1120],NE:[411,1093],SE:[407,1098],NW:[433,1115]},
{SW:[428,1120],NE:[411,1094],SE:[406,1098],NW:[433,1116]},
{SW:[428,1121],NE:[410,1095],SE:[406,1099],NW:[432,1117]},
{SW:[427,1121],NE:[409,1095],SE:[405,1099],NW:[431,1117]},
{SW:[427,1121],NE:[409,1096],SE:[405,1099],NW:[431,1118]},
{SW:[426,1122],NE:[408,1096],SE:[404,1100],NW:[430,1118]},
{SW:[426,1122],NE:[407,1097],SE:[404,1100],NW:[429,1119]},
{SW:[425,1123],NE:[407,1097],SE:[403,1101],NW:[429,1119]},
{SW:[424,1123],NE:[406,1098],SE:[402,1101],NW:[428,1120]},
{SW:[424,1123],NE:[405,1098],SE:[402,1101],NW:[427,1120]},
{SW:[423,1124],NE:[404,1099],SE:[401,1102],NW:[426,1121]},
{SW:[423,1124],NE:[404,1099],SE:[401,1102],NW:[426,1121]},
{SW:[422,1124],NE:[403,1100],SE:[400,1102],NW:[425,1122]},
{SW:[421,1125],NE:[402,1100],SE:[399,1103],NW:[424,1122]},
{SW:[421,1125],NE:[401,1101],SE:[399,1103],NW:[423,1123]},
{SW:[420,1125],NE:[400,1101],SE:[398,1103],NW:[422,1123]},
{SW:[419,1126],NE:[400,1101],SE:[397,1104],NW:[422,1123]},
{SW:[418,1126],NE:[399,1102],SE:[397,1104],NW:[421,1124]},
{SW:[418,1126],NE:[398,1102],SE:[396,1104],NW:[420,1124]},
{SW:[417,1127],NE:[397,1103],SE:[395,1105],NW:[419,1125]},
{SW:[416,1127],NE:[396,1103],SE:[394,1105],NW:[418,1125]},
{SW:[416,1127],NE:[395,1104],SE:[394,1105],NW:[417,1126]},
{SW:[415,1128],NE:[394,1104],SE:[393,1106],NW:[416,1126]},
{SW:[414,1128],NE:[394,1104],SE:[392,1106],NW:[416,1126]},
{SW:[413,1128],NE:[393,1105],SE:[391,1106],NW:[415,1127]},
{SW:[413,1128],NE:[392,1105],SE:[391,1106],NW:[414,1127]},
{SW:[412,1129],NE:[391,1106],SE:[390,1107],NW:[413,1128]},
{SW:[411,1129],NE:[390,1106],SE:[389,1107],NW:[412,1128]},
{SW:[410,1129],NE:[389,1106],SE:[388,1107],NW:[411,1128]},
{SW:[409,1129],NE:[388,1107],SE:[387,1107],NW:[410,1129]},
{SW:[409,1130],NE:[387,1107],SE:[387,1108],NW:[409,1129]},
{SW:[408,1130],NE:[386,1107],SE:[386,1108],NW:[408,1129]},
{SW:[407,1130],NE:[385,1108],SE:[385,1108],NW:[407,1130]},
{SW:[406,1130],NE:[384,1108],SE:[384,1108],NW:[406,1130]},
{SW:[405,1131],NE:[383,1109],SE:[383,1109],NW:[405,1131]},
{SW:[404,1131],NE:[382,1109],SE:[382,1109],NW:[404,1131]},
{SW:[404,1131],NE:[382,1109],SE:[382,1109],NW:[403,1131]},
{SW:[403,1131],NE:[381,1110],SE:[381,1109],NW:[403,1132]},
{SW:[402,1132],NE:[380,1110],SE:[380,1110],NW:[402,1132]},
{SW:[401,1132],NE:[379,1110],SE:[379,1110],NW:[401,1132]},
{SW:[400,1132],NE:[378,1111],SE:[378,1110],NW:[400,1133]},
{SW:[399,1132],NE:[377,1111],SE:[377,1110],NW:[399,1133]},
{SW:[399,1132],NE:[376,1111],SE:[377,1110],NW:[398,1133]},
{SW:[398,1133],NE:[375,1112],SE:[376,1111],NW:[397,1134]},
{SW:[397,1133],NE:[374,1112],SE:[375,1111],NW:[396,1134]},
{SW:[396,1133],NE:[373,1112],SE:[374,1111],NW:[395,1134]},
{SW:[395,1133],NE:[372,1113],SE:[373,1111],NW:[394,1135]},
{SW:[395,1134],NE:[371,1113],SE:[373,1112],NW:[393,1135]},
{SW:[394,1134],NE:[370,1114],SE:[372,1112],NW:[392,1136]},
{SW:[393,1134],NE:[369,1114],SE:[371,1112],NW:[391,1136]},
{SW:[392,1134],NE:[368,1114],SE:[370,1112],NW:[390,1136]},
{SW:[391,1135],NE:[367,1115],SE:[369,1112],NW:[389,1137]},
{SW:[390,1135],NE:[366,1115],SE:[369,1113],NW:[388,1137]},
{SW:[390,1135],NE:[365,1115],SE:[368,1113],NW:[387,1137]},
{SW:[389,1135],NE:[365,1116],SE:[367,1113],NW:[387,1138]},
{SW:[388,1135],NE:[364,1116],SE:[366,1113],NW:[386,1138]},
{SW:[387,1136],NE:[363,1116],SE:[365,1114],NW:[385,1138]},
{SW:[387,1136],NE:[362,1117],SE:[365,1114],NW:[384,1139]},
{SW:[386,1136],NE:[361,1117],SE:[364,1114],NW:[383,1139]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[384,1137],NE:[359,1118],SE:[362,1115],NW:[381,1140]},
{SW:[384,1137],NE:[358,1118],SE:[362,1115],NW:[380,1140]},
{SW:[383,1137],NE:[357,1119],SE:[361,1115],NW:[379,1141]},
{SW:[382,1137],NE:[357,1119],SE:[360,1115],NW:[379,1141]},
{SW:[381,1138],NE:[356,1119],SE:[359,1116],NW:[378,1141]},
{SW:[381,1138],NE:[355,1120],SE:[359,1116],NW:[377,1142]},
{SW:[380,1138],NE:[354,1120],SE:[358,1116],NW:[376,1142]},
{SW:[379,1139],NE:[353,1120],SE:[357,1117],NW:[375,1142]},
{SW:[379,1139],NE:[353,1121],SE:[357,1117],NW:[375,1143]},
{SW:[378,1139],NE:[352,1121],SE:[356,1117],NW:[374,1143]},
{SW:[377,1139],NE:[351,1122],SE:[355,1117],NW:[373,1144]},
{SW:[377,1140],NE:[350,1122],SE:[355,1118],NW:[372,1144]},
{SW:[376,1140],NE:[349,1122],SE:[354,1118],NW:[371,1144]},
{SW:[375,1140],NE:[349,1123],SE:[353,1118],NW:[371,1145]},
{SW:[375,1141],NE:[348,1123],SE:[353,1119],NW:[370,1145]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[373,1142],NE:[346,1125],SE:[351,1120],NW:[368,1147]},
{SW:[372,1142],NE:[345,1125],SE:[350,1120],NW:[367,1147]},
{SW:[372,1142],NE:[345,1125],SE:[350,1120],NW:[367,1147]},
{SW:[371,1143],NE:[344,1126],SE:[349,1121],NW:[366,1148]},
{SW:[371,1143],NE:[343,1126],SE:[349,1121],NW:[365,1148]},
{SW:[370,1143],NE:[343,1127],SE:[348,1121],NW:[365,1149]},
{SW:[370,1144],NE:[342,1127],SE:[348,1122],NW:[364,1149]},
{SW:[369,1144],NE:[342,1128],SE:[347,1122],NW:[364,1150]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[368,1145],NE:[341,1129],SE:[346,1123],NW:[363,1151]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[361,1151]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[366,1146],NE:[338,1131],SE:[344,1124],NW:[360,1153]},
{SW:[366,1147],NE:[338,1131],SE:[344,1125],NW:[360,1153]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[365,1148],NE:[337,1132],SE:[343,1126],NW:[359,1154]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1149],NE:[336,1134],SE:[343,1127],NW:[358,1156]},
{SW:[364,1149],NE:[335,1134],SE:[342,1127],NW:[357,1156]},
{SW:[364,1149],NE:[335,1134],SE:[342,1127],NW:[357,1156]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1151],NE:[334,1136],SE:[342,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1136],SE:[341,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1137],SE:[341,1129],NW:[356,1159]},
{SW:[363,1152],NE:[334,1137],SE:[341,1130],NW:[356,1159]},
{SW:[363,1152],NE:[334,1138],SE:[341,1130],NW:[356,1160]},
{SW:[363,1152],NE:[333,1138],SE:[341,1130],NW:[355,1160]},
{SW:[363,1153],NE:[333,1138],SE:[341,1131],NW:[355,1160]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1154],NE:[333,1139],SE:[341,1132],NW:[355,1161]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1155],NE:[333,1140],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1144],SE:[341,1136],NW:[355,1166]},
{SW:[364,1158],NE:[333,1144],SE:[342,1136],NW:[355,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[365,1159],NE:[335,1145],SE:[343,1137],NW:[357,1167]},
{SW:[365,1159],NE:[335,1146],SE:[343,1137],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[345,1138],NW:[358,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1168]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[340,1146],SE:[347,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[341,1146],SE:[348,1139],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[342,1146],SE:[349,1138],NW:[364,1168]},
{SW:[371,1160],NE:[342,1146],SE:[349,1138],NW:[364,1168]},
{SW:[372,1160],NE:[342,1145],SE:[350,1138],NW:[364,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[373,1160],NE:[344,1145],SE:[351,1138],NW:[366,1167]},
{SW:[373,1159],NE:[344,1144],SE:[351,1137],NW:[366,1166]},
{SW:[374,1159],NE:[344,1144],SE:[352,1137],NW:[366,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1159],NE:[345,1143],SE:[352,1137],NW:[367,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[347,1142],SE:[353,1136],NW:[369,1164]},
{SW:[376,1157],NE:[347,1142],SE:[354,1135],NW:[369,1164]},
{SW:[376,1157],NE:[347,1141],SE:[354,1135],NW:[369,1163]},
{SW:[376,1157],NE:[348,1141],SE:[354,1135],NW:[370,1163]},
{SW:[377,1156],NE:[348,1141],SE:[355,1134],NW:[370,1163]},
{SW:[377,1156],NE:[349,1140],SE:[355,1134],NW:[371,1162]},
{SW:[377,1155],NE:[349,1140],SE:[355,1133],NW:[371,1162]},
{SW:[378,1155],NE:[350,1139],SE:[356,1133],NW:[372,1161]},
{SW:[378,1154],NE:[350,1138],SE:[356,1133],NW:[372,1160]},
{SW:[378,1154],NE:[350,1138],SE:[356,1132],NW:[372,1160]},
{SW:[379,1154],NE:[351,1137],SE:[357,1132],NW:[373,1159]},
{SW:[379,1153],NE:[351,1137],SE:[357,1131],NW:[373,1159]},
{SW:[379,1153],NE:[352,1136],SE:[357,1131],NW:[374,1158]},
{SW:[379,1152],NE:[352,1135],SE:[357,1130],NW:[374,1157]},
{SW:[380,1151],NE:[352,1135],SE:[358,1129],NW:[374,1157]},
{SW:[380,1151],NE:[353,1134],SE:[358,1129],NW:[375,1156]},
{SW:[380,1150],NE:[353,1134],SE:[358,1128],NW:[375,1155]},
{SW:[381,1150],NE:[353,1133],SE:[359,1128],NW:[375,1155]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1148],NE:[354,1131],SE:[359,1127],NW:[376,1153]},
{SW:[381,1148],NE:[355,1131],SE:[359,1126],NW:[377,1153]},
{SW:[382,1147],NE:[355,1130],SE:[360,1125],NW:[377,1152]},
{SW:[382,1147],NE:[355,1129],SE:[360,1125],NW:[377,1151]},
{SW:[382,1146],NE:[356,1128],SE:[360,1124],NW:[378,1150]},
{SW:[382,1145],NE:[356,1128],SE:[360,1123],NW:[378,1150]},
{SW:[383,1145],NE:[356,1127],SE:[361,1123],NW:[378,1149]},
{SW:[383,1144],NE:[357,1126],SE:[361,1122],NW:[379,1148]},
{SW:[383,1143],NE:[357,1125],SE:[361,1121],NW:[379,1147]},
{SW:[383,1142],NE:[357,1124],SE:[361,1120],NW:[379,1146]},
{SW:[384,1142],NE:[358,1123],SE:[362,1120],NW:[380,1145]},
{SW:[384,1141],NE:[358,1123],SE:[362,1119],NW:[380,1145]},
{SW:[384,1140],NE:[358,1122],SE:[362,1118],NW:[380,1144]},
{SW:[384,1139],NE:[359,1121],SE:[362,1117],NW:[381,1143]},
{SW:[384,1139],NE:[359,1120],SE:[362,1117],NW:[381,1142]},
{SW:[385,1138],NE:[359,1119],SE:[363,1116],NW:[381,1141]},
{SW:[385,1137],NE:[360,1118],SE:[363,1115],NW:[382,1140]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[385,1135],NE:[360,1116],SE:[363,1113],NW:[382,1138]},
{SW:[385,1135],NE:[361,1115],SE:[363,1113],NW:[383,1137]},
{SW:[386,1134],NE:[361,1114],SE:[364,1112],NW:[383,1136]},
{SW:[386,1133],NE:[361,1113],SE:[364,1111],NW:[383,1135]},
{SW:[386,1132],NE:[361,1113],SE:[364,1110],NW:[383,1134]},
{SW:[386,1131],NE:[362,1112],SE:[364,1109],NW:[384,1134]},
{SW:[386,1130],NE:[362,1111],SE:[364,1108],NW:[384,1133]},
{SW:[386,1130],NE:[362,1110],SE:[364,1108],NW:[384,1132]},
{SW:[387,1129],NE:[363,1109],SE:[365,1107],NW:[385,1131]},
{SW:[387,1128],NE:[363,1108],SE:[365,1106],NW:[385,1130]},
{SW:[387,1127],NE:[363,1107],SE:[365,1105],NW:[385,1129]},
{SW:[387,1126],NE:[363,1106],SE:[365,1104],NW:[385,1128]},
{SW:[387,1125],NE:[364,1105],SE:[365,1103],NW:[386,1127]},
{SW:[387,1124],NE:[364,1104],SE:[365,1102],NW:[386,1126]},
{SW:[388,1124],NE:[364,1103],SE:[366,1102],NW:[386,1125]},
{SW:[388,1123],NE:[365,1102],SE:[366,1101],NW:[387,1124]},
{SW:[388,1122],NE:[365,1101],SE:[366,1100],NW:[387,1123]},
{SW:[388,1121],NE:[365,1100],SE:[366,1099],NW:[387,1122]},
{SW:[388,1120],NE:[365,1099],SE:[366,1098],NW:[387,1121]},
{SW:[388,1119],NE:[366,1098],SE:[366,1097],NW:[388,1120]},
{SW:[389,1118],NE:[366,1097],SE:[367,1096],NW:[388,1119]},
{SW:[389,1117],NE:[366,1096],SE:[367,1095],NW:[388,1118]},
{SW:[389,1117],NE:[367,1095],SE:[367,1095],NW:[389,1117]},
{SW:[389,1116],NE:[367,1094],SE:[367,1094],NW:[389,1116]},
{SW:[389,1115],NE:[367,1093],SE:[367,1093],NW:[389,1115]},
{SW:[389,1114],NE:[368,1092],SE:[367,1092],NW:[390,1114]},
{SW:[390,1113],NE:[368,1091],SE:[368,1091],NW:[390,1113]},
{SW:[390,1112],NE:[368,1090],SE:[368,1090],NW:[390,1112]},
{SW:[390,1111],NE:[368,1089],SE:[368,1089],NW:[390,1111]},
{SW:[390,1111],NE:[369,1088],SE:[368,1089],NW:[391,1110]},
{SW:[390,1110],NE:[369,1087],SE:[368,1088],NW:[391,1109]},
{SW:[390,1109],NE:[369,1086],SE:[368,1087],NW:[391,1108]},
{SW:[391,1108],NE:[370,1085],SE:[369,1086],NW:[392,1107]},
{SW:[391,1107],NE:[370,1084],SE:[369,1085],NW:[392,1106]},
{SW:[391,1106],NE:[370,1083],SE:[369,1084],NW:[392,1105]},
{SW:[391,1105],NE:[371,1082],SE:[369,1084],NW:[393,1104]},
{SW:[391,1105],NE:[371,1081],SE:[369,1083],NW:[393,1103]},
{SW:[392,1104],NE:[371,1080],SE:[370,1082],NW:[393,1102]},
{SW:[392,1103],NE:[372,1079],SE:[370,1081],NW:[394,1101]},
{SW:[392,1102],NE:[372,1078],SE:[370,1080],NW:[394,1100]},
{SW:[392,1101],NE:[372,1077],SE:[370,1080],NW:[394,1099]},
{SW:[393,1101],NE:[373,1077],SE:[371,1079],NW:[395,1099]},
{SW:[393,1100],NE:[373,1076],SE:[371,1078],NW:[395,1098]},
{SW:[393,1099],NE:[374,1075],SE:[371,1077],NW:[396,1097]},
{SW:[393,1098],NE:[374,1074],SE:[371,1076],NW:[396,1096]},
{SW:[394,1098],NE:[374,1073],SE:[372,1076],NW:[396,1095]},
{SW:[394,1097],NE:[375,1072],SE:[372,1075],NW:[397,1094]},
{SW:[394,1096],NE:[375,1071],SE:[372,1074],NW:[397,1093]},
{SW:[395,1096],NE:[376,1071],SE:[373,1074],NW:[398,1093]},
{SW:[395,1095],NE:[376,1070],SE:[373,1073],NW:[398,1092]},
{SW:[395,1094],NE:[376,1069],SE:[373,1072],NW:[398,1091]},
{SW:[396,1094],NE:[377,1068],SE:[373,1072],NW:[399,1090]},
{SW:[396,1093],NE:[377,1067],SE:[374,1071],NW:[399,1089]},
{SW:[396,1092],NE:[378,1067],SE:[374,1070],NW:[400,1089]},
{SW:[397,1092],NE:[378,1066],SE:[375,1070],NW:[400,1088]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[398,1090],NE:[380,1064],SE:[376,1068],NW:[402,1086]},
{SW:[398,1089],NE:[380,1063],SE:[376,1067],NW:[402,1085]},
{SW:[398,1089],NE:[381,1063],SE:[376,1067],NW:[403,1085]},
{SW:[399,1088],NE:[381,1062],SE:[377,1066],NW:[403,1084]},
{SW:[399,1088],NE:[382,1061],SE:[377,1066],NW:[404,1083]},
{SW:[400,1087],NE:[382,1061],SE:[378,1065],NW:[404,1083]},
{SW:[400,1087],NE:[383,1060],SE:[378,1065],NW:[405,1082]},
{SW:[400,1086],NE:[383,1060],SE:[378,1064],NW:[405,1082]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[408,1080]},
{SW:[403,1084],NE:[386,1057],SE:[381,1062],NW:[408,1079]},
{SW:[403,1084],NE:[387,1057],SE:[381,1062],NW:[409,1079]},
{SW:[404,1084],NE:[387,1056],SE:[382,1062],NW:[409,1078]},
{SW:[404,1084],NE:[388,1056],SE:[382,1062],NW:[410,1078]},
{SW:[405,1083],NE:[388,1055],SE:[383,1061],NW:[410,1077]},
{SW:[405,1083],NE:[389,1055],SE:[383,1061],NW:[411,1077]},
{SW:[406,1083],NE:[390,1055],SE:[384,1061],NW:[412,1077]},
{SW:[406,1083],NE:[390,1054],SE:[384,1061],NW:[412,1076]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[407,1082],NE:[392,1054],SE:[385,1060],NW:[414,1076]},
{SW:[408,1082],NE:[392,1054],SE:[386,1060],NW:[414,1076]},
{SW:[409,1082],NE:[393,1053],SE:[387,1060],NW:[415,1075]},
{SW:[409,1082],NE:[394,1053],SE:[387,1060],NW:[416,1075]},
{SW:[410,1082],NE:[394,1053],SE:[388,1060],NW:[416,1075]},
{SW:[410,1082],NE:[395,1053],SE:[388,1060],NW:[417,1075]},
{SW:[411,1082],NE:[396,1053],SE:[389,1060],NW:[418,1075]},
{SW:[411,1082],NE:[396,1053],SE:[389,1060],NW:[418,1075]},
{SW:[412,1082],NE:[397,1053],SE:[390,1060],NW:[419,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[414,1082],NE:[400,1053],SE:[392,1060],NW:[422,1075]},
{SW:[415,1082],NE:[400,1053],SE:[393,1060],NW:[422,1075]},
{SW:[416,1082],NE:[401,1053],SE:[394,1060],NW:[423,1075]},
{SW:[416,1082],NE:[402,1053],SE:[394,1060],NW:[424,1075]},
{SW:[417,1082],NE:[402,1053],SE:[395,1060],NW:[424,1075]},
{SW:[418,1082],NE:[403,1053],SE:[396,1061],NW:[425,1075]},
{SW:[418,1083],NE:[404,1053],SE:[396,1061],NW:[426,1075]},
{SW:[419,1083],NE:[404,1053],SE:[397,1061],NW:[426,1075]},
{SW:[419,1083],NE:[405,1054],SE:[397,1061],NW:[427,1076]},
{SW:[420,1083],NE:[406,1054],SE:[398,1061],NW:[428,1076]},
{SW:[421,1084],NE:[406,1054],SE:[399,1062],NW:[428,1076]},
{SW:[421,1084],NE:[407,1054],SE:[399,1062],NW:[429,1076]},
{SW:[422,1084],NE:[407,1055],SE:[400,1062],NW:[429,1077]},
{SW:[422,1085],NE:[408,1055],SE:[400,1063],NW:[430,1077]},
{SW:[423,1085],NE:[409,1055],SE:[401,1063],NW:[431,1077]},
{SW:[423,1085],NE:[409,1056],SE:[401,1063],NW:[431,1078]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[425,1086],NE:[410,1056],SE:[403,1064],NW:[432,1078]},
{SW:[425,1087],NE:[411,1057],SE:[403,1065],NW:[433,1079]},
{SW:[426,1087],NE:[412,1057],SE:[404,1065],NW:[434,1079]},
{SW:[426,1088],NE:[412,1058],SE:[404,1066],NW:[434,1080]},
{SW:[427,1088],NE:[413,1058],SE:[405,1066],NW:[435,1080]},
{SW:[427,1089],NE:[413,1059],SE:[405,1067],NW:[435,1081]},
{SW:[428,1089],NE:[414,1059],SE:[406,1067],NW:[436,1081]},
{SW:[428,1090],NE:[414,1060],SE:[406,1068],NW:[436,1082]},
{SW:[429,1090],NE:[415,1060],SE:[407,1068],NW:[437,1082]},
{SW:[429,1091],NE:[415,1061],SE:[407,1069],NW:[437,1083]},
{SW:[430,1091],NE:[416,1061],SE:[408,1069],NW:[438,1083]},
{SW:[430,1092],NE:[416,1062],SE:[408,1070],NW:[438,1084]},
{SW:[431,1092],NE:[416,1062],SE:[409,1070],NW:[438,1084]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[431,1093],NE:[417,1064],SE:[409,1071],NW:[439,1086]},
{SW:[432,1094],NE:[417,1064],SE:[410,1072],NW:[439,1086]},
{SW:[432,1095],NE:[418,1065],SE:[410,1073],NW:[440,1087]},
{SW:[432,1095],NE:[418,1066],SE:[410,1073],NW:[440,1088]},
{SW:[433,1096],NE:[418,1066],SE:[411,1074],NW:[440,1088]},
{SW:[433,1096],NE:[419,1067],SE:[411,1074],NW:[441,1089]},
{SW:[433,1097],NE:[419,1067],SE:[411,1075],NW:[441,1089]},
{SW:[434,1098],NE:[419,1068],SE:[412,1076],NW:[441,1090]},
{SW:[434,1098],NE:[419,1069],SE:[412,1076],NW:[441,1091]},
{SW:[434,1099],NE:[420,1069],SE:[412,1077],NW:[442,1091]},
{SW:[434,1100],NE:[420,1070],SE:[412,1078],NW:[442,1092]},
{SW:[434,1100],NE:[420,1071],SE:[412,1078],NW:[442,1093]},
{SW:[435,1101],NE:[420,1072],SE:[413,1079],NW:[442,1094]},
{SW:[435,1101],NE:[420,1072],SE:[413,1079],NW:[442,1094]},
{SW:[435,1102],NE:[420,1073],SE:[413,1080],NW:[442,1095]},
{SW:[435,1103],NE:[420,1074],SE:[413,1081],NW:[442,1096]},
{SW:[435,1103],NE:[420,1074],SE:[413,1081],NW:[442,1096]},
{SW:[435,1104],NE:[420,1075],SE:[413,1082],NW:[442,1097]},
{SW:[435,1105],NE:[420,1076],SE:[413,1083],NW:[442,1098]},
{SW:[435,1105],NE:[420,1076],SE:[413,1083],NW:[442,1098]},
{SW:[435,1106],NE:[420,1077],SE:[413,1084],NW:[442,1099]},
{SW:[435,1107],NE:[420,1078],SE:[413,1085],NW:[442,1100]},
{SW:[435,1107],NE:[420,1079],SE:[413,1085],NW:[442,1101]},
{SW:[435,1108],NE:[420,1079],SE:[413,1086],NW:[442,1101]},
{SW:[435,1108],NE:[420,1080],SE:[413,1086],NW:[442,1102]},
{SW:[435,1109],NE:[419,1081],SE:[413,1087],NW:[441,1103]},
{SW:[435,1110],NE:[419,1081],SE:[413,1088],NW:[441,1103]},
{SW:[435,1110],NE:[419,1082],SE:[413,1088],NW:[441,1104]},
{SW:[435,1111],NE:[419,1083],SE:[413,1089],NW:[441,1105]},
{SW:[434,1111],NE:[418,1083],SE:[412,1089],NW:[440,1105]},
{SW:[434,1112],NE:[418,1084],SE:[412,1090],NW:[440,1106]},
{SW:[434,1112],NE:[418,1085],SE:[412,1090],NW:[440,1107]},
{SW:[434,1113],NE:[418,1085],SE:[412,1091],NW:[440,1107]},
{SW:[434,1114],NE:[417,1086],SE:[412,1092],NW:[439,1108]},
{SW:[433,1114],NE:[417,1087],SE:[411,1092],NW:[439,1109]},
{SW:[433,1115],NE:[416,1087],SE:[411,1093],NW:[438,1109]},
{SW:[433,1115],NE:[416,1088],SE:[411,1093],NW:[438,1110]},
{SW:[432,1116],NE:[416,1089],SE:[410,1094],NW:[438,1111]},
{SW:[432,1116],NE:[415,1089],SE:[410,1094],NW:[437,1111]},
{SW:[432,1117],NE:[415,1090],SE:[410,1095],NW:[437,1112]},
{SW:[431,1117],NE:[414,1090],SE:[409,1095],NW:[436,1112]},
{SW:[431,1118],NE:[414,1091],SE:[409,1096],NW:[436,1113]},
{SW:[430,1118],NE:[413,1092],SE:[408,1096],NW:[435,1114]},
{SW:[430,1119],NE:[412,1092],SE:[408,1097],NW:[434,1114]},
{SW:[429,1119],NE:[412,1093],SE:[407,1097],NW:[434,1115]},
{SW:[429,1120],NE:[411,1093],SE:[407,1098],NW:[433,1115]},
{SW:[429,1120],NE:[411,1094],SE:[407,1098],NW:[433,1116]},
{SW:[428,1120],NE:[410,1094],SE:[406,1098],NW:[432,1116]},
{SW:[427,1121],NE:[409,1095],SE:[405,1099],NW:[431,1117]},
{SW:[427,1121],NE:[409,1095],SE:[405,1099],NW:[431,1117]},
{SW:[426,1122],NE:[408,1096],SE:[404,1100],NW:[430,1118]},
{SW:[426,1122],NE:[407,1097],SE:[404,1100],NW:[429,1119]},
{SW:[425,1122],NE:[407,1097],SE:[403,1100],NW:[429,1119]},
{SW:[425,1123],NE:[406,1098],SE:[403,1101],NW:[428,1120]},
{SW:[424,1123],NE:[405,1098],SE:[402,1101],NW:[427,1120]},
{SW:[423,1124],NE:[405,1099],SE:[401,1102],NW:[427,1121]},
{SW:[423,1124],NE:[404,1099],SE:[401,1102],NW:[426,1121]},
{SW:[422,1124],NE:[403,1099],SE:[400,1102],NW:[425,1122]},
{SW:[421,1125],NE:[402,1100],SE:[399,1103],NW:[424,1122]},
{SW:[421,1125],NE:[401,1100],SE:[399,1103],NW:[423,1122]},
{SW:[420,1125],NE:[401,1101],SE:[398,1103],NW:[423,1123]},
{SW:[419,1126],NE:[400,1101],SE:[397,1104],NW:[422,1123]},
{SW:[419,1126],NE:[399,1102],SE:[397,1104],NW:[421,1124]},
{SW:[418,1126],NE:[398,1102],SE:[396,1104],NW:[420,1124]},
{SW:[417,1127],NE:[397,1103],SE:[395,1105],NW:[419,1125]},
{SW:[416,1127],NE:[396,1103],SE:[394,1105],NW:[418,1125]},
{SW:[416,1127],NE:[396,1103],SE:[394,1105],NW:[418,1125]},
{SW:[415,1127],NE:[395,1104],SE:[393,1105],NW:[417,1126]},
{SW:[414,1128],NE:[394,1104],SE:[392,1106],NW:[416,1126]},
{SW:[413,1128],NE:[393,1105],SE:[391,1106],NW:[415,1127]},
{SW:[413,1128],NE:[392,1105],SE:[391,1106],NW:[414,1127]},
{SW:[412,1129],NE:[391,1105],SE:[390,1107],NW:[413,1127]},
{SW:[411,1129],NE:[390,1106],SE:[389,1107],NW:[412,1128]},
{SW:[410,1129],NE:[389,1106],SE:[388,1107],NW:[411,1128]},
{SW:[410,1129],NE:[388,1107],SE:[388,1107],NW:[410,1129]},
{SW:[409,1130],NE:[387,1107],SE:[387,1108],NW:[409,1129]},
{SW:[408,1130],NE:[386,1107],SE:[386,1108],NW:[408,1129]},
{SW:[407,1130],NE:[386,1108],SE:[385,1108],NW:[408,1130]},
{SW:[406,1130],NE:[385,1108],SE:[384,1108],NW:[407,1130]},
{SW:[405,1131],NE:[384,1108],SE:[383,1109],NW:[406,1130]},
{SW:[405,1131],NE:[383,1109],SE:[383,1109],NW:[405,1131]},
{SW:[404,1131],NE:[382,1109],SE:[382,1109],NW:[404,1131]},
{SW:[403,1131],NE:[381,1110],SE:[381,1109],NW:[403,1132]},
{SW:[402,1132],NE:[380,1110],SE:[380,1110],NW:[402,1132]},
{SW:[401,1132],NE:[379,1110],SE:[379,1110],NW:[401,1132]},
{SW:[401,1132],NE:[378,1111],SE:[379,1110],NW:[400,1133]},
{SW:[400,1132],NE:[377,1111],SE:[378,1110],NW:[399,1133]},
{SW:[399,1132],NE:[376,1111],SE:[377,1110],NW:[398,1133]},
{SW:[398,1133],NE:[375,1112],SE:[376,1111],NW:[397,1134]},
{SW:[397,1133],NE:[374,1112],SE:[375,1111],NW:[396,1134]},
{SW:[396,1133],NE:[373,1112],SE:[374,1111],NW:[395,1134]},
{SW:[396,1133],NE:[372,1113],SE:[374,1111],NW:[394,1135]},
{SW:[395,1134],NE:[371,1113],SE:[373,1112],NW:[393,1135]},
{SW:[394,1134],NE:[370,1113],SE:[372,1112],NW:[392,1135]},
{SW:[393,1134],NE:[369,1114],SE:[371,1112],NW:[391,1136]},
{SW:[392,1134],NE:[368,1114],SE:[370,1112],NW:[390,1136]},
{SW:[391,1134],NE:[368,1114],SE:[369,1112],NW:[390,1136]},
{SW:[391,1135],NE:[367,1115],SE:[369,1113],NW:[389,1137]},
{SW:[390,1135],NE:[366,1115],SE:[368,1113],NW:[388,1137]},
{SW:[389,1135],NE:[365,1116],SE:[367,1113],NW:[387,1138]},
{SW:[388,1135],NE:[364,1116],SE:[366,1113],NW:[386,1138]},
{SW:[388,1136],NE:[363,1116],SE:[366,1114],NW:[385,1138]},
{SW:[387,1136],NE:[362,1117],SE:[365,1114],NW:[384,1139]},
{SW:[386,1136],NE:[361,1117],SE:[364,1114],NW:[383,1139]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[384,1137],NE:[359,1118],SE:[362,1115],NW:[381,1140]},
{SW:[384,1137],NE:[359,1118],SE:[362,1115],NW:[381,1140]},
{SW:[383,1137],NE:[358,1118],SE:[361,1115],NW:[380,1140]},
{SW:[382,1137],NE:[357,1119],SE:[360,1115],NW:[379,1141]},
{SW:[382,1138],NE:[356,1119],SE:[360,1116],NW:[378,1141]},
{SW:[381,1138],NE:[355,1120],SE:[359,1116],NW:[377,1142]},
{SW:[380,1138],NE:[354,1120],SE:[358,1116],NW:[376,1142]},
{SW:[379,1138],NE:[354,1120],SE:[357,1116],NW:[376,1142]},
{SW:[379,1139],NE:[353,1121],SE:[357,1117],NW:[375,1143]},
{SW:[378,1139],NE:[352,1121],SE:[356,1117],NW:[374,1143]},
{SW:[377,1139],NE:[351,1122],SE:[355,1117],NW:[373,1144]},
{SW:[377,1140],NE:[350,1122],SE:[355,1118],NW:[372,1144]},
{SW:[376,1140],NE:[350,1122],SE:[354,1118],NW:[372,1144]},
{SW:[375,1140],NE:[349,1123],SE:[353,1118],NW:[371,1145]},
{SW:[375,1140],NE:[348,1123],SE:[353,1118],NW:[370,1145]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[374,1141],NE:[347,1124],SE:[352,1119],NW:[369,1146]},
{SW:[373,1141],NE:[346,1124],SE:[351,1119],NW:[368,1146]},
{SW:[373,1142],NE:[345,1125],SE:[351,1120],NW:[367,1147]},
{SW:[372,1142],NE:[345,1125],SE:[350,1120],NW:[367,1147]},
{SW:[371,1142],NE:[344,1126],SE:[349,1120],NW:[366,1148]},
{SW:[371,1143],NE:[344,1126],SE:[349,1121],NW:[366,1148]},
{SW:[370,1143],NE:[343,1127],SE:[348,1121],NW:[365,1149]},
{SW:[370,1143],NE:[342,1127],SE:[348,1121],NW:[364,1149]},
{SW:[369,1144],NE:[342,1128],SE:[347,1122],NW:[364,1150]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[369,1144],NE:[341,1128],SE:[347,1122],NW:[363,1150]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[368,1145],NE:[340,1129],SE:[346,1123],NW:[362,1151]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[367,1146],NE:[339,1130],SE:[345,1124],NW:[361,1152]},
{SW:[367,1146],NE:[338,1131],SE:[345,1124],NW:[360,1153]},
{SW:[366,1147],NE:[338,1131],SE:[344,1125],NW:[360,1153]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[366,1147],NE:[337,1132],SE:[344,1125],NW:[359,1154]},
{SW:[365,1148],NE:[337,1133],SE:[343,1126],NW:[359,1155]},
{SW:[365,1148],NE:[336,1133],SE:[343,1126],NW:[358,1155]},
{SW:[365,1149],NE:[336,1133],SE:[343,1127],NW:[358,1155]},
{SW:[364,1149],NE:[336,1134],SE:[342,1127],NW:[358,1156]},
{SW:[364,1149],NE:[335,1134],SE:[342,1127],NW:[357,1156]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[335,1135],SE:[342,1128],NW:[357,1157]},
{SW:[364,1150],NE:[334,1136],SE:[342,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1136],SE:[341,1129],NW:[356,1158]},
{SW:[363,1151],NE:[334,1137],SE:[341,1129],NW:[356,1159]},
{SW:[363,1152],NE:[334,1137],SE:[341,1130],NW:[356,1159]},
{SW:[363,1152],NE:[334,1138],SE:[341,1130],NW:[356,1160]},
{SW:[363,1152],NE:[333,1138],SE:[341,1130],NW:[355,1160]},
{SW:[363,1153],NE:[333,1138],SE:[341,1131],NW:[355,1160]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1153],NE:[333,1139],SE:[341,1131],NW:[355,1161]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1154],NE:[333,1140],SE:[341,1132],NW:[355,1162]},
{SW:[363,1155],NE:[333,1140],SE:[341,1133],NW:[355,1162]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1155],NE:[333,1141],SE:[341,1133],NW:[355,1163]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1156],NE:[333,1142],SE:[341,1134],NW:[355,1164]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1143],SE:[341,1135],NW:[355,1165]},
{SW:[363,1157],NE:[333,1144],SE:[341,1135],NW:[355,1166]},
{SW:[364,1158],NE:[333,1144],SE:[342,1136],NW:[355,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1144],SE:[342,1136],NW:[356,1166]},
{SW:[364,1158],NE:[334,1145],SE:[342,1136],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[364,1159],NE:[334,1145],SE:[342,1137],NW:[356,1167]},
{SW:[365,1159],NE:[335,1145],SE:[343,1137],NW:[357,1167]},
{SW:[365,1159],NE:[335,1146],SE:[343,1137],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[365,1160],NE:[335,1146],SE:[343,1138],NW:[357,1168]},
{SW:[366,1160],NE:[335,1146],SE:[344,1138],NW:[357,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[366,1160],NE:[336,1146],SE:[344,1138],NW:[358,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1168]},
{SW:[367,1160],NE:[337,1146],SE:[345,1138],NW:[359,1169]},
{SW:[367,1161],NE:[337,1147],SE:[345,1139],NW:[359,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[368,1161],NE:[338,1147],SE:[346,1139],NW:[360,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1147],SE:[347,1139],NW:[361,1169]},
{SW:[369,1161],NE:[339,1146],SE:[347,1139],NW:[361,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[340,1146],SE:[348,1139],NW:[362,1168]},
{SW:[370,1161],NE:[341,1146],SE:[348,1139],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[341,1146],SE:[349,1138],NW:[363,1168]},
{SW:[371,1160],NE:[342,1146],SE:[349,1138],NW:[364,1168]},
{SW:[372,1160],NE:[342,1145],SE:[350,1138],NW:[364,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[372,1160],NE:[343,1145],SE:[350,1138],NW:[365,1167]},
{SW:[373,1160],NE:[344,1145],SE:[351,1138],NW:[366,1167]},
{SW:[373,1159],NE:[344,1144],SE:[351,1137],NW:[366,1166]},
{SW:[373,1159],NE:[344,1144],SE:[351,1137],NW:[366,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[374,1159],NE:[345,1144],SE:[352,1137],NW:[367,1166]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1143],SE:[353,1136],NW:[368,1165]},
{SW:[375,1158],NE:[346,1142],SE:[353,1136],NW:[368,1164]},
{SW:[376,1157],NE:[347,1142],SE:[354,1135],NW:[369,1164]},
{SW:[376,1157],NE:[347,1142],SE:[354,1135],NW:[369,1164]},
{SW:[376,1157],NE:[348,1141],SE:[354,1135],NW:[370,1163]},
{SW:[377,1156],NE:[348,1141],SE:[355,1134],NW:[370,1163]},
{SW:[377,1156],NE:[349,1140],SE:[355,1134],NW:[371,1162]},
{SW:[377,1155],NE:[349,1140],SE:[355,1133],NW:[371,1162]},
{SW:[378,1155],NE:[349,1139],SE:[356,1133],NW:[371,1161]},
{SW:[378,1155],NE:[350,1139],SE:[356,1133],NW:[372,1161]},
{SW:[378,1154],NE:[350,1138],SE:[356,1132],NW:[372,1160]},
{SW:[378,1154],NE:[351,1137],SE:[356,1132],NW:[373,1159]},
{SW:[379,1153],NE:[351,1137],SE:[357,1131],NW:[373,1159]},
{SW:[379,1153],NE:[351,1136],SE:[357,1131],NW:[373,1158]},
{SW:[379,1152],NE:[352,1136],SE:[357,1130],NW:[374,1158]},
{SW:[380,1152],NE:[352,1135],SE:[358,1130],NW:[374,1157]},
{SW:[380,1151],NE:[353,1134],SE:[358,1129],NW:[375,1156]},
{SW:[380,1150],NE:[353,1134],SE:[358,1128],NW:[375,1156]},
{SW:[381,1150],NE:[353,1133],SE:[359,1128],NW:[375,1155]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1149],NE:[354,1132],SE:[359,1127],NW:[376,1154]},
{SW:[381,1148],NE:[355,1131],SE:[359,1126],NW:[376,1153]},
{SW:[382,1147],NE:[355,1130],SE:[360,1125],NW:[377,1152]},
{SW:[382,1147],NE:[355,1129],SE:[360,1125],NW:[377,1151]},
{SW:[382,1146],NE:[356,1129],SE:[360,1124],NW:[378,1151]},
{SW:[382,1145],NE:[356,1128],SE:[360,1123],NW:[378,1150]},
{SW:[383,1145],NE:[356,1127],SE:[361,1123],NW:[378,1149]},
{SW:[383,1144],NE:[357,1126],SE:[361,1122],NW:[379,1148]},
{SW:[383,1143],NE:[357,1125],SE:[361,1121],NW:[379,1147]},
{SW:[383,1143],NE:[357,1124],SE:[361,1121],NW:[379,1146]},
{SW:[384,1142],NE:[358,1124],SE:[362,1120],NW:[380,1146]},
{SW:[384,1141],NE:[358,1123],SE:[362,1119],NW:[380,1145]},
{SW:[384,1140],NE:[358,1122],SE:[362,1118],NW:[380,1144]},
{SW:[384,1140],NE:[359,1121],SE:[362,1118],NW:[381,1143]},
{SW:[384,1139],NE:[359,1120],SE:[362,1117],NW:[381,1142]},
{SW:[385,1138],NE:[359,1119],SE:[363,1116],NW:[381,1141]},
{SW:[385,1137],NE:[360,1118],SE:[363,1115],NW:[382,1140]},
{SW:[385,1136],NE:[360,1117],SE:[363,1114],NW:[382,1139]},
{SW:[385,1136],NE:[360,1116],SE:[363,1114],NW:[382,1138]},
{SW:[385,1135],NE:[360,1116],SE:[363,1113],NW:[382,1138]},
{SW:[385,1134],NE:[361,1115],SE:[363,1112],NW:[383,1137]},
{SW:[386,1133],NE:[361,1114],SE:[364,1111],NW:[383,1136]},
{SW:[386,1132],NE:[361,1113],SE:[364,1110],NW:[383,1135]},
{SW:[386,1131],NE:[362,1112],SE:[364,1109],NW:[384,1134]},
{SW:[386,1131],NE:[362,1111],SE:[364,1109],NW:[384,1133]},
{SW:[386,1130],NE:[362,1110],SE:[364,1108],NW:[384,1132]},
{SW:[387,1129],NE:[363,1109],SE:[365,1107],NW:[385,1131]},
{SW:[387,1128],NE:[363,1108],SE:[365,1106],NW:[385,1130]},
{SW:[387,1127],NE:[363,1107],SE:[365,1105],NW:[385,1129]},
{SW:[387,1126],NE:[363,1106],SE:[365,1104],NW:[385,1128]},
{SW:[387,1125],NE:[364,1105],SE:[365,1104],NW:[386,1127]},
{SW:[387,1125],NE:[364,1104],SE:[365,1103],NW:[386,1126]},
{SW:[388,1124],NE:[364,1103],SE:[366,1102],NW:[386,1125]},
{SW:[388,1123],NE:[365,1102],SE:[366,1101],NW:[387,1124]},
{SW:[388,1122],NE:[365,1101],SE:[366,1100],NW:[387,1123]},
{SW:[388,1121],NE:[365,1100],SE:[366,1099],NW:[387,1122]},
{SW:[388,1120],NE:[365,1099],SE:[366,1098],NW:[387,1121]},
{SW:[388,1119],NE:[366,1098],SE:[366,1097],NW:[388,1120]},
{SW:[388,1119],NE:[366,1097],SE:[366,1097],NW:[388,1119]},
{SW:[389,1118],NE:[366,1096],SE:[367,1096],NW:[388,1118]},
{SW:[389,1117],NE:[367,1095],SE:[367,1095],NW:[389,1117]},
{SW:[389,1116],NE:[367,1094],SE:[367,1094],NW:[389,1116]},
{SW:[389,1115],NE:[367,1093],SE:[367,1093],NW:[389,1115]},
{SW:[389,1114],NE:[367,1092],SE:[367,1092],NW:[389,1114]},
{SW:[390,1113],NE:[368,1091],SE:[367,1091],NW:[390,1113]},
{SW:[390,1112],NE:[368,1090],SE:[368,1090],NW:[390,1112]},
{SW:[390,1112],NE:[368,1089],SE:[368,1090],NW:[390,1111]},
{SW:[390,1111],NE:[369,1088],SE:[368,1089],NW:[391,1110]},
{SW:[390,1110],NE:[369,1087],SE:[368,1088],NW:[391,1109]},
{SW:[390,1109],NE:[369,1086],SE:[368,1087],NW:[391,1108]},
{SW:[391,1108],NE:[370,1085],SE:[369,1086],NW:[392,1107]},
{SW:[391,1107],NE:[370,1084],SE:[369,1085],NW:[392,1106]},
{SW:[391,1106],NE:[370,1083],SE:[369,1085],NW:[392,1105]},
{SW:[391,1106],NE:[371,1082],SE:[369,1084],NW:[393,1104]},
{SW:[391,1105],NE:[371,1081],SE:[369,1083],NW:[393,1103]},
{SW:[392,1104],NE:[371,1080],SE:[370,1082],NW:[393,1102]},
{SW:[392,1103],NE:[372,1079],SE:[370,1081],NW:[394,1101]},
{SW:[392,1102],NE:[372,1079],SE:[370,1080],NW:[394,1101]},
{SW:[392,1102],NE:[372,1078],SE:[370,1080],NW:[394,1100]},
{SW:[393,1101],NE:[373,1077],SE:[371,1079],NW:[395,1099]},
{SW:[393,1100],NE:[373,1076],SE:[371,1078],NW:[395,1098]},
{SW:[393,1099],NE:[373,1075],SE:[371,1077],NW:[395,1097]},
{SW:[393,1099],NE:[374,1074],SE:[371,1077],NW:[396,1096]},
{SW:[394,1098],NE:[374,1073],SE:[372,1076],NW:[396,1095]},
{SW:[394,1097],NE:[375,1072],SE:[372,1075],NW:[397,1094]},
{SW:[394,1096],NE:[375,1072],SE:[372,1075],NW:[397,1094]},
{SW:[395,1096],NE:[375,1071],SE:[372,1074],NW:[397,1093]},
{SW:[395,1095],NE:[376,1070],SE:[373,1073],NW:[398,1092]},
{SW:[395,1094],NE:[376,1069],SE:[373,1072],NW:[398,1091]},
{SW:[395,1094],NE:[377,1068],SE:[373,1072],NW:[399,1090]},
{SW:[396,1093],NE:[377,1068],SE:[374,1071],NW:[399,1090]},
{SW:[396,1092],NE:[378,1067],SE:[374,1071],NW:[400,1089]},
{SW:[396,1092],NE:[378,1066],SE:[374,1070],NW:[400,1088]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[397,1091],NE:[379,1065],SE:[375,1069],NW:[401,1087]},
{SW:[398,1090],NE:[379,1064],SE:[375,1068],NW:[402,1086]},
{SW:[398,1090],NE:[380,1063],SE:[376,1068],NW:[402,1085]},
{SW:[398,1089],NE:[380,1063],SE:[376,1067],NW:[402,1085]},
{SW:[399,1088],NE:[381,1062],SE:[377,1067],NW:[403,1084]},
{SW:[399,1088],NE:[382,1061],SE:[377,1066],NW:[404,1083]},
{SW:[399,1087],NE:[382,1061],SE:[377,1066],NW:[404,1083]},
{SW:[400,1087],NE:[383,1060],SE:[378,1065],NW:[405,1082]},
{SW:[400,1087],NE:[383,1060],SE:[378,1065],NW:[405,1082]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[401,1086],NE:[384,1059],SE:[379,1064],NW:[406,1081]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[402,1085],NE:[385,1058],SE:[380,1063],NW:[407,1080]},
{SW:[403,1085],NE:[386,1057],SE:[381,1063],NW:[408,1079]},
{SW:[403,1084],NE:[387,1057],SE:[381,1062],NW:[409,1079]},
{SW:[404,1084],NE:[387,1056],SE:[382,1062],NW:[409,1078]},
{SW:[404,1084],NE:[388,1056],SE:[382,1062],NW:[410,1078]},
{SW:[405,1083],NE:[388,1056],SE:[383,1061],NW:[410,1078]},
{SW:[405,1083],NE:[389,1055],SE:[383,1061],NW:[411,1077]},
{SW:[406,1083],NE:[390,1055],SE:[384,1061],NW:[412,1077]},
{SW:[406,1083],NE:[390,1055],SE:[384,1061],NW:[412,1077]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[407,1082],NE:[391,1054],SE:[385,1060],NW:[413,1076]},
{SW:[408,1082],NE:[392,1054],SE:[386,1060],NW:[414,1076]},
{SW:[408,1082],NE:[393,1054],SE:[386,1060],NW:[415,1076]},
{SW:[409,1082],NE:[393,1053],SE:[387,1060],NW:[415,1075]},
{SW:[410,1082],NE:[394,1053],SE:[388,1060],NW:[416,1075]},
{SW:[410,1082],NE:[395,1053],SE:[388,1060],NW:[417,1075]},
{SW:[411,1082],NE:[395,1053],SE:[389,1060],NW:[417,1075]},
{SW:[411,1082],NE:[396,1053],SE:[389,1060],NW:[418,1075]},
{SW:[412,1082],NE:[397,1053],SE:[390,1060],NW:[419,1075]},
{SW:[413,1082],NE:[397,1053],SE:[391,1060],NW:[419,1075]},
{SW:[413,1082],NE:[398,1053],SE:[391,1060],NW:[420,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[414,1082],NE:[399,1053],SE:[392,1060],NW:[421,1075]},
{SW:[415,1082],NE:[400,1053],SE:[393,1060],NW:[422,1075]},
{SW:[416,1082],NE:[401,1053],SE:[394,1060],NW:[423,1075]},
{SW:[416,1082],NE:[401,1053],SE:[394,1060],NW:[423,1075]},
{SW:[417,1082],NE:[402,1053],SE:[395,1060],NW:[424,1075]},
{SW:[417,1082],NE:[403,1053],SE:[395,1060],NW:[425,1075]},
{SW:[418,1083],NE:[403,1053],SE:[396,1061],NW:[425,1075]},
{SW:[419,1083],NE:[404,1053],SE:[397,1061],NW:[426,1075]},
{SW:[419,1083],NE:[405,1054],SE:[397,1061],NW:[427,1076]},
{SW:[420,1083],NE:[405,1054],SE:[398,1061],NW:[427,1076]},
{SW:[420,1084],NE:[406,1054],SE:[398,1062],NW:[428,1076]},
{SW:[421,1084],NE:[407,1054],SE:[399,1062],NW:[429,1076]},
{SW:[422,1084],NE:[407,1055],SE:[400,1062],NW:[429,1077]},
{SW:[422,1085],NE:[408,1055],SE:[400,1063],NW:[430,1077]},
{SW:[423,1085],NE:[409,1055],SE:[401,1063],NW:[431,1077]},
{SW:[423,1085],NE:[409,1056],SE:[401,1063],NW:[431,1078]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[424,1086],NE:[410,1056],SE:[402,1064],NW:[432,1078]},
{SW:[425,1087],NE:[411,1057],SE:[403,1065],NW:[433,1079]},
{SW:[426,1087],NE:[411,1057],SE:[404,1065],NW:[433,1079]},
{SW:[426,1087],NE:[412,1058],SE:[404,1065],NW:[434,1080]},
{SW:[427,1088],NE:[413,1058],SE:[405,1066],NW:[435,1080]},
{SW:[427,1088],NE:[413,1059],SE:[405,1066],NW:[435,1081]},
{SW:[428,1089],NE:[414,1059],SE:[406,1067],NW:[436,1081]},
{SW:[428,1089],NE:[414,1060],SE:[406,1067],NW:[436,1082]},
{SW:[429,1090],NE:[415,1060],SE:[407,1068],NW:[437,1082]},
{SW:[429,1090],NE:[415,1061],SE:[407,1068],NW:[437,1083]},
{SW:[430,1091],NE:[415,1061],SE:[408,1069],NW:[437,1083]},
{SW:[430,1092],NE:[416,1062],SE:[408,1070],NW:[438,1084]},
{SW:[430,1092],NE:[416,1062],SE:[408,1070],NW:[438,1084]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[431,1093],NE:[417,1063],SE:[409,1071],NW:[439,1085]},
{SW:[432,1094],NE:[417,1064],SE:[410,1072],NW:[439,1086]},
{SW:[432,1094],NE:[418,1065],SE:[410,1072],NW:[440,1087]},
];

export const GetSunInfo = (lat, lng, date = null) => {
    const d = date ? date : new Date();
    const millis = d.getTime() - StartDateUTC.getTime();
    const days = Math.round(millis / MILLIS_PER_DAY);
    const index = days % SunHawaiiData.length;
    const data = SunHawaiiData[index];
    const percents = {
        lat: (lat - MIN_LAT) / DLAT,
        lng: (lng - MIN_LNG) / DLNG,
    };
    var rise_set = [];
    for (let i = 0; i < 2; i++) {
        const interp = {
            W: data.SW[i] + percents.lat * (data.NW[i] - data.SW[i]),
            E: data.SE[i] + percents.lat * (data.NE[i] - data.SE[i]),
            N: data.NW[i] + percents.lng * (data.NE[i] - data.NW[i]),
            S: data.SW[i] + percents.lng * (data.SE[i] - data.SW[i]),
        };
        const latInterp = interp.W + percents.lng * (interp.E - interp.W);
        const lngInterp = interp.S + percents.lat * (interp.N - interp.S);
        const ave = Math.round((latInterp + lngInterp) * 0.5);
        rise_set.push(ave);
    }
    return rise_set;
};

export const GetMoonPhase = (date = null) => {
    const d = date ? date : new Date();
    const deltaMillis = d.getTime() - HalfPhaseShiftedBaselineMillis;
    const cycleMillis = deltaMillis % MoonPeriodMillis;
    const phaseIndex = Math.floor(cycleMillis / MoonPhaseMillis);
    return MoonPhases[phaseIndex];
};
