import React from 'react';
import { StyleSheet } from 'react-native';
import { Emoji } from './Emoji';
import { Image } from './Image';
import { useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { Images } from '../media';

const NAME = 'Flag';

// web does not support flag emojis
// to resolve this, flags are implemented as images on web
// the exception is the hawaii flag, which doesn't have an emoji
export const Flag = props => {

    const {
        containerStyle,
        country,
        scale, // there is a default flag size that can be scaled
    } = props;

    const { isAndroid } = useSystemState();

    const scaleFactor = scale ? scale : 1;

    Console.stack(NAME, props, { isAndroid, scaleFactor });

    // MARKMARK: emoji's should work on ios, but needs some tlc...for now, just use images
    return Optional(!isAndroid || country === 'hi',
        (
            <Image
                containerStyle={containerStyle}
                source={Images.flag[country]}
                width={Images.flag.WIDTH * scaleFactor}
                height={Images.flag.HEIGHT * scaleFactor}
            />
        ),
        (
            <Emoji
                containerStyle={containerStyle}
                value={country}
                size={Images.flag.WIDTH * scaleFactor}
                textStyle={styles.emoji}
            />
        ),
    );
};

const styles = StyleSheet.create({
    emoji: {
        textAlign: 'center',
    },
});
