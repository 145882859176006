import React, { lazy, Suspense } from 'react';
const Loader = lazy(() => import('./Loader'));

export const LazyLoader = props => {
    return (
        <Suspense
            fallback={<></>}
        >
            <Loader {...props} />
        </Suspense>
    );
};
