import React from 'react';
import { StatusBar, StyleSheet } from 'react-native';
import { ParticleEngine, PhysicsEngine, Renderer } from './engine';
import { GameEngine } from './engine/platform';
import { Console, Optional } from '../utils';
import { Gases } from '../constants';

const NAME = 'ParticlesGame';

class ParticlesGameUtils {
    static GetGases() {
        const gases = [];
        Object.keys(Gases).forEach(key => {
            const gas = Gases[key];
            if (gas.percent) {
                gases.push(gas);
            }
        });
        const percentages = gases.map(gas => gas.percent);
        return { gases, percentages };
    }
}


export class ParticlesGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            engine: null,
            particles: null,
            entities: null,
            systems: [],
        };

        Console.log(`${NAME}.constructor`, { props, state: this.state });
    }

    componentDidMount() {

        const { top, left, width, height, tScale } = this.props;

        const callbacks = {};
        const events = [];

        const { gases, percentages } = ParticlesGameUtils.GetGases();

        this.state.engine = new PhysicsEngine(top, left, width, height, callbacks, events, tScale, 0, 0, 0);
        this.state.particles = new ParticleEngine(this.state.engine, gases, percentages);
        this.state.particles.initialize();
        this.state.entities = this.state.engine.entities();
        this.state.systems = this.state.engine.systems();

        ///* eslint-disable react/no-did-mount-set-state */
        // I think this is required to force an update
        this.setState({ initialized: true });

        Console.log(`${NAME}.componentDidMount`, { state: this.state });
    }

    render() {
        const { running } = this.props;
        const { initialized, systems, entities } = this.state;
        Console.log(`${NAME}.render`, { initialized, running, systems, entities });
        return Optional(initialized, (
            <GameEngine
                style={styles.game}
                systems={systems}
                entities={entities}
                running={running}
                renderer={Renderer}
            >
                <StatusBar hidden={true} />
            </GameEngine>
        ));
    }
}

const styles = StyleSheet.create({
    game: {
        flex: 1,
        width: '100%',
        height: '100%',
    },
});
