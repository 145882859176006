import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { Console, Optional } from '../../utils';

const NAME = 'VideoPlayer.web';


export const VideoPlayer = props => {

  const {
    source,
    youtube,
    muted,
    onEnded,
    play,
    setPlayRef,
    width,
    height,
  } = props;

  const _props = {
    playing: play || muted,
    muted,
    width,
    height,
    controls: false,
    onPause: () => {
      setPlayRef.current(false);
    },
    onEnded: () => {
      setPlayRef.current(false);
      if (onEnded) {
        onEnded();
      }
    },
    onError: e => {
      Console.error(`${NAME}.onError`, e);
    },
  };

  Console.stack(NAME, props, { _props });

  return Optional(youtube,
    (
      <ReactPlayer
        {..._props}
        url={`http://www.youtube.com/watch?v=${source}`}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload nofullscreen',
              disablePictureInPicture: true,
            },
          },
          youtube: {
            playerVars: {
              cc_load_policy: 1,
              fs: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              rel: 0,
              controls: 1,
            },
          },
        }}
      />
    ),
    (
      <ReactPlayer
        {..._props}
        url={source}
      />
    ));
};
