import { colors } from './colors';


const BasePos = {
    color: colors.transparent,
    //fontWeight: 'bold',
    //fontStyle: 'normal',
    _scale: 1.0,
};

export const Pos = new Map([
    [ 'root',
        { ...BasePos, _scale: 1.5, info: 'root' } ],
    [ 'unknown',
        { ...BasePos, color: colors.gray, info: 'unknown' } ],
    [ 'noun',
        { ...BasePos, color: colors.blue, info: 'noun' } ],
    [ 'verb',
        { ...BasePos, color: colors.red, info: 'verb' } ],
    [ 'adjective',
        { ...BasePos, color: colors.fuchsia, info: 'adj' } ],
    [ 'adverb',
        { ...BasePos, color: colors.lime, info: 'adv' } ],
]);

const SOLID = 1;
const DASH = [5, 5];
const DOTS = [1, 5];
const DASH_DOT = [5, 5, 1, 5];

export const BaseRelation = {
    stroke: colors.transparent,
    strokeWidth: '1',
    strokeOpacity: '1',
    strokeDasharray: SOLID,
    strokeDashoffset: 0,
    strokeLinecap: 'square', // 'butt' | 'square' | 'round'
    strokeLinejoin: 'miter', // 'miter' | 'bevel' | 'round'
    strokeMiterlimit: 1,
    info: 'UNKNOWN',
};

export const Relation = new Map([
    [ 'Sense',
        { ...BaseRelation, heading: 'Synonyms', info: 'synonym', icon: ['equal', 'equal'] } ],

    [ 'Domain',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DOTS, heading: 'Domains', info: 'domain', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_member',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DOTS, heading: 'Domain_Members', info: 'domain member', icon: ['folder', 'folder-outline'] } ],
    [ 'Instance',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DASH, heading: 'Instances', info: 'instance', icon: ['folder', 'folder-outline'] } ],
    [ 'Member',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DASH, heading: 'Members', info: 'member', icon: ['folder', 'folder-outline'] } ],
    [ 'Substance',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DASH, heading: 'Substances', info: 'substance', icon: ['folder', 'folder-outline'] } ],
    [ 'Part',
        { ...BaseRelation, stroke: colors.gray, strokeDasharray: DASH, heading: 'Parts', info: 'part', icon: ['folder', 'folder-outline'] } ],

    [ 'Antonym',
        { ...BaseRelation, stroke: colors.red, heading: 'Opposites', info: 'opposite', icon: ['folder', 'folder-outline'] } ],

    [ 'Hypernym',
        { ...BaseRelation, stroke: colors.blue, strokeDasharray: SOLID, heading: 'Generalizations', info: 'generalization', icon: ['hand-pointing-up', 'hand-pointing-up'] } ],
    [ 'Hypernym_Instance',
        { ...BaseRelation, stroke: colors.blue, strokeDasharray: DASH, heading: 'Generalized_Instances', info: 'generalized instance', icon: ['folder', 'folder-outline'] } ],

    [ 'Hyponym',
        { ...BaseRelation, stroke: colors.lime, strokeDasharray: SOLID, heading: 'Specializations', info: 'specialization', icon: ['folder', 'folder-outline'] } ],
    [ 'Hyponym_Member',
        { ...BaseRelation, stroke: colors.lime, strokeDasharray: DASH, heading: 'Specialized_Members', info: 'specialized member', icon: ['folder', 'folder-outline'] } ],
    [ 'Hyponym_Part',
        { ...BaseRelation, stroke: colors.lime, strokeDasharray: DOTS, heading: 'Specialized_Parts', info: 'specialized part', icon: ['folder', 'folder-outline'] } ],
    [ 'Hyponym_Substance',
        { ...BaseRelation, stroke: colors.lime, strokeDasharray: DASH_DOT, heading: 'Specialized_Substances', info: 'specialized substance', icon: ['folder', 'folder-outline'] } ],

    [ 'Meronym',
        { ...BaseRelation, stroke: colors.magenta, strokeDasharray: SOLID, heading: 'Components', info: 'component', icon: ['folder', 'folder-outline'] } ],
    [ 'Meronym_Member',
        { ...BaseRelation, stroke: colors.magenta, strokeDasharray: DASH, heading: 'Component_Members', info: 'component member', icon: ['folder', 'folder-outline'] } ],
    [ 'Meronym_Part',
        { ...BaseRelation, stroke: colors.magenta, strokeDasharray: DOTS, heading: 'Component_Parts', info: 'component part', icon: ['basket-fill', 'basket-fill'] } ],
    [ 'Meronym_Substance',
        { ...BaseRelation, stroke: colors.magenta, strokeDasharray: DASH_DOT, heading: 'Component_Substances', info: 'component substance', icon: ['folder', 'folder-outline'] } ],

    [ 'Region',
        { ...BaseRelation, stroke: colors.orange, strokeDasharray: SOLID, heading: 'Regions', info: 'regions', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_Region',
        { ...BaseRelation, stroke: colors.orange, strokeDasharray: DASH, heading: 'Domain_Regions', info: 'domain region', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_member_Region',
        { ...BaseRelation, stroke: colors.orange, strokeDasharray: DASH_DOT, heading: 'Domain_Member_Regions', info: 'domain member region', icon: ['folder', 'folder-outline'] } ],

    [ 'Usage',
        { ...BaseRelation, stroke: colors.pink, strokeDasharray: SOLID, heading: 'Usages', info: 'usage', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_Usage',
        { ...BaseRelation, stroke: colors.pink, strokeDasharray: DASH, heading: 'Domain_Usages', info: 'domain usage', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_member_Usage',
        { ...BaseRelation, stroke: colors.pink, strokeDasharray: DASH_DOT, heading: 'Domain_Member_Usages', info: 'domain member usage', icon: ['folder', 'folder-outline'] } ],

    [ 'Topic',
        { ...BaseRelation, stroke: colors.purple, strokeDasharray: SOLID, heading: 'Topics', info: 'topic', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_Topic',
        { ...BaseRelation, stroke: colors.purple, strokeDasharray: DASH, heading: 'Domain_Topics', info: 'domain topic', icon: ['folder', 'folder-outline'] } ],
    [ 'Domain_member_Topic',
        { ...BaseRelation, stroke: colors.purple, strokeDasharray: DASH_DOT, heading: 'Domain_Member_Topics', info: 'domain member topic', icon: ['folder', 'folder-outline'] } ],

    [ 'Attribute',
        { ...BaseRelation, stroke: colors.fuchsia, strokeDasharray: SOLID, heading: 'Attributes', info: 'attribute', icon: ['folder', 'folder-outline'] } ],
    [ 'Entailment',
        { ...BaseRelation, stroke: colors.fuchsia, strokeDasharray: DASH, heading: 'Entailments', info: 'entailment', icon: ['folder', 'folder-outline'] } ],
    [ 'Cause',
        { ...BaseRelation, stroke: colors.fuchsia, strokeDasharray: DOTS, heading: 'Causes', info: 'cause', icon: ['folder', 'folder-outline'] } ],
    [ 'Derivation',
        { ...BaseRelation, stroke: colors.fuchsia, strokeDasharray: DASH_DOT, heading: 'Derivations', info: 'derivation', icon: ['hand-pointing-down', 'hand-pointing-down'] } ],

    [ 'Also_see',
        { ...BaseRelation, stroke: colors.green, strokeDasharray: DOTS, heading: 'See_Also', info: 'see also', icon: ['eye', 'eye-outline'] } ],
    [ 'Similar',
        { ...BaseRelation, stroke: colors.green, strokeDasharray: DASH, heading: 'Similar', info: 'similar', icon: ['approximately-equal', 'approximately-equal'] } ],

    [ 'Adj_pertainym',
        { ...BaseRelation, stroke: colors.green, strokeDasharray: DASH_DOT, heading: 'Pertains', info: 'pertains', icon: ['folder', 'folder-outline'] } ],

    [ 'Adv_derivation',
        { ...BaseRelation, stroke: colors.maroon, strokeDasharray: DOTS, heading: 'Derivations', info: 'derivation', icon: ['hand-pointing-down', 'hand-pointing-down'] } ],
    [ 'Adv_derivation_Instance',
        { ...BaseRelation, stroke: colors.maroon, strokeDasharray: DASH_DOT, heading: 'Derived_Instances', info: 'derived instance', icon: ['folder', 'folder-outline'] } ],

    [ 'Verb_group',
        { ...BaseRelation, stroke: colors.maroon, strokeDasharray: SOLID, heading: 'Verb_Groups', info: 'verb group', icon: ['folder', 'folder-outline'] } ],
    [ 'Verb_participle',
        { ...BaseRelation, stroke: colors.maroon, strokeDasharray: DASH, heading: 'Verb_Participles', info: 'verb participle', icon: ['folder', 'folder-outline'] } ],

    [ 'Lemma_root',
        { ...BaseRelation, stroke: colors.red, strokeDasharray: DASH_DOT, heading: 'Lemmas', info: 'lemma', icon: ['folder', 'folder-outline'] } ],
    [ 'Lemma_member',
        { ...BaseRelation, stroke: colors.red, strokeDasharray: DOTS, heading: 'Lemma_Members', info: 'lemma member', icon: ['folder', 'folder-outline'] } ],
]);
