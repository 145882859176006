export { languageColors } from './languageColors';
export { Instruments, Notes440 } from './music';
export { Gases } from './physics';
import { languages, languageSynonyms, localeLookup } from './languages';

export const DEV_MODE = process.env.NODE_ENV === 'development';
export const USE_DEV_COLOR = false && DEV_MODE;

export const DEV_USER = 'mark';

const ENABLE_LOGGING = false;
export const VERBOSE = {
    ENABLED: ENABLE_LOGGING,
    STACK: ENABLE_LOGGING,
    TRACE: false,//ENABLE_LOGGING,
};

export const APP_NAME = 'DaʻApp Hawaiʻi';
export const LANGUAGES = languages;
export const LANGUAGE_SYNONYMS = languageSynonyms;
export const LOCALE_LOOKUP = localeLookup;

const ButtonWidth = 30;
const ButtonHeight = 48;
const ButtonRadius = 4;
const ButtonMarginVertical = 4;

export const LANGUAGE = 'en_us';
export const NAVIGATOR = 'BottomTab';
export const AUDIO_DEFAULT = true;
export const STD_HEIGHT = 725;
export const STD_WIDTH = 360;
export const STD_ASPECT = STD_HEIGHT / STD_WIDTH;
export const MAX_FILENAME_LEN = 120;
export const WAV_FILENAME = 'voice.wav';
export const MIN_RECORDING_SECONDS = 1.0;
export const MAX_RECORDING_SECONDS = 15.0;//7.5; // request 4.01, get 4.69, so adjust ask by 32

export const ANIMATED_USE_NATIVE_DRIVER = false;

export const DEFAULT = {
    HEADER_HEIGHT: 56, // [59]
    TAB_BAR_HEIGHT: 70, // [34] 60
    ICON_SIZE: 30,
//    ICON_COLOR: '#2e64e5',
    EMOJI_SIZE: 20,
    IMAGE_SIZE: 50,
    TEXT_HEIGHT: 14,
    SIGN_SVG_SIZE: 50,
    CAMERA_WIDTH: 360,
    CAMERA_HEIGHT: 480,
    VPAD_PERCENTAGE: 0.2,
    SELECT_DISTANCE: 20,
    DRAG_BAR_HEIGHT: 10,
    PROGRESS_WIDTH: 185,
    PROGRESS_HEIGHT: 33,
    KB_BUTTON_WIDTH: ButtonWidth,
    KB_BUTTON_HEIGHT: ButtonHeight,
    KB_BUTTON_RADIUS: ButtonRadius,
    KB_PADDING_TOP: 47,
    KB_MARGIN_HZ: 2.5,
    KB_MARGIN_VT: ButtonMarginVertical,
    KB_HEIGHT: 366,
    KB_ROW_HEIGHT: ButtonHeight + 2 * ButtonMarginVertical,
    KB_COLOR_BG: 'lightgray',
    KB_BUTTON_COLOR: 'blue',
    KB_TEXT_COLOR: 'white',
    KB_FONT_SIZE: 16,
    KB_DURATION_MS: 250,
    GRAPH_DETAIL_HEIGHT: 50,
    GRAPH_INFO_HEIGHT: 200,
};

export const DEV_COLOR = color => { return color && USE_DEV_COLOR ? color : undefined; };

export const RNW = {
    WEB: 'web',
    ANDROID: 'android',
    IOS: 'ios',
    DARK: 'dark',
};

export const LINKS = {
    GitHub: 'https://github.com/acquilingua/daapphawaii',
    AWS: 'https://us-east-2.console.aws.amazon.com/amplify/home?region=us-east-2#/d380iapk1v7sfi',
    Facebook: 'https://www.facebook.com/people/DaʻApp-Hawaiʻi/100081089598730/',
    Google: 'https://drive.google.com/drive/my-drive',
    Instagram: 'https://www.instagram.com/da_app_hawaii/',
    LinkedIn: 'https://www.linkedin.com/in/al-lingua-0305b121a',
    Twitter: 'https://twitter.com/da_app_hawaii',
    YouTube: 'https://www.youtube.com/@braddahhonu|https://m.youtube.com/@braddahhonu',
    Email: 'https://privateemail.com/',
};

export const REGEX = {
    EMAIL: /^[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
    PHONE: /^(1[ -.]?|)([0-9]{3}|\([0-9]{3}\))[ -.]?[0-9]{3}[ -.]?[0-9]{4}$/,
};

//export const MARKMARK_TAB = 56;
//export const MARKMARK_STATUS = 49;
//export const MARKMARK_FUDGE = MARKMARK_TAB + MARKMARK_STATUS;

export const MAX_CONTENT_LEN = 255;

export const ACTION_SERVER = 'https://acquilingua-chat.xyz/action/';
export const S3_SERVER = 'https://s3-us-east-2.amazonaws.com/acquilingua-apk173646-demo';

/*
    iPhoneSE: {
        width: 375,
        height: 667,
        //aspect: 1.77878
    },
    iPhoneXR: {
        width: 414,
        height: 896,
        //aspect: 2.16425
    },
    iPhone12Pro: {
        width: 390,
        height: 844,
        //aspect: 2.1641
    },
    pixel5: {
        width: 393,
        height: 851,
        //aspect: 2.1654
    },
    galaxyS8: {
        width: 360,
        height: 740,
        //aspect: 2.05556
    },
    galaxyS20Ultra: {
        width: 412,
        height: 915,
        //aspect: 2.22087
    },
    galaxy: {
        width: 412,
        height: 914,
        //aspect: 2.2184
    },
    iPadAir: {
        width: 820,
        height: 1180,
        //aspect: 1.439
    },
    iPadMini: {
        width: 768,
        height: 1024,
        //aspect: 1.333
    },
    iPadMini: {
        width: 768,
        height: 1024,
        //aspect: 1.333
    },
    surfacePro7: {
        width: 912,
        height: 1368,
        //aspect: 1.5
    },
    surfaceDuo: {
        width: 540,
        height: 720,
        //aspect: 1.333
    },
*/

/*
export const ALLOW_ORIGIN = { 'Access-Control-Allow-Origin': '*' };
export const MAX_CONTENT_LEN = 255;

*/

// https://accents.dliflc.edu/spanish/result.asp?language=Spanish&level=0&topic=0&submit=Submit
// https://gloss.dliflc.edu/
