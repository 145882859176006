import { MrPotatoHead } from './engine';
import { Audio, Console, Numbers } from '../utils';
import { S3_SERVER } from '../constants';

const KRASHEN = `${S3_SERVER}/mrph/krashen.mp3`;

// https://www.youtube.com/watch?v=5bHZTtEHL20


export class MrPotatoHeadData {

    constructor() {

        this.leftCol = 35;
        this.middleCol = 180;
        this.rightCol = 325;
        this.stiffness = 0.003;
        this.damping = 0.15;
        this.constraints = new Map();
        this.bodies = new Map();
    }

    populate(engine) {
        Object.keys(MrPotatoHead).forEach(key => {
            const item = MrPotatoHead[key];
            const { path, svg, xform } = item;
            const { x, y, s } = xform;
            var options = { isStatic: true, restitution: 0.8, friction: 0.01, frictionStatic: 0.01 };
            if (key === 'head') {
                options.collisionFilter = { mask: 0 };
            }
            engine.addVertices(key, path, svg, x, y, s, null, options);
        });
    }

    get(height) {

        const { leftCol, middleCol, rightCol, stiffness, damping, constraints, bodies } = this;

        return [

            // opening
            [1.0, entities => {
                Console.log('start audio', { entities });
                Audio.Play(KRASHEN);
            }],

            // this is my hand
            [2500.0, entities => {
                const element = 'hand';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '🖐️', leftCol, 0, 100, 50, 'red', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            // this is my head
            [11000.0, entities => {
                const element = 'baldHead';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '👨‍🦲', rightCol, 0, 100, 50, 'red', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            // swap in inert mustache to nose and mouth don't go crazy
            [12000.0, entities => {
                const element = 'mustache';
                const tempElement = 'mustacheTemp';
                Console.log(element, { entities });
                entities.physics.system.setPosition(element, { x: -500, y: -500 });
                const entity = entities.physics.system.addVertices(tempElement,
                    MrPotatoHead.mustache.path,
                    MrPotatoHead.mustache.svg,
                    MrPotatoHead.mustache.xform.x,
                    MrPotatoHead.mustache.xform.y,
                    MrPotatoHead.mustache.xform.s,
                    null,
                    { isStatic: true, collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            [12500.0, entities => {
                const leftElement = 'leftEar';
                const rightElement = 'rightEar';
                Console.log('drop ears', { entities });
                entities.physics.system.setStatic(leftElement, false);
                entities.physics.system.setVelocity(leftElement, { x: 0.5, y: -3 });
                entities.physics.system.setAngularVelocity(leftElement, 0.035);
                entities.physics.system.setStatic(rightElement, false);
                entities.physics.system.setVelocity(rightElement, { x: -0.5, y: -3 });
                entities.physics.system.setAngularVelocity(rightElement, -0.035);
            }],

            [13500.0, entities => {
                const leftElement = 'leftEye';
                const rightElement = 'rightEye';
                Console.log('drop eyes', { entities });
                entities.physics.system.setStatic(leftElement, false);
                entities.physics.system.setVelocity(leftElement, { x: 0.5, y: -1 });
                entities.physics.system.setAngularVelocity(leftElement, 0.25);
                entities.physics.system.setStatic(rightElement, false);
                entities.physics.system.setVelocity(rightElement, { x: -0.5, y: -1 });
                entities.physics.system.setAngularVelocity(rightElement, -0.25);
            }],

            [14250.0, entities => {
                const element = 'hat';
                Console.log(element, { entities });
                entities.physics.system.setVelocity(element, { x: -0.1, y: 0 });
                entities.physics.system.setStatic(element, false);
            }],

            [14500.0, entities => {
                const element = 'nose';
                Console.log(element, { entities });
                entities.physics.system.setStatic(element, false);
                entities.physics.system.setVelocity(element, { x: 0, y: 0 });
            }],

            [14750.0, entities => {
                const element = 'mouth';
                Console.log(element, { entities });
                entities.physics.system.setStatic(element, false);
            }],

            [15000.0, entities => {
                const element = 'mustache';
                const tempElement = 'mustacheTemp';
                Console.log(element, { entities });
                entities.physics.system.remove(tempElement);
                delete entities[tempElement];
                bodies.delete(tempElement);
                const { x, y } = MrPotatoHead.mustache.xform;
                entities.physics.system.setPosition(element, { x, y });
                entities.physics.system.setVelocity(element, { x: 0.1, y: 0 });
                entities.physics.system.setStatic(element, false);
            }],

            [18000.0, entities => {
                const hat = 'hat';
                const nose = 'nose';
                const mustache = 'mustache';
                Console.log('constrain face', { entities });
                constraints.set('hat', entities.physics.system.addConstraint(entities[hat].body, { x: leftCol, y: height }, stiffness / 2, damping));
                constraints.set('nose', entities.physics.system.addConstraint(entities[nose].body, { x: middleCol, y: height }, stiffness / 2, damping));
                constraints.set('mustache', entities.physics.system.addConstraint(entities[mustache].body, { x: rightCol, y: height }, stiffness / 2, damping));
            }],

            [21500.0, entities => {
                const element = 'baldHead';
                Console.log(element, { entities });
                entities.physics.system.setPosition(element, { x: leftCol, y: 0 });
                entities.physics.system.setVelocity(element, { x: 0, y: 0 });
            }],


            [24000.0, entities => {
                const element = 'rightEar';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.rightEar.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body, { x, y }, stiffness, damping));
            }],
            [24250.0, entities => {
                const element = 'rightEar';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
            }],

            [26000.0, entities => {
                const element = 'leftEar';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.leftEar.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body, { x, y }, stiffness, damping));
            }],
            [26250.0, entities => {
                const element = 'leftEar';
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
            }],

            [26500.0, entities => {
                const element = 'baldHead';
                Console.log(element, { entities });
                entities.physics.system.setPosition(element, { x: rightCol, y: 0 });
                entities.physics.system.setVelocity(element, { x: 0, y: 0 });
            }],

            [29000.0, entities => {
                const element = 'mrSpock';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '🖖🏻', leftCol, 0, 100, 50, 'green', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [32500.0, entities => {
                const element = 'ear1';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '2️', 90, 0, 100, 50, 'blue', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [33000.0, entities => {
                const element = 'ear2';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '👂', 270, 0, 100, 50, 'red', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [36500.0, entities => {
                const element = 'two';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '2️', rightCol, 0, 100, 50, 'green', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [37000.0, entities => {
                const element = 'ear3';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '👂', rightCol, 0, 100, 50, 'green', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [37500.0, entities => {
                Console.log('freeze ears', { entities });
                entities.physics.system.setStatic('rightEar', true);
                entities.physics.system.setStatic('leftEar', true);
            }],

            [43000.0, entities => {
                const element = 'rightEye';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.rightEye.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body, { x, y }, stiffness, damping));
            }],
            [44250.0, entities => {
                const element = 'rightEye';
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
            }],
            [44500.0, entities => {
                const element = 'eye1';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '👁️', leftCol, 0, 100, 50, 'green', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            [48000.0, entities => {
                const element = 'leftEye';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.leftEye.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body, { x, y }, stiffness, damping));
            }],
            [49250.0, entities => {
                const element = 'leftEye';
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
            }],
            [49500.0, entities => {
                const element = 'eye2';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '👀', leftCol, 0, 100, 50, 'green', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            [53000.0, entities => {
                const element = 'thirdEye';
                Console.log(element, { entities });
                const entity = entities.physics.system.addVertices(element,
                    MrPotatoHead.rightEye.path,
                    MrPotatoHead.rightEye.svg,
                    100,
                    50,
                    MrPotatoHead.rightEye.xform.s);
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);

                entities.physics.system.setAngle(element, Numbers.toRadians(90));
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.rightEye.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body,
                    { x: x + 25, y: y - 50 }, stiffness, damping));
            }],
            [54000.0, entities => {
                const element = 'three';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '3️?', leftCol, 0, 100, 50, 'blue', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            [55750.0, entities => {
                const leftElement = 'leftEye';
                const rightElement = 'rightEye';
                Console.log('freeze eyes', { entities });
                entities.physics.system.setStatic(leftElement, true);
                entities.physics.system.setStatic(rightElement, true);
            }],
            [56000.0, entities => {
                const element = 'thirdEye';
                Console.log(`free ${element}`, { entities });
                entities.physics.system.remove(constraints.get(element));
                entities.physics.system.setStatic(element, false);
            }],
            [57000.0, entities => {
                const element = 'thirdEye';
                Console.log(`kick ${element}`, { entities });
                entities.physics.system.setVelocity(element, { x: 3.5, y: -4 });
                entities.physics.system.setAngularVelocity(element, 0.35);
            }],
            [59000.0, entities => {
                const element = 'no';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '🚫', rightCol, 0, 100, 50, 'red', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [59500.0, entities => {
                const element = 'twoDammit';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '2️!', rightCol - 10, 0, 100, 50, 'red', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [60000.0, entities => {
                const element = 'mouth';
                Console.log(element, { entities });
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
                entities.physics.system.setInertia(element, Infinity);
                const { x, y } = MrPotatoHead.mouth.xform;
                constraints.set(element, entities.physics.system.addConstraint(entities[element].body, { x, y }, stiffness, damping));
            }],
            [64000.0, entities => {
                const element = 'mouth';
                entities.physics.system.setAngle(element, 0);
                entities.physics.system.setAngularVelocity(element, 0);
            }],
            [64500.0, entities => {
                const element = 'mouth';
                Console.log(`freeze ${element}`, { entities });
                entities.physics.system.setStatic(element, true);
            }],
            [65500.0, entities => {
                const element = 'cigarette';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '🚬', middleCol - 35, 270, 100, 50, 'green', { isStatic: true, collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [68000.0, entities => {
                const element = 'noSmoking';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, '🚭', middleCol, 0, 100, 50, 'blue', { collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
                entities.physics.system.setStatic('cigarette', false);
            }],
            [68500.0, entities => {
                Console.log('drop face', { entities });
                constraints.forEach(constraint => {
                    entities.physics.system.remove(constraint);
                });
                entities.physics.system.setStatic('leftEar', false);
                entities.physics.system.setStatic('rightEar', false);
                entities.physics.system.setStatic('leftEye', false);
                entities.physics.system.setStatic('rightEye', false);
                entities.physics.system.setStatic('mouth', false);

                entities.physics.system.setAngularVelocity('leftEar', 0.15);
                entities.physics.system.setAngularVelocity('rightEar', -0.15);
                entities.physics.system.setAngularVelocity('leftEye', -0.25);
                entities.physics.system.setAngularVelocity('rightEye', 0.25);
                entities.physics.system.setAngularVelocity('mouth', 0.05);

            }],
            [72000.0, entities => {
                const element = 'head';
                Console.log(`drop ${element}`, { entities });
                entities.physics.system.remove(element);
                const entity = entities.physics.system.addVertices(element,
                    MrPotatoHead.head.path,
                    MrPotatoHead.head.svg,
                    MrPotatoHead.head.xform.x,
                    MrPotatoHead.head.xform.y,
                    MrPotatoHead.head.xform.s);
                entities[entity.id] = entity.body;
                entities.physics.system.setVelocity(element, { x: 0.05, y: -0.1 });
                entities.physics.system.setAngularVelocity(element, 0.01);
            }],

            [101500.0, entities => {
                const element = 'comprehensible';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, element, middleCol, 0, 100, 50, 'green', { isStatic: false, collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],
            [103000.0, entities => {
                const element = 'input';
                Console.log(element, { entities });
                const entity = entities.physics.system.addText(element, element, middleCol, 0, 100, 50, 'green', { isStatic: false, collisionFilter: { mask: 0 } });
                entities[entity.id] = entity.body;
                bodies.set(entity.id, entity.body);
            }],

            [104500.0, entities => {
                Console.log('cleanup', { entities });
                bodies.forEach(item => {
                    entities.physics.system.remove(item);
                    delete entities[item];
                });
                bodies.clear();
            }],
        ];
    }
}
