import { API } from '../aws';
import { Base64, Console } from '../utils';
import { models } from './models';

const NAME = 'stt';

export const stt = async (lang, user, data) => {

    if (!data?.length) {
        Console.warn(`${NAME} bad input`);
        return '';
    }

    try {
        Console.log(`${NAME} enter`, { lang, user, data: data?.length });
        const base64 = Base64.FromArrayBuffer(data);
        Console.log(`${NAME} base64`, { base64: base64?.length });

        const body = {
            command: 'stt',
            models,
            doTranslate: false,
            lang,
            user,
            base64,
        };

        var result = await API.chat(body);
        Console.LOG(`${NAME} (${lang}, ${user}): [${result?.message}]`);

        // return empty string if the content was flagged
        if (result?.message && result.message.startsWith('flagged')) {
            Console.LOG(`${NAME} warning: [${result.message}]`);
            return '';
        }

        return result?.message;

    } catch (error) {
        Console.error(`${NAME} error`, error);
        return '';
    }
};
