export const Gases = {
    'Xe': {
        name: 'Xenon',
        particleSize: 6,
        color: 'cyan',
        mass: 131.293,
        percent: 0,
    },
    'C02': {
        name: 'Carbon dioxide',
        particleSize: 4.5,
        color: 'red',
        mass: 44.009500,
        percent: 0.036,
    },
    'O2': {
        name: 'Oxygen',
        particleSize: 4,
        color: 'green',
        mass: 31.9988,
        percent: 20.95,
    },
    'N2': {
        name: 'Nitrogen',
        particleSize: 3.5,
        color: 'gray',
        mass: 28.0134,
        percent: 78.08,
    },
    'H2O': {
        name: 'Water',
        particleSize: 3,
        color: 'blue',
        mass: 18.015280,
        percent: 0.934,
    },
    'NH3': {
        name: 'Ammonia',
        particleSize: 3,
        color: 'orange',
        mass: 17.03052,
        percent: 0,
    },
    'CH4': {
        name: 'Methane',
        particleSize: 3,
        color: 'brown',
        mass: 16.042460,
        percent: 0,
    },
    'He': {
        name: 'Helium',
        particleSize: 2.5,
        color: 'pink',
        mass: 4.002602,
        percent: 0,
    },
    'H2': {
        name: 'Hydrogen',
        particleSize: 2.5,
        color: 'magenta',
        mass: 2.01588,
        percent: 0,
    },
};
