import React from 'react';
import { Caption, Headline, HelperText, Paragraph, Subheading, Text as RNPText, Title } from 'react-native-paper';
import { useAppState, useSystemState } from '../context';
import { Console, Numbers, Optional, Validate } from '../utils';
import { TextStyles } from '../styles';

const NAME = 'Text';

export const TEXT_VARIANTS = {
    CAPTION: 'caption',
    ERROR: 'error',
    HEADLINE: 'headline',
    INFO: 'info',
    PARAGRAPH: 'paragraph',
    SUBHEADING: 'subheading',
    TEXT: 'text',
    TITLE: 'title',
};

const DEFAULT_VARIANT = TEXT_VARIANTS.TEXT;

const TextMap = new Map([
    [TEXT_VARIANTS.CAPTION, { Component: Caption, style: TextStyles.caption }],
    [TEXT_VARIANTS.ERROR, { Component: HelperText, style: TextStyles.helperText }],
    [TEXT_VARIANTS.HEADLINE, { Component: Headline, style: TextStyles.headline }],
    [TEXT_VARIANTS.INFO, { Component: HelperText, style: TextStyles.helperText }],
    [TEXT_VARIANTS.PARAGRAPH, { Component: Paragraph, style: TextStyles.paragraph }],
    [TEXT_VARIANTS.SUBHEADING, { Component: Subheading, style: TextStyles.subheading }],
    [TEXT_VARIANTS.TEXT, { Component: RNPText, style: TextStyles.paragraph }],
    [TEXT_VARIANTS.TITLE, { Component: Title, style: TextStyles.title }],
]);


export const Text = props => {

    const {
        value,
        variant,
        color,
        noTranslate,
        selectable,
        onPress,
        textAlign,
        // style, NOTATION
    } = props;

    const { deviceScale } = useSystemState();
    const { t } = useAppState();

    const { Component, style } = TextMap.get(Validate.isValidNonEmptyString(variant) ? variant : DEFAULT_VARIANT);
    const fontStyle = Numbers.ReScaleStyle(style, deviceScale);

    const xValue = noTranslate ? value : t(value);
    const helperType = variant === TEXT_VARIANTS.ERROR || variant === TEXT_VARIANTS.INFO ? variant : null;
    const visible = !Validate.isValid(helperType) || Validate.isValidNonEmptyString(xValue);
    const colorStyle = Validate.isValid(color) ? { color } : {};

    Console.stack(`${NAME}[${xValue}]`, props, { deviceScale, style, fontStyle });

    return Optional(helperType || xValue, (
        <Component
            style={[fontStyle, props.style, colorStyle]}
            textAlign={textAlign}
            type={helperType}
            visible={visible}
            selectable={Validate.isValid(selectable) ? selectable : false}
            allowFontScaling={false}
            onPress={onPress}
        >
            {xValue}
        </Component>
    ));
};
