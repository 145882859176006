import React from 'react';
import { Text } from './Text';
import { View } from './View';
import { GetEmoji } from './utils';
import { useAppState, useSystemState } from '../context';
import { Console, Validate } from '../utils';

const NAME = 'Emoji';

export { GetEmoji } from './utils';

export const Emoji = props => {

    const {
        emoji,
        value,
        size,
        containerStyle,
        textStyle,
    } = props;

    const { deviceScale } = useSystemState();
    const { DEFAULT } = useAppState();
    const { EMOJI_SIZE } = DEFAULT;

    const fontSize = deviceScale * (size ? size : EMOJI_SIZE);
    const sizeStyle = {
        fontSize,
        lineHeight: fontSize * 1.15,
    };

    const emojiValue = Validate.isValid(emoji) ? emoji : GetEmoji(value);
    const missingEmoji = '';//`UNDEFINED EMOJI ${value}`;

    Console.stack(NAME, props, { EMOJI_SIZE, deviceScale, emojiValue, containerStyle, textStyle, sizeStyle });

    return (
        <View
            value={'EmojiView'}
            style={containerStyle}
        >
            <Text
                style={[sizeStyle, textStyle]}
                value={Validate.isValid(emojiValue) ? emojiValue : missingEmoji}
            />
        </View>
    );
};
