import { useCallback, useMemo, useRef } from 'react';
import { useAppDispatch, useAppState, APP_TYPES } from '../context';
import { Console } from '../utils';

const NAME = 'useKeyboard';


export const useKeyboard = () => {

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { kbVisible, kbInput, ipaKeyboard } = useAppState();

    const setKbVisible = useCallback(
        payload => {
            Console.devLog(`${NAME}.setKbVisible`, { payload });
            appDispatchRef.current({ type: APP_TYPES.SET_KB_VISIBLE, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const setKbInput = useCallback(
        payload => {
            Console.devLog(`${NAME}.setKbInput`, { payload });
            appDispatchRef.current({ type: APP_TYPES.SET_KB_INPUT, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const setIpaKeyboard = useCallback(
        payload => {
            Console.devLog(`${NAME}.setIpaKeyboard`, { payload });
            appDispatchRef.current({ type: APP_TYPES.SET_IPA_KEYBOARD, payload });
        },
        [
            appDispatchRef,
        ],
    );

    const result = useMemo(
        () => {
            Console.log(`${NAME} render`, { kbVisible, kbInput, ipaKeyboard });
            return {
                kbVisible,
                kbInput,
                ipaKeyboard,
                setKbVisible,
                setKbInput,
                setIpaKeyboard,
            };
        },
        [
            kbVisible,
            kbInput,
            ipaKeyboard,
            setKbVisible,
            setKbInput,
            setIpaKeyboard,
        ],
    );

    Console.stack(`${NAME} result`, { result });
    return result;
};
