let ICON_FONT = null;

export class IconFont {
    static Set(iconFont) {
        if (!ICON_FONT && iconFont) {
            ICON_FONT = iconFont;
        }
    }

    static Get() {
        return ICON_FONT;
    }
}
