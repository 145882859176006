import React, { createContext, useContext, useReducer } from 'react';
import { Array2Object, Console } from '../utils';

const types = [
  'UNSET_MESSAGES',
  'SET_MESSAGES',
  'ADD_MESSAGE',
  'ADD_REACTION',
];
export const TYPES = Array2Object(types);

const MessagesStateContext = createContext();
const MessagesDispatchContext = createContext();

const messagesState = {
  messages: [],
  messageCount: 0,
};

const messagesReducer = (state, action) => {
  const { type, payload } = action;
  var result = {};

  switch (type) {

    case TYPES.UNSET_MESSAGES:
      result = {
        ...state,
        messages: [],
        messageCount: 0,
      };
      break;

    case TYPES.SET_MESSAGES:
      result = {
        ...state,
        messages: payload ? payload : [],
        messageCount: payload?.length ? payload.length : 0,
      };
      break;

    case TYPES.ADD_MESSAGE:
      payload.reactions = [];
      result = {
        ...state,
        messages: state.messages ? [ payload, ...state.messages ] : [ payload ],
        messageCount: state.messageCount + 1,
      };
      break;

    case TYPES.ADD_REACTION:
      // find the referenced message, and bail if not found
      const messageIndex = state.messages.findIndex(message => message.id === payload.message.id);
      if (messageIndex < 0) {
        throw new Error(`messagesReducer: Reaction message ${payload.message.id} not found.`);
      }
      // still here? make a copy of the messages and the referenced message's reactions
      let messagesCopy = [...state.messages];
      let reactionsCopy = [...messagesCopy[messageIndex].reactions];
      // look for this reaction...if found, update, otherwise, add it to the reactions
      const reactionIndex = reactionsCopy.findIndex(reaction => reaction.id === payload.id);
      if (reactionIndex > -1) {
        reactionsCopy[reactionIndex] = payload;
      } else {
        reactionsCopy = [...reactionsCopy, payload];
      }
      // update the message's reactions
      messagesCopy[messageIndex] = {...messagesCopy[messageIndex], reactions: reactionsCopy};
      result = { ...state, messages: messagesCopy };
      break;

    default:
      throw new Error(`messagesReducer: Unhandled action "${type}".`);
  }

  Console.LOG(`messagesReducer[${type}] (${JSON.stringify(payload)})`);
  Console.trace('messagesReducer', { state, result });

  return result;
};

const NAME = 'MessagesProvider';


export const MessagesProvider = props => {

  const {
    children,
  } = props;

  const [ state, dispatch ] = useReducer(messagesReducer, messagesState);

  Console.stack(NAME, props, { state });

  return (
    <MessagesStateContext.Provider value={state} >
      <MessagesDispatchContext.Provider value={dispatch} >
        {children}
       </MessagesDispatchContext.Provider>
    </MessagesStateContext.Provider>
  );
};

export const useMessagesState = () => useContext(MessagesStateContext);
export const useMessagesDispatch = () => useContext(MessagesDispatchContext);
