import * as Colors from './colors';
export { Colors };

import * as Styles from './styles';
export { Styles };

import * as Text from './text';
export { Text };

export { TextStyles } from './text';

import * as Words from './words';
export { Words };
