const auth = require('./auth.json');
const camera = require('./camera.json');
const emojis = require('./emojis.json');
const features = require('./features.json');
const featureDescriptions = require('./featureDescriptions.json');
//const help = require('./help.json');
const languages = require('./languages.json');
const navigation = require('./navigation.json');
const translation = require('./translation.json');
const weather = require('./weather.json');
const oahu = require('./oahu.json');

export default {
    'translation': {
        ...auth,
        ...camera,
        ...emojis,
        ...features,
        ...featureDescriptions,
//        ...help,
        ...languages,
        ...navigation,
        ...translation,
        ...weather,
        ...oahu,
    },
};
