import { Chat, Games, LookCamera, Users } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Home,

    TabArray: ['Users', 'Games', 'Camera'],

    Tabs: {
        Users: {
            title: 'Chat',
            Component: Users,
            Icon: Icons.Users,
            children: new Set(['Chat']),
        },
        Games: {
            Component: Games,
            Icon: Icons.Games,
        },
        Camera: {
            Component: LookCamera,
            Icon: Icons.Camera,
        },
    },

    Stacks: {
        Chat: {
            Component: Chat,
        },
    },

};
