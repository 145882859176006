import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Text, View, Screen, TEXT_VARIANTS } from '../components';
import { Console, Optional } from '../utils';
import { useAppState, useSystemState } from '../context';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';
import { NavParams } from './utils';

const NAME = 'MapDirectionsDetail';


export const MapDirectionsDetail = props => {

    const {
        navigation,
        route,
    } = props;

    const { adjHeight, width } = useSystemState();
    const { dark, language, showAds } = useAppState();

    const [data] = useState(NavParams(route)?.data);
    const [title] = useState(NavParams(route)?.title);

    useEffect(
        () => {
            navigation.setOptions({
                headerTitle: ({ tintColor }) => (
                    <Text
                        value={title}
                        color={tintColor}
                        variant={title?.length > 20 ? TEXT_VARIANTS.SUBHEADING : TEXT_VARIANTS.TITLE}
                    />
                ),
            });
        },
        [
            title,
            navigation,
        ],
    );

    Console.devStack(NAME, props, { adjHeight, width, dark, showAds, data });

    return useMemo(
        () => {
            const titleStyle =  {
                width,
                height: showAds ? adjHeight - BANNER_AD_HEIGHT : adjHeight,
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-evenly',
            };
            Console.devLog(`${NAME} render`, { dark, language, data, showAds });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <View
                        style={titleStyle}
                    >
                        <Text
                            value={'Hello world'}
                            variant={TEXT_VARIANTS.TITLE}
                        />
                    </View>
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            dark,
            language,
            adjHeight,
            width,
            showAds,
            data,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
    },
});
