import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Animated, StyleSheet } from 'react-native';
import { VideoPlayer } from './platform';
import { Pressable } from './Pressable';
import { View } from './View';
import { useSystemState } from '../context';
import { Console, Optional } from '../utils';
import { Colors } from '../styles';
import { ANIMATED_USE_NATIVE_DRIVER } from '../constants';

const NAME = 'Video';

export const VIDEO_WIDTH = 360;
export const VIDEO_HEIGHT = (190 / 250) * VIDEO_WIDTH;
const VIDEO_OVERLAY = VIDEO_HEIGHT * 0.71;

export const Video = props => {

    const {
        source,
        youtube,
        muted,
        onEnded,
        //        externalPlay,
    } = props;

    const { deviceScale } = useSystemState();

    const [play, setPlay] = useState(muted);
    const setPlayRef = useRef(setPlay);

    const [opacity] = useState(new Animated.Value(1));
    /*
        useEffect(
            () => {
                Console.log(`${NAME} useEffect play video`, { externalPlay });
                setPlayRef.current(v => !v);
            },
            [
                externalPlay,
                setPlayRef,
            ],
        );
    */
    useEffect(
        () => {
            Animated.timing(opacity, {
                useNativeDriver: ANIMATED_USE_NATIVE_DRIVER,
                toValue: 0,
                duration: 1000,
            }).start();
        },
        [
            opacity,
        ],
    );

    Console.stack(NAME, props, { deviceScale, play });

    return useMemo(
        () => {
            const videoSize = {
                width: VIDEO_WIDTH * deviceScale,
                height: VIDEO_HEIGHT * deviceScale,
            };
            const videoOverlaySize = {
                width: videoSize.width,
                height: VIDEO_OVERLAY * deviceScale,
            };

            const opacityStyle = { opacity };

            Console.log(`${NAME} render`, { deviceScale, youtube, muted, source, play, videoSize, videoOverlaySize });

            return (
                <View
                    value={'VideoView'}
                    style={[styles.video, videoSize]}
                >
                    <VideoPlayer
                        youtube={youtube}
                        muted={muted}
                        source={source}
                        play={play}
                        setPlayRef={setPlayRef}
                        onEnded={onEnded}
                        width={videoSize.width}
                        height={videoSize.height}
                    />
                    {Optional(youtube, (
                        <Animated.View
                            style={[styles.videoOverlay, videoOverlaySize, opacityStyle]}
                        >
                            <Pressable
                                style={[styles.videoOverlay, videoOverlaySize]}
                                onPress={() => setPlayRef.current(v => !v)}
                            />
                        </Animated.View>
                    ))}
                </View>
            );
        },
        [
            deviceScale,
            youtube,
            muted,
            source,
            play,
            setPlayRef,
            onEnded,
            opacity,
        ],
    );
};

const styles = StyleSheet.create({
    video: {
        alignSelf: 'center',
    },
    videoOverlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: Colors.colors.black,//Colors.colors.transparent,
    },
});
