import React from 'react';
import { Screen } from '../components';
import { GravityShapesGame as Game } from '../applications';
import { useAppState, useSystemState } from '../context';
import { Console } from '../utils';

const NAME = 'GravityGame';


export const GravityGame = props => {

  const { width, /*headerHeight,*/ adjHeight } = useSystemState();
  const { pause, tScale, gScale, gMagnitude } = useAppState();

  Console.stack(NAME, props, { width, /*headerHeight,*/ adjHeight, pause, tScale, gScale, gMagnitude });

  return (
    <Screen
      {...props}
      value={NAME}
    >
      <Game
        top={0}
        left={0}
        width={width}
        height={adjHeight}
        running={!pause}
        tScale={tScale}
        gScale={gScale}
        gMagnitude={gMagnitude}
        gx={0}
        gy={1}
      />
    </Screen>
  );
};
