import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator as RNPActivityIndicator } from 'react-native-paper';
import { View } from '.';
import { Console } from '../utils';

const NAME = 'ActivityIndicator';


export const ActivityIndicator = props => {

    const {
        color,
        size,
        style,
        containerStyle,
    } = props;

    Console.stack(NAME, props);

    return (
        <View
            value={'ActivityIndicator'}
            style={[styles.view, containerStyle]}
        >
            <RNPActivityIndicator
                style={style}
                color={color}
                size={size}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
        alignSelf: 'center',
    },
});
