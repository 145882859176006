import { Console } from '../Console';
import { Numbers } from '../Numbers';
import { getVoices, getLanguageKey } from './GetVoices';

const synth = window.speechSynthesis;

const NAME = 'Speech.web';


export class Speech {
    static GetVoices(lang = null, gender = null) {
        if (!getVoices()) {
            const installedVoices = synth.getVoices();
            const voices = getVoices(null, null, installedVoices);
            Console.log(`${NAME}.GetVoices`, { installedVoices, voices });
        }
        const result = getVoices(lang, gender);
        Console.LOG(`${NAME}.GetVoices(${lang}, ${gender})`, { result });
        return result;
    }

    static Talk(isIOS, text, lang, botVoice = null, gender = 'male', rate = 1.0, pitch = 1.0, volume = 1.0, doNotTranslateRate = false) {

        var voice = botVoice;
        if (!voice) {
            const _voices = Speech.GetVoices(lang, gender);
            voice = _voices?.length ? _voices[0] : null;
            Console.log(`${NAME}.Talk getting voice`, { lang, gender, voice0: _voices[0]?.lang, _voices, voice });
        } else {
            Console.log(`${NAME}.Talk using botVoice`, { botVoice });
        }

        const _rate = Numbers.clamp(rate, 0.01, 10.0);
        const _pitch = Numbers.clamp(pitch, 0.0, 2.0);
        const _volume = Numbers.clamp(volume, 0.0, 1.0);

        Console.LOG(`${NAME}.Talk (${voice?.name ? voice.name : voice?.id})`, { isIOS, text, lang, gender, botVoice, rate, _rate, pitch, _pitch, volume, _volume, doNotTranslateRate, voice });

        // https://developer.mozilla.org/en-US/docs/Web/API/SpeechSynthesisUtterance
        const msg = new SpeechSynthesisUtterance(text);
        msg.lang = getLanguageKey(lang);
        msg.rate = _rate;
        msg.pitch = _pitch;
        msg.volume = _volume;
        msg.voice = voice;

        synth.speak(msg);

        return voice;
    }
}
