import { MapKeys } from './MapKeys';
import { Numbers } from '../utils';


export const BusColor = (adherence, transparency = 0) => {
    const a = Numbers.clamp(adherence, -10, 10);
    const r = Math.round(256 * (a <= 0 ? 1.0 : (1.0 - a / 10.0)));
    const g = Math.round(256 * (a >= 0 ? 1.0 : (1.0 - -a / 10.0)));
    return transparency
        ? `rgba(${r}, ${g}, 0, ${transparency})`
        : `rgb(${r}, ${g}, 0)`;
};

export const BusKey = title => {
    return title ? title.toLowerCase().replace(/ /g, '') : null;
};

export const CreateBuses = buses => {
    var lookup = new Map();
    buses.forEach(bus => {
        const busTitle = BusKey(bus?.title);
        if (!lookup.has(busTitle)) {
            lookup.set(busTitle, []);
        }
        lookup.get(busTitle).push(bus);
        //console.log('MARKMARK BUS TITLE: ', busTitle);
    });
    return lookup;
};

export const DecodeBus = input => {
    var result = {
        ...input,
        type: MapKeys.BUSES,
        title: `${input?.route}: ${input?.sign}`,
        coordinate: {
            latitude: input?.lat,
            longitude: input?.lng,
        },
    };
    delete result.lat;
    delete result.lng;

    return result;
};
