import { Profile, Charts, Settings } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.User,

    TabArray: ['Profile', 'Charts', 'Settings'],

    Tabs: {
        Profile: {
            Component: Profile,
            Icon: Icons.User,
        },
        Charts: {
            Component: Charts,
            Icon: Icons.Charts,
        },
        Settings: {
            Component: Settings,
            Icon: Icons.Settings,
            menu: true,
        },
    },

    Stacks: {
    },

};
