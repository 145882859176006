import React, { useCallback, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Icon } from 'react-native-elements';
import { Card, FlatList, Screen, Text, View, TEXT_VARIANTS } from '../components';
import { useAppState } from '../context';
import { MATERIAL_COMMUNITY } from '../media';
import { Console, Numbers, Optional } from '../utils';
import { Colors } from '../styles';
import { GoogleAds, BANNER_AD_WIDTH, BANNER_AD_HEIGHT } from '../ads';

const NAME = 'Coupons';

var Toggles = new Map([/*['hawaiian', true],*/['farmersMarkets', true]]);

const GetToggle = type => {
    return Toggles.has(type) ? Toggles.get(type) : false;
};

const Toggle = type => {
    Console.log('Toggle', { type, Toggles });
    if (!Toggles.has(type)) {
        Toggles.set(type, true);
    } else {
        Toggles.set(type, !Toggles.get(type));
    }
};

const IconColors = [
    Colors.colors.red,
    Colors.colors.darkorange,
    Colors.colors.yellow,
    Colors.colors.lightgreen,
    Colors.colors.lightblue,
    Colors.colors.violet,
    'rgb(188,0,188)',
];

const IconMap = new Map([

    // commercial

    ['deptstores', { name: 'purse', color: IconColors[0] }],
    ['grocery', { name: 'cart', color: IconColors[1] }],
    ['farmersMarkets', { name: 'coffee'/*'tractor-variant'*/, color: IconColors[2] }],
    ['hawaiian', { name: 'silverware-fork-knife', color: IconColors[3] }],
    ['bars', { name: 'glass-mug-variant', color: IconColors[4] }],
    ['hotels', { name: 'bed', color: IconColors[5] }],
    ['attractions'/*music*/, { name: 'music-note', color: IconColors[6] }],

]);

const ENTRIES = [
    { id: 0, type: 'hawaiian', title: 'Cinnamon\'s at the Ilakai', right: '10%', subtitle: 'good thru  12/03/22' },
    { id: 1, type: 'hawaiian', title: 'Kuhio Beach Grill', right: '10%', subtitle: 'good thru 11/04/22' },
    { id: 2, type: 'hawaiian', title: 'Chart House Waikiki', right: '15%', subtitle: 'good thru 11/06/22' },
    { id: 3, type: 'hawaiian', title: 'Basalt Waikiki', right: '5%', subtitle: 'good thru 11/08/22' },
    { id: 4, type: 'hawaiian', title: 'Liliha Bakery', right: '10%', subtitle: 'good thru 11/13/22' },
    { id: 5, type: 'hawaiian', title: 'Hula Grill Waikiki', right: '15%', subtitle: 'good thru 11/20/22' },
    { id: 6, type: 'hawaiian', title: 'Hiking Hawaii Cafe', right: '10%', subtitle: 'good thru 12/31/22' },
    { id: 7, type: 'hawaiian', title: 'Duke\'s Waikiki', right: '15%', subtitle: 'good thru 11/15/22' },
    { id: 8, type: 'bars', title: 'Lava Tube Waikiki', right: '15%', subtitle: 'good thru 11/06/22' },
    { id: 9, type: 'bars', title: 'The Beach Bar', right: '20%', subtitle: 'good thru 10/31/22' },
    { id: 10, type: 'bars', title: 'Yard House', right: '10%', subtitle: 'good thru 11/14/22' },
    { id: 11, type: 'bars', title: 'Trees Bar & Grill', right: '10%', subtitle: 'good thru 12/20/22' },
    { id: 12, type: 'bars', title: 'Tropics Bar & Grill', right: '5%', subtitle: 'good thru 11/04/22' },
    { id: 13, type: 'bars', title: 'Waikiki Brewing Company', right: '10%', subtitle: 'good thru 12/31/22' },
    { id: 14, type: 'deptstores', title: 'Stoke House', right: '10%', subtitle: 'good thru 12/17/22' },
    { id: 15, type: 'deptstores', title: 'Tiki Products', right: '10%', subtitle: 'good thru 11/12/22' },
    { id: 16, type: 'deptstores', title: 'CocoNene', right: '10%', subtitle: 'good thru 11/02/22' },
    { id: 17, type: 'deptstores', title: 'Aloha Imagination', right: '10%', subtitle: 'good thru 11/01/22' },
    { id: 18, type: 'farmersMarkets', title: 'Maleko\'s Coffee', right: '15%', subtitle: 'good thru 11/09/22' },
];

const BACKGROUND_COLORS = [
    Colors.colors.lightgray,
    Colors.colors.white,
    Colors.colors.beige,
    Colors.colors.ivory,
    Colors.colors.lightcyan,
    Colors.colors.silver,
    Colors.colors.snow,
    Colors.colors.whitesmoke,
    Colors.colors.skyblue,
    Colors.colors.powderblue,
    Colors.colors.navajowite,
];

const COLORS = [
    Colors.colors.green,
    Colors.colors.purple,
    Colors.colors.blue,
    Colors.colors.tomato,
    Colors.colors.black,
    Colors.colors.blueviolet,
    Colors.colors.crimson,
    Colors.colors.darkblue,
    Colors.colors.darkgreen,
    //Colors.colors.plum,
    Colors.colors.maroon,
];

const DATA = ENTRIES.map(v => ({
    ...v,
    titleColor: Numbers.randomItem(COLORS),
    rightColor: Numbers.randomItem(COLORS),
    cardColor: Numbers.randomItem(BACKGROUND_COLORS),
}));


export const Coupons = props => {

    //const { adjHeight } = useSystemState();
    const { showAds } = useAppState();

    const [filterUpdate, setFilterUpdate] = useState(2);
    const setFilterUpdateRef = useRef(setFilterUpdate);

    const [type, setType] = useState('');
    const setTypeRef = useRef(setType);
    /*
        const [ads, setAds] = useState(false);
        const setAdsRef = useRef(setAds);

        useEffect(
            () => {
                Console.log(`${NAME} useEffect GoogleAds`);
                return GoogleAds.Get().subscribe(AD_TYPES.BANNER, setAdsRef);
            },
            [
                setAdsRef,
            ],
        );
    */
    const renderIcon = useCallback(
        (_type) => {
            const icon = IconMap.get(_type);
            Console.log(`${NAME}.renderIcon`, { _type, icon });
            const colorStyle = GetToggle(_type) ? { color: Colors.colors.white } : {};
            return (
                <Icon
                    type={MATERIAL_COMMUNITY}
                    name={icon.name}
                    color={icon.color}
                    underlayColor={icon.color}
                    iconStyle={[styles.icon, colorStyle]}
                    onPress={() => {
                        setTypeRef.current(_type);
                        Toggle(_type);
                        setFilterUpdateRef.current(v => v + 1);
                    }}
                />
            );
        },
        [
            setTypeRef,
            setFilterUpdateRef,
        ],
    );

    const renderIcons = useCallback(
        () => {
            Console.log(`${NAME}.renderIcons`);
            return (
                <>
                    {renderIcon('deptstores')}
                    {renderIcon('grocery')}
                    {renderIcon('farmersMarkets')}
                    {renderIcon('hawaiian')}
                    {renderIcon('bars')}
                    {renderIcon('hotels')}
                    {renderIcon('attractions')}
                </>
            );
        },
        [
            renderIcon,
        ],
    );

    const renderItem = useCallback(
        _item => {
            const { item } = _item;
            const icon = IconMap.get(item.type);
            const titleStyle = { color: item.titleColor };
            const cardStyle = { backgroundColor: item.cardColor };
            Console.log(`${NAME}.renderItem`, { item });
            return (
                <Card
                    id={item.id}
                    title={item.title}
                    subtitle={item.subtitle}
                    titleStyle={titleStyle}
                    cardStyle={cardStyle}
                    left={(
                        <Icon
                            type={MATERIAL_COMMUNITY}
                            name={icon.name}
                            color={icon.color}
                            underlayColor={icon.color}
                        />
                    )}
                    right={(
                        <Text
                            color={item.rightColor}
                            variant={TEXT_VARIANTS.TITLE}
                            value={item.right}
                        />
                    )}
                />
            );
        },
        [
        ],
    );

    const renderContent = useCallback(
        _filterUpdate => {
            Console.log(`${NAME}.renderContent`, { _filterUpdate });
            return (
                <FlatList
                    style={styles.flatlist}
                    data={DATA.filter(v => GetToggle(v.type)).sort((a, b) => a.title > b.title ? true : false)}
                    renderItem={renderItem}
                />
            );
        },
        [
            renderItem,
        ],
    );

    const renderFilter = useCallback(
        _filterUpdate => {
            const filterStyle = { backgroundColor: 'rgb(36,98,195)' };
            Console.log(`${NAME}.renderFilter`, { _filterUpdate });
            return (
                <View
                    style={[styles.types, filterStyle]}
                >
                    <Icon
                        type={MATERIAL_COMMUNITY}
                        name={'menu-left'}
                        color={Colors.colors.black}
                        underlayColor={Colors.colors.black}
                        iconStyle={styles.icon}
                    />
                    {renderIcons()}
                    <Icon
                        type={MATERIAL_COMMUNITY}
                        name={'menu-right'}
                        color={Colors.colors.black}
                        underlayColor={Colors.colors.black}
                        iconStyle={styles.icon}
                    />
                </View>
            );
        },
        [
            renderIcons,
        ],
    );

    Console.stack(NAME, props, { /*adjHeight,*/ filterUpdate, type, showAds });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { filterUpdate, showAds });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    {renderContent(filterUpdate)}
                    {renderFilter(filterUpdate)}
                    {Optional(showAds, (
                        <View
                            style={styles.ads}
                        >
                            {GoogleAds.Get().show(true)}
                        </View>
                    ))}
                </Screen>
            );
        },
        [
            props,
            showAds,
            filterUpdate,
            renderContent,
            renderFilter,
        ],
    );
};

const styles = StyleSheet.create({
    ads: {
        width: BANNER_AD_WIDTH,
        height: BANNER_AD_HEIGHT,
        alignSelf: 'center',
        //backgroundColor: Colors.colors.red,
    },
    types: {
        width: '100%',
        height: '7%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor: Colors.colors.darkgray,
        paddingHorizontal: 10,
    },
    icon: {
        padding: 5,
        fontSize: 30,
    },
    flatlist: {
        height: '100%',
    },
});
