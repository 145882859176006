import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Button, Chart, Divider, Input, Microphone, Picker, Pressable, Screen, Slider, Speaker, Switch, Text, View, INPUT_VARIANTS, INPUT_TYPE, TEXT_VARIANTS } from '../components';
import { useAppState, useSystemState } from '../context';
import { Console, Optional, Validate, Wav } from '../utils';
import { Colors } from '../styles';
import { DEV_COLOR, Instruments, Notes440 } from '../constants';

const NAME = 'Sound';

const HEADER_HEIGHT = 325;
const FOOTER_HEIGHT = 110;
const PICKER_WIDTH = 160; // MARKMARK: NEEDS WORK, try to make width work as a percent value...look at screen stuff
const PICKER_HEIGHT = 50;

const MAX_CHART_SAMPLES = 10000;
const SCALE_STEPS = 300;

const OrderValues = [
    ['off', 0],
    ['4', 4],
    ['8', 8],
    ['12', 12],
    ['16', 16],
    ['20', 20],
    ['24', 24],
];
//const Orders = new Map(OrderValues);
const OrderArray = OrderValues.map(i => i[0]);

const SampleRateValues = [
    ['8 kHz', 8000],
    ['16 kHz', 16000],
    ['22.05 kHz', 22050],
    ['24 kHz', 24000],
    ['32 kHz', 32000],
    ['44.1 kHz', 44100],
    ['48 kHz', 48000],
    ['64 kHz', 64000],
    ['88.2 kHz', 88200],
    ['96 kHz', 96000],
];
const SampleRates = new Map(SampleRateValues);
const SampleRateArray = SampleRateValues.map(i => i[0]);

const BitDepthValues = [
    ['8-bit', 8],
    ['12-bit', 12],
    ['16-bit', 16],
    ['20-bit', 20],
    ['24-bit', 24],
    ['32-bit', 32],
    ['64-bit', 64],
];
const BitDepths = new Map(BitDepthValues);
const BitDepthArray = BitDepthValues.map(i => i[0]);

const ChannelValues = [
    ['mono', 1],
    ['stereo', 2],
];
const Channels = new Map(ChannelValues);
const ChannelArray = ChannelValues.map(i => i[0]);

class SoundUtils {

    static Pressable(value, dark, onPress) {
        return (
            <Pressable
                key={value}
                id={value}
                style={[
                    styles.pressable,
                    {
                        backgroundColor: dark
                            ? Colors.colors.darkgray
                            : Colors.colors.lightgray,
                    },
                ]}
                value={value}
                onPress={onPress}
            />
        );
    }

    static Button(value, onPress) {
        return (
            <Button
                style={styles.button}
                value={value}
                onPress={onPress}
            />
        );
    }

    static Picker(selected, items, onChange, width = PICKER_WIDTH) {
        Console.log('Picker', { selected, items });
        const widthStyle = { width };
        return (
            <Picker
                width={width}
                height={PICKER_HEIGHT}
                viewStyle={[styles.pickerView, widthStyle]}
                style={styles.picker}
                selected={selected}
                items={items}
                onChange={onChange}
            />
        );
    }

    static Switch(label, value, onValueChange) {
        return (
            <Switch
                containerStyle={styles.switch}
                leftLabel={label}
                value={value}
                onValueChange={onValueChange}
            />
        );
    }

    static Input(title, value, setValue) {
        return (
            <Input
                containerStyle={styles.input}
                title={title}
                value={`${value}`}
                onChangeText={setValue}
                variant={INPUT_VARIANTS.OUTLINED}
                type={INPUT_TYPE.NUMBER}
            />
        );
    }

    static Info(header, first, last, max) {
        const seconds = header.Subchunk2Size / header.ByteRate;
        const _last = Math.min(last, max);
        const info1 = `${header.SampleRate / 1000} kHz, ${header.BitsPerSample / header.NumChannels} bits, ${seconds} ${seconds === 1.0 ? 'sec' : 'secs'}`;
        const info2 = `[${first}-${_last})`;
        return Optional(Validate.isValid(header) && header?.NumChannels, (
            <>
                <Text
                    value={`${info1}, ${info2}`}
                    textAlign={'center'}
                    variant={TEXT_VARIANTS.INFO}
                />
            </>
        ));
    }

    static Chart(values, riff, mainHeight, color) {
        const sizeStyle = {
            width: '90%',
            height: mainHeight * (riff?.NumChannels === 2 ? 0.40 : 0.80),
        };
        Console.log('Chart', { values, riff });
        return Optional(Validate.isValid(values) && values.length, (
            <Chart
                style={[styles.chart, sizeStyle]}
                data={values}
                color={color}
            />
        ));
    }

    static Slider(maximumValue, maxSlider, onValueChange) {
        Console.log('Slider', { maximumValue, maxSlider });
        return (
            <Slider
                viewStyle={styles.slider}
                trackStyle={styles.sliderTrack}
                thumbStyle={styles.sliderThumb}
                onValueChange={onValueChange}
                minimumValue={0}
                maximumValue={Math.min(maximumValue, maxSlider)}
                step={1}
                enabled={maximumValue}
            />
        );
    }

    static LogZoom(value, length, magnitude = 3) {
        const scale = Math.pow(10.0, (magnitude * 10.0 * value / SCALE_STEPS) / 10.0);
        const count = Math.round(length / scale);
        Console.log('LogZoom', { value, length, scale, count });
        return count;
    }
}

export const Sound = props => {

    const { deviceScale, adjHeight, isWeb } = useSystemState();
    const { DEFAULT, dark } = useAppState();
    const { SELECT_DISTANCE } = DEFAULT;

    const [channel, setChannel] = useState(ChannelValues[0][0]);
    const setChannelRef = useRef(setChannel);
    const [bitDepth, setBitDepth] = useState(BitDepthValues[0][0]);
    const setBitDepthRef = useRef(setBitDepth);
    const [sampleRate, setSampleRate] = useState(SampleRateValues[0][0]);
    const setSampleRateRef = useRef(setSampleRate);
    const [order, setOrder] = useState(OrderValues[0][0]);
    const setOrderRef = useRef(setOrder);
    const [minFrequency, setMinFrequency] = useState(10);
    const setMinFrequencyRef = useRef(setMinFrequency);
    const [maxFrequency, setMaxFrequency] = useState(3000);
    const setMaxFrequencyRef = useRef(setMaxFrequency);
    const [duration, setDuration] = useState(4797);
    const setDurationRef = useRef(setDuration);
    const [amplitude, setAmplitude] = useState(0.5);
    const setAmplitudeRef = useRef(setAmplitude);
    const [trim, setTrim] = useState(false);
    const setTrimRef = useRef(setTrim);

    const [data, setData] = useState([]);
    const setDataRef = useRef(setData);
    const [riff, setRiff] = useState({});
    const setRiffRef = useRef(setRiff);
    const [left, setLeft] = useState([]);
    const setLeftRef = useRef(setLeft);
    const [right, setRight] = useState([]);
    const setRightRef = useRef(setRight);

    const [slider, setSlider] = useState(0);
    const setSliderRef = useRef(setSlider);
    const [scale, setScale] = useState(0);
    const setScaleRef = useRef(setScale);
    const [numSamples, setNumSamples] = useState(0);
    const setNumSamplesRef = useRef(setNumSamples);

    const [instrumentIndex, setInstrumentIndex] = useState(3);
    const setInstrumentIndexRef = useRef(setInstrumentIndex);

    const [headerHeight, setHeaderHeight] = useState(HEADER_HEIGHT);
    const setHeaderHeightRef = useRef(setHeaderHeight);
    const [footerHeight, setFooterHeight] = useState(FOOTER_HEIGHT);
    const setFooterHeightRef = useRef(setFooterHeight);
    const [mainHeight, setMainHeight] = useState(-1);
    const setMainHeightRef = useRef(setMainHeight);

    useEffect(
        () => {
            const newMainHeight = adjHeight - (headerHeight + footerHeight);
            Console.log(`${NAME} useEffect height`, { adjHeight, headerHeight, footerHeight, newMainHeight });
            setMainHeightRef.current(newMainHeight);
        },
        [
            adjHeight,
            headerHeight,
            footerHeight,
            setMainHeightRef,
        ],
    );

    useEffect(
        () => {
            const wav = new Wav(data);
            const header = wav.getHeader();
            const leftChannel = wav.getChannel(0);
            const rightChannel = wav.getChannel(1);

            Console.log(`${NAME} useEffect wav`, { header, leftChannel, rightChannel });

            setRiffRef.current(header);
            setLeftRef.current(leftChannel);
            setRightRef.current(rightChannel);
            setSliderRef.current(0);
            setScaleRef.current(0);
        },
        [
            data,
            setRiffRef,
            setLeftRef,
            setRightRef,
            setSliderRef,
            setScaleRef,
        ],
    );

    useEffect(
        () => {
            const count = SoundUtils.LogZoom(scale, left.length);
            Console.log(`${NAME} useEffect zoom`, { scale, left, length: left.length, count });
            setNumSamplesRef.current(count);
            setSliderRef.current(v => Math.min(v, left.length - count));
        },
        [
            scale,
            left,
            setNumSamplesRef,
            setSliderRef,
        ],
    );

    const onHeightChange = useCallback(
        heights => {
            Console.log(`${NAME}.onHeightChange`, { heights });
            if (heights?.header?.height) {
                setHeaderHeightRef.current(heights.header.height > HEADER_HEIGHT / 2 ? HEADER_HEIGHT : 1);
            }
            if (heights?.footer?.height) {
                setFooterHeightRef.current(heights.footer.height);
            }
        },
        [
            setHeaderHeightRef,
            setFooterHeightRef,
        ],
    );

    const onStop = useCallback(
        payload => {
            Console.log(`${NAME}.onStop`, { data: payload?.data?.length, path: payload?.path });
            setDataRef.current(payload?.data);
        },
        [
            setDataRef,
        ],
    );

    const onCreate = useCallback(
        () => {
            const _channel = Channels.get(channel);
            const _bitDepth = BitDepths.get(bitDepth);
            const _sampleRate = SampleRates.get(sampleRate);

            Console.log(`${NAME}.onCreate`, { _channel, _bitDepth, _sampleRate, minFrequency, maxFrequency, amplitude, duration });

            const sinWave = Wav.createSinWave(_channel, _bitDepth * _channel, _sampleRate, [minFrequency, maxFrequency], [amplitude, amplitude], duration / 1000.0);
            setDataRef.current(new Uint8Array(sinWave));
        },
        [
            channel,
            bitDepth,
            sampleRate,
            minFrequency,
            maxFrequency,
            amplitude,
            duration,
            setDataRef,
        ],
    );

    const onNote = useCallback(
        note => {
            const frequency = Notes440.get(note);

            Console.log(`${NAME}.onNote`, { note, frequency });

            setMinFrequencyRef.current(frequency);
            setMaxFrequencyRef.current(frequency);
            setDataRef.current([]);
        },
        [
            setMinFrequencyRef,
            setMaxFrequencyRef,
            setDataRef,
        ],
    );

    const onUpdate = useCallback(
        () => {
            const _channel = Channels.get(channel);
            const _bitDepth = BitDepths.get(bitDepth);
            const _sampleRate = SampleRates.get(sampleRate);

            Console.log(`${NAME}.onUpdate`, { _sampleRate, _channel, _bitDepth, trim });

            const wav = new Wav(data);
            const converted = wav.convert(_sampleRate, _channel, _bitDepth, trim);
            setDataRef.current(new Uint8Array(converted));
        },
        [
            data,
            channel,
            sampleRate,
            bitDepth,
            trim,
            setDataRef,
        ],
    );

    const instrumentView = useMemo(
        () => {
            Console.log(`${NAME} render header instrumentView`);
            return (
                <View
                    value={'SoundTuning'}
                    style={styles.tuning}
                >
                    <View
                        value={'SoundInstrument'}
                        style={styles.instrument}
                    >
                        {
                            SoundUtils.Pressable(
                                Instruments[instrumentIndex][0],
                                dark,
                                () => setInstrumentIndexRef.current(v => (v + 1) % Instruments.length),
                            )
                        }
                    </View>
                    <View
                        value={'SoundStrings'}
                        style={styles.strings}
                    >
                        <Divider
                            orientation={'vertical'}
                        />
                        {
                            Instruments[instrumentIndex][1].map(n =>
                                SoundUtils.Pressable(n, dark, () => onNote(n)),
                            )
                        }
                    </View>
                </View>
            );
        },
        [
            dark,
            instrumentIndex,
            setInstrumentIndexRef,
            onNote,
        ],
    );

    const controlView = useMemo(
        () => {
            Console.log(`${NAME} render header controlView`);
            return (
                <View
                    value={'SoundControlsView'}
                    style={styles.controls}
                >
                    <View
                        value={'SoundControls1'}
                        style={styles.audioColumn}
                    >
                        {SoundUtils.Picker(sampleRate, SampleRateArray, setSampleRateRef.current)}
                        {SoundUtils.Picker(bitDepth, BitDepthArray, setBitDepthRef.current)}
                    </View>
                    <View
                        value={'SoundControls2'}
                        style={styles.audioColumn}
                    >
                        {SoundUtils.Picker(channel, ChannelArray, setChannelRef.current)}
                        {SoundUtils.Picker(order, OrderArray, setOrderRef.current)}
                    </View>
                </View>
            );
        },
        [
            channel,
            sampleRate,
            bitDepth,
            order,
            setChannelRef,
            setSampleRateRef,
            setBitDepthRef,
            setOrderRef,
        ],
    );

    const inputView = useMemo(
        () => {
            Console.log(`${NAME} render header inputView`);
            return (
                <View
                    value={'SoundInputsView'}
                    style={styles.inputs}
                >
                    <View
                        value={'SoundInputs1'}
                        style={styles.inputColumn}
                    >
                        {SoundUtils.Input('amplitude', amplitude, setAmplitudeRef.current)}
                        {SoundUtils.Input(order === OrderValues[0][0] ? 'left Hz' : 'min Hz', minFrequency, setMinFrequencyRef.current)}
                    </View>
                    <View
                        value={'SoundInputs2'}
                        style={styles.inputColumn}
                    >
                        {SoundUtils.Input('millis', duration, setDurationRef.current)}
                        {SoundUtils.Input(order === OrderValues[0][0] ? 'right Hz' : 'max Hz', maxFrequency, setMaxFrequencyRef.current)}
                    </View>
                </View>
            );
        },
        [
            order,
            amplitude,
            duration,
            minFrequency,
            maxFrequency,
            setAmplitudeRef,
            setDurationRef,
            setMinFrequencyRef,
            setMaxFrequencyRef,
        ],
    );

    const headerView = useMemo(
        () => {
            Console.log(`${NAME} render headerView`, { headerHeight });
            return Optional(headerHeight > HEADER_HEIGHT / 2, (
                <>
                    {instrumentView}
                    <Divider />
                    {controlView}
                    <Divider />
                    {inputView}
                </>
            ));
        },
        [
            headerHeight,
            instrumentView,
            controlView,
            inputView,
        ],
    );

    const mainView = useMemo(
        () => {
            const last = slider + numSamples;
            var leftSamples = [];
            var rightSamples = [];
            if (left.length) {
                leftSamples = Wav.downSample(left.slice(slider, last), MAX_CHART_SAMPLES);
            }
            if (right.length) {
                rightSamples = Wav.downSample(right.slice(slider, last), MAX_CHART_SAMPLES);
            }

            /** /
                        const sliderSteps = left?.length ? left.length : 0;
                        const maxSteps = sliderSteps - numSamples >= 0 ? sliderSteps - numSamples : sliderSteps;
                        const sliderView = (
                            <View
                                style={styles.sliderView}
                            >
                                {SoundUtils.Slider(riff?.NumChannels ? maxSteps : 0, maxSteps, setSliderRef.current)}
                                {SoundUtils.Slider(riff?.NumChannels ? SCALE_STEPS : 0, SCALE_STEPS, setScaleRef.current)}
                            </View>
                        );
            /**/

            Console.log(`${NAME} render mainView`, { slider, numSamples, last, left, right, leftSamples, rightSamples });
            return (
                <>
                    {SoundUtils.Chart(leftSamples, riff, mainHeight, Colors.colors.blue)}
                    {SoundUtils.Chart(rightSamples, riff, mainHeight, Colors.colors.red)}
                    {SoundUtils.Info(riff, slider, last, left.length)}
                </ >
            );
        },
        [
            numSamples,
            left,
            right,
            slider,
            riff,
            mainHeight,
        ],
    );

    const footerView = useMemo(
        () => {
            /**/
            const sliderSteps = left?.length ? left.length : 0;
            const maxSteps = sliderSteps - numSamples >= 0 ? sliderSteps - numSamples : sliderSteps;
            const sliderView = Optional(left?.length, (
                <View
                    value={'SoundSlider'}
                    style={styles.sliderView}
                >
                    {SoundUtils.Slider(riff?.NumChannels ? maxSteps : 0, maxSteps, setSliderRef.current)}
                    {SoundUtils.Slider(riff?.NumChannels ? SCALE_STEPS : 0, SCALE_STEPS, setScaleRef.current)}
                </View>
            ));
            /**/
            Console.log(`${NAME} render footerView`/*, { left, numSamples, maxSteps, sliderSteps }*/);
            return (
                <>
                    {sliderView}
                    <View
                        value={'SoundIO'}
                        style={styles.io}
                    >
                        <Microphone onStop={onStop} />
                        {
                            Optional(left?.length,
                                SoundUtils.Button('update', onUpdate),
                                SoundUtils.Button('create', onCreate),
                            )
                        }
                        {SoundUtils.Switch('trim', trim, setTrimRef.current)}
                        <Speaker data={data} />
                    </View>
                </>
            );
        },
        [
            /**/
            riff,
            numSamples,
            setSliderRef,
            setScaleRef,
            /**/
            data,
            left,
            trim,
            onCreate,
            onUpdate,
            onStop,
            setTrimRef,
        ],
    );

    Console.stack(NAME, props, { SELECT_DISTANCE, deviceScale, adjHeight, isWeb, channel: Channels[channel], bitDepth: BitDepths[bitDepth], sampleRate: SampleRates[sampleRate], order, minFrequency, maxFrequency, headerHeight, mainHeight, footerHeight, riff, slider, left: left.length, right: right.length, data: data.length });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { SELECT_DISTANCE, deviceScale, headerHeight, mainHeight, footerHeight });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    headerHeight={headerHeight}
                    headerStyle={styles.header}
                    headerView={headerView}
                    mainHeight={mainHeight}
                    mainStyle={styles.main}
                    mainView={mainView}
                    footerHeight={footerHeight}
                    footerStyle={styles.footer}
                    footerView={footerView}
                    dynamic={true}
                    selectDistance={SELECT_DISTANCE * deviceScale}
                    topDragColor={Colors.colors.green}
                    onHeightChange={onHeightChange}
                />
            );
        },
        [
            SELECT_DISTANCE,
            deviceScale,
            props,
            headerView,
            headerHeight,
            mainView,
            mainHeight,
            footerView,
            footerHeight,
            onHeightChange,
        ],
    );
};

const styles = StyleSheet.create({
    header: {
        backgroundColor: DEV_COLOR(Colors.colors.red),
        width: '100%',
        height: '100%',
        paddingHorizontal: 5,
    },
    tuning: {
        backgroundColor: DEV_COLOR(Colors.colors.cyan),
        flexDirection: 'row',
        width: '100%',
        paddingVertical: 7,
        alignSelf: 'flex-start',
        justifyContent: 'space-between',
    },
    instrument: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '27%',
    },
    strings: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '73%',
    },
    controls: {
        backgroundColor: DEV_COLOR(Colors.colors.purple),
        flexDirection: 'row',
        width: '100%',
        paddingVertical: 7,
        justifyContent: 'space-between',
    },
    audioColumn: {
        backgroundColor: DEV_COLOR(Colors.colors.cyan),
        alignItems: 'center',
        justifyContent: 'center',
        width: '48%',
    },
    inputs: {
        backgroundColor: DEV_COLOR(Colors.colors.blue),
        flexDirection: 'row',
        alignSelf: 'center',
        paddingVertical: 7,
        justifyContent: 'space-between',
        width: '97%',
    },
    inputColumn: {
        backgroundColor: DEV_COLOR(Colors.colors.orange),
        width: '48%',
        alignItems: 'center',
    },
    main: {
        backgroundColor: DEV_COLOR(Colors.colors.yellow),
        width: '100%',
        height: '100%',
        marginVertical: 5,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    footer: {
        backgroundColor: DEV_COLOR(Colors.colors.green),
        width: '100%',
        height: '100%',
        justifyContent: 'flex-end',
    },
    io: {
        backgroundColor: DEV_COLOR(Colors.colors.magenta),
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    pressable: {
        height: 40,
        paddingHorizontal: '2%',
        borderRadius: 8,
    },
    input: {
        width: '100%',
        height: 70,
    },
    chart: {
        backgroundColor: DEV_COLOR(Colors.colors.pink),
        alignSelf: 'center',
        borderColor: Colors.colors.gray,
        borderWidth: 1,
    },
    sliderView: {
        backgroundColor: DEV_COLOR(Colors.colors.maroon),
        flexDirection: 'row',
        width: '95%',
        height: '50%',
        alignSelf: 'center',
        justifyContent: 'space-between',
    },
    slider: {
        backgroundColor: DEV_COLOR(Colors.colors.cyan),
        justifyContent: 'center',
        width: '45%',
    },
    sliderTrack: {
    },
    sliderThumb: {
        height: 15,
        width: 15,
    },
    sliderButton: {
        backgroundColor: DEV_COLOR(Colors.colors.lightblue),
        width: '30%',
        height: '50%',
    },
    switch: {
        backgroundColor: DEV_COLOR(Colors.colors.gold),
        alignSelf: 'center',
    },
    pickerView: {
        backgroundColor: DEV_COLOR(Colors.colors.orange),
    },
    picker: {
        backgroundColor: DEV_COLOR(Colors.colors.blue),
    },
    button: {
        marginVertical: 3,
    },
    border: {
        borderColor: Colors.colors.black,
        borderWidth: 1,
    },
});
