import { GetMarkerImage } from '../media';
import { Numbers } from '../utils';
import { MapKeys } from './MapKeys';
import { WeatherAttributeMap } from './WeatherAttributes';


export const CreateWeather = stations => {
    var indexRange = {
        xMin: Number.POSITIVE_INFINITY,
        xMax: Number.NEGATIVE_INFINITY,
        yMin: Number.POSITIVE_INFINITY,
        yMax: Number.NEGATIVE_INFINITY,
    };

    var lookup = new Map();

    var grid = new Map();
    stations.forEach(station => {
        const xy = station.id.substring(4).split(',');
        const x = +xy[0];
        const y = +xy[1];
        indexRange.xMin = Math.min(indexRange.xMin, x);
        indexRange.xMax = Math.max(indexRange.xMax, x);
        indexRange.yMin = Math.min(indexRange.yMin, y);
        indexRange.yMax = Math.max(indexRange.yMax, y);
        if (!grid.has(x)) {
            grid.set(x, new Map());
        }
        grid.get(x).set(y, station);
        if (!lookup.has(station.id)) {
            lookup.set(station.id, station);
        }
    });

    return {
        lookup,
        grid,
        get: step => {
            var result = [];
            const { xMin, xMax, yMin, yMax } = indexRange;
            for (let x = xMin; x <= xMax; x += step) {
                if (grid.has(x)) {
                    for (let y = yMin; y <= yMax; y += step) {
                        if (grid.get(x).has(y)) {
                            result.push(grid.get(x).get(y));
                        }
                    }
                }
            }
            return result;
        },
    };
};

export const DecodeWeather = (input, sunInfo) => {

    const { id, lat, dLat, lng, dLng, elev, ts, properties, weather } = input;
    const { tokens, descriptions, indices } = weather;
    const {
        probabilityOfPrecipitation,
        relativeHumidity,
        skyCover,
        temperature,
        // MARKMARK waveDirection,
        waveHeight,
        wavePeriod,
        windDirection,
        windGust,
        windSpeed,
    } = properties;

    const sun = sunInfo(lat, lng).upcoming();

    const descriptionMap = descriptions.map(description => {
        const t = description.split('|');
        return `${tokens[+t[0]]}|${tokens[+t[1]]}|${tokens[+t[2]]}`;
    });

    const MARKMARK_daynightPrefix = 'd';

    const conditions = indices.map((index, idx) => {
        // break up the weather description
        const description = (descriptionMap[+index]?.length ? descriptionMap[+index] : 'skc||').split('|');

        var result = {
            description,
            image: GetMarkerImage(`${MARKMARK_daynightPrefix}_${description[0]?.length ? description[0] : 'skc'}`),

            // the report will be updated next
            report: {},

            // percentages
            clouds: skyCover?.length <= idx ? null : skyCover[idx],
            rain: probabilityOfPrecipitation?.length <= idx ? null : probabilityOfPrecipitation[idx],
            humidity: relativeHumidity?.length <= idx ? null : relativeHumidity[idx],

            // [english, metric]
            temperature: temperature?.length <= idx ? null : [Numbers.celsiusToFahrenheit(temperature[idx]), temperature[idx]],
            waveHeight: waveHeight?.length < 2 || waveHeight?.length <= idx ? null : [Numbers.metersToFeet(waveHeight[idx]), waveHeight[idx]],

            // seconds
            wavePeriod: waveHeight?.length < 2 || wavePeriod?.length <= idx ? null : wavePeriod[idx],

            // knots
            windGust: windGust?.length <= idx ? null : windGust[idx],
            windSpeed: windSpeed?.length <= idx ? null : windSpeed[idx],

            // compass
            windDirection: windDirection?.length <= idx ? null : Numbers.degreesToCompass(windDirection[idx]),
            // MARKMARK The raw data seems wrong...
            // waveDirection: waveDirection?.length < 2 || waveDirection?.length <= idx ? null : waveDirection[idx],
        };

        // initialize the weather report
        if (result.temperature?.length === 2) {
            const attr = WeatherAttributeMap.get('temperature');
            const { emoji, color } = attr;
            result.report.temperature = [`${emoji} ${Numbers.format(Numbers.fix(result.temperature[0]), 1)}°F`, `${emoji} ${Numbers.format(Numbers.fix(result.temperature[1]), 1)}°C`];
            result.report.temperatureColor = color;
        }
        if (result.clouds) {
            const attr = WeatherAttributeMap.get('clouds');
            const { emoji, color } = attr;
            result.report.clouds = `${emoji} ${Numbers.format(result.clouds, 0)}%`;
            result.report.cloudsColor = color;
        }
        if (result.rain) {
            const attr = WeatherAttributeMap.get('rain');
            const { emoji, color } = attr;
            result.report.rain = `${emoji} ${Numbers.format(result.rain, 0)}%`;
            result.report.rainColor = color;
        }
        if (result.humidity) {
            const attr = WeatherAttributeMap.get('humidity');
            const { emoji, color } = attr;
            result.report.humidity = `${emoji} ${Numbers.format(result.humidity, 0)}%`;
            result.report.humidityColor = color;
        }
        if (result.windSpeed) {
            const attr = WeatherAttributeMap.get('windSpeed');
            const { emoji, color } = attr;
            result.report.windSpeed = `${emoji} ${Numbers.format(result.windSpeed, 0)}`;
            if (result.windGust && result.windGust > result.windSpeed) {
                result.report.windSpeed += `-${Numbers.format(result.windGust, 0)}`;
            }
            result.report.windSpeed += ' kts';
            if (result.windDirection) {
                result.report.windSpeed += ` ${result.windDirection}`;
            }
            result.report.windSpeedColor = color;
        }
        if (result.waveHeight?.length === 2) {
            const attr = WeatherAttributeMap.get('waveHeight');
            const { emoji, color } = attr;
            result.report.waveHeight = [`${emoji} ${Numbers.format(Numbers.fix(result.waveHeight[0]), 1)}ft`, `${emoji} ${Numbers.format(Numbers.fix(result.waveHeight[1]), 1)}m`];
            if (result.wavePeriod) {
                const wavePeriodString = ` @${Numbers.format(result.wavePeriod, 0)}s`;
                result.report.waveHeight[0] += wavePeriodString;
                result.report.waveHeight[1] += wavePeriodString;
            }
            result.report.waveHeightColor = color;
        }
        return result;
    });

    const currentReport = conditions?.length ? conditions[0]?.report : null;
    const currentTemperature = currentReport?.temperature;
    /*
    const currentWaves = currentReport?.waves;
    var standardReport = '';
    ['clouds', 'rain', 'humidity', 'wind'].forEach(key => {
        if (currentReport && currentReport[key]) {
            standardReport += `\n${currentReport[key]}`;
        }
    });
    */
    const title = [
        `${currentTemperature?.length === 2 ? currentTemperature[0] : ''}`,
            //${standardReport}${currentWaves?.length === 2 ? `\n${currentWaves[0]}` : ''}`,
        `${currentTemperature?.length === 2 ? currentTemperature[1] : ''}`,
            //${standardReport}${currentWaves?.length === 2 ? `\n${currentWaves[1]}` : ''}`,
    ];

    const result = {
        key: id,
        id,
        type: MapKeys.WEATHER,
        title: title.join('|'),
        coordinate: {
            latitude: lat,
            longitude: lng,
            altitude: elev,
        },
        image: conditions[0].image,
        conditions,
        sun: {
            risingMins: sun[0],
            settingMins: sun[1],
            text: `${sun[0] < sun[1] ? '☀️' : '🌅'} ${Numbers.minsToTime(sun[0])} - ${Numbers.minsToTime(sun[1])}`,
        },
        dLat,
        dLng,
        ts,
    };

    return result;
};
