import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card, List } from 'react-native-paper';
import { GetSenseTypeEmoji } from './utils';
import { Button, BUTTON_VARIANTS } from './Button';
import { Flag } from './Flag';
import { Text } from './Text';
import { Video } from './Video';
import { View, VIEW_VARIANTS } from './View';
import { useAppDispatch, useAppState, useSystemState, APP_TYPES } from '../context';
import { aslmp4, ASLMP4_URL } from '../media';
import { Console, Optional, Speech, Strings, Validate } from '../utils';
import { Words } from '../styles';

const NAME = 'FlashCard';

const DEFINITIONS = 'Definitions';
const SYNONYMS = 'Synonyms';
const TRANSLATIONS = 'Translations';
const WATCH = 'WatchSigningVideo';
const ENGLISH = 'en';

export const FlashCard = props => {

    const {
        data,
    } = props;

    const { t, dark, language } = useAppState();
    const { isIOS, width } = useSystemState();

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const [value, setValue] = useState('');
    const setValueRef = useRef(setValue);

    const [ipa, setIpa] = useState([]);
    const setIpaRef = useRef(setIpa);

    const [senseArray, setSenseArray] = useState([]);
    const setSenseArrayRef = useRef(setSenseArray);

    const [video, setVideo] = useState('');
    const setVideoRef = useRef(setVideo);

    const [translationSet, setTranslationSet] = useState([]);
    const setTranslationSetRef = useRef(setTranslationSet);

    const [showVideo, setShowVideo] = useState(false);
    const setShowVideoRef = useRef(setShowVideo);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect`, { data, value, language });

            if (Validate.isValid(data?.value)) {
                setValueRef.current(data.value);
            } else {
                setValueRef.current('');
            }
            if (Validate.isValid(data?.ipa)) {
                setIpaRef.current(data.ipa);
            } else {
                setIpaRef.current([]);
            }

            var theEnglishTranslations = []; // used for asl lookup

            var _translations = []; // keep collection of all translations

            // the sense data in array form for rendering
            var theSenseArray = [];

            // populate the sense array
            if (Validate.isValid(data?.senses)) {
                data.senses.forEach((sense, senseKey) => {
                    const pos = senseKey.split('%')[1];
                    const { type, definition, emojis, examples, semantic, translations, words } = sense;

                    // get the translated type and associated emoji
                    const xType = t(`_${type}`);
                    var typeEmoji = GetSenseTypeEmoji(`<${type}>`);
                    if (!Validate.isValid(typeEmoji)) {
                        typeEmoji = GetSenseTypeEmoji(`<${pos}_${type}>`);
                    }
                    const typeEmojisString = Validate.isValid(typeEmoji) ? `${typeEmoji} ` : '';
                    const description = `${typeEmojisString}${xType}`;

                    // combine the sense emojis with the definition
                    var emojiDefinition = Validate.isValid(emojis) && emojis.length
                        ? `${emojis.join(', ')} ${definition}`
                        : definition;

                    // get and reformat the sense translations
                    // also keep track of the english versions for asl lookup
                    var senseTranslations = [];
                    if (Validate.isValid(translations)) {
                        Object.keys(translations).forEach(xLanguage => {
                            const languageTranslations = translations[xLanguage];
                            languageTranslations.forEach(translation => {
                                const tokens = translation.split('%');
                                const xWord = tokens[0];
                                const langValue = `${xLanguage}:${xWord}`;
                                senseTranslations.push(langValue);
                                if (xLanguage === ENGLISH) {
                                    theEnglishTranslations.push(xWord);
                                }
                                _translations.push(langValue);
                            });
                        });
                    }

                    theSenseArray.push({
                        expanded: false,
                        pos,
                        definition: emojiDefinition,
                        description,
                        examples: Validate.isValid(examples) ? [...examples] : [],
                        words: Validate.isValid(words) ? [...words] : [],
                        semantic: Validate.isValid(semantic) ? { ...semantic } : {},
                        translations: senseTranslations,
                    });

                    // keep track of the english values for asl lookup
                    if (language === ENGLISH) {
                        theEnglishTranslations.push(value);
                    }
                });
            }

            setTranslationSetRef.current(_translations);

            // update the sense array state for rendering
            setSenseArrayRef.current(theSenseArray);

            // try to match one of the english translations with something in the asl catelog
            // MARKMARK: Needs some tlc...shouldn't just find first match
            var videoUrl = '';
            for (let i = 0; i < theEnglishTranslations.length; i++) {
                const englishWord = theEnglishTranslations[i];
                if (aslmp4.has(englishWord)) {
                    videoUrl = `${ASLMP4_URL}${englishWord}.mp4`;
                    Console.log(`${NAME} useEffect video`, { i, englishWord, videoUrl });
                }
            }
            setVideoRef.current(videoUrl);
        },
        [
            t,
            language,
            data,
            value,
            setValueRef,
            setIpaRef,
            setSenseArrayRef,
            setVideoRef,
            setTranslationSetRef,
        ],
    );

    const onPress = useCallback(
        index => {
            Console.log(`${NAME}.onPress`, { index });
            var theSenseArray = [...senseArray];
            theSenseArray[index].expanded = !theSenseArray[index].expanded;
            setSenseArrayRef.current(theSenseArray);
        },
        [
            senseArray,
            setSenseArrayRef,
        ],
    );

    const onPressWord = useCallback(
        word => {
            Console.log(`${NAME}.onPressWord`, { word });
            appDispatchRef.current({ type: APP_TYPES.SET_SEARCH, payload: word });
        },
        [
            appDispatchRef,
        ],
    );

    const renderAccordion = useCallback(
        (title, icon, items, self, _onPress = null) => {
            if (!Validate.isValid(items)) {
                return null;
            }
            const onPressCb = _onPress ? _onPress : onPressWord;
            const uniqueItems = new Set(items);
            const listItems = Array.from(uniqueItems).sort();

            Console.log(`${NAME}.renderAccordion`, { title, icon, items, self, listItems });

            return Optional(listItems.length,
                (
                    <List.Accordion
                        key={title}
                        title={<Text value={title} />}
                        left={_props => <List.Icon {..._props} icon={icon} />}
                    >
                        {listItems.filter(item => item !== self).map(item => {
                            const itemTokens = item.split(':');
                            const itemTitle = itemTokens.length === 2 ? itemTokens[1] : item;
                            const itemLang = itemTokens.length === 2 ? itemTokens[0] : language;
                            return (
                                <List.Item
                                    key={item}
                                    title={itemTitle.replace(/_/g, ' ')}
                                    onPress={() => onPressCb(item)}
                                    left={__props => <Flag country={itemLang} scale={0.5} />}
                                />
                            );
                        })}
                    </List.Accordion>
                ),
            );
        },
        [
            language,
            onPressWord,
        ],
    );

    const renderSemantic = useCallback(
        (semantic, _dark, self) => {
            if (!Validate.isValid(semantic)) {
                return null;
            }
            const relationTypes = Object.keys(semantic).sort();

            Console.log(`${NAME}.renderSemantic`, { semantic, _dark, relationTypes });

            return (
                <>
                    {relationTypes.map(relationType =>
                        renderAccordion(
                            t(Words.Relation.get(relationType).heading.replace(/_/g, ' ')),
                            Words.Relation.get(relationType).icon[_dark ? 0 : 1],
                            semantic[relationType],
                            self,
                        ),
                    )}
                </>
            );
        },
        [
            t,
            renderAccordion,
        ],
    );

    Console.stack(NAME, props, { /*aslmp4, ASLMP4_URL,*/ isIOS, language, width, dark, value, ipa, senseArray });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { isIOS, value, ipa, senseArray, width, translationSet });
            const sizeStyle = { width: '100%', height: '100%' };
            return (

                <Card
                    style={sizeStyle}
                >
                    <Card.Title
                        title={(
                            <Button
                                variant={BUTTON_VARIANTS.TEXT}
                                noTranslate={true}
                                value={ipa.map(v => `/${v}/ 🔊`).join(', ')}
                                onPress={() => Speech.Talk(isIOS, value, language)}
                            />
                        )}
                    />
                    <Card.Actions
                        style={styles.buttons}
                    >

                        {Optional(video.length, (
                            <Button
                                value={showVideo ? '📽️ 📌' : WATCH}
                                onPress={() => setShowVideoRef.current(v => !v)}
                            />
                        ))}
                    </Card.Actions>
                    {Optional(video.length && showVideo, <Video source={video} muted={true} />)}
                    {Optional(translationSet?.length, (
                        <View
                            value={'Translations'}
                            style={styles.view}
                            variant={VIEW_VARIANTS.SCROLL}
                        >

                            {renderAccordion(TRANSLATIONS, 'translate', translationSet, value, item => Console.log(`${NAME}.translations_set.onPress(${item})`))}
                        </View>
                    ))}

                    <List.Section
                        key={DEFINITIONS}
                        style={styles.section}
                        title={t(DEFINITIONS)}
                    >
                        <View
                            value={'Definitions'}
                            style={styles.view}
                            variant={VIEW_VARIANTS.SCROLL}
                        >
                            {senseArray.map((senseValue, senseIndex) => (
                                <List.Accordion
                                    key={`accordian_${senseIndex}`}
                                    description={Strings.LanguageQuotes(language, senseValue.definition, false)}
                                    title={senseValue.description}
                                    descriptionNumberOfLines={4}
                                    expanded={senseValue.expanded}
                                    onPress={() => onPress(senseIndex)}
                                    left={_props => <List.Icon {..._props} icon={senseValue.expanded ? `alpha-${senseValue.pos}` : `alpha-${senseValue.pos}-circle${dark ? '' : '-outline'}`} />}
                                >
                                    {senseValue.examples.map((example, eIndex) => (
                                        <List.Item
                                            key={`example_${eIndex}`}
                                            titleNumberOfLines={3}
                                            title={`🔊 ${Strings.LanguageQuotes(language, `${example}`)}`}
                                            onPress={() => Speech.Talk(isIOS, example, language)}
                                        />
                                    ))}
                                    <List.Section>
                                        {renderAccordion(SYNONYMS, dark ? 'equal-box' : 'equal', senseValue?.words, value)}
                                        {renderAccordion(TRANSLATIONS, 'translate', senseValue?.translations, value, item => Console.log(`${NAME}.translations.onPress(${item})`))}
                                        {renderSemantic(senseValue?.semantic, dark, value)}
                                    </List.Section>
                                </List.Accordion>
                            ))}
                        </View>
                    </List.Section>
                </Card >
            );
        },
        [
            t,
            isIOS,
            width,
            language,
            dark,
            value,
            ipa,
            senseArray,
            video,
            showVideo,
            translationSet,
            onPress,
            renderAccordion,
            renderSemantic,
            setShowVideoRef,
        ],
    );
};

const styles = StyleSheet.create({
    view: {
        //flex: 1,
        width: '100%',
        //height: '100%',
    },
    buttons: {
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    section: {
        width: '100%',
        //height: '100%',
    },
});
