import jwtDecode from 'jwt-decode';
import { Console } from './Console';
import { Storage } from './Storage';

const TOKEN = '@token';

// retrieves the token from storage
export class Token {

    static set(token) {
        Console.log(`Token.set(${token})`);
        Storage.setItem(TOKEN, token);
    }

    static del() {
        Console.log('Token.del()');
        Storage.delItem(TOKEN);
    }

    static get() {
        const token = Storage.getItem(TOKEN);
        Console.log('Token.get()', { token });
        return token;
    }

    static authString(value = null) {
        const token = value ? value : Token.get();
        const result = token ? `Bearer ${token}` : '';
        Console.log('Token.authString()', { result });
        return result;
    }

    static token() {

        // initialize result object
        var result = {
            userId: null,
            token: Storage.getItem(TOKEN),
        };

        // if there is no token in storage, bail
        if (!result.token) {
            return result;
        }

        // decode the token
        var decodedToken;
        try {
            decodedToken = jwtDecode(result.token);
        } catch (e) {
            Console.error('Token.token', e);
        }

        // validate the decoded token
        if (!decodedToken || !decodedToken.data || !decodedToken.exp) {
            Console.warn('Token.token: Unexpected or null decoded token.', { decodedToken });
            return result;
        }

        // validate the token expiration
        if (new Date() >= new Date(decodedToken.exp * 1000)) {
            Console.warn('Token.token: Expired token.');
            return result;
        }

        // all is well, set the result's user id
        result.userId = decodedToken.data;

        // if the user id is null, remove the token
        if (!result.userId) {
            Console.warn('Token.token: User undefined, removing token.');
            Storage.delItem(TOKEN);
        }

        // return result containing the user id
        Console.log('Token.token', { result });
        return result;
    }
}
