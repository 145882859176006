import { Camera, Sound } from '../../screens';
import { Icons } from '../../media';


export const Routes = {

    Icon: Icons.Tools,

    TabArray: ['Sound', 'Camera'],

    Tabs: {
        Sound: {
            Component: Sound,
            Icon: Icons.Sound,
        },
        Camera: {
            Component: Camera,
            Icon: Icons.Camera,
        },
    },

    Stacks: {
    },

};
