import React from 'react';
import { StatusBar, View } from 'react-native';
import { PhysicsEngine, Renderer, TouchCallbacks } from './engine';
import { GameEngine } from './engine/platform';
import { Console, Numbers, Optional, Sensors } from '../utils';
//import { Colors } from '../styles';

const NAME = 'GravityShapesGame';


export class GravityShapesGame extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            accelerometer: null,
            engine: null,
            entities: {},
            systems: [],
        };

        Console.log(`${NAME}.constructor`, { props, state: this.state });
    }

    componentDidMount() {
        this.state.accelerometer = Sensors.accelerometer.subscribe(({ x, y }) => {
            if (this.props.running && this.state.engine) {
                this.state.engine.gravity(-x, y);
            }
        });

        const callbacks = {
            onSelect: (entities, position) => {
                TouchCallbacks.onSelect(entities, position);
                var { x, y } = position;
                const { system } = entities.physics;
                const { width, height } = system.dimensions();
                const offset = 50;
                x = Numbers.clamp(x, offset, width - offset);
                y = Numbers.clamp(y, offset, height - offset);
                const entity = Numbers.randomBool()
                    ? system.addCircle(null, x, y)
                    : system.addRectangle(null, x, y);
                entities[entity.id] = entity.body;
                Console.log(`${NAME}.onSelect`, { entities, position, x, y });
                return entities;
            },
        };

        const { top, left, width, height, tScale, gScale, gMagnitude } = this.props;
        this.state.engine = new PhysicsEngine(top, left, width, height, callbacks, null, tScale, gScale, gMagnitude);

        this.populate();

        this.state.entities = this.state.engine.entities();
        this.state.systems = this.state.engine.systems();

        ///* eslint-disable react/no-did-mount-set-state */
        // I think this is required to force an update
        this.setState({ initialized: true });

        Console.log(`${NAME}.componentDidMount`, { state: this.state });
    }

    componentDidUpdate(prevProps) {
        Console.log(`${NAME}.componentDidUpdate`, { state: this.state, props: this.props, prevProps });
        this.state.engine.setDimensions(this.props.width, this.props.height);
    }

    componentWillUnmount() {
        Console.log(`${NAME}.componentWillUnmount1`, { state: this.state });
        if (this.state.accelerometer) {
            this.state.accelerometer.unsubscribe();
        }
    }

    populate() {
        for (let i = 1; i > 0; i--) {
            this.state.engine.addCircle();
            this.state.engine.addRectangle();
        }
        Console.log(`${NAME}.populate`, { engine: this.state.engine });
    }

    render() {
        const { top, left, width, height, running } = this.props;
        const { initialized, systems, entities } = this.state;
        const gameStyle = {
            //position: 'absolute',
            //top,
            //left,
            width,
            height,
            //backgroundColor: Colors.colors.yellow,
            //borderColor: Colors.colors.red,
            //borderWidth: 1,
        };
        Console.log(`${NAME}.render`, { top, left, width, height, running, initialized, systems, entities });
        return Optional(initialized,
            (
                <GameEngine
                    style={gameStyle}
                    systems={systems}
                    entities={entities}
                    running={running}
                    renderer={Renderer}
                >
                    <StatusBar hidden={true} />
                </GameEngine>
            ),
            <View
                style={gameStyle}
            />,
        );
    }
}
