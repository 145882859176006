// https://www.debuggr.io/react-update-unmounted-component/

import { useEffect, useRef } from 'react';
import { Console } from '../utils';

const NAME = 'useIsMountedRef';


export const useIsMountedRef = () => {
    const isMountedRef = useRef(null);
    useEffect(
        () => {
            Console.LOG(`${NAME} useEffect loading`);
            isMountedRef.current = true;
            return () => {
                Console.LOG(`${NAME} useEffect unloading`);
                isMountedRef.current = false;
            };
        },
    );
    return isMountedRef;
};
