import axios from 'axios';

const HOLO_URI = 'https://www.holocard.net';

const HOLO_DETAILS_URL = 'https://www.holocard.net/umbraco/Api/PublicApi/CheckCardDetails';

const MISSING_HOLO_INPUT = 'Missing card or security code';

const Headers = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf8',
        'Access-Control-Allow-Origin': '*',
    },
};


export const HoloUrl = language => {
    return `${HOLO_URI}/${language === 'haw_hi' ? 'en' : language.substring(0, 2)}/`;
};

export const HoloCard = async (cardNumber, securityCode) => {
    if (!cardNumber?.length || !securityCode?.length) {
        return {
            balance: 0,
            messages: [MISSING_HOLO_INPUT],
        };
    }
    const postData = `CardNumber=${cardNumber}&SecurityCode=${securityCode}&Locale=en`;
    const result = await axios.post(HOLO_DETAILS_URL, postData, Headers);
    const data = result?.data?.Data;
    return !data
        ?
        {
            balance: 0,
            messages: result?.data?.Messages,
        }
        :
        {
            balance: data?.Balance ? +(data.Balance) * 0.01 : 0,
            messages: [],
        };
};
