import { Amplify } from './amplify';

const NAME = 'Auth';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';


export class Auth {

    static async changePassword(user, oldPassword, newPassword) {
        try {
            await Amplify.Get().Auth.changePassword(user, oldPassword, newPassword);
            //console.log(`${NAME}.changePassword`, { user });
        } catch (err) {
            console.error(`${NAME}.changePassword`, err);
        }
    }

    static async completeNewPassword(user, password, requiredAttributes) {
        try {
            const result = await Amplify.Get().Auth.completeNewPassword(user, password, requiredAttributes);
            //console.log(`${NAME}.completeNewPassword`, { user, password, requiredAttributes, result });
            return result;
        } catch (err) {
            console.error(`${NAME}.completeNewPassword`, err);
            return { error: err };
        }
    }

    // configure

    // confirmSignIn

    static async confirmRegister(username, code) {
        try {
            const result = await Amplify.Get().Auth.confirmSignUp(username, code);
            console.log(`${NAME}.confirmRegister`, { username, code, result });
            return result;
        } catch (err) {
            console.error(`${NAME}.confirmRegister`, err);
            return { error: err };
        }
    }

    static async currentAuthenticatedUser(parms = null) {
        try {
            var result = {};
            const amplify = Amplify.Get();
            if (amplify) {
                result = await Amplify.Get().Auth.currentAuthenticatedUser(parms);
            } else {
                console.error(`${NAME}.currentAuthenticatedUser Amplify not initialized`);
            }
            if (result) {
                result.language = 'en_us';
            }
            console.log(`${NAME}.currentAuthenticatedUser`, { parms, result });
            return result;
        } catch (err) {
            console.warn(`${NAME}.currentAuthenticatedUser`, err);
            return { error: err };
        }
    }

    static async currentSession() {
        try {
            const result = await Amplify.Get().Auth.currentSession();
            //console.log(`${NAME}.currentSession`, { result });
            return result;
        } catch (err) {
            console.error(`${NAME}.currentSession`, err);
            return { error: err };
        }
    }

    // currentUserCredentials

    // currentUserInfo

    // currentUserPoolUser

    // disableSMS

    // enableSMS

    // essentialCredentials

    // federatedSignIn

    static async forgotPassword(username) {
        try {
            await Amplify.Get().Auth.forgotPassword(username);
            //console.log(`${NAME}.forgotPassword`, { username });
        } catch (err) {
            console.error(`${NAME}.forgotPassword`, err);
        }
    }

    static async forgotPasswordSubmit(username, code, password) {
        try {
            await Amplify.Get().Auth.forgotPasswordSubmit(username, code, password);
            //console.log(`${NAME}.forgotPasswordSubmit`, { username, code });
        } catch (err) {
            console.error(`${NAME}.forgotPasswordSubmit`, err);
        }
    }

    // getMFAOptions

    // getModuleName

    // getPreferredMFA

    static async resendRegister(username) {
        try {
            await Amplify.Get().Auth.resendSignUp(username);
            //console.log(`${NAME}.resendRegister`, { username });
        } catch (err) {
            console.error(`${NAME}.resendRegister`, err);
        }
    }

    // sendCustomerChallengeAnswer

    // setPreferredMFA

    // setupTOTP

    static async login(username, password) {
        try {
            const result = await Amplify.Get().Auth.signIn(username, password);
            const challenge = {
                newPassword: result.challengeName === NEW_PASSWORD_REQUIRED,
                attributes: result.challengeParam,
            };
            //console.log(`${NAME}.login`, { username, result, challenge });
            return { result, challenge };
        } catch (err) {
            console.error(`${NAME}.login`, err);
            return { error: err };
        }
    }

    static async logout() {
        try {
            await Amplify.Get().Auth.signOut();
            //console.log(`${NAME}.logout`);
        } catch (err) {
            console.error(`${NAME}.logout`, err);
        }
    }

    static async register(username, password, email, language) {
        const parms = {
            username,
            password,
            attributes: {
                email,
                'custom:language': language,
            },
            /*
            autoSignIn: { // optional - enables auto sign in after user is confirmed
                enabled: true,
            },
            */
        };

        try {
            const result = await Amplify.Get().Auth.signUp(parms);
            console.log(`${NAME}.register`, { parms, result });
            return result; // { user, userConfirmed, userSub }
        } catch (err) {
            console.error(`${NAME}.register`, err);
            return { error: err };
        }
    }

    static async updateUserAttributes(user, attributes) {
        try {
            const result = await Amplify.Get().Auth.updateUserAttributes(user, attributes);
            //console.log(`${NAME}.updateUserAttributes`, { user, attributes, result });
            return result;
        } catch (err) {
            console.error(`${NAME}.updateUserAttributes`, err);
            return { error: err };
        }
    }

    static async userAttributes(user) {
        try {
            const result = await Amplify.Get().Auth.userAttributes(user);
            //console.log(`${NAME}.userAttributes`, { user, result });
            return result;
        } catch (err) {
            console.error(`${NAME}.userAttributes`, err);
            return { error: err };
        }
    }

    // userSession

    // verifiedContact

    // verifiedCurrentUserAttribute

    // verifiedCurrentUserAttributeSubmit

    // verifyTotpToken

    // verifyUserAttribute

    // verifyUserAttributeSubmit

}
