import { Icons } from '../media';
import { Colors } from '../styles';


export const MODES = {
    GENERIC: 'generic',
    RESOURCE: 'resource',
    COMMERCIAL: 'commercial',
};

export const Mode = [
    {
        mode: MODES.GENERIC,
        color: Colors.colors.lime,
        icon: Icons.MapMenuRight,
    },
    {
        mode: MODES.RESOURCE,
        color: Colors.colors.yellow,
        icon: Icons.MapMenuRight,
    },
    {
        mode: MODES.COMMERCIAL,
        color: Colors.colors.red,
        icon: Icons.MapMenuRight,
    },
];

export const NUM_MODES = Mode.length;

var modeMap = new Map();
Mode.forEach(v => modeMap.set(v.mode, v));

export const ModeMap = modeMap;
