export const Voices = [
    {
        voiceURI: 'Samantha',
        name: 'Samantha',
        language: 'en-US',
        gender: 'female',
    },
    {
        voiceURI: 'Alice',
        name: 'Alice',
        language: 'it-IT',
        gender: 'female',
    },
    {
        voiceURI: 'Anna',
        name: 'Anna',
        language: 'de-DE',
        gender: 'female',
    },
    {
        voiceURI: 'Ioana',
        name: 'Ioana',
        language: 'ro-RO',
        gender: 'female',
    },
    {
        voiceURI: 'Jacques',
        name: 'Jacques',
        language: 'fr-FR',
        gender: 'male',
    },
    {
        voiceURI: 'Joana',
        name: 'Joana',
        language: 'pt-PT',
        gender: 'female',
    },
    {
        voiceURI: 'Kyoko',
        name: 'Kyoko',
        language: 'ja-JP',
        gender: 'female',
    },
    {
        voiceURI: 'Lekha',
        name: 'Lekha',
        language: 'hi-IN',
        gender: 'female',
    },
    {
        voiceURI: 'Luciana',
        name: 'Luciana',
        language: 'pt-BR',
        gender: 'female',
    },
    {
        voiceURI: 'Majed',
        name: 'Majed',
        language: 'ar-001',
        gender: 'male',
    },
    {
        voiceURI: 'Meijia',
        name: 'Meijia',
        language: 'zh-TW',
        gender: 'female',
    },
    {
        voiceURI: 'Milena',
        name: 'Milena',
        language: 'ru-RU',
        gender: 'female',
    },
    {
        voiceURI: 'Mónica',
        name: 'Mónica',
        language: 'es-ES',
        gender: 'female',
    },
    {
        voiceURI: 'Paulina',
        name: 'Paulina',
        language: 'es-MX',
        gender: 'female',
    },
    {
        voiceURI: 'Thomas',
        name: 'Thomas',
        language: 'fr-FR',
        gender: 'male',
    },
    {
        voiceURI: 'Tingting',
        name: 'Tingting',
        language: 'zh-CN',
        gender: 'female',
    },
    {
        voiceURI: 'Yuna',
        name: 'Yuna',
        language: 'ko-KR',
        gender: 'female',
    },
    {
        voiceURI: 'Google Deutsch',
        name: 'Google Deutsch',
        language: 'de-DE',
        gender: 'female', // ???
    },
    {
        voiceURI: 'Google US English',
        name: 'Google US English',
        language: 'en-US',
        gender: 'female',
    },
    {
        voiceURI: 'Google UK English Female',
        name: 'Google UK English Female',
        language: 'en-GB',
        gender: 'female',
    },
    {
        voiceURI: 'Google UK English Male',
        name: 'Google UK English Male',
        language: 'en-GB',
        gender: 'male',
    },
    {
        voiceURI: 'Google español',
        name: 'Google español',
        language: 'es-ES',
        gender: 'male',
    },
    {
        voiceURI: 'Google español de Estados Unidos',
        name: 'Google español de Estados Unidos',
        language: 'es-US',
        gender: 'male',
    },
    {
        voiceURI: 'Google français',
        name: 'Google français',
        language: 'fr-FR',
        gender: 'female',
    },
    {
        voiceURI: 'Google हिन्दी',
        name: 'Google हिन्दी',
        language: 'hi-IN',
        gender: 'female',
    },
    {
        voiceURI: 'Google italiano',
        name: 'Google italiano',
        language: 'it-IT',
        gender: 'female',
    },
    {
        voiceURI: 'Google 日本語',
        name: 'Google 日本語',
        language: 'ja-JP',
        gender: 'female',
    },
    {
        voiceURI: 'Google 한국의',
        name: 'Google 한국의',
        language: 'ko-KR',
        gender: 'female',
    },
    {
        voiceURI: 'Google português do Brasil',
        name: 'Google português do Brasil',
        language: 'pt-BR',
        gender: 'female',
    },
    {
        voiceURI: 'Google русский',
        name: 'Google русский',
        language: 'ru-RU',
        gender: 'female',
    },
    {
        voiceURI: 'Google 普通话（中国大陆）',
        name: 'Google 普通话（中国大陆）',
        language: 'zh-CN',
        gender: 'female',
    },
    {
        voiceURI: 'Google 國語（臺灣）',
        name: 'Google 國語（臺灣）',
        language: 'zh-TW',
        gender: 'female',
    },
];
