import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FlashCard, Screen, Text, TEXT_VARIANTS } from '../components';
import { useAppState } from '../context';
import { Vocabulary, SENSE } from '../vocabulary';
import { Console, Validate } from '../utils';

const NAME = 'Detail';

const EMPTY_NODE = {
    id: 'na',
    pos: 'root',
    type: 'Lemma',
    rank: 0,
    ipa: [],
    value: 'na',
    senses: new Map(),
};

class DetailUtils {

    static DataToNode(data) {

        const { graph, nodes } = data;

        // check the input
        if (!Validate.isValid(graph) || !Validate.isValid(nodes) || !nodes.length) {
            Console.warn('DataToNode invalid graph or nodes', { graph, nodes });
            return;
        }

        // extract the word and it's graph value
        const word = nodes[0]?.id;
        const value = graph.get(word);
        if (!Validate.isValid(value?.node) || !Validate.isValid(value?.targetIds)) {
            Console.warn('DataToNode invalid word or value', { graph, nodes, word, value });
            return;
        }

        // create our node
        var node = { ...value.node };
        node.senses = new Map();

        // sanity check
        if (word.replace(/_/g, ' ') !== node.value) {
            Console.warn('DataToNode mismath word and node.id', { graph, nodes, word, node });
            return;
        }

        // add the source senses
        value.targetIds.forEach((targetSenseValue, senseId) => {
            if (targetSenseValue === SENSE) {
                Console.warn('DataToNode invalid target sense', { graph, nodes, senseId, targetSenseValue });
                return;
            }
            const sense = graph.get(senseId);
            // sanity check
            if (!Validate.isValid(sense?.node)) {
                Console.warn('DataToNode invalid sense', { graph, nodes, value, senseId, sense });
                return;
            }
            node.senses.set(senseId, sense.node);
        });

        // voila
        Console.log('DataToNode', { graph, nodes, word, value, node });
        return node;
    }
}


export const Detail = props => {

    const {
        navigation,
    } = props;

    const { DEFAULT, language, search } = useAppState();

    const [lang] = useState(language.split('_')[0]);

    const [node, setNode] = useState(EMPTY_NODE);
    const setNodeRef = useRef(setNode);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect entry`, { search });
            navigation.setOptions({ headerTitle: ({ tintColor }) => <Text value={search.replace(/_/g, ' ')} color={tintColor} variant={TEXT_VARIANTS.TITLE} /> });
        },
        [
            search,
            navigation,
        ],
    );

    useEffect(
        () => {
            const { TEXT_HEIGHT } = DEFAULT;
            const dummyDimension = 100;
            Console.log(`${NAME} useEffect node`, { lang, search });
            setNodeRef.current(DetailUtils.DataToNode(Vocabulary.Graph(lang, search, dummyDimension, dummyDimension, TEXT_HEIGHT)));
        },
        [
            DEFAULT,
            lang,
            search,
            setNodeRef,
        ],
    );

    Console.stack(NAME, props, { DEFAULT, language, lang, search, node });

    return useMemo(
        () => {
            Console.log(`${NAME} render`);
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    <FlashCard
                        data={node}
                    />
                </Screen>
            );
        },
        [
            props,
            node,
        ],
    );
};
