import React from 'react';
import { StyleSheet } from 'react-native';
import { Image } from './Image';
import { View } from './View';
import { useSystemState } from '../context';
import { Console, Optional, Validate } from '../utils';
import ColorPicker from 'react-native-wheel-color-picker';

// MARKMARK: MAJOR HACK #1
import Wheel from '../../node_modules/react-native-wheel-color-picker/assets/graphics/ui/color-wheel.png';
import HSlider from '../../node_modules/react-native-wheel-color-picker/assets/graphics/ui/black-gradient.png';
import VSlider from '../../node_modules/react-native-wheel-color-picker/assets/graphics/ui/black-gradient-rotated.png';

const NAME = 'ColorInput';

const DEFAULT_SIZE = 240;
const WHEEL_DIAMETER = 200;
const SWATCH_DIAMETER = 20;
const GAP = 16;
const SLIDER = 20;
const THUMB = 50;

const PALETTE = [
    '#888888',
    '#ed1c24',
    '#d11cd5',
    '#1633e6',
    '#00aeef',
    '#00c85d',
    '#57ff0a',
    '#ffde17',
    '#f26522',
];


export const ColorInput = props => {

    const {
        dark,
        color,
        onChangeColor,
        size,
        row,
        noSnap,
        gapSize,
        thumbSize,
        sliderSize,
        sliderHidden,
        swatches,
        swatchesLast,
        swatchesOnly,
        shadeWheelThumb,
        //shadeSliderThumb, //overloaded by dark to update palette
        autoResetSlider,
    } = props;

    const { isIOS, deviceScale } = useSystemState();

    const _color = Validate.isValid(color) ? color : '#ffffff';
    const _row = Validate.isValid(row) ? row : false;
    const _noSnap = Validate.isValid(noSnap) ? noSnap : false;
    const _sliderHidden = Validate.isValid(sliderHidden) ? sliderHidden : false;
    const _swatches = Validate.isValid(swatches) ? swatches : true;
    const _swatchesLast = Validate.isValid(swatchesLast) ? swatchesLast : true;
    const _swatchesOnly = Validate.isValid(swatchesOnly) ? swatchesOnly : false;
    const _shadeWheelThumb = Validate.isValid(shadeWheelThumb) ? shadeWheelThumb : false;
    const _autoResetSlider = Validate.isValid(autoResetSlider) ? autoResetSlider : false;

    const _size = Validate.isValid(size) ? Math.max(size, DEFAULT_SIZE) : DEFAULT_SIZE;

    const _gapSize = Validate.isValid(gapSize) ? gapSize : GAP;
    const _sliderSize = Validate.isValid(row) ? sliderSize : SLIDER;
    const _thumbSize = Validate.isValid(thumbSize) ? thumbSize : THUMB;

    const wheelDiameter = WHEEL_DIAMETER;
    const wheelOffset = (_size - wheelDiameter) / 2;

    const swatchDiameter = SWATCH_DIAMETER;
    const swatchOffset = _swatchesLast
        ? 0
        : swatchDiameter + _gapSize;

    const sliderWidth = _sliderHidden
        ? 0
        : _sliderSize + _gapSize;
    const swatchWidth = _swatches
        ? (swatchDiameter * 1.5) + _gapSize
        : 0;

    const length = _swatchesOnly
        ? swatchDiameter * 1.5
        : wheelDiameter + sliderWidth + swatchWidth;

    const viewStyle = _row
        ? {
            width: length,
            height: _size,
        }
        : {
            width: _size,
            height: length,
        };

    const sizeStyle = _row
        ? {
            height: _size,
        }
        : {
            width: _size,
        };

    const wheelStyle = {
        top: _row ? wheelOffset : swatchOffset,
        left: _row ? swatchOffset : wheelOffset,
    };

    const sliderPosition = swatchOffset + wheelDiameter + _gapSize;

    const sliderStyle = _row
        ? {
            left: sliderPosition,
            width: _sliderSize,
            height: _size,
        }
        : {
            top: sliderPosition,
            width: _size,
            height: _sliderSize,
        };

    var sliderImageStyle = {
        zIndex: 1,
        borderRadius: sliderStyle.width / 2,
        opacity: 0.5,
    };
    if (!isIOS) {
        sliderImageStyle.elevation = 1;
    }

    // MARKMARK: MAJOR HACK #2
    // prevents unwanted crash when using slider
    const _onChangeColor = newColor => {
        if (Validate.isValid(onChangeColor)) {
            if (newColor !== '#NaNNaNNaN') {
                onChangeColor(newColor);
            } else {
                Console.log(`${NAME}._onChangeColor ignoring bad value`, { newColor });
            }
        }
    };

    Console.stack(NAME, props, { deviceScale });

    // MARKMARK: One lingering display issue is that the slider thumb goes under the gradient...should sit above like wheel thumb ???
    return (
        <View
            value={'ColorInputView'}
            style={viewStyle}
        >
            <View
                value={'ColorInputImage'}
                style={[styles.absolute, sizeStyle]}
                pointerEvents={'none'}
            >
                {Optional(!_swatchesOnly, (
                    <Image
                        containerStyle={[styles.absolute, wheelStyle]}
                        source={Wheel}
                        width={wheelDiameter / deviceScale}
                        height={wheelDiameter / deviceScale}
                    />
                ))}
                <View
                    value={'ColorInputPicker'}
                    style={[styles.absolute, sizeStyle]}
                    pointerEvents={'auto'}
                >
                    <ColorPicker
                        color={_color}
                        onColorChange={_onChangeColor}
                        row={_row}
                        noSnap={_noSnap}
                        thumbSize={_thumbSize}
                        sliderSize={_sliderSize}
                        gapSize={_gapSize}
                        sliderHidden={_sliderHidden}
                        swatches={_swatches}
                        swatchesLast={_swatchesLast}
                        swatchesOnly={_swatchesOnly}
                        shadeWheelThumb={_shadeWheelThumb}
                        autoResetSlider={_autoResetSlider}
                        shadeSliderThumb={dark}
                        palette={[(dark ? '#000000' : '#FFFFFF'), ...PALETTE]}
                    />
                </View>
                {Optional(!_swatchesOnly && !_sliderHidden, (
                    <Image
                        containerStyle={[styles.absolute, sliderStyle]}
                        imageStyle={sliderImageStyle}
                        source={_row ? VSlider : HSlider}
                        width={sliderStyle.width / deviceScale}
                        height={sliderStyle.height / deviceScale}
                    />
                ))}
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    absolute: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
});
