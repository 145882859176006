const listeners = [];
const back = window?.history?.back;
var once = false;

if (!once && back) {
    once = true;

    window.history.back = () => {
        const handled = listeners.find(l => l());
        if (!handled) {
            return back.apply(window.history, []);
        }
    };

    window.addEventListener('popstate', () => {
        const handled = listeners.find(l => l());
        if (!handled) {
            back.apply(window.history, []);
        }
        window.history.pushState(null, '', window.location.href);
    });

    // Hack for Chrome. See this issue: https://stackoverflow.com/questions/64001278/history-pushstate-on-chrome-is-being-ignored?noredirect=1#comment113174647_64001278
    const onFirstPress = () => {
        window.history.pushState(null, '', window.location.href);
        window.removeEventListener('focusin', onFirstPress);
    };

    window.addEventListener('focusin', onFirstPress);
}


export class BackKeyUtils {

    static addEventListener(event, listener) {
        listeners.unshift(listener);
    }

    static removeEventListener(_, listener) {
        listeners.splice(listeners.indexOf(listener), 1);
    }

    static exitApp() {
        listeners.length = 0;
        if (back) {
            back.apply(window.history, []);
        }
    }
}
