import React from 'react';
import { StyleSheet } from 'react-native';
import { Switch as RNPSwitch } from 'react-native-paper';
import { Icon } from './Icon';
import { Text } from './Text';
import { View } from './View';
import { useSystemState } from '../context';
import { Console, Optional, Validate } from '../utils';

const NAME = 'Switch';

const DEFAULT_MARGIN = 10;
const DEFAULT_HEIGHT = 25;


export const Switch = props => {

    const {
        value,
        onValueChange,
        leftLabel,
        rightLabel,
        leftIcon,
        rightIcon,
        textVariant,
        switchColor,
        switchStyle,
        leftStyle,
        rightStyle,
        containerStyle,
        enabled,
        margin,
        noTranslate,
    } = props;

    const { deviceScale } = useSystemState();

    const disabled = Validate.isValid(enabled) ? !enabled : false;

    const _margin = deviceScale * (margin ? margin : DEFAULT_MARGIN);
    const leftText = {
        marginRight: _margin,
    };
    const rightText = {
        marginLeft: _margin,
    };
    const heightStyle = {
        height: DEFAULT_HEIGHT * deviceScale,
    };

    Console.stack(`${NAME}[${leftLabel}-${rightLabel}]`, props, { deviceScale, disabled });

    return (
        <View
            value={`${leftLabel}_${rightLabel}_Switch`}
            style={[styles.container, containerStyle]}
        >
            {Optional(Validate.isValidNonEmptyString(leftLabel), (
                <Text
                    value={leftLabel}
                    variant={textVariant}
                    style={[leftText, leftStyle]}
                    noTranslate={noTranslate}
                />
            ), Optional(leftIcon, <Icon icon={leftIcon} color={leftStyle?.color} />))}
            <RNPSwitch
                value={value}
                onValueChange={onValueChange}
                style={[styles.switch, heightStyle, switchStyle]}
                color={switchColor}
                disabled={disabled}
            />
            {Optional(Validate.isValidNonEmptyString(rightLabel), (
                <Text
                    value={rightLabel}
                    variant={textVariant}
                    style={[rightText, rightStyle]}
                    noTranslate={noTranslate}
                />
            ), Optional(rightIcon, <Icon icon={rightIcon} color={rightStyle?.color} />))}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignContent: 'center',
        //justifyContent: 'space-between',
    },
    switch: {
    },
});
