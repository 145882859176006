import React, { useEffect } from 'react';
import { useSystemState } from '../../context';
import { Console, Numbers } from '../../utils';

const NAME = 'Orientation';

const COMPASS_DEGREES = 1;


export const Orientation = props => {

    const {
        enabled,
        setCompassHeadingRef,
        setCompassPitchRef,
    } = props;

    const { orientation, startCompass, stopCompass } = useSystemState();

    useEffect(
        () => {

            if (!COMPASS_DEGREES || !enabled) {
                setCompassHeadingRef.current(-1);
                setCompassPitchRef.current(0);
                return;
            }

            var orientationSubscription = null;
            var headingEnabled = null;

            if (enabled && !orientationSubscription && orientation?.subscribe) {
                Console.devLog(`${NAME} start pitch`);
                orientationSubscription = orientation.subscribe(({ pitch }) => {
                    const degrees = Math.round(Numbers.toDegrees(Math.abs(pitch)));
                    headingEnabled = Math.abs(degrees) < 80 ? true : false;
                    if (degrees % 3 === 0) {
                        Console.trace(`${NAME} pitch ${degrees}`);
                        setCompassPitchRef.current(degrees);
                    }
                });
            } else {
                if (orientationSubscription) {
                    Console.devLog(`${NAME} stop pitch`);
                    orientationSubscription.unsubscribe();
                    orientationSubscription = null;
                }
            }

            if (enabled) {
                Console.devLog(`${NAME} start heading`);
                startCompass(COMPASS_DEGREES, compass => {
                    const degrees = Math.round(compass.heading);
                    Console.trace(`${NAME} heading ${degrees} [${headingEnabled}]`);
                    if (headingEnabled) {
                        setCompassHeadingRef.current(degrees);
                    }
                });
            } else {
                Console.devLog(`${NAME} stop heading`);
                stopCompass();
            }

            Console.devLog(`${NAME} subscribe`, { enabled, headingEnabled, orientationSubscription });

            return () => {
                Console.devLog(`${NAME} unsubscribe`, { orientationSubscription });
                stopCompass();
                if (orientationSubscription) {
                    orientationSubscription.unsubscribe();
                    orientationSubscription = null;
                }
            };
        },
        [
            enabled,
            orientation,
            startCompass,
            stopCompass,
            setCompassHeadingRef,
            setCompassPitchRef,
        ],
    );

    Console.stack(NAME, props);

    return <></>;
};
