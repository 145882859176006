export const TextStyles = {

    caption: {
        fontSize: 12,
        lineHeight: 20,
        marginVertical: 2,
        letterSpacing: 0.4,
    },

    headline: {
        fontSize: 24,
        lineHeight: 32,
        marginVertical: 2,
        letterSpacing: 0,
    },

    paragraph: {
        fontSize: 14,
        lineHeight: 20,
        marginVertical: 2,
        letterSpacing: 0.25,
    },

    subheading: {
        fontSize: 16,
        lineHeight: 20,
        marginVertical: 2,
        letterSpacing: 0.5,
    },

    title: {
        fontSize: 20,
        lineHeight: 30,
        marginVertical: 2,
        letterSpacing: 0.15,
    },

    helperText: {
        fontSize: 12,
    },

    appBarTitle: {
        //fontSize: isIOS ? 17 : 20,
        fontSize: 18,
    },

    appBarSubTitle: {
        //fontSize: isIOS ? 11 : 14,
        fontSIze: 12,
    },

    accordionListTitle: {
        fontSize: 16,
    },

    accordionListDescription: {
        fontSize: 14,
    },

    bottomNavigation: {
        fontSize: 12,
    },

    checkboxLabel: {
        fontSize: 16,
    },

    dataTableTitle: {
        fontSize: 12,
    },

    dataTablePagination: {
        fontSize: 12,
    },

    menuItem: {
        fontSize: 16,
    },

    radioButtonItem: {
        fontSize: 16,
    },

    searchBar: {
        fontSize: 18,
    },

    tabBarLabelBeside: {
        fontSize: 10,//13,
        marginLeft: 20,
        marginTop: 3,
    },
    tabBarLabelBeneath: {
        fontSize: 10,//10,
    },
    tabBarLabel: {
        fontSize: 10,//11, // compromise
    },

    buttonLabel: {
        fontSize: 16, // guess same as paragraph
        letterSpacing: 1,
        marginVertical: 9,
        marginHorizontal: 16,
    },
};
