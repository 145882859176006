import { Console, Numbers, Validate } from '../../utils';
//import { RNW } from '../../constants';
import { emojiList } from './emojiList';
import { cwpuzzle } from './cwpuzzle';


const NAME = 'GameUtils';

const PUZZLE_SIZE = 10;
const MIN_WORDS = 4;

const Categories = Object.keys(emojiList);


export class GameUtils {
    static isUnlocked(i, j, unlocked) {
        var found = false;
        unlocked.forEach(coord => {
            if (coord.i === i && coord.j === j) {
                found = true;
            }
        });
        Console.log(`${NAME}.isUnlocked`, { i, j, found });
        return found;
    }

    static newPuzzle(t) {
        var category = null;
        var game = null;
        do {
            category = Numbers.randomItem(Categories);
            const wordIcons = emojiList[category];
            const wordIconList = Object.keys(wordIcons).map(word => {
                const emojis = wordIcons[word][0].split('|');
                const icon = Numbers.randomItem(emojis);
                return { word: t(word), icon };
            });
            game = cwpuzzle(wordIconList, PUZZLE_SIZE);
        } while (game.words.length < MIN_WORDS);
        Console.log(`${NAME}.newPuzzle`, { category, words: game.words });
        return { category, game };
    }

    static isMatch(os, language, text, target) {
        if (!Validate.isValidNonEmptyString(text)) {
            return false;
        }
        const lc = text.toLowerCase().replace(/[(.|?|,|!)]/g, '');

        const match = lc === target;
        // MARKMARK: TBD add os/lang specific overrides
        /*(
            (lc.toLowerCase() === target) ||
            (
                os === RNW.ANDROID &&
                language === 'en' && (
                    (lc === '0' && target === 'zero') ||
                    (lc === '1' && target === 'one') ||
                    (lc === '2' && target === 'two') ||
                    (lc === '3' && target === 'three') ||
                    (lc === '4' && target === 'four') ||
                    (lc === '5' && target === 'five') ||
                    (lc === '6' && target === 'six') ||
                    (lc === '7' && target === 'seven') ||
                    (lc === '8' && target === 'eight') ||
                    (lc === '9' && target === 'nine') ||
                    (lc === '10' && target === 'ten') ||
                    (lc === '11' && target === 'eleven') ||
                    (lc === '12' && target === 'twelve') ||
                    (lc === '100' && target === 'hundred') ||
                    (lc === 'I' && target === 'eye') ||
                    (lc === 'pallet' && target === 'palette') ||
                    (lc === 'palm tree' && target === 'palmtree') ||
                    (lc === 'donut' && target === 'doughnut') ||
                    (lc === 'neil' && target === 'kneel') ||
                    (lc === 'teddy bear' && target === 'teddybear') ||
                    (lc === 'ice cream' && target === 'icecream') ||
                    (lc === 'light bulb' && target === 'lightbulb') ||
                    (lc === "fry's" && target === 'fries') ||
                    (lc === 'lyft' && target === 'lift') ||
                    (lc === 'pair' && target === 'pear') ||
                    (lc === 'dear' && target === 'deer') ||
                    (lc === 'aunt' && target === 'ant') ||
                    (lc === 'meet' && target === 'meat') ||
                    (lc === 'adam' && target === 'atom')

                    || (lc === "shari's" && target === 'cherries')
                )
            )
            // pen, skull, ball, deer, ewe, pencil, boar, hole, tea, key (chess)
        ) ? true : false;
        */
        Console.log(`${NAME}.isMatch`, { os, language, text, target, match });
        return match;
    }
}
