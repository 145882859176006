import React, { useEffect } from 'react';
import Stl from './Stl';

// borrowed from 'react-stl-file-viewer', which depended on old react
// instead using 'three' library directly (./Stl)
// MARKMARK: should investigate what all I can do with 'three'
export const Viewer3D = props => {

    const {
        width,
        height,
        url,
        objectColor,
        gridLineColor,
        skyboxColor,
        groundColor,
        lightColor,
        volume,
        cx, cy, cz,
        tx, ty, tz,
        rx, ry, rz,
        sx, sy, sz,
    } = props;

    useEffect(
        () => {
            Stl(
                url,
                width,
                height,
                objectColor,
                gridLineColor,
                skyboxColor,
                groundColor,
                lightColor,
                volume ? volume : () => {},
                cx, cy, cz,
                tx, ty, tz,
                rx, ry, rz,
                sx, sy, sz,
            );
        },
        [
            url,
            width,
            height,
            objectColor,
            gridLineColor,
            skyboxColor,
            groundColor,
            lightColor,
            volume,
            cx, cy, cz,
            tx, ty, tz,
            rx, ry, rz,
            sx, sy, sz,
        ],
    );

    return <div id={'stlviewer'} />;
};
