import { Icons } from '../media';
import { Colors } from '../styles';


export const MODES = {
    STANDARD: 'standard',
    HYBRID: 'hybrid',
    SATELLITE: 'satellite',
    TERRAIN: 'terrain',
};

export const Mode = [
    {
        mode: MODES.STANDARD,
        color: Colors.colors.lime,
        icon: Icons.MapStandardView,
    },
    {
        mode: MODES.HYBRID,
        color: Colors.colors.lime,
        icon: Icons.MapSatelliteView,
    },
    {
        mode: MODES.SATELLITE,
        color: Colors.colors.green,
        icon: Icons.MapSatelliteView,
    },
    {
        mode: MODES.TERRAIN,
        color: Colors.colors.green,
        icon: Icons.MapStandardView,
    },
];

export const NUM_MODES = Mode.length;

var modeMap = new Map();
Mode.forEach(v => modeMap.set(v.mode, v));

export const ModeMap = modeMap;
