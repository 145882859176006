export const DictionaryFiles = [

    'Words_en_a.json',
    'Words_en_b.json',
    'Words_en_c.json',
    'Words_en_d.json',
    'Words_en_e.json',
    'Words_en_f.json',
    'Words_en_g.json',
    'Words_en_h.json',
    'Words_en_i.json',
    'Words_en_j.json',
    'Words_en_k.json',
    'Words_en_l.json',
    'Words_en_m.json',
    'Words_en_n.json',
    'Words_en_o.json',
    'Words_en_p.json',
    'Words_en_q.json',
    'Words_en_r.json',
    'Words_en_s.json',
    'Words_en_t.json',
    'Words_en_u.json',
    'Words_en_v.json',
    'Words_en_w.json',
    'Words_en_x.json',
    'Words_en_y.json',
    'Words_en_z.json',
    'Words_es__.json',
    'Words_es_a.json',
    'Words_es_b.json',
    'Words_es_c.json',
    'Words_es_d.json',
    'Words_es_e.json',
    'Words_es_f.json',
    'Words_es_g.json',
    'Words_es_h.json',
    'Words_es_i.json',
    'Words_es_j.json',
    'Words_es_k.json',
    'Words_es_l.json',
    'Words_es_m.json',
    'Words_es_n.json',
    'Words_es_o.json',
    'Words_es_p.json',
    'Words_es_q.json',
    'Words_es_r.json',
    'Words_es_s.json',
    'Words_es_t.json',
    'Words_es_u.json',
    'Words_es_v.json',
    'Words_es_w.json',
    'Words_es_x.json',
    'Words_es_y.json',
    'Words_es_z.json',
    'Words_fr__.json',
    'Words_fr_a.json',
    'Words_fr_b.json',
    'Words_fr_c.json',
    'Words_fr_d.json',
    'Words_fr_e.json',
    'Words_fr_f.json',
    'Words_fr_g.json',
    'Words_fr_h.json',
    'Words_fr_i.json',
    'Words_fr_j.json',
    'Words_fr_k.json',
    'Words_fr_l.json',
    'Words_fr_m.json',
    'Words_fr_n.json',
    'Words_fr_o.json',
    'Words_fr_p.json',
    'Words_fr_q.json',
    'Words_fr_r.json',
    'Words_fr_s.json',
    'Words_fr_t.json',
    'Words_fr_u.json',
    'Words_fr_v.json',
    'Words_fr_w.json',
    'Words_fr_x.json',
    'Words_fr_y.json',
    'Words_fr_z.json',
    'Words_it__.json',
    'Words_it_a.json',
    'Words_it_b.json',
    'Words_it_c.json',
    'Words_it_d.json',
    'Words_it_e.json',
    'Words_it_f.json',
    'Words_it_g.json',
    'Words_it_h.json',
    'Words_it_i.json',
    'Words_it_j.json',
    'Words_it_k.json',
    'Words_it_l.json',
    'Words_it_m.json',
    'Words_it_n.json',
    'Words_it_o.json',
    'Words_it_p.json',
    'Words_it_q.json',
    'Words_it_r.json',
    'Words_it_s.json',
    'Words_it_t.json',
    'Words_it_u.json',
    'Words_it_v.json',
    'Words_it_w.json',
    'Words_it_x.json',
    'Words_it_y.json',
    'Words_it_z.json',
    'Words_pt__.json',
    'Words_pt_a.json',
    'Words_pt_b.json',
    'Words_pt_c.json',
    'Words_pt_d.json',
    'Words_pt_e.json',
    'Words_pt_f.json',
    'Words_pt_g.json',
    'Words_pt_h.json',
    'Words_pt_i.json',
    'Words_pt_j.json',
    'Words_pt_k.json',
    'Words_pt_l.json',
    'Words_pt_m.json',
    'Words_pt_n.json',
    'Words_pt_o.json',
    'Words_pt_p.json',
    'Words_pt_q.json',
    'Words_pt_r.json',
    'Words_pt_s.json',
    'Words_pt_t.json',
    'Words_pt_u.json',
    'Words_pt_v.json',
    'Words_pt_w.json',
    'Words_pt_x.json',
    'Words_pt_y.json',
    'Words_pt_z.json',
    'Words_ro__.json',
    'Words_ro_a.json',
    'Words_ro_b.json',
    'Words_ro_c.json',
    'Words_ro_d.json',
    'Words_ro_e.json',
    'Words_ro_f.json',
    'Words_ro_g.json',
    'Words_ro_h.json',
    'Words_ro_i.json',
    'Words_ro_j.json',
    'Words_ro_k.json',
    'Words_ro_l.json',
    'Words_ro_m.json',
    'Words_ro_n.json',
    'Words_ro_o.json',
    'Words_ro_p.json',
    'Words_ro_q.json',
    'Words_ro_r.json',
    'Words_ro_s.json',
    'Words_ro_t.json',
    'Words_ro_u.json',
    'Words_ro_v.json',
    'Words_ro_w.json',
    'Words_ro_x.json',
    'Words_ro_y.json',
    'Words_ro_z.json',

    'Senses_00001740.json',
    'Senses_00103599.json',
    'Senses_00195400.json',
    'Senses_00285060.json',
    'Senses_00377296.json',
    'Senses_00467557.json',
    'Senses_00575418.json',
    'Senses_00709929.json',
    'Senses_00853319.json',
    'Senses_00993156.json',
    'Senses_01125519.json',
    'Senses_01257237.json',
    'Senses_01412417.json',
    'Senses_01567617.json',
    'Senses_01724519.json',
    'Senses_01881778.json',
    'Senses_02034858.json',
    'Senses_02179828.json',
    'Senses_02327920.json',
    'Senses_02474216.json',
    'Senses_02628229.json',
    'Senses_02758442.json',
    'Senses_02921265.json',
    'Senses_03133523.json',
    'Senses_03462844.json',
    'Senses_03838339.json',
    'Senses_04200222.json',
    'Senses_04559383.json',
    'Senses_04971620.json',
    'Senses_05372278.json',
    'Senses_05783692.json',
    'Senses_06255946.json',
    'Senses_06662312.json',
    'Senses_07044205.json',
    'Senses_07434954.json',
    'Senses_07786010.json',
    'Senses_08301142.json',
    'Senses_09390888.json',
    'Senses_10059388.json',
    'Senses_10447528.json',
    'Senses_11448976.json',
    'Senses_12900287.json',
    'Senses_13530731.json',
    'Senses_13935275.json',
    'Senses_14342354.json',
    'Senses_14738514.json',
    'Senses_15111811.json',
    'Senses_60001172.json',
    'Senses_60003242.json',
    'Senses_80001679.json',
];
