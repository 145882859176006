import React from 'react';
import { DefaultRenderer } from 'react-game-engine';
import Svg from 'react-native-svg';


export const Renderer = (entities, window) => {
    if (!entities || !window) {
        return null;
    }
    const { width, height } = entities.physics.system.dimensions();
    return (
        <Svg
            width={width}
            height={height}
        >
            {DefaultRenderer(entities, window)}
        </Svg>
    );
};
