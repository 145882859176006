import { Numbers, Validate } from '../../utils';

/* OAHU: MARKMARK: Trying to get emoji for hawaiian flag
// https://emojipedia.org/flag-for-hawaii-ushi/
const unicode = ['1F3F4', 'E0075', 'E0073', 'E0068', 'E0069', 'E007F' ];
var unicodeString = '';
unicode.forEach(val => { unicodeString += String.fromCodePoint(parseInt(val, 16)); });
console.log('\n\n\nunicodeString', unicodeString);
*/

// https://emojiterra.com/categories/
export const emojis = {

    // https://flagcdn.com/256x192/${region}.png
    // flags
    'br': ['🇧🇷'],
    'us': ['🇺🇸'],
    'es': ['🇪🇸'],
    'fr': ['🇫🇷'],
    'gb': ['🇬🇧'],
    'it': ['🇮🇹'],
    'mx': ['🇲🇽'],
    'pt': ['🇵🇹'],
    'ro': ['🇷🇴'],
    'in': ['🇮🇳'],
    //'hi': ['🇮🇳'],
    'ru': ['🇷🇺'],

    // OAHU
    'de': ['🇩🇪'],
    'cn': ['🇨🇳'],
    'zh': ['🇨🇳'],
    'jp': ['🇯🇵'],
    'ja': ['🇯🇵'],
    'kr': ['🇰🇷'],
    'ko': ['🇰🇷'],
    'tw': ['🇹🇼'],
    'hi': ['🤙🏽󠁵󠁵'],
    'sa': ['🇸🇦'],

    'primate': ['🐒|🐵|🦍|🦧'],
    'human': ['🧑|👨|👩'],
    'canine': ['🐕|🐶|🐩|🐺|🦊'],
    'feline': ['🐈|🐅|🐯|🦁|🐆'],
    'rodent': ['🐁|🐭|🐀|🐹|🐇|🐰|🐿️|🦔|🦨'],
    'reptile': ['🐸|🐊|🦎|🐍'],
    'equine': ['🐎|🐴|🦓'],
    'bovine': ['🐂|🐄|🐮'],
    'sheep': ['🐏|🐑'],
    'lamb': ['🐏|🐑'],
    'rhino': ['🦏'],
    'hippo': ['🦛'],
    'swine': ['🐖|🐷|🐗'],
    'citrus': ['🍊|🍋'],
    'earth': ['🌍|🌍|🌎|🌏'],
    'cutlery': ['🍴'],

    // mammals
    'monkey': ['🐒|🐵'],
    'gorilla': ['🦍'],
    'orangutan': ['🦧'],
    'dog': ['🐕|🐶', 'puppy', 'canine'],
    'poodle': ['🐩'],
    'wolf': ['🐺'],
    'fox': ['🦊'],
    'racoon': ['🦝'],
    'cat': ['🐈', 'kitten', 'feline'],
    'lion': ['🦁'],
    'tiger': ['🐅|🐯'],
    'leopard': ['🐆'],
    'horse': ['🐎|🐴'],
    'unicorn': ['🦄'],
    'zebra': ['🦓'],
    'deer': ['🦌'],
    'ox': ['🐂'],
    'cow': ['🐄|🐮'],
    'pig': ['🐖|🐷'],
    'boar': ['🐗'],
    'ram': ['🐏'],
    'ewe': ['🐑'],
    'goat': ['🐐'],
    'camel': ['🐪'],
    'llama': ['🦙'],
    'giraffe': ['🦒'],
    'elephant': ['🐘'],
    'raccoon': ['🦝'],
    'rhinoceros': ['🦏'],
    'hippopotamus': ['🦛'],
    'mouse': ['🐁|🐭'],
    'rat': ['🐀'],
    'hamster': ['🐹'],
    'rabbit': ['🐇|🐰'],
    'chipmunk': ['🐿️'],
    'hedgehog': ['🦔'],
    'bat': ['🦇'],
    'bear': ['🐻'],
    'panda': ['🐼'],
    'koala': ['🐨'],
    'sloth': ['🦥'],
    'otter': ['🦦'],
    'skunk': ['🦨'],
    'kangaroo': ['🦘'],
    'badger': ['🦡'],

    // birds
    'turkey': ['🦃'],
    'chicken': ['🐔'],
    'rooster': ['🐓'],
    'chick': ['🐤'],
    'bird': ['🐦'],
    'penguin': ['🐧'],
    'dove': ['🕊️'],
    'eagle': ['🦅'],
    'duck': ['🦆'],
    'swan': ['🦢'],
    'owl': ['🦉'],
    'flamingo': ['🦩'],
    'peacock': ['🦚'],
    'parrot': ['🦜'],

    // amphibians/reptiles
    'frog': ['🐸'],
    'crocodile': ['🐊'],
    'turtle': ['🐢'],
    'lizard': ['🦎'],
    'snake': ['🐍'],
    'dragon': ['🐉|🐲'],
    'dinasour': ['🦖|🦕'],

    // marine
    'whale': ['🐳'],
    'dolphin': ['🐬'],
    'fish': ['🐟'],
    'shark': ['🦈'],
    'octopus': ['🐙'],
    'crab': ['🦀'],
    'lobster': ['🦞'],
    'shrimp': ['🦐'],
    'squid': ['🦑'],
    'oyster': ['🦪'],

    // small animals
    'snail': ['🐌'],
    'butterfly': ['🦋'],
    'bug': ['🐛'],
    'ant': ['🐜'],
    'bee': ['🐝'],
    'ladybug': ['🐞'],
    'cricket': ['🦗'],
    'spider': ['🕷️'],
    'scorpion': ['🦂'],
    'mosquito': ['🦟'],
    'microbe': ['🦠'],

    // animal related
    'shell': ['🐚'],
    'web': ['🕸️'],

    // flowers/plants
    'bouquet': ['💐', 'flowers'],
    'rose': ['🌹', 'flower'],
    'hibiscus': ['🌺', 'flower'],
    'sunflower': ['🌻', 'flower'],
    'blossom': ['🌼', 'flower'],
    'tulip': ['🌷', 'flower'],
    'evergreen': ['🌲', 'tree'],
    'palm-tree': ['🌴', 'tree'],
    'tree': ['🌳'],
    'cactus': ['🌵'],
    'herb': ['🌿'],
    'shamrock': ['☘️', 'leaf', 'clover'],
    'leaf': ['🍂'],
    'plant': ['🌱'],

    // body
    'hand': ['🖐️'],
    'hands': ['👐'],
    'muscle': ['💪', 'bicep'],
    'leg': ['🦵'],
    'foot': ['🦶'],
    'ear': ['👂'],
    'nose': ['👃'],
    'brain': ['🧠'],
    'tooth': ['🦷'],
    'bone': ['🦴'],
    'eyes': ['👀'],
    'eye': ['👁️'],
    'tongue': ['👅'],
    'mouth': ['👄', 'lips'],
    'baby': ['👶'],
    'child': ['🧒'],
    'boy': ['👦', 'child', 'male'],
    'girl': ['👧', 'child', 'female'],
    'person': ['🧑'],
    'man': ['👨', 'adult', 'male'],
    'woman': ['👩', 'adult', 'female'],
    'doctor': ['👨‍⚕️|👩‍⚕️'],
    'student': ['👨‍🎓|👩‍🎓'],
    'teacher': ['👨‍🏫|👩‍🏫'],
    'judge': ['👨‍⚖️|👩‍⚖️'],
    'farmer': ['👨‍🌾|👩‍🌾'],
    'cook': ['👨‍🍳|👩‍🍳'],
    'mechanic': ['👨‍🔧|👩‍🔧'],
    'scientist': ['👨‍🔬|👩‍🔬'],
    'programmer': ['👨‍💻|👩‍💻'],
    'astronaut': ['👨‍🚀|👩‍🚀'],
    'detective': ['🕵️‍♂️|🕵️‍♀️'],
    'guard': ['💂‍♂️|💂‍♀️'],
    'pilot': ['👨‍✈️|👩‍✈️'],
    'singer': ['👨‍🎤|👩‍🎤'],
    'artist': ['👨‍🎨|👩‍🎨'],
    'firefighter': ['👨‍🚒|👩‍🚒'],
    'police': ['👮‍♂️|👮‍♀️'],
    'pregnant': ['🤰'],

    // food
    'grapes': ['🍇'],
    'melon': ['🍈'],
    'watermelon': ['🍉'],
    'tangerine': ['🍊'],
    'lemon': ['🍋'],
    'banana': ['🍌'],
    'pineapple': ['🍍'],
    'mango': ['🥭'],
    'apple': ['🍎|🍏'],
    'pear': ['🍐'],
    'peach': ['🍑'],
    'cherries': ['🍒'],
    'strawberry': ['🍓'],
    'kiwi': ['🥝'],
    'tomato': ['🍅'],
    'coconut': ['🥥'],
    'avocado': ['🥑'],
    'eggplant': ['🍆'],
    'potato': ['🥔'],
    'carrot': ['🥕'],
    'corn': ['🌽'],
    'pepper': ['🌶️'],
    'cucumber': ['🥒'],
    'lettuce': ['🥬'],
    'broccoli': ['🥦'],
    'garlic': ['🧄'],
    'onion': ['🧅'],
    'mushroom': ['🍄'],
    'peanuts': ['🥜'],
    'chestnut': ['🌰'],
    'bread': ['🍞'],
    'croissant': ['🥐'],
    'baguette': ['🥖'],
    'pretzel': ['🥨'],
    'bagel': ['🥯'],
    'pancakes': ['🥞'],
    'waffle': ['🧇'],
    'cheese': ['🧀'],
    'meat': ['🥩'],
    'poultry': ['🍗'],
    'bacon': ['🥓'],
    'hamburger': ['🍔'],
    'french-fries': ['🍟'],
    'pizza': ['🍕'],
    'hotdog': ['🌭'],
    'sandwich': ['🥪'],
    'taco': ['🌮'],
    'burrito': ['🌯'],
    'falafel': ['🧆'],
    'sushi': ['🍣'],
    'ramen': ['🍜'],
    'spaghetti': ['🍝'],
    'egg': ['🥚'],
    'salad': ['🥗'],
    'popcorn': ['🍿'],
    'butter': ['🧈'],
    'salt': ['🧂'],
    'doughnut': ['🍩'], // MARKMARK spelling
    'ice-cream': ['🍦'],
    'cookie': ['🍪'],
    'cake': ['🎂'],
    'cupcake': ['🧁'],
    'pie': ['🥧'],
    'chocolate': ['🍫', 'candybar'],
    'candy': ['🍬'],
    'honey': ['🍯'],
    'milk': ['🥛'],
    'coffee': ['☕'],
    'tea': ['🍵'], // ???? MARKMARK
    'wine': ['🍷', 'glass'],
    'beer': ['🍺', 'mug'],
    'alchohol': ['🍹', 'drink', 'cocktail'],
    'beverage': ['🥤', 'drink'],
    'ice': ['🧊', 'ice-cube'],
    'chopsticks': ['🥢'],
    'utensils': ['🍴'],
    'spoon': ['🥄', 'utensil'],
    'knife': ['🔪', 'utensil'],
    'bottle': ['🍾|🍼'],
    'bowl': ['🥣'],

    // places
    'Africa': ['🌍'],
    'Europe': ['🌍'],
    'America': ['🌎'],
    'North America': ['🌎'],
    'South America': ['🌎'],
    'Asia': ['🌏'],
    'africa': ['🌍'],
    'europe': ['🌍'],
    'america': ['🌎'],
    'north america': ['🌎'],
    'south america': ['🌎'],
    'asia': ['🌏'],
    'map': ['🗺️'],
    'compass': ['🧭'],
    'mountain': ['⛰️'],
    'volcano': ['🌋'],
    'camping': ['🏕️', 'tent'],
    'beach': ['🏖️'],
    'desert': ['🏜️'],
    'island': ['🏝️'],
    'brick': ['🧱', 'bricks'],
    'construction': ['🏗️', 'crane'], // MARKMARK ???
    'house': ['🏠', 'home'],
    'houses': ['🏘️', 'homes'],
    'post-office': ['🏤'],
    'hospital': ['🏥'],
    'bank': ['🏦'],
    'hotel': ['🏨'],
    'store': ['🏪'],
    'school': ['🏫'],
    'factory': ['🏭'],
    'castle': ['🏰'],
    'church': ['⛪|🕌|🛕|🕍'], // MARKMARK, break this up
    'fountain': ['⛲'],
    'barber': ['💈'],
    'circus': ['🎪'],
    'roller-coaster': ['🎢'],
    'ferris-wheel': ['🎡'],
    'bridge': ['🌉'],

    // vehicles
    'train': ['🚂|🚆'],
    'bus': ['🚌|🚍'],
    'ambulance': ['🚑'],
    'fire-engine': ['🚒'],
    'police-car': ['🚓|🚔'],
    'taxi': ['🚕|🚖'],
    'automobile': ['🚗', 'car', 'vehicle'],
    'truck': ['🚚', 'vehicle'],
    'tractor': ['🚜'],
    'race-car': ['🏎️'],
    'motorcycle': ['🏍️', 'motorbike'],
    'wheelchair': ['🦽'],
    'bicycle': ['🚲', 'bike'],
    'skateboard': ['🛹'],
    'scooter': ['🛴'],
    'fuel': ['⛽', 'gas'],
    'stop': ['🛑|⏹️'],
    'anchor': ['⚓'],
    'sailboat': ['⛵', 'boat'],
    'canoe': ['🛶'],
    'speedboat': ['🚤', 'boat'],
    'boat': ['🛥️'],
    'ship': ['🚢'],
    'airplane': ['✈️', 'plane', 'aircraft'],
    'parachute': ['🪂'],
    'helicopter': ['🚁', 'aircraft'],
    'satellite': ['🛰️'],
    'rocket': ['🚀'],
    'alien': ['🛸|👽'],
    'trafficlight': ['🚦|🚥', 'traffic-light', 'street-light'],
    'siren': ['🚨'],

    // time
    'hourglass': ['⏳'],
    'watch': ['⌚'],
    'alarm-clock': ['⏰', 'clock'],
    'stopwatch': ['⏱️'],
    'clock': ['🕰️'],
    'one-oclock': ['🕐'],
    'one-thirty': ['🕜'],
    'two-oclock': ['🕑'],
    'two-thirty': ['🕝'],
    'three-oclock': ['🕒'],
    'three-thirty': ['🕞'],
    'four-oclock': ['🕓'],
    'four-thirty': ['🕟'],
    'five-oclock': ['🕔'],
    'five-thirty': ['🕠'],
    'six-oclock': ['🕕'],
    'six-thirty': ['🕡'],
    'seven-oclock': ['🕖'],
    'seven-thirty': ['🕢'],
    'eight-oclock': ['🕗'],
    'eight-thirty': ['🕣'],
    'nine-oclock': ['🕘'],
    'nine-thirty': ['🕤'],
    'ten-oclock': ['🕙'],
    'ten-thirty': ['🕥'],
    'eleven-oclock': ['🕚'],
    'eleven-thirty': ['🕦'],
    'twelve-oclock': ['🕛'],
    'twelve-thirty': ['🕧'],

    // nature
    'thermometer': ['🌡️'],
    'sun': ['🌞', 'sunny', 'day'],
    'moon': ['🌜', 'night'],
    'planet': ['🪐', 'Saturn'],
    'star': ['⭐'],
    'galaxy': ['🌌'],
    'cloud': ['☁️', 'cloudy'],
    'rain': ['🌧️', 'raining'],
    'snow': ['🌨️', 'snowing'],
    'lightning': ['🌩️', 'storm'],
    'tornado': ['🌪️', 'twister'],
    'fog': ['🌫️', 'foggy'],
    'wind': ['🌬️', 'windy'],
    'cyclone': ['🌀', 'hurricane'],
    'rainbow': ['🌈'],
    'umbrella': ['☂️'],
    'snowman': ['⛄'],
    'comet': ['☄️'],
    'fire': ['🔥'],
    'water': ['💧'],
    'wave': ['🌊'],

    // events
    'fireworks': ['🎆|🧨'],
    'balloon': ['🎈'],
    'gift': ['🎁', 'present'],
    'ticket': ['🎟️|🎫'],
    'ribbon': ['🎗️'],
    'trophy': ['🏆'],
    'soccer': ['⚽', 'ball'],
    'baseball': ['⚾', 'ball'],
    'basketball': ['🏀', 'ball'],
    'vollyball': ['🏐', 'ball'],
    'football': ['🏈', 'ball'],
    'tennis': ['🎾', 'paddle'],
    'frisbee': ['🥏'],
    'bowling': ['🎳'],
    'hockey': ['🏒'],
    'ping-pong': ['🏓'],
    'badminton': ['🏸'],
    'boxing': ['🥊'],
    'golf': ['⛳'],
    'skate': ['⛸️'],
    'fishing': ['🎣'],
    'diving': ['🤿'],
    'skis': ['🎿'],
    'sled': ['🛷'],
    'darts': ['🎯'],
    'kite': ['🪁'],
    'billiards': ['🎱'],
    'game': ['🎮'],
    'dice': ['🎲'],
    'puzzle': ['🧩'],
    'chess': ['♟️'],
    'cards': ['🃏'],

    // objects
    'painting': ['🖼️'],
    'palette': ['🎨'],
    'thread': ['🧵'],
    'yarn': ['🧶'],
    'glasses': ['👓'],
    'sunglasses': ['🕶️'],
    'goggles': ['🥽'],
    'underwear': ['🩲'],
    'shirt': ['👕'],
    'pants': ['👖'],
    'scarf': ['🧣'],
    'gloves': ['🧤'],
    'coat': ['🧥'],
    'socks': ['🧦'],
    'swimsuit': ['🩱|👙'],
    'dress': ['👗'],
    'purse': ['👜'],
    'backpack': ['🎒'],
    'shoe': ['👞|👟|👠'],
    'boot': ['🥾|👢'],
    'crown': ['👑'],
    'hat': ['🎩|👒'],
    'necktie': ['👔'],
    'ring': ['💍'],
    'lipstick': ['💄'],
    'gem': ['💎'],
    'bell': ['🔔'],
    'music': ['🎼'],
    'microphone': ['🎤'],
    'speaker': ['🔊'],
    'headphone': ['🎧'],
    'radio': ['📻'],
    'horn': ['📯'],
    'saxophone': ['🎷'],
    'guitar': ['🎸'],
    'piano': ['🎹'],
    'trumpet': ['🎺'],
    'violin': ['🎻'],
    'banjo': ['🪕'],
    'drum': ['🥁'],
    'telephone': ['☎️'],
    'movie': ['📽️'],
    'television': ['📺'],
    'camera': ['📷'],
    'candle': ['🕯️'],
    'light-bulb': ['💡'],
    'flashlight': ['🔦'],
    'book': ['📖'],
    'books': ['📚'],
    'newspaper': ['📰'],
    'money': ['💵'],
    'envelope': ['✉️'],
    'email': ['📧'],
    'package': ['📦'],
    'mailbox': ['📬'],
    'postbox': ['📮'],
    'ballot-box': ['🗳️'],
    'pencil': ['✏️'],
    'pen': ['🖋️'],
    'paintbrush': ['🖌️'],
    'crayon': ['🖍️'],
    'battery': ['🔋'],
    'electric-plug': ['🔌'],
    'laptop': ['💻'],
    'computer': ['🖥️'],
    'printer': ['🖨️'],
    'keyboard': ['⌨️'],
    'computermouse': ['🖱️'],
    'disk': ['💿|💾'],
    'abacus': ['🧮'],
    'lock': ['🔒'],
    'key': ['🔑'],
    'hammer': ['🔨'],
    'ax': ['🪓'],
    'pick': ['⛏️'],
    'shield': ['🛡️'],
    'archery': ['🏹'],
    'wrench': ['🔧'],
    'gear': ['⚙️'],
    'scale': ['⚖️'],
    'chain': ['⛓️'],
    'toolbox': ['🧰'],
    'magnet': ['🧲'],
    'microscope': ['🔬'],
    'telescope': ['🔭'],
    'blood': ['🩸'],
    'pill': ['💊'],
    'syringe': ['💉'],
    'bandage': ['🩹'],
    'stethoscope': ['🩺'],
    'door': ['🚪'],
    'bed': ['🛏️'],
    'chair': ['🪑'],
    'toilet': ['🚽'],
    'shower': ['🚿'],
    'bathtub': ['🛁'],
    'razor': ['🪒'],
    'broom': ['🧹'],
    'basket': ['🧺'],
    'soap': ['🧼'],
    'sponge': ['🧽'],
    'extinguisher': ['🧯'],
    'shopping-cart': ['🛒'],
    'cigarette': ['🚬'],
    'coffin': ['⚰️'],
    'folder': ['📁'],
    'calendar': ['📅'],
    'clipboard': ['📋'],
    'pin': ['📌|📍'],
    'chart': ['📊'],
    'paperclip': ['📎'],
    'ruler': ['📏'],
    'scissors': ['✂️'],
    'wastebasket': ['🗑️'],
    'luggage': ['🧳'],
    'cellphone': ['📱'],
    'teddybear': ['🧸'],
    'vase': ['🏺'],

    'male': ['♂️'],
    'female': ['♀️'],
    'medical': ['⚕️'],
    'recycle': ['♻️'],
    'radioactive': ['☢️'],
    'biohazard': ['☣️'],
    'up': ['⬆️'],
    'down': ['⬇️'],
    'right': ['➡️'],
    'left': ['⬅️'],
    'atom': ['⚛️'],
    'peace': ['☮️'],
    'play': ['▶️'],
    'reverse': ['◀️'],
    'pause': ['⏸️'],
    'record': ['⏺️'],
    'eject': ['⏏️'],

    'red': ['🔴'],
    'orange': ['🟠'],
    'yellow': ['🟡'],
    'green': ['🟢'],
    'blue': ['🔵'],
    'purple': ['🟣'],
    'brown': ['🟤'],
    'black': ['⚫'],
    'white': ['⚪'],
    'add': ['➕'],
    'subtract': ['➖'],
    'multiply': ['✖️'],
    'divide': ['➗'],
    'infinity': ['♾️'],
    'question': ['❔'],
    'exclamation': ['❕'],

    'pirate': ['🏴‍☠️'],
    'deaf': ['🧏‍♂️|🧏'],
    'blind': ['🦯|👨‍🦯|👩‍🦯'],
    'walking': ['🚶‍♂️|🚶‍♀️'],
    'standing': ['🧍‍♂️|🧍‍♀️'],
    'kneeling': ['🧎‍♂️|🧎‍♀️'],
    'running': ['🏃‍♂️|🏃‍♀️'],
    'dancing': ['🕺|💃'],
    'golfing': ['🏌️‍♂️|🏌️‍♀️'],
    'surfing': ['🏄‍♂️|🏄‍♀️'],
    'rowing': ['🚣‍♂️|🚣‍♀️'],
    'swimming': ['🏊‍♂️|🏊‍♀️'],
    'lifting': ['🏋️‍♂️|🏋️‍♀️'],
    'climbing': ['🧗‍♂️|🧗‍♀️'],
    'biking': ['🚴‍♂️|🚴‍♀️'],
    'biker': ['🚴‍♂️|🚴‍♀️'],
    'juggling': ['🤹‍♂️|🤹‍♀️'],
    'meditating': ['🧘‍♂️|🧘‍♀️'],
    'beard': ['🧔'],
    'bald': ['👨‍🦲'],
    'skier': ['⛷️'],
    'snowboarder': ['🏂'],
    'grinning': ['😀'],
    'winking': ['😉'],
    'smiling': ['😊'],
    'thinking': ['🤔'],
    'drooling': ['🤤'],
    'frowning': ['☹️'],
    'crying': ['😢'],
    'yawning': ['🥱'],
    'angry': ['😠'],
    'pleading': ['🥺'],
    'partying': ['🥳'],
    'drunk': ['🥴'],
    'quiet': ['🤫'],
    'vomit': ['🤮'],
    'nauseated': ['🤢'],
    'lying': ['🤥'],
    'sneezing': ['🤧'],
    'kissing': ['😗'],
    'smirking': ['😏'],
    'grimacing': ['😬'],
    'relieved': ['😌'],
    'pensive': ['😔'],
    'sleepy': ['😪'],
    'sleeping': ['😴'],
    'ill': ['🤒'],
    'injured': ['🤕'],
    'dizzy': ['😵'],
    'confused': ['😕'],
    'worried': ['😟'],
    'astonished': ['😲'],
    'anguished': ['😧'],
    'anxious': ['😰'],
    'fearful': ['😨'],
    'perservering': ['😣'],
    'disappointed': ['😞'],
    'weary': ['😫'],
    'tired': ['😫'],
    'pouting': ['😡'],
    'skull': ['💀'],
    'prince': ['🤴'],
    'princess': [''],
    'cold': ['🥶'],
    'hot': ['🥵'],
    'film': ['🎞️'],
    'credit': ['💳'],
    'bomb': ['💣'],
    'hole': ['🕳️'],
    'poop': ['💩'],
    'toiletpaper': ['🧻'],
    'clown': ['🤡'],
    'ghost': ['👻'],
    'robot': ['🤖'],
    'heart': ['❤️'],
    'thumb': ['👍'],
    'warning': ['⚠️'],
    'paper': ['📃'],
    'videocamera': ['📹'],
    'faxmachine': ['📠'],
    'family': ['👪'],
    'speaking': ['🗣️'],
    'love': ['🤟'],
    'search': ['🔎'],
    'chat': ['🗨️'],
    'flag': ['🏁'],

    'aries': ['♈'],
    'taurus': ['♉'],
    'gemini': ['♊'],
    'cancer': ['♋'],
    'leo': ['♌'],
    'virgo': ['♍'],
    'libra': ['♎'],
    'scorpio': ['♏'],
    'sagittarius': ['♐'],
    'capricorn': ['♑'],
    'aquarius': ['♒'],
    'pisces': ['♓'],

    'zero': ['\u0030\u20E3'],
    'one': ['\u0031\u20E3'],
    'two': ['\u0032\u20E3'],
    'three': ['\u0033\u20E3'],
    'four': ['\u0034\u20E3'],
    'five': ['\u0035\u20E3'],
    'six': ['\u0036\u20E3'],
    'seven': ['\u0037\u20E3'],
    'eight': ['\u0038\u20E3'],
    'nine': ['\u0039\u20E3'],
    /*
    //    'hash': '\u0023\u20E3',
    //    'copyright': '\u00A9',
    //    'registered': '\u00AE',
        'U+2002': '\u2002',
        'U+2003': '\u2003',
        'U+2005': '\u2005',
        'bangbang': '\u203C',
        'interrobang': '\u2049',
        'tm': '\u2122',
        'information-source': '\u2139',
        'left-right-arrow': '\u2194',
        'arrow-up-down': '\u2195',
        'arrow-upper-left': '\u2196',
        'arrow-upper-right': '\u2197',
        'arrow-lower-right': '\u2198',
        'arrow-lower-left': '\u2199',
        'leftwards-arrow-with-hook': '\u21A9',
        'arrow-right-hook': '\u21AA',
        'watch': '\u231A',
        'hourglass': '\u231B',
        'fast-forward': '\u23E9',
        'rewind': '\u23EA',
        'arrow-double-up': '\u23EB',
        'arrow-double-down': '\u23EC',
        'alarm-clock': '\u23F0',
        'hourglass-flowing-sand': '\u23F3',
        'm': '\u24C2',
        'black-small-square': '\u25AA',
        'white-small-square': '\u25AB',
        'arrow-forward': '\u25B6',
        'arrow-backward': '\u25C0',
        'white-medium-square': '\u25FB',
        'black-medium-square': '\u25FC',
        'white-medium-small-square': '\u25FD',
        'black-medium-small-square': '\u25FE',
        'sunny': '\u2600',
        'cloud': '\u2601',
        'telephone': '\u260E',
        'ballot-box-with-check': '\u2611',
        'umbrella': '\u2614',
        'coffee': '\u2615',
        'point-up': '\u261D',
        'relaxed': '\u263A',
        'spades': '\u2660',
        'clubs': '\u2663',
        'hearts': '\u2665',
        'diamonds': '\u2666',
        'hotsprings': '\u2668',
        'recycle': '\u267B',
        'wheelchair': '\u267F',
        'anchor': '\u2693',
        'warning': '\u26A0',
        'zap': '\u26A1',
        'white-circle': '\u26AA',
        'black-circle': '\u26AB',
        'soccer': '\u26BD',
        'baseball': '\u26BE',
        'snowman': '\u26C4',
        'partly-sunny': '\u26C5',
        'ophiuchus': '\u26CE',
        'no-entry': '\u26D4',
        'church': '\u26EA',
        'fountain': '\u26F2',
        'golf': '\u26F3',
        'sailboat': '\u26F5',
        'tent': '\u26FA',
        'fuelpump': '\u26FD',
        'scissors': '\u2702',
        'white-check-mark': '\u2705',
        'airplane': '\u2708',
        'envelope': '\u2709',
        'fist': '\u270A',
        'raised-hand': '\u270B',
        'v': '\u270C',
        'pencil2': '\u270F',
        'black-nib': '\u2712',
        'heavy-check-mark': '\u2714',
        'heavy-multiplication-x': '\u2716',
        'sparkles': '\u2728',
        'eight-spoked-asterisk': '\u2733',
        'eight-pointed-black-star': '\u2734',
        'snowflake': '\u2744',
        'sparkle': '\u2747',
        'x': '\u274C',
        'negative-squared-cross-mark': '\u274E',
        'question': '\u2753',
        'grey-question': '\u2754',
        'grey-exclamation': '\u2755',
        'exclamation': '\u2757',
        'heart': '\u2764',
        'heavy-plus-sign': '\u2795',
        'heavy-minus-sign': '\u2796',
        'heavy-division-sign': '\u2797',
        'arrow-right': '\u27A1',
        'curly-loop': '\u27B0',
        'arrow-heading-up': '\u2934',
        'arrow-heading-down': '\u2935',
        'arrow-left': '\u2B05',
        'arrow-up': '\u2B06',
        'arrow-down': '\u2B07',
        'black-large-square': '\u2B1B',
        'white-large-square': '\u2B1C',
        'star': '\u2B50',
        'o': '\u2B55',
        'wavy-dash': '\u3030',
        'part-alternation-mark': '\u303D',
        'congratulations': '\u3297',
        'secret': '\u3299',
        'mahjong': '\uD83C\uDC04',
        'black-joker': '\uD83C\uDCCF',
        'a': '\uD83C\uDD70',
        'b': '\uD83C\uDD71',
        'o2': '\uD83C\uDD7E',
        'parking': '\uD83C\uDD7F',
        'ab': '\uD83C\uDD8E',
        'cl': '\uD83C\uDD91',
        'cool': '\uD83C\uDD92',
        'free': '\uD83C\uDD93',
        'id': '\uD83C\uDD94',
        'new': '\uD83C\uDD95',
        'ng': '\uD83C\uDD96',
        'ok': '\uD83C\uDD97',
        'sos': '\uD83C\uDD98',
        'up': '\uD83C\uDD99',
        'vs': '\uD83C\uDD9A',
        'koko': '\uD83C\uDE01',
        'sa': '\uD83C\uDE02',
        'u7121': '\uD83C\uDE1A',
        'u6307': '\uD83C\uDE2F',
        'u7981': '\uD83C\uDE32',
        'u7a7a': '\uD83C\uDE33',
        'u5408': '\uD83C\uDE34',
        'u6e80': '\uD83C\uDE35',
        'u6709': '\uD83C\uDE36',
        'u6708': '\uD83C\uDE37',
        'u7533': '\uD83C\uDE38',
        'u5272': '\uD83C\uDE39',
        'u55b6': '\uD83C\uDE3A',
        'ideograph-advantage': '\uD83C\uDE50',
        'accept': '\uD83C\uDE51',
        'cyclone': '\uD83C\uDF00',
        'foggy': '\uD83C\uDF01',
        'closed-umbrella': '\uD83C\uDF02',
        'night-with-stars': '\uD83C\uDF03',
        'sunrise-over-mountains': '\uD83C\uDF04',
        'sunrise': '\uD83C\uDF05',
        'city-sunset': '\uD83C\uDF06',
        'city-sunrise': '\uD83C\uDF07',
        'rainbow': '\uD83C\uDF08',
        'bridge-at-night': '\uD83C\uDF09',
        'ocean': '\uD83C\uDF0A',
        'volcano': '\uD83C\uDF0B',
        'milky-way': '\uD83C\uDF0C',
        'earth-asia': '\uD83C\uDF0F',
        'new-moon': '\uD83C\uDF11',
        'first-quarter-moon': '\uD83C\uDF13',
        'waxing-gibbous-moon': '\uD83C\uDF14',
        'full-moon': '\uD83C\uDF15',
        'crescent-moon': '\uD83C\uDF19',
        'first-quarter-moon-with-face': '\uD83C\uDF1B',
        'star2': '\uD83C\uDF1F',
        'stars': '\uD83C\uDF20',
        'chestnut': '\uD83C\uDF30',
        'seedling': '\uD83C\uDF31',
        'palm-tree': '\uD83C\uDF34',
        'cactus': '\uD83C\uDF35',
        'tulip': '\uD83C\uDF37',
        'cherry-blossom': '\uD83C\uDF38',
        'rose': '\uD83C\uDF39',
        'hibiscus': '\uD83C\uDF3A',
        'sunflower': '\uD83C\uDF3B',
        'blossom': '\uD83C\uDF3C',
        'corn': '\uD83C\uDF3D',
        'ear-of-rice': '\uD83C\uDF3E',
        'herb': '\uD83C\uDF3F',
        'four-leaf-clover': '\uD83C\uDF40',
        'maple-leaf': '\uD83C\uDF41',
        'fallen-leaf': '\uD83C\uDF42',
        'leaves': '\uD83C\uDF43',
        'mushroom': '\uD83C\uDF44',
        'tomato': '\uD83C\uDF45',
        'eggplant': '\uD83C\uDF46',
        'grapes': '\uD83C\uDF47',
        'melon': '\uD83C\uDF48',
        'watermelon': '\uD83C\uDF49',
        'tangerine': '\uD83C\uDF4A',
        'banana': '\uD83C\uDF4C',
        'pineapple': '\uD83C\uDF4D',
        'apple': '\uD83C\uDF4E',
        'green-apple': '\uD83C\uDF4F',
        'peach': '\uD83C\uDF51',
        'cherries': '\uD83C\uDF52',
        'strawberry': '\uD83C\uDF53',
        'hamburger': '\uD83C\uDF54',
        'pizza': '\uD83C\uDF55',
        'meat-on-bone': '\uD83C\uDF56',
        'poultry-leg': '\uD83C\uDF57',
        'rice-cracker': '\uD83C\uDF58',
        'rice-ball': '\uD83C\uDF59',
        'rice': '\uD83C\uDF5A',
        'curry': '\uD83C\uDF5B',
        'ramen': '\uD83C\uDF5C',
        'spaghetti': '\uD83C\uDF5D',
        'bread': '\uD83C\uDF5E',
        'fries': '\uD83C\uDF5F',
        'sweet-potato': '\uD83C\uDF60',
        'dango': '\uD83C\uDF61',
        'oden': '\uD83C\uDF62',
        'sushi': '\uD83C\uDF63',
        'fried-shrimp': '\uD83C\uDF64',
        'fish-cake': '\uD83C\uDF65',
        'icecream': '\uD83C\uDF66',
        'shaved-ice': '\uD83C\uDF67',
        'ice-cream': '\uD83C\uDF68',
        'doughnut': '\uD83C\uDF69',
        'cookie': '\uD83C\uDF6A',
        'chocolate-bar': '\uD83C\uDF6B',
        'candy': '\uD83C\uDF6C',
        'lollipop': '\uD83C\uDF6D',
        'custard': '\uD83C\uDF6E',
        'honey-pot': '\uD83C\uDF6F',
        'cake': '\uD83C\uDF70',
        'bento': '\uD83C\uDF71',
        'stew': '\uD83C\uDF72',
        'egg': '\uD83C\uDF73',
        'fork-and-knife': '\uD83C\uDF74',
        'tea': '\uD83C\uDF75',
        'sake': '\uD83C\uDF76',
        'wine-glass': '\uD83C\uDF77',
        'cocktail': '\uD83C\uDF78',
        'tropical-drink': '\uD83C\uDF79',
        'beer': '\uD83C\uDF7A',
        'beers': '\uD83C\uDF7B',
        'ribbon': '\uD83C\uDF80',
        'gift': '\uD83C\uDF81',
        'birthday': '\uD83C\uDF82',
        'jack-o-lantern': '\uD83C\uDF83',
        'christmas-tree': '\uD83C\uDF84',
        'santa': '\uD83C\uDF85',
        'fireworks': '\uD83C\uDF86',
        'sparkler': '\uD83C\uDF87',
        'balloon': '\uD83C\uDF88',
        'tada': '\uD83C\uDF89',
        'confetti-ball': '\uD83C\uDF8A',
        'tanabata-tree': '\uD83C\uDF8B',
        'crossed-flags': '\uD83C\uDF8C',
        'bamboo': '\uD83C\uDF8D',
        'dolls': '\uD83C\uDF8E',
        'flags': '\uD83C\uDF8F',
        'wind-chime': '\uD83C\uDF90',
        'rice-scene': '\uD83C\uDF91',
        'school-satchel': '\uD83C\uDF92',
        'mortar-board': '\uD83C\uDF93',
        'carousel-horse': '\uD83C\uDFA0',
        'ferris-wheel': '\uD83C\uDFA1',
        'roller-coaster': '\uD83C\uDFA2',
        'fishing-pole-and-fish': '\uD83C\uDFA3',
        'microphone': '\uD83C\uDFA4',
        'movie-camera': '\uD83C\uDFA5',
        'cinema': '\uD83C\uDFA6',
        'headphones': '\uD83C\uDFA7',
        'art': '\uD83C\uDFA8',
        'tophat': '\uD83C\uDFA9',
        'circus-tent': '\uD83C\uDFAA',
        'ticket': '\uD83C\uDFAB',
        'clapper': '\uD83C\uDFAC',
        'performing-arts': '\uD83C\uDFAD',
        'video-game': '\uD83C\uDFAE',
        'dart': '\uD83C\uDFAF',
        'slot-machine': '\uD83C\uDFB0',
        '8ball': '\uD83C\uDFB1',
        'game-die': '\uD83C\uDFB2',
        'bowling': '\uD83C\uDFB3',
        'flower-playing-cards': '\uD83C\uDFB4',
        'musical-note': '\uD83C\uDFB5',
        'notes': '\uD83C\uDFB6',
        'saxophone': '\uD83C\uDFB7',
        'guitar': '\uD83C\uDFB8',
        'musical-keyboard': '\uD83C\uDFB9',
        'trumpet': '\uD83C\uDFBA',
        'violin': '\uD83C\uDFBB',
        'musical-score': '\uD83C\uDFBC',
        'running-shirt-with-sash': '\uD83C\uDFBD',
        'tennis': '\uD83C\uDFBE',
        'ski': '\uD83C\uDFBF',
        'basketball': '\uD83C\uDFC0',
        'checkered-flag': '\uD83C\uDFC1',
        'snowboarder': '\uD83C\uDFC2',
        'runner': '\uD83C\uDFC3',
        'surfer': '\uD83C\uDFC4',
        'trophy': '\uD83C\uDFC6',
        'football': '\uD83C\uDFC8',
        'swimmer': '\uD83C\uDFCA',
        'house': '\uD83C\uDFE0',
        'house-with-garden': '\uD83C\uDFE1',
        'office': '\uD83C\uDFE2',
        'post-office': '\uD83C\uDFE3',
        'hospital': '\uD83C\uDFE5',
        'bank': '\uD83C\uDFE6',
        'atm': '\uD83C\uDFE7',
        'hotel': '\uD83C\uDFE8',
        'love-hotel': '\uD83C\uDFE9',
        'convenience-store': '\uD83C\uDFEA',
        'school': '\uD83C\uDFEB',
        'department-store': '\uD83C\uDFEC',
        'factory': '\uD83C\uDFED',
        'izakaya-lantern': '\uD83C\uDFEE',
        'japanese-castle': '\uD83C\uDFEF',
        'european-castle': '\uD83C\uDFF0',
        'snail': '\uD83D\uDC0C',
        'snake': '\uD83D\uDC0D',
        'racehorse': '\uD83D\uDC0E',
        'sheep': '\uD83D\uDC11',
        'monkey': '\uD83D\uDC12',
        'chicken': '\uD83D\uDC14',
        'boar': '\uD83D\uDC17',
        'elephant': '\uD83D\uDC18',
        'octopus': '\uD83D\uDC19',
        'shell': '\uD83D\uDC1A',
        'bug': '\uD83D\uDC1B',
        'ant': '\uD83D\uDC1C',
        'bee': '\uD83D\uDC1D',
        'beetle': '\uD83D\uDC1E',
        'fish': '\uD83D\uDC1F',
        'tropical-fish': '\uD83D\uDC20',
        'blowfish': '\uD83D\uDC21',
        'turtle': '\uD83D\uDC22',
        'hatching-chick': '\uD83D\uDC23',
        'baby-chick': '\uD83D\uDC24',
        'hatched-chick': '\uD83D\uDC25',
        'bird': '\uD83D\uDC26',
        'penguin': '\uD83D\uDC27',
        'koala': '\uD83D\uDC28',
        'poodle': '\uD83D\uDC29',
        'camel': '\uD83D\uDC2B',
        'dolphin': '\uD83D\uDC2C',
        'mouse': '\uD83D\uDC2D',
        'cow': '\uD83D\uDC2E',
        'tiger': '\uD83D\uDC2F',
        'rabbit': '\uD83D\uDC30',
        'cat': '\uD83D\uDC31',
        'dragon-face': '\uD83D\uDC32',
        'whale': '\uD83D\uDC33',
        'horse': '\uD83D\uDC34',
        'monkey-face': '\uD83D\uDC35',
        'dog': '\uD83D\uDC36',
        'pig': '\uD83D\uDC37',
        'frog': '\uD83D\uDC38',
        'hamster': '\uD83D\uDC39',
        'wolf': '\uD83D\uDC3A',
        'bear': '\uD83D\uDC3B',
        'panda-face': '\uD83D\uDC3C',
        'pig-nose': '\uD83D\uDC3D',
        'feet': '\uD83D\uDC3E',
        'eyes': '\uD83D\uDC40',
        'ear': '\uD83D\uDC42',
        'nose': '\uD83D\uDC43',
        'lips': '\uD83D\uDC44',
        'tongue': '\uD83D\uDC45',
        'point-up-2': '\uD83D\uDC46',
        'point-down': '\uD83D\uDC47',
        'point-left': '\uD83D\uDC48',
        'point-right': '\uD83D\uDC49',
        'punch': '\uD83D\uDC4A',
        'wave': '\uD83D\uDC4B',
        'ok-hand': '\uD83D\uDC4C',
        'thumbsup': '\uD83D\uDC4D',
        'thumbsdown': '\uD83D\uDC4E',
        'clap': '\uD83D\uDC4F',
        'open-hands': '\uD83D\uDC50',
        'crown': '\uD83D\uDC51',
        'womans-hat': '\uD83D\uDC52',
        'eyeglasses': '\uD83D\uDC53',
        'necktie': '\uD83D\uDC54',
        'shirt': '\uD83D\uDC55',
        'jeans': '\uD83D\uDC56',
        'dress': '\uD83D\uDC57',
        'kimono': '\uD83D\uDC58',
        'bikini': '\uD83D\uDC59',
        'womans-clothes': '\uD83D\uDC5A',
        'purse': '\uD83D\uDC5B',
        'handbag': '\uD83D\uDC5C',
        'pouch': '\uD83D\uDC5D',
        'mans-shoe': '\uD83D\uDC5E',
        'athletic-shoe': '\uD83D\uDC5F',
        'high-heel': '\uD83D\uDC60',
        'sandal': '\uD83D\uDC61',
        'boot': '\uD83D\uDC62',
        'footprints': '\uD83D\uDC63',
        'bust-in-silhouette': '\uD83D\uDC64',
        'boy': '\uD83D\uDC66',
        'girl': '\uD83D\uDC67',
        'man': '\uD83D\uDC68',
        'woman': '\uD83D\uDC69',
        'family': '\uD83D\uDC6A',
        'couple': '\uD83D\uDC6B',
        'cop': '\uD83D\uDC6E',
        'dancers': '\uD83D\uDC6F',
        'bride-with-veil': '\uD83D\uDC70',
        'person-with-blond-hair': '\uD83D\uDC71',
        'man-with-gua-pi-mao': '\uD83D\uDC72',
        'man-with-turban': '\uD83D\uDC73',
        'older-man': '\uD83D\uDC74',
        'older-woman': '\uD83D\uDC75',
        'baby': '\uD83D\uDC76',
        'construction-worker': '\uD83D\uDC77',
        'princess': '\uD83D\uDC78',
        'japanese-ogre': '\uD83D\uDC79',
        'japanese-goblin': '\uD83D\uDC7A',
        'ghost': '\uD83D\uDC7B',
        'angel': '\uD83D\uDC7C',
        'alien': '\uD83D\uDC7D',
        'space-invader': '\uD83D\uDC7E',
        'imp': '\uD83D\uDC7F',
        'skull': '\uD83D\uDC80',
        'information-desk-person': '\uD83D\uDC81',
        'guardsman': '\uD83D\uDC82',
        'dancer': '\uD83D\uDC83',
        'lipstick': '\uD83D\uDC84',
        'nail-care': '\uD83D\uDC85',
        'massage': '\uD83D\uDC86',
        'haircut': '\uD83D\uDC87',
        'barber': '\uD83D\uDC88',
        'syringe': '\uD83D\uDC89',
        'pill': '\uD83D\uDC8A',
        'kiss': '\uD83D\uDC8B',
        'love-letter': '\uD83D\uDC8C',
        'ring': '\uD83D\uDC8D',
        'gem': '\uD83D\uDC8E',
        'couplekiss': '\uD83D\uDC8F',
        'bouquet': '\uD83D\uDC90',
        'couple-with-heart': '\uD83D\uDC91',
        'wedding': '\uD83D\uDC92',
        'heartbeat': '\uD83D\uDC93',
        'broken-heart': '\uD83D\uDC94',
        'two-hearts': '\uD83D\uDC95',
        'sparkling-heart': '\uD83D\uDC96',
        'heartpulse': '\uD83D\uDC97',
        'cupid': '\uD83D\uDC98',
        'blue-heart': '\uD83D\uDC99',
        'green-heart': '\uD83D\uDC9A',
        'yellow-heart': '\uD83D\uDC9B',
        'purple-heart': '\uD83D\uDC9C',
        'gift-heart': '\uD83D\uDC9D',
        'revolving-hearts': '\uD83D\uDC9E',
        'heart-decoration': '\uD83D\uDC9F',
        'diamond-shape-with-a-dot-inside': '\uD83D\uDCA0',
        'bulb': '\uD83D\uDCA1',
        'anger': '\uD83D\uDCA2',
        'bomb': '\uD83D\uDCA3',
        'zzz': '\uD83D\uDCA4',
        'boom': '\uD83D\uDCA5',
        'sweat-drops': '\uD83D\uDCA6',
        'droplet': '\uD83D\uDCA7',
        'dash': '\uD83D\uDCA8',
        'poop': '\uD83D\uDCA9',
        'muscle': '\uD83D\uDCAA',
        'dizzy': '\uD83D\uDCAB',
        'speech-balloon': '\uD83D\uDCAC',
        'white-flower': '\uD83D\uDCAE',
        '100': '\uD83D\uDCAF',
        'moneybag': '\uD83D\uDCB0',
        'currency-exchange': '\uD83D\uDCB1',
        'heavy-dollar-sign': '\uD83D\uDCB2',
        'credit-card': '\uD83D\uDCB3',
        'yen': '\uD83D\uDCB4',
        'dollar': '\uD83D\uDCB5',
        'money-with-wings': '\uD83D\uDCB8',
        'chart': '\uD83D\uDCB9',
        'seat': '\uD83D\uDCBA',
        'computer': '\uD83D\uDCBB',
        'briefcase': '\uD83D\uDCBC',
        'minidisc': '\uD83D\uDCBD',
        'floppy-disk': '\uD83D\uDCBE',
        'cd': '\uD83D\uDCBF',
        'dvd': '\uD83D\uDCC0',
        'file-folder': '\uD83D\uDCC1',
        'open-file-folder': '\uD83D\uDCC2',
        'page-with-curl': '\uD83D\uDCC3',
        'page-facing-up': '\uD83D\uDCC4',
        'date': '\uD83D\uDCC5',
        'calendar': '\uD83D\uDCC6',
        'card-index': '\uD83D\uDCC7',
        'chart-with-upwards-trend': '\uD83D\uDCC8',
        'chart-with-downwards-trend': '\uD83D\uDCC9',
        'bar-chart': '\uD83D\uDCCA',
        'clipboard': '\uD83D\uDCCB',
        'pushpin': '\uD83D\uDCCC',
        'round-pushpin': '\uD83D\uDCCD',
        'paperclip': '\uD83D\uDCCE',
        'straight-ruler': '\uD83D\uDCCF',
        'triangular-ruler': '\uD83D\uDCD0',
        'bookmark-tabs': '\uD83D\uDCD1',
        'ledger': '\uD83D\uDCD2',
        'notebook': '\uD83D\uDCD3',
        'notebook-with-decorative-cover': '\uD83D\uDCD4',
        'closed-book': '\uD83D\uDCD5',
        'book': '\uD83D\uDCD6',
        'green-book': '\uD83D\uDCD7',
        'blue-book': '\uD83D\uDCD8',
        'orange-book': '\uD83D\uDCD9',
        'books': '\uD83D\uDCDA',
        'name-badge': '\uD83D\uDCDB',
        'scroll': '\uD83D\uDCDC',
        'pencil': '\uD83D\uDCDD',
        'telephone-receiver': '\uD83D\uDCDE',
        'pager': '\uD83D\uDCDF',
        'fax': '\uD83D\uDCE0',
        'satellite': '\uD83D\uDCE1',
        'loudspeaker': '\uD83D\uDCE2',
        'mega': '\uD83D\uDCE3',
        'outbox-tray': '\uD83D\uDCE4',
        'inbox-tray': '\uD83D\uDCE5',
        'package': '\uD83D\uDCE6',
        'e-mail': '\uD83D\uDCE7',
        'incoming-envelope': '\uD83D\uDCE8',
        'envelope-with-arrow': '\uD83D\uDCE9',
        'mailbox-closed': '\uD83D\uDCEA',
        'mailbox': '\uD83D\uDCEB',
        'postbox': '\uD83D\uDCEE',
        'newspaper': '\uD83D\uDCF0',
        'iphone': '\uD83D\uDCF1',
        'calling': '\uD83D\uDCF2',
        'vibration-mode': '\uD83D\uDCF3',
        'mobile-phone-off': '\uD83D\uDCF4',
        'signal-strength': '\uD83D\uDCF6',
        'camera': '\uD83D\uDCF7',
        'video-camera': '\uD83D\uDCF9',
        'tv': '\uD83D\uDCFA',
        'radio': '\uD83D\uDCFB',
        'vhs': '\uD83D\uDCFC',
        'arrows-clockwise': '\uD83D\uDD03',
        'loud-sound': '\uD83D\uDD0A',
        'battery': '\uD83D\uDD0B',
        'electric-plug': '\uD83D\uDD0C',
        'mag': '\uD83D\uDD0D',
        'mag-right': '\uD83D\uDD0E',
        'lock-with-ink-pen': '\uD83D\uDD0F',
        'closed-lock-with-key': '\uD83D\uDD10',
        'key': '\uD83D\uDD11',
        'lock': '\uD83D\uDD12',
        'unlock': '\uD83D\uDD13',
        'bell': '\uD83D\uDD14',
        'bookmark': '\uD83D\uDD16',
        'link': '\uD83D\uDD17',
        'radio-button': '\uD83D\uDD18',
        'back': '\uD83D\uDD19',
        'end': '\uD83D\uDD1A',
        'on': '\uD83D\uDD1B',
        'soon': '\uD83D\uDD1C',
        'top': '\uD83D\uDD1D',
        'underage': '\uD83D\uDD1E',
        'keycap-ten': '\uD83D\uDD1F',
        'capital-abcd': '\uD83D\uDD20',
        'abcd': '\uD83D\uDD21',
        '1234': '\uD83D\uDD22',
        'symbols': '\uD83D\uDD23',
        'abc': '\uD83D\uDD24',
        'fire': '\uD83D\uDD25',
        'flashlight': '\uD83D\uDD26',
        'wrench': '\uD83D\uDD27',
        'hammer': '\uD83D\uDD28',
        'nut-and-bolt': '\uD83D\uDD29',
        'knife': '\uD83D\uDD2A',
        'gun': '\uD83D\uDD2B',
        'crystal-ball': '\uD83D\uDD2E',
        'six-pointed-star': '\uD83D\uDD2F',
        'beginner': '\uD83D\uDD30',
        'trident': '\uD83D\uDD31',
        'black-square-button': '\uD83D\uDD32',
        'white-square-button': '\uD83D\uDD33',
        'red-circle': '\uD83D\uDD34',
        'large-blue-circle': '\uD83D\uDD35',
        'large-orange-diamond': '\uD83D\uDD36',
        'large-blue-diamond': '\uD83D\uDD37',
        'small-orange-diamond': '\uD83D\uDD38',
        'small-blue-diamond': '\uD83D\uDD39',
        'small-red-triangle': '\uD83D\uDD3A',
        'small-red-triangle-down': '\uD83D\uDD3B',
        'arrow-up-small': '\uD83D\uDD3C',
        'arrow-down-small': '\uD83D\uDD3D',
        'clock1': '\uD83D\uDD50',
        'clock2': '\uD83D\uDD51',
        'clock3': '\uD83D\uDD52',
        'clock4': '\uD83D\uDD53',
        'clock5': '\uD83D\uDD54',
        'clock6': '\uD83D\uDD55',
        'clock7': '\uD83D\uDD56',
        'clock8': '\uD83D\uDD57',
        'clock9': '\uD83D\uDD58',
        'clock10': '\uD83D\uDD59',
        'clock11': '\uD83D\uDD5A',
        'clock12': '\uD83D\uDD5B',
        'mount-fuji': '\uD83D\uDDFB',
        'tokyo-tower': '\uD83D\uDDFC',
        'statue-of-liberty': '\uD83D\uDDFD',
        'japan': '\uD83D\uDDFE',
        'moyai': '\uD83D\uDDFF',
        'grin': '\uD83D\uDE01',
        'joy': '\uD83D\uDE02',
        'smiley': '\uD83D\uDE03',
        'smile': '\uD83D\uDE04',
        'sweat-smile': '\uD83D\uDE05',
        'laughing': '\uD83D\uDE06',
        'wink': '\uD83D\uDE09',
        'blush': '\uD83D\uDE0A',
        'yum': '\uD83D\uDE0B',
        'relieved': '\uD83D\uDE0C',
        'heart-eyes': '\uD83D\uDE0D',
        'smirk': '\uD83D\uDE0F',
        'unamused': '\uD83D\uDE12',
        'sweat': '\uD83D\uDE13',
        'pensive': '\uD83D\uDE14',
        'confounded': '\uD83D\uDE16',
        'kissing-heart': '\uD83D\uDE18',
        'kissing-closed-eyes': '\uD83D\uDE1A',
        'stuck-out-tongue-winking-eye': '\uD83D\uDE1C',
        'stuck-out-tongue-closed-eyes': '\uD83D\uDE1D',
        'disappointed': '\uD83D\uDE1E',
        'angry': '\uD83D\uDE20',
        'rage': '\uD83D\uDE21',
        'cry': '\uD83D\uDE22',
        'persevere': '\uD83D\uDE23',
        'triumph': '\uD83D\uDE24',
        'disappointed-relieved': '\uD83D\uDE25',
        'fearful': '\uD83D\uDE28',
        'weary': '\uD83D\uDE29',
        'sleepy': '\uD83D\uDE2A',
        'tired-face': '\uD83D\uDE2B',
        'sob': '\uD83D\uDE2D',
        'cold-sweat': '\uD83D\uDE30',
        'scream': '\uD83D\uDE31',
        'astonished': '\uD83D\uDE32',
        'flushed': '\uD83D\uDE33',
        'dizzy-face': '\uD83D\uDE35',
        'mask': '\uD83D\uDE37',
        'smile-cat': '\uD83D\uDE38',
        'joy-cat': '\uD83D\uDE39',
        'smiley-cat': '\uD83D\uDE3A',
        'heart-eyes-cat': '\uD83D\uDE3B',
        'smirk-cat': '\uD83D\uDE3C',
        'kissing-cat': '\uD83D\uDE3D',
        'pouting-cat': '\uD83D\uDE3E',
        'crying-cat-face': '\uD83D\uDE3F',
        'scream-cat': '\uD83D\uDE40',
        'no-good': '\uD83D\uDE45',
        'ok-woman': '\uD83D\uDE46',
        'bow': '\uD83D\uDE47',
        'see-no-evil': '\uD83D\uDE48',
        'hear-no-evil': '\uD83D\uDE49',
        'speak-no-evil': '\uD83D\uDE4A',
        'raising-hand': '\uD83D\uDE4B',
        'raised-hands': '\uD83D\uDE4C',
        'person-frowning': '\uD83D\uDE4D',
        'person-with-pouting-face': '\uD83D\uDE4E',
        'pray': '\uD83D\uDE4F',
        'rocket': '\uD83D\uDE80',
        'railway-car': '\uD83D\uDE83',
        'bullettrain-side': '\uD83D\uDE84',
        'bullettrain-front': '\uD83D\uDE85',
        'metro': '\uD83D\uDE87',
        'station': '\uD83D\uDE89',
        'bus': '\uD83D\uDE8C',
        'busstop': '\uD83D\uDE8F',
        'ambulance': '\uD83D\uDE91',
        'fire-engine': '\uD83D\uDE92',
        'police-car': '\uD83D\uDE93',
        'taxi': '\uD83D\uDE95',
        'red-car': '\uD83D\uDE97',
        'blue-car': '\uD83D\uDE99',
        'truck': '\uD83D\uDE9A',
        'ship': '\uD83D\uDEA2',
        'speedboat': '\uD83D\uDEA4',
        'traffic-light': '\uD83D\uDEA5',
        'construction': '\uD83D\uDEA7',
        'rotating-light': '\uD83D\uDEA8',
        'triangular-flag-on-post': '\uD83D\uDEA9',
        'door': '\uD83D\uDEAA',
        'no-entry-sign': '\uD83D\uDEAB',
        'smoking': '\uD83D\uDEAC',
        'no-smoking': '\uD83D\uDEAD',
        'bike': '\uD83D\uDEB2',
        'walking': '\uD83D\uDEB6',
        'mens': '\uD83D\uDEB9',
        'womens': '\uD83D\uDEBA',
        'restroom': '\uD83D\uDEBB',
        'baby-symbol': '\uD83D\uDEBC',
        'toilet': '\uD83D\uDEBD',
        'wc': '\uD83D\uDEBE',
        'bath': '\uD83D\uDEC0',
        'articulated-lorry': '\uD83D\uDE9B',
        'kissing-smiling-eyes': '\uD83D\uDE19',
        'pear': '\uD83C\uDF50',
        'bowtie': '\u22c8',
        'bicyclist': '\uD83D\uDEB4',
        'rabbit2': '\uD83D\uDC07',
        'clock830': '\uD83D\uDD63',
        'train': '\uD83D\uDE8B',
        // 'squirrel': '',
        'oncoming-automobile': '\uD83D\uDE98',
        'expressionless': '\uD83D\uDE11',
        'smiling-imp': '\uD83D\uDE08',
        'frowning': '\uD83D\uDE26',
        'no-mouth': '\uD83D\uDE36',
        'baby-bottle': '\uD83C\uDF7C',
        'non-potable-water': '\uD83D\uDEB1',
        'open-mouth': '\uD83D\uDE2E',
        'last-quarter-moon-with-face': '\uD83C\uDF1C',
        'do-not-litter': '\uD83D\uDEAF',
        'sunglasses': '\uD83D\uDE0E',
        // 'metal': '',
        'loop': '\u27BF',
        'last-quarter-moon': '\uD83C\uDF17',
        'grinning': '\uD83D\uDE00',
        'euro': '\uD83D\uDCB6',
        'clock330': '\uD83D\uDD5E',
        // 'octocat': '',
        'telescope': '\uD83D\uDD2D',
        'globe-with-meridians': '\uD83C\uDF10',
        'postal-horn': '\uD83D\uDCEF',
        'stuck-out-tongue': '\uD83D\uDE1B',
        // 'shipit': '',
        'clock1030': '\uD83D\uDD65',
        'pound': '\uD83D\uDCB7',
        'two-men-holding-hands': '\uD83D\uDC6C',
        'tiger2': '\uD83D\uDC05',
        'anguished': '\uD83D\uDE27',
        'vertical-traffic-light': '\uD83D\uDEA6',
        'confused': '\uD83D\uDE15',
        'repeat': '\uD83D\uDD01',
        'oncoming-police-car': '\uD83D\uDE94',
        'tram': '\uD83D\uDE8A',
        'dragon': '\uD83D\uDC09',
        'earth-americas': '\uD83C\uDF0E',
        'rugby-football': '\uD83C\uDFC9',
        'left-luggage': '\uD83D\uDEC5',
        'sound': '\uD83D\uDD09',
        'clock630': '\uD83D\uDD61',
        'dromedary-camel': '\uD83D\uDC2A',
        'oncoming-bus': '\uD83D\uDE8D',
        'horse-racing': '\uD83C\uDFC7',
        // 'finnadie': '',
        'rooster': '\uD83D\uDC13',
        'rowboat': '\uD83D\uDEA3',
        'customs': '\uD83D\uDEC3',
        'repeat-one': '\uD83D\uDD02',
        'waxing-crescent-moon': '\uD83C\uDF12',
        'mountain-railway': '\uD83D\uDE9E',
        'clock930': '\uD83D\uDD64',
        'put-litter-in-its-place': '\uD83D\uDEAE',
        'arrows-counterclockwise': '\uD83D\uDD04',
        'clock130': '\uD83D\uDD5C',
        'goat': '\uD83D\uDC10',
        'pig2': '\uD83D\uDC16',
        'innocent': '\uD83D\uDE07',
        'no-bicycles': '\uD83D\uDEB3',
        'light-rail': '\uD83D\uDE88',
        'whale2': '\uD83D\uDC0B',
        'train2': '\uD83D\uDE86',
        'earth-africa': '\uD83C\uDF0D',
        'shower': '\uD83D\uDEBF',
        'waning-gibbous-moon': '\uD83C\uDF16',
        // 'hurtrealbad': '',
        'steam-locomotive': '\uD83D\uDE82',
        'cat2': '\uD83D\uDC08',
        'tractor': '\uD83D\uDE9C',
        // 'goberserk': '',
        'thought-balloon': '\uD83D\uDCAD',
        'two-women-holding-hands': '\uD83D\uDC6D',
        'full-moon-with-face': '\uD83C\uDF1D',
        'mouse2': '\uD83D\uDC01',
        'clock430': '\uD83D\uDD5F',
        'worried': '\uD83D\uDE1F',
        'rat': '\uD83D\uDC00',
        'ram': '\uD83D\uDC0F',
        'dog2': '\uD83D\uDC15',
        'kissing': '\uD83D\uDE17',
        'helicopter': '\uD83D\uDE81',
        'clock1130': '\uD83D\uDD66',
        //'rage4': '',
        //'rage3': '',
        //'rage2': '',
        //'rage1': '',
        'no-mobile-phones': '\uD83D\uDCF5',
        'european-post-office': '\uD83C\uDFE4',
        'ox': '\uD83D\uDC02',
        'mountain-cableway': '\uD83D\uDEA0',
        'sleeping': '\uD83D\uDE34',
        'cow2': '\uD83D\uDC04',
        //'godmode': '',
        'minibus': '\uD83D\uDE90',
        'clock730': '\uD83D\uDD62',
        'aerial-tramway': '\uD83D\uDEA1',
        'speaker': '\uD83D\uDD08',
        'no-bell': '\uD83D\uDD15',
        'mailbox-with-mail': '\uD83D\uDCEC',
        'no-pedestrians': '\uD83D\uDEB7',
        'microscope': '\uD83D\uDD2C',
        //'neckbeard': '',
        'bathtub': '\uD83D\uDEC1',
        'suspension-railway': '\uD83D\uDE9F',
        //'trollface': '',
        'crocodile': '\uD83D\uDC0A',
        'mountain-bicyclist': '\uD83D\uDEB5',
        'waning-crescent-moon': '\uD83C\uDF18',
        'monorail': '\uD83D\uDE9D',
        'children-crossing': '\uD83D\uDEB8',
        'clock230': '\uD83D\uDD5D',
        'busts-in-silhouette': '\uD83D\uDC65',
        'mailbox-with-no-mail': '\uD83D\uDCED',
        'leopard': '\uD83D\uDC06',
        //'suspect': '',
        'deciduous-tree': '\uD83C\uDF33',
        'oncoming-taxi': '\uD83D\uDE96',
        //'fu': '',
        'lemon': '\uD83C\uDF4B',
        'mute': '\uD83D\uDD07',
        'baggage-claim': '\uD83D\uDEC4',
        'twisted-rightwards-arrows': '\uD83D\uDD00',
        //'feelsgood': '',
        'sun-with-face': '\uD83C\uDF1E',
        'trolleybus': '\uD83D\uDE8E',
        'evergreen-tree': '\uD83C\uDF32',
        'passport-control': '\uD83D\uDEC2',
        'new-moon-with-face': '\uD83C\uDF1A',
        'potable-water': '\uD83D\uDEB0',
        'high-brightness': '\uD83D\uDD06',
        'low-brightness': '\uD83D\uDD05',
        'clock530': '\uD83D\uDD60',
        'hushed': '\uD83D\uDE2F',
        'grimacing': '\uD83D\uDE2C',
        'water-buffalo': '\uD83D\uDC03',
        'neutral-face': '\uD83D\uDE10',
        'clock1230': '\uD83D\uDD67'
    */

        'flowers': ['💐'],
        'palmtree': ['🌴'],
        'clover': ['☘️'],

        'arm': ['💪'],

        'engineer': ['👨‍💻|👩‍💻'],

        'fries': ['🍟'],
        'pasta': ['🍝'],
        'donut': ['🍩'],

        'alcohol': ['🍹'],
        'icecream': ['🍦'],

        'postoffice': ['🏤'],

        'car': ['🚗'],
        'racecar': ['🏎️'],
        'spaceship': ['🛸'],

        'sunny': ['🌞'],

        'lightbulb': ['💡'],
        'alarmclock': ['⏰'],

        'walk': ['🚶‍♂️|🚶‍♀️'],
        'stand': ['🧍‍♂️|🧍‍♀️'],
        'kneel': ['🧎‍♂️|🧎‍♀️'],
        'run': ['🏃‍♂️|🏃‍♀️'],
        'dance': ['🕺|💃'],
        'surf': ['🏄‍♂️|🏄‍♀️'],
        'row': ['🚣‍♂️|🚣‍♀️'],
        'swim': ['🏊‍♂️|🏊‍♀️'],
        'lift': ['🏋️‍♂️|🏋️‍♀️'],
        'climb': ['🧗‍♂️|🧗‍♀️'],
        'bike': ['🚴‍♂️|🚴‍♀️'],
        'juggle': ['🤹‍♂️|🤹‍♀️'],
        'meditate': ['🧘‍♂️|🧘‍♀️'],
        'ski': ['⛷️'],
        'snowboard': ['🏂'],

        'plug': ['🔌'],

        'hundred': ['💯'],

        'pingpong': ['🏓'],

        'currency': ['💱'],
        'crossing': ['🚸'],
        'wrongway': ['⛔'],
        'trash': ['🚮'],
        'men': ['🚹'],
        'women': ['🚺'],
        'bathroom': ['🚻'],
        'passport': ['🛂'],
        'customs': ['🛃'],
        'baggage': ['🛄'],
        'locker': ['🛅'],

        'circle': ['🔴|🟠|🟡|🟢|🔵|🟣|🟤|⚫|⚪'],
        'square': ['🟥|🟧|🟨|🟩|🟦|🟪|🟫|⬛|⬜'],
        'diamond': ['🔶|🔷'],
        'triangle': ['🔺|🔻'],

        'letters': ['🔡|🔠|🔤'],
        'numbers': ['🔢'],
        'symbols': ['🔣'],

        'shuffle': ['🔀'],
        'repeat': ['🔁'],
        'backward': ['⏪'],
        'forward': ['⏩'],
        'next': ['⏭️'],
        'last': ['⏮️'],

        'ophiuchus': ['⛎'],

        'bricks': ['🧱'],
        'crane': ['🏗️'],
        'coaster': ['🎢'],
        'wheel': ['🎡'],
        'gas': ['⛽'],
        'vote': ['🗳️'],
        'cart': ['🛒'],

};

const SenseTypeEmoji = new Map([
    ['<a_all>', '🔻'], // red
    ['<r_all>', '🔷'], // blue
    ['<pert>', '📎'],
    ['<Tops>', '⭐'],
    ['<act>', '🏃‍♂️|🏃‍♀️'],
    ['<animal>', '🦖|🦕|🦜'],
    ['<artifact>', '⚙️|🧱'],
    ['<attribute>', '🔎'],
    ['<n_body>', '💪|🦵|🦶|🖐️'],
    ['<n_cognition>', '🧠'],
    ['<n_communication>', '☎️|📱'],
    ['<event>', '📰'],
    ['<feeling>', '😀|☹️'],
    ['<food>', '🍴|🥢'],
    ['<group>', '🧰|📁'],
    ['<location>', '🌍|🌍|🌎|🌏'],
    ['<motive>', '💡'],
    ['<object>', '🎗️'],
    ['<person>', '🧑|👨|👩'],
    ['<phenomenon>', '🌋|🔥|🌪️'],
    ['<plant>', '🌱|🌹|🌺|🌼|🌷|🍂|🌿|🌵|🌴|🌲'],
    ['<n_possession>', '🔒'],
    ['<process>', '📋'],
    ['<quantity>', '⚖️|📏'],
    ['<relation>', '👪'],
    ['<shape>', '🔺'],
    ['<state>', '⚓'],
    ['<substance>', '🔬'],
    ['<time>', '⏳|📅|⏰|⏱️|🕰️'],
    ['<v_body>', '🕺|💃'],
    ['<change>', '🦋'],
    ['<v_cognition>', '🤔|😕'],
    ['<v_communication>', '🗣️|🔊|🎤'],
    ['<competition>', '🏆|🏁'],
    ['<consumption>', '💵'],
    ['<contact>', '🧲|🧩'],
    ['<creation>', '🔧|🔨'],
    ['<emotion>', '😢|😠|😬|😨|😰|😲|😞|😣|😫|😡'],
    ['<motion>', '🎡|🚀|🏎️'],
    ['<perception>', '👀|👁️|👃|👂|👅'],
    ['<v_possession>', '🔑'],
    ['<social>', '🗨️|📧|✉️'],
    ['<stative>', '🧘‍♀️|🧘‍♂️'],
    ['<weather>', '☁️|🌧️|🌨️|🌩️'],
    ['<ppl>', '🎲'],
    ['<root>', '⬆️'],
    ['<lemma>', '⬇️'],
]);

export const IsSenseType = value => {
    return value.length > 1 && value.charAt(0) === '<' && value.charAt(value.length - 1) === '>' ? true : false;
};

export const GetEmoji = value => {
    const emojiValue = Validate.isValidNonEmptyString(value) ? emojis[value.replace(/_/g, '-')] : null;
    const emojiString = Validate.isValid(emojiValue) && emojiValue.length ? emojiValue[0] : null;
    const emojiOpts = Validate.isValidNonEmptyString(emojiString) ? emojiString.split('|') : null;
    return Numbers.randomItem(emojiOpts);
};

export const GetSenseTypeEmoji = (value, pos = null) => {
    var emoji = SenseTypeEmoji.get(value);
    if (Validate.isValid(emoji)) {
        const emojiOpts = emoji.split('|');
        emoji = Numbers.randomItem(emojiOpts);
    }
    return emoji;
};

export const GetNodeEmoji = value => {
    return IsSenseType(value) ? GetSenseTypeEmoji(value) : GetEmoji(value);
};
