import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, GetEmoji, Input, Picker, Screen, Text, View } from '../components';
import { SearchResults } from './SearchResults';
import { useAppState, useSystemState } from '../context';
import { useVocabulary } from '../hooks';
import { Vocabulary, WILDCARD } from '../vocabulary';
import { Console, Numbers, Optional } from '../utils';
import { LANGUAGES } from '../constants';
import { Colors } from '../styles';


const NAME = 'Word5';

const LOADING = 'Loading';

const WORDLE_LENGTH = 5;
const INPUT_DIMENSIONS_WIDTH = Math.floor(100.0 * (1.0 / (WORDLE_LENGTH + 2.0)));
const INPUT_DIMENSIONS = {
    width: `${INPUT_DIMENSIONS_WIDTH}%`,
};


export const Word5 = props => {

    const { width } = useSystemState();
    const { t, language } = useAppState();

    const { vocabularyLoaded, vocabularyProgress } = useVocabulary();

    const [lang] = useState(language.split('_')[0]);

    const [data, setData] = useState([]);
    const setDataRef = useRef(setData);

    const [items, setItems] = useState(['']);
    const setItemsRef = useRef(setItems);

    const [pos1, setPos1] = useState('');
    const setPos1Ref = useRef(setPos1);
    const [pos2, setPos2] = useState('');
    const setPos2Ref = useRef(setPos2);
    const [pos3, setPos3] = useState('');
    const setPos3Ref = useRef(setPos3);
    const [pos4, setPos4] = useState('');
    const setPos4Ref = useRef(setPos4);
    const [pos5, setPos5] = useState('');
    const setPos5Ref = useRef(setPos5);

    const [any, setAny] = useState('');
    const setAnyRef = useRef(setAny);
    const [none, setNone] = useState('');
    const setNoneRef = useRef(setNone);

    useEffect(
        () => {

            if (!vocabularyLoaded) {
                return;
            }

            const listData = Vocabulary.List(lang, WILDCARD);

            var itemsArray = [];
            const listDataKeys = [...listData.keys()]
                .filter(v => {
                    if (v.length !== WORDLE_LENGTH || !/^[a-záàâãăçéèêëíìîïñóòôõşșţțúùûüÿ]{5}$/.test(v)) {
                        return false;
                    }
                    if ((pos1.length && v.charAt(0) !== pos1.charAt(0)) ||
                        (pos2.length && v.charAt(1) !== pos2.charAt(0)) ||
                        (pos3.length && v.charAt(2) !== pos3.charAt(0)) ||
                        (pos4.length && v.charAt(3) !== pos4.charAt(0)) ||
                        (pos5.length && v.charAt(4) !== pos5.charAt(0))) {
                        return false;
                    }
                    const anyChars = any.split('');
                    for (let i = 0; i < anyChars.length; i++) {
                        const c = anyChars[i];
                        if (v.indexOf(c) === -1) {
                            return false;
                        }
                    }
                    const noneChars = none.split('');
                    for (let i = 0; i < noneChars.length; i++) {
                        const c = noneChars[i];
                        if (v.indexOf(c) !== -1) {
                            return false;
                        }
                    }
                    return true;
                }).sort();

            listDataKeys.forEach((key, id) => {
                const value = listData.get(key);
                const emoji = GetEmoji(key);
                var item = {
                    key,
                    id,
                    pos: Object.keys(value.w).map(v => Vocabulary.GetPos(v)),
                };
                if (emoji) {
                    item.emoji = emoji;
                }
                if (value?.i) {
                    if (value.i?.p) {
                        item.ipa = value.i.p;
                    }
                    if (value.i?.r) {
                        item.rank = value.i.r;
                    }
                    if (value.i?.l) {
                        item.root = value.i.l;
                    }
                    if (value.i?.c) {
                        item.cognates = value.i.c.split('|');
                    }
                }
                itemsArray.push(item);
            });

            Console.log(`${NAME} useEffect list`, { language, lang, listData, itemsArray });

            setDataRef.current(itemsArray);
            setItemsRef.current(['', ...LANGUAGES[language].lc.split('')]);
        },
        [
            vocabularyLoaded,
            language,
            lang,
            setDataRef,
            setItemsRef,
            pos1,
            pos2,
            pos3,
            pos4,
            pos5,
            any,
            none,
        ],
    );

    const renderInput = useCallback(
        (selected, onChange) => {
            return (
                <Picker
                    width={INPUT_DIMENSIONS_WIDTH * width}
                    viewStyle={[styles.picker, INPUT_DIMENSIONS]}
                    selected={selected}
                    items={items}
                    onChange={onChange}
                />
            );
        },
        [
            items,
            width,
        ],
    );

    Console.stack(NAME, props, { language, lang, width, data, pos1, pos2, pos3, pos4, pos5, any, none, vocabularyLoaded, vocabularyProgress });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { pos1, pos2, pos3, pos4, pos5, any, none });
            return (
                <Screen
                    {...props}
                    value={NAME}
                >
                    {Optional(vocabularyLoaded, (
                        <>
                            <View
                                value={'Word5Controls'}
                                style={styles.controls}
                            >
                                {renderInput(pos1, text => setPos1Ref.current(text.substring(0, 1).toLowerCase()))}
                                {renderInput(pos2, text => setPos2Ref.current(text.substring(0, 1).toLowerCase()))}
                                {renderInput(pos3, text => setPos3Ref.current(text.substring(0, 1).toLowerCase()))}
                                {renderInput(pos4, text => setPos4Ref.current(text.substring(0, 1).toLowerCase()))}
                                {renderInput(pos5, text => setPos5Ref.current(text.substring(0, 1).toLowerCase()))}
                                <View
                                    value={'Word5Any'}
                                    style={[styles.any, INPUT_DIMENSIONS]}
                                >
                                    <Input
                                        title={'✔️'}
                                        value={any}
                                        onChangeText={text => setAnyRef.current(text.substring(0, 5).toLowerCase())}
                                    />
                                </View>
                                <View
                                    value={'Word5None'}
                                    style={[styles.none, INPUT_DIMENSIONS]}
                                >
                                    <Input
                                        title={'❌'}
                                        value={none}
                                        onChangeText={text => setNoneRef.current(text.toLowerCase())}
                                    />
                                </View>
                            </View>
                            <SearchResults
                                data={data}
                            />
                        </>
                    ), (
                        <View
                            style={styles.view}
                        >
                            <ActivityIndicator />
                            <Text
                                value={`${t(LOADING)} ${Numbers.percentage(vocabularyProgress, 0)}`}
                            />
                        </View>
                    ))}

                </Screen>
            );
        },
        [
            t,
            props,
            data,
            pos1,
            pos2,
            pos3,
            pos4,
            pos5,
            any,
            none,
            setPos1Ref,
            setPos2Ref,
            setPos3Ref,
            setPos4Ref,
            setPos5Ref,
            setAnyRef,
            setNoneRef,
            renderInput,
            vocabularyLoaded,
            vocabularyProgress,
        ],
    );
};

const styles = StyleSheet.create({
    view: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'center',
        paddingVertical: '33%',
    },
    controls: {
        flexDirection: 'row',
        width: '100%',
        height: '10.5%',
        justifyContent: 'space-between',
        alignContent: 'center',
    },
    any: {
        backgroundColor: Colors.colors.yellow,
        padding: '1%',
    },
    none: {
        backgroundColor: Colors.colors.red,
        padding: '1%',
    },
    picker: {
        backgroundColor: Colors.colors.green,
        padding: '1%',
    },
    progressBar: {
        width: '300',
        height: '50',
    },
});
