import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Card, FlatList, Network, Screen, Text, TEXT_VARIANTS } from '../components';
import { APP_TYPES, useAppDispatch, useAppState, useSystemState } from '../context';
import { Vocabulary } from '../vocabulary';
import { Console, Validate } from '../utils';
import { Colors, Words } from '../styles';

const NAME = 'Graph';

const MIN_RANK = 0;


export const Graph = props => {

    const {
        navigation,
    } = props;

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { deviceScale, adjHeight, width } = useSystemState();
    const { DEFAULT, dark, language, search } = useAppState();
    const { GRAPH_DETAIL_HEIGHT, GRAPH_INFO_HEIGHT, SELECT_DISTANCE, TEXT_HEIGHT } = DEFAULT;

    const [detailHeight, setDetailHeight] = useState(GRAPH_DETAIL_HEIGHT * deviceScale);
    const setDetailHeightRef = useRef(setDetailHeight);
    const [infoHeight, setInfoHeight] = useState(GRAPH_INFO_HEIGHT * deviceScale);
    const setInfoHeightRef = useRef(setInfoHeight);
    const [graphHeight, setGraphHeight] = useState();
    const setGraphHeightRef = useRef(setGraphHeight);

    const [lang] = useState(language.split('_')[0]);

    const [node, setNode] = useState();
    const setNodeRef = useRef(setNode);
    const [data, setData] = useState(Vocabulary.Graph(lang, '', graphHeight, width, TEXT_HEIGHT));
    const setDataRef = useRef(setData);
    const [detail, setDetail] = useState();
    const setDetailRef = useRef(setDetail);
    const [info, setInfo] = useState([]);
    const setInfoRef = useRef(setInfo);
    const [dragging, setDragging] = useState(false);
    const setDraggingRef = useRef(setDragging);

    useEffect(
        () => {
            Console.log(`${NAME} useEffect entry`, { search });
            navigation.setOptions({ headerTitle: ({ tintColor }) => <Text value={search.replace(/_/g, ' ')} color={tintColor} variant={TEXT_VARIANTS.TITLE} /> });
        },
        [
            search,
            navigation,
        ],
    );

    useEffect(
        () => {
            const newGraphHeight = adjHeight - (detailHeight + infoHeight);
            Console.log(`${NAME} useEffect height`, { adjHeight, detailHeight, infoHeight, newGraphHeight });
            setGraphHeightRef.current(newGraphHeight);
        },
        [
            adjHeight,
            detailHeight,
            infoHeight,
            setGraphHeightRef,
        ],
    );

    useEffect(
        () => {
            if (graphHeight <= 0) {
                return;
            }
            Console.log(`${NAME} useEffect data before`, { lang, search, graphHeight, width, graph });
            const graph = Vocabulary.Graph(lang, search, graphHeight, width, TEXT_HEIGHT, true, MIN_RANK);
            Console.log(`${NAME} useEffect data`, { lang, search, graphHeight, width, graph });

            setDataRef.current(graph);

            var infoArray = [];
            var detailText = '';
            const searchResult = graph.graph.get(search);
            if (searchResult) {
                if (searchResult?.targetIds) {
                    searchResult.targetIds.forEach((_, targetId) =>
                        infoArray.push(graph.graph.get(targetId).node.info));
                } else {
                    Console.LOG(`${NAME} missing targetIds`, { search, graph });
                }
                if (searchResult?.node && searchResult.node?.info) {
                    const startIndex = searchResult.node.info.indexOf('|') + 1;
                    detailText = `${searchResult.node.info.substring(startIndex).replace(/\|/g, ', ')}`;
                    if (!Validate.isValidNonEmptyString(detailText)) {
                        detailText = search.replace(/_/g, ' ');
                    }
                } else {
                    Console.LOG(`${NAME} missing node`, { search, graph });
                }
            } else {
                Console.log(`${NAME} missing searchResult`, { search, graph }); // MARKMARK: Hitting this!
            }

            setInfoRef.current(infoArray);
            setDetailRef.current(detailText);
        },
        [
            TEXT_HEIGHT,
            lang,
            search,
            graphHeight,
            width,
            setDataRef,
            setInfoRef,
            setDetailRef,
        ],
    );

    const onSelect = useCallback(
        (selection, x, y, h, w) => {
            const payload = selection.id.split('%')[0];
            Console.log(`${NAME}.onSelect`, { payload, selection, x, y, h, w });
            setNodeRef.current(selection);
            appDispatchRef.current({
                type: APP_TYPES.SET_SELECTION,
                payload,
            });
            return selection?.info;
        },
        [
            setNodeRef,
            appDispatchRef,
        ],
    );

    const onRelease = useCallback(
        (selection, x, y, h, w) => {
            const payload = selection.id.split('%')[0];
            Console.log(`${NAME}.onRelease`, { payload, selection, x, y, h, w });
            if (!/[0-9]/.test(payload) &&
                (x < 0 || x > w || y < 0 || y > h)) {
                appDispatchRef.current({
                    type: APP_TYPES.SET_SEARCH,
                    payload,
                });
            }
            setNodeRef.current(null);
            appDispatchRef.current({
                type: APP_TYPES.SET_SELECTION,
                payload: null,
            });
        },
        [
            appDispatchRef,
            setNodeRef,
        ],
    );

    const onHeightChange = useCallback(
        (heights, drag) => {
            Console.log(`${NAME}.onHeightChange`, { heights, drag });
            if (Validate.isValid(drag)) {
                setDraggingRef.current(drag);
            }
            if (heights?.header?.height) {
                setDetailHeightRef.current(heights.header.height);
            }
            if (heights?.footer?.height) {
                setInfoHeightRef.current(heights.footer.height);
            }
        },
        [
            setDetailHeightRef,
            setInfoHeightRef,
            setDraggingRef,
        ],
    );

    const renderInfoItem = useCallback(
        item => {
            const pos = item.item.messages[0];
            const type = item.item.messages[1];
            const _type = type === 'all' ? '' : `[${type}] `;
            const def = item.item.messages[2];
            const title = `${_type}${def}`;
            const messages = item.item.messages.slice(3);
            const titleTextStyle = {
                color: Words.Pos.get(pos).color,
            };

            return (
                <Card
                    id={item.item.id}
                    title={title}
                    titleTextStyle={titleTextStyle}
                    titleLines={3}
                    titleVariant={TEXT_VARIANTS.PARAGRAPH}
                    content={messages.length ? messages.map((m, i) => <Text id={i} value={`- ${m}`} variant={TEXT_VARIANTS.INFO} />) : null}
                />
            );
        },
        [
        ],
    );

    const renderInfo = useMemo(
        () => {
            var infoMessages = [];
            info.forEach((infoMessage, id) => {
                infoMessages.push({
                    id,
                    messages: infoMessage.split('|'),
                });
            });

            Console.log(`${NAME}.renderInfo`, { info, infoMessages });

            return (
                <FlatList
                    data={infoMessages}
                    renderItem={renderInfoItem}
                />
            );
        },
        [
            info,
            renderInfoItem,
        ],
    );

    Console.stack(NAME, props, { GRAPH_DETAIL_HEIGHT, GRAPH_INFO_HEIGHT, SELECT_DISTANCE, TEXT_HEIGHT, deviceScale, language, lang, search, info, width, adjHeight, detailHeight, graphHeight, infoHeight, node, data, dark });

    return useMemo(
        () => {
            const detailColorStyle = { backgroundColor: dark ? Colors.colors.gray : Colors.colors.darkgray };
            Console.log(`${NAME} render`, { detailHeight, graphHeight, infoHeight, detailColorStyle });
            return (
                <Screen
                    {...props}
                    value={NAME}
                    headerHeight={detailHeight}
                    headerStyle={[styles.detail, detailColorStyle]}
                    headerView={(
                        <Text
                            value={detail}
                            variant={TEXT_VARIANTS.PARAGRAPH}
                        />
                    )}
                    mainHeight={graphHeight}
                    mainStyle={styles.graph}
                    mainView={(
                        <Network
                            data={data}
                            selectDistance={SELECT_DISTANCE * deviceScale}
                            height={graphHeight}
                            width={width}
                            onSelect={onSelect}
                            onRelease={onRelease}
                            dragging={dragging}
                        />
                    )}
                    footerHeight={infoHeight}
                    footerStyle={styles.info}
                    footerView={renderInfo}
                    dynamic={true}
                    selectDistance={SELECT_DISTANCE * deviceScale}
                    onHeightChange={onHeightChange}
                    bottomDragColor={Colors.colors.green}
                />
            );
        },
        [
            props,
            dark,
            detail,
            SELECT_DISTANCE,
            deviceScale,
            detailHeight,
            graphHeight,
            infoHeight,
            renderInfo,
            data,
            dragging,
            width,
            onSelect,
            onRelease,
            onHeightChange,
        ],
    );
};

const styles = StyleSheet.create({
    detail: {
    },
    graph: {
    },
    info: {
    },
    infoItem: {
        marginHorizontal: 5,
        marginVertical: 2,
        borderRadius: 5,
        paddingVerical: 3,
        paddingHorizontal: 10,
    },
});
