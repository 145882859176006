/* eslint-disable no-bitwise */
import { Console } from './Console';

const NAME = 'Base64';

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

// Use a lookup table to find the index.
const lookup = typeof Uint8Array === 'undefined' ? [] : new Uint8Array(256);
for (let c = 0; c < chars.length; c++) {
    lookup[chars.charCodeAt(c)] = c;
}


export class Base64 {

    static ToArrayBuffer(base64) {

        if (!base64?.length) {
            Console.warn(`${NAME}.ToArrayBuffer`, { base64 });
            return new ArrayBuffer(0);
        }

        let bufferLength = base64.length * 0.75;
        if (base64[base64.length - 1] === '=') {
            bufferLength--;
            if (base64[base64.length - 2] === '=') {
                bufferLength--;
            }
        }

        const arrayBuffer = new ArrayBuffer(bufferLength);
        const dataView = new Uint8Array(arrayBuffer);

        let p = 0;
        for (let i = 0; i < base64.length; i += 4) {
            const encoded1 = lookup[base64.charCodeAt(i)];
            const encoded2 = lookup[base64.charCodeAt(i + 1)];
            const encoded3 = lookup[base64.charCodeAt(i + 2)];
            const encoded4 = lookup[base64.charCodeAt(i + 3)];

            dataView[p++] = (encoded1 << 2) | (encoded2 >> 4);
            dataView[p++] = ((encoded2 & 15) << 4) | (encoded3 >> 2);
            dataView[p++] = ((encoded3 & 3) << 6) | (encoded4 & 63);
        }

        return arrayBuffer;
    }

    static FromArrayBuffer(arrayBuffer) {

        var base64 = '';

        if (!arrayBuffer) {
            Console.warn(`${NAME}.FromArrayBuffer`, { arrayBuffer });
            return base64;
        }

        const dataView = new Uint8Array(arrayBuffer);

        for (let i = 0; i < dataView.length; i += 3) {
            base64 += chars[dataView[i] >> 2];
            base64 += chars[((dataView[i] & 3) << 4) | (dataView[i + 1] >> 4)];
            base64 += chars[((dataView[i + 1] & 15) << 2) | (dataView[i + 2] >> 6)];
            base64 += chars[dataView[i + 2] & 63];
        }

        if (dataView.length % 3 === 2) {
            base64 = base64.substring(0, base64.length - 1) + '=';
        } else if (dataView.length % 3 === 1) {
            base64 = base64.substring(0, base64.length - 2) + '==';
        }

        return base64;
    }

    /*
        static async ToBlob(base64, type) {
            const res = await fetch(`data:${type};base64,${base64}`);
            return await res.blob();
        }
    */
}
