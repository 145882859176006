import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Header } from 'react-native-elements';
import { Navigators } from './Navigators';
import { Routes } from './routes';
import { Divider, Flag, Gradient, HeaderGradients, Icon, Image, Menu, Pressable, Text, TEXT_VARIANTS, View } from '../components';
import { Icons, Images } from '../media';
import { APP_TYPES, useAppDispatch, useAppState, useSystemState, useUserState } from '../context';
import { useKeyboard } from '../hooks';
import { Console, GetMoonPhase, Optional, Validate } from '../utils';
import { APP_NAME, DEV_COLOR, LANGUAGES } from '../constants';
import { Colors, TextStyles } from '../styles';

const NAME = 'Navigation';

const AUTH = 'Auth';
const HOME = 'Home';
const INFO = 'Info';
const USER = 'User';

//const ICON_COLOR = Colors.colors.black;
const ACTIVE_NAV_COLOR = Colors.colors.gray;
const DIVIDER_WIDTH = 1;
const DIVIDER_COLOR = Colors.colors.gray;

const APP_TITLE = 'MapView';//'DaʻApp';
const COPYRIGHT_NAME = 'da-app-hawaii.com';

const SPLASH = 'Splash';
const CHAT = 'Chat';
const GAMES = 'Games';
const SPEECH = 'Speech';
const SEARCH = 'Search';
const CAMERA = 'Camera';

var ROUTE_NAMES = {};
Object.keys(Routes).forEach(key => { ROUTE_NAMES[key] = null; });
ROUTE_NAMES[AUTH] = SPLASH;

class NavigationUtils {

    static MenuItems() {
        return Object.keys(LANGUAGES).map(lang => {
            return {
                id: lang,
                ...LANGUAGES[lang],
                image: (
                    <Flag
                        country={LANGUAGES[lang].flag}
                    />
                ),
            };
        });
    }

    static Options(deviceScale, theme_primary_color, theme_text_color, headerStyleIndex, headerHeight) {
        const scaledHeaderHeight = headerHeight * deviceScale;
        const scaledHeightStyle = { height: scaledHeaderHeight };
        const adjIndex = (headerStyleIndex % (HeaderGradients.length + 1)) - 1;
        return Optional(adjIndex >= 0,
            {
                header: _props => {
                    // https://codepen.io/yuhomyan/pen/jOqvjZq?editors=1111
                    return (
                        <Header
                            containerStyle={scaledHeightStyle}
                            ViewComponent={Gradient}
                            linearGradientProps={HeaderGradients[adjIndex][1]}
                        />
                    );
                },
            },
            {
                headerStyle: {
                    /// MARKMARK height: scaledHeaderHeight,
                    backgroundColor: theme_primary_color,
                },
                headerTintColor: theme_text_color,
                headerTitleStyle: {
                    fontWeight: 'bold',
                    paddingVertical: 0,
                    marginVertical: 0,
                },
            });
    }
}


export const Navigation = props => {

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const { deviceScale, isIOS, isWeb } = useSystemState();
    const { DEFAULT, audio, dark, theme, themeUpdate, detail, help,
        threeD, frontCamera, phone, image,
        headerStyleIndex, language, navigator, pause, screen, t,
        ui_acquilingua, ui_da_app_hawaii, ui_speak, ui_asl, ui_games, ui_ipa, ui_tools, ui_word5, ui_dictionary,
    } = useAppState();

    const { username } = useUserState();

    const { ipaKeyboard, setIpaKeyboard } = useKeyboard();

    const [routeName, setRouteName] = useState(AUTH);
    const setRouteNameRef = useRef(setRouteName);
    const [screenNames, setScreenNames] = useState(ROUTE_NAMES);
    const setScreenNamesRef = useRef(setScreenNames);

    const [menuItems, setMenuItems] = useState([]);
    const setMenuItemsRef = useRef(setMenuItems);
    const [options, setOptions] = useState({});
    const setOptionsRef = useRef(setOptions);

    useEffect(
        () => {
            const { colors } = theme;
            const { primary, text } = colors;
            const { HEADER_HEIGHT } = DEFAULT;
            Console.log(`${NAME} useEffect platform`, { primary, text, language, themeUpdate });
            setMenuItemsRef.current(NavigationUtils.MenuItems());
            setOptionsRef.current(NavigationUtils.Options(deviceScale, primary, text, headerStyleIndex, HEADER_HEIGHT));
        },
        [
            DEFAULT,
            deviceScale,
            language, // MARKMARK TODO: required to force translations on android ??? Make it so when language changes, the screen doesn't reset
            theme,
            themeUpdate,
            headerStyleIndex,
            setMenuItemsRef,
            setOptionsRef,
            routeName,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect route name`, { username });
            setRouteNameRef.current(Validate.isValidNonEmptyString(username) ? HOME : AUTH);
        },
        [
            username,
            setRouteNameRef,
        ],
    );

    const setScreen = useCallback(
        () => {
            const tabRoutes = Routes[routeName];
            var _screen = null;
            if (!Validate.isValid(tabRoutes.Tabs[screen])) {
                tabRoutes.TabArray.forEach(tab => {
                    if (!Validate.isValidNonEmptyString(_screen) &&
                        Validate.isValid(tabRoutes.Tabs[tab]?.children)) {
                        if (tabRoutes.Tabs[tab].children.has(screen)) {
                            _screen = `${tab}|${screen}`;
                        }
                    }
                });
            }
            if (!Validate.isValidNonEmptyString(_screen)) {
                _screen = screen;
            }
            Console.log(`${NAME}.setScreen`, { routeName, screen, _screen });
            setScreenNamesRef.current(v => {
                var w = { ...v };
                w[routeName] = _screen;
                return w;
            });
        },
        [
            screen,
            routeName,
            setScreenNamesRef,
        ],
    );

    const updateRoute = useCallback(
        value => {
            const nextRoute = value;/* !== Icons.Info.key// || Validate.isValidNonEmptyString(username)
                ? value
                : null;*/
            if (nextRoute) {
                Console.log(`${NAME}.updateRoute`, { value, nextRoute });
                setRouteNameRef.current(nextRoute);
                setScreen();
            }
        },
        [
            //username,
            setRouteNameRef,
            setScreen,
        ],
    );

    const HeaderButtonPressable = useCallback(
        _props => {
            const {
                icon,
                onPress,
                disabled,
                color,
            } = _props;
            const marginStyle = {
                marginHorizontal: 2 * deviceScale,
            };
            Console.log(`${NAME}.HeaderButtonPressable`, { _props, marginStyle, themeUpdate });
            return (
                <Pressable
                    style={marginStyle}
                    iconColor={color ? color : theme?.colors?.text}
                    icon={icon}
                    onPress={onPress}
                    disabled={(help && icon !== Icons.Help) || disabled}
                />
            );
        },
        [
            deviceScale,
            help,
            theme,
            themeUpdate,
        ],
    );

    const StackButtons = useMemo(
        () => {
            var result = {
                Auth: HeaderButtonPressable({
                    icon: Routes.Home.Icon,
                    onPress: () => updateRoute(Icons.Auth.key),
                }),
                User: HeaderButtonPressable({
                    icon: Routes.User.Icon,
                    onPress: () => updateRoute(Icons.User.key),
                    color: routeName === USER ? ACTIVE_NAV_COLOR : undefined,
                }),
                Info: HeaderButtonPressable({
                    icon: Routes.Info.Icon,
                    onPress: () => updateRoute(Icons.Info.key),
                    color: routeName === INFO ? ACTIVE_NAV_COLOR : undefined,
                }),
            };
            const UI_MODES = [
                { key: 'AcquiLingua', ui: ui_acquilingua },
                { key: 'DaAppHawaii', ui: ui_da_app_hawaii },
                { key: 'Speak', ui: ui_speak },
                { key: 'ASL', ui: ui_asl },
                { key: 'Games', ui: ui_games },
                { key: 'IPA', ui: ui_ipa },
                { key: 'Tools', ui: ui_tools },
                { key: 'Word5', ui: ui_word5 },
                { key: 'Dictionary', ui: ui_dictionary },
            ];
            for (let i = 0; i < UI_MODES.length; i++) {
                const { key, ui } = UI_MODES[i];
                if (ui) {
                    result[HOME] = HeaderButtonPressable({
                        icon: Routes[key].Icon,
                        onPress: () => screen === SPLASH ? null : updateRoute(key),
                        color: routeName === key || screen === SPLASH ? ACTIVE_NAV_COLOR : undefined,
                    });
                }
            }
            Console.log(`${NAME}.StackButtons`, { ui_acquilingua, ui_da_app_hawaii, ui_speak, ui_asl, ui_games, ui_ipa, ui_tools, ui_word5, ui_dictionary, home: result[HOME] });
            return result;
        },
        [
            ui_acquilingua, ui_da_app_hawaii, ui_speak, ui_asl, ui_games, ui_ipa, ui_tools, ui_word5, ui_dictionary,
            routeName,
            screen,
            updateRoute,
            HeaderButtonPressable,
        ],
    );

    const HeaderButtons = useMemo(
        () => {
            Console.log(`${NAME}.HeaderButtons`, { themeUpdate });
            var result = {};
            [
                { key: 'Audio', iconOn: 'VolumeLow', iconOff: 'VolumeOff', type: 'SET_AUDIO', value: audio },
                { key: 'Pause', iconOn: 'Play', iconOff: 'Pause', type: 'SET_PAUSE', value: pause },
                { key: 'Game', iconOn: 'SkipNext', type: 'NEXT_GAME' },
                { key: 'Stl', iconOn: 'SkipNext', type: 'NEXT_STL' },
                { key: 'Detail', iconOn: 'Detail', iconOff: 'Graph', type: 'SET_DETAIL', value: detail },
                { key: 'ThreeD', iconOn: 'Dimensions2D', iconOff: 'Dimensions3D', type: 'SET_THREE_D', value: threeD },
                { key: 'Camera', iconOn: 'BackCamera', iconOff: 'FrontCamera', type: 'SET_FRONT_CAMERA', value: frontCamera },
                { key: 'Phone', iconOn: 'AutoSendOn', iconOff: 'AutoSendOff', type: 'SET_PHONE', value: phone },
                { key: 'Image', iconOn: 'Image', iconOff: 'Speech', type: 'SET_IMAGE', value: image },
                { key: 'Theme', iconOn: 'Theme', type: 'SET_DARK', value: dark },
                { key: 'Help', iconOn: 'Help', color: routeName === AUTH ? Colors.colors.lime : null, type: 'SET_HELP', value: help },
            ].forEach(({ key, iconOn, iconOff, color, type, value }) => {
                const _icon = value ? Icons[iconOn] : Icons[iconOff ? iconOff : iconOn];
                var __icon = {..._icon};
                if (key === 'Theme') {
                    const sun = _icon.name(true);
                    __icon.name = (_dark) => {
                        return _dark ? sun : GetMoonPhase();
                    };
                }
                result[key] = HeaderButtonPressable({
                    color,
                    icon: __icon,
                    onPress: () => appDispatchRef.current({ type: APP_TYPES[type], payload: !value }),
                });
            });
            return {
                ...result,
                IpaKeyboard: HeaderButtonPressable({
                    icon: ipaKeyboard ? Icons.KeyboardOff : Icons.Keyboard,
                    onPress: () => setIpaKeyboard(!ipaKeyboard),
                }),
                Language: (
                    <Menu
                        value={Icons.Translate.key}
                        current={language}
                        Anchor={Pressable}
                        icon={Icons.Translate}
                        iconColor={theme?.colors?.text}
                        items={menuItems}
                        onPress={payload => appDispatchRef.current({ type: APP_TYPES.SET_LANGUAGE, payload })}
                        disabled={help}
                    />
                ),
            };
        },
        [
            routeName,
            language,
            audio,
            dark,
            help,
            detail,
            ipaKeyboard,
            threeD,
            frontCamera,
            phone,
            image,
            menuItems,
            pause,
            theme,
            themeUpdate,
            appDispatchRef,
            setIpaKeyboard,
            HeaderButtonPressable,
        ],
    );

    const headerLeft = useCallback(
        (name, title, isTab, _props) => {
            Console.log(`${NAME}.headerLeft`, { Route: Routes[routeName], routeName, name, title, isTab, _props, isIOS });
            return Optional(!isTab,
                HeaderButtonPressable({
                    icon: isIOS ? Icons.BackIOS : Icons.Back,
                    onPress: () => {
                        appDispatchRef.current({ type: APP_TYPES.SET_AUTH, payload: '' });
                        _props.onPress();
                    },
                }),
                Optional(Routes[routeName]?.Tabs[name]?.menu, HeaderButtonPressable({
                    icon: Icons.Menu,
                    onPress: () => {
                        appDispatchRef.current({ type: APP_TYPES.TOGGLE_DRAWER });
                    },
                }),
            ));
        },
        [
            routeName,
            isIOS,
            appDispatchRef,
            HeaderButtonPressable,
        ],
    );

    const headerRight = useCallback(
        (isTab, _props) => {
            Console.log(`${NAME}.headerRight`, { isTab, /*_props,*/ routeName, screen, username });
            const marginStyle = {
                marginRight: 12 * deviceScale,
            };
            const marginDivider = {
                marginHorizontal: 6 * deviceScale,
            };
            const additionalIconScreens = new Set([CHAT, GAMES, SPEECH, SEARCH, CAMERA]);

            return (
                <View
                    value={'NavHeaderRight'}
                    style={[styles.headerRight, marginStyle]}
                >
                    {Optional(additionalIconScreens.has(screen), (
                        <>
                            {Optional(screen === CHAT, HeaderButtons.Image)}
                            {Optional(screen === CHAT, HeaderButtons.Phone)}
                            {Optional(screen === CHAT, HeaderButtons.Audio)}
                            {Optional(screen === SPEECH && threeD, HeaderButtons.Stl)}
                            {Optional(screen === SPEECH && !threeD, HeaderButtons.IpaKeyboard)}
                            {Optional(screen === SPEECH, HeaderButtons.ThreeD)}
                            {Optional(screen === SEARCH, HeaderButtons.Detail)}
                            {Optional(screen === CAMERA, HeaderButtons.Camera)}
                            {Optional(screen === GAMES, HeaderButtons.Pause)}
                            {Optional(screen === GAMES, HeaderButtons.Game)}
                            <Divider
                                style={marginDivider}
                                color={DIVIDER_COLOR}
                                width={DIVIDER_WIDTH}
                                orientation={'vertical'}
                            />
                        </>
                    ))}
                    {Optional(isTab, (
                        <>
                            {Optional(routeName === AUTH,
                                (
                                    <>
                                        {StackButtons.Info}
                                        {Optional(isWeb, StackButtons.Home)}
                                    </>
                                ),
                                Optional(routeName !== INFO && routeName !== USER,
                                    (
                                        <>
                                            {StackButtons.User}
                                            {StackButtons.Info}
                                            {Optional(isWeb, StackButtons.Home)}
                                        </>
                                    ),
                                    Optional(Validate.isValidNonEmptyString(username),
                                        (
                                            <>
                                                {Optional(isWeb, StackButtons.User)}
                                                {Optional(isWeb, StackButtons.Info)}
                                                {StackButtons.Home}
                                            </>
                                        ),
                                        (
                                            <>
                                                {Optional(isWeb, StackButtons.Info)}
                                                {StackButtons.Auth}
                                            </>
                                        ),
                                    ),
                                ),
                            )}
                            <Divider
                                style={marginDivider}
                                color={DIVIDER_COLOR}
                                width={DIVIDER_WIDTH}
                                orientation={'vertical'}
                            />
                        </>
                    ))}
                    {HeaderButtons.Help}
                    {HeaderButtons.Theme}
                    {HeaderButtons.Language}
                </View>
            );
        },
        [
            isWeb,
            deviceScale,
            threeD,
            routeName,
            screen,
            username,
            HeaderButtons,
            StackButtons,
        ],
    );

    const stackOptions = useCallback(
        (name, title, isTab = false) => {
            Console.log(`${NAME}.stackOptions`, { name, title, isTab });
            return {
                ...options,
                headerTitleAlign: 'left',
                headerTitle: ({ tintColor }) => (
                    Optional(title === t(APP_TITLE) || title === APP_NAME,
                    (
                        <Image
                            containerStyle={styles.headerImage}
                            source={Images.miscl.da_app_title_header}
                            width={DEFAULT.HEADER_HEIGHT * 0.9 * 3}
                            height={DEFAULT.HEADER_HEIGHT * 0.9}
                            resizeMode={'contain'}
                        />
                    ),
                    (
                        <Text
                            style={styles.headerTitleText}
                            value={title}
                            color={tintColor}
                            variant={TEXT_VARIANTS.TITLE}
                        />
                    ))
                ),
                headerLeft: _props => headerLeft(name, title, isTab, _props),
                headerRight: _props => headerRight(isTab, _props),
            };
        },
        [
            t,
            DEFAULT,
            options,
            headerLeft,
            headerRight,
        ],
    );

    const tabOptions = useCallback(
        (name, title, icon) => {
            Console.log(`${NAME}.tabOptions`, { name, title, icon });
            return {
                ...stackOptions(name, title, true),
                tabBarLabel: name === SPLASH ? `${t(SPLASH)} ${COPYRIGHT_NAME}` : title,
                tabBarIcon: Validate.isValid(icon)
                    ? ({ focused, color, size }) => (
                        <Icon
                            icon={icon}
                            color={color}
                            size={size}
                            focused={focused}
                        />
                    )
                    : null,
            };
        },
        [
            t,
            stackOptions,
        ],
    );

    Console.stack(`${NAME}[${routeName}]`, props, { language, deviceScale, isIOS, dark, help, navigator, routeName, screenNames, screen, username });

    return useMemo(
        () => {
            const { TAB_BAR_HEIGHT } = DEFAULT;
            const Navigator = Navigators[navigator];
            const Route = Routes[routeName];
            var initialRouteName = null;
            const routeScreenName = screenNames[routeName];
            if (Validate.isValidNonEmptyString(routeScreenName)) {
                const screenNameTokens = routeScreenName.split('|');
                initialRouteName = screenNameTokens?.length && Validate.isValidNonEmptyString(screenNameTokens[0])
                    ? screenNameTokens[0].split('.')[0]
                    : Route.TabArray[0];
            }
            Console.log(`${NAME}[${routeName}] render`, { navigator, deviceScale, routeName, screenNames, initialRouteName, Tabs: Route.Tabs });
            return (
                <Navigators.Stack.Navigator>
                    <Navigators.Stack.Screen
                        name={routeName}
                        options={{ headerShown: false }}
                    >
                        {
                            () => (
                                <Navigator.Navigator
                                    initialRouteName={initialRouteName}
                                    screenOptions={{
                                        title: APP_NAME,
                                        tabBarStyle: { height: Route.TabArray.length === 1 ? 0 : TAB_BAR_HEIGHT * deviceScale },
                                        tabBarLabelPosition: 'below-icon',
                                        tabBarLabelStyle: {
                                            fontSize: TextStyles.tabBarLabel.fontSize * deviceScale,
                                        },
                                    }}
                                >
                                    {
                                        Route.TabArray.map((name, index) => {
                                            // https://reactnavigation.org/docs/bottom-tab-navigator/
                                            const Tab = Route.Tabs[name];
                                            const xName = t(name);
                                            const xTitle =
                                                Validate.isValidNonEmptyString(Tab.title)
                                                    ? t(Tab.title)
                                                    : xName;
                                            return (
                                                <Navigator.Screen
                                                    key={`tab_${index}`}
                                                    name={name}
                                                    component={Tab.Component}
                                                    options={tabOptions(name, xTitle, Tab.Icon)}
                                                />
                                            );
                                        })
                                    }
                                </Navigator.Navigator>
                            )
                        }
                    </Navigators.Stack.Screen>
                    {
                        Object.keys(Route.Stacks).map((name, index) => {
                            // https://reactnavigation.org/docs/stack-navigator/
                            const Stack = Route.Stacks[name];
                            const title = t(name);
                            return (
                                <Navigators.Stack.Screen
                                    key={`stack_${index}`}
                                    name={title}
                                    component={Stack.Component}
                                    options={({ route }) => stackOptions(name, route?.params?.params?.title)}
                                />
                            );
                        })
                    }
                </Navigators.Stack.Navigator>
            );
        },
        [
            t,
            DEFAULT,
            navigator,
            deviceScale,
            routeName,
            screenNames,
            stackOptions,
            tabOptions,
        ],
    );
};

const styles = StyleSheet.create({
    headerRight: {
        flexDirection: 'row',
        backgroundColor: DEV_COLOR(Colors.colors.pink),
    },
    headerLeft: {
        flexDirection: 'row',
        backgroundColor: DEV_COLOR(Colors.colors.lime),
    },
    headerTitleText: {
        backgroundColor: DEV_COLOR(Colors.colors.red),
    },
    headerImage: {
        marginLeft: -25,
    },
});
