import React from 'react';
import { Text, TEXT_VARIANTS } from '../components';
import { useSystemState } from '../context';
import { Audio, Console, Numbers } from '../utils';
import { TextStyles } from '../styles';
import { S3_SERVER } from '../constants';

// android files must be mp3
// https://online-audio-converter.com/
// note: can keep launching in incognito to bypass daily limit

import { ipaWavLookup } from './ipaWavLookup';

const NAME = 'IpaAudio';


export const IpaAudio = props => {

    const {
        ipa,
        index,
        style,
        onPress,
    } = props;

    const { deviceScale } = useSystemState();

    var ipaWavs = ipaWavLookup[ipa === 'ɡ' ? 'g' : ipa];

    var textStyle = {...TextStyles.title};
    textStyle.lineHeight = textStyle.fontSize * 2;
    const _textStyle = Numbers.ReScaleStyle(textStyle, deviceScale);

    Console.stack(NAME, props, { deviceScale, ipaWavs });

    return (
        <Text
            style={[_textStyle, style]}
            value={`${ipa}${ipaWavs ? ' 🔊' : ''}`}
            variant={TEXT_VARIANTS.HEADLINE}
            onPress={() => {
                if (ipaWavs) {
                    const types = Object.keys(ipaWavs);
                    const type = types[index % types.length];
                    Audio.Play(`${S3_SERVER}/ipa_2d/${ipaWavs[type]}.mp3`);
                    if (onPress) {
                        onPress();
                    }
                }
            }}
        />
    );
};
