import React from 'react';
import { StyleSheet } from 'react-native';
import { ActivityIndicator, Image as RNImage } from 'react-native';
import { View } from './View';
import { useAppState, useSystemState } from '../context';
import { Console } from '../utils';

const NAME = 'Image';


export const Image = props => {

    const {
        source,
        placeholder,
        width,
        height,
        containerStyle,
        imageStyle,
        resizeMode,
    } = props;

    const { deviceScale } = useSystemState();
    const { DEFAULT } = useAppState();
    const { IMAGE_SIZE } = DEFAULT;

    const sizeStyle = {
        width: deviceScale * (width ? width : IMAGE_SIZE),
        height: deviceScale * (height ? height : IMAGE_SIZE),
    };

    Console.stack(NAME, props, { deviceScale, IMAGE_SIZE, containerStyle, imageStyle, sizeStyle });

    return (
        <View
            value={'ImageView'}
            style={[styles.container, sizeStyle, containerStyle]}
        >
            <RNImage
                resizeMode={resizeMode}
                style={[sizeStyle, imageStyle]}
                source={source}
                PlaceholderContent={placeholder ? placeholder : <ActivityIndicator />}
                onError={error => Console.error(`${NAME}.onError`, { source, error })}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        //height: '100%',
        //justifyContent: 'center',
    },
});
