import React, { useEffect, useMemo, useRef } from 'react';
import { useColorScheme, useWindowDimensions } from 'react-native';
import { ThemeProvider } from 'react-native-elements';
import { NavigationContainer, DarkTheme as NavigationDarkTheme, DefaultTheme as NavigationLightTheme } from '@react-navigation/native';
import { Provider as PaperProvider, DarkTheme as PaperDarkTheme, DefaultTheme as PaperLightTheme } from 'react-native-paper';
import { SafeAreaProvider } from './SafeAreaProvider';
import { Navigation } from './Navigation';
import { APP_TYPES, useAppDispatch, useAppState, SYSTEM_TYPES, useSystemDispatch } from '../context';
import { useKeyboard } from '../hooks';
import { KeyboardView } from '../keyboard';
import { Console, Optional } from '../utils';
import { RNW } from '../constants';
import { Colors } from '../styles';
import merge from 'deepmerge';

const NAME = 'Router';
const version = 2; // https://callstack.github.io/react-native-paper/introducing-v5-with-material-you.html

// merge nav, paper, and app light/dark themes
const Light = merge(merge(PaperLightTheme, NavigationLightTheme), { version, colors: Colors.lightTheme });
const Dark = merge(merge(PaperDarkTheme, NavigationDarkTheme), { version, colors: Colors.darkTheme });


export const Router = props => {

    const appDispatch = useAppDispatch();
    const appDispatchRef = useRef(appDispatch);

    const systemDispatch = useSystemDispatch();
    const systemDispatchRef = useRef(systemDispatch);

    const colorScheme = useColorScheme();
    const windowDimensions = useWindowDimensions();

    const { dark, theme, themeUpdate, customTheme, customThemeUpdate } = useAppState();

    const { setKbInput } = useKeyboard();

    useEffect(
        () => {
            Console.log(`${NAME} useEffect dimensions`, { windowDimensions });
            systemDispatchRef.current({
                type: SYSTEM_TYPES.SET_DIMENSIONS,
                payload: windowDimensions,
            });
        },
        [
            windowDimensions,
            systemDispatchRef,
        ],
    );

    useEffect(
        () => {
            Console.log(`${NAME} useEffect color scheme`, { colorScheme });
            appDispatchRef.current({
                type: APP_TYPES.SET_DARK,
                payload: RNW.DARK === colorScheme,
            });
        },
        [
            colorScheme,
            appDispatchRef,
        ],
    );

    useEffect(
        () => {
            const { darkTheme, lightTheme } = customTheme;
            Console.log(`${NAME} useEffect set dark ${customThemeUpdate}`, { dark, darkTheme, lightTheme });
            appDispatchRef.current({
                type: APP_TYPES.SET_THEME,
                payload: dark ? merge(Dark, darkTheme) : merge(Light, lightTheme),
            });
        },
        [
            dark,
            customTheme,
            customThemeUpdate,
            appDispatchRef,
        ],
    );

    Console.stack(NAME, props, { dark, theme, themeUpdate, colorScheme, windowDimensions, customTheme, customThemeUpdate });

    return useMemo(
        () => {
            Console.log(`${NAME} render`, { theme, themeUpdate });
            return (
                <ThemeProvider>
                    <PaperProvider theme={theme}>
                        <SafeAreaProvider>
                            <NavigationContainer
                                theme={theme}
                            >
                                <Navigation />
                            </NavigationContainer>
                            {Optional(true, <KeyboardView onPress={setKbInput} />)}
                        </SafeAreaProvider>
                    </PaperProvider>
                </ThemeProvider>
            );
        },
        [
            theme,
            themeUpdate,
            setKbInput,
        ],
    );
};
