import { Console, Validate } from '../utils';
import { GetLex, GetPos, GetRelation, VocabularyUtils } from './utils';
import { Dictionary } from './Dictionary';
import { LEXICAL, SEMANTIC, SENSE, SENSE_KEY } from './DictionaryConstants';

const NAME = 'Vocabulary';

const SENSE_RELATION_TYPE = 'Sense';
const ROOT_POS = 'root';

let DICTIONARY = null;


export class Vocabulary {

    static Get(cb = null) {
        if (!DICTIONARY) {
            Console.LOG(`${NAME}.Get initializing vocabulary...`);
            DICTIONARY = new Dictionary(cb);
        }
        return DICTIONARY;
    }

    static IsLoaded() {
        const MARKMARK_SENSE_COUNT = 103487;
        const result = DICTIONARY && DICTIONARY.senseCount() === MARKMARK_SENSE_COUNT ? true : false;
        Console.log(`${NAME}.IsLoaded`, { result });
        return result;
    }

    static GetPos(value) {
        return GetPos(value);
    }

    static GetLex(value) {
        return GetLex(value);
    }

    static GetRelation(value) {
        return GetRelation(value);
    }

    static Size(language) {
        return Vocabulary.Get().wordCount(language);
    }

    static List(language, searchWord = '') {

        // bail on null input
        if (!Validate.isValidNonEmptyString(searchWord)) {
            Console.log(`${NAME}.List empty`, { searchWord });
            return new Map();
        }

        const result = Vocabulary.Get().wordList(searchWord, language);

        Console.LOG(`${NAME}.List`, { searchWord, result: result.size });
        Console.trace(`${NAME}.List result`, { result });

        return result;
    }

    static GetSense(senseKey) {
        return Vocabulary.Get().getSense(senseKey);
    }

    static GetWord(word, language) {
        return Vocabulary.Get().getWord(word, language);
    }

    static Graph(language, searchWord = '', height = 0, width = 0, textHeight = 0, filter = false, minRank = 0) {

        const graphUtil = new VocabularyUtils();

        // bail on null input
        if (!Validate.isValidNonEmptyString(searchWord) || !height || !width) {
            Console.log(`${NAME}.Graph empty`, { language, searchWord, height, width });
            return graphUtil.graph(height, width);
        }

        Console.LOG(`${NAME}.Graph input`, { language, searchWord, height, width, filter });

        // get the word data, and bail if not found
        const wordData = Vocabulary.Find(searchWord, language);

        Console.LOG(`${NAME}.Graph wordData`, { wordData });

        if (!Validate.isValid(wordData)) {
            Console.LOG(`${NAME}.Graph not found`, { language, searchWord, height, width });
            return graphUtil.graph(height, width);
        }

        var rootNode = null;

        // MARKMARK: Object.keys().forEach... (do a global search for this)
        Object.keys(wordData).forEach(wordKey => {

            const rootWord = wordData[wordKey];
            if (!rootWord) {
                return;
            }
            const semantic = rootWord[SEMANTIC];
            if (!semantic) {
                return;
            }
            const senses = semantic[SENSE];
            if (!senses) {
                return;
            }

            if (!rootNode) {
                const _rootNodeInfo =  { ...rootWord, pos: ROOT_POS };
                rootNode = graphUtil.node(searchWord, null, null, _rootNodeInfo);
            }

            Object.keys(senses).forEach(senseKey => {

                if (senseKey.split('%')[1] === 'u') {
                    return; // MARKMARK: Lemmas
                }

                const sense = senses[senseKey];
                const { frames, relations, words } = sense;

                // get the related words
                var semanticRelationships = {};
                if (Validate.isValid(relations)) {
                    Object.keys(relations).forEach(relationType => {
                        const relatedSenseKeys = relations[relationType];
                        relatedSenseKeys.forEach(relatedSenseKey => {
                            const relatedSense = semantic[relationType][relatedSenseKey];
                            Object.keys(relatedSense.words).forEach(relatedWordKey => {
                                if (!semanticRelationships[relationType]) {
                                    semanticRelationships[relationType] = [];
                                }
                                const relatedWord = relatedWordKey.split('%')[0];
                                if (relatedWord !== searchWord) {
                                    if (!filter || !VocabularyUtils.HasSpaceOrHyphenOrCap(relatedWord)) {
                                        semanticRelationships[relationType].push(relatedWord);
                                    }
                                }
                            });
                        });
                    });
                }

                var senseNode = filter ? null : graphUtil.node(senseKey, rootNode, SENSE_RELATION_TYPE, {...sense, semantic: semanticRelationships });

                const wordsArray = words ? Object.keys(words).sort() : [];
                wordsArray.forEach(_wordKey => {
                    if (_wordKey.split('%')[0] !== searchWord) {
                        const _wordKeyInfo = words[_wordKey];
                        if (!filter || (!VocabularyUtils.HasSpaceOrHyphenOrCap(_wordKey) && VocabularyUtils.HasRankOrIPA(_wordKeyInfo, minRank))) {
                            if (!senseNode) {
                                senseNode = graphUtil.node(senseKey, rootNode, SENSE_RELATION_TYPE, {...sense, semantic: semanticRelationships });
                            }
                            graphUtil.node(_wordKey, senseNode, SENSE_RELATION_TYPE, _wordKeyInfo);
                        }
                    }
                });

                const relationsArray = wordsArray.length && relations ? Object.keys(relations).sort() : [];

                relationsArray.forEach(relationType => {

                    const relationSenseKeys = relations[relationType];
                    relationSenseKeys.forEach(relatedSenseKey => {

                        const relatedSense = semantic[relationType][relatedSenseKey];
                        var rSenseNode = filter ? null : graphUtil.node(relatedSenseKey, senseNode, relationType, relatedSense);

                        Object.keys(relatedSense.words).forEach(_wordKey => {
                            if (_wordKey.split('%')[0] !== searchWord) {
                                const _wordKeyInfo = relatedSense.words[_wordKey];
                                if (!filter || (!VocabularyUtils.HasSpaceOrHyphenOrCap(_wordKey) && VocabularyUtils.HasRankOrIPA(_wordKeyInfo, minRank))) {
                                    if (!senseNode) {
                                        senseNode = graphUtil.node(senseKey, rootNode, SENSE_RELATION_TYPE, {...sense, semantic: semanticRelationships });
                                    }
                                    if (!rSenseNode) {
                                        rSenseNode = graphUtil.node(relatedSenseKey, senseNode, relationType, relatedSense);
                                    }
                                    graphUtil.node(_wordKey, rSenseNode, relationType, _wordKeyInfo);
                                }
                            }
                        });
                    });
                });

                const framesArray = frames ? Object.keys(frames).sort() : [];
                framesArray.forEach(frame => {
                    //Console.LOG(`${NAME}.Graph MARKMARK TODO adding Frame node`, { frame });
                });
            });
        });

        const result = graphUtil.graph(height, width, textHeight);

        Console.LOG(`${NAME}.Graph`, { searchWord, height, width, textHeight, wordData });
        Console.LOG(`${NAME}.Graph result`, { result });

        return result;
    }

    static Find(searchKey, lang = 'en') {
        Console.LOG(`${NAME}.Find`, { searchKey, lang });
        return /[0-9]{8}%(a|n|r|u|v)/.test(searchKey)
            ? Vocabulary.#FindSense(searchKey, lang)
            : Vocabulary.#FindWord(searchKey, lang);
    }

    static #FindSense(senseKey, lang) {

        Console.log(`${NAME}.#FindSense`, { senseKey, lang });

        const sense = Vocabulary.GetSense(senseKey);
        if (!sense) {
            return null;
        }

        var result = { type: sense.t };
        if (sense?.e) {
            result.emojis = sense.e;
        }

        const langSense = sense[lang] ? sense[lang] : sense?.en;

        if (langSense) {

            if (langSense?.d) {
                result.definition = langSense.d;
            } else if (sense?.en?.d) {
                result.definition = sense.en.d.split(';').map(v => { return `en: ${v}`; }).join(';');
            }
            if (langSense?.w) {
                result.words = langSense.w;
                console.log('\nMARKMARK.FindSense\n', { result });
            }
            if (langSense?.x) {
                // MARKMARK
                //result.examples = langSense.x;
            }

        }

        Console.log(`${NAME}.#FindSense`, { lang, senseKey, result });
        return result;
    }

    static #FindWord(searchKey, lang) {

        Console.log(`${NAME}.#FindWord`, { searchKey, lang });

        var result = {};

        // iterate over every search word key
        VocabularyUtils.GetKeys(searchKey, lang, Vocabulary.Get()).forEach(wordKey => {

            const wordKeyTokens = wordKey.split('%');
            const posIdTokens = wordKeyTokens[1].split(':');

            const word = wordKeyTokens[0];
            const pos = posIdTokens[0];
            const id = posIdTokens[1];

            //console.log('\nMARKMARK FindWord INPUT\n', { searchKey, lang, wordKey, word, pos, id });

            // initialize the result word data entry
            if (!result[wordKey]) {
                result[wordKey] = {};
            }
            var resultWord = result[wordKey];
            VocabularyUtils.AddWord(wordKey, resultWord, lang, Vocabulary.Get());

            ///console.log('MARKMARK AddWord', { resultWord });

            // get the word data
            var vocabWord = Vocabulary.GetWord(word, lang);
            var wordData = vocabWord?.w && vocabWord.w[pos] ? vocabWord.w[pos][id] : null;

            //console.log('MARKMARK FindWord GetWord', { word, lang, vocabWordW: vocabWord.w, wordData });

            // handle syntactic markers (adj only)
            /*
            if (wordData.syntacticMarkers && wordData.syntacticMarkers.length) {
                if (!resultWord.syntacticMarkers) {
                    resultWord.syntacticMarkers = {};
                }
                wordData.syntacticMarkers.forEach((syntacticMarkerValue, syntacticMarkerKey) => {
                    resultWord.syntacticMarkers[syntacticMarkerKey] = syntacticMarkerValue;
                });
            }
            */

            // handle semantic relationships
            if (wordData && wordData[SEMANTIC]) {
                Object.keys(wordData[SEMANTIC]).forEach(relationKey => {
                    const relationValue = wordData[SEMANTIC][relationKey];

                    var resultWordSemanticRelationSenses = VocabularyUtils.InitResult(resultWord, SEMANTIC, relationKey);

                    // iterate relationship's senses
                    Object.keys(relationValue).forEach(senseKey => {
                        const sense = Vocabulary.GetSense(senseKey);
                        if (!sense) {
                            Console.warn(`${NAME} invalid sense ${senseKey}`, { searchKey });
                            return;
                        }

                        const senseData = {};
                        if (sense?.f) {
                            senseData.frames = sense.f;
                        }
                        if (sense?.e && sense.e.length) {
                            senseData.emojis = sense.e;
                        }
                        const langSense = sense[lang] ? sense[lang] : sense?.en;
                        if (langSense) {
                            if (langSense?.d) {
                                senseData.definition = langSense.d;
                            } else if (sense?.en?.d) {
                                senseData.definition = sense.en.d.split(';').map(v => { return `en: ${v}`; }).join(';');
                            }
                            if (langSense?.x) {
                                senseData.examples = langSense.x;
                            }
                        }

                        // set the result, overriding type, words, and relations
                        resultWordSemanticRelationSenses[senseKey] = {
                            ...senseData,
                            words: {},
                        };
                        if (sense?.t) {
                            resultWordSemanticRelationSenses[senseKey].type = GetLex(sense.t);
                        }

                        if (langSense && langSense?.w) {
                            const selfWord = langSense.w[wordKey];
                            //console.log('\nMARKMARK FindWord LANGSENSE\n', { selfWord, langSense, sense, senseData, resultWordSemanticRelationSenses, senseKey, relationKey, wordKey, wordData });
                            if (selfWord && selfWord[SENSE_KEY]) {
                                const xLations = selfWord[SENSE_KEY];
                                if (xLations && xLations.length) {
                                    if (!resultWordSemanticRelationSenses[senseKey].translations) {
                                        resultWordSemanticRelationSenses[senseKey].translations = {};
                                    }
                                    xLations.forEach(xLation => {
                                        if (xLation.length) {
                                            const xLationTokens = xLation.split('|');
                                            const xLationLang = xLationTokens[0];
                                            const xLationWordKey = xLationTokens[1];

                                            if (!resultWordSemanticRelationSenses[senseKey].translations[xLationLang]) {
                                                resultWordSemanticRelationSenses[senseKey].translations[xLationLang] = [];
                                            }
                                            resultWordSemanticRelationSenses[senseKey].translations[xLationLang].push(xLationWordKey);
                                        }
                                    });
                                }
                                const xEmojis = selfWord[SENSE_KEY].e;
                                if (xEmojis && xEmojis.size) {
                                    ///console.error('MARKMARK THIS GOT HIT???', { xEmojis, selfWord });
                                    if (!resultWordSemanticRelationSenses[senseKey].emojis) {
                                        resultWordSemanticRelationSenses[senseKey].emojis = [];
                                    }
                                    xEmojis.forEach(xEmoji => {
                                        resultWordSemanticRelationSenses[senseKey].emojis.push(xEmoji);
                                    });
                                }
                            }
                            Object.keys(langSense.w).filter(v => v !== wordKey).sort().forEach(relatedWordKey => {
                                resultWordSemanticRelationSenses[senseKey].words[relatedWordKey] = {};
                                VocabularyUtils.AddWord(relatedWordKey, resultWordSemanticRelationSenses[senseKey].words[relatedWordKey], lang, Vocabulary.Get());
                            });
                        }
                    });
                });
            }

            //// this part could be moved downstream
            //// it is just handling data already in the result object

            // add relations to the semantic sense relationship entries
            // these are other relationKey/senseKeys within the same wordKey result with the same sense type
            const wordKeySemantic = resultWord[SEMANTIC];
            const wordKeySenseRelations = wordKeySemantic[SENSE];

            ///console.log('MARKMARK Semantics', { resultWord, wordKeySemantic, wordKeySenseRelations });

            if (wordKeySenseRelations) {
                // iterate over the sense relationship senses
                Object.keys(wordKeySenseRelations).forEach(senseKey => {
                    const wordKeySenseRelation = wordKeySenseRelations[senseKey];
                    // look for other relationship/senses with the same type
                    Object.keys(wordKeySemantic).filter(v => v !== SENSE).forEach(relationKey => {

                        Object.keys(wordKeySemantic[relationKey]).forEach(relatedSenseKey => {
                            if (wordKeySemantic[relationKey][relatedSenseKey].type === wordKeySenseRelation.type) {

                                // if found, add the relationKey->senseKey to the wordKey's result
                                if (!wordKeySenseRelation.relations) {
                                    wordKeySenseRelation.relations = {};
                                }
                                if (!wordKeySenseRelation.relations[relationKey]) {
                                    wordKeySenseRelation.relations[relationKey] = new Set();
                                }
                                wordKeySenseRelation.relations[relationKey].add(relatedSenseKey);
                            }
                        });
                        // fixup the result
                        if (wordKeySenseRelation.relations &&
                            wordKeySenseRelation.relations[relationKey]) {
                            const temp = [...wordKeySenseRelation.relations[relationKey].keys()].sort();
                            wordKeySenseRelation.relations[relationKey] = temp;
                        }
                    });
                });
            }

            ///console.log('MARKMARK Lexical', { wordKeySemantic, wordKeySenseRelations });

            ////

            // handle lexical relationships
            if (wordData && wordData[LEXICAL]) {
                Object.keys(wordData[LEXICAL]).forEach(relationKey => {
                    const relationValue = wordData[LEXICAL][relationKey];

                    var resultWordLexicalRelationSenses = VocabularyUtils.InitResult(resultWord, LEXICAL, relationKey);

                    // iterate relationship's senses
                    Object.keys(relationValue).forEach(senseKey => {
                        const relatedWordKeys = relationValue[senseKey];

                        const relSense = Vocabulary.GetSense(senseKey);
                        const senseData = {};
                        if (relSense?.f) {
                            senseData.frames = relSense.f;
                        }
                        if (relSense?.e) {
                            senseData.emojis = relSense.e;
                        }
                        const langSense = relSense[lang] ? relSense[lang] : relSense?.en;
                        if (langSense) {
                            if (langSense?.d) {
                                relSense.definition = langSense.d;
                            } else if (relSense?.en?.d) {
                                relSense.definition = relSense.en.d.split(';').map(v => { return `en: ${v}`; }).join(';');
                            }
                            if (langSense?.w) {
                                // MARKMARK
                                //relSense.words = langSense.w;
                            }
                            if (langSense?.x) {
                                relSense.examples = langSense.x;
                            } else if (relSense?.en?.x) {
                                relSense.examples = relSense.en.x.map(v => { return `en: ${v}`; });
                            }
                        }

                        // set the result, overriding type and words
                        resultWordLexicalRelationSenses[senseKey] = {
                            ...senseData,
                            words: {},
                        };
                        if (relSense?.type) {
                            resultWordLexicalRelationSenses[senseKey].type = GetLex(relSense.t);
                        }

                        // add related words to the result's words array
                        relatedWordKeys.forEach(relatedWordKey => {
                            resultWordLexicalRelationSenses[senseKey].words[relatedWordKey] = {};
                            VocabularyUtils.AddWord(relatedWordKey, resultWordLexicalRelationSenses[senseKey].words[relatedWordKey], lang, Vocabulary.Get());
                        });
                    });
                });
            }
        });

        Console.log(`${NAME}.#FindWord`, { lang, searchKey, result });
        return result;
    }
}
