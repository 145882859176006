import React from 'react';
import Svg, { G, Path } from '../svg';

//https://www.qr-code-generator.com/


export const qr = () => {

        return (
                <Svg width="300" height="300" viewBox="0 0 300 300">
                        <G fill="#000" stroke="none" scaleX="0.1" scaleY="-0.1" translateX="0" translateY="300">
                                <Path d="M370 2315 l0 -315 315 0 315 0 0 315 0 315 -315 0 -315 0 0 -315z
m540 0 l0 -225 -225 0 -225 0 0 225 0 225 225 0 225 0 0 -225z"/>
                                <Path d="M550 2315 l0 -135 135 0 135 0 0 135 0 135 -135 0 -135 0 0 -135z" />
                                <Path d="M1090 2585 l0 -45 90 0 90 0 0 45 0 45 -90 0 -90 0 0 -45z" />
                                <Path d="M1630 2585 l0 -45 -45 0 -45 0 0 -45 0 -45 -90 0 -90 0 0 -90 0 -90
-45 0 -45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 -135 0 -135 45 0
45 0 0 -45 0 -45 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 -45 0 -45 0
0 -90 0 -90 90 0 90 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 45 0 45
45 0 45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 90 0 90 0 0 -45 0 -45 -45 0 -45
0 0 -45 0 -45 -90 0 -90 0 0 -45 0 -45 90 0 90 0 0 -45 0 -45 -45 0 -45 0 0
-45 0 -45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 45 0 45
-45 0 -45 0 0 -90 0 -90 -45 0 -45 0 0 45 0 45 -90 0 -90 0 0 45 0 45 -45 0
-45 0 0 -135 0 -135 45 0 45 0 0 45 0 45 90 0 90 0 0 -45 0 -45 -45 0 -45 0 0
-45 0 -45 -90 0 -90 0 0 -45 0 -45 -90 0 -90 0 0 -45 0 -45 270 0 270 0 0 -45
0 -45 45 0 45 0 0 45 0 45 90 0 90 0 0 -45 0 -45 -45 0 -45 0 0 -45 0 -45 45
0 45 0 0 45 0 45 45 0 45 0 0 -90 0 -90 45 0 45 0 0 -45 0 -45 45 0 45 0 0
-45 0 -45 -45 0 -45 0 0 -90 0 -90 45 0 45 0 0 90 0 90 45 0 45 0 0 -45 0 -45
45 0 45 0 0 45 0 45 90 0 90 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 90 0 90 0 0
-45 0 -45 180 0 180 0 0 90 0 90 -45 0 -45 0 0 45 0 45 45 0 45 0 0 180 0 180
-90 0 -90 0 0 45 0 45 45 0 45 0 0 45 0 45 45 0 45 0 0 360 0 360 -45 0 -45 0
0 -90 0 -90 -135 0 -135 0 0 -90 0 -90 -90 0 -90 0 0 45 0 45 45 0 45 0 0 90
0 90 135 0 135 0 0 45 0 45 -135 0 -135 0 0 -45 0 -45 -45 0 -45 0 0 -90 0
-90 -90 0 -90 0 0 45 0 45 45 0 45 0 0 90 0 90 -45 0 -45 0 0 90 0 90 -45 0
-45 0 0 -45 0 -45 -45 0 -45 0 0 135 0 135 45 0 45 0 0 -45 0 -45 45 0 45 0 0
90 0 90 -45 0 -45 0 0 135 0 135 -90 0 -90 0 0 -45z m90 -135 l0 -90 -45 0
-45 0 0 -180 0 -180 -45 0 -45 0 0 -45 0 -45 -45 0 -45 0 0 45 0 45 -45 0 -45
0 0 45 0 45 -45 0 -45 0 0 -45 0 -45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 45 0
45 90 0 90 0 0 45 0 45 45 0 45 0 0 90 0 90 45 0 45 0 0 45 0 45 45 0 45 0 0
-90z m180 -630 l0 -90 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 -45 0 -45 -45 0
-45 0 0 45 0 45 -45 0 -45 0 0 -45 0 -45 -90 0 -90 0 0 45 0 45 45 0 45 0 0
45 0 45 90 0 90 0 0 45 0 45 -90 0 -90 0 0 45 0 45 135 0 135 0 0 -90z m630
-225 l0 -45 -45 0 -45 0 0 -90 0 -90 45 0 45 0 0 -45 0 -45 -45 0 -45 0 0 -90
0 -90 -90 0 -90 0 0 45 0 45 -135 0 -135 0 0 90 0 90 -90 0 -90 0 0 -135 0
-135 -95 0 -95 0 0 45 0 45 -85 0 -85 0 0 90 0 90 45 0 45 0 0 45 0 45 45 0
45 0 0 -90 0 -90 45 0 45 0 0 45 0 45 45 0 45 0 0 45 0 45 90 0 90 0 0 -45 0
-45 45 0 45 0 0 -45 0 -45 135 0 135 0 0 135 0 135 45 0 45 0 0 45 0 45 45 0
45 0 0 -45z m-1260 -180 l0 -45 45 0 45 0 0 -45 0 -45 -45 0 -45 0 0 -45 0
-45 -45 0 -45 0 0 90 0 90 -45 0 -45 0 0 45 0 45 90 0 90 0 0 -45z m-180 -180
l0 -45 -90 0 -90 0 0 45 0 45 90 0 90 0 0 -45z m1080 -270 l0 -135 -135 0
-135 0 0 135 0 135 135 0 135 0 0 -135z m180 0 l0 -45 45 0 45 0 0 -45 0 -45
45 0 45 0 0 -45 0 -45 -45 0 -45 0 0 -45 0 -45 -45 0 -45 0 0 45 0 45 -45 0
-45 0 0 135 0 135 45 0 45 0 0 -45z m-540 -135 l0 -90 -45 0 -45 0 0 45 0 45
-90 0 -90 0 0 45 0 45 135 0 135 0 0 -90z m450 -225 l0 -45 90 0 90 0 0 -45 0
-45 -90 0 -90 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 -45z"/>
                                <Path d="M1990 2315 l0 -315 315 0 315 0 0 315 0 315 -315 0 -315 0 0 -315z
m540 0 l0 -225 -225 0 -225 0 0 225 0 225 225 0 225 0 0 -225z"/>
                                <Path d="M2170 2315 l0 -135 135 0 135 0 0 135 0 135 -135 0 -135 0 0 -135z" />
                                <Path d="M370 1775 l0 -135 45 0 45 0 0 -45 0 -45 90 0 90 0 0 45 0 45 -45 0
-45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 90 0 90 0 0 -45 0 -45 135 0 135 0 0
-45 0 -45 45 0 45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 45 0 45 -45
0 -45 0 0 -45 0 -45 -90 0 -90 0 0 45 0 45 -180 0 -180 0 0 -135z"/>
                                <Path d="M460 1370 l0 -90 -45 0 -45 0 0 -45 0 -45 45 0 45 0 0 45 0 45 90 0
90 0 0 45 0 45 -45 0 -45 0 0 45 0 45 -45 0 -45 0 0 -90z"/>
                                <Path d="M370 695 l0 -315 315 0 315 0 0 315 0 315 -315 0 -315 0 0 -315z
m540 0 l0 -225 -225 0 -225 0 0 225 0 225 225 0 225 0 0 -225z"/>
                                <Path d="M550 695 l0 -135 135 0 135 0 0 135 0 135 -135 0 -135 0 0 -135z" />
                                <Path d="M1270 785 l0 -45 -90 0 -90 0 0 -180 0 -180 135 0 135 0 0 45 0 45
45 0 45 0 0 45 0 45 -45 0 -45 0 0 45 0 45 45 0 45 0 0 45 0 45 -45 0 -45 0 0
45 0 45 -45 0 -45 0 0 -45z"/>
                        </G>
                </Svg>
        );
};
